<div class="d-flex justify-content-between p-3">
  <div class="mx-2 d-flex">
    <label class="go-to-label">Total {{ totalLength }} items</label>
    <select
      class="cb-input-focus custom-select custom-select-sm w-auto mx-2"
      (change)="changePageSize($event.target.value)"
      [(ngModel)]="pageSize"
    >
      <ng-container *ngFor="let item of pageSizes">
        <option [value]="item.value">{{ item.label }}</option>
      </ng-container>
    </select>
  </div>
  <div class="mx-2 d-flex">
    <div class="go-to-label">Goto</div>
    <input
      [(ngModel)]="pageText"
      type="number"
      class="form-control go-to-input"
      #ref
      (ngModelChange)="onPageTextChange($event)"
      (keyup.enter)="goToPage(ref.value)"
    />
    <div class="">
      <ngb-pagination
        [collectionSize]="totalLength"
        [(page)]="page"
        [pageSize]="pageSize"
        [maxSize]="4"
        aria-label="Default pagination"
        (pageChange)="onPageChange($event)"
      ></ngb-pagination>
    </div>
  </div>
</div>
