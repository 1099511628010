import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { OrganisationService } from '@app/core/organisation.service';
import { SettingsService } from '@app/settings/settings.service';
import { EventScheduleDialogComponent } from '@app/Organisation-admin/EventsSchedule/eventsSchedule.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { take } from 'rxjs/operators';
import { ModalService } from '@app/core/modal/modal.service';
import { OrganisationAdminService } from '@app/Organisation-admin/organisation-admin.service';
import { NgOption } from '@ng-select/ng-select';

@Component({
  selector: 'app-profile-editor',
  templateUrl: './addEventDiag.component.html',
  styleUrls: ['./addEventDiag.component.scss']
})
export class AddEventDiagComponent implements OnInit, OnDestroy {
  OrganisationID: string;
  committeeList: object[];
  programList: object[];
  error = {
    name: '',
    purpose: '',
    description: '',
    channelName: '',
    event_status: '',
    org_compliance_program_guid: '',
    org_rev_committee_guid: '',
    document_urls_FA: ''
  };
  org_sub: any;
  loading = true;
  eventForm: any;
  isLoading = true;
  submitted = false;
  all_url_types: NgOption[];
  slack_channel_name = '';
  maxLengthSlackChannelName = 79;
  default_event_status = 'enabled';
  default_doc_url_type: any;
  @ViewChild(EventScheduleDialogComponent, { static: false }) eventScheduleComponent: EventScheduleDialogComponent;

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private orgService: OrganisationService,
    private orgAdminService: OrganisationAdminService,
    private modalService: ModalService,
    private settingsService: SettingsService,
    private translate: TranslateService,
    private toaster: ToasterService
  ) {}

  ngOnInit() {
    this.org_sub = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
      this.default_doc_url_type = 'Others';
      // this.loading = false;

      this.settingsService.getOrganizationCommitteeList(this.OrganisationID).subscribe(
        data => {
          this.committeeList = data.data;
          this.settingsService.organisationalProgram(this.OrganisationID).subscribe(
            data2 => {
              this.programList = data2;
              this.loading = false;
            },
            error2 => {
              this.error = error2.error.message;
            }
          );
        },
        error => {
          this.error = error.error.message;
        }
      );
    });

    this.orgAdminService.getUrlTypes().subscribe(data => {
      this.all_url_types = data;
      this.createForm();
      this.loading = false;
    });
  }

  public createForm(): void {
    this.eventForm = this.fb.group({
      name: ['', [Validators.required()]],
      purpose: ['', [Validators.required()]],
      channelName: ['', [Validators.required(), Validators.channelNameValidator()]],
      description: ['', [Validators.required()]],
      event_status: ['', [Validators.required()]],
      org_compliance_program_guid: ['', [Validators.required()]],
      org_rev_committee_guid: ['', [Validators.required()]],
      document_urls_FA: this.fb.array([])
    });
  }

  get optionsData(): FormArray {
    return this.eventForm.get('document_urls_FA') as FormArray;
  }

  newDocumentUrl(): FormGroup {
    return this.fb.group({
      urltype: [this.default_doc_url_type, [Validators.required()]],
      url: ['', [Validators.httpsURL()]],
      title: ['', [Validators.required()]]
    });
  }

  addDocumentUrl() {
    this.submitted = false;
    if (this.optionsData.valid) {
      this.optionsData.push(this.newDocumentUrl());
    } else {
      return;
    }
  }

  removeOption(i: number) {
    this.modalService
      .confirm('Are you sure that you want to remove this ?', 'Remove document')
      .pipe(take(1))
      .subscribe((confirmed: any) => {
        if (confirmed) {
          this.optionsData.removeAt(i);
        }
      });
  }

  ngOnDestroy() {
    this.org_sub.unsubscribe();
  }

  makeSlackChannelName($event: any) {
    this.slack_channel_name = $event
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '')
      .substring(0, this.maxLengthSlackChannelName);
  }

  setDocumentUrlType(index: number) {
    let urltype = '';
    if (this.eventForm.value.document_urls_FA[index].url) {
      if (this.eventForm.value.document_urls_FA[index].url.startsWith('https://docs.google.com')) {
        urltype = 'Google Drive';
      } else if (this.eventForm.value.document_urls_FA[index].url.startsWith('https://www.dropbox.com')) {
        urltype = 'DropBox';
      } else if (this.eventForm.value.document_urls_FA[index].url.startsWith('https://onedrive')) {
        urltype = 'OneDrive';
      } else if (this.eventForm.value.document_urls_FA[index].url.startsWith('https://www.icloud.com')) {
        urltype = 'iCloud';
      } else {
        urltype = 'Others';
      }
    }
    this.eventForm
      .get('document_urls_FA')
      .at(index)
      .get('urltype')
      .patchValue(urltype);
  }

  submit() {
    this.submitted = true;
    if (this.eventForm.valid) {
      const event_data = Object.assign({}, this.eventForm.value);
      event_data['schedule'] = this.eventScheduleComponent.getPreparedData();
      this.settingsService.submitOrganisationAddEventData(this.OrganisationID, event_data).subscribe(
        data => {
          this.error = {
            name: '',
            purpose: '',
            channelName: '',
            description: '',
            event_status: '',
            org_compliance_program_guid: '',
            org_rev_committee_guid: '',
            document_urls_FA: ''
          };
          // this.submitted = false;
          this.activeModal.close(true);
          this.translate.get('organisation_add_event.add_event_toaster_message.event_submitted').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        },
        (error: any) => {
          this.error = error.error;
        }
      );
    } else {
      return;
    }
  }

  close() {
    this.activeModal.dismiss();
  }
}
