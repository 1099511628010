import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-program-check-box',
  templateUrl: './programCheckbox.component.html',
  styleUrls: ['./programCheckbox.component.scss']
})
export class ProgramCheckboxComponent implements OnInit {
  @Input() program: any;
  @Output() public program_clicked = new EventEmitter();

  constructor() {}

  ngOnInit() {}
  select_deselect_program() {
    this.program_clicked.emit();
  }
}
