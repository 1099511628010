<div class="row  mx-0">
  <div class="col-12 m-0">
    <div class="background-container">
      <div class="success-container">
        <img src="/assets/images/core/congrats.svg" alt="Overlay Image" class="success-image" />
        <div class="text-overlay">
          <div class="succes-title mt-4">
            Congratulations
          </div>
          <div class="success-subtitle mt-4">
            You have successfully completed the onboarding process with your policies and procedures and are now
            {{ this.frameworkString }} compliant.
          </div>
        </div>
      </div>
      <mat-icon class="position-absolute cursor-pointer close-btn" (click)="closeDialog()">close</mat-icon>
    </div>
  </div>

  <div class="col-12 d-flex justify-content-center certificates row">
    <ng-container *ngFor="let certificate of certificateData">
      <div class="cetificate-container-back position-relative col-4 mx-2 my-2 ">
        <div class="d-flex  align-items-center ">
          <div class="certificate-container">
            <div class="">
              <div class="row">
                <div class="w-100p text-center">
                  <img src="/assets/images/cb-lite/stamp.png" class="stamp" alt="no-img" />
                </div>
              </div>
              <div class="row mt-1 justify-content-center">
                <div class="cert-title">Certificate of Compliance</div>
              </div>
              <hr class="m-0 my-1 " />
              <div class="my-1">
                <div class=" mx-1 row text-left">
                  <div class="cert-subtitle">
                    This is to certify that <b>{{ certificate?.org_name }}</b> has successfully completed risk
                    assessment and creation of policies and procedures in line with <b>45 CFR § 164.308(a)(1)(i).</b>
                  </div>
                </div>
                <div class=" mx-1 row text-left mt-1">
                  <div class="cert-subtitle">
                    The following list of policy and procedure documents was reviewed and approved by
                    <b>{{ certificate?.org_name }}</b> management on <b>{{ certificate?.date }}</b>
                  </div>
                </div>
                <div class="mx-1 row mt-1" id="listContainer" style="min-height: 73px;">
                  <div class="col-md-6">
                    <ul class="category-style-certificate" [id]="certificate.framework + '-first-column-small'"></ul>
                  </div>
                  <div class="col-md-6">
                    <ul class="category-style-certificate" [id]="certificate.framework + '-second-column-small'"></ul>
                  </div>
                </div>
                <hr class="m-0 my-1 " />
                <div class="d-flex justify-content-around align-items-center sign-container px-2">
                  <ng-container *ngIf="isSignatureLoading; else show">
                    <h2 class="font-weight-bold mt-2 text-black-3 text-center">
                      <i
                        class="fa"
                        [ngClass]="{ 'fa-spin fa-spinner': isSignatureLoading }"
                        style="font-size:large;"
                      ></i>
                      &nbsp;
                    </h2>
                  </ng-container>
                  <ng-template #show>
                    <div class=" cursor-pointer w-100p d-flex align-items-center">
                      <img src="/assets/images/logo/logo.png" class="cb-lite-logo mx-1" alt="logo" srcset="" />
                      <span style="font-size: 5.3px;" class="font-weight-bold ">ComplianceBot</span>
                    </div>
                    <img id="onlineImage" class="user-avatar-pic" [src]="UserSignature" alt="no-image" />
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="certificate-footer mt-3">Certificate of Compliance - {{ certificate.framework }}</div>
        <div>
          <button
            class="mt-2 cb-btn-secondary download-btn"
            (click)="generatePdfWithOnlineImages(certificate.framework)"
          >
            Download Certificate
          </button>
        </div>
        <img
          (click)="expandCertificate(certificate)"
          class="expand-btn"
          src="/assets/images/core/expand.svg"
          alt=""
          srcset=""
        />
      </div>
    </ng-container>
  </div>
  <div class="w-100p text-center mt-4">
    <button type="button" class="lite-login-btn mr-2" (click)="closeDialog()">
      Continue to Dashboard
    </button>
  </div>
</div>
<div style="height: 0; overflow: hidden;">
  <div class="d-flex justify-content-center align-items-center " *ngFor="let certificate of certificateData">
    <div class="certificate-container-hidden" [id]="certificate.framework + '-certificate-content'">
      <div>
        <div class="row">
          <div class="w-100p text-center">
            <img src="/assets/images/cb-lite/stamp.png" class="stamp-hidden" alt="no-img" />
          </div>
        </div>
        <div class="row mt-3 justify-content-center">
          <div class="cert-title-hidden">Certificate of Compliance</div>
        </div>
        <hr />
        <div class="m-3 p-3">
          <div class="row text-left">
            <div class="cert-subtitle-hidden">
              This is to certify that <b>{{ certificate?.org_name }}</b> has successfully completed risk assessment and
              creation of policies and procedures in line with <b>45 CFR § 164.308(a)(1)(i).</b>
            </div>
          </div>
          <div class="row text-left mt-3">
            <div class="cert-subtitle-hidden">
              The following list of policy and procedure documents was reviewed and approved by
              <b>{{ certificate?.org_name }}</b> management on <b>{{ certificate?.date }}</b>
            </div>
          </div>
          <div class="row mt-3" id="listContainer-hidden">
            <div class="col-md-6">
              <ul
                class="category-style-hidden d-flex flex-column"
                [id]="certificate.framework + '-first-column-hidden'"
              ></ul>
            </div>
            <div class="col-md-6">
              <ul
                class="category-style-hidden d-flex flex-column"
                [id]="certificate.framework + '-second-column-hidden'"
              ></ul>
            </div>
          </div>
          <hr />
          <div class="d-flex justify-content-around align-items-center sign-container-hidden">
            <div class="fs-17 cursor-pointer w-100p">
              <img src="/assets/images/logo/logo.png" class="cb-lite-logo-hidden mx-1" alt="logo" srcset="" />
              <span class="font-weight-bold fs-17">ComplianceBot</span>
            </div>
            <img
              [id]="certificate.framework + '-onlineImage-hidden'"
              class="user-avatar-pic-hidden"
              [src]="UserSignature"
              height="70"
              width="130px"
              style="max-height: 100px; max-width: 130px;"
              alt="no-image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
