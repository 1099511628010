import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface PasswordResetContext {
  new_password1: string;
  new_password2: string;
  uid: string;
  token: string;
}

export interface ForgotPasswordContext {
  email: string;
}

export interface Credentials {
  // Customize received credentials here
  key: string;
  user: object;
}

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable()
export class PasswordResetService {
  private _password_reset_url = '/api/accounts/password/reset/confirm/';
  private _send_password_reset_email_url = '/api/sendpasswordresetemail/';

  constructor(private http: HttpClient) {}

  passwordReset(context: PasswordResetContext, uid: string, token: string): Observable<any> {
    context.uid = uid;
    context.token = token;
    // console.log('uid: ', uid, ' token: ', token, this._password_reset_url, context);
    return this.http.post<any>(this._password_reset_url, context, { withCredentials: false });
  }

  sendPasswordResetEmail(context: ForgotPasswordContext): Observable<any> {
    return this.http.post<any>(this._send_password_reset_email_url, context, { withCredentials: false });
  }
  sendverificationEmail(context: ForgotPasswordContext): Observable<any> {
    return this.http.post<any>('/api/resendwelcomeemail/', context, { withCredentials: false });
  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }
}
