import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from 'app/core';
import { LoginComponent } from '../login/login.component';
import { Shell } from 'app/shells/shell/shell.service';
import { HomeComponent } from 'app/home/home.component';
import { SlackOauthComponent } from '@app/slack-oauth/slack-oauth.component';
import { PublicShell } from '@app/shells/publicShell/publicShell.service';

const routes: Routes = [
  PublicShell.childRoutes([
    { path: 'slack-integration', component: SlackOauthComponent, data: { title: extract('Slack integration') } }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class SlackOauthRoutingModule {}
