import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { OrganisationService } from '@app/core/organisation.service';
import { SettingsService } from '@app/settings/settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';

@Component({
  selector: 'app-committees',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss']
})
export class CommunicationComponent implements OnInit, OnDestroy {
  OrganisationID: string | null;
  communicationID1: string | null;
  communicationID2: string | null;
  communicationForm1: FormGroup;
  communicationForm2: FormGroup;
  error = {
    message: ''
  };
  guidSubscription: any;
  communicationData: object[];
  dummyCommunicationData = [
    {
      message: '',
      communication_type: 'mid-day-reminder',
      is_enabled: false,
      next_run_time: '12:00 AM',
      review_schedule: 'DAILY',
      communication_channels: ['slack'],
      guid: ''
    },
    {
      message: '',
      communication_type: 'daily-status-email',
      is_enabled: false,
      next_run_time: '12:00 AM',
      review_schedule: 'DAILY',
      communication_channels: ['email'],
      guid: ''
    }
  ];
  isLoading = false;
  submitted = false;
  success = false;
  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#fff',
      buttonColor: '#FFFFFF'
    },
    dial: {
      dialBackgroundColor: '#555'
    },
    clockFace: {
      clockFaceBackgroundColor: '#fff',
      clockHandColor: 'black',
      clockFaceTimeInactiveColor: 'black'
    }
  };
  loading = true;

  constructor(
    private orgService: OrganisationService,
    private settingsService: SettingsService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private toaster: ToasterService
  ) {}

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getCommunicationData();
    });
  }

  // convenience getters for easy access to form fields
  get f1() {
    return this.communicationForm1.controls;
  }
  get f2() {
    return this.communicationForm2.controls;
  }

  onReset() {
    this.submitted = false;
    this.communicationForm1.reset();
    this.communicationForm2.reset();
    this.getCommunicationData();
  }

  // TODO: Need to update the save api of communication as 2 forms are saving from 2 different api's.
  save(): void {
    this.submitted = true;
    if (this.communicationForm1.valid && this.communicationForm2.valid) {
      this.isLoading = true;
      const communicationMidDayForm = this.communicationForm1.value;
      const communicationEmailStatusForm = this.communicationForm2.value;
      communicationMidDayForm['communication_type'] = 'mid-day-reminder';
      communicationMidDayForm['review_schedule'] = 'DAILY';
      communicationMidDayForm['communication_channels'] = ['slack'];
      communicationEmailStatusForm['communication_type'] = 'daily-status-email';
      communicationEmailStatusForm['review_schedule'] = 'DAILY';
      communicationEmailStatusForm['communication_channels'] = ['email'];
      this.callMidReminderSave(communicationMidDayForm, communicationEmailStatusForm);
    } else {
      return;
    }
  }

  callMidReminderSave(communicationMidDayPayload: any, communicationEmailStatusPayload: any): void {
    this.settingsService
      .submitOrganisationCommunication(communicationMidDayPayload, this.OrganisationID, this.communicationID2)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.submitted = false;
          this.callDailyEmailStatusSave(communicationEmailStatusPayload);
        },
        (error: any) => {
          this.error = error.error;
        }
      );
  }

  callDailyEmailStatusSave(communicationEmailStatusPayload: any): void {
    this.settingsService
      .submitOrganisationCommunication(communicationEmailStatusPayload, this.OrganisationID, this.communicationID1)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.submitted = false;
          this.getCommunicationData();
          this.translate
            .get('organisation_communications.communication_toaster_message.update_communication')
            .subscribe(res => {
              this.toaster.showSuccess(res);
            });
        },
        (error: any) => {
          this.error = error.error;
        }
      );
  }

  private getCommunicationData(): void {
    this.settingsService.getOrganizationCommunicationData(this.OrganisationID).subscribe(
      (communicationData: any) => {
        this.communicationData = communicationData.length > 0 ? communicationData : this.dummyCommunicationData;
        // TODO: Add dynamic form instaed of using 2 forms.
        this.communicationData.map((data: any) => {
          switch (data.length > 0) {
            case data['communication_type'] === 'daily-status-email':
              this.communicationID1 = data['guid'];
              this.communicationForm2 = this.formBuilder.group({
                message: [
                  data ? (data['message'] ? data['message'] : '') : '',
                  [Validators.required(), Validators.maxLength(4096)]
                ],
                next_run_time: [
                  data ? (data['next_run_time'] ? data['next_run_time'] : '12:00 AM') : '',
                  [Validators.required()]
                ],
                is_enabled: [data ? (data['is_enabled'] ? data['is_enabled'] : false) : true]
              });
              break;
            case data['communication_type'] === 'mid-day-reminder':
              this.communicationID2 = data['guid'];
              this.communicationForm1 = this.formBuilder.group({
                message: [
                  data ? (data['message'] ? data['message'] : '') : '',
                  [Validators.required(), Validators.maxLength(4096)]
                ],
                next_run_time: [
                  data ? (data['next_run_time'] ? data['next_run_time'] : '12:00 AM') : '',
                  [Validators.required()]
                ],
                is_enabled: [data ? (data['is_enabled'] ? data['is_enabled'] : false) : true]
              });
              break;
            default:
              break;
          }
        });
        this.loading = false;
      },
      (error: any) => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }
}
