import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { OrganisationService } from '@app/core/organisation.service';
import { finalize } from 'rxjs/operators';
import { UserService } from '@app/core/user.service';
import { ModalService } from '@app/core/modal/modal.service';
import { take } from 'rxjs/operators';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { TranslateService } from '@ngx-translate/core';
import stringInfo from '../../../../translations/en-US.json';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-manage-responder-approver',
  templateUrl: './manage-responder-approver.component.html',
  styleUrls: ['./manage-responder-approver.component.scss']
})
export class ManageResponderApproverComponent implements OnInit {
  no_user: any = false;
  public separatorKeysCodes = [ENTER, COMMA];
  public emailList: any = [];
  removable = true;
  rulesForm: FormGroup;
  invalidForm: any = false;
  date = stringInfo.Document_Invite_Approver.Date;
  form: FormGroup;
  submitted = false;
  isLoading = false;
  OrganisationID: string | null;
  guidSubscription: any;
  success: boolean;
  dropdownList: any = [];
  selectedItems: any = [];
  dropdownSettings = {};
  error: any;
  selectedUser = true;
  reviewerApproverList: object[];
  userList: object[] = [];
  DataList: object[];
  loginUser: any;
  loginUsername: string;
  loginUserEmail: string;
  minDate: NgbDateStruct;
  @Input() formGuid: string;
  @Input() title_tag: string;
  @Input() formCreatedBy: string;
  @Input() formTitle: string;
  @Input() type: string;
  @Input() comment: string;
  @Input() due_date: any;
  statusData: any;
  reviewerstatusData: any;
  backend_error: string;
  revokeReviewerdata: string;
  revokeApproverData: string;
  reassignReviewerdata: string;
  reassignApproverData: string;
  store_due_date = '';
  actions: any;
  show_new_user = true;
  isResponderAddModalOpen = false;
  emails: any[] = [''];
  isEmailvalid = false;
  searchString = '';
  constructor(
    private documentsService: DocumentsService,
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private userservice: UserService,
    private modalService: ModalService,
    private toaster: ToasterService,
    private translate: TranslateService // private config: NgbDatepickerConfig
  ) {}
  @ViewChild('multiSelectDropdown') multiSelectDropdown: any;
  ngOnInit() {
    if (this.type == 'Manage Form Approvers') {
      this.show_new_user = false;
    }
    this.minDate = this.documentsService.getTomorrowDate();
    if (this.due_date != null) {
      const due_date_arr = this.due_date.split('-');
      this.due_date = new NgbDate(parseInt(due_date_arr[0]), parseInt(due_date_arr[1]), parseInt(due_date_arr[2]));
    }
    this.loginUser = this.userservice.getuserdetails();
    this.loginUsername = this.loginUser.user['username'];
    this.loginUserEmail = this.loginUser.user['email'];

    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getForm();
      this.getReviewerApproverList();
      this.getUnassignedReviewerApproverList();
    });
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'guid',
      textField: 'FullName',
      itemsShowLimit: 10,
      enableCheckAll: false,
      allowSearchFilter: true,
      orderby: 'FullName',
      allowRemoteDataSearch: true,
      closeDropDownOnSelection: false,
      searchPlaceholderText: this.type == 'Manage Form Approvers' ? 'Search approver' : 'Search or add responder'
    };
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  onItemSelect(item: any) {
    this.checkSelection();
  }

  onItemDeSelect(item: any) {
    this.checkSelection();
  }

  checkSelection() {
    this.selectedItems.length > 0 ? (this.selectedUser = true) : (this.selectedUser = false);
  }

  getForm() {
    this.form = this.formBuilder.group({
      due_date: ['', [Validators.required(), Validators.DateIsAfterToday()]],
      comment: ['', Validators.maxLength(4096)]
      // newly_added_users: [[], [this.validateArrayNotEmpty], this.emailListValidator()]
      // newly_added_users: ['', [this.emailListValidator()]]
    });
  }

  haveCommonValues(arr1: any, arr2: any) {
    for (const value of arr1) {
      if (arr2.some((obj2: any) => obj2?.user?.email === value || obj2?.email === value)) {
        return true;
      }
    }
    return false;
  }

  private validateEmail(email: any) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  emailListValidator() {
    return (control: any) => {
      const emailValue = control.value;
      // Check if the field is empty or contains only whitespace
      if (!emailValue || emailValue.trim() === '') {
        return null; // Return null for empty/whitespace-only input
      }
      // Split and validate email addresses only if the field is not empty
      const email = emailValue.split(',').map((email: string) => email.trim());
      const invalidEmails = email.filter((email: string) => !this.isValidEmail(email));
      if (invalidEmails.length > 0) {
        return { invalidEmails: true }; // Return a validation error for invalid emails
      } else {
        return null; // Return null for valid input
      }
    };
  }

  // Helper function to validate individual email addresses
  isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  get f() {
    return this.form.controls;
  }

  private getUnassignedReviewerApproverList(): void {
    let unassigned = {
      'Manage Form Approvers': 'getUnassignedApproverList',
      'Manage Responders': 'getUnassignedResponderList'
    };

    this.documentsService[unassigned[this.type]](this.OrganisationID, this.formGuid).subscribe(
      (data: any) => {
        this.DataList = data;
        this.userList = this.DataList.filter(user => {
          if (this.type !== 'Manage Form Approvers') {
            return user['roles'].includes(
              'form_responder' || (this.formCreatedBy == this.loginUserEmail && this.loginUserEmail == user['email'])
            );
          } else {
            return user['roles'].includes(
              'form_approver' || (this.formCreatedBy == this.loginUserEmail && this.loginUserEmail == user['email'])
            );
          }
        });

        this.userList.forEach(user => {
          user['FullName'] = user['first_name'] + ' ' + user['last_name'] + '(' + user['email'] + ')';
        });
        this.userList.sort((a, b) => a['FullName'].localeCompare(b['FullName']));
      },
      (error: any) => {
        this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
        });
      }
    );
  }

  revokeResponderApprover(formGuid: string, guid: string, user: string, type: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: this.type == 'Manage Responders' ? 'Remove Responder' : 'Remove Approver',
        message: `Are you sure you want to remove ${user} ?`,
        modalType: this.type == 'Manage Responders' ? 'Revoke Form Responder' : 'Revoke Form Approver',
        guid: guid,
        formGuid: formGuid,
        type: type
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          this.getReviewerApproverList();
          this.getUnassignedReviewerApproverList();
          this.actions = result.result;
          if (this.type == 'Manage Form Approvers') {
            this.translate
              .get('Revoke_Incident_Approver.revoke_approver_toaster_message.revoke_approver_submitted')
              .subscribe(res => {
                this.toaster.showSuccess(res);
              });
          } else {
            this.translate
              .get('Revoke_Incident_Reviewer.revoke_reviewer_toaster_message.revoke_responder_submitted')
              .subscribe(res => {
                this.toaster.showSuccess(res);
              });
          }
        } else {
          console.log('Cancelled.');
        }
      });
  }

  checkEditorSelection() {
    this.selectedItems.length > 0 ? (this.selectedUser = true) : (this.selectedUser = false);
  }
  submitInviteReviewer(analystData: any): void {
    this.documentsService
      .submitFormReviewerData(this.OrganisationID, this.formGuid, analystData)
      .pipe(
        finalize(() => {
          this.form.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.error = {
            due_date: ''
          };
          this.submitted = false;
          this.backend_error = '';
          this.activeModal.close({ result: data, isUpdate: true, show_msg: true });
          let successText =
            this.selectedItems.length > 1 || this.emailList.length > 1
              ? this.type === 'Manage Responders'
                ? 'Responders invited'
                : 'Approvers invited'
              : this.type === 'Manage Responders'
              ? 'Responder invited'
              : 'Approver invited';

          this.translate.get(successText).subscribe(res => {
            this.toaster.showSuccess(res);
          });
        },
        (error: any) => {
          this.submitted = false;
          this.isLoading = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
  }

  private getReviewerApproverList(): void {
    let unassigned = {
      'Manage Form Approvers': 'getFormApproverList',
      'Manage Responders': 'getFormResponderList'
    };
    this.documentsService[unassigned[this.type]](this.OrganisationID, this.formGuid).subscribe(
      (data: any) => {
        this.reviewerApproverList = data;
        if (this.reviewerApproverList) {
          this.reviewerApproverList.forEach(item => {
            item['initials'] = item['user']['name']
              ? item['user']['name']
                  .match(/\b(\w)/g)
                  .join('')
                  .toUpperCase()
              : item['user']['email'].charAt(0).toUpperCase();
          });
        }
      },
      (error: any) => {
        this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
        });
      }
    );
  }

  onDateSelection(date: any) {
    if (date) {
      let myDate = new Date(date.year, date.month - 1, date.day + 1);
      this.store_due_date = myDate.toISOString().split('T')[0];
    } else {
      this.store_due_date = this.store_due_date;
    }
  }
  save() {
    let approver: any = [];
    this.submitted = true;
    if (!this.store_due_date) return;
    let selectedItemsToSend = {
      selectedEmails: this.selectedItems
        .filter((item: any) => item.guid == item.FullName)
        .map((item: any) => item.guid),
      selectedUsers: this.selectedItems.filter((item: any) => item.guid !== item.FullName).map((item: any) => item.guid)
    };
    if (this.selectedItems.length && this.form.valid && !this.no_user) {
      approver = selectedItemsToSend.selectedUsers;
      this.checkSelection();
      this.isLoading = true;
      this.no_user = false;
      const approverData = this.form.value;
      approverData['organization_users'] = approver;
      if (this.type !== 'Manage Form Approvers') {
        approverData['newly_added_users'] = selectedItemsToSend.selectedEmails;
      }
      approverData['role'] = this.type == 'Manage Form Approvers' ? 'approver' : 'responder';
      approverData['due_date'] = this.store_due_date;
      this.submitInviteReviewer(approverData);
    } else {
      this.checkSelection();
      return;
    }
  }

  openChangeDueFrom(reviewerform: any): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: this.type == 'Manage Responders' ? 'Edit Due Date' : 'Edit Due Date',
        modalType:
          this.type == 'Manage Responders'
            ? 'Change Due Date for Manage Responder'
            : 'Change Due Date for Manage Form Approver',
        ...reviewerform,
        formGuid: this.formGuid,
        guid: reviewerform.guid,
        user: reviewerform.user.name ? reviewerform.user.name : reviewerform.user.email,
        module: 'Form',
        role: this.type == 'Manage Responders' ? 'responder' : 'approver'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getReviewerApproverList();
          this.translate.get('Change_due_date_toaster_message.Due_date_changed').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  openRestoreApproverForm(reviewerform: any, docTitle: any): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: this.type == 'Manage Responders' ? 'Reassign Responder' : 'Reassign Approver',
        message:
          this.type === 'Manage Responders'
            ? 'Are you sure you want to invite ' +
              (reviewerform.user.name ? reviewerform.user.name : reviewerform.user.email) +
              ' as a responder to the form again?'
            : 'Are you sure you want to invite ' +
              (reviewerform.user.name ? reviewerform.user.name : reviewerform.user.email) +
              ' as an approver to the form again?',
        modalType: this.type == 'Manage Responders' ? 'Reassign Form Responder' : 'Reassign Form Approver',
        ...reviewerform,
        formGuid: this.formGuid,
        guid: reviewerform.user.guid,
        docTitle: docTitle,
        due_date: reviewerform.due_date,
        type: this.type,
        module: 'Form'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          this.getReviewerApproverList();
          this.actions = result.result;
          if (this.type == 'Manage Form Approvers') {
            this.translate
              .get('Reassign_Approver.reassign_approver_toaster_message.reassign_approver_submitted')
              .subscribe(res => {
                this.toaster.showSuccess(res);
              });
          } else {
            this.translate
              .get('Reassign_Approver.reassign_reviewer_toaster_message.reassign_responder_submitted')
              .subscribe(res => {
                this.toaster.showSuccess(res);
              });
          }
        } else {
          console.log('Cancelled.');
        }
      });
  }

  filterChange(searchString: any) {
    this.searchString = searchString;
    if (this.type == 'Manage Form Approvers') return;
    let result = this.selectedItems.filter((obj: any) =>
      Object.values(obj).some(val => val.toString().includes(searchString))
    );
    if (
      searchString.slice(-1) == ',' &&
      this.validateEmail(searchString.slice(0, -1)) &&
      !this.checkEmailExistence(searchString.slice(0, -1))
    ) {
      this.addResponder(searchString.slice(0, -1));
      this.searchString = '';
      this.isEmailvalid = false;
      this.clearSearchInput();
    }
    if (this.validateEmail(searchString) && !result.length) {
      this.isEmailvalid = true;
      this.searchString = searchString;
    } else {
      this.isEmailvalid = false;
    }
  }
  addResponder(email: any): void {
    this.userList = [
      ...this.userList,
      {
        FullName: email,
        guid: email,
        email: email
      }
    ];
    this.selectedItems = [
      ...this.selectedItems,
      {
        FullName: email,
        guid: email,
        email: email
      }
    ];
    this.emailList = [
      ...this.emailList,
      {
        FullName: email,
        guid: email,
        email: email
      }
    ];
    this.isEmailvalid = false;
  }
  closeResponderDropdown(event: any) {
    this.isEmailvalid = false;
    this.searchString = '';
  }
  clearSearchInput() {
    this.multiSelectDropdown.filter.text = '';
  }
  checkEmailExistence(email: string): boolean {
    return (
      this.userList.some((item: any) => item.email.toLowerCase() == email.toLowerCase()) ||
      this.reviewerApproverList.some((item: any) => item.user.email.toLowerCase() == email.toLowerCase())
    );
  }
}
