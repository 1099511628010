import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToasterService, ToastInfo } from './toastr.service';
export interface Toast {
  message: string;
  type: 'success' | 'error' | 'info';
}

@Component({
  selector: 'app-toaster',
  template: `
    <div class="toast-container">
      <ngb-toast
        *ngFor="let toast of toasts; let i = index"
        [class]="getToastClass(toast.type)"
        [autohide]="true"
        [delay]="5000"
        (hidden)="removeToast(i)"
      >
        <div class="d-flex align-items-center">
          <div class="icon-circle mr-2" [ngClass]="getIconCircleClass(toast.type)">
            <i class="fas" [ngClass]="getIconClass(toast.type)"></i>
          </div>
          <div>{{ toast.message }}</div>
        </div>
      </ngb-toast>
    </div>
  `,
  styles: [
    `
      .toast-container {
        position: fixed;
        top: 1rem;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1050;
        width: 350px;
        max-width: 90%;
      }
      .toast-body {
        width: 100%;
      }
      .icon-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 24px;
        min-height: 24px;
        border-radius: 50%;
      }
      ::ng-deep ngb-toast {
        width: 100%;
        margin-bottom: 0.5rem;
      }
      ::ng-deep .toast {
        width: 100%;
      }
      .bg-info {
        background: rgba(0, 34, 68, 1) !important;
        color: white;
      }
      .fa-info {
        background: white !important;
        color: rgba(0, 34, 68, 1) !important;
      }
    `
  ]
})
export class ToasterComponent implements OnInit, OnDestroy {
  toasts: Toast[] = [];
  private subscription: Subscription;

  constructor(private toasterService: ToasterService) {}

  ngOnInit() {
    this.subscription = this.toasterService.toast$.subscribe(toast => {
      if (toast) {
        if (toast.type === 'success') {
          // For success, replace any existing success toast
          this.toasts = this.toasts.filter(t => t.type !== 'success');
        }
        this.toasts.push(toast);
      }
    });
  }

  getToastClass(type: Toast['type']): string {
    switch (type) {
      case 'success':
        return 'bg-success text-light';
      case 'error':
        return 'bg-danger text-light';
      case 'info':
        return 'bg-info text-light';
      default:
        return '';
    }
  }
  getIconCircleClass(type: Toast['type']): string {
    switch (type) {
      case 'success':
        return 'bg-white text-success';
      case 'error':
        return 'bg-white text-danger';
      case 'info':
        return 'bg-white text-info';
      default:
        return '';
    }
  }

  getIconClass(type: Toast['type']): string {
    switch (type) {
      case 'success':
        return 'fa-check';
      case 'error':
        return 'fa-times';
      case 'info':
        return 'fa-info';
      default:
        return '';
    }
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  removeToast(index: number) {
    this.toasts.splice(index, 1);
  }
}
