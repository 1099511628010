<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div class="row">
    <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
      <h2 class="text-cyan m-0" style="cursor:default" translate>{{ titleMapper[tab_status] }}</h2>
      <div class="col top-header-filters">
        <button mat-icon-button *ngIf="show" (click)="refresh()">
          <mat-icon class="refresh">refresh</mat-icon>
        </button>
        <i class="fa fa-spinner fa-spin refresh" style="margin-right: 11px;" *ngIf="spnning"></i>
        <div class="search-people position-relative">
          <div class="input-group w-330">
            <div class="input-group w-330">
              <input
                id="search"
                [(ngModel)]="searchText"
                class="form-control cb-input"
                placeholder="Search"
                name="search"
                type="text"
                (keyup)="updateSearchText($event)"
                (keyDown.enter)="search(searchText)"
                #titleInput
              />
            </div>
            <i (click)="search(titleInput.value)" class="fa fa-search search-icon"></i>
          </div>
        </div>
        <div *ngIf="no_status">
          <select
            style="margin-left: 10px;"
            class="cb-input-1 w-140"
            [(ngModel)]="state_filter"
            (change)="onStatusChange($event.target.value)"
            *ngIf="!status_loading"
            [(ngModel)]="statusFilter"
          >
            <option *ngFor="let status of formStatus; let i = index" [attr.data-index]="i" value="{{ status.key }}">
              {{ status.value }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="row scroll-view" *ngIf="pendingFormList?.length > 0; else no_content">
    <div
      class="col p-0 search-results"
      infiniteScroll
      [scrollWindow]="false"
      (scrolled)="onScroll()"
      [infiniteScrollDistance]="modalScrollDistance"
      [infiniteScrollThrottle]="modalScrollThrottle"
      (scrolledUp)="onScrollUp()"
    >
      <div class="table_wrapper">
        <table style="width:105%" class="w-100p bg-white dm-table table table-responsive">
          <thead>
            <tr style="height: 65px;">
              <th scope="col" class="font-weight-bold text-black-3 text-center" translate>
                Create_Form_Request.Title
              </th>
              <th scope="col" class="font-weight-bold text-black-3" translate>
                Create_Form_Request.Status
              </th>
              <th scope="col" class="font-weight-bold text-black-3 text-center" translate>
                Create_Form_Request.Created_By
              </th>
              <th scope="col" class="font-weight-bold text-black-3 text-center" translate>
                Create_Form_Request.Created_Date
              </th>
              <th scope="col" class="font-weight-bold text-black-3 text-center" translate>
                Create_Form_Request.Vendor
              </th>
              <th scope="col" class="font-weight-bold text-black-3 text-center" translate>
                Create_Form_Request.Actions
              </th>
              <th scope="col" class="font-weight-bold text-black-3 text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center" *ngFor="let pending of pendingFormList; let rowIndex = index">
              <td class="css-b8r45">
                <abbr title="{{ pending['title'] }}">
                  <h4 class="text-black-4 dm-text-1 mt-3 ">{{ pending['title'] }}</h4>
                </abbr>
              </td>
              <td>
                <h4 class="text-black-3 dm-text-2 mt-3">{{ pending['status'] }}</h4>
              </td>
              <td>
                <h4 class="text-black-3 dm-text-2 mt-3">{{ pending['created_by']['name'] }}</h4>
              </td>
              <td>
                <h4 class="text-black-3 dm-text-2 mt-3">{{ pending['created_timestamp'] | date: 'yyyy-MM-dd' }}</h4>
              </td>
              <td>
                <h4 class="text-black-3 dm-text-2 mt-3">{{ pending['vendor'] ? pending['vendor'] : '' }}</h4>
              </td>
              <td>
                <mat-icon class="mt-2" [matMenuTriggerFor]="menu">more_horiz</mat-icon>
                <mat-menu xPosition="before" #menu="matMenu" class="fixed-menu-panel">
                  <ng-container *ngFor="let actions of pending['allowed_actions_list']">
                    <button mat-menu-item (click)="getClickEvents(actions.value, pending)">
                      {{ actions.value }}
                      <mat-icon
                        *ngIf="
                          actions.value == 'Edit Document' ||
                          actions.value == 'View Document' ||
                          actions.value == 'Edit Form Template'
                        "
                        >launch</mat-icon
                      >
                    </button>
                  </ng-container>
                </mat-menu>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #no_content>
  <div class="row scroll-view">
    <div class="col">
      <img class="empty-doc-list-image" src="../../../assets/images/documents/empty.svg" />
      <div class="text-empty-doc-list text-black-1" translate>Create_Form_Request.No_Form</div>
      <div class="sub-text-empty-doc-list " *ngIf="show_add_new_button">
        <a class="cursor-pointer text-cyan-force" (click)="goToCreateForm()" style="font-size: 18px;" translate>
          Create_Form_Request.Add_Form
        </a>
      </div>
    </div>
  </div>
</ng-template>
&nbsp;
<h2 *ngIf="scrollLoader" class="font-weight-bold text-black-3 text-center">
  <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': scrollLoader }" style="font-size:large;"></i> &nbsp; Loading...
</h2>
