import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { NgbActiveModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import stringInfo from '../../../../translations/en-US.json';
import { TranslateService } from '@ngx-translate/core';
import { IncidentService } from '@app/Organisation-admin/incident/incident.service';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';

@Component({
  selector: 'app-revoke-incident-reviewer',
  templateUrl: './revoke-incident-reviewer.component.html',
  styleUrls: ['./revoke-incident-reviewer.component.scss']
})
export class RevokeIncidentReviewerComponent implements OnInit {
  RevokeReviewerForm: FormGroup;
  submitted = false;
  isLoading = false;
  OrganisationID: string | null;
  guidSubscription: any;
  success: boolean;
  error: any;
  loading = true;
  statusData: any;
  revokeReviewerData: any;

  @Input() formType: string;
  @Input() guid: string;
  @Input() title_tag: string;
  @Input() message: string;
  @Input() incidentGuid: string;
  @Input() formGuid: string;
  @Input() type: string;
  backend_error: any;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private documentsService: DocumentsService,
    private translate: TranslateService,
    private incidentService: IncidentService
  ) {}

  ngOnInit() {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getRevokeReviewerForm();
    });
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }
  getRevokeReviewerForm() {
    this.RevokeReviewerForm = this.formBuilder.group({
      comment: ['', Validators.maxLength(4096)]
    });
  }

  get f() {
    return this.RevokeReviewerForm.controls;
  }

  save() {
    this.submitted = true;
    this.isLoading = true;
    // const data = { guid: this.guid };

    if (this.RevokeReviewerForm.valid) {
      const RevokeData = this.RevokeReviewerForm.value;
      RevokeData['guid'] = this.guid;
      this.submitRevokeData(RevokeData);
    } else {
      this.submitted = false;
      this.isLoading = false;
      return;
    }
  }

  changeName(statusData: any) {
    this.incidentService.setStatusforRevokeApprover(statusData);
  }
  changeNameReviewer(reviewerstatusData: any) {
    this.incidentService.setStatusforRevokeReviewer(reviewerstatusData);
  }
  submitRevokeData(RevokeData: any): void {
    (this.type == 'Manage Responders '
      ? this.documentsService.submitRevokeResponderApproverData(this.OrganisationID, this.formGuid, RevokeData)
      : this.type === 'Manage Reviewer'
      ? this.incidentService.submitRevokeReviewerApproverData(this.OrganisationID, this.incidentGuid, RevokeData)
      : this.documentsService.submitRevokeResponderApproverData(this.OrganisationID, this.formGuid, RevokeData)
    )
      .pipe(
        finalize(() => {
          this.RevokeReviewerForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.error = {
            comment: ''
          };
          this.submitted = false;
          this.backend_error = '';
          this.activeModal.close({ result: data, isUpdate: true });
        },
        (error: any) => {
          this.submitted = false;
          this.isLoading = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
  }
}
