<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div>
    <div>
      <div class="publish-doc-label">
        <div class="label">Complete Edit</div>
        <div>
          <i
            class="fa fa-times text-black-1"
            style="width: 12px;
                        height: 12px;
                        right: 1em;
                        cursor: pointer;
                        position: absolute;
                        top: 1.3em;"
            (click)="activeModal.close({ isUpdate: false })"
            ngbAutoFocus
            translate
          ></i>
        </div>
      </div>
    </div>

    <div class="modal-body">
      <div class="text-body">
        <div class="text" [innerHtml]="message"></div>
      </div>

      <form [formGroup]="MarkEditDoneForm" novalidate>
        <div>
          <div>
            <label for="remarks" class="text-black-1 p-2 comment-label" translate
              >Mark_Editing_Done.mark_edit_done.Comments</label
            >
          </div>
          <textarea
            id="remarks"
            class="form-control cb-input"
            [placeholder]="'Mark_Editing_Done.mark_edit_done.Comments' | translate"
            name="remarks"
            type="text"
            formControlName="remarks"
            cols="64"
            rows="5"
            [maxLength]="4097"
          ></textarea>
          <div class=" error-mssg" *ngIf="(f.remarks.touched || f.remarks.dirty) && f.remarks.errors">
            <label for="name" class="text-danger" *ngIf="f.remarks.errors.maxLength" translate
              >Mark_Editing_Done.* Ensure that the comment has no more than 4096 characters.</label
            >
          </div>
        </div>
      </form>
    </div>

    <div class="modal-footer border-0 cb-modal-footer">
      <button
        class="btn-cancel cb-btn-seconday"
        (click)="activeModal.close({ isUpdate: false })"
        ngbAutoFocus
        translate
      >
        Cancel
      </button>
      <button class="btn-save cb-btn-primary" *ngIf="!isLoading" (click)="save()" translate>
        Yes , Mark Editing Done
      </button>
      <button class="btn-save cb-btn-disabled" disabled *ngIf="isLoading">
        <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
      </button>
    </div>
  </div>
</ng-container>
