<label class="form-check-label text-black-1 d-inline mr-3 checkbox-2" for="inlinecheck-{{ program['guid'] }}">
  <input
    class="form-check-input"
    type="checkbox"
    id="inlinecheck-{{ program['guid'] }}"
    [checked]="program['checked']"
    (click)="select_deselect_program()"
  />
  <span class="checkmark"></span>
  {{ program.name }}
</label>
