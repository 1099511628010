<div class="row justify-content-center">
  <div class="signup-container">
    <div class="my-5">
      <div class=" text-center d-flex">
        <div class="fs-17 cursor-pointer w-100p" routerLink="/login">
          <img src="/assets/images/logo/logo.png" class="cb-lite-logo mx-1" alt="logo" srcset="" />
          <span class="font-weight-bold fs-17">ComplianceBot</span>
        </div>
      </div>
      <div class="signup-intro" *ngIf="signupStep == 1 || signupStep == 2">
        <div>
          <div class=" text-center w-100p  mb-2">
            <div class="font-weight-bold create-text">
              Create an Account
            </div>
          </div>
          <div class=" text-center w-100p mb-4">
            <div class="font-weight-light login-text">
              Already Registered? <a class="login-text custom-color" routerLink="/login">Login</a>
            </div>
          </div>
          <div *ngIf="signUpWithGoogleError?.length" class="mb-2" style="line-height: 1 !important;margin-left: 8%;">
            <mat-error *ngIf="signUpWithGoogleError?.length"> *{{ this.signUpWithGoogleError }} </mat-error>
          </div>
          <div class=" text-center w-100p mb-4" *ngIf="signupStep == 1">
            <button class="lite-signup-btn" (click)="openGoogleSignupPage()">
              <div class="d-flex justify-content-center">
                <img height="20px" src="../../../../assets/images/icons/search.png" />
                <div class="lite-signup-btn-text mx-2">
                  Sign up with Google
                </div>
              </div>
            </button>
            <button class="lite-signup-btn mt-2" (click)="setStep(2)">
              <div class="d-flex justify-content-center" style="padding-top: 5px;">
                <mat-icon class="fs-20">alternate_email</mat-icon>
                <div class="lite-signup-btn-text mx-2">
                  Sign up with Email
                </div>
              </div>
            </button>
          </div>

          <div *ngIf="signupStep == 2">
            <form class="example-form" (ngSubmit)="signup()" [formGroup]="signupForm">
              <mat-error class="ml-2 my-2" *ngIf="error?.length">{{ this.error }} </mat-error>
              <div class="text-center w-100p d-flex justify-content-center mb-2">
                <div class="text-left mx-2">
                  <div>
                    <label class="m-0">First Name</label>
                  </div>
                  <mat-form-field appearance="outline">
                    <input
                      type="text"
                      matInput
                      placeholder="John"
                      (keydown.space)="$event.preventDefault()"
                      formControlName="first_name"
                    />
                  </mat-form-field>
                  <ng-container
                    *ngIf="signupForm.controls.first_name.invalid && signupForm.controls.first_name.touched"
                  >
                    <mat-error *ngIf="signupForm.controls.first_name.hasError('required')">
                      *First name is required
                    </mat-error>
                  </ng-container>
                </div>
                <div class="text-left mx-2">
                  <div>
                    <label class="m-0">Last Name</label>
                  </div>
                  <mat-form-field appearance="outline">
                    <input
                      type="text"
                      matInput
                      placeholder="doe"
                      (keydown.space)="$event.preventDefault()"
                      formControlName="last_name"
                    />
                  </mat-form-field>
                  <ng-container *ngIf="signupForm.controls.last_name.invalid && signupForm.controls.last_name.touched">
                    <mat-error *ngIf="signupForm.controls.last_name.hasError('required')">
                      *Last name is required
                    </mat-error>
                  </ng-container>
                </div>
              </div>
              <!-- <div class="d-flex justify-content-center w-100p mb-2">
                <div class="text-left">
                  <div>
                    <label class="m-0">Organisation Name</label>
                  </div>
                  <mat-form-field appearance="outline" class="w-400">
                    <input type="text" matInput placeholder="Ex. Google" formControlName="organization_name" />
                  </mat-form-field>
                  <ng-container
                    *ngIf="
                      signupForm.controls.organization_name.invalid && signupForm.controls.organization_name.touched
                    "
                  >
                    <mat-error *ngIf="signupForm.controls.organization_name.hasError('required')">
                      Organization name is required
                    </mat-error>
                  </ng-container>
                </div>
              </div> -->
              <div class="d-flex justify-content-center w-100p mb-2">
                <div class="text-left">
                  <div>
                    <label class="m-0">Work Email</label>
                  </div>
                  <mat-form-field appearance="outline" class="w-400">
                    <input
                      (keydown.space)="$event.preventDefault()"
                      type="email"
                      matInput
                      (keydown.space)="$event.preventDefault()"
                      placeholder="Ex. pat@example.com"
                      formControlName="organization_admin_email"
                    />
                  </mat-form-field>
                  <ng-container
                    *ngIf="
                      signupForm.controls.organization_admin_email.invalid &&
                      signupForm.controls.organization_admin_email.touched
                    "
                  >
                    <mat-error *ngIf="signupForm.controls.organization_admin_email.hasError('required')">
                      *Email is required
                    </mat-error>
                    <mat-error *ngIf="signupForm.controls.organization_admin_email.hasError('email')">
                      *Please enter a valid email address
                    </mat-error>
                  </ng-container>
                </div>
              </div>
              <div class="d-flex justify-content-center w-100p mb-4">
                <div class="text-left">
                  <div>
                    <label class="m-0">Password</label>
                  </div>
                  <mat-form-field appearance="outline" class="w-400">
                    <input
                      (keydown.space)="$event.preventDefault()"
                      [type]="hide ? 'password' : 'text'"
                      matInput
                      formControlName="password"
                    />
                    <mat-icon class="cursor-pointer pt-2" matSuffix (click)="hide = !hide">{{
                      hide ? 'visibility_off' : 'visibility'
                    }}</mat-icon>
                  </mat-form-field>
                  <ng-container *ngIf="signupForm.controls.password.invalid && signupForm.controls.password.touched">
                    <mat-error *ngIf="signupForm.controls.password.hasError('required')">
                      *Password is required
                    </mat-error>
                    <mat-error class="w-400" translate *ngIf="signupForm.controls.password.errors['invalidPassword']">
                      password_recovery.password_type
                    </mat-error>
                  </ng-container>
                </div>
              </div>
              <div class="row justify-content-center flex-row-reverse  ">
                <button
                  class="auth-btn  ml-2"
                  [disabled]="signupForm.invalid || isLoading"
                  [class.lite-signup-btn]="!signupForm.invalid"
                  [class.signup-login-btn-disabled]="signupForm.invalid || isLoading"
                  (click)="$event.preventDefault(); signup()"
                  (keydown.enter)="$event.preventDefault(); signup()"
                >
                  <span class="lite-signup-btn-text">
                    Sign Up
                  </span>
                </button>
                <button class="auth-btn lite-signup-btn mr-2" (click)="$event.preventDefault(); setStep(1)">
                  <span class="lite-signup-btn-text">
                    Back
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class=" text-center w-100p mb-4" *ngIf="signupStep == 3">
      <div>
        <div class=" text-center w-100p mb-2 signup-intro d-inline-block">
          <div class="text-center w-100p ">
            <img src="/assets/images/email/email-sent.png" class="email-sent-img" />
          </div>
          <div class="email-confirmation-text mt-4">Please confirm your email</div>
          <div class="confirmation-sub-text mt-3">
            We have sent an email to
            <span class="text-bold confirmation-sub-text">{{ this.signupForm.value?.organization_admin_email }}</span>
          </div>
          <div class="confirmation-sub-text mt-4">
            Confirm by clicking the link in email. Don’t forget to check your junk folder.
          </div>
          <div class="confirmation-sub-text mt-4 fs-light">
            Didn’t get the code?
            <button
              (click)="resendPassword()"
              [disabled]="timer > 0"
              [style.cursor]="timer > 0 ? 'not-allowed' : 'pointer'"
              class="custom-color confirmation-sub-text bg-transparent"
            >
              Click to resend
            </button>
          </div>
          <p class=" mt-1  confirmation-sub-text" *ngIf="timer > 0">Resend email in {{ timer }} seconds</p>
        </div>
      </div>
    </div>
  </div>
</div>
<app-spinner *ngIf="loading"></app-spinner>
