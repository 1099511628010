import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingsService } from '@app/settings/settings.service';
import { ModalService } from '@app/core/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { DocumentsService } from '../documents.service';

interface Form {
  id: Number;
  title: String;
  created_by: String;
  status: String;
  last_changed: String;
  due_date: String;
  assigned_users: String;
}

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit {
  loaderArr: any = [];
  accArray: any[] = [];
  loading = true;
  error: any;

  constructor(
    private settingsService: SettingsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private documentsService: DocumentsService,
    private modalService: ModalService,
    private translate: TranslateService,
    private toaster: ToasterService
  ) {}

  ngOnInit(): void {
    this.loading = false;
  }

  // forms1: Form[] = AttentionJson;

  displayStyle = 'none';
  LogdisplayStyle = 'none';

  OpenFormRequest() {
    this.displayStyle = 'block';
  }
  CloseFormRequest() {
    this.displayStyle = 'none';
  }
  clearLoader(value: any): void {
    const index = this.loaderArr.indexOf(value);
    if (index > -1) {
      this.loaderArr.splice(index, 1);
    }
  }

  goToCreateForms() {
    this.router.navigate(['organisation/documents/create-form']);
  }
}
