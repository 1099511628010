import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */

const orgGuidKey = 'org-guid';
const isOrgOnboardedKey = 'is-org-onboarded';
@Injectable()
export class OrganisationService {
  __organisation_guid: BehaviorSubject<string | null>;
  organisation_data: BehaviorSubject<any>;
  isverifyLink = new BehaviorSubject(false);

  organisation_guid: any;
  _isOrganisationOnboarded: boolean | false;
  private _organization_board_check_url = '/api/organizations/';

  constructor(private httpClient: HttpClient) {
    this.__organisation_guid = new BehaviorSubject<string>(null);

    this.organisation_data = new BehaviorSubject<object>({});
    this.organisation_guid = this.__organisation_guid.asObservable();

    const savedOrgGuid = sessionStorage.getItem(orgGuidKey) || localStorage.getItem(orgGuidKey);
    if (savedOrgGuid) {
      this.__organisation_guid.next(savedOrgGuid);
    }
  }

  setSelectedOrganisationGuid(guid: string): BehaviorSubject<string | null> {
    this.__organisation_guid.next(guid);
    this.saveOrgGuid(guid, true);
    return this.__organisation_guid;
  }

  getSelectedOrganisationGuid(): BehaviorSubject<string | null> {
    // Replace by proper authentication call
    return this.__organisation_guid;
  }

  clearOrganisationGuid(): void {
    this.saveOrgGuid();
  }

  setIsOrganisationOnboarded(isOrganisationOnboarded: boolean): boolean {
    localStorage.setItem(isOrgOnboardedKey, String(isOrganisationOnboarded));
    this._isOrganisationOnboarded = isOrganisationOnboarded;
    return this._isOrganisationOnboarded;
  }

  getIsOrganisationOnboarded(): boolean | null {
    if (this._isOrganisationOnboarded !== null) {
      return this._isOrganisationOnboarded;
    } else {
      return null;
    }
  }

  private saveOrgGuid(guid?: string, remember?: boolean) {
    if (guid) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(orgGuidKey, guid);
    } else {
      sessionStorage.removeItem(guid);
      localStorage.removeItem(guid);
    }
  }

  getHealthcheckcall() {
    return this.httpClient.get(`/api/healthcheck/`, {
      withCredentials: true
    });
  }
  updateStoreDetails(org_uuid: string, payload = {}) {
    return this.httpClient.post(`/api/organizations/${org_uuid}/update_store_details/`, payload, {
      withCredentials: true
    });
  }
  updateRefreshToken(org_uuid: string, payload = {}) {
    return this.httpClient.post(`/api/organizations/${org_uuid}/generate_refresh_token/`, payload, {
      withCredentials: true
    });
  }

  getDriveConfig(org_uuid: string) {
    return this.httpClient.get(`/api/organizations/${org_uuid}/drive_config/`, {
      withCredentials: true
    });
  }
  getCertificateDetails(orgGuid: string) {
    return this.httpClient.get(
      `/api/organizations/${orgGuid}/pnp-api/onboard-generate-documents/pnp_documents_details/`,
      {
        withCredentials: true
      }
    );
  }
  getPnpDocumens() {
    return this.httpClient.get('https://run.mocky.io/v3/9713060b-de10-4935-9a18-451f75acba75');
  }

  async generatePdfWithOnlineImages(htmlId: string[], pdfName: string) {
    await this.generatePdf(htmlId, pdfName);
  }
  async generatePdf(htmlIds: string[], pdfName: string) {
    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgWidth = 208;
    const pageHeight = 295;
    let currentPageHeight = 10;
    for (const htmlId of htmlIds) {
      const data = document.getElementById(htmlId);
      if (data) {
        const canvas = await html2canvas(data);
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const contentDataURL = canvas.toDataURL('image/png');
        if (currentPageHeight + imgHeight > pageHeight) {
          pdf.addPage();
          currentPageHeight = 10;
        }
        const marginLeft = 2;
        const position = currentPageHeight;
        pdf.addImage(contentDataURL, 'PNG', marginLeft, position, imgWidth - marginLeft, imgHeight);
        currentPageHeight += imgHeight;
      }
    }
    pdf.save(pdfName + '.pdf');
  }
  getProgramLiteSummary(org_guid: string) {
    return this.httpClient.get(`/api/organizations/${org_guid}/onboarding/program_unit_lite/summary/`, {
      withCredentials: true
    });
  }
  restartOnboarding(org_uuid: string, payload = {}) {
    return this.httpClient.post(`/api/organizations/${org_uuid}/reset_program/`, payload, {
      withCredentials: true
    });
  }
}
