<!--<div style="display: none">-->
<!--<h3>Invite Committee Members</h3>-->
<!--<p>-->
<!--The following committees need to be formed based on the compliance programs you have chosen to implement. Please use-->
<!--this screen to invite members of your compliance team for the various committees.-->
<!--</p>-->
<!--<div class="row">-->
<!--<table mat-table [dataSource]="data" class="mat-elevation-z8" style="width: 100%;margin-top: 50px;">-->
<!--<ng-container matColumnDef="addMembers">-->
<!--<th mat-header-cell *matHeaderCellDef></th>-->
<!--<td mat-cell *matCellDef="let row; let i = index" style="width: 160px">-->
<!--<span (click)="addRemoveMembers(row, i)" class="fake-link">Add/Remove Members</span>-->
<!--</td>-->
<!--</ng-container>-->

<!--<ng-container matColumnDef="committee_name">-->
<!--<th mat-header-cell *matHeaderCellDef>Committee Name</th>-->
<!--<td mat-cell *matCellDef="let element">{{ element.committee_name }}</td>-->
<!--</ng-container>-->

<!--<ng-container matColumnDef="program_name">-->
<!--<th mat-header-cell *matHeaderCellDef>Program</th>-->
<!--<td mat-cell *matCellDef="let element">{{ element.program_name }}</td>-->
<!--</ng-container>-->

<!--<ng-container matColumnDef="members">-->
<!--<th mat-header-cell *matHeaderCellDef>Members</th>-->
<!--<td mat-cell *matCellDef="let element">-->
<!--&lt;!&ndash;<div class="row" style="width: 400px;">&ndash;&gt;-->

<!--<div class="flex-row">-->
<!--<div class="flex-content-start" style="display: inline-block;">-->
<!--<div *ngFor="let member of element.members">{{ member }}</div>-->
<!--</div>-->
<!--</div>-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--</td>-->
<!--</ng-container>-->

<!--&lt;!&ndash;<ng-container matColumnDef="members">&ndash;&gt;-->
<!--&lt;!&ndash;<th mat-header-cell *matHeaderCellDef> Members</th>&ndash;&gt;-->
<!--&lt;!&ndash;<td mat-cell *matCellDef="let row">&ndash;&gt;-->
<!--&lt;!&ndash;<span  (click)="addMembers(row)" class="fake-link">Add/Invite Members</span>&ndash;&gt;-->
<!--&lt;!&ndash;</td>&ndash;&gt;-->
<!--&lt;!&ndash;</ng-container>&ndash;&gt;-->

<!--<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>-->
<!--<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>-->
<!--</table>-->
<!--</div>-->

<!--&lt;!&ndash;<div class="row" style="margin-top: 10%;">&ndash;&gt;-->
<!--&lt;!&ndash;<div class="offset-5 col-md-4">&ndash;&gt;-->
<!--&lt;!&ndash;<button mat-button matStepperPrevious style="display: inline-block;">prev</button>&ndash;&gt;-->
<!--&lt;!&ndash;<button mat-button (click)="submitForm()" style="display: inline-block;">next</button>&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--</div>-->

<div class="onboarding-main pt-4" *ngIf="show">
  <div class="container max-w-840">
    <div class="row">
      <div class="col">
        <h1 class="text-black-2 mb-3 font-weight-bold" translate>
          organisation_onboard.committee_members_page.Invite Committee Members
        </h1>
        <p class="text-black-2 mb-5" translate>
          organisation_onboard.committee_members_page.committee_member_details
        </p>
        <table class="w-100p table-2">
          <thead class="bg-yellow-2 ">
            <tr class="border-0">
              <td>
                <h4 class="font-weight-bold text-black-2 p-3 mt-2" translate>
                  organisation_onboard.committee_members_page.Committee Name
                </h4>
              </td>
              <td>
                <h4 class="font-weight-bold text-black-2 p-3 mt-2" translate>
                  organisation_onboard.committee_members_page.Program
                </h4>
              </td>
              <td>
                <h4 class="font-weight-bold text-black-2 p-3 mt-2" translate>
                  organisation_onboard.committee_members_page.Members
                </h4>
              </td>
              <td><h4 class="font-weight-bold text-black-2 p-3 mt-2"></h4></td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let committee of data; let i = index">
              <td>
                <h4 class="text-black-1 p-3 pr-3 w-240">{{ committee.committee_name }}</h4>
              </td>
              <td>
                <h4 class="text-black-1 p-3 pr-3 ">{{ committee.program_name }}</h4>
              </td>
              <td>
                <h4 class="text-black-1 p-3">
                  <ng-container *ngFor="let member of committee.members; let last = last">
                    {{ member }}<ng-container *ngIf="!last">, </ng-container>
                  </ng-container>
                </h4>
              </td>
              <td (click)="addRemoveMembers(committee, i)">
                <a class="text-black-1 p-3 pr-3 link-1"
                  ><i class="fas fa-pencil-alt text-gray pr-2"></i
                  ><u translate>organisation_onboard.committee_members_page.Manage</u></a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
