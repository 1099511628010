import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SignupRoutingModule } from './signup-routing.module';
import { SignUpComponent } from './signup.component';
import { SharedModule } from '@app/shared';
import { DemoMaterialModule } from '../materials.module';
import { NgxSelectModule } from 'ngx-select-ex';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxSelectModule,
    FormsModule,
    TranslateModule,
    SignupRoutingModule,
    SharedModule,
    DemoMaterialModule
  ],
  declarations: [SignUpComponent]
})
export class SignupModule {}
