<div id="mySidenav" class="sidenav">
  <div class="sidenav-header">
    <span class="title"> {{ subFolderName ? 'Create Subfolder' : 'Create Folder' }}</span>
    <i class="fa fa-times text-black-1 closebtn" (click)="closeNav()" ngbAutoFocus translate></i>
  </div>
  <div class="row mt-2" *ngIf="backend_error">
    <div class="col-12 col-sm-12 col-lg-12 ml-5">
      <div class="text-danger" translate>* {{ backend_error }}</div>
    </div>
  </div>
  <form [formGroup]="folderForm" class="sidenav-body form">
    <div *ngIf="subFolderName" class="d-flex flex-column">
      <label for="folder" class="label">Folder</label>
      <label for="folderName" class="subfolder-name">{{ subFolderName }}</label>
    </div>
    <div class="form-group">
      <label for="folderName" class="label">Folder Name</label>
      <input
        type="text"
        id="folderName"
        formControlName="title"
        class=" form-control cb-input-focus"
        placeholder="Enter folder name"
        [maxLength]="256"
        (keyup)="onChange($event)"
        required
      />
      <div *ngIf="(f.title.touched || f.title.dirty) && f.title.errors" class="text-danger">
        <label *ngIf="f.title.errors.required">*Title is required.</label>
        <label *ngIf="f.title.errors.maxLength">*Title must be less than 255 characters.</label>
      </div>
    </div>
    <div class="form-group">
      <label for="folderDescription" class="label">Folder Description</label>
      <textarea
        id="folderDescription"
        formControlName="description"
        class=" form-control cb-input-focus"
        placeholder="Description"
        [maxLength]="4097"
        required
      ></textarea>
      <div *ngIf="(f.description.touched || f.description.dirty) && f.description.errors" class="text-danger">
        <label *ngIf="f.description.errors.required">*Description is required.</label>
        <label *ngIf="f.description.errors.maxLength">*Description must be less than 4096 characters.</label>
      </div>
    </div>
  </form>
  <div class="sidenav-footer d-flex">
    <button
      type="submit"
      class="btn-save cb-btn-primary"
      [ngClass]="{ 'btn-disabled cb-btn-disabled': folderForm.invalid }"
      [disabled]="folderForm.invalid"
      *ngIf="!isLoading"
      (click)="createfolderForm()"
    >
      Save
    </button>
    <button type="submit" class="btn-save cb-btn-disabled" disabled *ngIf="isLoading">
      <i class="fa fa-spinner fa-spin"></i>
    </button>
    <button type="button" class="px-2 cb-btn-secondary" (click)="closeNav()">Cancel</button>
  </div>
</div>
