import { Inject, NgModule } from '@angular/core';

import { VersionRoutingModule } from './version-routing.module';

import { VersionComponent } from '@app/version/version.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { VersionService } from '@app/version/version.service';
import { EventScheduleDialogComponent } from '@app/Organisation-admin/EventsSchedule/eventsSchedule.component';
import { OrganisationService } from '@app/core/organisation.service';

@NgModule({
  imports: [CommonModule, VersionRoutingModule],
  declarations: [VersionComponent],
  providers: [VersionService]
})
export class VersionModule {}
