import { Component, OnInit } from '@angular/core';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { ModalService } from '@app/core/modal/modal.service';
import { OrganisationService } from '@app/core/organisation.service';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { UserService } from '@app/core/user.service';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '@app/settings/settings.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-invite-members',
  templateUrl: './invite-members.component.html',
  styleUrls: ['./invite-members.component.scss']
})
export class InviteMembersComponent implements OnInit {
  members: any[] = [];
  invitedCollaborators: any[] = [];
  userEmail: string;
  guidSubscription: Subscription;
  orgPasswordBasedLogin: boolean = true;
  domainName: string = '';
  OrganisationID: string | null;

  //
  isLoading: boolean = false;
  isInviting: boolean = false;
  error: any;

  //
  UsersList: object[];
  backend_error: string;
  form: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private settingsService: SettingsService,
    private orgService: OrganisationService,
    private toaster: ToasterService,
    private user: UserService,
    private translate: TranslateService,
    private backendErrorService: BackendErrorService,
    private modalService: ModalService,
    private fb: FormBuilder
  ) {
    this.orgPasswordBasedLogin = localStorage.getItem('org_password_based_login') == 'true' ? true : false;
    this.getDomainName(localStorage.getItem('domain'));
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
    this.userEmail = this.user.getuserdetails().user['email'];
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
    });
    this.isLoading = true;
    this.getUserList();
  }

  submitOrganisationAddProfile(email: any): void {
    let payload = {
      email: email
    };
    this.isInviting = true;
    this.settingsService
      .submitOrganisationAddPeopleProfile(this.OrganisationID, payload)
      .pipe(
        finalize(() => {
          this.isInviting = false;
          this.getUserList();
        })
      )
      .subscribe(
        (data: any) => {
          this.isInviting = false;
          this.translate.get('Invitation sent successfully!').subscribe(res => {
            this.toaster.showSuccess(res);
          });
          this.form.reset();
        },
        (error: any) => {
          this.error = error.error;
          this.translate.get(error.error).subscribe(res => {
            this.toaster.showError(res);
          });
        }
      );
  }

  getUserList(): void {
    this.settingsService.getOrganizationPeopleList(this.OrganisationID).subscribe(
      data => {
        this.UsersList = data.data;
        this.invitedCollaborators = this.UsersList;
        this.isLoading = false;
      },
      error => {
        this.backendErrorService.setBackendError(error);
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
        });
      }
    );
  }

  openConfirm(email: string, userGuid?: string) {
    this.modalService
      .confirm('Do you want to remove ' + email + '?')
      .pipe(take(1))
      .subscribe((confirmed: any) => {
        if (confirmed) {
          this.removeUser(userGuid);
        }
      });
  }

  removeUser(guid: string): void {
    this.settingsService.deleteOrganizationPeopleUser(guid, this.OrganisationID).subscribe(
      data => {
        this.getUserList();
        this.translate.get('organisation_people.people_toaster_message.remove_people').subscribe(res => {
          this.toaster.showSuccess(res);
        });
      },
      error => {
        this.error = error.error.message;
        this.translate.get('organisation_people.people_toaster_message.error_people').subscribe(res => {
          this.toaster.showError(res);
        });
        console.log('Error:', error.error.message);
      }
    );
  }

  getDomainName(url: string) {
    const domainRegex = /^(?:https?:\/\/)?(?:www\.)?([^/?]+).*/;
    const match = url?.match(domainRegex);
    this.domainName = match ? match[1] : null;
  }

  invite() {
    if (!Array.isArray(this.members)) {
      this.members = [];
    }

    const emailInput = document.getElementById('emailInput') as HTMLInputElement;
    const email = emailInput.value.trim();

    if (email) {
      const firstName = email.split('@')[0];
      const capitalizedFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
      this.members.push({ email: email, first_name: capitalizedFirstName, last_name: '' });

      emailInput.value = '';
    }
  }

  getInitials(email: string): string {
    const emailInitial = email.charAt(0);
    return emailInitial;
  }
}
