<div class="row mt-5">
  <div class="col-6">
    <div class="background-container">
      <div class="success-container">
        <img src="/assets/images/cb-lite/success-image.png" alt="Overlay Image" class="success-image" />
        <div class="text-overlay">
          <div class="succes-title">
            Congratulations!
          </div>
          <div class="success-subtitle">
            You have successfully completed the onboarding process with your policies and procedures and now have a
            HIPAA program in place for your organization.
          </div>
          <div class="success-button">
            <button type="button" class="lite-login-btn mr-2" routerLink="/organisation/dashboard/">
              Continue to Dashboard
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-6 mt-5">
    <div class="d-flex justify-content-center align-items-center ">
      <div class="certificate-container" id="contentToConvert">
        <div class="container">
          <div class="row">
            <div class="w-100p text-center">
              <img src="/assets/images/cb-lite/stamp.png" class="stamp" alt="no-img" />
            </div>
          </div>
          <div class="row mt-3 justify-content-center">
            <div class="cert-title">Certificate of Compliance</div>
          </div>
          <hr />
          <div class="m-3" style="min-height: 366px;">
            <div class="row text-left">
              <div class="cert-subtitle">
                This is to certify that <b>{{ certificateData?.org_name }}</b> has successfully completed risk
                assessment and creation of policies and procedures in line with <b>45 CFR § 164.308(a)(1)(i).</b>
              </div>
            </div>
            <div class="row text-left mt-3">
              <div class="cert-subtitle">
                The following list of policy and procedure documents was reviewed and approved by
                <b>{{ certificateData?.org_name }}</b> management on <b>{{ certificateData?.date }}</b>
              </div>
            </div>
            <div class="row mt-3" id="listContainer">
              <div class="col-md-6">
                <ul class="category-style d-flex flex-column" id="firstColumn"></ul>
              </div>
              <div class="col-md-6">
                <ul class="category-style d-flex flex-column" id="secondColumn"></ul>
              </div>
            </div>
            <hr />
            <div class="d-flex justify-content-around align-items-center sign-container">
              <ng-container *ngIf="isSignatureLoading; else show">
                <h2 class="font-weight-bold mt-2 text-black-3 text-center">
                  <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': isSignatureLoading }" style="font-size:large;"></i>
                  &nbsp;
                </h2>
              </ng-container>
              <ng-template #show>
                <div class="fs-17 cursor-pointer w-100p">
                  <img src="/assets/images/logo/logo.png" class="cb-lite-logo mx-1" alt="logo" srcset="" />
                  <span class="font-weight-bold fs-17">ComplianceBot</span>
                </div>
                <img
                  id="onlineImage"
                  class="user-avatar-pic"
                  [src]="UserSignature"
                  height="70"
                  width="130px"
                  style="max-height: 100px; max-width: 130px;"
                  alt="no-image"
                />
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div [ngStyle]="{ 'margin-top': isSignatureLoading ? '9%' : '4%' }" class="text-center w-100p">
      <button
        [disabled]="isSignatureLoading || isDownloading"
        class="auth-btn lite-signup-btn mr-2"
        (click)="generatePdfWithOnlineImages()"
      >
        <span *ngIf="!isDownloading && !isSignatureLoading" class="lite-signup-btn-text">
          Download Certificate
        </span>
        <span>
          <i
            *ngIf="isDownloading || isSignatureLoading"
            class="fa"
            [ngClass]="{ 'fa-spin fa-spinner': isDownloading || isSignatureLoading }"
            style="font-size:large;"
          ></i>
          &nbsp;
        </span>
      </button>
    </div>
  </div>
</div>
