<div class="row lead-generated-header">
  <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
    <h2 class="text-cyan m-0" translate>GRC DATA</h2>
  </div>
</div>
<div class="row scroll-view lead-generated-section">
  <div class="col" *ngIf="grcList !== null; else other_content">
    <div
      class="cb-accordian mx-auto wm-1000 mt-4"
      *ngFor="let lead of grcList; let i = index"
      [ngClass]="!accArray.includes(i) ? '' : 'expand'"
      [attr.data-index]="i"
    >
      <div class="acc-header d-flex justify-content-between">
        <h5 class="text-black-1">{{ lead['name'] }}</h5>
        <div class="d-flx align-items-center d-flex-row">
          <button class="ml-1 mr-3 btn-acc-toggle" (click)="toggleDetails(i, lead)">
            <i
              [ngClass]="!accArray.includes(i) ? 'fas fa-chevron-down text-black-2' : 'fas fa-chevron-up text-black-2'"
            ></i>
          </button>
        </div>
      </div>
      <div class="acc-body px-3">
        <table class="w-100p table-2">
          <thead>
            <tr>
              <td>
                <h4 class="font-weight-bold text-black-2 p-3" translate>Is GRC enabled?</h4>
              </td>
              <td><h4 class="font-weight-bold text-black-2 p-3" translate>Email</h4></td>
              <td>
                <h4 class="font-weight-bold text-black-2 p-3" translate>Action</h4>
              </td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <h4 class="text-black-1 p-3 pr-3 w-240">
                  {{ grcEnabled?.is_grc }}
                  <button class="btn-save" (click)="toggleGrcFlagForOrg(lead, !grcEnabled?.is_grc)" translate>
                    {{ grcEnabled?.is_grc ? 'Disable' : 'Enable' }}
                  </button>
                </h4>
              </td>
              <td>
                <h4 class="text-black-1 p-3">
                  {{ lead['admin_email'] !== null ? lead['admin_email'] : '-' }}
                </h4>
              </td>
              <td>
                <!-- <div class="row mt-3"> -->
                <!-- <div class="col-6">
                    <button class="btn-cancel"  ngbAutoFocus translate>
                      Cancel
                    </button>
                  </div> -->
                <button style="width: 180px;" (click)="upDateGrcMasterDataForOrg(lead.guid)" class="btn-save" translate>
                  Update GRC master data
                </button>
                <!-- </div> -->
              </td>
              <td>
                <!-- <a
                    (click)="openConfirm(members['full_name'], committee['org_review_com_guid'], members['email'])"
                    class="text-black-1 p-3 pr-3 link-1 cursor-pointer"
                    ><u translate>organisation_committee.Remove</u></a
                  > -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <ng-template #other_content class="no-org-selected">
    <app-no-organisation-selected
      class="no-lead-section"
      [typeMessage]="parentTypeMessage"
    ></app-no-organisation-selected>
  </ng-template>
</div>
