<app-spinner *ngIf="loading"></app-spinner>
<div class="modal-content-width">
  <ng-container *ngIf="!loading">
    <div *ngIf="trainees?.length > 0; else no_content">
      <div class="modal-header cb-modal-header">
        <div>
          <h4 class="modal-title font-weight-bold text-black-1" translate>{{ title_tag }}</h4>
          <i
            class="fa fa-times text-black-1"
            style="width: 12px;
              height: 12px;
              right: 1em;
              cursor: pointer;
              position: absolute;
              top: 1.3em;"
            (click)="activeModal.close(false)"
            ngbAutoFocus
            translate
          ></i>
        </div>
      </div>
      <div class="modal-body">
        <!-- <div class="send-invite" *ngIf="istraineesWithPending">
          <div
            style="padding-left: 12px; font-size: 14px; color:#425266;
        ;"
          >
            Send out bulk reminders to all participants who are yet to attend training
          </div>
          <div style="margin-right: 1rem;">
            <button class="btn-reinvite" *ngIf="showReInviteAllButton" (click)="reInviteAllTrainee()">Re-invite</button>
            <button type="button" [disabled]="true" class="btn-reminder" *ngIf="!showReInviteAllButton">
              Try again in 00:{{ timer < 10 ? '0' + timer : timer }}
            </button>
          </div>
        </div> -->
        <div style="padding-left: 10px; padding-right: 10px;">
          <table class="table table-borderless">
            <thead>
              <tr>
                <th scope="col-6">
                  <h4 class="modal-title font-weight-bold text-black-1" translate>view_trainees.first_column</h4>
                </th>
                <th scope="col-2">
                  <h4 class="modal-title font-weight-bold text-black-1" translate>view_trainees.second_column</h4>
                </th>
                <th scope="col-2">
                  <h4 class="modal-title font-weight-bold text-black-1" translate></h4>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let trainee of trainees; let i = index">
                <td>
                  <div class="row align-items-center">
                    <div class="avatar col-md-auto avatar-bg-{{ trainee.user.name[0] }}">
                      {{ getInitials(trainee.user.name) }}
                    </div>
                    <div class="col text-black-1">{{ trainee.user.name }}</div>
                  </div>
                </td>
                <td>
                  <h4 class="text-black-1 capitalize data-align" translate>{{ trainee.status_description }}</h4>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer border-0 cb-modal-footer">
        <button class="btn-cancel ml-4 w-100" (click)="activeModal.close(false)" ngbAutoFocus translate>
          view_trainees.close
        </button>
      </div>
    </div>
  </ng-container>

  <ng-template #no_content>
    <div class="modal-body">
      <h2 class="modal-title font-weight-bold text-black-1 p-5 text-center" translate>
        view_trainees.not_found
      </h2>
    </div>
    <div class="modal-footer border-0 cb-modal-footer">
      <button class="btn-cancel ml-4 w-100" (click)="activeModal.close(false)" ngbAutoFocus translate>
        view_trainees.close
      </button>
    </div>
  </ng-template>
</div>
