<!--<mat-card style="width: 70%; margin: 0 auto; margin-top: 100px;display: none;">-->
<!--<mat-card-header style="margin-top: 20px;">-->
<!--<mat-card-title><h4>ComplianceBot Setup Wizard</h4></mat-card-title>-->
<!--</mat-card-header>-->
<!--<mat-divider style="margin-top: 20px;"></mat-divider>-->

<!--<mat-card-content style="margin-top: 50px;">-->
<!--<mat-horizontal-stepper labelPosition="bottom" #stepper>-->
<!--&lt;!&ndash;<mat-step *ngIf="showSelectOrgStep">&ndash;&gt;-->
<!--&lt;!&ndash;<ng-template matStepLabel>Select Organisation</ng-template>&ndash;&gt;-->
<!--&lt;!&ndash;<app-select-org-step [stepper]="stepper" #selectorg></app-select-org-step>&ndash;&gt;-->
<!--&lt;!&ndash;<mat-card-actions style="margin-top: 10%">&ndash;&gt;-->
<!--&lt;!&ndash;<button mat-button (click)="selectorg.submitForm()" class="wizard-action-button">Next</button>&ndash;&gt;-->
<!--&lt;!&ndash;</mat-card-actions>&ndash;&gt;-->
<!--&lt;!&ndash;</mat-step>&ndash;&gt;-->

<!--<mat-step>-->
<!--<ng-template matStepLabel>Connect Slack</ng-template>-->
<!--<app-connect-slack-step #connectSlackStep></app-connect-slack-step>-->
<!--<mat-card-actions style="margin-top: 10%">-->
<!--<button mat-button (click)="connectSlackStep.submitForm()" class="wizard-action-button">Next</button>-->
<!--</mat-card-actions>-->
<!--</mat-step>-->

<!--<mat-step>-->
<!--<ng-template matStepLabel>Select program</ng-template>-->
<!--<app-select-program-step #selectProgramStep></app-select-program-step>-->
<!--<mat-card-actions style="margin-top: 10%">-->
<!--<button mat-button matStepperPrevious class="wizard-action-button">Previous</button>-->
<!--<button mat-button (click)="selectProgramStep.submitForm()" class="wizard-action-button">Next</button>-->
<!--</mat-card-actions>-->
<!--</mat-step>-->

<!--<mat-step>-->
<!--<ng-template matStepLabel>Review schedule</ng-template>-->
<!--<app-review-schedule-step #reviewScheduleStep> </app-review-schedule-step>-->
<!--<mat-card-actions style="margin-top: 10%">-->
<!--<button mat-button matStepperPrevious class="wizard-action-button">Previous</button>-->
<!--<button mat-button (click)="reviewScheduleStep.submitForm()" class="wizard-action-button">Next</button>-->
<!--</mat-card-actions>-->
<!--</mat-step>-->

<!--<mat-step>-->
<!--<ng-template matStepLabel>Invite committee members</ng-template>-->
<!--<app-invite-committee-members-step #inviteCommitteeMembersStep></app-invite-committee-members-step>-->
<!--<mat-card-actions style="margin-top: 10%">-->
<!--<button mat-button matStepperPrevious class="wizard-action-button">Previous</button>-->
<!--<button mat-button (click)="inviteCommitteeMembersStep.submitForm()" class="wizard-action-button">-->
<!--Next-->
<!--</button>-->
<!--</mat-card-actions>-->
<!--</mat-step>-->

<!--<mat-step>-->
<!--<ng-template matStepLabel>Create backend automation</ng-template>-->
<!--<app-create-backendautomation-step #createBackendautomationStep></app-create-backendautomation-step>-->
<!--<mat-card-actions style="margin-top: 10%">-->
<!--<button mat-button matStepperPrevious class="wizard-action-button">Previous</button>-->
<!--<button mat-button (click)="createBackendautomationStep.next_step()" class="wizard-action-button">-->
<!--Next-->
<!--</button>-->
<!--</mat-card-actions>-->
<!--</mat-step>-->

<!--<mat-step>-->
<!--<ng-template matStepLabel>Finish and Run</ng-template>-->
<!--<app-review-run-finish-step #reviewRunFinishStep></app-review-run-finish-step>-->
<!--<mat-card-actions style="margin-top: 10%">-->
<!--<button mat-button matStepperPrevious class="wizard-action-button">Previous</button>-->
<!--<button mat-button (click)="reviewRunFinishStep.submitForm()" class="wizard-action-button">-->
<!--Run Selected-->
<!--</button>-->
<!--<button mat-button class="wizard-action-button" (click)="finishClicked()">Finish</button>-->
<!--</mat-card-actions>-->
<!--</mat-step>-->
<!--</mat-horizontal-stepper>-->
<!--</mat-card-content>-->
<!--</mat-card>-->

<app-onboard-header [activeStep]="step"></app-onboard-header>
<app-connect-slack-step [show]="step == 1" #connectSlackStep></app-connect-slack-step>
<app-select-program-step [show]="step == 2" #selectProgramStep></app-select-program-step>
<app-review-schedule-step [show]="step == 3" #reviewScheduleStep> </app-review-schedule-step>
<app-invite-committee-members-step [show]="step == 4" #inviteCommitteeMembersStep></app-invite-committee-members-step>
<app-create-backendautomation-step [show]="step == 5" #createBackendautomationStep></app-create-backendautomation-step>
<app-review-run-finish-step [show]="step == 6" #reviewRunFinishStep></app-review-run-finish-step>
<app-onboard7-final [show]="step == 7" #finalStep></app-onboard7-final>
<app-onboard-footer
  [activeStep]="step"
  (goToPrevious)="previous_step()"
  (goToNext)="next_step()"
  (goToFinish)="finishClicked()"
  (runSelected)="runSelected()"
></app-onboard-footer>
