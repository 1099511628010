<app-spinner *ngIf="isloading"></app-spinner>
<div class="row">
  <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
    <h2 class="text-cyan m-0" style="cursor:default" translate>
      {{ formGuid ? 'Create_Form_Request.Edit_Form_Request' : 'Create_Form_Request.Create_Form_Request' }}
    </h2>
    <div>
      <button
        *ngIf="!formGuid"
        class="btn-cancel ml-4 w-100"
        [ngClass]="{ 'btn-cancel': true }"
        (click)="onReset()"
        translate
      >
        Create_Form_Request.clear
      </button>
      <button
        class="btn-save ml-4 w-100"
        [disabled]="addFormRequest.invalid || !templateName || addFormRequest.pristine"
        [ngStyle]="{
          'background-color':
            addFormRequest.invalid || !templateName || addFormRequest.pristine ? 'rgba(0,0,0,.26)' : '#4db7c6'
        }"
        [ngClass]="{ 'btn-save': true }"
        (click)="saveForm()"
        translate
      >
        Create_Form_Request.save
      </button>
    </div>
  </div>
</div>
<div class="row mt-2" *ngIf="backend_error">
  <div class="col-12 col-sm-12 col-lg-12 ml-5">
    <div class="text-danger" translate>* {{ backend_error }}</div>
  </div>
</div>
<form [formGroup]="addFormRequest" novalidate autocomplete="off">
  <div class="col">
    <div class="row mt-5">
      <div class="col-2 col-sm-2 col-lg-2">
        <label for="title" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate
          >Create_Form_Request.Title <span class="custom-text-color">*</span></label
        >
      </div>

      <div class="input-group col-9 col-sm-9 col-lg-9">
        <input
          id="title"
          class="form-control cb-input"
          formControlName="title"
          [placeholder]="'Form Title'"
          autocomplete="title"
          required
          name="title"
          type="text"
          style="height: 40px;"
          (keyup)="onChangeTitle($event)"
          (keydown.enter)="$event.preventDefault()"
          [maxLength]="256"
          required
        />
        <div
          class="col-12 error-mssg"
          *ngIf="
            (addFormRequest.controls.title.touched || addFormRequest.controls.title.dirty) &&
            addFormRequest.controls.title.errors
          "
        >
          <label for="title" class="text-danger" *ngIf="addFormRequest.controls.title.errors.required" translate
            >Create_Form_Request.*Form Title is Required</label
          >
          <label for="title" class="text-danger" *ngIf="addFormRequest.controls.title.errors.maxLength" translate
            >Create_Form_Request.Title must be 255 characters or less</label
          >
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-2 col-sm-2 col-lg-2">
        <label for="description" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate
          >Create_Form_Request.Purpose <span class="custom-text-color">*</span></label
        >
      </div>
      <div class="input-group col-9 col-sm-9 col-lg-9">
        <textarea
          id="description"
          class="form-control cb-input"
          formControlName="description"
          [placeholder]="'Purpose'"
          name="description"
          type="text"
          cols="64"
          rows="5"
          [maxLength]="4097"
          required
        ></textarea>
        <div
          class="col-12 error-mssg"
          *ngIf="
            (addFormRequest.controls.description.touched || addFormRequest.controls.description.dirty) &&
            addFormRequest.controls.description.errors
          "
        >
          <label
            for="description"
            class="text-danger"
            *ngIf="addFormRequest.controls.description.errors.required"
            translate
            >Create_Form_Request.*Form purpose is Required</label
          >
          <label
            for="description"
            class="text-danger"
            *ngIf="addFormRequest.controls.description.errors.maxLength"
            translate
            >Create_Form_Request.*Purpose must be 4096 characters or less</label
          >
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-2 col-sm-2 col-lg-2">
        <label for="email_message" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate
          >Create_Form_Request.Welcome_email
        </label>
        <mat-icon> email</mat-icon>
      </div>
      <div class="input-group col-9 col-sm-9 col-lg-9">
        <textarea
          id="email_message"
          class="form-control cb-input"
          [placeholder]="'Welcome email message'"
          name="email_message"
          type="text"
          formControlName="email_message"
          [maxLength]="4097"
          cols="64"
          rows="5"
        ></textarea>
        <div
          class="col-12 error-mssg"
          *ngIf="
            (addFormRequest.controls.email_message.touched || addFormRequest.controls.email_message.dirty) &&
            addFormRequest.controls.email_message.errors
          "
        >
          <label
            for="email_message"
            class="text-danger"
            *ngIf="addFormRequest.controls.email_message.errors.maxLength"
            translate
            >Create_Form_Request.*Purpose must be 4096 characters or less</label
          >
        </div>
      </div>
    </div>

    <ng-container *ngIf="!formGuid; else normalLink">
      <div class="row mt-6">
        <div class="col-2 col-sm-2 col-lg-2">
          <label for="title" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate
            >Create_Form_Request.Template<span class="custom-text-color">*</span></label
          >
        </div>
        <div class="input-group col-8 col-sm-3 col-lg-3">
          <button
            class="cb-cancel-button text-black-1"
            (click)="openSelectTemplateForm()"
            style="border: solid 1px #d6dce2; border-radius: 5px; font-size: 12px; font-weight: normal;"
            translate
          >
            Create_Document.Sel_template
          </button>
          <div class="col-12 error-mssg" *ngIf="submitted && !templateName">
            <label for="folder" class="text-danger" translate>* {{ templateErrorMessage }}</label>
          </div>
          <p
            class="col-12 error-mssg text-black-3"
            *ngIf="templateName"
            style="font-size: 14px; line-height: 18px; font-weight: 400;padding-top: 0.7em;"
            translate
          >
            Create_Document.Selected_template <strong> {{ templateName }}</strong>
          </p>
        </div>
      </div>
    </ng-container>

    <label
      for="groups"
      class="text-cyan cursor-pointer"
      (click)="toggleVendorDetails()"
      translate
      style="font-size: 14px;margin-top: 43px;
    margin-left: 18px;"
    >
      Create_Form_Request.Add_Vendor
    </label>

    <div *ngIf="showVendorDetails">
      <div class="row mt-4">
        <div class="col-2 col-sm-2 col-lg-2">
          <label for="reported_by" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate
            >Create_Form_Request.Vendor_Name</label
          >
        </div>
        <div class="input-group col-3 col-sm-3 col-lg-3">
          <input
            id="vendor_name"
            class="form-control cb-input"
            formControlName="vendor"
            [placeholder]="'Vendor Name'"
            autocomplete="vendor_name"
            name="vendor_name"
            type="text"
            style="height: 40px;"
            (keydown.enter)="$event.preventDefault()"
            [maxLength]="256"
          />
          <div
            class="col-12 error-mssg"
            *ngIf="
              (addFormRequest.controls.vendor.touched || addFormRequest.controls.vendor.dirty) &&
              addFormRequest.controls.title.errors
            "
          >
            <label
              for="vendor_name"
              class="text-danger"
              *ngIf="addFormRequest.controls.vendor.errors.maxLength"
              translate
              >Create_Form_Request.Vendor name must be 255 characters or less</label
            >
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-2 col-sm-2 col-lg-2">
          <label for="website" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate
            >Create_Form_Request.Website</label
          >
        </div>
        <div class="input-group col-3 col-sm-3 col-lg-3">
          <input
            id="website"
            class="form-control cb-input"
            [placeholder]="'Website'"
            formControlName="vendor_website"
            autocomplete="vendor_website"
            name="vendor_website"
            type="text"
            style="height: 40px;"
            (keydown.enter)="$event.preventDefault()"
            [maxLength]="256"
          />
          <div
            class="col-12 error-mssg"
            *ngIf="
              (addFormRequest.controls.vendor_website.touched || addFormRequest.controls.vendor_website.dirty) &&
              addFormRequest.controls.vendor_website.errors
            "
          >
            <label
              for="vendor_website"
              class="text-danger"
              *ngIf="addFormRequest.controls.vendor_website.errors.maxLength"
              translate
              >Create_Form_Request.Website must be 255 characters or less</label
            >
            <div
              class="error-mssg "
              *ngIf="addFormRequest.get('vendor_website').touched && addFormRequest.get('vendor_website').errors"
            >
              <label for="url" class="text-danger" *ngIf="addFormRequest.get('vendor_website').errors.url">
                *{{ addFormRequest.get('vendor_website').errors.url.msg }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-2 col-sm-2 col-lg-2">
          <label for="vendor_address" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate
            >Create_Form_Request.Address</label
          >
        </div>
        <div class="input-group col-9 col-sm-9 col-lg-9">
          <input
            id="vendor_address"
            class="form-control cb-input"
            [placeholder]="'Address'"
            formControlName="vendor_address"
            autocomplete="vendor_address"
            name="vendor_address"
            type="text"
            style="height: 40px;"
            col="64"
            row="10"
            (keyup)="onChangeTitle($event)"
            (keydown.enter)="$event.preventDefault()"
            [maxLength]="256"
          />
          <div
            class="col-12 error-mssg"
            *ngIf="
              (addFormRequest.controls.vendor_address.touched || addFormRequest.controls.vendor_address.dirty) &&
              addFormRequest.controls.vendor_address.errors
            "
          >
            <label
              for="vendor_address"
              class="text-danger"
              *ngIf="addFormRequest.controls.vendor_address.errors.maxLength"
              translate
              >Create_Form_Request.Address must be 255 characters or less</label
            >
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-2 col-sm-2 col-lg-2">
          <label for="vendor_contact" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate
            >Create_Form_Request.Point_Of_Contact
          </label>
        </div>
        <div class="input-group col-3 col-sm-3 col-lg-3">
          <input
            id="vendor_contact"
            class="form-control cb-input"
            [placeholder]="'Phone number'"
            formControlName="vendor_contact"
            autocomplete="vendor_contact"
            name="vendor_contact"
            type="text"
            style="height: 40px;"
            (keydown.enter)="$event.preventDefault()"
            [maxLength]="256"
          />
          <div
            class="col-12 error-mssg"
            *ngIf="addFormRequest.get('vendor_contact').touched && addFormRequest.get('vendor_contact').errors"
          >
            <label for="url" class="text-danger" *ngIf="addFormRequest.get('vendor_contact').errors" translate>
              Create_Form_Request.Please enter a valid phone number
            </label>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-2 col-sm-2 col-lg-2">
          <label for="vendor_contact" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate
            >Create_Form_Request.Email
          </label>
        </div>
        <div class="input-group col-3 col-sm-3 col-lg-3">
          <input
            id="vendor_email"
            class="form-control cb-input"
            [placeholder]="'Email'"
            formControlName="vendor_email"
            autocomplete="vendor_email"
            name="vendor_email"
            type="text"
            style="height: 40px;"
            (keydown.enter)="$event.preventDefault()"
            [maxLength]="256"
          />
          <div
            class="col-12 error-mssg"
            *ngIf="addFormRequest.get('vendor_email').touched && addFormRequest.get('vendor_email').errors"
          >
            <label for="vendor_email" class="text-danger" *ngIf="addFormRequest.get('vendor_email').errors" translate>
              Create_Form_Request.Please enter a valid email address
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #normalLink>
    <div class="row mt-6">
      <div class="col-2 col-sm-2 col-lg-2">
        <label for="title" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate>Edit_Incident.Template</label>
      </div>
      <a
        class=" pt-2 mt-1 cb-label"
        style="text-decoration:underline;cursor: pointer;margin-left: 220px;"
        target="_blank"
        [href]="formData?.source_template_document_store_identifier"
        >{{ templateName }}
      </a>
    </div>
  </ng-template>
</form>
