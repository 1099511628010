import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, ChildActivationEnd, NavigationEnd, NavigationStart, Params, Router } from '@angular/router';
import { OrganisationService } from '@app/core/organisation.service';
import { UserService } from '@app/core/user.service';
import 'rxjs-compat/add/operator/take';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-org-admin-shell-sidenav',
  templateUrl: './orgAdminshellSidenav.component.html',
  styleUrls: ['./orgAdminshellSidenav.component.scss']
})
export class OrgAdminshellSidenavComponent implements OnInit, OnChanges {
  currentURL: any;
  guidSubscription: any;
  organisationID: string;
  orgSelect = true;
  closeSideNav = false;
  message: string;
  userType: any;

  @Input() sidenavChildMessage: string;
  setUserDetails: any;
  showDashboardComponent: boolean;
  showComponent: boolean;
  showDocumentComponent: boolean;
  showOnBoardComponent = false;
  showIncidentComponent = false;
  showFormComponent = false;
  showTasksComponent = false;
  fullPath: any;
  sidnavClass = '';
  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private orgService: OrganisationService,
    private user: UserService,
    private activatedRoute: ActivatedRoute
  ) {
    this.router.events.pipe(filter((event: any) => event instanceof NavigationStart)).subscribe((event: any) => {
      const parts = event.url.split('/');
      this.currentURL = parts[parts.length - 1]; // Or parts.pop();
    });
    const url = window.location.href.replace(/\/$/, ''); /* remove optional end / */
    this.currentURL = url.substr(url.lastIndexOf('/') + 1);
    let params = this.activatedRoute.snapshot;
    while (params.firstChild) {
      params = params.firstChild;
    }
    this.fullPath = params.routeConfig.path;
    console.log(this.fullPath);
  }

  ngOnInit() {
    this.setUserDetails = this.user.getuserdetails();
    if (this.setUserDetails.user.role.includes('organization_admin_role')) {
      this.showDashboardComponent = true;
    } else {
      this.showDashboardComponent = false;
    }
    if (this.setUserDetails.user.role.includes('organization_admin_role')) {
      this.showOnBoardComponent = true;
      this.showTasksComponent = true;
    } else {
      this.showOnBoardComponent = false;
      this.showTasksComponent = false;
    }

    if (
      this.setUserDetails.user.role.includes('organization_admin_role') ||
      this.setUserDetails.user.role.includes('document_author') ||
      this.setUserDetails.user.role.includes('document_editor') ||
      this.setUserDetails.user.role.includes('document_approver') ||
      this.setUserDetails.user.role.includes('organization_user_role') ||
      this.setUserDetails.user.role.includes('document_reader')
    ) {
      this.showDocumentComponent = true;
    }

    if (
      this.setUserDetails.user.role.includes('organization_admin_role') ||
      this.setUserDetails.user.role.includes('incident_coordinator') ||
      this.setUserDetails.user.role.includes('incident_operator') ||
      this.setUserDetails.user.role.includes('incident_reviewer')
    ) {
      this.showIncidentComponent = true;
    }

    if (
      this.setUserDetails.user.role.includes('organization_admin_role') ||
      this.setUserDetails.user.role.includes('form_responder') ||
      this.setUserDetails.user.role.includes('form_requester') ||
      this.setUserDetails.user.role.includes('form_reader') ||
      this.setUserDetails.user.role.includes('form_approver')
    ) {
      this.showFormComponent = true;
    }

    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.organisationID = guid;
      this.checkOrganisationId();
    });
    this.userType = this.user.getUserRole();
  }
  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.closeSideNav = false;
  }

  closeSidenav(): void {
    this.closeSideNav = true;
  }

  checkOrganisationId(): void {
    if (this.organisationID !== null) {
      this.orgSelect = true;
    } else {
      this.orgSelect = false;
    }
  }
}
