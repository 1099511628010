<ng-container *ngIf="!show_edit_form">
  <div class="row mb-2">
    <div class="col-lg-4">
      <h5 class="text-black-2 font-weight-bold" translate>event_details.Review Event Start Message:</h5>
    </div>
    <div class="col-lg-8">
      <h5 class="text-black-1">{{ event_start_message }}</h5>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-lg-4">
      <h5 class="text-black-2 font-weight-bold" translate>event_details.Review Event End Message:</h5>
    </div>
    <div class="col-lg-8">
      <h5 class="text-black-1">{{ event_end_message }}</h5>
    </div>
  </div>

  <ng-container *ngIf="!isLoading && document_urls.length > 0">
    <div class="row mb-2 mt-3">
      <div class="col-lg-12">
        <h5 class="text-black-2 font-weight-bold mb-0" translate>event_details.Review Documents:</h5>
      </div>
      <div class="col-12 doc-url-div">
        <div class="row show-doc-url-row " *ngFor="let link of document_urls">
          <div class="text-black-1 review-doc-title align-items-center d-flex">
            <img class="mx-1" src="../../../assets/images/logo/{{ link.urltype }}.png" width="16px" height="16px" />
            <a
              class="d-flex align-items-center text-decoration-none"
              target="_blank"
              href="{{ link.url }}"
              style="font-size: 16px;"
              >{{ link.title }}
              <mat-icon style="font-size: 14px;width: 16px;height:16px; " class="mx-1">launch</mat-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="col text-right doc-url-edit-button" *ngIf="is_editable">
    <button class="btn-add ml-4 w-80" *ngIf="is_editable" (click)="show_edit_form = !show_edit_form">
      <i class="fas fa-pencil-alt btn-edit" aria-hidden="true"></i> Edit
    </button>
  </div>
</ng-container>

<ng-container *ngIf="show_edit_form">
  <form [formGroup]="eventMessageForm" novalidate *ngIf="showForm">
    <div class="row mb-2">
      <div class="col-4">
        <h5 class="text-black-2 font-weight-bold" translate>event_details.Review Event Start Message:</h5>
      </div>
      <div class="col-8">
        <div class="row">
          <textarea
            class="form-control cb-input col-10"
            formControlName="event_start_message"
            placeholder="{{ defaultStartMessage }}"
            [(ngModel)]="event_data['event_start_message']"
            type="text"
            name="event_start_message"
            id="event_start_message"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-4">
        <h5 class="text-black-2 font-weight-bold" translate>event_details.Review Event End Message:</h5>
      </div>
      <div class="col-8">
        <div class="row">
          <textarea
            class="form-control cb-input col-10"
            formControlName="event_end_message"
            placeholder="{{ defaultEndMessage }}"
            [(ngModel)]="event_data['event_end_message']"
            type="text"
            name="event_end_message"
            id="event_end_message"
          ></textarea>
        </div>
      </div>
    </div>

    <div *ngIf="show_doc_urls">
      <div class="row">
        <div class="col-lg-4">
          <h5 class="text-black-2 font-weight-bold" translate>event_details.Review Documents:</h5>
        </div>
        <div class="col-lg-8" *ngIf="!this.eventMessageForm.get('document_urls_FA').controls.length">
          <h5 class="text-black-1" translate>event_details.No documents found</h5>
        </div>

        <div class="col-12">
          <div class="row" *ngIf="this.eventMessageForm.get('document_urls_FA').controls.length > 0">
            <div class="col-1"></div>
            <div class="col-5">
              <label class="text-black-1" translate>event_details.Title</label>
            </div>
            <div class="col-5">
              <label class="text-black-1" translate>event_details.URL</label>
            </div>
          </div>
          <div
            formArrayName="document_urls_FA"
            *ngFor="let document_url of eventMessageForm.get('document_urls_FA').controls; let i = index"
          >
            <div [formGroupName]="i" class="row">
              <div class="col-1">
                <img
                  src="../../../assets/images/logo/{{ event_data.document_urls[i].urltype }}.png"
                  width="20px"
                  *ngIf="event_data.document_urls[i].urltype"
                />
              </div>
              <div class="col-5">
                <input
                  class="cb-input col-12"
                  formControlName="title"
                  placeholder="Please enter a review document title."
                  [(ngModel)]="event_data.document_urls[i].title"
                />
                <ng-container
                  *ngIf="
                    eventMessageForm.get('document_urls_FA').controls[i].controls.title.invalid &&
                    (eventMessageForm.get('document_urls_FA').controls[i].controls.title.touched || form_submitted)
                  "
                >
                  <div
                    *ngIf="eventMessageForm.get('document_urls_FA').controls[i].controls.title.errors['title']"
                  ></div>
                  <span class="error-danger" translate>
                    event_details.Please enter a valid title.
                  </span>
                </ng-container>
              </div>
              <div class="col-5">
                <input
                  class="cb-input col-12"
                  formControlName="url"
                  placeholder="Please enter the document url."
                  [(ngModel)]="event_data.document_urls[i].url"
                  (keyup)="setDocumentUrlType(i)"
                />
                <ng-container
                  *ngIf="
                    eventMessageForm.get('document_urls_FA').controls[i].controls.url.invalid &&
                    (eventMessageForm.get('document_urls_FA').controls[i].controls.url.touched || form_submitted)
                  "
                >
                  <div *ngIf="eventMessageForm.get('document_urls_FA').controls[i].controls.url.errors['url']"></div>
                  <span class="error-danger" translate>
                    event_details.Please enter a valid url. Example: https://www.compliancebot.io
                  </span>
                </ng-container>
              </div>
              <div class="col-1" (click)="remove_document_url(i)">
                <i class="fa fa-trash  btn-edit" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="row mb-2">
    <div class="col-3">
      <p class="text-cyan mb-0 add-url add-document-url" (click)="add_document_url()" translate>
        event_details.+ Add URL
      </p>
    </div>
  </div>

  <div class="success-msg" *ngIf="success">
    {{ this.success }}
  </div>
  <div class="error-danger" *ngIf="error">
    {{ this.error }}
  </div>

  <div class="row mb-2 mt-4">
    <div class="col text-right">
      <button class="btn-cancel ml-4 w-80" (click)="cancel()" translate>event_details.Cancel</button>
      <button class="btn-add ml-4 w-80" (click)="reset()" translate>event_details.Reset</button>
      <button class="btn-save ml-4 w-80" (click)="updateMessage()" translate>event_details.Update</button>
    </div>
  </div>
</ng-container>
