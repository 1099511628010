<app-cb-lite-header [disabledFinish]="disabledFinish"></app-cb-lite-header>
<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div class="cblite-body">
    <div class="d-flex">
      <div class="tables-side" style="height: 69vh;">
        <ng-container *ngFor="let tableData of pnpControlData; let i = index">
          <div class="fixTableHead">
            <table>
              <thead data-qa="categoryHeader">
                <tr>
                  <th>
                    <div class="header-wrapper">
                      <div class="document-header">
                        <abbr title="{{ tableData.header }}">{{ tableData.header }}</abbr>
                      </div>
                      <div style="display: flex;height: 30px;">
                        <div class="category-custom-border">
                          <div class="category-progress-bar">
                            <div
                              class="progress"
                              [style.width.%]="(isGeneratedCategoryCtrlsCount(tableData) / tableData.rows.length) * 100"
                            ></div>
                          </div>
                          <div class="d-flex justify-content-between pr-3 pb-2">
                            <div
                              class="category-progress-label"
                              style="color: rgb(134,134,134);font-size: 14px;margin-top: 2px;"
                            >
                              {{ isGeneratedCategoryCtrls(tableData) ? 'Completed' : 'In Progress' }} |
                              {{ isGeneratedCategoryCtrlsCount(tableData) }}/{{ tableData?.rows?.length }}
                            </div>
                          </div>
                        </div>
                        <mat-icon
                          [ngStyle]="{ color: isGeneratedCategoryCtrls(tableData) ? '#2a647c' : 'rgb(210,210,210)' }"
                          class="check-circle-icon"
                          >check_circle</mat-icon
                        >
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody class="scrollable-tbody" id="{{ 'ctrl-header-' + i }}">
                <tr
                  (click)="onMenuClicked(row)"
                  id="{{ 'ctrl-' + i }}"
                  *ngFor="let row of tableData.rows; let i = index"
                >
                  <abbr title="{{ row.title }}">
                    <td
                      style="display: flex;justify-content: space-between;"
                      [ngStyle]="{ 'background-color': currentlyExpandedCtrl === row.code ? '#f3f3f3' : '' }"
                      class="table-cell"
                    >
                      <div [ngStyle]="{ color: isGeneratedCtrl(row.code) ? '' : 'rgb(115,128,142)' }">
                        {{ row.title }}
                      </div>
                      <span style="margin-right: 5px;margin-left: 3px;">
                        <mat-icon class="done" *ngIf="isGeneratedCtrl(row.code)">done</mat-icon>
                        <mat-spinner
                          *ngIf="!isGeneratedCtrl(row.code)"
                          class="progress-spinner-row"
                          [diameter]="18"
                          [color]="'accent'"
                        ></mat-spinner>
                      </span>
                    </td>
                  </abbr>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
      </div>

      <div class="panels-side" #panelsSide>
        <div class="custom-border" style="background-color: rgba(243, 255, 255, 1);border-radius: 4px;">
          <div class="d-flex align-items-center">
            <div>
              <img
                *ngIf="documentProgress != totalDocument"
                style="width: 20px;height: 24px;"
                class="ml-2 mr-2"
                src="./../../../assets/images/core/document_icon.svg"
                height="15px"
              />
            </div>
            <div class="flex-grow-1">
              <div class="d-flex justify-content-between pr-3 pb-2">
                <div class="policy-header">
                  Documents Generated &nbsp;&nbsp;&nbsp;&nbsp;{{ documentProgress }}/{{ totalDocument }} |
                  {{ documentProgress / totalDocument | percent: '1.2-2' }} complete
                </div>
                <div class="document-lenght"></div>
              </div>
              <div class="progress-bar" style="height: 16px !important;">
                <div class="progress" [style.width.%]="progressPercentage"></div>
              </div>
            </div>
          </div>
          <div class="progress-note mt-3 ml-2">
            <ng-container *ngIf="(documentProgress / totalDocument) * 100 != 100">
              {{
                (documentProgress / totalDocument) * 100 > 90
                  ? 'Hang tight!! We are almost done....'
                  : 'Your compliance documents are being created. This process can take anywhere from 5-20 mins'
              }}
            </ng-container>
          </div>
        </div>

        <div class="custom-scroll mt-3">
          <ng-container *ngFor="let tableData of pnpControlData; let i = index">
            <mat-accordion *ngFor="let row of tableData.rows">
              <mat-expansion-panel
                class="custom-accordion"
                [expanded]="currentlyExpandedCtrl === row.code"
                (opened)="onPanelOpened(row)"
              >
                <mat-expansion-panel-header>
                  <div class="header-content" id="{{ row.id }}">
                    <div class="left-title">
                      <mat-panel-title>
                        {{ row.title }}
                      </mat-panel-title>
                    </div>
                  </div>
                </mat-expansion-panel-header>
                <div class="div-container">
                  <div class="policy-border">
                    <div>
                      <div class="label">
                        <div>
                          Policy text
                          <img
                            style="margin-top: -1px !important;"
                            class="ml-1"
                            *ngIf="row.isRestorePolicyDisabled"
                            src="./../../../assets/images/core/ai-generated.svg"
                            height="14px"
                          />
                        </div>
                        <div class="edit-icons">
                          <button
                            class="edit-btn"
                            mat-stroked-button
                            *ngIf="!row.isPolicyEditable && row.policy_text"
                            (click)="openDialog(row, 'isPolicyEditable')"
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                      <div class="value">
                        <ng-container
                          ><div class="policy-text" *ngIf="row.policy_text">
                            {{ row.policy_text }}
                          </div>
                          <div class="item" *ngIf="!row.policy_text">
                            <ng-container *ngFor="let item of items; let i = index">
                              <ngx-skeleton-loader
                                [theme]="{ width: item + '%', height: '10px' }"
                                [appearance]="'line'"
                              ></ngx-skeleton-loader>
                            </ng-container>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <div class="label">
                        <div>
                          Procedure text
                          <img
                            style="margin-top: -1px !important;"
                            class="ml-1"
                            *ngIf="row.isRestoreProcedureDisabled"
                            src="./../../../assets/images/core/ai-generated.svg"
                            height="14px"
                          />
                        </div>
                        <div class="edit-icons">
                          <button
                            class="edit-btn"
                            mat-stroked-button
                            *ngIf="!row.isProcedureEditable && row.procedure_text"
                            (click)="openDialog(row, 'isProcedureEditable')"
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                      <div class="value">
                        <ng-container>
                          <div class="policy-text" *ngIf="row.procedure_text">
                            {{ row.procedure_text }}
                          </div>

                          <div class="item" *ngIf="!row.procedure_text">
                            <ng-container *ngFor="let item of items; let i = index">
                              <ngx-skeleton-loader
                                [theme]="{ width: item + '%', height: '10px' }"
                                [appearance]="'line'"
                              ></ngx-skeleton-loader>
                            </ng-container>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
