import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '../documents.service';
import { ModalService } from '@app/core/modal/modal.service';
import { take, filter } from 'rxjs/operators';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { TranslateService } from '@ngx-translate/core';
import stringInfo from '../../../../translations/en-US.json';

export interface Document {
  guid: string;
  name: string;
  groups: Group[];
}

export interface Group {
  guid: string;
  name: string;
  trainees: Trainee[];
  completion: string;
}

export interface TrainingCampaign {
  title: string;
  status: string;
  start_date: string;
  end_date: string;
  created_by: number;
  trainees: Trainee[];
  training_campaign_materials: TrainingCampaignMaterial[];
}

export interface Trainee {
  email: string;
  name: string;
  documents: TrainingCampaignMaterial[];
}

export interface TrainingCampaignMaterial {
  type: string;
  document: TrainingMaterial;
  status: string;
  trainees: Trainee[];
  external_link: string | null;
  summary: TrainingMaterialSummary;
}

export interface TrainingMaterial {
  title: string;
  version: string;
  guid: string;
  type: string;
}

export interface TrainingMaterialStatus {
  guid: string;
  status: string;
  assigned_date: string;
  start_date: string | null;
  completion_date: string;
  user: User;
  completion_remark: string;
}

export interface TrainingMaterialSummary {
  total_trainees: number;
  yet_to_start: number;
  in_progress: number;
  completed: number;
}

export interface User {
  email: string;
  name: string;
}
export interface Response {
  title: string;
  status: string;
  description: string;
  groups: Group[];
  documents: Document[];
}

@Component({
  selector: 'app-training-report-page',
  templateUrl: './training-report-page.component.html',
  styleUrls: ['./training-report-page.component.scss']
})
export class TrainingReportPageComponent implements OnInit {
  data: any;
  trainees: Trainee[];
  documents: TrainingCampaignMaterial[];
  training_report: any = stringInfo.training_report;
  description: string;
  guid: any;
  guidSubscription: any;
  OrganisationID: string | null;
  error: any;
  details: any;
  version: any = [];
  title: any;
  status: any;
  document_version: any;
  loading = true;
  workspace: any;
  Select_recurring_renewal: any;
  Select_schedule: any;
  Allowed_duration: any;
  week_of_month: any;
  Month: any;
  day_of_week: any;
  time: any;
  isAtleastOneInviteButtonEnable: boolean = false;
  showReInviteButton: boolean = true;
  showReInviteAllButton: boolean = true;
  timer: number = 59;
  interval: any;
  constructor(
    public route: ActivatedRoute,
    private orgService: OrganisationService,
    private documentsService: DocumentsService,
    private modalService: ModalService,
    private toaster: ToasterService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.document_version = this.route.snapshot.params['document_version'];
    if (this.document_version) {
      this.document_version = this.route.snapshot.params['document_version'];
      this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
        this.OrganisationID = guid;
      });
      this.getTrainingReport(this.OrganisationID, this.document_version);
    }
  }

  getInitials(name: string): string {
    let initials = '-';
    if (!!name) {
      let fullName = name.split(' ');
      if (fullName.length === 1) {
        return fullName[0][0].toUpperCase();
      }
      initials = `${fullName[0][0].toUpperCase()}${!!fullName[1][0] ? fullName[1][0].toUpperCase() : ''}`;
    }
    return initials;
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  getTrainingReport(OrganisationID: any, guid: any): void {
    this.documentsService.getTrainingReport(OrganisationID, guid).subscribe(
      data => {
        this.loading = false;
        this.data = data;
        console.log(' this.data', this.data);
        this.trainees = data.trainees;
        this.trainees.forEach((item: any) => {
          item['isShowReinvite'] = true;
          item['timer'] = 59;
        });
        this.trainees.forEach((trainee: any) => {
          trainee['isCompletedOrPending'] = trainee.documents?.find(
            (document: any) => document?.status == 'completed' || document?.status == 'in_progress'
          );
          if (!trainee['isCompletedOrPending']) {
            this.isAtleastOneInviteButtonEnable = true;
          }
        });
        console.log(this.trainees);
        this.documents = data.training_campaign_materials;
        this.description = data.description;
        this.title = data.title;
        this.status = data.status_description;
        if (data.renewal_schedule.length != '0') {
          this.Select_recurring_renewal = data.renewal_schedule[0].renewal_string;
        }
      },
      error => {
        this.error = error.error.message;
        this.loading = false;
        console.log('Error:', error.error.message);
      }
    );
  }
  checkAccess() {
    return (
      JSON.parse(localStorage.getItem('credentials')).user.role.find(
        (role: any) => role == 'organization_admin_role'
      ) || JSON.parse(localStorage.getItem('credentials')).user.email == this.data.created_by
    );
  }

  filterDocumentsByType(status: string, documents: TrainingCampaignMaterial[]) {
    if (status.toLowerCase() === 'assigned') {
      return documents;
    }
    return documents.filter(doc => doc.status === status);
  }

  viewDocuments(action: string, column: string, email: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Documents',
        modalType: 'VDocuments',
        column: column,
        due_date: this.data.end_date,
        documentGuid: email,
        documents: this.filterDocumentsByType(action, this.trainees.find(trainee => trainee.email === email).documents)
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
        } else {
          console.log('Cancelled.');
        }
      });
  }

  viewTrainees(guid: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Trainees',
        modalType: 'Trainees',
        documentGuid: guid,
        trainees: this.documents.find(doc => doc.document.guid === guid).trainees,
        trainingGuid: this.document_version,
        userData: this.trainees
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
        } else {
          console.log('Cancelled.');
        }
      });
  }
  reInviteAllTrainee() {
    this.interval = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        this.timer = 59;
        clearInterval(this.interval);
      }
    }, 1000);
    this.showReInviteAllButton = false;
    // this.showReInviteButton = false;
    const traineesWithYetToStart = this.data.trainees.filter((trainee: any) =>
      trainee.documents.every((doc: any) => doc.status === 'yet_to_start')
    );
    const orgGuids = traineesWithYetToStart.map((trainee: any) => trainee.org_guid);
    const payload = {
      organization_users: orgGuids
    };
    this.trainingReInvitation(payload);
    this.trainees.forEach((trainee: any) => this.reInviteTimer(trainee));
  }
  reInviteTrainee(guid: string, trainee: any) {
    this.showReInviteButton = false;
    const payload = {
      organization_users: [guid]
    };
    this.reInviteTimer(trainee);
    this.trainingReInvitation(payload);
  }
  reInviteTimer(trainee: any) {
    trainee.isShowReinvite = false;
    let interval = setInterval(() => {
      if (trainee.timer > 0) {
        trainee.timer--;
      } else {
        trainee.timer = 59;
        trainee.isShowReinvite = true;
        this.showReInviteAllButton = true;
        clearInterval(interval);
      }
    }, 1000);
  }
  trainingReInvitation(payload: any) {
    this.documentsService.resendTrainingInvitation(this.OrganisationID, this.document_version, payload).subscribe(
      response => {
        this.translate.get('Invitation sent successfully !').subscribe(res => {
          this.toaster.showSuccess(res);
        });
      },
      (error: any) => {
        this.loading = false;
        // this.backendErrorService.setBackendError(error);
        // this.backendErrorService.backendError$.subscribe(error => {
        //   this.backend_error = error;
        // });
      }
    );
  }
}
