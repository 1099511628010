import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { finalize, take } from 'rxjs/operators';
import { OrganisationService } from '@app/core/organisation.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { IncidentService } from '@app/Organisation-admin/incident/incident.service';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
@Component({
  selector: 'app-create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.scss']
})
export class CreateCategoryComponent implements OnInit {
  @Input() title_tag: string;

  createCategoryForm: FormGroup;
  submitted = false;
  isLoading = false;
  OrganisationID: string | null;
  guidSubscription: any;
  error = {
    name: '',
    description: ''
  };
  success: boolean;
  backend_error: string;

  constructor(
    private backendErrorService: BackendErrorService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private orgService: OrganisationService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private incidentService: IncidentService
  ) {}

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  ngOnInit() {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getCreateCategoryForm();
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.createCategoryForm.controls;
  }
  onChangeTitle(evt: any) {
    if (evt.type == 'keyup' && this.backend_error) {
      this.backend_error = '';
    }
  }

  save() {
    this.submitted = true;
    if (this.createCategoryForm.valid) {
      this.isLoading = true;
      const createCategoryData = this.createCategoryForm.value;
      const data = {
        description: this.createCategoryForm.value.description,
        title: this.createCategoryForm.value.name
      };

      if (this.OrganisationID != null) {
        this.incidentService
          .createIncidentCategory(this.OrganisationID, data)
          .pipe(
            finalize(() => {
              this.createCategoryForm.markAsPristine();
              this.isLoading = false;
            })
          )
          .subscribe(
            (data: any) => {
              this.success = true;
              this.error = {
                name: '',
                description: ''
              };
              this.submitted = false;
              this.translate.get('Category.category_toaster_message.category_created').subscribe(res => {
                this.toaster.showSuccess(res);
              });
              this.activeModal.close(true);
              this.backend_error = '';
            },
            (error: any) => {
              this.isLoading = false;
              this.submitted = false;
              this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
              this.backendErrorService.backendError$.subscribe(error => {
                this.backend_error = error;
              });
            }
          );
      }
    } else {
      return;
    }
  }

  getCreateCategoryForm() {
    this.createCategoryForm = this.formBuilder.group({
      name: ['', [Validators.required(), Validators.maxLength(255)]],
      description: ['', [Validators.required(), Validators.maxLength(4096)]]
    });
  }
}
