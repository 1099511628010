import { Component, OnInit, Input, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '@app/core';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { finalize, take } from 'rxjs/operators';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { OrganisationService } from '@app/core/organisation.service';
import { SettingsService } from '@app/settings/settings.service';
import { ModalService } from '@app/core/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';

@Component({
  selector: 'app-add-committee',
  templateUrl: './addCommittee.component.html',
  styleUrls: ['./addCommittee.component.scss']
})
export class AddCommitteeComponent implements OnInit, OnDestroy {
  @Input() title_tag: string;
  @Input() form_data: [];
  newlyAddedMembersInCommittee: any[] = [];
  addCommitteeForm: FormGroup;
  submitted = false;
  isLoading = false;
  OrganisationID: string | null;
  guidSubscription: any;
  error = {
    name: '',
    description: ''
  };
  success: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private orgService: OrganisationService,
    private settingsService: SettingsService,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private translate: TranslateService,
    private toaster: ToasterService
  ) {}

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  ngOnInit() {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getAddCommitteeForm();
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.addCommitteeForm.controls;
  }

  removeMemberFromAddNewCommitteeForm(email: string): void {
    this.newlyAddedMembersInCommittee.splice(this.newlyAddedMembersInCommittee.indexOf(email), 1);
  }

  save() {
    this.submitted = true;
    if (this.addCommitteeForm.valid) {
      this.isLoading = true;
      const committeeMemberData = this.addCommitteeForm.value;
      committeeMemberData['members_email_list'] = this.newlyAddedMembersInCommittee.map((str, index) => ({
        email: str
      }));
      this.settingsService
        .submitOrganisationNewCommittee(this.OrganisationID, committeeMemberData)
        .pipe(
          finalize(() => {
            this.addCommitteeForm.markAsPristine();
            this.isLoading = false;
          })
        )
        .subscribe(
          (data: any) => {
            this.success = true;
            this.error = {
              name: '',
              description: ''
            };
            this.submitted = false;
            this.activeModal.close(true);
          },
          (error: any) => {
            this.error = error.error;
          }
        );
    } else {
      return;
    }
  }

  addCommitteeParticipants() {
    this.modalService
      .custom(
        CustomDialogComponent,
        {
          title: 'Add Committee',
          modalType: 'committee-members',
          newCommittee: true
        },
        { windowClass: 'add-admin' }
      )
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.newlyAddedMembersInCommittee = result;
        }
      });
  }

  getAddCommitteeForm() {
    this.addCommitteeForm = this.formBuilder.group({
      name: ['', [Validators.required(), Validators.maxLength(255)]],
      description: ['', [Validators.required(), Validators.maxLength(4096)]]
    });
  }
}
