<div id="mySidenav" class="sidenav">
  <div class="sidenav-header">
    <span class="title"> {{ role == 'approver' ? 'Edit Approver' : 'Edit Editor' }}</span>
    <i class="fa fa-times text-black-1 closebtn" (click)="closeNav()" ngbAutoFocus translate></i>
  </div>
  <div class="row mt-2" *ngIf="backend_error">
    <div class="col-sm-12 col-lg-12 ml-5">
      <div class="text-danger" translate>* {{ backend_error }}</div>
    </div>
  </div>
  <form [formGroup]="editEditorApproverForm" class="sidenav-body">
    <div class="form-group">
      <label for="Editors" class="label">
        {{ role == 'approver' ? 'Approvers' : 'Editors' }}
      </label>
      <div class="d-flex cursor-default">
        <span class="initials" [style.backgroundColor]="getColor(user)">
          {{ getInitials(user) }}
        </span>
        <div>
          <div class="username">
            {{ user }}
          </div>
          <div class="email">{{ userEmail }}</div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="duedates" class="label">Due Date</label>
      <div class="position-relative">
        <input
          class=" form-control cb-input-focus pr-5"
          id="due_date"
          placeholder="Select due date"
          formControlName="due_date"
          name="due_date"
          ngbDatepicker
          (click)="d.toggle()"
          [minDate]="minDate"
          required
          #d="ngbDatepicker"
        />
        <img src="../../../assets/images/core/calender.svg" class="calendar-icon" (click)="d.toggle()" />
      </div>
      <div class="error-mssg" *ngIf="f.due_date.touched && f.due_date.errors">
        <label for="due_date" class="text-danger" *ngIf="f.due_date.errors.required" translate>
          Document_Invite_Editor.invite_editor.*Due Date is required.
        </label>
        <label for="due_date" class="text-danger" *ngIf="f.due_date.errors.DateTodayOrAfter_error" translate>
          Document_Invite_Editor.invite_editor.*Due Date must be greater than today.
        </label>
      </div>
    </div>
    <div class="form-group">
      <label for="comment" class="label">Comment</label>
      <textarea
        id="comment"
        formControlName="comment"
        [(ngModel)]="start_message"
        class=" form-control cb-input-focus"
        placeholder="Comment"
        [maxLength]="4097"
      ></textarea>
      <div *ngIf="(f.comment.touched || f.comment.dirty) && f.comment.errors" class="text-danger">
        <label *ngIf="f.comment.errors.maxLength">*Description must be less than 4096 characters.</label>
      </div>
    </div>
  </form>

  <div class="sidenav-footer d-flex">
    <button
      type="submit"
      class="btn-save"
      [ngClass]="{ 'btn-disabled': editEditorApproverForm.invalid }"
      [disabled]="editEditorApproverForm.invalid"
      *ngIf="!isLoading"
      (click)="save()"
    >
      Save
    </button>
    <button type="submit" class="btn-save" disabled *ngIf="isLoading">
      <i class="fa fa-spinner fa-spin"></i>
    </button>
    <button type="button" class="btn-cancel" (click)="closeNav()">Cancel</button>
  </div>
</div>
