import { Component, HostListener, OnInit } from '@angular/core';
import { UserService } from '@app/core/user.service';
import { OrganisationService } from '@app/core/organisation.service';

@Component({
  selector: 'app-org-admin-shell',
  templateUrl: './orgAdminshell.component.html',
  styleUrls: ['./orgAdminshell.component.scss']
})
export class OrgAdminShellComponent implements OnInit {
  orgSelect = false;
  message: string;
  public innerWidth: any;
  showMessage = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 1000) {
      this.showMessage = false;
    } else {
      this.showMessage = true;
    }
  }

  constructor(private orgService: OrganisationService) {}

  ngOnInit() {
    this.orgService.__organisation_guid.subscribe(org_guid => {
      if (org_guid !== null) {
        this.orgSelect = true;
      } else {
        this.orgSelect = false;
      }
    });
    this.onResize();
  }

  receiveMessage($event: any) {
    this.message = $event;
  }
}
