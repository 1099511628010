import { VERSION } from 'environments/version';
import { Component } from '@angular/core';
import { VersionService } from '@app/version/version.service';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent {
  version = VERSION;
  bkVersion = '';

  constructor(private versionService: VersionService) {
    this.versionService.getBackendVersion().subscribe(
      data => {
        this.bkVersion = data;
      },
      error => {
        console.log(error);
      }
    );
  }
}
