<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div class="row">
    <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
      <h2 class="text-cyan m-0" translate>Notifications</h2>
      <div class="col top-header-filters">
        <button mat-icon-button *ngIf="show" (click)="refresh()">
          <mat-icon class="refresh">refresh</mat-icon>
        </button>
        <i class="fa fa-spinner fa-spin refresh" style="margin-right: 11px;" *ngIf="spnning"></i>
        <div class="search-people position-relative">
          <div class="input-group w-330">
            <input
              id="search"
              [(ngModel)]="searchText"
              class="form-control cb-input"
              placeholder="{{ this.placeholderSearch }}"
              name="search"
              type="text"
              (keyDown.enter)="Search(searchText)"
              #titleInput
            />
          </div>
          <i class="fa fa-search search-icon"></i>
        </div>
        <div>
          <select
            class="cb-input w-140 ml-3"
            [(ngModel)]="statusFilter"
            (change)="change_state($event.target.value)"
            *ngIf="!state_loading"
          >
            <option translate value="" disabled selected hidden>Attention_Required.Status</option>
            <option *ngFor="let state of states; let i = index" [attr.data-index]="i" value="{{ state.value }}">
              {{ state.viewValue }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="col">
    <div *ngIf="notificationDetails?.length > 0; else no_content">
      <div
        class="col p-0 search-results"
        infiniteScroll
        [scrollWindow]="false"
        (scrolled)="onScroll()"
        [infiniteScrollDistance]="modalScrollDistance"
        [infiniteScrollThrottle]="modalScrollThrottle"
        (scrolledUp)="onScrollUp()"
      >
        <table class="w-100p bg-white dm-table table table-responsive">
          <thead>
            <tr style="height: 65px;">
              <th style="width: 20%;" scope="col" class="font-weight-bold text-black-3 " translate>
                Title
              </th>
              <th scope="col" class="font-weight-bold text-center text-black-3 " translate>
                Type
              </th>
              <th scope="col" class="font-weight-bold text-center text-black-3" translate>
                Created date
              </th>
              <th scope="col" class="font-weight-bold text-center text-black-3" translate>
                Renewal type
              </th>
              <th scope="col" class="font-weight-bold text-black-3 text-center" translate>
                Due date
              </th>
              <th scope="col" class="font-weight-bold text-black-3 text-center" translate>
                Created by
              </th>

              <th scope="col" class="font-weight-bold text-black-9 text-center" translate>
                Attention_Required.Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="" *ngFor="let notification of notificationDetails; let rowIndex = index">
              <td class="d-none"></td>
              <td class="css-b8r45">
                <abbr title="{{ notification['document']['title'] }}">
                  <h4 class="text-black-4 dm-text-1 mt-3 ">{{ notification['document']['title'] }}</h4>
                </abbr>
              </td>
              <td>
                <h4 class="text-black-3 dm-text-2 mt-3 text-center">{{ notification['document']['type'] }}</h4>
              </td>
              <td>
                <h4 class="text-black-3 dm-text-2 mt-3 text-center">{{ notification['creation_date'] }}</h4>
              </td>
              <td>
                <h4 class="text-black-3 dm-text-2 mt-3 text-center">
                  {{ notification['renewal_type'] }}
                </h4>
              </td>
              <td>
                <h4 class="text-black-3 dm-text-2 mt-3 text-center">
                  {{ notification['due_date'] ? notification['due_date'] : '' }}
                </h4>
              </td>
              <td>
                <h4 class="text-black-3 dm-text-2 mt-3 text-center">
                  {{ notification['author'] }}
                </h4>
              </td>
              <td style="text-align: center;">
                <mat-icon class="mt-2" [matMenuTriggerFor]="menu">more_horiz</mat-icon>
                <mat-menu xPosition="before" #menu="matMenu" class="fixed-menu-panel">
                  <ng-container *ngFor="let action of notification['actions']">
                    <button
                      mat-menu-item
                      (click)="
                        getClickEvents(action, notification['document'], notification['title'], notification['guid'])
                      "
                    >
                      {{ action }}
                    </button>
                  </ng-container>
                </mat-menu>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <ng-template #no_content>
      <div class="row scroll-view">
        <div class="col" *ngIf="noData">
          <img class="empty-doc-list-image" src="../../../assets/images/documents/empty.svg" />
          <div class="text-empty-doc-list text-black-1" translate>No new notifications</div>
          <div class="sub-text-empty-doc-list cursor-pointer text-cyan-force" translate>
            <a
              class="sub-text-empty-doc-list text-center text-cyan-force"
              (click)="refresh()"
              style="font-size: 18px;"
              translate
            >
              Show all notifications</a
            >
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</ng-container>
&nbsp;
<div *ngIf="scrollLoader" class="w-100p d-flex justify-content-center">
  <h2 class="font-weight-bold  ">
    <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': scrollLoader }" style="font-size:large;"></i> &nbsp; Loading...
  </h2>
</div>
