import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalService } from '@app/core/modal/modal.service';
import { OrganisationService } from '@app/core/organisation.service';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { TranslateService } from '@ngx-translate/core';
import { finalize, take } from 'rxjs/operators';

interface VehicleNode {
  name: string;
  id?: number;
  children?: VehicleNode[];
  selected?: boolean;
  indeterminate?: boolean;
  parent?: VehicleNode;
}

@Component({
  selector: 'app-create-sub-folder',
  templateUrl: './create-sub-folder.component.html',
  styleUrls: ['./create-sub-folder.component.scss']
})
export class CreateSubFolderComponent implements OnInit {
  FolderForm: FormGroup;
  selectFolderForm: FormGroup;
  submitted = false;
  guidSubscription: any;
  error: any;
  form_error: any;
  isLoading = false;
  success: boolean;
  OrganisationID: string | null;
  ParentFolderList: object[];
  folder_name: any;
  parent_folder: any;
  error_message: string;
  subFolderStorageId: string = 'create_sub_folder_data';
  oldFolderName: any;
  backend_error: any;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    private formBuilder: FormBuilder,
    private documentsService: DocumentsService,
    private modalService: ModalService,
    private translate: TranslateService,
    private toaster: ToasterService
  ) {}

  ngOnInit() {
    localStorage.removeItem(this.subFolderStorageId);
    this.oldFolderName = this.folder_name;
    console.log(this.oldFolderName);
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
    });

    this.selectFolderForm = this.formBuilder.group({
      select_folder: ['', [Validators.required()]],
      select_subfolder: ['']
    });
    this.FolderForm = this.formBuilder.group({
      title: ['', [Validators.required(), Validators.maxLength(255)]],
      description: ['', [Validators.required(), Validators.maxLength(4096)]],
      parent_folder_guid: ['']
    });

    this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
      this.documentsService.getOrganizationFolderList(this.OrganisationID).subscribe(
        data => {
          this.ParentFolderList = data.results;
        },
        error => {
          this.error = error.error.message;
        }
      );
    });
  }
  ngAfterViewInit() {
    this.documentsService.shareSelectedFolder$.subscribe(shareSelectedFolder => {
      if (shareSelectedFolder) {
        this.folder_name = shareSelectedFolder.folderPath;
        this.parent_folder = shareSelectedFolder.selectedFolderGuid;
      }
    });
  }

  get f() {
    return this.FolderForm.controls;
  }
  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
    localStorage.removeItem(this.subFolderStorageId);
  }

  onReset() {
    this.submitted = false;
    this.FolderForm.reset();
    this.folder_name = '';
    this.backend_error = '';
    localStorage.removeItem(this.subFolderStorageId);
  }

  onChange(evt: any) {
    if (evt.type == 'keyup' && this.backend_error) {
      this.backend_error = '';
    }
  }

  getAddFolderForm() {
    this.FolderForm = this.formBuilder.group({
      title: ['', [Validators.required()]],
      description: ['']
    });
  }
  submit() {
    if (this.folder_name != this.oldFolderName) {
      this.backend_error = '';
    }
    this.isLoading = false;
    this.submitted = true;
    if (this.FolderForm.valid && this.parent_folder) {
      this.isLoading = true;
      this.error_message = '';
      const document_data = Object.assign({}, this.FolderForm.value);

      if (this.folder_name !== '') {
        let data = this.folder_name;
        document_data['parent_folder_guid'] = this.parent_folder;
      } else {
        let data1 = this.folder_name;
        document_data['parent_folder_guid'] = this.parent_folder;
      }

      this.documentsService.SubmitDocumentFolderData(this.OrganisationID, document_data).subscribe(
        data => {
          this.isLoading = false;
          this.error = {
            title: '',
            description: '',
            folder: ''
          };
          this.submitted = false;
          this.translate.get('create_a_subfolder.add_subfolder_toaster_message.subfolder_submitted').subscribe(res => {
            this.toaster.showSuccess(res);
          });
          this.submitted = false;
          this.FolderForm.reset();
          this.selectFolderForm.reset();
          this.folder_name = '';
          this.backend_error = '';
          localStorage.removeItem(this.subFolderStorageId);
        },
        (error: any) => {
          this.isLoading = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
    } else {
      this.error_message = 'Please select at least one checkbox.';
      return;
    }
  }
  SaveFolderForm() {
    this.submitted = true;
    if (this.FolderForm.valid) {
      this.isLoading = true;
      const SubFolderData = this.FolderForm.value;
      this.submitDocumentAddSubFolder(SubFolderData);
    } else {
      return;
    }
  }

  submitDocumentAddSubFolder(SubFolderData: any): void {
    this.documentsService
      .SubmitDocumentFolderData(this.OrganisationID, SubFolderData)
      .pipe(
        finalize(() => {
          this.FolderForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.error = {
            title: '',
            description: '',
            parent_folder_guid: ''
          };
          this.submitted = false;
          this.FolderForm.reset();
          this.backend_error = '';
        },
        (error: any) => {
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
  }

  SelectFolderDialog(): void {
    if (this.backend_error) {
      this.backend_error = '';
    }
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Select a folder',
        storage_id: this.subFolderStorageId,
        modalType: 'document'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.documentsService.getOrganizationFolderList(this.OrganisationID);
        } else {
        }
      });
  }
}
