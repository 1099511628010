import { UserService } from '@app/core/user.service';
import { Component, Input, OnInit } from '@angular/core';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { OrganisationService } from '@app/core/organisation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';

@Component({
  selector: 'app-form-management',
  templateUrl: './form-management.component.html',
  styleUrls: ['./form-management.component.scss']
})
export class FormManagementComponent implements OnInit {
  loginUser: any;
  canAddNew: boolean = false;
  searchText = '';
  page = 1;
  scrollLoader = false;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  pagnationData: any;
  guidSubscription: any;
  error: any;
  treeApiList: any;
  organisationID: string;
  storedFilteredFormsData: string;
  filteredFormData: string;
  showWorkSpace: boolean = true;

  constructor(
    private userservice: UserService,
    private documentsService: DocumentsService,
    private orgService: OrganisationService
  ) {}

  ngOnInit(): void {
    this.loginUser = this.userservice.getuserdetails();
    if (
      this.loginUser.user.role.includes('form_responder') &&
      !(
        this.loginUser.user.role.includes('organization_admin_role') ||
        this.loginUser.user.role.includes('form_requester') ||
        this.loginUser.user.role.includes('form_approver')
      )
    ) {
      this.showWorkSpace = false;
    }
    if (
      this.loginUser.user.role.includes('form_requester') ||
      this.loginUser.user.role.includes('organization_admin_role')
    ) {
      this.canAddNew = true;
    } else {
      this.canAddNew = false;
    }
    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.organisationID = guid;
    });
    this.storedFilteredFormsData = localStorage.getItem('filteredWorkspaceFormsData');
    this.filteredFormData = JSON.parse(this.storedFilteredFormsData);
  }
  ngAfterViewInit() {
    this.getClickedEventsOnWorkspaceElement(this.filteredFormData);
  }

  getClickedEventsOnWorkspaceElement(workspace: any) {
    this.documentsService.getFolderDocumentViewList(this.organisationID, workspace[0].guid, 'form').subscribe(
      data => {
        this.treeApiList = data;
        if (this.treeApiList != 0) {
          this.documentsService.setData({ workspace: workspace, treeApiList: this.treeApiList });
          this.documentsService.setworkspaceData(this.treeApiList);
        }
      },
      error => {
        this.error = error.error.message;
      }
    );
  }
}
