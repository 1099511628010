import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OrganisationService } from '@app/core/organisation.service';
import { UserService } from '@app/core/user.service';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { IncidentService } from '@app/Organisation-admin/incident/incident.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-create-jira-ticket',
  templateUrl: './create-jira-ticket.component.html',
  styleUrls: ['./create-jira-ticket.component.scss']
})
export class CreateJiraTicketComponent implements OnInit {
  jiraTicket: FormGroup;
  submitted = false;
  isLoading = true;
  isLoadingSubmitButton = false;
  success: boolean;
  OrganisationID: string | null;
  guidSubscription: any;
  error: any;

  loginUser: any;
  loginUsername: string;
  loginUserEmail: string;
  jiraLoading = true;

  @Input() formType: string;
  @Input() incidentGuid: string;
  @Input() title_tag: string;
  @Input() incidentTitle: string;
  @Input() comment: string;
  @Input() status_to_be_sent: string;
  orgGuidSubscription: any;
  organization: any;
  selectedAssignee: any;
  assigneeList: any;
  jira_issue_type: any;
  error_message: string;
  issueType: boolean;
  selectedIssue: any;
  hideForm = true;
  backend_error: any;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private incidentService: IncidentService,
    private userservice: UserService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.loginUser = this.userservice.getuserdetails();
    this.loginUsername = this.loginUser.user['username'];
    this.loginUserEmail = this.loginUser.user['email'];

    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.jiraTicket = this.fb.group({
        issue_type: ['', Validators.required],
        summary: ['', [Validators.required, Validators.maxLength(255)]],
        description: [
          'For more details on the incident refer here http://localhost:4200//organisation/incident/incident-details?guid=' +
            this.incidentGuid,
          [Validators.required, Validators.maxLength(32767)]
        ],
        assignee: ['', Validators.required]
      });

      this.getJiraTicket();
      this.getJira_assignee();
    });
  }
  getJiraTicket() {
    this.incidentService.get_jira_issue_type(this.OrganisationID).subscribe(
      (data: any) => {
        this.jira_issue_type = data;
        this.selectedIssue = data['label'];
        if (this.jira_issue_type > 0 && this.assigneeList > 0) {
          this.isLoading = true;
        } else {
          this.isLoading = false;
        }
        this.jiraLoading = false;
      },
      (error: any) => {
        if (error.status == 404) {
          this.hideForm = true;
          this.isLoading = false;
        }
        this.error = error.error;
      }
    );
  }

  getJira_assignee(): void {
    this.incidentService.getJiraAssigneeList(this.OrganisationID).subscribe(
      data => {
        this.assigneeList = data;
        this.selectedAssignee = data['label'];
        if (this.jira_issue_type > 0 && this.assigneeList > 0) {
          this.isLoading = true;
        } else {
          this.isLoading = false;
        }
        console.log(this.assigneeList);
      },
      (error: any) => {
        if (error.status == 404) {
          this.hideForm = true;
          this.isLoading = false;
        }
        this.error = error.error;
      }
    );
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.jiraTicket.controls;
  }

  save() {
    this.submitted = true;
    if (this.jira_issue_type && this.jiraTicket.valid) {
      this.isLoadingSubmitButton = true;
      const issue_data = Object.assign({}, this.jiraTicket.value);
      this.error_message = '';
      console.log('issue_data', issue_data);
      this.createAnIssue(issue_data);
    }
  }
  createAnIssue(issue_data: any) {
    this.incidentService
      .createJiraTicket(this.OrganisationID, this.incidentGuid, issue_data)
      .pipe(
        finalize(() => {
          this.jiraTicket.markAsPristine();
          this.isLoading = false;
          this.isLoadingSubmitButton = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.error = {
            issue_type: '',
            summary: '',
            description: '',
            assignee: ''
          };
          this.submitted = false;
          this.backend_error = '';
          this.activeModal.close(true);
        },
        (error: any) => {
          this.submitted = false;
          this.isLoading = false;
          this.isLoadingSubmitButton = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
          // this.activeModal.close(true);
        }
      );
  }
}
