<app-spinner *ngIf="isLoading"></app-spinner>
<ng-container *ngIf="!isLoading">
  <div class="onboarding-main pt-4" *ngIf="show && organizationJiraCredemtials">
    <div class="container max-w-840">
      <div class="row">
        <div class="col">
          <h1 class="text-black-2 mb-3 font-weight-bold" translate>onboard.automation.Create Backend Automation</h1>
          <p class="text-black-2 mb-5" translate>
            onboard.automation.automation_details
          </p>
          <div class="row">
            <div class="col-12">
              <h3 class="font-weight-bold text-black-2" translate>onboard.automation.Jira Integration</h3>
              <form class="w-100p" [formGroup]="jiraForm">
                <div class="row mt-3">
                  <div class="col-5">
                    <label for="JIRA_USER_EMAIL" class="text-black-1"
                      >{{ 'onboard.automation.User Email:' | translate }}
                      <i
                        class="fas fa-question-circle ml-2 cb-tooltip-btn"
                        ngbTooltip="Jira Administrators can specify a default email format: Choose the Jira icon ( , , , or ) > Jira settings > System. Select Default user preferences."
                        triggers="hover:blur"
                        tooltipClass="cb-tooltip"
                        placement="bottom"
                      ></i
                    ></label>
                  </div>

                  <div class="input-group col-7">
                    <input
                      id="JIRA_USER_EMAIL"
                      formControlName="JIRA_USER_EMAIL"
                      [(ngModel)]="organizationJiraCredemtials['JIRA_USER_EMAIL']"
                      (change)="resetJiraIssueTypeField()"
                      class="form-control cb-input"
                      placeholder="Jira email"
                      name="aws"
                      type="text"
                    />
                  </div>
                  <div
                    class="col-12"
                    *ngIf="
                      jiraForm.controls.JIRA_USER_EMAIL.invalid &&
                      (jiraForm.controls.JIRA_USER_EMAIL.dirty || jiraForm.controls.JIRA_USER_EMAIL.touched)
                    "
                  >
                    <label class="text-danger" *ngIf="jiraForm.controls.JIRA_USER_EMAIL.errors.required" translate
                      >onboard.document.User Email is required</label
                    >
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-5">
                    <label for="JIRA_SERVER_URL" class="text-black-1"
                      >{{ 'onboard.automation.Server URL:' | translate }}
                      <i
                        class="fas fa-question-circle ml-2 cb-tooltip-btn"
                        ngbTooltip="Choose > System. Select System info on the left menu to open the System info page. The Server ID is displayed in the Jira Info section of the page."
                        triggers="hover:blur"
                        tooltipClass="cb-tooltip"
                        placement="bottom"
                      ></i
                    ></label>
                  </div>

                  <div class="input-group col-7">
                    <input
                      id="JIRA_SERVER_URL"
                      formControlName="JIRA_SERVER_URL"
                      [(ngModel)]="organizationJiraCredemtials['JIRA_SERVER_URL']"
                      (change)="resetJiraIssueTypeField()"
                      class="form-control cb-input"
                      placeholder="Jira server url"
                      name="aws"
                      type="text"
                    />
                  </div>
                  <div
                    class="col-12"
                    *ngIf="
                      jiraForm.controls.JIRA_SERVER_URL.invalid &&
                      (jiraForm.controls.JIRA_SERVER_URL.dirty || jiraForm.controls.JIRA_SERVER_URL.touched)
                    "
                  >
                    <label class="text-danger" *ngIf="jiraForm.controls.JIRA_SERVER_URL.errors.required" translate
                      >onboard.document.Server URL is required</label
                    >
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-5">
                    <label for="JIRA_PROJECT_KEY" class="text-black-1"
                      >{{ 'onboard.automation.Project Key:' | translate }}
                      <i
                        class="fas fa-question-circle ml-2 cb-tooltip-btn"
                        ngbTooltip="Go to Project>View all Projects.
Click on the Project Name that you wanted to view the project key.
Click on the Project Administration.
Mouse-over the Edit Project button and you can view the project key in the navigation bar."
                        triggers="hover:blur"
                        tooltipClass="cb-tooltip"
                        placement="bottom"
                      ></i>
                    </label>
                  </div>

                  <div class="input-group col-7">
                    <input
                      id="JIRA_PROJECT_KEY"
                      formControlName="JIRA_PROJECT_KEY"
                      [(ngModel)]="organizationJiraCredemtials['JIRA_PROJECT_KEY']"
                      (change)="resetJiraIssueTypeField()"
                      class="form-control cb-input"
                      placeholder="Jira project name"
                      name="aws"
                      type="text"
                    />
                  </div>
                  <div
                    class="col-12"
                    *ngIf="
                      jiraForm.controls.JIRA_PROJECT_KEY.invalid &&
                      (jiraForm.controls.JIRA_PROJECT_KEY.dirty || jiraForm.controls.JIRA_PROJECT_KEY.touched)
                    "
                  >
                    <label class="text-danger" *ngIf="jiraForm.controls.JIRA_PROJECT_KEY.errors.required" translate
                      >onboard.document.*Project ID is required</label
                    >
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-5">
                    <label for="JIRA_API_KEY" class="text-black-1"
                      >{{ 'onboard.automation.API Key:' | translate }}
                      <i
                        class="fas fa-question-circle ml-2 cb-tooltip-btn"
                        ngbTooltip="You can get the api key by clicking the profile icon at bottom left of your screen. Path : profile>>Security>>api token>>create and manage your api tokens."
                        triggers="hover:blur"
                        tooltipClass="cb-tooltip"
                        placement="bottom"
                      ></i
                    ></label>
                  </div>

                  <div class="input-group col-7">
                    <input
                      id="JIRA_API_KEY"
                      formControlName="JIRA_API_KEY"
                      [(ngModel)]="organizationJiraCredemtials['JIRA_API_KEY']"
                      (change)="resetJiraIssueTypeField()"
                      (focusout)="focusOutFunction()"
                      class="form-control cb-input jira-api-key-input"
                      placeholder="Jira API key"
                      name="aws"
                      [type]="jiraApiKey ? 'text' : 'password'"
                    />
                    <span *ngIf="jiraApiKey" class="p-viewer icon-position-inside-input">
                      <i (click)="showPassword('jira_api_key')" class="fa fa-eye" aria-hidden="true"></i>
                    </span>
                    <span *ngIf="!jiraApiKey" class="p-viewer icon-position-inside-input">
                      <i (click)="showPassword('jira_api_key')" class="fa fa fa-eye-slash" aria-hidden="true"></i>
                    </span>
                  </div>
                  <div
                    class="col-12"
                    *ngIf="
                      jiraForm.controls.JIRA_API_KEY.invalid &&
                      (jiraForm.controls.JIRA_API_KEY.dirty || jiraForm.controls.JIRA_API_KEY.touched)
                    "
                  >
                    <label
                      class="text-danger"
                      *ngIf="jiraForm.controls.JIRA_API_KEY.errors.required && !isJiraFormSubmitted"
                      translate
                      >onboard.document.*API Key is required</label
                    >
                  </div>
                  <div class="row offset-5 jira-select-issue-type-error">
                    <span *ngIf="!!jiraIssueTypeError && !isJiraFormIssueTypeRecieved && !isJiraIssueLoading">
                      *{{ jiraIssueTypeError }}
                    </span>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="default-jira-issue-type-call" *ngIf="isJiraIssueLoading">
                    <i class="fas fa-cog fa-spin"></i>
                  </div>

                  <div class="col-5" *ngIf="!isJiraIssueLoading">
                    <label for="JIRA_PROJECT_ISSUE_TYPE" class="text-black-1"
                      >{{ 'onboard.automation.Jira Task Type:' | translate }}
                      <i
                        class="fas fa-question-circle ml-2 cb-tooltip-btn"
                        ngbTooltip="Select jira issue type. i.e story, bug, task. Fill the form and click the load icon to load the jira issue list."
                        triggers="hover:blur"
                        tooltipClass="cb-tooltip"
                        placement="bottom"
                      ></i
                    ></label>
                  </div>

                  <div class="input-group col-7" *ngIf="!isJiraIssueLoading">
                    <ng-select
                      [items]="organizationJiraCredemtials['JIRA_ISSUE_TYPE_LIST']"
                      formControlName="JIRA_ISSUE_TYPE"
                      bindLabel="name"
                      class="jira-select-issue-type"
                      placeholder="Select jira issue type"
                      [(ngModel)]="organizationJiraCredemtials['JIRA_ISSUE_TYPE']"
                    >
                      <ng-template
                        ng-header-tmp
                        *ngIf="
                          organizationJiraCredemtials['JIRA_ISSUE_TYPE_LIST']?.length > 0;
                          else selectJiraIssueTypeMessage
                        "
                      >
                        {{ 'onboard.automation.jira_issue_type_instruction.select_message_instruction' | translate }}
                      </ng-template>
                      <ng-template ng-header-tmp #selectJiraIssueTypeMessage>
                        {{ 'onboard.automation.jira_issue_type_instruction.load_message_instruction' | translate }}
                      </ng-template>
                      <ng-template ng-label-tmp let-item="item">
                        <img height="15" width="15" [src]="item.iconUrl" />
                        <b>{{ item.name }}</b>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-index="index">
                        <img height="15" width="15" [src]="item.iconUrl" />
                        <b>{{ item.name }}</b>
                      </ng-template>
                    </ng-select>
                    <span class="p-viewer jira-issue-type-loader icon-position-inside-input">
                      <img (click)="detectJiraChange()" src="../../../../assets/images/icons/refresh_icon.png" />
                    </span>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col text-right">
                    <button
                      class="ml-4 w-100"
                      [ngClass]="jiraForm.valid ? 'btn-submit' : 'btn-submit-disabled'"
                      (click)="jiraForm.valid && submitJiraForm()"
                    >
                      <i class="fa fa-spinner fa-spin" style="font-size:16px" *ngIf="isJiraSubmitButtonLoading"></i>
                      <span *ngIf="!isJiraSubmitButtonLoading">Submit</span>
                    </button>
                  </div>
                </div>
              </form>
              <h3 class="font-weight-bold text-black-2 pb-3 mt-4" translate>
                onboard.document.Document Store Integration
                <i class="fa fa-spinner fa-spin refresh" *ngIf="storeUpdating"></i>
              </h3>

              <div class="col scroll-view">
                <form class="w-100p" [formGroup]="boxForm">
                  <div class="row mt-3">
                    <div class="col-5">
                      <label for="document_store" class="text-black-1" translate
                        >onboard.document.Document Store Type
                        <i
                          class="fas fa-question-circle ml-2 cb-tooltip-btn"
                          ngbTooltip="Select document store type. i.e box"
                          triggers="hover:blur"
                          tooltipClass="cb-tooltip"
                          placement="bottom"
                        ></i
                      ></label>
                    </div>

                    <div class="input-group col-7">
                      <select
                        [value]="selectedOption"
                        formControlName="document_store"
                        class="form-control cb-input"
                        placeholder="Box Document Id"
                        name="document_store"
                        (change)="onStatusChange($event.target.value)"
                        required
                      >
                        <option value="" translate>onboard.document.Select Document Store Type</option>
                        <option *ngFor="let name of DocStoresList" [value]="name.value">
                          {{ name.value }}
                        </option>
                      </select>
                    </div>
                    <div
                      class="col-12"
                      *ngIf="
                        boxForm.controls.document_store.invalid &&
                        (boxForm.controls.document_store.dirty || boxForm.controls.document_store.touched)
                      "
                    >
                      <label class="text-danger" *ngIf="boxForm.controls.document_store.errors.required" translate
                        >onboard.document.Document Store type is required</label
                      >
                    </div>
                    <div class="col-12" *ngIf="backend_error">
                      <label class="text-danger" translate>* {{ backend_error }}</label>
                    </div>
                  </div>
                  <div class="row mt-3" *ngIf="show_box_button && showBoxData">
                    <div class="col-5">
                      <label for="store_secret" class="text-black-1" translate
                        >onboard.document.Select Store Secret File
                        <i
                          class="fas fa-question-circle ml-2 cb-tooltip-btn"
                          ngbTooltip="The file that is selected using this feature is usually encrypted and stored in a secure location, such as a password manager or a secure file storage system."
                          triggers="hover:blur"
                          tooltipClass="cb-tooltip"
                          placement="bottom"
                        ></i
                      ></label>
                    </div>

                    <div class="input-group col-7">
                      <input
                        style="position: relative;
                          top:8px;
                          margin-right: -55px;
                          margin-bottom: 10px;
                          left: 2px;
                          border-radius: 5px"
                        formControlName="store_secret"
                        class="form-control cb-input  "
                        name="store_secret"
                        type="text"
                        autocomplete="none"
                        disabled
                      />
                      <input
                        id="store_secret"
                        class="file-upload file-input ml-1 mt-2"
                        formControlName="store_secret"
                        name="store_secret"
                        style="height: 40px;
                      position: relative;
                      left: 78px;
                  ;"
                        autocomplete="file"
                        type="file"
                        (change)="uploadFile($event)"
                        accept=".json"
                        #file
                      />
                    </div>
                    <div class="col-12" *ngIf="boxForm.controls.store_secret.invalid">
                      <label
                        class="text-danger"
                        *ngIf="boxForm.controls.store_secret?.errors?.required && boxForm.controls.store_secret.touched"
                        translate
                        >onboard.automation.File is required</label
                      >
                    </div>
                    <div *ngIf="error">
                      <div class="col-12 error-mssg" *ngIf="error.store_secret">
                        <label class="text-danger" translate>* {{ error.store_secret[0] }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3" *ngIf="selectedOption">
                    <div class="col-5">
                      <label for="doc_store_url" class="text-black-1" translate
                        >onboard.document.Document Store URL<i
                          class="fas fa-question-circle ml-2 cb-tooltip-btn"
                          ngbTooltip="The Document Store URL is the address that is used to access the document store from a web browser or other client application."
                          triggers="hover:blur"
                          tooltipClass="cb-tooltip"
                          placement="bottom"
                        ></i
                      ></label>
                    </div>
                    <div class="input-group col-7">
                      <input
                        id="doc_store_url"
                        formControlName="doc_store_url"
                        class="form-control cb-input"
                        placeholder="Document Store URL"
                        name="doc_store_url"
                        type="text"
                        autocomplete="none"
                        (change)="onChange($event)"
                        required="required"
                      />
                    </div>
                    <div class="col-12" *ngIf="boxForm.controls.doc_store_url.invalid">
                      <label
                        class="text-danger"
                        *ngIf="
                          boxForm.controls.doc_store_url?.errors?.required && boxForm.controls.doc_store_url.touched
                        "
                        translate
                        >onboard.document.Server URL is required</label
                      >
                    </div>
                  </div>
                  <div *ngIf="error">
                    <div class="col-12 error-mssg" *ngIf="error.doc_store_url">
                      <label class="text-danger" style="margin-left: 21rem" translate
                        >* {{ error.doc_store_url[0] }}</label
                      >
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col text-right">
                      <button
                        *ngIf="
                          showSubmitBtn &&
                          !(
                            boxForm.value.document_store == 'Google Drive' ||
                            boxOrganization?.document_store_title == 'Google Drive'
                          )
                        "
                        class="ml-4 w-100"
                        [ngClass]="boxForm.valid ? 'btn-submit' : 'btn-submit-disabled'"
                        (click)="boxForm.valid && submitBoxForm()"
                      >
                        <i class="fa fa-spinner fa-spin" style="font-size:16px" *ngIf="isBoxSubmitButtonLoading"></i>
                        <span
                          *ngIf="
                            !isBoxSubmitButtonLoading &&
                            show_box_button &&
                            !(show_GD_button && show_initialize_folder_btn)
                          "
                          translate
                          >onboard.document.submit</span
                        >
                      </button>
                    </div>
                  </div>

                  <div
                    class="mt-2 ml-2"
                    *ngIf="
                      (boxForm.value?.document_store == 'Google Drive' ||
                        boxOrganization?.document_store_title === 'Google Drive') &&
                      !boxOrganization?.store_details?.initialised_folders
                    "
                  >
                    <div class="form-check d-flex">
                      <input
                        class="form-check-input p-1"
                        (change)="onRadioChange($event)"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        value="shared_drive"
                        [disabled]="storeUpdating"
                        [checked]="drivePermissions"
                      />
                      <label class="form-check-label mt-1" for="flexRadioDefault1">
                        Create Shared Drive
                      </label>
                      <ng-container *ngIf="driveConfiguration == 'shared_drive'">
                        <button
                          class="ml-4 w-100 btn-sm"
                          style="font-size: 12px;"
                          [ngClass]="
                            drivePermissions || this.boxOrganization?.store_details?.is_shared_drive
                              ? 'btn-submit-disabled'
                              : 'btn-submit'
                          "
                          (click)="openGoogleDrivePage()"
                          [disabled]="drivePermissions || this.boxOrganization?.store_details?.is_shared_drive"
                        >
                          Create Drive
                        </button>
                        <button
                          *ngIf="
                            boxForm.value?.document_store == 'Google Drive' ||
                            boxOrganization?.document_store_title === 'Google Drive'
                          "
                          class="ml-4 "
                          style="width: 120px;"
                          [ngClass]="
                            this.boxOrganization?.store_details?.is_shared_drive && !drivePermissions
                              ? 'btn-submit'
                              : 'btn-submit-disabled'
                          "
                          [disabled]="
                            !this.boxOrganization?.store_details?.is_shared_drive || drivePermissions || storeUpdating
                          "
                          (click)="openGoogleCreateSharedDoc()"
                        >
                          Initialise Folders
                        </button>
                      </ng-container>
                    </div>
                    <span *ngIf="drivePermissions" class="font-weight-light text-danger ml-0" style="font-size: 12px;">
                      This authenticated user cannot create new shared drive.
                    </span>

                    <div class="form-check mt-2 d-flex">
                      <input
                        class="form-check-input "
                        (change)="onRadioChange($event)"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        value="folder_initialized"
                        [disabled]="storeUpdating"
                      />
                      <label class="form-check-label mt-1 " for="flexRadioDefault2">
                        Create a Shared Folder in My Drive
                      </label>
                      <ng-container *ngIf="driveConfiguration == 'folder_initialized'">
                        <button
                          *ngIf="
                            boxForm.value?.document_store == 'Google Drive' ||
                            boxOrganization.document_store_title === 'Google Drive'
                          "
                          class="ml-4 btn-submit"
                          style="width: 120px;"
                          (click)="openGoogleCreateSharedDoc()"
                        >
                          Initialise Folders
                        </button>
                      </ng-container>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
