import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-retired-docs',
  templateUrl: './retired-docs.component.html',
  styleUrls: ['./retired-docs.component.scss']
})
export class RetiredDocsComponent implements OnInit {
  loaderArr: any = [];
  accArray: any[] = [];
  title = 'Retired docs';

  constructor() {}

  ngOnInit() {}

  clearLoader(value: any): void {
    const index = this.loaderArr.indexOf(value);
    if (index > -1) {
      this.loaderArr.splice(index, 1);
    }
  }
}
