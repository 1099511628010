import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';

@Component({
  selector: 'app-view-editors',
  templateUrl: './view-editors.component.html',
  styleUrls: ['./view-editors.component.scss']
})
export class ViewEditorsComponent implements OnInit {
  OrganisationID: string | null;
  guidSubscription: any;
  EditorList: object[];
  error: any;
  loading = true;

  @Input() formType: string;
  @Input() DocumentGuid: string;
  @Input() title_tag: string;
  @Input() docTitle: string;
  @Input() type: string;

  constructor(
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private documentsService: DocumentsService
  ) {}

  ngOnInit() {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getEditorList();
    });
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  private getEditorList(): void {
    let unassigned = {
      'View Editors': 'getDocumentEditorList',
      'View Responders': 'getFormResponderList',
      'View Approvers': 'getFormApproverList'
    };
    console.log(unassigned[this.type]);
    this.documentsService[unassigned[this.type]](this.OrganisationID, this.DocumentGuid).subscribe(
      (data: any) => {
        this.EditorList = data;
        if (this.EditorList) {
          this.EditorList.forEach(editor => {
            if (editor['user']['name']) {
              var initials = editor['user']['name'].match(/\b(\w)/g);
              editor['initials'] = initials.join('').toUpperCase();
            } else {
              var initials = editor['user']['email'].charAt(0);
              editor['initials'] = initials.toUpperCase();
            }
          });
          this.EditorList.sort((a, b) => a['user']['name'].localeCompare(b['user']['name']));
        }
        this.loading = false;
      },
      (error: any) => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
        this.loading = false;
      }
    );
  }
}
