import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { finalize, take } from 'rxjs/operators';
import { OrganisationService } from '@app/core/organisation.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { IncidentService } from '@app/Organisation-admin/incident/incident.service';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';

@Component({
  selector: 'app-edit-incident-category',
  templateUrl: './edit-incident-category.component.html',
  styleUrls: ['./edit-incident-category.component.scss']
})
export class EditIncidentCategoryComponent implements OnInit {
  @Input() title_tag: string;
  @Input() categoryGuid: string;
  @Input() categoryTitle: string;
  @Input() categoryDescription: string;

  createCategoryForm: FormGroup;
  submitted = false;
  isLoading = false;
  OrganisationID: string | null;
  guidSubscription: any;
  error = {
    title: '',
    description: ''
  };
  success: boolean;
  backend_error: string;

  constructor(
    private backendErrorService: BackendErrorService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private orgService: OrganisationService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private incidentService: IncidentService
  ) {}

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  ngOnInit() {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getCreateCategoryForm();
      console.log(this.categoryGuid);
      console.log(this.categoryTitle);
      console.log(this.categoryDescription);
      console.log(this.title_tag);
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.createCategoryForm.controls;
  }

  save() {
    this.submitted = true;
    if (this.createCategoryForm.valid) {
      this.isLoading = true;

      this.createCategoryForm.getRawValue();
      const createCategoryData = Object.assign({}, this.createCategoryForm.getRawValue());

      if (this.OrganisationID != null) {
        this.incidentService
          .updateCategoryDescription(this.OrganisationID, this.categoryGuid, createCategoryData)
          .pipe(
            finalize(() => {
              this.createCategoryForm.markAsPristine();
              this.isLoading = false;
            })
          )
          .subscribe(
            (data: any) => {
              this.success = true;
              this.error = {
                title: '',
                description: ''
              };
              this.submitted = false;
              this.backend_error = '';
              this.translate.get('Category.category_toaster_message.incident_category_edited').subscribe(res => {
                this.toaster.showSuccess(res);
              });
              this.activeModal.close(true);
            },
            (error: any) => {
              this.submitted = false;
              this.isLoading = false;
              this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
              this.backendErrorService.backendError$.subscribe(error => {
                this.backend_error = error;
              });
            }
          );
      }
    } else {
      return;
    }
  }

  getCreateCategoryForm() {
    this.createCategoryForm = this.formBuilder.group({
      title: [this.categoryTitle, [Validators.required(), Validators.maxLength(255)]],
      description: [this.categoryDescription, [Validators.required(), Validators.maxLength(4096)]]
    });
  }
}
