import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { SidenavService } from '@app/core/modal/sidenav.service';

@Component({
  selector: 'app-disconnect-integration',
  templateUrl: './disconnect-integration.component.html',
  styleUrls: ['./disconnect-integration.component.scss']
})
export class DisconnectIntegrationComponent implements OnInit {
  isScreenSpinnerLoading: boolean = false;
  isLoading: boolean = false;
  orgGuidSubscription: any;
  submitted = false;
  isModalSpinnerLoading = false;
  OrganisationID: string | null;
  guidSubscription: any;
  success: boolean;
  error: any;
  loading: boolean = false;
  @Input() title_tag: string;
  @Input() message: string;
  backend_error: any;
  accountDetails: any;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private documentsService: DocumentsService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private sidenavService: SidenavService
  ) {}

  ngOnInit(): void {
    this.orgGuidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
    });
    this.sidenavService.ServiceProviderAccountDetails.subscribe(account => {
      this.accountDetails = account;
    });
  }

  connectDisconectAccount() {
    let payload = {
      is_active: false
    };
    this.loading = true;
    this.documentsService.connectDisconectAccount(this.OrganisationID, payload, this.accountDetails?.guid).subscribe(
      res => {
        this.loading = false;
        this.toaster.showSuccess('Account disconnected successfully');
        this.activeModal.close({ isUpdate: true });
      },
      error => {
        this.backendErrorService.setBackendError(error);
        this.backendErrorService.backendError$.subscribe(error => {
          this.translate.get(error).subscribe(res => {
            this.toaster.showError(res);
          });
        });
      }
    );
  }
}
