<div class="modal-header cb-modal-header">
  <div>
    <h4 class="modal-title font-weight-bold text-black-1" translate>
      {{ type == 'Invite Approvers' ? 'Incident_Manage_Approver.Manage_Approver' : 'Incident_Manage_Reviewer.Title' }}
    </h4>
    <i
      class="fa fa-times text-black-1"
      style="width: 12px;
              height: 12px;
              right: 1em;
              cursor: pointer;
              position: absolute;
              top: 1.3em;"
      (click)="activeModal.close({ isUpdate: true, result: actions, show_msg: false })"
      ngbAutoFocus
      translate
    ></i>
  </div>
</div>

<div class="modal-body">
  <div class="row mt-1 mb-3" *ngIf="backend_error">
    <div class="col-12">
      <span class="text-danger backend_error" translate> * {{ backend_error }}</span>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-3">
      <label class="text-black-4 p-2" translate
        >{{ type == 'Invite Approvers' ? 'Incident_Manage_Approver.Incident' : 'Incident_Manage_Reviewer.Incident' }}
      </label>
    </div>
    <div class="col-9">
      <div class="input-group">
        <input class="form-control cb-input" value="{{ incidentTitle }}" disabled />
      </div>
    </div>
  </div>

  <form [formGroup]="form" novalidate>
    <div class="row mt-2">
      <div class="col-3">
        <label class="text-black-2 p-2" translate>
          {{
            type == 'Invite Approvers'
              ? 'Incident_Manage_Approver.Select_Approver'
              : 'Incident_Manage_Reviewer.Select_Reviewer'
          }}
        </label>
      </div>
      <div class="col-12">
        <ng-multiselect-dropdown
          class="multi-select-dropdown"
          [placeholder]="type == 'Invite Approvers' ? 'Select Approvers' : 'Reviewers'"
          [data]="UsersList"
          [(ngModel)]="selectedItems"
          [settings]="dropdownSettings"
          (onSelect)="onItemSelect($event)"
          (onDeSelect)="onItemDeSelect($event)"
          [ngModelOptions]="{ standalone: true }"
        >
        </ng-multiselect-dropdown>
        <div class="col-17 error-mssg" *ngIf="!selectedUser">
          <label for="users" class="text-danger" style="margin-left: 142px;" translate>
            {{
              type == 'Invite Approvers'
                ? 'Incident_Manage_Approver.invite_approver.*Atleast one approver is required.'
                : 'Incident_Manage_Reviewer.invite_reviewer.*Atleast one Reviewer is required.'
            }}</label
          >
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-3">
        <label for="due_date" class="text-black-1 p-2" translate>{{
          type == 'Invite Approvers'
            ? 'Incident_Manage_Approver.Due_Date'
            : 'Incident_Manage_Reviewer.invite_reviewer.Due Date'
        }}</label>
      </div>
      <div class="col-9">
        <div class="input-group">
          <input
            id="change_due_date"
            class="form-control cb-input"
            placeholder="yyyy-mm-dd"
            formControlName="due_date"
            name="due_date"
            [minDate]="minDate"
            ngbDatepicker
            #d="ngbDatepicker"
            (click)="d.toggle()"
            (dateSelect)="onDateSelection($event)"
            required
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary fa fa-calendar-alt cb-input"
              (click)="d.toggle()"
              type="button"
            ></button>
          </div>
          <div class="col-12 error-mssg" *ngIf="(f.due_date.touched || submitted) && f.due_date.errors">
            <label for="due_date" class="text-danger" *ngIf="f.due_date.errors.required" translate>
              {{
                type == 'Invite Approvers'
                  ? 'Incident_Manage_Approver.invite_approver.*Due Date is required.'
                  : 'Incident_Manage_Reviewer.invite_reviewer.*Due Date is required.'
              }}</label
            >
            <label for="due_date" class="text-danger" *ngIf="f.due_date.errors.DateTodayOrAfter_error" translate>
              {{
                type == 'Invite Approvers'
                  ? 'Incident_Manage_Approver.invite_approver.*Due Date must be greater than today.'
                  : 'Incident_Manage_Reviewer.invite_reviewer.*Due Date must be greater than today.'
              }}</label
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-3">
        <label for="comment" class="text-black-1 p-2" translate
          >{{
            type == 'Invite Approvers'
              ? 'Incident_Manage_Approver.invite_approver.Comment'
              : 'Incident_Manage_Reviewer.invite_reviewer.Comment'
          }}
        </label>
      </div>
      <div class="col-9">
        <div class="input-group">
          <textarea
            id="comment"
            class="form-control cb-input"
            [placeholder]="'Comment'"
            name="comment"
            type="text"
            formControlName="comment"
            cols="64"
            rows="5"
            [maxLength]="4097"
          ></textarea>
          <div class=" col-12 error-mssg" *ngIf="(f.comment.touched || f.comment.dirty) && f.comment.errors">
            <label
              for="name"
              style="margin-left:-15px;"
              class="text-danger"
              *ngIf="f.comment.errors.maxLength"
              translate
            >
              Incident_Manage_Reviewer.* Ensure that the comment has no more than 4096 characters.</label
            >
          </div>
        </div>
      </div>
    </div>
  </form>
  <button class="btn-save ml-4 w-100 mt-3 mb-3 float-right" *ngIf="!isLoading" (click)="save()" translate>
    {{ type == 'Invite Approvers' ? 'Incident_Manage_Approver.Send_Invite' : 'Incident_Manage_Reviewer.Send_Invite' }}
  </button>
  <button class="btn-save ml-4 w-100 mt-3 mb-3 float-right" disabled *ngIf="isLoading">
    <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
  </button>
  <button
    class="btn-cancel ml-4 w-100 mt-3 float-right"
    (click)="activeModal.close({ isUpdate: true, result: actions, show_msg: false })"
    ngbAutoFocus
    translate
  >
    {{
      type == 'Invite Approvers' ? 'Incident_Manage_Approver.invite_approver.Cancel' : 'Incident_Manage_Reviewer.Close'
    }}
  </button>
</div>
<div class="approver-modal-footer cb-modal-footer mt-5" *ngIf="reviewerApproverList?.length > 0">
  <table class="table table-borderless">
    <thead>
      <tr>
        <th scope="col-6">
          <h4 class="modal-title font-weight-bold text-black-1" translate>
            {{
              type == 'Invite Approvers' ? 'Incident_Manage_Approver.Approvers' : 'Incident_Manage_Reviewer.Reviewers'
            }}
          </h4>
        </th>
        <th scope="col-2">
          <h4 class="modal-title font-weight-bold text-black-1" translate>Incident_Manage_Approver.Due_Date</h4>
        </th>
        <th scope="col-2">
          <h4 class="modal-title font-weight-bold text-black-1" translate>Incident_Manage_Approver.Status</h4>
        </th>
        <th scope="col-2"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of reviewerApproverList">
        <td>
          <h4 class="text-black-1 text-left" scope="row">
            <span class="i-circle text-black-1 mr-2 font-weight-bold">{{ item.initials }}</span
            >{{ item.user.name }}
          </h4>
        </td>
        <td>
          <h4 class="text-black-1">{{ item.due_date | date }}</h4>
        </td>
        <td>
          <h4 class="text-black-1 capitalize">{{ item.status }}</h4>
        </td>
        <td>
          <mat-icon
            class="cursor-pointer"
            *ngIf="item.status.toLowerCase() == 'to do' || item.status.toLowerCase() == 'pending closure'"
            (click)="openChangeDueFrom(item)"
            >edit</mat-icon
          >
          <mat-icon
            class="cursor-pointer"
            *ngIf="item.status.toLowerCase() == 'resolved' || item.status.toLowerCase() == 'closed'"
            (click)="openRestoreApproverForm(item, incidentTitle)"
            >refresh</mat-icon
          >
          <mat-icon
            class="cursor-pointer"
            *ngIf="item.status.toLowerCase() == 'to do'"
            (click)="revokeApproverReviewer(incidentGuid, item.guid, item.user.name, type)"
            >delete_outline</mat-icon
          >
        </td>
      </tr>
    </tbody>
  </table>
  <br />
</div>
