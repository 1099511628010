<div>
  <div class="modal-header cb-modal-header">
    <h4 class="modal-title font-weight-bold text-black-1">{{ title }}</h4>
  </div>
  <div class="modal-body">
    <p>{{ message }}</p>
    <form [formGroup]="acceptLeadDialogForm" novalidate autocomplete="false">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <!-- <label for="agent_repo_url" translate>CBAgent forked repo URL:</label> -->
            <input
              type="text"
              class="form-control cb-input"
              id="agent_repo_url"
              aria-describedby="agent_repo_url"
              formControlName="agent_repo_url"
              autocomplete="agent_repo_url"
              placeholder="Please enter CBAgent forked URL."
            />
            <small
              [hidden]="
                acceptLeadDialogForm.controls.agent_repo_url.valid ||
                acceptLeadDialogForm.controls.agent_repo_url.untouched
              "
              class="text-danger"
              translate
            >
              organisation_lead_generation.Required
            </small>
            <small *ngIf="error" translate>
              <span
                class="text-danger"
                *ngIf="error.agent_repo_url && !acceptLeadDialogForm.controls.agent_repo_url.dirty"
                translate
              >
                {{ error.agent_repo_url[0] }}
              </span>
            </small>
            <div class="mt-4 ml-2">
              <label class="form-check-label text-black-1 checkbox-2 checkbox-2-padding" translate>
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="is_trial"
                  id="is_trial"
                  formControlName="is_trial"
                />
                <span class="checkmark"></span>
                organisation_lead_generation.trial
              </label>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer border-0 cb-modal-footer">
    <button class="btn-cancel ml-4 w-100" (click)="activeModal.close(false)" translate>
      organisation_lead_generation.Cancel
    </button>
    <button
      type="submit"
      class="btn-save ml-4 w-100"
      [disabled]="acceptLeadDialogForm.invalid || isLoading"
      (click)="acceptLeadDialogFormSubmit()"
    >
      <i class="fas fa-cog fa-spin" [hidden]="!isLoading"></i>
      <span translate>organisation_lead_generation.Submit</span>
    </button>
  </div>
</div>
