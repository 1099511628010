import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@app/core/modal/modal.service';
import { OrganisationService } from '@app/core/organisation.service';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { take } from 'rxjs/operators';
import stringInfo from '../../../../translations/en-US.json';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganisationAdminService } from '@app/Organisation-admin/organisation-admin.service';

@Component({
  selector: 'app-approve-pending-form',
  templateUrl: './approve-pending-form.component.html',
  styleUrls: ['./approve-pending-form.component.scss']
})
export class ApprovePendingFormComponent implements OnInit {
  docURL: any;
  show_add_new_button: boolean = false;
  orgGuidSubscription: any;
  organisationID: string | null;
  loading = true;
  pendingFormList: any;
  totalLength: any;
  searchText: any;
  error: any;
  search_title: any;
  statusFilter: any = '';
  page = 1;
  show = true;
  spnning = false;
  paginatedData: any;
  state_filter: string | null = '';
  scrollLoader = false;
  no_status = false;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  clickedGuid: string;
  statusAndActions: any;
  status_loading: boolean = false;
  formStatus: [];
  paginatedDataUrl: any;
  tab_status: string = '';
  formDetails: any;
  roleRouteMapper = {
    '/organisation/form/pending-form': 'Pending',
    '/organisation/form/approved-form': 'Approved'
  };
  titleMapper = {
    Pending: 'Pending',
    Approved: 'Approved'
  };
  constructor(
    private documentsService: DocumentsService,
    private orgService: OrganisationService,
    private modalService: ModalService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private router: Router,
    private route: ActivatedRoute,
    private orgAdminService: OrganisationAdminService
  ) {
    this.tab_status = this.roleRouteMapper[window.location.pathname];
  }

  ngOnInit() {
    this.orgGuidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.organisationID = guid;
      this.getPendingList();
    });
    if (this.tab_status == 'Pending') {
      this.no_status = true;
      this.getStatusTypes();
    }
    const enteredSearchValue: string = '';
    const action: string = this.route.snapshot.queryParamMap.get('action');
    const formGuid: string = this.route.snapshot.queryParamMap.get('guid');
    if (formGuid) {
      this.getformDetailsdata(action, formGuid);
    }
  }

  private getformDetailsdata(action: string, formGuid: string) {
    console.log(formGuid);
    this.documentsService.OrganizationFormSearchAPI(this.organisationID, formGuid).subscribe(
      data => {
        this.formDetails = data.results[0];
        if (
          action == 'edit_form_template' &&
          formGuid == this.formDetails['guid'] &&
          this.formDetails['allowed_actions_list'].some((action: any) => action.key === 'edit_form_template')
        ) {
          this.OpenForm(this.formDetails['document_store_identifier']);
        } else if (
          action == 'view_form_details' &&
          formGuid == this.formDetails['guid'] &&
          this.formDetails['allowed_actions_list'].some((action: any) => action.key === 'view_form_details')
        ) {
          this.router.navigate(['/organisation/form/form-details/', formGuid]);
        }
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }
  OpenForm(url: string) {
    window.open(url, '_blank');
  }
  getStatusTypes() {
    if (this.organisationID !== null) {
      this.status_loading = true;
      this.orgAdminService.getOrganisationFormStatusTypes(this.tab_status).subscribe(data => {
        this.formStatus = data;
        this.status_loading = false;
      });
    }
  }

  onScrollUp() {
    this.scrollLoader = false;
  }

  refresh() {
    this.show = false;
    this.spnning = true;
    this.page = 1;
    this.getPendingList();
    this.searchText = '';
    this.getStatusTypes();
  }

  updatePaginatedData(dataResult: any) {
    this.scrollLoader = false;
    this.paginatedData = dataResult.results;
    this.paginatedDataUrl = dataResult;
    this.pendingFormList = this.pendingFormList.concat(dataResult.results);
  }
  onScrollData() {
    this.scrollLoader = true;
    if (!this.search_title || !this.statusFilter) {
      this.search_title = '';
      this.statusFilter = this.statusFilter;
      this.documentsService
        .getPendingForm(this.organisationID, this.statusFilter, (this.page += 1), this.search_title, this.tab_status)
        .subscribe(
          data => {
            this.updatePaginatedData(data);
          },
          error => {
            this.scrollLoader = false;
            this.error = error.error.message;
          }
        );
    } else {
      this.scrollLoader = true;
      this.documentsService
        .getPendingForm(this.organisationID, this.statusFilter, (this.page += 1), this.search_title, this.tab_status)
        .subscribe(
          data => {
            this.updatePaginatedData(data);
          },
          error => {
            this.scrollLoader = false;
            this.error = error.error.message;
          }
        );
    }
  }
  onScroll() {
    if (this.paginatedDataUrl?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }

  search(search_title: string) {
    this.page = 1;
    this.loading = true;
    this.search_title = search_title;
    if (!this.statusFilter) {
      this.statusFilter = '';
    }
    this.documentsService
      .getPendingForm(this.organisationID, this.statusFilter, this.page, this.search_title, this.tab_status)
      .subscribe(
        data => {
          this.loading = false;
          this.pendingFormList = data.results;
        },
        error => {
          this.error = error.error.message;
        }
      );
  }

  onStatusChange(state: any, value: any) {
    this.loading = true;
    this.statusFilter = state;
    if (!this.search_title) {
      this.search_title = this.search_title;
    }
    if (!this.search_title) {
      this.search_title = '';
    }
    for (let i = 0; i < this.pendingFormList.length; i++) {
      if (this.pendingFormList[i]['status'] != this.statusFilter) {
        this.page = 1;
      }
    }
    this.documentsService
      .getPendingForm(this.organisationID, this.statusFilter, this.page, this.search_title, this.tab_status)
      .subscribe(
        data => {
          this.loading = false;
          this.pendingFormList = data.results;
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
  }
  getPendingList(): void {
    if (this.organisationID != null) {
      if (!this.search_title) {
        this.search_title = '';
      }
      if (this.tab_status == 'Pending') {
        this.show_add_new_button = true;
      }
      this.documentsService
        .getPendingForm(
          this.organisationID,
          (this.statusFilter = ''),
          this.page,
          (this.search_title = ''),
          this.tab_status
        )
        .subscribe(
          data => {
            this.show = true;
            this.spnning = false;
            this.loading = false;
            this.paginatedDataUrl = data;
            this.pendingFormList = data.results;
            this.totalLength = data.count;
          },
          error => {
            this.show = true;
            this.spnning = false;
            this.error = error.error.message;
            this.loading = false;
          }
        );
    }
  }

  goToCreateForm() {
    this.router.navigate(['organisation/form/create-form-management']);
  }

  goToEditForm(formGuid: any) {
    this.router.navigate(['/organisation/form/edit-form'], { queryParams: { formGuid: formGuid } });
  }

  openResponderApproverModal(type: string, formGuid: string, formCreatedBy: string, formTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: type === 'Manage Responders' ? 'Manage Responders' : 'Manage Approvers',
        modalType: type === 'Manage Responders' ? 'Manage Responders' : 'Manage Form Approvers',
        formGuid: formGuid,
        formCreatedBy: formCreatedBy,
        formTitle: formTitle
      })
      .pipe(take(1))
      .subscribe((result: any) => {
        if (result.isUpdate && Object.keys(result.result).length) {
          this.updateFormList(result.result['status'], result.result['allowed_actions_list']);
        }
      });
  }

  updateSearchText(x: any) {
    this.search_title = x.target.value;
    if (this.search_title == undefined) {
      this.search_title = '';
    }
  }

  viewForm(formGuid: any) {
    let payload = {
      remarks: '',
      status: 'pending_submission'
    };
    this.documentsService.getFormDetailsEditUrl(this.organisationID, formGuid, payload).subscribe(
      data => {
        this.updateFormList(data['status'], data['allowed_actions_list']);
        window.open(data.document_store_identifier, '_blank');
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }

  updateFormList(status: any, actions: any) {
    for (let i = 0; i < this.pendingFormList.length; i++) {
      if (this.clickedGuid == this.pendingFormList[i]['guid']) {
        this.pendingFormList[i]['status'] = status;
        this.pendingFormList[i]['allowed_actions_list'] = actions;
        break;
      }
    }
  }

  completeEdit(formGuid: string, formTitle: string) {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Form Edit Complete',
        modalType: 'Form Edit Complete',
        DocumentGuid: formGuid,
        docTitle: formTitle
      })
      .pipe(take(1))
      .subscribe((result: any) => {
        if (result.isUpdate) {
          this.updateFormList(result.result['status'], result.result['allowed_actions_list']);
          let msg = 'Edit Complete Successfully';
          this.translate.get(msg).subscribe(res => {
            this.toaster.showSuccess(res);
          });
        }
      });
  }

  approveForm(formGuid: string, formTitle: string) {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Are you sure you want to approve changes ?',
        modalType: 'Approve Form Changes',
        DocumentGuid: formGuid,
        docTitle: formTitle
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          this.pendingFormList = this.pendingFormList.filter((item: any) => item.guid !== this.clickedGuid);

          this.translate
            .get('Approve_Changes.approve_changes_toaster_message.form_changes_submitted')
            .subscribe(res => {
              this.toaster.showSuccess(res);
            });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  changeRequestModal(formGuid: string, formTitle: string, fromCreator: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Form Change Request',
        modalType: 'Form Change Request',
        DocumentGuid: formGuid,
        docTitle: formTitle,
        DocCreatedBy: fromCreator
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          this.updateFormList(result.result['status'], result.result['allowed_actions_list']);
          this.translate.get('Create_Form_Request.Responder_Success').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        }
      });
  }

  editForm(formGuid: any) {
    this.documentsService.getOrganizationFormEditURl(this.organisationID, formGuid).subscribe(
      (data: any) => {
        this.docURL = data;
        window.open(this.docURL.document_store_identifier, '_blank');
      },
      (error: { error: { message: any } }) => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }

  openDocument(url: string) {
    window.open(url, '_blank');
  }

  viewResponderApproverOpenModal(DocumentGuid: string, docTitle: string, type: any): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: type == 'view responders' ? 'View responders' : 'View Approvers',
        modalType: type == 'view responders' ? 'View responders' : 'View Form Approvers',
        DocumentGuid: DocumentGuid,
        docTitle: docTitle,
        type: type
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {});
  }
  getClickEvents(action: string, pending: any[]): void {
    this.clickedGuid = pending['guid'];
    if (action == 'Edit Form') {
      this.goToEditForm(pending['guid']);
    } else if (action == 'Manage Responders' || action == 'Manage Approvers') {
      this.openResponderApproverModal(action, pending['guid'], pending['created_by']['name'], pending['title']);
    } else if (action == 'View Responders' || action == 'View Approvers') {
      this.viewResponderApproverOpenModal(pending['guid'], pending['title'], action);
    } else if (action == 'View Form Details') {
      this.router.navigate(['/organisation/form/form-details/', pending['guid']]);
    } else if (action == 'Edit Form Template') {
      this.viewForm(pending['guid']);
    } else if (action == 'Complete Edit') {
      this.completeEdit(pending['guid'], pending['title']);
    } else if (action == 'Approve Form') {
      this.approveForm(pending['guid'], pending['title']);
    } else if (action == 'Reassign Form') {
      this.changeRequestModal(pending['guid'], pending['title'], pending['created_by']['name']);
    } else if (action == 'Edit Document') {
      this.editForm(pending['guid']);
    } else if (action == 'View Document') {
      this.openDocument(pending['document_store_identifier']);
    }
  }
}
