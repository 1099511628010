<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div class="row">
    <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
      <h2 class="text-cyan m-0" translate>organisation_administration.Administrators</h2>
      <div>
        <button class="btn-add ml-4" (click)="openModal()" translate>
          <i class="fa fa-plus"></i>organisation_administration.Add Administrator
        </button>
      </div>
    </div>
  </div>
  <div class="row scroll-view" *ngIf="administratorList.length > 0; else other_content">
    <div
      class="col search-results"
      infiniteScroll
      [scrollWindow]="false"
      (scrolled)="onScroll()"
      [infiniteScrollDistance]="modalScrollDistance"
      [infiniteScrollThrottle]="modalScrollThrottle"
      (scrolledUp)="onScrollUp()"
    >
      <div class="table_wrapper my-5 mx-3">
        <table class="w-100p table-2">
          <thead class="bg-yellow-2">
            <tr class="border-0">
              <td>
                <h4 class="font-weight-bold text-black-2 p-3 mt-2" translate>organisation_administration.Name</h4>
              </td>
              <td>
                <h4 class="font-weight-bold text-black-2 p-3 mt-2" translate>
                  organisation_administration.Phone Number
                </h4>
              </td>
              <td>
                <h4 class="font-weight-bold text-black-2 p-3 mt-2" translate>organisation_administration.Email</h4>
              </td>
              <td><h4 class="font-weight-bold text-black-2 p-3 mt-2"></h4></td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let adminList of administratorList">
              <td>
                <h4 class="text-black-1 p-3 pr-3 w-240">{{ adminList['first_name'] }} {{ adminList['last_name'] }}</h4>
              </td>
              <td>
                <h4 class="text-black-1 p-3 pr-3 ">{{ adminList['phone'] !== null ? adminList['phone'] : '-' }}</h4>
              </td>
              <td>
                <h4 class="text-black-1 p-3">{{ adminList['email'] !== null ? adminList['email'] : '-' }}</h4>
              </td>
              <td>
                <a
                  class="text-black-1 p-3 pr-3 link-1 cursor-pointer"
                  (click)="openConfirm(adminList['first_name'], adminList['last_name'], adminList['guid'])"
                  ><u translate>organisation_administration.Remove</u></a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #other_content class="no-org-selected">
  <app-no-organisation-selected [typeMessage]="parentTypeMessage"></app-no-organisation-selected>
</ng-template>
&nbsp;
<h2 *ngIf="scrollLoader" class="font-weight-bold text-black-3 text-center">
  <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': scrollLoader }" style="font-size:large;"></i> &nbsp; Loading...
</h2>
