<div class="approve-document">
  <div class="publish-doc-label">
    <div class="label">{{ modalType === 'Approve Changes' ? 'Approve Document' : 'Continue Current Version' }}</div>
    <div>
      <i
        class="fa fa-times text-black-1"
        style="width: 12px;
                    height: 12px;
                    right: 1em;
                    cursor: pointer;
                    position: absolute;
                    top: 1.3em;"
        (click)="activeModal.close({ isUpdate: false })"
        ngbAutoFocus
        translate
      ></i>
    </div>
  </div>
  <div class="document-name-container">
    <div class="document-name-inner">
      <div class="document-name-label">Document Name</div>
      <div class="document-name truncated" title="{{ docTitle }}">
        {{ docTitle }}
      </div>
    </div>
  </div>
  <form [formGroup]="ApproveChangesForm" novalidate>
    <div class="remark-container">
      <div class="remark-label">Remarks</div>
      <div class="input-group">
        <textarea
          id="remarks"
          class="form-control cb-input remarks"
          [placeholder]="'Add Remarks'"
          name="remarks"
          type="text"
          formControlName="remarks"
          cols="64"
          rows="5"
          [maxLength]="4097"
        ></textarea>
        <div class="col-12 error-mssg" *ngIf="(f.remarks.touched || f.remarks.dirty) && f.remarks.errors">
          <label for="name" class="text-danger" *ngIf="f.remarks.errors.maxLength" translate
            >Mark_Editing_Done.Remarks must be 4096 characters or less</label
          >
        </div>
      </div>
    </div>

    <div class="signature-label">
      Signature
    </div>
    <div class="signature-container" *ngIf="UserSignature && !isSignatureLoading">
      <div style="padding: 20px;" *ngIf="!UserSignature && isSignatureLoading">
        <i class="fa fa-spinner fa-spin" style="font-size:24px;transform-origin: center;width: 20px;height: 20px;"></i>
      </div>
      <span class="readonly-box">
        <img
          [src]="UserSignature"
          *ngIf="UserSignature && !isSignatureLoading"
          style="height: 100px; width: 200px; margin-left: 44px; margin-top: -9px;"
        />
      </span>
    </div>
    <div class="d-flex pb-2 mt-1">
      <img
        src="./../../../assets/images/core/file_upload.svg"
        (click)="OpenChangeSignatureModal()"
        class="signature-icon  ml-2"
      />
      <a class="text-black-1 cursor-pointer ml-1 mt-1 mr-1" (click)="OpenChangeSignatureModal()" class="signature-icon"
        >Upload Signature</a
      >
      <img
        src="./../../../assets/images/core/drawsignature.svg"
        (click)="OpenDrawSignatureModal()"
        class="signature-icon ml-2"
      />
      <a class="text-black-1 cursor-pointer ml-1 mt-1 mr-1" (click)="OpenDrawSignatureModal()" class="signature-icon"
        >Draw Signature</a
      >
      <img
        src="./../../../assets/images/core/generatesignature.svg"
        (click)="OpenGenerateSignatureModal(userName)"
        class="signature-icon ml-2"
      />
      <a
        class="text-black-1 cursor-pointer ml-1 mt-1 mr-1"
        (click)="OpenGenerateSignatureModal(userName)"
        class="signature-icon"
        >Generate Signature</a
      >
    </div>

    <div class="confirm">
      <label class=" checkbox-2" translate>
        <input
          class="form-check-input"
          formControlName="agreement_accepted"
          type="checkbox"
          name="inlineRadioOptions"
          id="inlinecheck"
        />
        <span class="checkmark" translate></span>
        <div class="confirm-text">
          I have reviewed the document. I am approving the document using the above signature.
        </div>
      </label>
      <div class="col-12 error-mssg" *ngIf="!agreement_accepted">
        <label for="editor" class="text-danger" translate
          >Approve_Changes.approve_changes.*Please accept the agreement.</label
        >
      </div>
    </div>
  </form>
  <div class="footer">
    <button type="button" class="px-2 cb-btn-secondary" (click)="activeModal.close({ isUpdate: false })">Cancel</button>
    <button type="submit" class="btn-save cb-btn-primary" *ngIf="!isLoading" (click)="save()">
      {{ modalType === 'Approve Changes' ? 'Approve' : 'Submit' }}
    </button>
    <button type="submit" class="btn-save cb-btn-disabled" disabled *ngIf="isLoading">
      <i class="fa fa-spinner fa-spin"></i>
    </button>
  </div>
</div>
