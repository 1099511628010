import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from '@app/core/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganisationService } from '@app/core/organisation.service';
import { ConnectSlackComponent } from '@app/home/steps/connectSlack/connectSlack.component';
import { SelectProgramComponent } from '@app/home/steps/selectProgram/selectProgram.component';
import { ReviewScheduleComponent } from '@app/home/steps/reviewSchedule/reviewSchedule.component';
import { InviteCommitteeMembersComponent } from '@app/home/steps/inviteCommitteeMembers/inviteCommitteeMembers.component';
import { CreateBackendAutomationComponent } from '@app/home/steps/createBackendAutomation/createBackendAutomation.component';
import { ReviewRunFinishComponent } from '@app/home/steps/reviewRunFinish/reviewRunFinish.component';
import { Onboard7FinalComponent } from '@app/home/steps/onboard7-final/onboard7-final.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
  isLoading: boolean;
  formStep: FormGroup;
  showSelectOrgStep = true;
  step = 1;
  @ViewChild(ConnectSlackComponent, { static: false }) connectSlackComponent: ConnectSlackComponent;
  @ViewChild(SelectProgramComponent, { static: false }) selectProgramComponent: SelectProgramComponent;
  @ViewChild(ReviewScheduleComponent, { static: false }) reviewScheduleComponent: ReviewScheduleComponent;
  @ViewChild(InviteCommitteeMembersComponent, { static: false })
  inviteCommitteeMembersComponent: InviteCommitteeMembersComponent;
  @ViewChild(CreateBackendAutomationComponent, { static: false })
  createBackendAutomationComponent: CreateBackendAutomationComponent;
  @ViewChild(ReviewRunFinishComponent, { static: false }) reviewRunFinishComponent: ReviewRunFinishComponent;
  @ViewChild(Onboard7FinalComponent, { static: false }) onboard7FinalComponent: Onboard7FinalComponent;
  step_component: any = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private _user: UserService,
    private orgService: OrganisationService,
    private route: ActivatedRoute
  ) {
    this.formStep = fb.group([]);
    this.route.queryParams.subscribe(params => {
      if (params['code']) {
        this.step = 5;
      }
    });
  }

  ngOnInit() {
    this.isLoading = true;
    if (this._user.getUserRole() === 'organization_admin_role') {
      this.showSelectOrgStep = false;
    }
  }

  ngAfterViewInit() {
    this.step_component = [
      this.connectSlackComponent,
      this.selectProgramComponent,
      this.reviewScheduleComponent,
      this.inviteCommitteeMembersComponent,
      this.createBackendAutomationComponent,
      this.reviewRunFinishComponent,
      this.onboard7FinalComponent
    ];
  }

  next_step() {
    if (this.step_component[this.step - 1].canGoToNextStep()) {
      this.step = this.step + 1;
    }
  }
  previous_step() {
    if (this.step > 1) {
      // Todo: check if can goto next
      this.step = this.step - 1;
    }
  }
  runSelected() {
    this.reviewRunFinishComponent.submitForm();
    this.step = this.step + 1;
  }
  finishClicked() {
    const isOrganisationOnboarded = true;
    this.orgService.setIsOrganisationOnboarded(isOrganisationOnboarded);
    this.router.navigateByUrl('/organisation/events');
  }
}
