import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-save-responses',
  templateUrl: './save-responses.component.html',
  styleUrls: ['./save-responses.component.scss']
})
export class SaveResponsesComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}
  @Input() message: string;
  @Input() title_tag: string;
  ngOnInit(): void {}

  confirm() {
    this.activeModal.close({ isConfirmed: true });
  }
  discardChanges() {
    this.activeModal.close({ isConfirmed: false });
  }
}
