<div>
  <div class="modal-header cb-modal-header">
    <div>
      <h4 class="modal-title font-weight-bold text-black-1" translate>{{ title_tag }}</h4>
      <i
        class="fa fa-times text-black-1"
        style="width: 12px;
                height: 12px;
                right: 1em;
                cursor: pointer;
                position: absolute;
                top: 1.3em;"
        (click)="activeModal.close(false)"
        ngbAutoFocus
        translate
      ></i>
    </div>
  </div>
  <div class="modal-body">
    <div class="col-12 mt-2 ">
      <label class="text-black-1 p-2" style="width: 105%;" translate>
        {{
          title_tag === 'Form Created'
            ? 'New form ' + title + ' has been created.'
            : title_tag === 'Form Edited'
            ? 'Changes to ' + title + ' have been saved.'
            : title
        }}
      </label>
    </div>
  </div>
  <!-- <div class="modal-body">
      <div class="col-12 mt-2 ">
        <label class="text-black-1 p-2" translate>{{ incidentTitle }}&nbsp;{{ CampaignCreated }} </label>
      </div>
    </div> -->
  <div class="modal-footer border-0 cb-modal-footer">
    <button
      *ngIf="title_tag != 'Form Created'"
      type="button"
      class="btn btn-cancel ml-4"
      ngbAutoFocus
      translate
      (click)="activeModal.close(false)"
    >
      {{ Cancel }}
    </button>
    <button
      *ngIf="title_tag === 'Form Created'"
      type="button"
      class="btn btn-save1 ml-4"
      ngbAutoFocus
      translate
      (click)="viewForm(formGuid)"
    >
      Edit Template
    </button>
    <button type="button" class="btn btn-save1 ml-4" (click)="goToIncidentPage()" ngbAutoFocus translate>
      {{ title_tag === 'Incident Created' || title_tag === 'Incident Edited' ? View_Incident : View_Form }}
    </button>
  </div>
</div>
