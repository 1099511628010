<!--<div style="display: none">-->
<!--<h3>Select program</h3>-->
<!--<p class="description_tag">Please select the compliance programs you are implementing.</p>-->
<!--<div *ngIf="programs.length > 0">-->
<!--<form *ngIf="!isLoading" [formGroup]="stepForm" class="step-container">-->
<!--<div class="row">-->
<!--<div class="offset-3 col-md-6">-->
<!--&lt;!&ndash; <div-->
<!--formArrayName="programs"-->
<!--class="row"-->
<!--*ngFor="let program of stepForm.controls.programs['controls']; let i = index"-->
<!--&gt; &ndash;&gt;-->
<!--<div formArrayName="programs" class="row" *ngFor="let program of programs; let i = index">-->
<!--&lt;!&ndash;<input type="checkbox" [formControlName]="i" >&ndash;&gt;-->
<!--&lt;!&ndash;{{programs[i].name}}&ndash;&gt;-->
<!--<mat-checkbox [formControlName]="i">{{ program.name }}</mat-checkbox>-->
<!--</div>-->
<!--<br />-->
<!--<div *ngIf="!stepForm.valid" style="color: red">At least one program must be selected</div>-->

<!--&lt;!&ndash;<label class="control-label col-sm-3" style="display: inline-block;" for="slackURL">Slack URL:</label>&ndash;&gt;-->
<!--&lt;!&ndash;<div class="col-sm-8" style="display: inline-block;">&ndash;&gt;-->
<!--&lt;!&ndash;<input nbInput type="text" id="slackURL" class="form-control" formControlName="slackURL"&ndash;&gt;-->
<!--&lt;!&ndash;[ngClass]="{'form-control-danger': stepForm.invalid && (stepForm.dirty || stepForm.touched)}">&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--</div>-->
<!--</div>-->
<!--</form>-->
<!--</div>-->
<!--</div>-->

<div class="onboarding-main pt-4" *ngIf="show">
  <div class="container max-w-840">
    <div class="row">
      <div class="col">
        <h1 class="text-black-2 mb-3 font-weight-bold" translate>onboard.select_program.Select Program</h1>
        <p class="text-black-2 mb-4" translate>
          onboard.select_program.select_program_details
        </p>
        <ng-container *ngIf="programs.length > 0">
          <form class="w-240" *ngIf="!isLoading">
            <div class="input-group pt-2 mb-2" *ngFor="let program of programs; let i = index">
              <app-program-check-box
                (program_clicked)="add_remove_program(i)"
                [program]="program"
              ></app-program-check-box>
              <!--<label-->
              <!--class="form-check-label text-black-1 "-->
              <!--for="inlinecheck-{{ i }}"-->
              <!--&gt;-->
              <!--<input-->
              <!--class="form-check-input"-->
              <!--type="checkbox"-->
              <!--id="inlinecheck-{{ i }}"-->
              <!--[checked]="check_selected(program['guid'])"-->
              <!--/>-->
              <!--<span class="checkmark"></span>-->
              <!--{{ program.name }}-->
              <!--</label>-->
            </div>
            <div class="input-group pt-2 mb-2">
              <label class="form-check-label text-black-1 checkbox-2" for="inlinecheck1">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="inlineRadioOptions"
                  id="inlinecheck1"
                  value="option2"
                  disabled
                />
                <span class="checkmark"></span>
                SOC2
                <span class="coming-soon-txt ml-4 d-inline-block" translate>onboard.select_program.Coming soon!</span>
              </label>
            </div>
          </form>
          <div *ngIf="form_submitted && selected_programs.length == 0" style="color: red" translate>
            onboard.select_program.At least one program must be selected
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
