import { Component, OnDestroy, OnInit } from '@angular/core';
import { OrganisationService } from '@app/core/organisation.service';
import { SettingsService } from '@app/settings/settings.service';
import { ModalService } from '@app/core/modal/modal.service';
import { finalize, take } from 'rxjs/operators';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { UserService } from '@app/core/user.service';
import { TranslateService } from '@ngx-translate/core';
import { WizardService } from '@app/home/wizard.services';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { MixpanelService } from '@app/mixpanel/mixpanel.service';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.scss']
})
export class PeopleComponent implements OnInit, OnDestroy {
  OrganisationID: string | null;
  error: any;
  guidSubscription: any;
  peopleList: object[];
  roles = {
    organization_user_role: 'User',
    organization_admin_role: 'Administrator'
  };
  search_text: any;
  page = 1;
  search_title: any = '';
  success = false;
  apiError = false;
  userEmail: string;
  parentTypeMessage = 'no-people-added';
  scrollLoader = false;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  pagnationData: any;
  loading = true;
  paginatedDataUrl: any;
  domainName = '';
  peopleStatus: [];
  statusType: any = 'Active';
  orgPasswordBasedLogin: boolean = true;
  clickedGuid = '';

  constructor(
    private orgService: OrganisationService,
    private settingsService: SettingsService,
    private documentsService: DocumentsService,
    private modalService: ModalService,
    private toaster: ToasterService,
    private user: UserService,
    private translate: TranslateService,
    private mixpanel: MixpanelService
  ) {
    this.orgPasswordBasedLogin = localStorage.getItem('org_password_based_login') == 'true' ? true : false;
    this.getDomainName(localStorage.getItem('domain'));
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.userEmail = this.user.getuserdetails().user['email'];
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
    });
    this.getPeopleList();
  }

  onScrollUp() {
    this.scrollLoader = false;
  }

  updateSearchText(x: any) {
    this.search_text = x.target.value;
    if (this.search_title == undefined) {
      this.search_text = '';
    }
  }

  Search(search_title: string) {
    console.log('search_title', search_title);
    this.search_title = search_title ? search_title : '';
    this.page = 1;
    this.settingsService
      .getOrganizationPeopleListWithPagination(this.OrganisationID, this.page, this.search_title, this.statusType)
      .subscribe(
        data => {
          this.peopleList = data.results;
          this.paginatedDataUrl = data;
          this.loading = false;
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
  }

  onScrollData() {
    this.scrollLoader = true;
    this.settingsService
      .getOrganizationPeopleListWithPagination(
        this.OrganisationID,
        (this.page += 1),
        this.search_title,
        this.statusType
      )
      .subscribe(
        data => {
          this.scrollLoader = false;
          this.pagnationData = data.results;
          this.paginatedDataUrl = data;
          this.peopleList = this.peopleList.concat(data.results);
        },
        error => {
          this.scrollLoader = false;
          this.error = error.error.message;
        }
      );
  }
  onScroll() {
    if (this.paginatedDataUrl?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }
  onStatusChange(evt: any) {
    this.statusType = evt;
    this.getPeopleList();
  }

  openConfirm(firstName?: string, lastName?: string, userGuid?: string) {
    this.modalService
      .confirm('Do you want to remove ' + firstName + ' ' + lastName + '?')
      .pipe(take(1))
      .subscribe((confirmed: any) => {
        if (confirmed) {
          this.removeUser(userGuid);
        }
      });
  }

  openPeopleForm(formType: string, formData?: any) {
    formData ? this.editPeopleOpenModal(formType, formData) : this.addPeopleOpenModal();
  }

  editPeopleOpenModal(formType: string, formData: any): void {
    this.clickedGuid = formData['guid'];
    this.modalService
      .custom(CustomDialogComponent, { title: 'Edit people', modalType: 'people', formType: formType, formData })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          for (let i = 0; i < this.peopleList.length; i++) {
            if (this.clickedGuid == this.peopleList[i]['guid']) {
              this.peopleList[i] = result.result;
              break;
            }
          }
          this.translate.get('organisation_people.people_toaster_message.edit_people').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  addInactivePeopleModal(formData: any): void {
    this.clickedGuid = formData['guid'];
    this.modalService
      .custom(CustomDialogComponent, { title: 'Add people', modalType: 'Add people', formData })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          for (let i = 0; i < this.peopleList.length; i++) {
            if (this.clickedGuid == this.peopleList[i]['guid']) {
              this.peopleList[i] = result.result;
              break;
            }
          }
          this.peopleList = this.peopleList.filter((item: any) => item['is_deleted']);
          this.translate.get('organisation_people.people_toaster_message.edit_people').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }
  addPeopleOpenModal(): void {
    this.modalService
      .custom(CustomDialogComponent, { title: 'Add people', modalType: 'people' })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getPeopleList();
          this.translate.get('organisation_people.people_toaster_message.add_people').subscribe(res => {
            this.toaster.showSuccess(res);
          });
          this.mixpanel.sendCBEvent('new_user');
        } else {
          console.log('Cancelled.');
        }
      });
  }

  removeUser(guid: string): void {
    this.settingsService.deleteOrganizationPeopleUser(guid, this.OrganisationID).subscribe(
      data => {
        this.getPeopleList();
        this.translate.get('organisation_people.people_toaster_message.remove_people').subscribe(res => {
          this.toaster.showSuccess(res);
        });
      },
      error => {
        this.error = error.error.message;
        this.translate.get('organisation_people.people_toaster_message.error_people').subscribe(res => {
          this.toaster.showError(res);
        });
        console.log('Error:', error.error.message);
      }
    );
  }

  showMessage(result: boolean): void {
    if (result) {
      this.success = true;
      setTimeout(() => {
        this.success = false;
      }, 5000);
    } else {
      this.apiError = true;
      setTimeout(() => {
        this.apiError = false;
      }, 5000);
    }
  }

  private getPeopleList(): void {
    this.page = 1;
    this.settingsService
      .getOrganizationPeopleListWithPagination(this.OrganisationID, this.page, this.search_title, this.statusType)
      .subscribe(
        data => {
          this.peopleList = data.results;
          this.paginatedDataUrl = data;
          this.peopleList = this.peopleList.concat(
            data.results.filter((data: any) => this.peopleList.every(item => item['guid'] !== data['guid']))
          );
          this.loading = false;
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
  }
  getDomainName(url: string) {
    const domainRegex = /^(?:https?:\/\/)?(?:www\.)?([^/?]+).*/;
    const match = url?.match(domainRegex);
    this.domainName = match ? match[1] : null;
  }

  checkNonDomain(email = '') {
    if (!email) return false;
    if (email.includes(this.domainName)) return false;
    return true;
  }
  enablePasswordBasedLogin(person: any = {}) {
    let payload = { email: person.email, password_based_login: person['password_based_login'] };
    console.log(payload);
    this.settingsService
      .changePasswordBasedLogin(person['guid'], this.OrganisationID, payload)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.loading = false;
        },
        (error: any) => {
          this.loading = false;
          this.error = error.error;
        }
      );
  }
}
