import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { NgbActiveModal, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import stringInfo from '../../../../translations/en-US.json';
import { TranslateService } from '@ngx-translate/core';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { IncidentService } from '@app/Organisation-admin/incident/incident.service';

@Component({
  selector: 'app-reassign-approver',
  templateUrl: './reassign-approver.component.html',
  styleUrls: ['./reassign-approver.component.scss']
})
export class ReassignApproverComponent implements OnInit {
  RestorApproverForm: FormGroup;
  submitted = false;
  isLoading = false;
  OrganisationID: string | null;
  guidSubscription: any;
  selectedItems: any = [];
  success: boolean;
  error: any;
  EditorSelected = true;
  date = stringInfo.Reassign_Approver.Date;
  loading = true;
  store_due_date = '';

  @Input() formType: string;
  @Input() guid: string;
  @Input() title_tag: string;
  @Input() message: string;
  @Input() DocumentGuid: string;
  @Input() formGuid: string;
  @Input() docTitle: string;
  @Input() due_date: any;
  @Input() module: string;
  @Input() incidentGuid: string;
  @Input() type: string;
  backend_error: string;

  statusData: any;
  minDate: NgbDateStruct;
  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private documentsService: DocumentsService,
    private translate: TranslateService,
    private incidentService: IncidentService
  ) {}

  ngOnInit() {
    this.minDate = this.documentsService.getTomorrowDate();
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      if (this.due_date != null) {
        this.store_due_date = this.due_date;
        const due_date_arr = this.due_date.split('-');
        this.due_date = new NgbDate(parseInt(due_date_arr[0]), parseInt(due_date_arr[1]), parseInt(due_date_arr[2]));
      }
      this.getRestorApproverForm();
      this.loading = false;
    });
  }
  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }
  getRestorApproverForm() {
    this.RestorApproverForm = this.formBuilder.group({
      due_date: [this.due_date, [Validators.required(), Validators.DateIsAfterToday()]],
      comment: ['', [Validators.maxLength(4096)]]
    });
  }

  onDateSelection(date: any) {
    if (date) {
      let myDate = new Date(date.year, date.month - 1, date.day + 1);
      this.store_due_date = myDate.toISOString().split('T')[0];
    } else {
      this.store_due_date = this.store_due_date;
    }
  }

  get f() {
    return this.RestorApproverForm.controls;
  }
  save() {
    this.submitted = true;

    let editor: any = [];
    this.isLoading = true;
    if (this.RestorApproverForm.valid) {
      editor = this.guid;
      const RestoreData = this.RestorApproverForm.value;
      RestoreData['organization_users'] = [editor];
      RestoreData['role'] =
        this.type == 'Manage Reviewer' ? 'reviewer' : this.type == 'Manage Responders' ? 'responder' : 'approver';
      RestoreData['due_date'] = this.store_due_date;
      this.submitInviteApprover(RestoreData);
    } else {
      this.isLoading = false;
      return;
    }
  }

  changeName(statusData: any) {
    this.documentsService.setStatus11(statusData);
  }
  changeNameApprover(statusData: any) {
    this.incidentService.setStatusforReassignApprover(statusData);
  }
  changeNameReviewer(reviewerstatusData: any) {
    this.incidentService.setStatusforReassignReviewer(reviewerstatusData);
  }
  submitInviteApprover(RestoreData: any): void {
    (this.module == 'Incident'
      ? this.incidentService.submitIncidentReviewerData(this.OrganisationID, this.incidentGuid, RestoreData)
      : this.module == 'Form'
      ? this.documentsService.submitInviteFormResponderData(this.OrganisationID, this.formGuid, RestoreData)
      : this.documentsService.submitInviteDocumentEditorData(this.OrganisationID, this.DocumentGuid, RestoreData)
    )
      .pipe(
        finalize(() => {
          this.RestorApproverForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.error = {
            due_date: ''
          };
          this.submitted = false;
          this.backend_error = '';
          if (this.module == 'Incident' || this.module == 'Form') {
            this.activeModal.close({ result: data, isUpdate: true });
          } else {
            this.statusData = data;
            this.changeName(this.statusData);
            this.changeNameApprover(this.statusData);
            this.changeNameReviewer(this.statusData);
            this.activeModal.close(true);
          }
        },
        (error: any) => {
          this.submitted = false;
          this.isLoading = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
  }
}
