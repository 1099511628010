<div class="container-fluid">
  <div class="jumbotron text-center">
    <div class="container">
      <div class="row">Front End <br /></div>
      <div class="row">
        <div class="col-md-3">Version</div>
        <div class="col-md-3">{{ version.appVersion }}</div>
      </div>
      <div class="row">
        <div class="col-md-3">GIT</div>
        <div class="col-md-3">{{ version['gitHash'] }}</div>
      </div>
    </div>

    <div class="container">
      <div class="row">Back End <br /></div>
      <!--<div class="row">-->
      <!--<div class="col-md-3">Version</div>-->
      <!--<div class="col-md-3">{{ version.appVersion }}</div>-->
      <!--</div>-->
      <div class="row">
        <div class="col-md-3">GIT</div>
        <div class="col-md-3">{{ bkVersion }}</div>
      </div>
    </div>
  </div>
</div>
