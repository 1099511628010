import { E } from '@angular/cdk/keycodes';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { retry } from 'rxjs-compat/operator/retry';

@Injectable()
export class SettingsService {
  signature_image: any = [];
  sig_image: string;
  checkFont: boolean = false;

  constructor(private http: HttpClient) {}

  getUserProfile(): Observable<any> {
    return this.http.get(`/api/users/profile/`, {
      withCredentials: true
    });
  }

  // Administrator api calls
  getOrganizationAdminnistrationList(org_uuid: string, page: number): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/users/?role=organization_admin_role&page=${page}`, {
      withCredentials: true
    });
  }

  revokeOrAddOrganizationAdminnistrationUser(user_uuid: string, org_uuid: string, event_type: string): Observable<any> {
    const payload = {
      role: 'organization_admin_role',
      value: event_type
    };
    return this.http.put(`/api/organizations/${org_uuid}/users/${user_uuid}/roles/`, payload, {
      withCredentials: true
    });
  }

  // People api calls
  getOrganizationPeopleList(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/users/`, {
      withCredentials: true
    });
  }

  getOrganizationPeopleListLite(org_uuid: string, value: Boolean): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/users/?isTrainingCamp=${value}`, {
      withCredentials: true
    });
  }

  getOrganizationPeopleListWithPagination(
    org_uuid: string,
    page: number,
    search_title?: string,
    statusType?: string
  ): Observable<any> {
    var is_deleted: boolean;
    if (statusType == 'Active') {
      is_deleted = false;
    } else {
      is_deleted = true;
    }
    let url = `/api/organizations/${org_uuid}/users`;
    url = url + '/?is_deleted=' + is_deleted + '&search=' + search_title + '&page=' + page;
    return this.http.get(url, {
      withCredentials: true
    });
  }

  getOrganizationSearchPeopleList(org_uuid: string, search_title: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/users/?search=${search_title}`, {
      withCredentials: true
    });
  }

  getOrganizationPeopleListWithdocReaderRoleAndPagination(
    org_uuid: string,
    page: number,
    docReaderRole: Boolean
  ): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/users/?page=${page}&docReaderRole=${docReaderRole}`, {
      withCredentials: true
    });
  }

  deleteOrganizationPeopleUser(user_uuid: string, org_uuid: string): Observable<any> {
    return this.http.delete(`/api/organizations/${org_uuid}/users/${user_uuid}/`, {
      withCredentials: true
    });
  }

  submitOrganisationAddPeopleProfile(org_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/users/`, payload, {
      withCredentials: true
    });
  }

  submitOrganisationAddEventData(org_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/events/`, payload, {
      withCredentials: true
    });
  }

  trainingGroupMemberAdd(org_uuid: string, user_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/training_group/${user_uuid}/add/`, payload, {
      withCredentials: true
    });
  }

  submitOrganisationEditPeopleProfile(user_uuid: string, org_uuid: string, payload: any): Observable<any> {
    return this.http.put(`/api/organizations/${org_uuid}/users/${user_uuid}/`, payload, {
      withCredentials: true
    });
  }
  changePasswordBasedLogin(user_uuid: string, org_uuid: string, payload: any): Observable<any> {
    return this.http.put(`/api/organizations/${org_uuid}/update_password_based_login/${user_uuid}/`, payload, {
      withCredentials: true
    });
  }

  // Committee api calls
  getPaginatedOrganizationCommitteeList(org_uuid: string, page: number): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/committees/?page=${page}`, {
      withCredentials: true
    });
  }

  getOrganizationCommitteeList(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/committees/`, {
      withCredentials: true
    });
  }

  organisationalProgram(org_uuid: string): Observable<any> {
    return this.http.get('/api/organizations/' + org_uuid + '/organizationprograms/', {
      withCredentials: true
    });
  }

  getTrainingGroupList(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/training_group/`, {
      withCredentials: true
    });
  }

  getTrainingGroupListWithPagination(org_uuid: string, page: number, fetch_users: any): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/training_group/?page=${page}&fetch_users=${fetch_users}`, {
      withCredentials: true
    });
  }

  updateTrainingGroupTitle(org_uuid: string, group_guid: string, payload: any): Observable<any> {
    return this.http.put(`/api/organizations/${org_uuid}/training_group/${group_guid}/`, payload, {
      withCredentials: true
    });
  }
  getTrainingGroupUserList(
    org_uuid: string,
    fetch_users: any,
    clickedGuid: any = [],
    page: number = 1
  ): Observable<any> {
    return this.http.get(
      `/api/organizations/${org_uuid}/training_group/?page=${page}&fetch_users=${fetch_users}&guid=${JSON.stringify(
        clickedGuid
      )}`,
      {
        withCredentials: true
      }
    );
  }

  revokeOrAddOrganizationCommitteeMember(
    committee_uuid: string,
    org_uuid: string,
    email: string,
    action_type: string
  ): Observable<any> {
    let url = '';
    const payload = [
      {
        email: email
      }
    ];
    if (action_type === 'revoke') {
      url = `/api/organizations/${org_uuid}/committees/${committee_uuid}/members/remove/`;
    } else {
      url = `/api/organizations/${org_uuid}/committees/${committee_uuid}/members/`;
    }
    return this.http.post(url, payload, {
      withCredentials: true
    });
  }

  submitOrganisationNewCommittee(org_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/committees/`, payload, {
      withCredentials: true
    });
  }

  // TODO : Need to integrate the api: Dummy API STRRUCTURE NOW
  revokeOrganizationCommitteeUser(user_uuid: string, org_uuid: string): Observable<any> {
    const payload = {
      role: 'organization_admin_role',
      value: 'revoke'
    };
    return this.http.put(`/api/organizations/${org_uuid}/users/${user_uuid}/roles/`, payload, {
      withCredentials: true
    });
  }

  // Operations api calls
  getOrganizationOperationData(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/operations/`, {
      withCredentials: true
    });
  }

  submitOperationalUserProfile(payload: any, org_uuid: string): Observable<any> {
    return this.http.put(`/api/organizations/${org_uuid}/operations/`, payload, {
      withCredentials: true
    });
  }

  // Communication api calls
  getOrganizationCommunicationData(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/communications/`, {
      withCredentials: true
    });
  }

  submitOrganisationCommunication(payload: any, org_uuid: string, communication_uuid: string): Observable<any> {
    if (communication_uuid === '') {
      return this.http.post(`/api/organizations/${org_uuid}/communications/`, payload, {
        withCredentials: true
      });
    } else {
      return this.http.put(`/api/organizations/${org_uuid}/communications/${communication_uuid}/`, payload, {
        withCredentials: true
      });
    }
  }

  // User profile
  uploadUserPhoto(formData: any): Observable<any> {
    return this.http.post(`/api/users/profile-photo/`, formData, {
      withCredentials: true
    });
  }

  submitUserProfile(payload: any): Observable<any> {
    return this.http.put(`/api/users/profile/`, payload, {
      withCredentials: true
    });
  }

  getUserPhoto(): Observable<any> {
    return this.http.get(`/api/users/profile-photo/`, {
      withCredentials: true
    });
  }
  removeUserPhoto(): Observable<any> {
    return this.http.delete(`/api/users/profile-photo/`, {
      withCredentials: true
    });
  }
  getOrganizationMeetingWithStatus(org_uuid: string, frequency: string = null): Observable<any> {
    let url = `/api/organizations/${org_uuid}/meetingstatus/`;
    if (frequency) {
      url = url + '?frequency=' + frequency;
    }
    return this.http.get(url, {
      withCredentials: true
    });
  }

  runReviewEventNow(guid: string): Observable<any> {
    return this.http.get(`/api/events/${guid}/runrevieweventnow/`, {
      withCredentials: true
    });
  }
  stopReviewEventNow(guid: string): Observable<any> {
    return this.http.get(`/api/events/${guid}/endrevieweventnow/`, {
      withCredentials: true
    });
  }
  organizationEventScheduleUpdate(org_uuid: string, event_guid: string, payload: object): Observable<any> {
    return this.http.put(`/api/organizations/${org_uuid}/events/${event_guid}/schedule/`, payload, {
      withCredentials: true
    });
  }

  isOrganisationOnboarded(org_guid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_guid}/organizationonboardchecker/`, {
      withCredentials: true
    });
  }

  getEventDetails(org_guid: string, review_event_guid: string): Observable<any> {
    return this.http.get<any>('/api/organizations/' + org_guid + '/events/' + review_event_guid + '/', {
      withCredentials: true
    });
  }

  disableEvent(org_guid: string, review_event_guid: string, event: object): Observable<any> {
    let context = {
      is_enabled: event['is_enabled'],
      reason_for_disabled: event['reason_for_disabled']
    };
    return this.http.put<any>(
      '/api/organizations/' + org_guid + '/events/' + review_event_guid + '/enable-disable-event/',
      context,
      {
        withCredentials: true
      }
    );
  }

  getUrlTypes(): Observable<any> {
    return this.http.get<any>('/api/events/urltypes/', {
      withCredentials: true
    });
  }

  getOrganisationScheduleTypes(org_guid: string): Observable<any> {
    return this.http.get<any>('/api/organizations/' + org_guid + '/schedules/', {
      withCredentials: true
    });
  }

  deleteReviewDoc(org_guid: string, review_event_guid: string, review_document_guid: string): Observable<any> {
    return this.http.delete<any>(
      '/api/organizations/' + org_guid + '/events/' + review_event_guid + '/review-docs/' + review_document_guid,
      {
        withCredentials: true
      }
    );
  }

  // Get Country list
  getCountryData(): Observable<any> {
    return this.http.get(`/api/countries/`, {
      withCredentials: true
    });
  }

  // Get Timezone list as per selected country
  getTimezoneDataAsPerSelectedCountry(countryId: string | number): Observable<any> {
    return this.http.get(`/api/timezones/?country_code=${countryId}`, {
      withCredentials: true
    });
  }

  // CbAgent APi call
  getCbAgent(org_guid: string) {
    return this.http.get(`/api/organizations/${org_guid}/cbagent/`, {
      withCredentials: true
    });
  }

  updateCbAgentEnvironment(org_uuid: string, payload: object): Observable<any> {
    return this.http.put(`/api/organizations/${org_uuid}/cbagent_environment/`, payload, {
      withCredentials: true
    });
  }

  updateCbAgentCode(payload: object): Observable<any> {
    return this.http.post(`/api/cbagent/agent/update`, payload, {
      withCredentials: true
    });
  }

  getActionCommands(org_guid: string, node_id: string, page: number) {
    return this.http.get(`/api/organizations/${org_guid}/cbagent/${node_id}/action_comands/?page=${page}`, {
      withCredentials: true
    });
  }

  getDeregister(org_guid: string, node_id: string) {
    return this.http.get(`/api/organizations/${org_guid}/cbagent/${node_id}/deregister`, {
      withCredentials: true
    });
  }

  checkDeregisterUpdate(org_guid: string, node_id: string) {
    return this.http.get(`/api/organizations/${org_guid}/cbagent/${node_id}/check_update`, {
      withCredentials: true
    });
  }

  private cbEnvChange = new Subject<boolean>();
  cbEnvChange$ = this.cbEnvChange.asObservable();

  sendcbEnvChange(message: boolean) {
    this.cbEnvChange.next(message);
  }

  // People api calls
  getOrganizationGroupsList(): Observable<any> {
    return this.http.get(`/api/groups/`, {
      withCredentials: true
    });
  }

  uploadUserSignature(org_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/signature/upload/`, payload, {
      withCredentials: true
    });
  }

  uploadUserSignatureToGCP(url: string, payload: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'image/png',
      'x-goog-content-length-range': '100,5000000',
      'Access-Control-Request-Method': 'PUT'
      // 'Access-Control-Allow-Origin': 'http://localhost:4200'
    });

    return this.http.put(url, payload, {
      headers: headers
    });
  }

  getUserSignature(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/signature/me/`, {
      withCredentials: true
    });
  }

  isUniqueArr(arr: any) {
    const tmp = new Set(arr);
    if (tmp.size > 1) {
      return true;
    }
    return false;
  }

  GenerateSignature(user_name: string, fonts: any) {
    if (user_name) {
      if (!this.checkFont) {
        this.signature_image = [];
        for (let i = 0; i < fonts.length; i++) {
          const canvas = document.createElement('canvas');
          canvas.style.display = 'none';
          document.body.appendChild(canvas);
          const context = canvas.getContext('2d')!;
          context.font = fonts[i];
          canvas.width = context.measureText(user_name).width + 50;
          canvas.height = 48 * 1.5;
          context.fillStyle = '#FFFFFF';
          context.fillRect(0, 0, canvas.width, 300);
          context.fillStyle = '#000000';
          context.font = fonts[i];
          context.fillText(user_name, 0, canvas.height / 1.5);
          context.stroke();
          this.sig_image = canvas.toDataURL('image/png');
          this.signature_image.push(this.sig_image);
        }
        this.checkFont = this.isUniqueArr(this.signature_image);
        return this.signature_image;
      }
      return this.signature_image;
    }
  }
  GenerateInitialSignature(user_name: string, fonts: any) {
    if (user_name) {
      this.signature_image = [];
      for (let i = 0; i < fonts.length; i++) {
        const canvas = document.createElement('canvas');
        canvas.style.display = 'none';
        document.body.appendChild(canvas);
        const context = canvas.getContext('2d')!;
        context.font = fonts[i];
        canvas.width = context.measureText(user_name).width + 50;
        canvas.height = 48 * 1.5;
        context.fillStyle = '#FFFFFF';
        context.fillRect(0, 0, canvas.width, 300);
        context.fillStyle = '#000000';
        context.font = fonts[i];
        context.fillText(user_name, 0, canvas.height / 1.5);
        context.stroke();
        this.sig_image = canvas.toDataURL('image/png');
        this.signature_image.push(this.sig_image);
      }
      this.checkFont = this.isUniqueArr(this.signature_image);
      return this.signature_image;
      return this.signature_image;
    }
  }
}
