import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class OrganisationSystemAdminService {
  constructor(private http: HttpClient) {}

  getOrganizationDetails(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/`, {
      withCredentials: true
    });
  }

  isOrganisationOnboarded(org_guid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_guid}/organizationonboardchecker/`, {
      withCredentials: true
    });
  }
}
