<!--<div style="display: none">-->
<!--<h3>Connect Slack</h3>-->
<!--<p class="description_tag">-->
<!--Please install the app on your slack by clicking the button below, so that ComplianceBot can communicate with your-->
<!--team.-->
<!--</p>-->
<!--<form [formGroup]="stepForm" class="step-container" *ngIf="!isLoading">-->
<!--<div class="row">-->
<!--<div class="offset-1 col-md-6">-->
<!--<div *ngIf="organizationData.SLACK_API_KEY">-->
<!--<span>Slack is already configured.</span><br />-->
<!--<span> To reconfigure the slack please click on the button:</span>-->
<!--</div>-->
<!--</div>-->
<!--<div class="col-md-5 form-group">-->
<!--&lt;!&ndash;<label class="control-label col-sm-4" style="display: inline-block;" for="SLACK_API_KEY">Slack API key:</label>&ndash;&gt;-->
<!--<div style="display: inline-block;">-->
<!--<a (click)="install_slack_app()">-->
<!--<img-->
<!--alt="Add to Slack"-->
<!--height="40"-->
<!--width="139"-->
<!--src="https://platform.slack-edge.com/img/add_to_slack.png"-->
<!--srcset="-->
<!--https://platform.slack-edge.com/img/add_to_slack.png    1x,-->
<!--https://platform.slack-edge.com/img/add_to_slack@2x.png 2x-->
<!--"-->
<!--/>-->
<!--</a>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--</form>-->
<!--</div>-->

<div class="onboarding-main pt-4" *ngIf="show">
  <div class="container max-w-840">
    <div class="row">
      <div class="col">
        <h1 class="text-black-2 mb-3 font-weight-bold" translate>onboard.connect_slack.Connect Slack</h1>
        <p class="text-black-2 mb-5" translate>
          onboard.connect_slack.connect_slack_details
        </p>
      </div>
    </div>
    <div class="row" *ngIf="!isLoading">
      <div class="col" *ngIf="!organizationData.SLACK_API_KEY">
        <a (click)="install_slack_app()">
          <img
            alt="Add to Slack"
            height="40"
            width="139"
            src="https://platform.slack-edge.com/img/add_to_slack.png"
            srcset="
              https://platform.slack-edge.com/img/add_to_slack.png    1x,
              https://platform.slack-edge.com/img/add_to_slack@2x.png 2x
            "
          />
        </a>
      </div>
      <button
        *ngIf="!isLoading && organizationData.SLACK_API_KEY"
        class="configure-slack"
        (click)="install_slack_app()"
      >
        <img src="../../../../assets/images/slack/slack.png" /><strong translate
          >onboard.connect_slack.Re-configure Slack</strong
        >
      </button>
      <ng-container *ngIf="!isLoading">
        <div *ngIf="organizationData.SLACK_API_KEY" class="col-12 mt-3">
          <span translate>onboard.connect_slack.Slack is already configured</span><br />
          <span translate> onboard.connect_slack.To reconfigure the slack please click on the button</span>
        </div>
      </ng-container>
    </div>
  </div>
</div>
