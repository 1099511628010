import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OrganisationDashboardService } from '../orgDashboard.service';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { ControlSwitchDialogComponent } from '../control-switch-dialog/control-switch-dialog.component';

@Component({
  selector: 'app-manage-controls',
  templateUrl: './manage-controls.component.html',
  styleUrls: ['./manage-controls.component.scss']
})
export class ManageControlsComponent implements OnInit {
  payload: any = {
    true_applicable_units: [],
    false_applicable_units: [],
    default_org_type: ''
  };
  controlsData: any[] = [];
  isScrollable: boolean = true;
  scrollLoader: boolean = false;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 30;
  previousSeletedOption = '';
  totalCount = 0;
  orgType = '';
  organisationType: any = '';
  organiationTypes: any = [];
  displayedColumns: string[] = ['control', 'title', 'applicability'];
  dataSource = new MatTableDataSource<any>(this.controlsData);
  selection = new SelectionModel<any>(true, []);
  overviewPage = 1;
  isUpdate = false;
  controlsUpdated = false;
  constructor(
    public dialogRef: MatDialogRef<ManageControlsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = {},
    private orgDashboardService: OrganisationDashboardService,
    private toaster: ToasterService,
    private dialog: MatDialog
  ) {}
  ngOnInit(): void {
    this.getOrganisationType();
    this.organisationType = this.data.defaultOrganisationType;
    this.payload['default_org_type'] = this.organisationType;
    this.previousSeletedOption = this.organisationType;
    this.getProgramUnitLite(1, false, this.data.defaultOrganisationType);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  selectControl(event: any, guid: string) {
    this.isUpdate = true;
    if (event.checked) {
      this.payload['true_applicable_units'].push(guid);
      this.payload['true_applicable_units'] = [...new Set(this.payload['true_applicable_units'])];
      this.payload['false_applicable_units'] = this.payload['false_applicable_units'].filter(
        (item: any) => item !== guid
      );
      if (this.controlsData.some(item => item.guid == guid && item.applicable)) {
        this.payload['true_applicable_units'] = this.payload['true_applicable_units'].filter(
          (item: any) => item !== guid
        );
      }
    } else {
      this.payload['false_applicable_units'].push(guid);
      this.payload['false_applicable_units'] = [...new Set(this.payload['false_applicable_units'])];
      this.payload['true_applicable_units'] = this.payload['true_applicable_units'].filter(
        (item: any) => item !== guid
      );
      if (this.controlsData.some(item => item.guid == guid && !item.applicable)) {
        this.payload['false_applicable_units'] = this.payload['false_applicable_units'].filter(
          (item: any) => item !== guid
        );
      }
    }
  }
  onNoClick(): void {
    this.dialogRef.close(this.controlsUpdated);
  }
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }
  getOrgType(org: any) {
    return org.map((item: any) => item.title);
  }
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  getOrganisationType() {
    this.orgDashboardService.getOrganizationType(this.data.organisationID).subscribe(res => {
      this.organiationTypes = res;
    });
  }
  getProgramUnitLite(page = 1, resetData = false, orgType = '', isUpdate = false) {
    this.scrollLoader = true;
    this.orgDashboardService.getProgramUnitLite(this.data.organisationID, this.data.programGuid, orgType).subscribe(
      res => {
        this.controlsData = res['results'];
        if (isUpdate) {
          this.controlsData.forEach(item => {
            item['applicable'] = true;
          });
        } else {
          this.controlsData = res['results'];
        }
        this.dataSource.data = this.controlsData;
        this.isScrollable = true;
        this.scrollLoader = false;
        this.payload['true_applicable_units'] = this.controlsData
          .filter(item => item.applicable)
          .map(item => item.guid);
      },
      error => {
        this.isScrollable = false;
        this.scrollLoader = false;
      }
    );
  }

  updateApplicableData() {
    let payload = JSON.parse(JSON.stringify(this.payload));
    for (let index = payload['false_applicable_units'].length - 1; index >= 0; index--) {
      const unit = payload['false_applicable_units'][index];
      if (payload['true_applicable_units'].includes(unit)) {
        payload['false_applicable_units'].splice(index, 1);
      }
    }
    this.orgDashboardService
      .updateApplicability(this.data.organisationID, this.data.programGuid, payload)
      .subscribe(res => {
        this.isUpdate = false;
        this.controlsUpdated = true;
        this.payload['true_applicable_units'] = [];
        this.payload['false_applicable_units'] = [];
        this.toaster.showSuccess('Applicable controls updated.');
        this.getProgramUnitLite(1, true, this.organisationType);
      });
  }
  openDialog(value: string): void {
    const dialogRef = this.dialog.open(ControlSwitchDialogComponent, {
      data: {}
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.payload['false_applicable_units'] = this.payload['true_applicable_units'];
        this.payload['default_org_type'] = value;
        this.getProgramUnitLite(1, true, value, true);
        this.previousSeletedOption = value;
        this.isUpdate = true;
      } else {
        this.organisationType = this.previousSeletedOption;
      }
    });
  }
  applyFilters(event: any) {
    this.openDialog(event.value);
  }
}
