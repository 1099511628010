import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { OrganisationAdminService } from '@app/Organisation-admin/organisation-admin.service';
import { OrganisationService } from '@app/core/organisation.service';
import * as _ from 'lodash';
import { DocumentsService } from '../documents.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { ModalService } from '@app/core/modal/modal.service';
import { take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import stringInfo from '../../../../translations/en-US.json';
import { ActivatedRoute } from '@angular/router';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';

declare var require: any;
const moment = require('moment');

@Component({
  selector: 'app-edit-documents',
  templateUrl: './edit-documents.component.html',
  styleUrls: ['./edit-documents.component.scss']
})
export class EditDocumentsComponent implements OnInit, AfterViewInit {
  @Input() dialogData: any = null;
  isloading = false;
  docTitle = stringInfo.Create_Document.Doc_Title;
  time = stringInfo.Create_Document.Time;
  version = stringInfo.Create_Document.Version_Placeholder;
  FolderList: object[];
  SubFolderList: object[];
  document_types: any = [];
  OrganisationID: string;
  DocStoreguid: string;
  guid: any;
  addDocumentForm: FormGroup;
  selectFolderForm: FormGroup;
  title: any;
  submitted = false;
  allowed_duration_data: {};
  allowed_duration_selected: string;
  allowed_duration: any = [];
  training_allowed_duration_data: {};
  training_allowed_duration_selected: string;
  training_allowed_duration: any = [];
  form_error: any;
  document_type: any;
  error_message: any;
  folder_name: any;
  selectedFolderDetails: any;
  UserSignature: any = null;
  signature_error: string = '';
  oldFolderName: any;
  document_version: any;
  workspace: any;
  saveDisabled: any = true;
  four_hundred_errors = [
    401,
    402,
    403,
    404,
    405,
    406,
    407,
    408,
    409,
    410,
    411,
    412,
    413,
    414,
    415,
    416,
    417,
    418,
    421,
    422,
    423,
    424,
    425,
    426,
    428,
    429,
    431,
    451
  ];

  five_hundred_errors = [500, 501, 502, 503, 504, 505, 506, 507, 508, 510, 511];

  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#fff',
      buttonColor: '#FFFFFF'
    },
    dial: {
      dialBackgroundColor: '#555'
    },
    clockFace: {
      clockFaceBackgroundColor: '#fff',
      clockHandColor: 'black',
      clockFaceTimeInactiveColor: 'black'
    }
  };
  isChecked = false;
  error: any;
  selected_option: any;
  subfolder: any[];
  checkedItem: string;
  parent_folder: any;
  template_name: any = '';
  template_guid: any;
  documentDetails: any;
  folderName: any;
  renewalRecurring: any;
  createDocStorageId: string = 'create_doc_folder_data';

  document_guid: any;

  upload_template_name: any = '';
  checkFileFormat: any;
  loading = true;
  guidSubscription: any;
  recurringSchedule: any;
  recurringMonth: any;
  recurringDay: any;
  recurringWeekOfMonth: any;
  recurringAllowedDurations: any;
  recurringyear: any;
  recurringTime: any;
  Docguid: any;
  schedules = [
    {
      value: 'SEMI-ANNUALLY',
      display_value: 'Semi Annually'
    },
    {
      value: 'ANNUALLY',
      display_value: 'Annually'
    },
    {
      value: 'EVERY TWO YEARS',
      display_value: 'Every Two Years'
    }
  ];

  numbered_week = [
    {
      display_string: 'first',
      value: '1st'
    },
    {
      display_string: 'second',
      value: '2nd'
    },
    {
      display_string: 'third',
      value: '3rd'
    },
    {
      display_string: 'fourth',
      value: '4th'
    },
    {
      display_string: 'last',
      value: 'last'
    }
  ];

  days = [
    {
      display_string: 'Monday',
      value: 'mon'
    },
    {
      display_string: 'Tuesday',
      value: 'tue'
    },
    {
      display_string: 'Wednesday',
      value: 'wed'
    },
    {
      display_string: 'Thursday',
      value: 'thu'
    },
    {
      display_string: 'Friday',
      value: 'fri'
    },
    {
      display_string: 'Saturday',
      value: 'sat'
    },
    {
      display_string: 'Sunday',
      value: 'sun'
    }
  ];

  numbered_bi = [
    {
      display_string: 'Odd',
      value: '1/2'
    },
    {
      display_string: 'Even',
      value: '2/2'
    }
  ];

  numbered_quarter = [
    {
      display_string: 'first',
      value: '1/3'
    },
    {
      display_string: 'second',
      value: '2/3'
    },
    {
      display_string: 'third',
      value: '3/3'
    }
  ];

  months_half_year = [
    {
      display_string: 'January And July',
      value: '1/6'
    },
    {
      display_string: 'February And August',
      value: '2/6'
    },
    {
      display_string: 'March And September',
      value: '3/6'
    },
    {
      display_string: 'April And October',
      value: '4/6'
    },
    {
      display_string: 'May And November',
      value: '5/6'
    },
    {
      display_string: 'June And December',
      value: '6/6'
    }
  ];

  months_year = [
    {
      display_string: 'January',
      value: '1'
    },
    {
      display_string: 'February',
      value: '2'
    },
    {
      display_string: 'March',
      value: '3'
    },
    {
      display_string: 'April',
      value: '4'
    },
    {
      display_string: 'May',
      value: '5'
    },
    {
      display_string: 'June',
      value: '6'
    },
    {
      display_string: 'July',
      value: '7'
    },
    {
      display_string: 'August',
      value: '8'
    },
    {
      display_string: 'September',
      value: '9'
    },
    {
      display_string: 'October',
      value: '10'
    },
    {
      display_string: 'November',
      value: '11'
    },
    {
      display_string: 'December',
      value: '12'
    }
  ];

  public data = {
    renewal_schedule: '',
    day_of_week: '',
    day: '',
    month: '*',
    year: '*',
    time: '',
    week: ''
  };
  backend_error: any;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgAdminService: OrganisationAdminService,
    private formBuilder: FormBuilder,
    private orgService: OrganisationService,
    private documentsService: DocumentsService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private modalService: ModalService,
    public route: ActivatedRoute
  ) {}

  static convertTimeTo24Format(time: any) {
    return moment(time, ['h:mm A']).format('HH:mm');
  }

  convertTimeTo24Format(time: any) {
    return moment(time, ['h:mm A']).format('HH:mm');
  }

  ngOnInit() {
    this.oldFolderName = this.folder_name;
    this.selected_option = 'select_doc_opt';
    localStorage.removeItem(this.createDocStorageId);
    this.selectFolderForm = this.formBuilder.group({
      select_folder: ['', [Validators.required()]],
      select_subfolder: ['']
    });

    this.addDocumentForm = this.formBuilder.group({
      title: ['', [Validators.required()]],
      description: ['', [Validators.required(), Validators.maxLength(4096)]],
      document_type: [''],
      version: ['', [Validators.required(), Validators.restrictZeroNumber()]],
      renewal_recurring: [''],
      file: ['']
    });

    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
      this.documentsService.getOrganizationFolderList(this.OrganisationID).subscribe(
        data => {
          this.FolderList = data.results;
        },
        error => {
          this.error = error.error.message;
        }
      );
      this.documentsService.getOrganizationDocumentTypesList().subscribe(data => {
        this.document_types = data;
      });
    });

    this.documentsService.getDocStoreID(this.OrganisationID).subscribe(
      data => {
        this.DocStoreguid = data['organization_document_store_guid'];
      },
      error => {
        this.error = error.error.message;
      }
    );
    this.document_version = this.route.snapshot.params['document_version'];
    // this.guid = this.route.snapshot.params['in_progress'];
    this.guid = this.route.snapshot.queryParamMap.get('DocGuid');

    if (this.document_version) {
      this.document_version = this.route.snapshot.params['document_version'];
      this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
        this.OrganisationID = guid;
      });
      this.getDocumentDetailPageInfo(this.OrganisationID, this.document_version);
    } else if (this.guid) {
      this.guid = this.route.snapshot.queryParamMap.get('DocGuid');
      this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
        this.OrganisationID = guid;
      });
      this.getDocumentDetailPageInfo(this.OrganisationID, this.guid);
    } else {
      this.workspace = this.route.snapshot.queryParamMap.get('DocGuid');
      this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
        this.OrganisationID = guid;
      });
      this.getDocumentDetailPageInfo(this.OrganisationID, this.workspace);
    }
  }
  ngAfterViewInit() {
    this.documentsService.shareSelectedFolder$.subscribe(shareSelectedFolder => {
      let storedFolderObj = localStorage.getItem(this.createDocStorageId);
      if (storedFolderObj) {
        let storedFolderData = JSON.parse(storedFolderObj);
        this.selectedFolderDetails = shareSelectedFolder;
        this.folder_name = storedFolderData.folderPath;
        this.parent_folder = storedFolderData.selectedFolderGuid;
      }
    });
    this.documentsService
      .getTemplateName()
      .subscribe((item: any) => (this.template_name = this.documentsService.getTemplate()));
    this.documentsService
      .getTemplateName()
      .subscribe((item: any) => (this.template_guid = this.documentsService.getTemplateGuid()));
  }
  getAllowedDuration(schedule?: string, duration_value?: string) {
    this.orgAdminService.getEventAllowedDuration().subscribe(result => {
      if (result && result.allowed_durations) {
        this.allowed_duration_data = result.allowed_durations;
        this.allowed_duration = schedule
          ? this.getCurrentAllowedDuration(result.allowed_durations, schedule)
          : this.getCurrentAllowedDuration(result.allowed_durations, 'SEMI-ANNUALLY');
        this.allowed_duration_selected = duration_value ? duration_value : '1 Day';
      }
    });
  }
  onChange(evt: any) {
    if (evt.type == 'keyup') {
      this.saveDisabled = false;
    } else if (evt.type == 'keyup' && this.backend_error) {
      this.backend_error = '';
    }
  }
  changeRecurrence(event: any) {
    this.saveDisabled = false;
  }
  getCurrentAllowedDuration(data: {}, type: string) {
    for (const key in data) {
      if (`${key}` === type) {
        return `${data[key]}`.split(',');
      }
    }
  }

  getPreparedData() {
    const schedule_data = _.cloneDeep(this.data);
    schedule_data['allowed_duration'] = this.allowed_duration_selected;
    if (schedule_data.renewal_schedule === 'DAILY') {
      schedule_data.day_of_week = 'mon-fri';
    } else if (schedule_data.renewal_schedule === 'EVERY TWO YEARS') {
      const year_data = schedule_data.year.split('/');
      if (+year_data[0] % 2 === 1) {
        schedule_data.year = '2019/2';
      } else {
        schedule_data.year = '2020/2';
      }
    }

    if (schedule_data.day !== null && schedule_data.day_of_week) {
      schedule_data.day = schedule_data.day + ' ' + schedule_data.day_of_week;
      schedule_data.day_of_week = null;
    }

    return this.orgAdminSchedularData(Object.assign({}, schedule_data));
  }

  public orgAdminSchedularData(scheduler_data: any): object {
    for (const key in scheduler_data) {
      if (scheduler_data.hasOwnProperty(key) && scheduler_data[key] === '') {
        scheduler_data[key] = null;
      } else {
        console.log('Scheduler data updated.');
      }
    }
    return scheduler_data;
  }

  dataRefresh(event: any) {
    this.saveDisabled = false;
    const renewal_schedule: string = event;
    const time: string = this.data.time;
    switch (renewal_schedule) {
      case 'WEEKLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: null,
          month: '*',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'BI-WEEKLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: null,
          month: '*',
          year: '*',
          time: time,
          week: '1/2'
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'MONTHLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '*',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'BI-MONTHLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1/2',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'QUARTERLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1/3',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'SEMI-ANNUALLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1/6',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'ANNUALLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'EVERY TWO YEARS':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1',
          year: '1/2',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      default:
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: null,
          day: null,
          month: '*',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
    }
  }

  timeSet(time: any) {
    this.saveDisabled = false;
    this.data.time = time;
  }
  //   ngOnDestroy(): void {
  //     this.guidSubscription.unsubscribe();
  //     localStorage.removeItem(this.createDocStorageId);
  //   }
  getAddDocumentForm() {
    this.addDocumentForm = this.formBuilder.group({
      title: [
        this.documentDetails ? (this.documentDetails['title'] ? this.documentDetails['title'] : '') : '',
        [Validators.required(), Validators.maxLength(255)]
      ],

      description: [
        this.documentDetails ? (this.documentDetails['description'] ? this.documentDetails['description'] : '') : '',
        [Validators.required(), Validators.maxLength(4096)]
      ],

      version: [
        this.documentDetails ? (this.documentDetails['version'] ? this.documentDetails['version'] : '') : '',
        [Validators.required()]
      ],

      renewal_recurring: [
        this.documentDetails
          ? this.documentDetails['renewal_schedule']
            ? this.documentDetails['renewal_schedule']
            : ''
          : ''
      ]
    });
  }

  onReset() {
    this.submitted = false;
    this.addDocumentForm.reset();
    this.folder_name = '';
    this.template_name = '';
    this.selected_option = '';
    this.backend_error = '';
    localStorage.removeItem(this.createDocStorageId);
  }

  getDocumentDetailPageInfo(OrganisationID: any, guid: any): void {
    this.documentsService.getDocumentDetailPage(OrganisationID, guid).subscribe(
      data => {
        this.documentDetails = data;
        this.loading = false;
        this.folderName = this.documentDetails['parent_folder'];
        this.renewalRecurring = this.documentDetails['renewal_schedule'].length;
        this.getAddDocumentForm();
        if (this.documentDetails['renewal_schedule'].length > 0) {
          this.data = _.cloneDeep(this.documentDetails['renewal_schedule'][0]);
          this.getAllowedDuration(
            this.documentDetails['renewal_schedule'][0]['renewal_schedule'],
            this.documentDetails['renewal_schedule'][0]['allowed_duration']
          );
          if (_.indexOf(this.documentDetails['renewal_schedule'][0]['day'], ' ') > 0) {
            const split_day = this.data.day.split(' ');
            this.data.day = split_day[0];
            this.data.day_of_week = split_day[1];
          }
          if (this.data.renewal_schedule === 'EVERY TWO YEARS') {
            const year_data = this.data.year.split('/');
            if (+year_data[0] % 2 === 1) {
              this.data.year = '1/2';
            } else {
              this.data.year = '2/2';
            }
          }
        } else {
          this.getAllowedDuration();
          this.data = {
            renewal_schedule: 'SEMI-ANNUALLY',
            time: '09:00 AM',
            day_of_week: 'mon',
            week: null,
            day: '1st',
            month: '1/6',
            year: '*'
          };
        }
        this.Docguid = this.documentDetails['document_guid'];
        if (this.renewalRecurring <= 0) {
          this.documentDetails['renewal_schedule'] = null;
        }
      },
      error => {
        this.error = error.error.message;
        this.loading = false;
        console.log('Error:', error.error.message);
      }
    );
  }
  submit() {
    this.submitted = true;
    this.saveDisabled = true;
    this.loading = true;
    if (this.addDocumentForm.valid) {
      this.isloading = true;
      const document_data = Object.assign({}, this.addDocumentForm.value);
      document_data['org_document_store'] = this.DocStoreguid;
      if (document_data.renewal_recurring) {
        document_data['renewal_schedule'] = this.getPreparedData();
      } else {
        document_data['renewal_schedule'] = null;
        document_data['day_of_week'] = null;
      }
      document_data['parent_folder'] = this.parent_folder;
      this.submitCreateDocumentData(document_data);
    } else {
      this.error_message = 'Please select at least one checkbox.';
      return;
    }
  }

  submitCreateDocumentData(document_data: any): void {
    delete document_data['document_option'];
    this.documentsService
      .submitOrganisationEditDocumentData(this.OrganisationID, document_data, this.Docguid)
      .subscribe(
        data => {
          this.loading = false;
          this.error = {
            title: '',
            version: '',
            folder: ''
          };
          // this.submitted = false;
          if (data['status']['value'] == 'Published') {
            this.modalService
              .custom(CustomDialogComponent, {
                title: 'Publish Document edited',
                modalType: 'Publish Document edited',
                DocumentGuid: data.guid,
                DocumentTitle: data.title
              })
              .pipe(take(1)) // take() manages unsubscription for us
              .subscribe((result: any) => {
                if (result) {
                } else {
                }
              });
          }
          if (data['status']['value'] != 'Published') {
            this.modalService
              .custom(CustomDialogComponent, {
                title: 'Document edited',
                modalType: 'Document edited',
                DocumentGuid: data.guid,
                DocumentTitle: data.title
              })
              .pipe(take(1)) // take() manages unsubscription for us
              .subscribe((result: any) => {
                if (result) {
                } else {
                }
              });
          }
          this.getDocumentDetailPageInfo(this.OrganisationID, this.guid);
          this.submitted = false;
          // this.addDocumentForm.reset();
          // this.selectFolderForm.reset();
          // this.folder_name = '';
          // this.template_name = '';
          this.backend_error = '';
          localStorage.removeItem(this.createDocStorageId);
        },
        (error: any) => {
          this.isloading = false;
          this.submitted = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
  }

  // Rename Document Title modal
  OpenDocumentTitleRenameModal(DocumentGuid: string, DocumentVersion: string, docTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Rename Document Title',
        modalType: 'Rename Document Title',
        DocumentGuid: DocumentGuid,
        DocumentVersion: DocumentVersion,
        docTitle: docTitle
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.documentsService.title.subscribe(data => {
            this.title = data;
          });
          this.documentDetails['title'] = this.title['title'];
          this.getAddDocumentForm();
          this.translate.get('rename_document.renamed_successfully').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }
  SelectFolderDialog(): void {
    if (this.backend_error) {
      this.backend_error = '';
    }
    this.documentsService.setSelectedFolderData(this.selectedFolderDetails);
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Select a folder',
        storage_id: this.createDocStorageId,
        modalType: 'document'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        this.saveDisabled = false;
      });
  }
}
