<div class="onboarding-main pt-4" *ngIf="show">
  <div class="container max-w-840">
    <div class="row">
      <div class="col">
        <h1 class="text-black-2 mb-3 font-weight-bold" translate>onboard.thank_you.Thank You!</h1>
        <p class="text-black-2 mb-5">
          {{ 'onboard.thank_you.thank_you_details' | translate }}
          <br /><br />
          {{ 'onboard.thank_you.For any queries please mail to' | translate }}
          <a href="mailto:help@compliancebot.io" class="text-cyan" translate>onboard.thank_you.help_email</a>
        </p>
      </div>
    </div>
  </div>
</div>
