import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { OrganisationService } from '@app/core/organisation.service';
import { OrganisationAdminService } from '@app/Organisation-admin/organisation-admin.service';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Logger } from '@app/core';
import { NgOption } from '@ng-select/ng-select';

const log = new Logger('Event details');

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit, AfterViewInit {
  @Input() is_editable = true;
  @Input() event: any;
  @Input() event_start_message: string;
  @Input() event_end_message: string;
  @Input() document_urls: any;
  form_submitted = false;
  OrganisationID: string;
  eventGuid: string;
  eventMessageForm: FormGroup;
  document_urls_FA: FormArray;
  isLoading: boolean = true;
  error: string = '';
  success: string = '';
  defaultStartMessage: string;
  defaultEndMessage: string;
  all_url_types: NgOption[];
  docUrlLogo = '';
  show_doc_urls = true;
  show_edit_form = false;
  showForm = false;
  event_data: { [key: string]: any } = {
    event_start_message: '',
    event_end_message: '',
    document_urls: []
  };
  generalDoc = 'Others';
  guidSubscription: any;

  constructor(
    private formBuilder: FormBuilder,
    private orgService: OrganisationService,
    private orgAdminService: OrganisationAdminService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
    });
  }

  ngOnInit() {}
  ngAfterViewInit() {
    this.event_data.event_start_message = this.event_start_message;
    this.event_data.event_end_message = this.event_end_message;
    this.event_data.document_urls = this.document_urls;
    this.eventGuid = this.event['review_event_guid'];
    this.getEventDetails();
    this.showForm = true;
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  updateMessage() {
    this.form_submitted = true;
    if (this.eventMessageForm.invalid) {
      return;
    }
    this.isLoading = true;
    this.error = '';
    let request_data = this.eventMessageForm.value;

    request_data.review_document_urls = this.event_data.document_urls;
    delete request_data['document_urls_FA'];
    this.orgAdminService.updateEventMessages(this.OrganisationID, this.eventGuid, request_data).subscribe(
      data => {
        this.success = 'Message updated successfully';
        this.document_urls = this.event_data.document_urls;
        this.event_start_message = data['event_start_message'];
        this.event_end_message = data['event_end_message'];
        this.isLoading = false;
        this.show_edit_form = false;
        this.form_submitted = false;
        setTimeout(() => {
          this.success = '';
        }, 2000);
      },
      (error: any) => {
        this.isLoading = false;
        log.debug(`Event details: URL validation: ${error}`);
        this.error = error['error']['detail'] || error['error']['url'];
      }
    );
  }

  getEventDetails() {
    this.orgAdminService.getUrlTypes().subscribe(data => {
      this.all_url_types = data;
      this.createForm();
      this.isLoading = false;
    });
  }

  setDocumentUrlType(event_data_index: number) {
    if (this.event_data.document_urls[event_data_index].url) {
      if (this.event_data.document_urls[event_data_index].url.startsWith('https://docs.google.com')) {
        this.event_data.document_urls[event_data_index].urltype = 'Google Drive';
      } else if (this.event_data.document_urls[event_data_index].url.startsWith('https://www.dropbox.com')) {
        this.event_data.document_urls[event_data_index].urltype = 'DropBox';
      } else if (this.event_data.document_urls[event_data_index].url.startsWith('https://onedrive')) {
        this.event_data.document_urls[event_data_index].urltype = 'OneDrive';
      } else if (this.event_data.document_urls[event_data_index].url.startsWith('https://www.icloud.com')) {
        this.event_data.document_urls[event_data_index].urltype = 'iCloud';
      } else {
        this.event_data.document_urls[event_data_index].urltype = this.generalDoc;
      }
    }
  }

  reset() {
    this.showForm = false;
    this.success = '';
    this.error = '';
    this.getEventData(() => {
      this.event_data.event_start_message = this.event_start_message;
      this.event_data.event_end_message = this.event_end_message;
      this.event_data.document_urls = this.document_urls;
      this.createForm();
      this.showForm = true;
    });
  }

  cancel() {
    this.getEventData(() => {
      this.show_edit_form = false;
    });
  }

  getEventData(afterFunction: any) {
    this.orgAdminService.getEventDetails(this.OrganisationID, this.event['review_event_guid']).subscribe(data => {
      this.error = data.error;
      this.event_start_message = data['event_start_message']
        ? data['event_start_message']
        : data['name'] + ' meeting has started.';
      this.event_end_message = data['event_end_message']
        ? data['event_end_message']
        : 'Thank you for participating in ' + data['name'] + ' meeting.';
      this.document_urls = data['review_document_urls'];
      afterFunction();
    });
  }

  add_document_url(): void {
    this.form_submitted = false;
    this.event_data.document_urls.push({
      url: '',
      urltype: this.generalDoc,
      title: ''
    });
    this.document_urls_FA = this.eventMessageForm.get('document_urls_FA') as FormArray;
    this.document_urls_FA.push(this.create_document_url());
  }

  remove_document_url(i: number): void {
    this.show_doc_urls = false;
    if (this.event_data.document_urls[i].hasOwnProperty('guid')) {
      this.orgAdminService
        .deleteReviewDoc(this.OrganisationID, this.eventGuid, this.event_data.document_urls[i]['guid'])
        .subscribe(data => {
          this.event_data.document_urls.splice(i, 1);
          this.document_urls_FA = this.eventMessageForm.get('document_urls_FA') as FormArray;
          this.document_urls_FA.removeAt(i);
          this.show_doc_urls = true;
        });
    } else {
      this.document_urls.splice(i, 1);
      this.document_urls_FA = this.eventMessageForm.get('document_urls_FA') as FormArray;
      this.document_urls_FA.removeAt(i);
      // this.document_urls_FA = this.formBuilder.array(this.init_docs_urls());
      // this.eventMessageForm.setControl('document_urls_FA', this.formBuilder.array(this.init_docs_urls()));
      this.show_doc_urls = true;
    }
  }

  create_document_url(data: any = null): FormGroup {
    let url = '';
    let urltype = this.generalDoc;
    let title = '';
    if (data) {
      url = data['url'];
      urltype = data['urltype'];
      title = data['title'];
    }
    return this.formBuilder.group({
      url: [url, [Validators.httpsURL()]],
      urltype: [urltype, [Validators.required()]],
      title: [title, [Validators.required()]]
    });
  }
  init_docs_urls() {
    let documents: Array<FormGroup | null>;
    documents = [];
    if (this.document_urls) {
      for (const url of this.document_urls) {
        documents.push(this.create_document_url(url));
      }
    }
    return documents;
  }

  createForm() {
    this.eventMessageForm = this.formBuilder.group({
      event_start_message: [],
      event_end_message: [],
      document_urls_FA: this.formBuilder.array(this.init_docs_urls())
    });
  }
}
