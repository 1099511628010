import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, EmailValidator } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Logger, I18nService, AuthenticationService } from '@app/core';
import { UserService } from '@app/core/user.service';
import { OrganisationService } from '@app/core/organisation.service';
import { OrganisationAdminService } from '@app/Organisation-admin/organisation-admin.service';
import { PasswordResetService } from '@app/password-reset/password-reset.service';
import { SettingsService } from '@app/settings/settings.service';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';

const log = new Logger('Signup');

@Component({
  selector: 'app-no-mobile',
  templateUrl: './no-mobile.component.html',
  styleUrls: ['./no-mobile.component.scss']
})
export class NoMobileComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
