<div class="cb-container cb-page-bg-color">
  <div class="container-fluid background-color">
    <div class="setting-header">
      <div class="setting-header-label">
        Settings
      </div>
    </div>
    <div class="row ">
      <div class="col setting-menu" style="padding-top: 0px !important;">
        <div class="d-flex d-lg-none justify-content-between mb-4">
          <a href="" class="btn-back ml-3">&lt; Back</a>
          <!-- TODO: closeSidnav() function needs to be included.-->
          <i class="fas fa-times close-setting-menu"></i>
        </div>
        <div class="setting-menu-wrapper">
          <!--          TODO: Commented for now.-->
          <!--          <button class="btn-submit btn-conf-wizard">-->
          <!--            <img src="../../../../assets/images/core/edit.svg" class="mr-3" />Configuration Wizard-->
          <!--          </button>-->
          <ul>
            <li>
              <a routerLink="/organisation/settings/profile" routerLinkActive="active">Profile Settings</a>
            </li>
            <li *ngIf="showSettingSubfolder">
              <a routerLink="/organisation/settings/administrator" routerLinkActive="active">Administrators</a>
            </li>
            <li *ngIf="showSettingSubfolder">
              <a routerLink="/organisation/settings/people" routerLinkActive="active">People</a>
            </li>
            <li *ngIf="showSettingSubfolder">
              <a routerLink="/organisation/settings/operations" routerLinkActive="active">Operations</a>
            </li>
            <li *ngIf="showSettingSubfolder">
              <a routerLink="/organisation/settings/committees" routerLinkActive="active">Committees</a>
            </li>
            <li *ngIf="showSettingSubfolder">
              <a routerLink="/organisation/settings/category" routerLinkActive="active">Incident Category</a>
            </li>
            <li *ngIf="showSettingSubfolder">
              <a routerLink="/organisation/settings/training" routerLinkActive="active">Training Groups</a>
            </li>
            <li *ngIf="showSettingSubfolder">
              <a routerLink="/organisation/settings/document-groups" routerLinkActive="active">Document Groups</a>
            </li>
            <li *ngIf="showSettingSubfolder">
              <a routerLink="/organisation/settings/events" routerLinkActive="active">Tasks</a>
            </li>
            <li *ngIf="showSettingSubfolder">
              <a routerLink="/organisation/settings/communication" routerLinkActive="active">Communication</a>
            </li>
            <li *ngIf="showSettingSubfolder">
              <a routerLink="/organisation/settings/integration" routerLinkActive="active">Integrations</a>
            </li>
            <li *ngIf="showSettingSubfolder">
              <a routerLink="/organisation/settings/cbagent" routerLinkActive="active"
                >CBAgent <i *ngIf="showCbAgentUpdateWarning"><mat-icon>warning</mat-icon></i></a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="col">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
