<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div>
    <div class="modal-header cb-modal-header">
      <div>
        <h3 class="modal-title font-weight-bold text-black-1" translate>rename_document.rename_document_title</h3>
        <i
          class="fa fa-times text-black-1"
          style="width: 12px;
          height: 12px;
          right: 1em;
          cursor: pointer;
          position: absolute;
          top: 1.3em;"
          (click)="activeModal.close(false)"
          ngbAutoFocus
          translate
        ></i>
      </div>
    </div>

    <div class="modal-body">
      <div class="row mt-2" *ngIf="backend_error">
        <div class="col-12 col-sm-12 col-lg-12 ml-3">
          <div class="text-danger" translate>* {{ backend_error }}</div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-3">
          <label class="text-black-4" translate>rename_document.old_title </label>
        </div>
        <div class="col-9">
          <div class="input-group">
            <input class="form-control cb-input" value="{{ docTitle }}" disabled />
          </div>
        </div>
      </div>
      <form [formGroup]="RenameDocumentForm" novalidate>
        <div class="row mt-2">
          <div class="col-3 col-sm-3 col-lg-3">
            <label for="title" class="text-black-3" translate>rename_document.new_title</label>
          </div>
          <div class="input-group col-9 col-sm-9 col-lg-9">
            <input
              id="title"
              class="form-control cb-input"
              formControlName="title"
              [placeholder]="'Document Title'"
              autocomplete="title"
              required
              name="title"
              type="text"
              (change)="onChange($event)"
              [maxLength]="256"
            />
            <div
              class="col-12 error-mssg"
              *ngIf="
                (RenameDocumentForm.controls.title.touched || submitted) && RenameDocumentForm.controls.title.errors
              "
            >
              <label for="title" class="text-danger" *ngIf="RenameDocumentForm.controls.title.errors.required" translate
                >Create_Document.Doc_Req_Title</label
              >
              <label for="name" class="text-danger" *ngIf="RenameDocumentForm.controls.title.errors.maxLength" translate
                >Create_Document.Title must be 255 characters or less</label
              >
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer border-0 cb-modal-footer">
      <button class="btn-cancel ml-4 w-100" (click)="activeModal.close(false)" ngbAutoFocus translate>
        rename_document.Cancel
      </button>
      <button class="btn-save ml-4 w-100" *ngIf="!isLoading" (click)="save()" translate>
        rename_document.Submit
      </button>
      <button class="btn-save ml-4 w-100" disabled *ngIf="isLoading">
        <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
      </button>
    </div>
  </div>
</ng-container>
