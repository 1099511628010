import { async } from '@angular/core/testing';
import { Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalService } from '@app/core/modal/modal.service';
import { take } from 'rxjs/operators';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { WizardService } from '@app/home/wizard.services';
import { WebsocketService } from '@app/core/websocket.service';
import { OrganisationService } from '@app/core/organisation.service';
import { Subject } from 'rxjs';
import { ToasterService } from '@app/shared/toaster/toastr.service';

@Component({
  selector: 'app-cb-lite-header',
  templateUrl: './cb-lite-header.component.html',
  styleUrls: ['./cb-lite-header.component.scss']
})
export class CbLiteHeaderComponent implements OnInit, OnChanges {
  @Input() disabledFinish: boolean;
  @Input() disabledSaveContinue: boolean;
  @Input() isAssesmentDataAvail: boolean = true;
  @Input() updatedCitation: boolean = false;
  @Input() updatedAssement: boolean = false;
  @Input() disabledStepper: boolean = false;
  selectedButton: string = '';

  OrganisationID: string | null;
  orgGuidSubscription: any;
  backend_error: string;
  @Output() trigger: EventEmitter<string> = new EventEmitter<string>();
  @Output() triggerAssement: EventEmitter<string> = new EventEmitter<string>();
  private triggerAssementComplete: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    public wizardService: WizardService,
    public websocketService: WebsocketService,
    private orgService: OrganisationService,
    private toaster: ToasterService
  ) {
    activatedRoute.snapshot.routeConfig;
    this.selectedButton = activatedRoute.snapshot.routeConfig.path;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['updatedAssement']?.currentValue) {
      this.updatedAssement = false;
      this.navigateTo('select-citations');
    }
    if (changes['updatedCitation']?.currentValue) {
      this.updatedCitation = false;
      this.navigateTo('generate-documents');
    }
  }

  ngOnInit(): void {
    this.orgGuidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
    });
  }

  navigateTo(route: string) {
    this.router.navigate(['/', route]);
    this.selectedButton = route;
  }
  isOrgAsmtCompleted() {
    if (localStorage.getItem('org_assessment_completed') === 'true') {
      return true;
    } else {
      return false;
    }
  }
  isCtrlCompleted() {
    if (localStorage.getItem('select_applicable_ctrl_completed') === 'true') {
      return true;
    } else {
      return false;
    }
  }

  saveAndContinue() {
    if (this.disabledSaveContinue) {
      return;
    }
    let nextStep: string;
    switch (this.selectedButton) {
      case 'organization-assessment':
        this.triggerOrgAssement();
        nextStep = 'select-citations';
        break;
      case 'select-citations':
        this.triggerParentFunction();
        break;
      default:
        break;
    }
    // if (nextStep && this.disabledSaveContinue) {
    //   this.navigateTo(nextStep);
    // }
  }

  backToControls() {
    this.navigateTo('select-citations');
  }
  inviteMembersModal() {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Invite Members',
        modalType: 'Invite Members'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {});
  }
  documentSuccessModal() {
    if (this.disabledFinish) {
      return;
    }
    this.modalService
      .custom(CustomDialogComponent, {
        modalType: 'Document Success'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {});
  }
  triggerParentFunction() {
    this.trigger.emit();
  }
  triggerOrgAssement() {
    this.triggerAssement.emit();
  }
}
