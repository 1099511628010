import { Component, OnDestroy, OnInit } from '@angular/core';
import { DocumentsService } from '../documents.service';
import { OrganisationService } from '@app/core/organisation.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

interface configOption {
  label: string;
  key: string;
}
interface ShowHideColumns {
  isShowUserEmail: boolean;
  isshowStartDate: boolean;
  isshowSignature: boolean;
}

@Component({
  selector: 'app-training-reports',
  templateUrl: './training-reports.component.html',
  styleUrls: ['./training-reports.component.scss']
})
export class TrainingReportsComponent implements OnInit, OnDestroy {
  page: number;
  dropdownSettings = {};
  OrganisationID: string | null;
  TrainingExportList: object[];
  dropDownCampaignList: object[];
  dropDownDocumentList: object[];
  dropDownUserList: object[];
  campaignList: object[];
  documentList: object[];
  userList: object[];
  guidSubscription: any;
  error: any;
  scrollLoader = false;
  pagnationData: any;
  paginatedDataUrlTraining: any;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  start_date: any = '';
  end_date: any = '';
  selectedCampaignIds: string[] = [];
  selectedUserIds: string[] = [];
  selectedDocumentIds: string[] = [];
  selectedExternalDocument: string[] = [];
  campaigns: any;
  users: any;
  documents: any;
  externalDocs: any;
  loading = true;
  loadingTable = false;
  mindate: NgbDateStruct;
  maxdate: NgbDateStruct;
  trainingStatus: any[];
  selectedStatus: any;
  reportData: any;
  hidereport: boolean = true;
  apiSubscription: any;
  reportFilterColOptions: configOption[] = [
    { label: 'User Email', key: 'isShowUserEmail' },
    { label: 'Start Date', key: 'isshowStartDate' },
    { label: 'Signature', key: 'isshowSignature' }
  ];

  dropdownSetting: any = {
    singleSelection: false,
    idField: 'key',
    textField: 'value',
    selectAllText: 'Select All',
    unSelectAllText: 'Unselect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  showHideColumns: ShowHideColumns = {
    isShowUserEmail: false,
    isshowStartDate: false,
    isshowSignature: false
  };
  currentData = new Date().toLocaleDateString();
  constructor(private documentsService: DocumentsService, private orgService: OrganisationService) {
    this.reportData = JSON.parse(localStorage.getItem('credentials'));
    this.reportData['org_name'] = localStorage.getItem('org_name');
    if (localStorage.getItem('public_logo')) {
      this.reportData['public_logo'] = JSON.parse(localStorage.getItem('public_logo'));
    }
  }
  ngOnDestroy(): void {
    this.apiSubscription.unsubscribe();
    this.guidSubscription.unsubscribe();
  }

  ngOnInit() {
    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
      this.getExportTrainingList();
      this.getDocumentFilterData();
      this.getUserFilterData();
      this.getCampaignFilterData();
      this.getTrainingStatus();
    });
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'guid',
      textField: 'FullName',
      enableCheckAll: true,
      allowSearchFilter: true
    };
  }

  getTrainingStatus(): void {
    this.apiSubscription = this.documentsService.getTrainingStatus().subscribe(
      data => {
        this.loading = false;
        this.trainingStatus = data;
        let index = this.trainingStatus.findIndex((item: any) => item.key.toLowerCase() === 'overdue');
        this.trainingStatus.splice(index, 1);
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }

  private getExportTrainingList(): void {
    this.page = 1;
    if (this.OrganisationID != null) {
      this.documentsService.getOrganizationExportTrainingList(this.OrganisationID, this.page).subscribe(
        data => {
          this.loading = false;
          this.paginatedDataUrlTraining = data;
          this.TrainingExportList = data.results.results;

          this.reportData['tableData'] = this.transformData(this.TrainingExportList);
          this.reportData['loggedin_user_signature'] = data.results.loggedin_user_signature;
          this.convertImageToBase64(this.reportData.public_logo).then(base64Logo => {
            const imgContainer = document.getElementById('img-container');
            if (imgContainer) {
              const logoImg = document.createElement('img');
              logoImg.src = base64Logo;
              logoImg.style.maxWidth = '250px';
              logoImg.style.maxHeight = '50px';
              logoImg.style.objectFit = 'contain';
              logoImg.style.width = '100%';
              logoImg.style.height = '48px';
              logoImg.classList.add('logo-class');
              imgContainer.innerHTML = '';
              imgContainer.appendChild(logoImg);
            }
          });
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
    }
  }
  private getCampaignFilterData(): void {
    if (this.OrganisationID != null) {
      this.documentsService.getCampaignFilterDataList(this.OrganisationID).subscribe(
        data => {
          this.campaignList = data;
          this.dropDownCampaignList = this.campaignList.map(data => data['title']);
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
    }
  }
  private getDocumentFilterData(): void {
    if (this.OrganisationID != null) {
      this.documentsService.getDocumentFilterDataList(this.OrganisationID).subscribe(
        data => {
          this.documentList = data;
          this.dropDownDocumentList = this.documentList.map(data =>
            data['document'] ? data['document']['title'] : data['external_link']['title']
          );
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
    }
  }
  private getUserFilterData(): void {
    if (this.OrganisationID != null) {
      this.documentsService.getUserFilterDataList(this.OrganisationID).subscribe(
        data => {
          this.userList = data;
          this.userList.forEach(user => {
            user['FullName'] = user['username'] + '(' + user['email'] + ')';
          });
          this.dropDownUserList = this.userList.map(user => user['FullName']);
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
    }
  }

  onScroll() {
    if (this.paginatedDataUrlTraining?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }
  onScrollData() {
    this.scrollLoader = true;
    let traingStatus;
    if (this.selectedStatus) {
      traingStatus = this.convertArrayToQueryParams(this.selectedStatus);
    }
    this.documentsService
      .getOrganizationExportTrainingListPagination(
        this.OrganisationID,
        (this.page += 1),
        this.start_date,
        this.end_date,
        this.campaigns,
        this.users,
        this.documents,
        this.externalDocs,
        traingStatus
      )
      .subscribe(
        data => {
          this.scrollLoader = false;
          this.pagnationData = data.results;
          this.paginatedDataUrlTraining = data;
          this.TrainingExportList = this.TrainingExportList.concat(data.results);
        },
        error => {
          this.scrollLoader = false;
          this.error = error.error.message;
        }
      );
  }
  onStartDateSelection(evt: NgbDateStruct) {
    let myDate = new Date(evt.year, evt.month - 1, evt.day + 1);
    this.start_date = myDate.toISOString().split('T')[0];
    this.mindate = {
      year: myDate.getFullYear(),
      month: myDate.getMonth() + 1,
      day: myDate.getDate()
    };
  }

  onEndDateSelection(evt: NgbDateStruct) {
    let myDate = new Date(evt.year, evt.month - 1, evt.day + 1);
    this.end_date = myDate.toISOString().split('T')[0];
    this.maxdate = {
      year: myDate.getFullYear(),
      month: myDate.getMonth() + 1,
      day: myDate.getDate() - 1
    };
  }
  onEndDateDeSelection(evt: any) {
    const inputValue = evt.target.value;
    if (inputValue.trim() === '') {
      this.end_date = '';
      this.maxdate = null;
    }
  }
  onStartDateDeSelection(evt: any) {
    console.log(evt.target.value);
    const inputValue = evt.target.value;
    if (inputValue.trim() === '') {
      this.start_date = '';
      this.mindate = null;
    }
  }
  onCampaignSelect(evt: any) {
    const selectedData = this.campaignList.filter((data: any) => data['title'] === evt);
    const selectedCampaignIds = [...new Set(selectedData.map((data: any) => data['guid']))] as string[];
    this.selectedCampaignIds.push(...selectedCampaignIds);
  }
  onCampaignDeselect(evt: any) {
    const deselectedData = this.campaignList.filter((data: any) => data['title'] === evt);
    const deselectedCampaignIds = deselectedData.map((data: any) => data['guid']);
    this.selectedCampaignIds = this.selectedCampaignIds.filter(
      campaignId => !deselectedCampaignIds.includes(campaignId)
    );
  }
  onUserSelect(evt: any) {
    const selectedData = this.userList.filter((data: any) => data['FullName'] === evt);
    const selectedUserIds = [...new Set(selectedData.map((data: any) => data['guid']))] as string[];
    this.selectedUserIds.push(...selectedUserIds);
  }
  onUserDeselect(evt: any) {
    const deselectedData = this.userList.filter((data: any) => data['FullName'] === evt);
    const deselectedUserIds = deselectedData.map((data: any) => data['guid']);
    this.selectedUserIds = this.selectedUserIds.filter(userId => !deselectedUserIds.includes(userId));
  }
  onDocumentSelect(evt: any) {
    const selectedData = this.documentList.filter(
      (data: any) => (data['document'] ? data['document']['title'] : data['external_link']['title']) === evt
    );

    const selectedDocumentIds = [
      ...new Set(
        selectedData.map((data: any) => (data['document'] ? data['document']['guid'] : data['external_link']['title']))
      )
    ] as string[];

    selectedDocumentIds.forEach(id => {
      if (selectedData.some(data => data['document'] && data['document']['guid'])) {
        this.selectedDocumentIds.push(id);
      } else {
        this.selectedExternalDocument.push(id);
      }
    });
  }

  onDocumentDeselect(evt: any) {
    const deselectedData = this.documentList.filter(
      (data: any) => (data['document'] ? data['document']['title'] : data['external_link']['title']) === evt
    );
    const deselectedDocumentIds = deselectedData.map((data: any) =>
      data['document'] ? data['document']['guid'] : data['external_link']['title']
    );
    this.selectedDocumentIds = this.selectedDocumentIds.filter(
      documentId => !deselectedDocumentIds.includes(documentId)
    );
    this.selectedExternalDocument = this.selectedExternalDocument.filter(
      documentId => !deselectedDocumentIds.includes(documentId)
    );
  }
  UpdatedreportdataWithPagination() {
    this.page = 1;
    this.loadingTable = true;
    this.campaigns = this.selectedCampaignIds.map((campaignId: any = []) => `campaign=${campaignId}`).join('&');
    this.users = this.selectedUserIds.map((userId: any = []) => `user=${userId}`).join('&');
    this.documents = this.selectedDocumentIds.map((documentId: any = []) => `document=${documentId}`).join('&');
    this.externalDocs = this.selectedExternalDocument
      .map((externalDocs: any = []) => `external_docs=${externalDocs}`)
      .join('&');
    let traingStatus;
    if (this.selectedStatus) {
      traingStatus = this.convertArrayToQueryParams(this.selectedStatus);
    }
    this.documentsService
      .getOrganizationExportTrainingListPagination(
        this.OrganisationID,
        this.page,
        this.start_date,
        this.end_date,
        this.campaigns,
        this.users,
        this.documents,
        this.externalDocs,
        traingStatus
      )
      .subscribe(data => {
        this.loadingTable = false;
        this.TrainingExportList = data.results.results;
        this.reportData['tableData'] = this.transformData(this.TrainingExportList);
      });
  }

  convertArrayToQueryParams(array: { key: string; value: string }[]): string {
    return array.map(item => `status=${item.key}`).join('&');
  }
  Updatedreportdata() {
    this.campaigns = this.selectedCampaignIds.map((campaignId: any = []) => `campaign=${campaignId}`).join('&');
    this.users = this.selectedUserIds.map((userId: any = []) => `user=${userId}`).join('&');
    this.documents = this.selectedDocumentIds.map((documentId: any = []) => `document=${documentId}`).join('&');
    this.externalDocs = this.selectedExternalDocument
      .map((externalDocs: any = []) => `external_docs=${externalDocs}`)
      .join('&');
    let traingStatus;
    if (this.selectedStatus) {
      traingStatus = this.convertArrayToQueryParams(this.selectedStatus);
    }
    this.documentsService
      .getOrganizationExportTrainingListFilterwise(
        this.OrganisationID,
        this.start_date,
        this.end_date,
        this.campaigns,
        this.users,
        this.documents,
        this.externalDocs,
        traingStatus
      )
      .subscribe(data => {
        this.TrainingExportList = data.results;
        this.exportToCSV();
      });
  }

  exportToCSV() {
    const allCSVData = [];
    const header = ['Document Title', 'User Name', 'User Email', 'Start Date', 'End Date', 'Status'];
    allCSVData.push(header.join(','));
    this.TrainingExportList.forEach(item => {
      const flattenedItem = {
        'Document Title': item['training_campaign_material']['document']
          ? item['training_campaign_material']['document']['title']
          : item['training_campaign_material']['external_link']['title'],
        'User Name': item['user']['username'],
        'User Email': item['user']['email'],
        'Start Date': item['training_campaign']['start_date'],
        'End Date': item['training_campaign']['end_date'],
        Status: this.getStatus(item['training_campaign_material']['status'])
      };

      const values = header.map(key => flattenedItem[key]);
      allCSVData.push(values.join(','));
    });

    if (allCSVData.length > 1) {
      // Check if there's data to export
      const currentDate = new Date();
      const formattedDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
      const fileName = `training_data_${formattedDate}.csv`;
      const combinedCSVData = allCSVData.join('\n');
      this.downloadCSV(combinedCSVData, fileName);
    }
  }

  private downloadCSV(csvData: string, fileName: string) {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  getStatus(status: string) {
    const foundStatus = this.trainingStatus?.find((item: any) => item.key == status);
    return foundStatus?.value;
  }
  async convertImageToBase64(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';

      img.onload = () => {
        // Create a high-resolution canvas
        const canvas = document.createElement('canvas');

        // Use a higher resolution by scaling up the canvas
        const scaleFactor = 3; // Increase for even better quality
        canvas.width = img.width * scaleFactor;
        canvas.height = img.height * scaleFactor;

        const ctx = canvas.getContext('2d');

        // Use high-quality image scaling
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';

        // Draw the image at a larger scale
        ctx.drawImage(img, 0, 0, img.width * scaleFactor, img.height * scaleFactor);

        // Convert canvas to base64 with higher quality
        const base64String = canvas.toDataURL('image/png', 1.0); // 1.0 is maximum quality

        resolve(base64String);
      };

      img.onerror = error => {
        console.error('Error loading image:', error);
        reject(error);
      };

      img.src = url;
    });
  }

  async generatePdf() {
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const initialMarginTop = 10;
    const subsequentPageMarginTop = 25; // Reduced margin for subsequent pages
    const data = document.getElementById('training-report');

    // Function to add header to the first page only
    const addHeaderToPage = async (pdf: jsPDF) => {
      const headerSection = data?.querySelector('.header-section') as HTMLElement;
      if (headerSection) {
        const headerCanvas = await html2canvas(headerSection, {
          useCORS: true,
          allowTaint: true,
          scale: 2
        });

        const headerImgData = headerCanvas.toDataURL('image/png', 1.0);
        const headerWidth = pageWidth;
        const headerHeight = (headerCanvas.height * headerWidth) / headerCanvas.width;

        pdf.addImage(headerImgData, 'PNG', 0, initialMarginTop, headerWidth, headerHeight);

        return headerHeight + initialMarginTop;
      }
      return initialMarginTop;
    };

    if (data) {
      try {
        // Select all report sections
        const reportSections = Array.from(data.querySelectorAll('.report-section')) as HTMLElement[];

        if (reportSections.length === 0) {
          console.error('No report sections found.');
          return;
        }

        let headerHeight = initialMarginTop;
        let isHeaderAdded = false;

        // Render each report section
        for (let i = 0; i < reportSections.length; i++) {
          // Add a new page for each section
          if (i > 0) {
            pdf.addPage();
          }

          // Add header only to the first page
          if (!isHeaderAdded) {
            headerHeight = await addHeaderToPage(pdf);
            isHeaderAdded = true;
          }

          const section = reportSections[i];

          // Create canvas for the current section
          const canvas = await html2canvas(section, {
            useCORS: true,
            allowTaint: true,
            logging: true,
            scale: 2
          });

          const imgData = canvas.toDataURL('image/png', 1.0);
          const canvasWidth = canvas.width;
          const canvasHeight = canvas.height;
          const pdfScaleFactor = pageWidth / canvasWidth;
          const scaledCanvasHeight = canvasHeight * pdfScaleFactor;

          // Determine available page height
          const availablePageHeight = pageHeight - (i === 0 ? headerHeight : subsequentPageMarginTop);

          // If section fits on current page, add it directly
          if (scaledCanvasHeight <= availablePageHeight) {
            pdf.addImage(
              imgData,
              'PNG',
              0,
              i === 0 ? headerHeight : subsequentPageMarginTop,
              pageWidth,
              scaledCanvasHeight
            );
          } else {
            // Section needs to be split across multiple pages
            let currentHeight = 0;

            while (currentHeight < canvasHeight) {
              const pageCanvas = document.createElement('canvas');
              const context = pageCanvas.getContext('2d')!;

              pageCanvas.width = canvas.width;
              pageCanvas.height = Math.min(
                canvasHeight - currentHeight,
                Math.floor((pageHeight - (i === 0 ? headerHeight : subsequentPageMarginTop)) / pdfScaleFactor)
              );

              context.drawImage(
                canvas,
                0,
                currentHeight,
                canvas.width,
                pageCanvas.height,
                0,
                0,
                pageCanvas.width,
                pageCanvas.height
              );

              const pageImgData = pageCanvas.toDataURL('image/png', 1.0);

              pdf.addImage(
                pageImgData,
                'PNG',
                0,
                i === 0 && currentHeight === 0 ? headerHeight : subsequentPageMarginTop,
                pageWidth,
                Math.min(
                  pageHeight - (i === 0 ? headerHeight : subsequentPageMarginTop),
                  scaledCanvasHeight - currentHeight * pdfScaleFactor
                )
              );

              currentHeight += pageCanvas.height;

              // Add new page if more content remains
              if (currentHeight < canvasHeight) {
                pdf.addPage();
              }
            }
          }
        }

        pdf.save('training-report.pdf');
      } catch (error) {
        console.error('Error generating PDF:', error);
      }
    } else {
      console.error('Element with id "training-report" not found.');
    }
  }

  chunkArray(array: any[]): any[] {
    const result = [];
    // Get the first chunk of 15 items
    result.push(array.slice(0, 15));

    // Get the remaining chunks of 20 items
    for (let i = 14; i < array.length; i += 18) {
      result.push(array.slice(i, i + 18));
    }
    return result;
  }

  transformData(data: any = []) {
    if (!data.length) return {};
    const result: any = {};
    data.forEach((item: any) => {
      const title =
        item.training_campaign_material?.document?.title || item.training_campaign_material?.external_link.title;
      const user = item.user;

      // If the title does not exist in the result, initialize it
      if (!result[title]) {
        result[title] = [];
      }

      // Push the relevant user information into the title's array
      result[title].push({
        username: user.username,
        email: user.email,
        assigned_date: user.assigned_date,
        completion_date: user.completion_date,
        user_signature: item.user_signature,
        status: item.training_campaign_material.status,
        end_date: item.training_campaign.end_date
      });
    });

    return result;
  }
}
