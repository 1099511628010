<div class="row" style="margin-bottom: 30px;" xmlns="http://www.w3.org/1999/html">
  <div class="col">
    <div class="single-event-acc mx-auto w-740" [ngClass]="showDetails ? 'expand' : ''">
      <div class="acc-header d-flex justify-content-between">
        <div
          class="participations"
          [ngClass]="{
            'bg-yellow': event['participation_count']['status'] == 'No participation',
            'bg-green': event['participation_count']['status'] == 'Good standing',
            'bg-gray': event['participation_count']['status'] == 'Pending meetings',
            'bg-blue': event['participation_count']['status'] == 'Not selected',
            'bg-red': event['participation_count']['status'] == 'Inactive',
            'bg-maroon': event['participation_count']['status'] == 'Disregard'
          }"
        >
          <h5>
            Participation: {{ event['participation_count']['active_members_count'] }}/{{
              event['participation_count']['all_members_count']
            }}
          </h5>
        </div>
        <h5 class="text-black-1">{{ event['meeting_name'] }}</h5>
        <div class="d-flex align-items-center">
          <span class="running-progress text-black-2" *ngIf="isRunning" translate>
            <i class="fas fa-sync-alt text-cyan pr-1"></i> Running
          </span>
          <ng-container *ngIf="!is_editable">
            <button (click)="stopEvent(event['review_event_guid'])" class="btn-stop ml-4" *ngIf="isRunning">
              <i class="fa fa-spinner fa-spin" style="font-size:16px;color: white" *ngIf="isloading"></i
              ><span style="color: white;" *ngIf="!isloading" translate>Stop</span>
            </button>
            <button
              (click)="runEvent(event['review_event_guid'])"
              class="btn-save ml-4"
              *ngIf="!isRunning && event['event_status'] !== 'disregard' && event['event_status'] !== 'disabled'"
            >
              <i class="fa fa-spinner fa-spin" style="font-size:16px;color: white" *ngIf="isloading"></i
              ><span style="color: white;" *ngIf="!isloading" translate>Run</span>
            </button>
          </ng-container>
          <button class="btn-acc-toggle ml-4 mr-3" (click)="toggleDetails()">
            <i class="fas fa-chevron-up text-black-2" *ngIf="showDetails"></i>
            <i class="fas fa-chevron-down text-black-2" *ngIf="!showDetails"></i>
          </button>
        </div>
      </div>
      <div class="acc-body">
        <div class="event-details p-4">
          <h4 class="text-black-2 mb-3" translate>Details</h4>
          <div class="row mb-2">
            <div class="col-lg-4"><h5 class="text-black-2 font-weight-bold" translate>Title:</h5></div>
            <div class="col-lg-8" *ngIf="!form.name.edit">
              <h5 class="text-black-1 d-inline-block">
                {{ event['meeting_name'] }}
                <i
                  *ngIf="is_editable"
                  (click)="form.name.edit = !form.name.edit"
                  class="fas fa-pencil-alt btn-edit d-inline-block ml-2"
                ></i>
              </h5>
            </div>
            <div class="col-lg-8" *ngIf="form.name.edit">
              <div class="row">
                <textarea
                  id="name"
                  class="form-control cb-input col-10"
                  placeholder="Task title"
                  [(ngModel)]="form.name.data"
                  name="name"
                  type="text"
                  (keyup)="onChange($event, 'name')"
                ></textarea>
                <div class="d-flex align-items-center">
                  <i class="fas fa-check btn-edit col-1" (click)="submitPartForm('name')"></i>
                  <i class="fas fa-times btn-edit col-1" (click)="resetField('name')"></i>
                </div>
              </div>
              <div class="row" *ngIf="form.name.error">
                <p class="error-danger">
                  {{ form.name.error }}
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-lg-4"><h5 class="text-black-2 font-weight-bold" translate>Purpose:</h5></div>
            <div class="col-lg-8" *ngIf="!form.purpose.edit">
              <h5 class="text-black-1 d-inline-block">
                {{ event['purpose'] }}
                <i
                  *ngIf="is_editable"
                  (click)="form.purpose.edit = !form.purpose.edit"
                  class="fas fa-pencil-alt btn-edit d-inline-block ml-2"
                ></i>
              </h5>
            </div>
            <div class="col-lg-8" *ngIf="form.purpose.edit">
              <div class="row">
                <textarea
                  id="purpose"
                  class="form-control cb-input col-10"
                  placeholder="Purpose of the event"
                  [(ngModel)]="form.purpose.data"
                  name="purpose"
                  type="text"
                ></textarea>
                <div class="d-flex align-items-center">
                  <i class="fas fa-check btn-edit col-1" (click)="submitPartForm('purpose')"></i>
                  <i class="fas fa-times btn-edit col-1" (click)="resetField('purpose')"></i>
                </div>
              </div>
              <div class="row" *ngIf="form.purpose.error">
                <p class="error-danger">
                  {{ form.purpose.error }}
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-lg-4"><h5 class="text-black-2 font-weight-bold" translate>Description:</h5></div>
            <div class="col-lg-8" *ngIf="!form.description.edit">
              <h5 class="text-black-1 d-inline-block">
                {{ event['description'] }}
                <i
                  *ngIf="is_editable"
                  (click)="form.description.edit = !form.description.edit"
                  class="fas fa-pencil-alt btn-edit d-inline-block ml-2"
                ></i>
              </h5>
            </div>
            <div class="col-lg-8" *ngIf="form.description.edit">
              <div class="row">
                <textarea
                  id="description"
                  class="form-control cb-input col-10"
                  placeholder="Description"
                  [(ngModel)]="form.description.data"
                  name="description"
                  type="text"
                ></textarea>
                <div class="d-flex align-items-center">
                  <i class="fas fa-check btn-edit col-1" (click)="submitPartForm('description')"></i>
                  <i class="fas fa-times btn-edit col-1" (click)="resetField('description')"></i>
                </div>
              </div>
              <div class="row" *ngIf="form.description.error">
                <p class="error-danger">
                  {{ form.description.error }}
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-lg-4"><h5 class="text-black-2 font-weight-bold" translate>Committee Name:</h5></div>
            <div class="col-lg-8" *ngIf="!form.committee_name.edit">
              <h5 class="text-black-1 d-inline-block">{{ selectedCommitteeName.name }}</h5>
              <i
                *ngIf="is_editable"
                (click)="form.committee_name.edit = !form.committee_name.edit"
                class="fas fa-pencil-alt btn-edit d-inline-block ml-2"
              ></i>
            </div>
            <div class="col-lg-8" *ngIf="form.committee_name.edit">
              <div class="row">
                <ng-select
                  [items]="event['committee_name']"
                  class="committee-edit-select col-10"
                  bindLabel="name"
                  placeholder="Select committee name"
                  [(ngModel)]="form.committee_name.data"
                >
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <b>{{ item.name }}</b>
                  </ng-template>
                </ng-select>
                <div class="d-flex align-items-center">
                  <i class="fas fa-check btn-edit col-1" (click)="submitPartForm('committee_name')"></i>
                  <i class="fas fa-times btn-edit col-1" (click)="resetField('committee_name')"></i>
                </div>
              </div>
              <div class="row" *ngIf="form.committee_name.error">
                <p class="error-danger">
                  {{ form.committee_name.error }}
                </p>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-lg-4"><h5 class="text-black-2 font-weight-bold" translate>Slack Channel Name:</h5></div>
            <div class="col-lg-8" *ngIf="!form.channel_name.edit">
              <h5 class="text-black-1 d-inline-block">{{ event['channel_name'] }}</h5>
              <!--              <i-->
              <!--                *ngIf="is_editable"-->
              <!--                (click)="form.channel_name.edit = !form.channel_name.edit"-->
              <!--                class="fas fa-pencil-alt btn-edit d-inline-block ml-2"-->
              <!--              ></i>-->
            </div>
            <!--            <div class="col-lg-8" *ngIf="form.channel_name.edit">-->
            <!--              <div class="row">-->
            <!--                <input-->
            <!--                  id="channel_name"-->
            <!--                  class="form-control cb-input col-10"-->
            <!--                  placeholder="Channel Name"-->
            <!--                  [(ngModel)]="form.channel_name.data"-->
            <!--                  name="channel_name"-->
            <!--                  type="text"-->
            <!--                />-->
            <!--&lt;!&ndash;                <div class="d-flex align-items-center">&ndash;&gt;-->
            <!--&lt;!&ndash;                  <i class="fas fa-check btn-edit col-1" (click)="submitPartForm('channel_name')"></i>&ndash;&gt;-->
            <!--&lt;!&ndash;                  <i (click)="form.channel_name.edit = !form.channel_name.edit" class="fas fa-times btn-edit col-1"></i>&ndash;&gt;-->
            <!--&lt;!&ndash;                </div>&ndash;&gt;-->
            <!--              </div>-->
            <!--              <div class="row" *ngIf="form.channel_name.error">-->
            <!--                <p class="error-danger">-->
            <!--                  {{ form.channel_name.error }}-->
            <!--                </p>-->
            <!--                <p class="error-danger" *ngIf="form.channel_name.error.channel_name">-->
            <!--                  {{ form.channel_name.error.channel_name[0] }}-->
            <!--                </p>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
          <div class="row mb-2">
            <div class="col-lg-6">
              <div class="row">
                <div class="col-12">
                  <h5 class="text-black-2 font-weight-bold" translate>Schedule:</h5>
                </div>
                <div class="col-12">
                  <span class="btn-time text-black-1 mr-2 mb-2 d-inline-block" *ngIf="!is_editable">
                    {{ event['schedule']['event_schedule'] | titlecase }} - {{ event['schedule']['time'] }}
                  </span>
                  <span
                    class="btn-time text-black-1 mr-2 mb-2 d-inline-block cursor-pointer"
                    (click)="openChangeScheduleDialog()"
                    *ngIf="is_editable"
                  >
                    {{ event['schedule']['event_schedule'] | titlecase }} - {{ event['schedule']['time'] }}
                    <i class="fas fa-pencil-alt ml-2"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="row">
                <div class="col-12">
                  <h5 class="text-black-2 font-weight-bold d-inline-block" translate>Task Status:</h5>
                  <h5 class="text-black-1 d-inline-block ml-2 text-capitalize" *ngIf="!is_editable">
                    {{ event['event_status'] }}
                  </h5>
                </div>
                <div class="col-12" *ngIf="is_editable">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input "
                      type="radio"
                      name="{{ event['review_event_guid'] }}-inlineRadioOptions"
                      id="{{ event['review_event_guid'] }}-inlineRadio1"
                      [checked]="'enabled' == event['event_status']"
                      [disabled]="event['is_attached_to_evidence']"
                    />
                    <label
                      class="form-check-label radio-1 text-black-1"
                      for="{{ event['review_event_guid'] }}-inlineRadio1"
                      (click)="handleEnableClick($event, 'Enable')"
                    >
                      Enable
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="{{ event['review_event_guid'] }}-inlineRadioOptions"
                      id="{{ event['review_event_guid'] }}-inlineRadio2"
                      [checked]="'disabled' == event['event_status']"
                      [disabled]="event['is_attached_to_evidence']"
                    />
                    <label
                      class="form-check-label radio-1 text-black-1"
                      for="{{ event['review_event_guid'] }}-inlineRadio2"
                      (click)="handleEnableClick($event, 'Disable')"
                    >
                      Disable
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="{{ event['review_event_guid'] }}-inlineRadioOptions"
                      id="{{ event['review_event_guid'] }}-inlineRadio3"
                      [checked]="'disregard' == event['event_status']"
                      [disabled]="event['is_attached_to_evidence']"
                    />
                    <label
                      class="form-check-label radio-1 text-black-1"
                      for="{{ event['review_event_guid'] }}-inlineRadio3"
                      (click)="handleEnableClick($event, 'Disregard')"
                    >
                      Disregard
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Commenting out below task edit date change section, Story ref -> https://webshar.atlassian.net/browse/COM-1746 -->
          <div class="row mb-2" *ngIf="event?.['schedule']?.['event_schedule'] != 'DAILY'">
            <div class="col-lg-6">
              <div class="row">
                <div class="col-12">
                  <h5 class="text-black-2 font-weight-bold" translate>Upcoming Meeting</h5>
                </div>
                <div class="col-12">
                  <div
                    *ngIf="!isDateEdit"
                    class=" text-black-1 btn-time text-grey  mb-2 d-inline-block"
                    style="min-width: 120px !important"
                  >
                    {{ event['custom_next_run_time'] || event['next_run_time'] | date: 'MMM dd, hh:mm a' }}
                    <i (click)="isDateEdit = true" class="fas fa-pencil-alt ml-2"></i>
                  </div>
                  <div [hidden]="!isDateEdit" class="input-group p-0 mx-0 ">
                    <input
                      class=" text-black-1 form-control cb-input"
                      (dateSelect)="onChange('date', 'next_run_time')"
                      ngbDatepicker
                      #d="ngbDatepicker"
                      (click)="d.toggle()"
                      [minDate]="minDate"
                      [maxDate]="maxDate"
                      [(ngModel)]="eventNextRunDate"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-secondary fa fa-calendar-alt cb-input"
                        (click)="d.toggle()"
                        type="button"
                      ></button>
                    </div>
                    <div class="cb-input mx-1 cb-time">
                      <i class="fa fa-clock"></i>
                      <input
                        placeholder="Time"
                        aria-label="Time"
                        class="time_input"
                        [ngxTimepicker]="timePicker"
                        [value]="this.eventNextRunTime"
                        readonly
                      />
                      <ngx-material-timepicker
                        class="timepicker"
                        #timePicker
                        (timeSet)="timeSet($event)"
                      ></ngx-material-timepicker>
                    </div>
                    <div class="d-flex align-items-center">
                      <i class="fas fa-check btn-edit col-1" (click)="submitDateChanges()"></i>
                      <i class="fas fa-times btn-edit col-1" (click)="cancelChanges()"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span *ngIf="event['is_attached_to_evidence'] && is_editable">
            <label translate>
              Note:The task is already linked to the evidence, so we cannot modify the status.
            </label>
          </span>
        </div>
        <div class="event-more-details p-4" [ngClass]="showMoreDetails ? 'expand' : ''">
          <h4 class="text-black-2 mb-3" translate>More Details</h4>
          <app-event-details
            *ngIf="!isEDLoading"
            [is_editable]="is_editable"
            [document_urls]="document_urls"
            [event_start_message]="event_start_message"
            [event_end_message]="event_end_message"
            [event]="event"
            [eventDateEdit]="event_date_edit"
          ></app-event-details>
        </div>
        <div class="event-view-more text-right">
          <span class="text-black-2" (click)="openEventDetails()" *ngIf="!showMoreDetails" translate>
            View More <img class="mr-4 ml-2" src="/assets/images/core/elipsis.svg"
          /></span>
          <span class="text-black-2" (click)="toggleMoreDetails()" *ngIf="showMoreDetails" translate>
            View Less <img class="mr-4 ml-2" src="/assets/images/core/elipsis.svg"
          /></span>
        </div>
      </div>
    </div>
  </div>
</div>
