import { Component, OnInit, Input, inject, ViewChildren, ElementRef, EventEmitter } from '@angular/core';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { OrganisationAdminService } from '@app/Organisation-admin/organisation-admin.service';
import { OrganisationService } from '@app/core/organisation.service';
import * as _ from 'lodash';
import { DocumentsService } from '../documents.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { E, G } from '@angular/cdk/keycodes';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { ModalService } from '@app/core/modal/modal.service';
import { take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import stringInfo from '../../../../translations/en-US.json';
import { Router } from '@angular/router';
import { SettingsService } from '@app/settings/settings.service';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { GoogleDriveUploadService } from '../google-drive-upload.service';

@Component({
  selector: 'app-import-document',
  templateUrl: './import-document.component.html',
  styleUrls: ['./import-document.component.scss']
})
export class ImportDocumentComponent implements OnInit {
  @Input() dialogData: any = null;
  buttonDisable = true;
  userSignatureCheck: boolean = false;
  UserSignature: any = null;
  isSignatureLoading = true;
  signature_error: boolean = false;
  agreement_accepted: boolean = true;
  statusData: any;
  loading = true;
  isloading = false;
  docTitle = stringInfo.Create_Document.Doc_Title;
  time = stringInfo.Create_Document.Time;
  version = stringInfo.Create_Document.Version_Placeholder;
  FolderList: object[];
  SubFolderList: object[];
  document_types: any = [];
  OrganisationID: string;
  DocStoreguid: string;
  addDocumentForm: FormGroup;
  selectFolderForm: FormGroup;
  submitted = false;
  allowed_duration_data: {};
  allowed_duration_selected: string;
  allowed_duration: any = [];
  training_allowed_duration_data: {};
  training_allowed_duration_selected: string;
  training_allowed_duration: any = [];
  form_error: any;
  document_type: any;
  error_message: any;
  folder_name: any;
  selectedFolderDetails: any;

  oldFolderName: any;
  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#fff',
      buttonColor: '#FFFFFF'
    },
    dial: {
      dialBackgroundColor: '#555'
    },
    clockFace: {
      clockFaceBackgroundColor: '#fff',
      clockHandColor: 'black',
      clockFaceTimeInactiveColor: 'black'
    }
  };

  error: any;
  selected_option: any;
  subfolder: any[];
  checkedItem: string;
  parent_folder: any;
  template_name: any = '';
  template_guid: any;
  createDocStorageId: string = 'create_doc_folder_data';

  DocCreate_Options = [{ id: 'upload_document', name: 'Upload a document' }];
  document_guid: any;

  upload_template_name: any = '';
  checkFileFormat: any;
  guidSubscription: any;
  backend_error: any;

  DocOptionsChange(option: any) {
    this.selected_option = option;
  }

  openSelectTemplateForm() {
    this.SelectTemplateOpenModal();
  }

  SelectTemplateOpenModal(): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Select Template',
        modalType: 'Select Template'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
        } else {
        }
      });
  }

  schedules = [
    {
      value: 'SEMI-ANNUALLY',
      display_value: 'Semi Annually'
    },
    {
      value: 'ANNUALLY',
      display_value: 'Annually'
    },
    {
      value: 'EVERY TWO YEARS',
      display_value: 'Every Two Years'
    }
  ];

  numbered_week = [
    {
      display_string: 'first',
      value: '1st'
    },
    {
      display_string: 'second',
      value: '2nd'
    },
    {
      display_string: 'third',
      value: '3rd'
    },
    {
      display_string: 'fourth',
      value: '4th'
    },
    {
      display_string: 'last',
      value: 'last'
    }
  ];

  days = [
    {
      display_string: 'Monday',
      value: 'mon'
    },
    {
      display_string: 'Tuesday',
      value: 'tue'
    },
    {
      display_string: 'Wednesday',
      value: 'wed'
    },
    {
      display_string: 'Thursday',
      value: 'thu'
    },
    {
      display_string: 'Friday',
      value: 'fri'
    },
    {
      display_string: 'Saturday',
      value: 'sat'
    },
    {
      display_string: 'Sunday',
      value: 'sun'
    }
  ];

  numbered_bi = [
    {
      display_string: 'Odd',
      value: '1/2'
    },
    {
      display_string: 'Even',
      value: '2/2'
    }
  ];

  numbered_quarter = [
    {
      display_string: 'first',
      value: '1/3'
    },
    {
      display_string: 'second',
      value: '2/3'
    },
    {
      display_string: 'third',
      value: '3/3'
    }
  ];

  months_half_year = [
    {
      display_string: 'January And July',
      value: '1/6'
    },
    {
      display_string: 'February And August',
      value: '2/6'
    },
    {
      display_string: 'March And September',
      value: '3/6'
    },
    {
      display_string: 'April And October',
      value: '4/6'
    },
    {
      display_string: 'May And November',
      value: '5/6'
    },
    {
      display_string: 'June And December',
      value: '6/6'
    }
  ];

  months_year = [
    {
      display_string: 'January',
      value: '1'
    },
    {
      display_string: 'February',
      value: '2'
    },
    {
      display_string: 'March',
      value: '3'
    },
    {
      display_string: 'April',
      value: '4'
    },
    {
      display_string: 'May',
      value: '5'
    },
    {
      display_string: 'June',
      value: '6'
    },
    {
      display_string: 'July',
      value: '7'
    },
    {
      display_string: 'August',
      value: '8'
    },
    {
      display_string: 'September',
      value: '9'
    },
    {
      display_string: 'October',
      value: '10'
    },
    {
      display_string: 'November',
      value: '11'
    },
    {
      display_string: 'December',
      value: '12'
    }
  ];

  public data = {
    renewal_schedule: '',
    day_of_week: '',
    day: '',
    month: '*',
    year: '*',
    time: '',
    week: ''
  };

  constructor(
    private backendErrorService: BackendErrorService,
    private orgAdminService: OrganisationAdminService,
    private formBuilder: FormBuilder,
    private orgService: OrganisationService,
    private documentsService: DocumentsService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private modalService: ModalService,
    private http: HttpClient,
    private router: Router,
    private settingsService: SettingsService,
    private googleDriveService: GoogleDriveUploadService
  ) {}

  // static convertTimeTo24Format(time: any) {
  //   return moment(time, ['h:mm A']).format('HH:mm');
  // }

  // convertTimeTo24Format(time: any) {
  //   return moment(time, ['h:mm A']).format('HH:mm');
  // }

  onChange(evt: any) {
    if (evt.type == 'keyup' && this.backend_error) {
      this.backend_error = '';
    }
  }

  ngOnInit() {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
    });
    this.oldFolderName = this.folder_name;
    this.selected_option = 'select_doc_opt';
    localStorage.removeItem(this.createDocStorageId);
    this.selectFolderForm = this.formBuilder.group({
      select_folder: ['', [Validators.required()]],
      select_subfolder: ['']
    });
    this.settingsService.getUserSignature(this.OrganisationID).subscribe(
      data => {
        this.UserSignature = data.url;
        if (this.UserSignature == undefined) {
          this.userSignatureCheck = true;
        }
        console.log(' this.UserSignature', this.UserSignature);
        this.isSignatureLoading = false;
        this.loading = false;
      },
      error => {
        this.isSignatureLoading = false;
        this.loading = false;
      }
    );
    this.addDocumentForm = this.formBuilder.group({
      title: ['', [Validators.required(), Validators.maxLength(255)]],
      description: ['', [Validators.required(), Validators.maxLength(4096)]],
      document_type: [''],
      version: ['', [Validators.required(), Validators.restrictZeroNumber()]],
      renewal_recurring: [''],
      file: [''],
      agreement_accepted: ['']
    });

    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
      this.documentsService.getOrganizationFolderList(this.OrganisationID).subscribe(
        data => {
          this.FolderList = data.results;
        },
        error => {
          this.error = error.error.message;
        }
      );
      this.documentsService.getOrganizationDocumentTypesList().subscribe(data => {
        this.document_types = data;
      });
    });

    this.documentsService.getDocStoreID(this.OrganisationID).subscribe(
      data => {
        this.DocStoreguid = data['organization_document_store_guid'];
      },
      error => {
        this.error = error.error.message;
      }
    );

    if (this.dialogData) {
      this.data = _.cloneDeep(this.dialogData.event.schedule);
      this.getAllowedDuration(this.dialogData.event.schedule.renewal_schedule, this.dialogData.event.allowed_duration);
      if (_.indexOf(this.dialogData.event.schedule.day, ' ') > 0) {
        const split_day = this.data.day.split(' ');
        this.data.day = split_day[0];
        this.data.day_of_week = split_day[1];
      }
      if (this.data.renewal_schedule === 'EVERY TWO YEARS') {
        const year_data = this.data.year.split('/');
        if (+year_data[0] % 2 === 1) {
          this.data.year = '1/2';
        } else {
          this.data.year = '2/2';
        }
      }
    } else {
      this.getAllowedDuration();
      this.data = {
        renewal_schedule: 'SEMI-ANNUALLY',
        time: '09:00 AM',
        day_of_week: 'mon',
        week: null,
        day: '1st',
        month: '1/6',
        year: '*'
      };
    }
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
    localStorage.removeItem(this.createDocStorageId);
  }

  onReset() {
    this.submitted = false;
    this.addDocumentForm.reset();
    this.folder_name = '';
    this.template_name = '';
    this.selected_option = '';
    localStorage.removeItem(this.createDocStorageId);
    this.backend_error = '';
  }

  dataRefresh(event: any) {
    const renewal_schedule: string = event;
    const time: string = this.data.time;
    switch (renewal_schedule) {
      case 'WEEKLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: null,
          month: '*',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'BI-WEEKLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: null,
          month: '*',
          year: '*',
          time: time,
          week: '1/2'
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'MONTHLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '*',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'BI-MONTHLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1/2',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'QUARTERLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1/3',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'SEMI-ANNUALLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1/6',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'ANNUALLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'EVERY TWO YEARS':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1',
          year: '1/2',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      default:
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: null,
          day: null,
          month: '*',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
    }
  }

  getAllowedDuration(schedule?: string, duration_value?: string) {
    this.orgAdminService.getEventAllowedDuration().subscribe(result => {
      if (result && result.allowed_durations) {
        this.allowed_duration_data = result.allowed_durations;
        this.allowed_duration = schedule
          ? this.getCurrentAllowedDuration(result.allowed_durations, schedule)
          : this.getCurrentAllowedDuration(result.allowed_durations, 'SEMI-ANNUALLY');
        this.allowed_duration_selected = duration_value ? duration_value : '1 Day';
      }
    });
  }

  getCurrentAllowedDuration(data: {}, type: string) {
    for (const key in data) {
      if (`${key}` === type) {
        return `${data[key]}`.split(',');
      }
    }
  }

  timeSet(time: any) {
    this.data.time = time;
  }

  getPreparedData() {
    const schedule_data = _.cloneDeep(this.data);
    schedule_data['allowed_duration'] = this.allowed_duration_selected;
    if (schedule_data.renewal_schedule === 'DAILY') {
      schedule_data.day_of_week = 'mon-fri';
    } else if (schedule_data.renewal_schedule === 'EVERY TWO YEARS') {
      const year_data = schedule_data.year.split('/');
      if (+year_data[0] % 2 === 1) {
        schedule_data.year = '2019/2';
      } else {
        schedule_data.year = '2020/2';
      }
    }

    if (schedule_data.day !== null && schedule_data.day_of_week) {
      schedule_data.day = schedule_data.day + ' ' + schedule_data.day_of_week;
      schedule_data.day_of_week = null;
    }

    return this.orgAdminSchedularData(Object.assign({}, schedule_data));
  }

  public orgAdminSchedularData(scheduler_data: any): object {
    for (const key in scheduler_data) {
      if (scheduler_data.hasOwnProperty(key) && scheduler_data[key] === '') {
        scheduler_data[key] = null;
      } else {
      }
    }
    return scheduler_data;
  }

  file: any;
  size: any;
  fileEvent(input: any) {
    if (input.type == 'change' && this.backend_error) {
      this.backend_error = '';
    }
    this.file = input.target.files[0];
    // if (this.file) {
    //   this.buttonDisable = false;
    // } else {
    //   this.buttonDisable = true;
    // }
    this.document_type = this.file.name.substr(this.file.name.lastIndexOf('.') + 1);
    this.size = this.file.size;
    if (
      this.document_type == 'docx' ||
      this.document_type == 'doc' ||
      this.document_type == 'pptx' ||
      this.document_type == 'xlsx' ||
      this.document_type == 'pdf'
    ) {
      this.upload_error_message = '';
    } else {
      this.upload_error_message = 'Please select .docx,.ppt,.xlsx,.pdf format only.';
    }
  }

  ngAfterViewInit() {
    this.documentsService.shareSelectedFolder$.subscribe(shareSelectedFolder => {
      let storedFolderObj = localStorage.getItem(this.createDocStorageId);
      if (storedFolderObj) {
        let storedFolderData = JSON.parse(storedFolderObj);
        this.selectedFolderDetails = shareSelectedFolder;
        this.folder_name = storedFolderData.folderPath;
        this.parent_folder = storedFolderData.selectedFolderGuid;
      }
    });
    this.documentsService
      .getTemplateName()
      .subscribe((item: any) => (this.template_name = this.documentsService.getTemplate()));
    this.documentsService
      .getTemplateName()
      .subscribe((item: any) => (this.template_guid = this.documentsService.getTemplateGuid()));
  }

  upload_error_message = '* Please upload document.';

  submit() {
    if (this.folder_name != this.oldFolderName) {
      this.backend_error = '';
    }
    this.submitted = true;

    if (this.folder_name && this.addDocumentForm.valid) {
      this.isloading = true;
      const document_data = Object.assign({}, this.addDocumentForm.value);
      console.log('document_data', document_data);

      document_data['org_document_store'] = this.DocStoreguid;

      if (document_data.renewal_recurring) {
        document_data['renewal_schedule'] = this.getPreparedData();
      } else {
        document_data['renewal_schedule'] = null;
      }

      if (this.folder_name !== '') {
        let data = this.folder_name;
        document_data['parent_folder'] = this.parent_folder;
      } else {
        let data1 = this.folder_name;
        document_data['parent_folder'] = this.parent_folder;
      }
      this.error_message = '';
      // if (document_data['agreement_accepted']) {
      //   this.agreement_accepted = true;
      this.modalService
        .custom(CustomDialogComponent, {
          title: 'Import Document',
          modalType: 'Import Document',
          docTitle: document_data.title
        })
        .pipe(take(1)) // take() manages unsubscription for us
        .subscribe((result: any) => {
          if (result) {
            this.submitted = false;

            this.createDocumentUploadPayload(document_data);
          }
        });

      localStorage.removeItem(this.createDocStorageId);
      // } else {
      this.submitted = false;
      this.isloading = false;
      // this.agreement_accepted = false;
      // if (!this.UserSignature) {
      //   this.signature_error = true;
      // } else {
      //   this.signature_error = false;
      // }
      // return;
    }
  }

  createDocumentUploadPayload(document_data: any): void {
    this.isloading = true;
    const data = {
      title: document_data.title,
      filename: this.file.name,
      size: this.size,
      folder_guid: document_data['parent_folder']
    };
    this.checkFileFormat = data.filename.substr(this.file.name.lastIndexOf('.') + 1);
    if (
      this.checkFileFormat == 'docx' ||
      this.checkFileFormat == 'doc' ||
      this.checkFileFormat == 'pptx' ||
      this.checkFileFormat == 'xlsx' ||
      this.checkFileFormat == 'pdf'
    ) {
      this.upload_error_message = '';
      this.documentsService.uploadDocument(this.OrganisationID, data).subscribe(
        async (data: any) => {
          // this.isloading = false;
          if (data['store_type'] == 'GOOGLE_DRIVE') {
            document_data['document_type'] = data.type;
            try {
              const { fileId, fileType } = await this.googleDriveService.uploadFileAndGetInfo(
                this.file,
                data['temp_token'],
                data['folder_id'],
                document_data.title
              );
              console.log('File ID:', fileId);
              console.log('File Type:', fileType);
              document_data['file'] = fileId;
              this.submitCreateDocumentData(document_data);
              // Handle successful upload
            } catch (error) {
              this.backend_error = error;
              console.error('Error uploading file:', error);
            }
          } else {
            let attributes = {
              name: data.title,
              parent: { id: data.restricted_to }
            };
            document_data['document_type'] = data.type;
            this.documentsService.uploadDocumentToBox(data.url, this.file, data.access_token, attributes).subscribe(
              (box: any) => {
                document_data['file'] = box.entries[0].id;
                this.submitCreateDocumentData(document_data);
              },
              (error: any) => {
                this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
                this.backendErrorService.backendError$.subscribe(error => {
                  this.backend_error = error;
                });
              }
            );
          }
        },
        (error: any) => {
          this.isloading = false;
          this.submitted = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
    }
  }

  submitCreateDocumentData(document_data: any): void {
    delete document_data['folder'];
    delete document_data['document_option'];
    this.documentsService.submitOrganisationImportDocumentData(this.OrganisationID, document_data).subscribe(
      data => {
        this.isloading = false;
        this.error = {
          title: '',
          version: '',
          folder: ''
        };
        this.translate.get('Approve_Changes.approve_changes_toaster_message.upload').subscribe(res => {
          this.toaster.showSuccess(res);
          this.addDocumentForm.reset();
          this.selectFolderForm.reset();
          this.folder_name = '';
          this.template_name = '';
          this.selected_option = '';
          this.backend_error = '';
        });
      },
      (error: any) => {
        this.isloading = false;
        this.submitted = false;
        this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
        });
      }
    );
  }
  goToSignatureUpload() {
    this.router.navigate(['organisation/settings/profile']);
  }
  SelectFolderDialog(): void {
    if (this.backend_error) {
      this.backend_error = '';
    }
    this.documentsService.setSelectedFolderData(this.selectedFolderDetails);
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Select a folder',
        storage_id: this.createDocStorageId,
        modalType: 'document'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {});
  }
}
