import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { ShellComponent } from './shell.component';
import { HeaderComponent } from './header/header.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { OrgAdminshellModule } from '@app/shells/orgAdminshell/orgAdminshell.module';
import { SettingsSubShellComponent } from '@app/shells/shell/settingsSubShell';
import { SharedModule } from '@app/shared';
import { DemoMaterialModule } from '../../materials.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    FlexLayoutModule,
    OrgAdminshellModule,
    SharedModule,
    DemoMaterialModule
  ],
  declarations: [HeaderComponent, ShellComponent, SettingsSubShellComponent]
})
export class ShellModule {}
