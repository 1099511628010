<div class="modal-header cb-modal-header">
  <div>
    <h3 class="modal-title font-weight-bold text-black-1" translate>Link_jira_ticket.header</h3>
    <i
      class="fa fa-times text-black-1"
      style="width: 12px;
          height: 12px;
          right: 1em;
          cursor: pointer;
          position: absolute;
          top: 1.3em;"
      (click)="activeModal.close(false)"
      ngbAutoFocus
      translate
    ></i>
  </div>
</div>
<div class="modal-body">
  <div class="row mt-1 mb-3" *ngIf="backend_error">
    <div class="col-12">
      <span class="text-danger backend_error" translate> * {{ backend_error }}</span>
    </div>
  </div>

  <form [formGroup]="LinkIssueForm" novalidate>
    <div class="row mt-2">
      <div class="col-3">
        <label class="text-black-2 p-2" translate>Link_jira_ticket.Jira ID</label>
      </div>
      <div div class="col-9">
        <input
          id="jira_id"
          formControlName="jira_id"
          class="form-control cb-input"
          placeholder="Jira Id"
          name="aws"
          type="text"
          (change)="JiraId($event)"
          (input)="changeJiraid($event)"
          autocomplete="off"
        />
        <div
          class="col-9"
          *ngIf="
            LinkIssueForm.controls.jira_id.invalid &&
            (LinkIssueForm.controls.jira_id.dirty || LinkIssueForm.controls.jira_id.touched || submitted)
          "
        >
          <label class="text-danger" *ngIf="LinkIssueForm.controls.jira_id.errors.required" translate
            >Link_jira_ticket.Jira ID is required</label
          >
        </div>
      </div>
    </div>
    <div *ngIf="showData">
      <div class="row mt-2">
        <div class="col-3">
          <label class="text-black-2 p-2" translate>Link_jira_ticket.Project Name </label>
        </div>
        <div div class="col-9">
          <input
            id="project_name"
            formControlName="project_name"
            class="form-control cb-input"
            placeholder="project_name"
            name="aws"
            type="text"
            autocomplete="off"
            disabled
          />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-3">
          <label class="text-black-2 p-2" translate>Link_jira_ticket.Project key </label>
        </div>
        <div div class="col-9">
          <input
            id="project_tag"
            formControlName="project_tag"
            class="form-control cb-input"
            placeholder="project_tag"
            name="aws"
            type="text"
            autocomplete="off"
            disabled
          />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-3">
          <label class="text-black-2 p-2" translate>Link_jira_ticket.Summary </label>
        </div>
        <div div class="col-9">
          <input
            id="summary"
            formControlName="summary"
            class="form-control cb-input"
            placeholder="Summary"
            name="aws"
            type="text"
            autocomplete="off"
            disabled
          />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-3">
          <label class="text-black-2 p-2" translate>Link_jira_ticket.Issue type </label>
        </div>
        <div div class="col-9">
          <input
            id="issue_type"
            formControlName="issue_type"
            class="form-control cb-input"
            placeholder="Issue Type"
            name="aws"
            type="text"
            autocomplete="off"
            disabled
          />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-3">
          <label class="text-black-2 p-2" translate>Link_jira_ticket.Description </label>
        </div>
        <div div class="col-9">
          <textarea
            id="comment"
            class="form-control cb-input"
            [placeholder]="'Description'"
            name="description"
            type="text"
            formControlName="description"
            cols="64"
            rows="5"
            disabled
          ></textarea>
        </div>
      </div>
      <div class="modal-footer border-0 cb-modal-footer" style="padding-bottom: 1px; padding-right: 0px;">
        <button
          class="btn-cancel ml-4 w-100 mt-3 float-right"
          (click)="activeModal.close(false)"
          ngbAutoFocus
          translate
        >
          Link_jira_ticket.Cancel
        </button>
        <button
          class="btn-save ml-4 w-100 mt-3 mb-3 float-right"
          *ngIf="!isLoading && Link_jira_ticket_Submit == 'Link'"
          (click)="save()"
          [disabled]="LinkIssueForm.invalid"
          [ngStyle]="{ 'background-color': LinkIssueForm.invalid ? 'rgba(0,0,0,.26)' : '#4db7c6' }"
          translate
        >
          {{ Link_jira_ticket_Submit }}
        </button>
        <button class="btn-save ml-4 w-100 mt-3 mb-3 float-right" disabled *ngIf="isLoading">
          <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
        </button>
      </div>
    </div>
  </form>
</div>

<div *ngIf="showSaveButton" class="modal-footer border-0 cb-modal-footer" style="margin-top: -25px;">
  <button class="btn-cancel ml-4 w-100 mt-3 float-right" (click)="activeModal.close(false)" ngbAutoFocus translate>
    Link_jira_ticket.Cancel
  </button>
  <button
    class="btn-save ml-4 w-100 mt-3 mb-3 float-right"
    *ngIf="!isLoading && Link_jira_ticket_Submit == 'Fetch Details'"
    [disabled]="LinkIssueForm.invalid"
    [ngStyle]="{ 'background-color': LinkIssueForm.invalid ? 'rgba(0,0,0,.26)' : '#4db7c6' }"
    (click)="getData()"
    translate
  >
    {{ Link_jira_ticket_Submit }}
  </button>
  <button class="btn-save ml-4 w-100 mt-3 mb-3 float-right" disabled *ngIf="isLoading">
    <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
  </button>
</div>
