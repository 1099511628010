<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div>
    <div class="modal-header cb-modal-header">
      <div>
        <h4 class="modal-title font-weight-bold text-black-1" translate>Restore_Editor.Title</h4>
        <i
          class="fa fa-times text-black-1"
          style="width: 12px;
                  height: 12px;
                  right: 1em;
                  cursor: pointer;
                  position: absolute;
                  top: 1.3em;"
          (click)="activeModal.close(false)"
          ngbAutoFocus
          translate
        ></i>
      </div>
    </div>
    <!-- <h3 for="due_date" class="text-black-3 font-weight-bold p-2">{{ docTitle }}</h3> -->
    <div class="modal-body">
      <div class="row mt-1 mb-3" *ngIf="backend_error">
        <div class="col-12">
          <span class="text-danger backend_error" translate> * {{ backend_error }}</span>
        </div>
      </div>
      <h3 class="modal-title font-weight-bold text-black-1 text-center m-auto p-4">{{ message }}</h3>
    </div>
    <form [formGroup]="RestorEditForm">
      <div class="row mt-3">
        <div class="col-3">
          <label class="text-black-4 p-2" translate>Document_Invite_Editor.Title </label>
        </div>
        <div class="col-9">
          <div class="input-group">
            <input class="form-control cb-input" value="{{ docTitle }}" disabled />
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-3">
          <label for="due_date" class="text-black-1 p-22" translate>Restore_Editor.Due_Date </label>
        </div>
        <div class="col-9">
          <div class="input-group">
            <input
              id="restore_due_date"
              class="form-control cb-input"
              placeholder="yyyy-mm-dd"
              formControlName="due_date"
              name="due_date"
              ngbDatepicker
              #d="ngbDatepicker"
              (click)="d.toggle()"
              required
              (dateSelect)="onDateSelection($event)"
              [minDate]="minDate"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary fa fa-calendar-alt cb-input"
                (click)="d.toggle()"
                type="button"
              ></button>
            </div>
            <div class="col-12 error-mssg" *ngIf="(f.due_date.touched || submitted) && f.due_date.errors">
              <label for="due_date" class="text-danger" *ngIf="f.due_date.errors.required" translate>
                Restore_Editor.Due_Date_Req</label
              >
              <label for="due_date" class="text-danger" *ngIf="f.due_date.errors.DateTodayOrAfter_error" translate>
                Restore_Editor.Due_Date_Gre</label
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-3">
          <label for="comment" class="text-black-1 p-22" translate>Restore_Editor.Comment </label>
        </div>
        <div class="col-9">
          <div class="input-group">
            <textarea
              id="comment"
              class="form-control cb-input"
              [placeholder]="'Comment'"
              name="comment"
              type="text"
              formControlName="comment"
              cols="64"
              rows="5"
              [maxLength]="4097"
            ></textarea>
            <div class="col-12 error-mssg" *ngIf="(f.comment.touched || f.comment.dirty) && f.comment.errors">
              <label
                for="name"
                style="margin-left: -10px;"
                class="text-danger"
                *ngIf="f.comment.errors.maxLength"
                translate
                >Mark_Editing_Done.* Ensure that the comment has no more than 4096 characters.</label
              >
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="modal-footer border-0 cb-modal-footer">
      <button class="btn-cancel ml-4 w-100 mt-3 float-right" (click)="activeModal.close(false)" ngbAutoFocus translate>
        Restore_Editor.Cancel
      </button>
      <button
        class="btn-save ml-4 w-100 mt-3 mb-3 float-right"
        id="restore_editor"
        *ngIf="!isLoading"
        (click)="save()"
        translate
      >
        Restore_Editor.Send_Invite
      </button>
      <button class="btn-save ml-4 w-100 mt-3 mb-3 float-right" disabled *ngIf="isLoading">
        <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
      </button>
    </div>
  </div>
</ng-container>
