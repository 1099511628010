<div id="mySidenav" class="sidenav">
  <div class="sidenav-header">
    <span class="title"> {{ type == 'Manage Approvers' ? 'Add Approvers' : 'Add Editors' }}</span>
    <i class="fa fa-times text-black-1 closebtn" (click)="closeNav()" ngbAutoFocus translate></i>
  </div>
  <div class="row mt-2" *ngIf="backend_error">
    <div class="col-12 col-sm-12 col-lg-12 w-100p ml-3">
      <div class="text-danger" translate>* {{ backend_error }}</div>
    </div>
  </div>
  <form [formGroup]="InviteEditorForm" class="sidenav-body form">
    <div class="form-group">
      <label for="Select Editors" class="label">
        {{ type == 'Manage Approvers' ? 'Select Approvers' : 'Select Editors' }}
      </label>
      <ng-select
        class="custom-ng-select"
        [clearSearchOnAdd]="true"
        [closeOnSelect]="false"
        [multiple]="true"
        placeholder="{{ type == 'Manage Approvers' ? 'Select approvers' : 'Select editors' }}"
        clearAllText="Clear"
        [items]="UsersList"
        bindLabel="fullName"
        class="select-editors"
      >
        <ng-template ng-option-tmp let-item="item">
          <div
            class="option-item d-flex"
            (click)="$event.stopPropagation()"
            [ngStyle]="{
              backgroundColor: item.selected ? '#E5F7F9' : ''
            }"
          >
            <div class="d-flex selected-option ">
              <input
                class="custom-checkbox"
                id="item-{{ item.guid }}"
                type="checkbox"
                [checked]="item.selected"
                (change)="onItemSelect(item)"
              />
              <div class="d-flex cursor-default" (click)="onItemSelect(item)">
                <span class="initials" [style.backgroundColor]="getColor(item.first_name)">
                  {{ getInitials(item.first_name, item.last_name) }}
                </span>
                <div (click)="onItemSelect(item)">
                  <div class="username" (click)="onItemSelect(item)">{{ item.first_name }} {{ item.last_name }}</div>
                  <div class="email" (click)="onItemSelect(item)">({{ item.email }})</div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-select>
      <div class="selected-items" *ngIf="selectedItems.length > 0" (mouseleave)="showTooltip = false">
        <ng-container *ngFor="let item of selectedItems.slice(0, 2)">
          <span class="badge badge-primary mr-2">
            {{ item.first_name }} {{ item.last_name }} <br />
            ({{ item.email }})
            <span class="badge-remove" (click)="removeItem(item)">x</span>
          </span>
        </ng-container>
        <ng-container *ngIf="selectedItems.length > 2">
          <span (mouseenter)="showTooltip = true">
            <span class="count">+{{ selectedItems.length - 2 }}</span>
            <div
              class="tooltip"
              *ngIf="showTooltip"
              (mouseenter)="showTooltip = true"
              (mouseleave)="showTooltip = false"
            >
              <ng-container *ngFor="let item of selectedItems.slice(2)">
                <span class="badge badge-primary mr-2">
                  {{ item.first_name }} {{ item.last_name }} <br />
                  ({{ item.email }})
                  <span class="badge-remove" (click)="removeItem(item)">x</span>
                </span>
              </ng-container>
            </div>
          </span>
        </ng-container>
      </div>
      <div class="col-12 error-mssg" *ngIf="!EditorSelected && submitted">
        <label for="editor" class="text-danger" style="margin-left: -13px;" translate>
          {{
            type == 'Manage Approvers'
              ? 'Document_Invite_Approver.invite_approver.*Atleast one approver is required.'
              : 'Document_Invite_Editor.invite_editor.*Atleast one editor is required.'
          }}
        </label>
      </div>
    </div>
    <div class="form-group">
      <label for="duedates" class="label">Due Date</label>
      <div class="position-relative">
        <input
          class="form-control cb-input-focus pr-5"
          id="due_date"
          placeholder="Select due date"
          formControlName="due_date"
          name="due_date"
          ngbDatepicker
          (click)="d.toggle()"
          [minDate]="minDate"
          (dateSelect)="onDateSelection($event)"
          required
          #d="ngbDatepicker"
        />
        <img src="../../../assets/images/core/calender.svg" class="calendar-icon" (click)="d.toggle()" />
      </div>
      <div
        class="col-12 error-mssg pl-2"
        style="margin-left: -5px;"
        *ngIf="(f.due_date.touched || submitted) && f.due_date.errors"
      >
        <label for="due_date" class="text-danger" *ngIf="f.due_date.errors.required" translate>
          Document_Invite_Editor.invite_editor.*Due Date is required.
        </label>
        <label for="due_date" class="text-danger" *ngIf="f.due_date.errors.DateTodayOrAfter_error" translate>
          Document_Invite_Editor.invite_editor.*Due Date must be greater than today.
        </label>
      </div>
    </div>
    <div class="form-group">
      <label for="comment" class="label">Comment</label>
      <textarea
        id="folderDescription"
        formControlName="comment"
        class="form-control cb-input-focus "
        placeholder="Write a comment"
        [maxLength]="4097"
      ></textarea>
      <div
        *ngIf="(f.comment.touched || f.comment.dirty) && f.comment.errors"
        style="margin-left: -5px;"
        class="text-danger pl-2"
      >
        <label *ngIf="f.comment.errors.maxLength">*Comment must be less than 4096 characters.</label>
      </div>
    </div>
  </form>

  <div *ngIf="EditorList?.length > 0" class="w-100p p-3">
    <label class="lable">{{ type == 'Manage Approvers' ? 'Assigned Approvers' : 'Assigned Editors' }}</label>
    <table class="w-100p">
      <thead>
        <tr>
          <th class="p-2">NAME</th>
          <th class="p-2">DUE DATE</th>
          <th class="p-2">STATUS</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let editor of EditorList" style="border-bottom: 1px solid #DEE2E6;">
          <td class="d-flex tdata">
            <span class="initials ml-1" [style.backgroundColor]="getColor(editor.initials)">
              {{ editor.initials }} </span
            ><span class="mt-2">{{ editor.user.name }}</span>
          </td>
          <td class="tdata">
            <div style="margin-left: 3px;">{{ editor.due_date | date }}</div>
          </td>
          <td class=" tdata">
            <div style="margin-left: 2px;">{{ editor.status }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="sidenav-footer d-flex">
    <button
      type="submit"
      class="btn-save cb-btn-primary"
      [ngClass]="{ 'btn-disabled cb-btn-disabled': InviteEditorForm.invalid || !EditorSelected }"
      [disabled]="InviteEditorForm.invalid || !EditorSelected"
      *ngIf="!isLoading"
      (click)="save()"
    >
      Save
    </button>
    <button type="submit" class="btn-save cb-btn-disabled" disabled *ngIf="isLoading">
      <i class="fa fa-spinner fa-spin"></i>
    </button>
    <button type="button" class="px-2 cb-btn-secondary" (click)="closeNav()">Cancel</button>
  </div>
</div>

<!-- <div>
    <div class="modal-header cb-modal-header">
      <div>
        <h3 class="modal-title font-weight-bold text-black-1" translate>
          {{ type == 'Manage Approvers' ? 'Document_Invite_Approver.Title' : 'Document_Invite_Editor.Manage_Editor ' }}
        </h3>
        <i
          class="fa fa-times text-black-1"
          style="width: 12px;
          height: 12px;
          right: 1em;
          cursor: pointer;
          position: absolute;
          top: 1.3em;"
          (click)="activeModal.close(false)"
          ngbAutoFocus
          translate
        ></i>
      </div>
    </div>

    <div class="modal-body">
      <div class="row mt-1 mb-3" *ngIf="backend_error">
        <div class="col-12">
          <span class="text-danger backend_error" translate> * {{ backend_error }}</span>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-3">
          <label class="text-black-4 p-2" translate>Document_Invite_Editor.Title </label>
        </div>
        <div class="col-9">
          <div class="input-group">
            <input class="form-control cb-input-focus cb-input" value="{{ docTitle }}" disabled />
          </div>
        </div>
      </div>
      <form [formGroup]="InviteEditorForm" novalidate>
        <div class="row mt-2">
          <div class="col-3">
            <label class="text-black-2 p-2" translate>
              {{
                type == 'Manage Approvers'
                  ? 'Document_Invite_Approver.Select_Approver'
                  : 'Document_Invite_Editor.Select_New_Editors'
              }}
            </label>
          </div>
          <div class="col-12">
            <ng-multiselect-dropdown
              class="multi-select-dropdown"
              [placeholder]="type == 'Manage Approvers' ? 'Select Approvers' : 'Select editors'"
              [data]="UsersList"
              [(ngModel)]="selectedItems"
              [settings]="dropdownSettings"
              (onSelect)="onItemSelect($event)"
              (onDeSelect)="onItemDeSelect($event)"
              [ngModelOptions]="{ standalone: true }"
            >
            </ng-multiselect-dropdown>
            <div class="col-17 error-mssg" *ngIf="!EditorSelected">
              <label for="editor" class="text-danger1" translate>
                {{
                  type == 'Manage Approvers'
                    ? 'Document_Invite_Approver.invite_approver.*Atleast one approver is required.'
                    : 'Document_Invite_Editor.invite_editor.*Atleast one editor is required.'
                }}</label
              >
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-3">
            <label for="due_date" class="text-black-1 p-2" translate>Document_Invite_Editor.Due_Date </label>
          </div>
          <div class="col-9">
            <div class="input-group">
              <div class="input-group">
                <input
                  id="invite_editor_due_date"
                  class="form-control cb-input-focus cb-input"
                  placeholder="{{ this.date }}"
                  formControlName="due_date"
                  name="due_date"
                  ngbDatepicker
                  #d="ngbDatepicker"
                  (click)="d.toggle()"
                  required
                  (dateSelect)="onDateSelection($event)"
                  [minDate]="minDate"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-outline-secondary fa fa-calendar-alt cb-input"
                    (click)="d.toggle()"
                    type="button"
                  ></button>
                </div>
                <div class="col-12 error-mssg" *ngIf="(f.due_date.touched || submitted) && f.due_date.errors">
                  <label for="due_date" class="text-danger" *ngIf="f.due_date.errors.required" translate>
                    Document_Invite_Editor.invite_editor.*Due Date is required.</label
                  >
                  <label for="due_date" class="text-danger" *ngIf="f.due_date.errors.DateTodayOrAfter_error" translate>
                    Document_Invite_Editor.invite_editor.*Due Date must be greater than today.</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-3">
            <label for="comment" class="text-black-1 p-2" translate>Document_Invite_Editor.Comment </label>
          </div>
          <div class="col-9">
            <div class="input-group">
              <textarea
                id="comment"
                class="form-control cb-input-focus cb-input"
                [placeholder]="'Comment'"
                name="comment"
                type="text"
                formControlName="comment"
                cols="64"
                rows="5"
                [maxLength]="4097"
              ></textarea>
              <div
                class="col-12 error-mssg"
                *ngIf="
                  (InviteEditorForm.controls.comment.touched || InviteEditorForm.controls.comment.dirty) &&
                  InviteEditorForm.controls.comment.errors
                "
              >
                <label
                  for="name"
                  class="text-danger"
                  *ngIf="InviteEditorForm.controls.comment.errors.maxLength"
                  style="margin-left: -11px;"
                  translate
                  >Document_Invite_Editor.* Ensure that the comment has no more than 4096 characters.</label
                >
              </div>
            </div>
          </div>
        </div>
      </form>
      <button class="btn-save ml-4 w-100 mt-3 mb-3 float-right" *ngIf="!isLoading" (click)="save()" translate>
        Document_Invite_Editor.Send_Invite
      </button>
      <button class="btn-save ml-4 w-100 mt-3 mb-3 float-right" disabled *ngIf="isLoading">
        <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
      </button>
      <button class="btn-cancel ml-4 w-100 mt-3 float-right" (click)="activeModal.close(false)" ngbAutoFocus translate>
        Document_Invite_Editor.Cancel
      </button>
    </div>
    <div class="editor-modal-footer cb-modal-footer mt-5" *ngIf="EditorList?.length > 0">
      <table class="table table-borderless">
        <thead>
          <tr>
            <th scope="col-6">
              <h4 class="modal-title font-weight-bold text-black-1" translate>Document_Invite_Editor.Editors</h4>
            </th>
            <th scope="col-2">
              <h4 class="modal-title font-weight-bold text-black-1" translate>Document_Invite_Editor.Due_Date</h4>
            </th>
            <th scope="col-2">
              <h4 class="modal-title font-weight-bold text-black-1" translate>Document_Invite_Editor.Status</h4>
            </th>
            <th scope="col-2" class="text-black-1"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let editor of EditorList">
            <td>
              <h4 class="text-black-1 text-left" scope="row">
                <span class="i-circle text-black-1 mr-2 font-weight-bold">{{ editor.initials }}</span
                >{{ editor.user.name }}
              </h4>
            </td>
            <td>
              <h4 class="text-black-1">{{ editor.due_date | date }}</h4>
            </td>
            <td>
              <h4 class="text-black-1 capitalize">{{ editor.status }}</h4>
            </td>
            <td class="p-2">
              <mat-icon
                class="cursor-pointer"
                *ngIf="editor.status.toLowerCase() == 'yet to start'; else Editing"
                (click)="
                  openChangeDueFrom(
                    DocumentGuid,
                    editor.guid,
                    editor.DocCreatedBy,
                    editor.user.name,
                    editor.start_message,
                    editor.due_date
                  )
                "
                >edit</mat-icon
              >
              <mat-icon
                class="cursor-pointer"
                *ngIf="editor.status.toLowerCase() == 'yet to start'; else completed"
                (click)="openRevokeEditorForm(DocumentGuid, editor.guid, editor.user.name)"
                >delete_outline</mat-icon
              >

              <ng-template #completed>
                <a
                  class="cursor-pointer"
                  *ngIf="editor.status.toLowerCase() == 'completed' || editor.status.toLowerCase() == 'approved'"
                  (click)="
                    editor.status.toLowerCase() == 'completed'
                      ? openRestoreEditorForm(
                          DocumentGuid,
                          editor.user.org_user_guid,
                          editor.user.name,
                          docTitle,
                          editor.due_date
                        )
                      : openRestoreApproverForm(
                          DocumentGuid,
                          editor.user.org_user_guid,
                          editor.user.name,
                          docTitle,
                          editor.due_date
                        )
                  "
                  ><mat-icon>refresh</mat-icon></a
                >
              </ng-template>
              <ng-template #Editing>
                <a
                  class="cursor-pointer"
                  *ngIf="editor.status.toLowerCase() == 'editing'"
                  (click)="
                    openChangeDueFrom(
                      DocumentGuid,
                      editor.guid,
                      editor.DocCreatedBy,
                      editor.user.name,
                      editor.comment,
                      editor.due_date
                    )
                  "
                  ><mat-icon>edit</mat-icon></a
                >
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
    </div>
  </div> -->
