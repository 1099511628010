import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OrganisationService } from '@app/core/organisation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { finalize } from 'rxjs/operators';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';

@Component({
  selector: 'app-rename-document-title',
  templateUrl: './rename-document-title.component.html',
  styleUrls: ['./rename-document-title.component.scss']
})
export class RenameDocumentTitleComponent implements OnInit {
  loading = true;
  isLoading: boolean = false;
  RenameDocumentForm: FormGroup;
  OrganisationID: string | null;
  guidSubscription: any;
  submitted: boolean = false;
  form_error: any;
  TitleData: any;

  @Input() formType: string;
  @Input() DocumentGuid: string;
  @Input() title_tag: string;
  @Input() docTitle: string;
  @Input() DocumentVersion: string;
  backend_error: any;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private documentsService: DocumentsService
  ) {}

  ngOnInit(): void {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
    });
    this.loading = false;
    this.getRenameDocumentForm();
  }
  getRenameDocumentForm() {
    this.RenameDocumentForm = this.formBuilder.group({
      title: ['', [Validators.required(), Validators.maxLength(255)]]
    });
  }

  changeName(TitleData: any) {
    this.documentsService.setTitle(TitleData);
  }

  onChange(evt: any) {
    if (evt.type == 'change' && this.backend_error) {
      this.backend_error = '';
    }
  }

  save() {
    this.submitted = true;
    if (this.RenameDocumentForm.valid) {
      this.isLoading = true;
      const documentData = this.RenameDocumentForm.value;
      documentData['document_version_guid'] = this.DocumentVersion;
      this.documentsService
        .submitRenameDocumentTitleData(this.OrganisationID, this.DocumentGuid, documentData)
        .pipe(
          finalize(() => {
            this.RenameDocumentForm.markAsPristine();
            this.isLoading = false;
          })
        )
        .subscribe(
          (data: any) => {
            this.TitleData = data;
            this.changeName(this.TitleData);
            this.submitted = false;
            this.activeModal.close(true);
            this.backend_error = '';
          },
          (error: any) => {
            this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
            this.backendErrorService.backendError$.subscribe(error => {
              this.backend_error = error;
            });
          }
        );
    } else {
      return;
    }
  }
}
