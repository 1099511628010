<!--<div style="display: none">-->
<!--<h3>Review schedule</h3>-->
<!--<p class="description_tag">-->
<!--Please review the schedule of meetings and other compliance events. This is a consolidated schedule based on the-->
<!--compliance programs you have selected.-->
<!--</p>-->
<!--<div class="row" *ngIf="data.length > 0">-->
<!--<table mat-table [dataSource]="data" class="mat-elevation-z8" style="width: 80%; margin-top: 50px; margin: 0 auto;">-->
<!--<ng-container matColumnDef="committee_name">-->
<!--<th mat-header-cell *matHeaderCellDef [ngClass]="'committee-name-width'">Committee Name</th>-->
<!--<td mat-cell *matCellDef="let element" [ngClass]="'committee-name-width'">{{ element.committee_name }}</td>-->
<!--</ng-container>-->

<!--<ng-container matColumnDef="meeting_name">-->
<!--<th mat-header-cell *matHeaderCellDef [ngClass]="'meeting-name-width'">Meeting Name</th>-->
<!--<td mat-cell *matCellDef="let element" [ngClass]="'meeting-name-width'">{{ element.meeting_name }}</td>-->
<!--</ng-container>-->

<!--<ng-container matColumnDef="schedule">-->
<!--<th mat-header-cell *matHeaderCellDef [ngClass]="'schedule-width'">Schedule</th>-->
<!--<td mat-cell *matCellDef="let element" [ngClass]="'schedule-width'">{{ element.schedule }}</td>-->
<!--</ng-container>-->

<!--<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>-->
<!--<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>-->
<!--</table>-->
<!--</div>-->

<!--&lt;!&ndash;<div class="row" style="margin-top: 10%;">&ndash;&gt;-->
<!--&lt;!&ndash;<div class="offset-5 col-md-4">&ndash;&gt;-->
<!--&lt;!&ndash;<button mat-button matStepperPrevious style="display: inline-block;">prev</button>&ndash;&gt;-->
<!--&lt;!&ndash;<button mat-button (click)="submitForm()" style="display: inline-block;">next</button>&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--</div>-->

<div class="onboarding-main pt-4" *ngIf="show">
  <div class="container max-w-840">
    <div class="row">
      <div class="col">
        <h1 class="text-black-2 mb-3 font-weight-bold" translate>onboard.review_schedule.Review Schedule</h1>
        <p class="text-black-2 mb-5" translate>
          onboard.review_schedule.review_schedule_details
        </p>
        <div class="w-640 m-auto">
          <ng-container *ngFor="let schedule of unique_schedules; let i = index">
            <div class="cb-accordian-yellow mb-4" [ngClass]="{ expand: schedule['expand'] }">
              <div class="acc-header d-flex justify-content-between">
                <h5 class="text-black-1">{{ schedule['display_value'] }}</h5>
                <div class="d-flex align-items-center">
                  <button class="btn-acc-toggle ml-4 mr-3" (click)="accordian_toggle(i)">
                    <i class="fas fa-chevron-up text-black-2" *ngIf="schedule['expand']"></i>
                    <i class="fas fa-chevron-down text-black-2" *ngIf="!schedule['expand']"></i>
                  </button>
                </div>
              </div>
              <div class="acc-body">
                <table class="w-100p table-2">
                  <thead>
                    <tr>
                      <td>
                        <h4 class="font-weight-bold text-black-2 p-3" translate>
                          onboard.review_schedule.Committee Name
                        </h4>
                      </td>
                      <td>
                        <h4 class="font-weight-bold text-black-2 p-3" translate>
                          onboard.review_schedule.Meeting Name
                        </h4>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let event of data">
                      <tr *ngIf="event['schedule'] == schedule['value']">
                        <td>
                          <h4 class="text-black-1 p-3 pr-3 w-240">{{ event['committee_name'] }}</h4>
                        </td>
                        <td>
                          <h4 class="text-black-1 p-3">{{ event['meeting_name'] }}</h4>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
