<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div *ngIf="documents?.length > 0; else no_content">
    <div class="modal-header cb-modal-header">
      <div>
        <h4 class="modal-title font-weight-bold text-black-1" translate>{{ title_tag }}</h4>
        <i
          class="fa fa-times text-black-1"
          style="width: 12px;
              height: 12px;
              right: 1em;
              cursor: pointer;
              position: absolute;
              top: 1.3em;"
          (click)="activeModal.close(false)"
          ngbAutoFocus
          translate
        ></i>
      </div>
    </div>
    <div class="modal-body">
      <table class="table table-borderless">
        <thead>
          <tr>
            <th scope="col-6">
              <h4 class="modal-title font-weight-bold text-black-1" translate>view_documents.first_column</h4>
            </th>
            <th scope="col-2">
              <h4 class="modal-title font-weight-bold text-black-1" translate>{{ column.description }}</h4>
            </th>
            <th scope="col-2">
              <h4 class="modal-title font-weight-bold text-black-1" translate>view_documents.third_column</h4>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let document of documents">
            <td>
              <h4 class="text-black-1" translate>
                {{ document.type == 'cb_document' ? document.document.title : document.external_link.title }}
              </h4>
            </td>
            <td *ngIf="column.date !== 'due_date'; else elseBlock">
              <h4 class="text-black-1" translate>{{ document[column.date] | date }}</h4>
            </td>
            <td *ngIf="document.document?.doc_status !== 'Archived'; else archivedTemplate">
              <h4 class="text-black-1 capitalize" translate>{{ document.status_description }}</h4>
            </td>
            <ng-template #archivedTemplate>
              <td>
                <h4 class="text-black-1 capitalize">{{ document?.document?.doc_status }}</h4>
              </td>
            </ng-template>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="modal-footer border-0 cb-modal-footer">
      <button class="btn-cancel ml-4 w-100" (click)="activeModal.close(false)" ngbAutoFocus translate>
        view_documents.close
      </button>
    </div>
  </div>
</ng-container>

<ng-template #no_content>
  <div class="modal-body">
    <h2 class="modal-title font-weight-bold text-black-1 p-5 text-center" translate>
      view_documents.not_found
    </h2>
  </div>
  <div class="modal-footer border-0 cb-modal-footer">
    <button class="btn-cancel ml-4 w-100" (click)="activeModal.close(false)" ngbAutoFocus translate>
      view_documents.close
    </button>
  </div>
</ng-template>

<ng-template #elseBlock
  ><td>
    <h4 class="text-black-1" translate>{{ due_date | date }}</h4>
  </td></ng-template
>
