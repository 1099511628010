import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { WizardService } from '@app/home/wizard.services';
import { UserService } from '@app/core/user.service';
import { OrganisationService } from '@app/core/organisation.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-connect-slack-step',
  templateUrl: './connectSlack.component.html',
  styleUrls: ['./connectSlack.component.scss']
})
export class ConnectSlackComponent implements OnInit, OnDestroy {
  @Input() show = false;
  stepForm: FormGroup;
  organisationID: any;
  organizationData: any;
  isLoading = true;
  guidSubscription: any;
  orgGuidSubscription: any;
  boxOrganization: object = null;
  boxOrganizationType: any;
  selectedOption: any;

  constructor(
    private fb: FormBuilder,
    private wizard: WizardService,
    private _user: UserService,
    private orgService: OrganisationService
  ) {}

  ngOnInit() {
    this.getOrganizationDetails();
    this.getBoxOrganizationDetails();
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  install_slack_app() {
    let url = 'https://slack.com/oauth/v2/authorize?client_id=' + environment.slackClientID;
    url = url + '&scope=' + environment.slackAppScope;
    let redirect_uri = '';
    if (environment.enableSlackRedirect) {
      redirect_uri = window.location.protocol + '//' + window.location.hostname + '/api/users/slack-auth/';
      url = url + '&redirect_uri=' + redirect_uri;
    }
    window.open(url, '_blank');
  }

  getOrganizationDetails() {
    this.guidSubscription = this.orgService.organisation_guid.subscribe((guid: any) => {
      this.organisationID = guid;
      this.wizard.getOrganizationDetails(this.organisationID).subscribe(organization => {
        this.orgService.organisation_data.next(organization);
        this.organizationData = organization;
        this.isLoading = false;
        this.stepForm = this.fb.group({
          SLACK_API_KEY: [organization.SLACK_API_KEY ? organization.SLACK_API_KEY : '', Validators.required]
        });
      });
    });
  }
  getBoxOrganizationDetails() {
    this.orgGuidSubscription = this.orgService.__organisation_guid.subscribe((guid: any) => {
      this.organisationID = guid;
    });
    this.wizard.organizationBoxDetailsUpdate(this.organisationID).subscribe((data: any) => {
      this.orgService.organisation_data.next(data);
      this.boxOrganization = data;
      this.boxOrganizationType = this.boxOrganization['store_secret'];
      this.selectedOption = this.boxOrganization['document_store_title'];
    });
  }

  canGoToNextStep() {
    return true;
  }

  submitForm() {
    this.stepForm.markAsDirty();
    if (this.stepForm.valid) {
      this.orgService.organisation_guid.subscribe((data: any) => {
        this.organisationID = data;
        this.wizard.organizationUpdate(this.organisationID, this.stepForm.value).subscribe(data2 => {
          console.log(data2);
        });
      });
    }
  }
}
