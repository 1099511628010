import { MatExpansionPanel } from '@angular/material/expansion';
import { SidenavService } from './../../../core/modal/sidenav.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-mapped-controls',
  templateUrl: './mapped-controls.component.html',
  styleUrls: ['./mapped-controls.component.scss']
})
export class MappedControlsComponent implements OnInit {
  @Input() controls: any = [];
  @Input() control: any = '';
  @Input() code: string = '';
  constructor(private sidenavService: SidenavService) {
    console.log(this.controls, this.control, this.code);
  }
  panelOpenState = false;
  isExpanded = false;

  expandedIndex: number = -1; // -1 means no item is expanded

  toggleAccordion(index: number) {
    if (this.expandedIndex === index) {
      // If clicking on the currently expanded item, close it
      this.expandedIndex = -1;
    } else {
      // Otherwise, expand the clicked item
      this.expandedIndex = index;
    }
  }
  ngOnInit(): void {}
  closeNav() {
    this.sidenavService.close(true);
  }
  getFrameworkClass(framework: string): string {
    switch (framework.toLowerCase()) {
      case 'hipaa':
        return 'hipaa';
      case 'soc2':
        return 'soc2';
      case 'iso27001':
        return 'iso27001';
      case 'gdpr':
        return 'gdpr';
      default:
        return '';
    }
  }

  toggleExpansionPanel(expansionPanel: MatExpansionPanel): void {
    expansionPanel.toggle();
  }
}
