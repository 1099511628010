import { Component, OnDestroy, OnInit, Injectable } from '@angular/core';
import { OrganisationService } from '@app/core/organisation.service';
import { LeadService } from '../Lead-generation/lead.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
@Component({
  selector: 'app-grc-data',
  templateUrl: './grc-data.component.html',
  styleUrls: ['./grc-data.component.scss']
})
export class GrcDataComponent implements OnInit {
  OrganisationID: string | null;
  userType: any;
  error: any;
  guidSubscription: any;
  grcList: any[];
  accArray: any[] = [];
  grcEnabled: any;

  constructor(
    private orgService: OrganisationService,
    private leadService: LeadService,
    private translate: TranslateService,
    private toaster: ToasterService
  ) {}

  ngOnInit(): void {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
    });
    this.getOrganisationList();
  }

  upDateGrcMasterDataForOrg(orgGuid: any): void {
    this.leadService.updateOrganisationGrcData(orgGuid).subscribe(
      (data: any) => {
        this.translate.get('GRC data updated successfully').subscribe(res => {
          this.toaster.showSuccess(res);
        });
      },
      (error: any) => {
        this.error = error.error.message;
      }
    );
  }

  toggleGrcFlagForOrg(orgGuid: any, grcFlag: boolean) {
    this.leadService.upDateGrcToggle(orgGuid.guid, grcFlag).subscribe(
      (data: any) => {
        this.translate.get('GRC flag updated').subscribe(res => {
          this.toaster.showSuccess(res);
          this.getGrcEnableFlag(orgGuid.guid);
        });
      },
      (error: any) => {
        this.error = error.error.message;
      }
    );
  }

  toggleDetails(index: any, grcData: any): void {
    this.getGrcEnableFlag(grcData.guid);
    if (this.accArray.includes(index)) {
      const pos = this.accArray.indexOf(index);
      if (index > -1) {
        this.accArray.splice(pos, 1);
      }
    } else {
      this.accArray.push(index);
    }
  }

  private getOrganisationList(): void {
    this.leadService.getOrganisationGrcDataList().subscribe(
      (data: any) => {
        this.grcList = data.length === 0 ? null : data;
      },
      (error: any) => {
        this.error = error.error.message;
      }
    );
  }
  private getGrcEnableFlag(orgGuid: any): void {
    this.leadService.getOrganisationGrcEnabled(orgGuid).subscribe(
      (data: any) => {
        this.grcEnabled = data.length === 0 ? null : data;
      },
      (error: any) => {
        this.error = error.error.message;
      }
    );
  }
}
