<app-spinner *ngIf="loading"></app-spinner>
<div id="mySidenav" class="sidenav">
  <div class="sidenav-header">
    <span class="title"> {{ title_tag }}</span>
    <i class="fa fa-times text-black-1 closebtn" (click)="closeNav()" ngbAutoFocus translate></i>
  </div>
  <!-- <div class="row mt-2" *ngIf="backend_error">
    <div class="col-12 col-sm-12 col-lg-12 ml-5">
      <div class="text-danger" translate>* {{ backend_error }}</div>
    </div>
  </div> -->
  <form [formGroup]="InviteEditorForm" class="sidenav-body">
    <div class="d-flex flex-column">
      <label for="document-title" class="label">
        Document Title
      </label>
      <label for="folderName" class="subfolder-name">{{ docTitle }}</label>
    </div>
    <div class="d-flex flex-column">
      <label for="document-title" class="label">
        Current Version
      </label>
      <label for="folderName" class="subfolder-name">{{ DocumentVersion }}</label>
    </div>
    <div class="form-group">
      <!-- <div class="input-group">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Version</mat-label>
          <mat-select
            (selectionChange)="radioChangeHandler($event.value)"
            formControlName="select_version"
            name="select_version"
            required
          >
            <mat-option
              *ngFor="let option of VersionList"
              [value]="option.id"
              [selected]="selected_option === option.name"
            >
              {{ option.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-select
             placeholder="Select due date"
              id="select_version"
              class="form-control cb-input-focus"
              formControlName="select_version"
              name="select_version"
            >
              <mat-option *ngFor="let folder of VersionList" [value]="folder.id">{{ folder.name }}</mat-option>
            </mat-select>
      </div> -->
      <select
        class="form-control cb-input-focus cb-input"
        (change)="radioChangeHandler($event.target.value)"
        formControlName="select_version"
        name="select_version"
        required
      >
        <option value="" selected>Version</option
        ><option
          *ngFor="let options of VersionList"
          [value]="options.id"
          [selected]="(selected_option | titlecase) == (options.name | titlecase)"
          >{{ options.name
          }}<span *ngIf="getVersionString() && (selected_option | titlecase) == (options.name | titlecase)">
            ({{ getVersionString() }})
          </span>
        </option>
      </select>
    </div>
    <div class="form-group">
      <label for="Select Editors" class="label"> Select Editors</label>
      <ng-select
        [clearSearchOnAdd]="true"
        [closeOnSelect]="false"
        [multiple]="true"
        placeholder="Select Editors"
        clearAllText="Clear"
        [items]="UsersList"
        bindLabel="FullName"
        class="select-editors"
      >
        <ng-template ng-option-tmp let-item="item">
          <div
            class="option-item d-flex"
            (click)="$event.stopPropagation()"
            [ngStyle]="{
              backgroundColor: item.selected ? '#E5F7F9' : ''
            }"
          >
            <div class="d-flex selected-option ">
              <input
                class="custom-checkbox"
                id="item-{{ item.guid }}"
                type="checkbox"
                [checked]="item.selected"
                (change)="onItemSelect(item)"
              />
              <div class="d-flex cursor-default" (click)="$event.stopPropagation()">
                <span class="initials" [style.backgroundColor]="getColor(item.first_name)">
                  {{ getInitials(item.first_name, item.last_name) }}
                </span>
                <div (click)="$event.stopPropagation()">
                  <div class="username" (click)="$event.stopPropagation()">
                    {{ item.first_name }} {{ item.last_name }}
                  </div>
                  <div class="email" (click)="$event.stopPropagation()">({{ item.email }})</div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-select>
      <div class="selected-items" *ngIf="selectedItems.length > 0" (mouseleave)="showTooltip = false">
        <ng-container *ngFor="let item of selectedItems.slice(0, 2)">
          <span class="badge badge-primary mr-2">
            {{ item.first_name }} {{ item.last_name }} <br />
            ({{ item.email }})
            <span class="badge-remove" (click)="removeItem(item)">x</span>
          </span>
        </ng-container>
        <ng-container *ngIf="selectedItems.length > 2">
          <span (mouseenter)="showTooltip = true">
            <span class="count">+{{ selectedItems.length - 2 }}</span>
            <div
              class="tooltip"
              *ngIf="showTooltip"
              (mouseenter)="showTooltip = true"
              (mouseleave)="showTooltip = false"
            >
              <ng-container *ngFor="let item of selectedItems.slice(2)">
                <span class="badge badge-primary mr-2">
                  {{ item.first_name }} {{ item.last_name }} <br />
                  ({{ item.email }})
                  <span class="badge-remove" (click)="removeItem(item)">x</span>
                </span>
              </ng-container>
            </div>
          </span>
        </ng-container>
      </div>
      <div class="col-12 error-mssg" *ngIf="!EditorSelected">
        <label for="editor" class="text-danger" translate>
          Create_new_version.new_version.*Atleast one editor is required.</label
        >
      </div>
    </div>
    <div class="form-group">
      <label for="duedates" class="label">Due Date</label>
      <div class="position-relative">
        <input
          class="form-control cb-input-focus pr-5"
          id="due_date"
          placeholder="Select due date"
          formControlName="due_date"
          name="due_date"
          ngbDatepicker
          (click)="d.toggle()"
          [minDate]="minDate"
          required
          #d="ngbDatepicker"
        />
        <img src="../../../assets/images/core/calender.svg" class="calendar-icon" (click)="d.toggle()" />
      </div>
      <div class="col-12 error-mssg" *ngIf="(f.due_date.touched || submitted) && f.due_date.errors">
        <label for="due_date" class="text-danger" *ngIf="f.due_date.errors.required" translate>
          Document_Invite_Editor.invite_editor.*Due Date is required.
        </label>
        <label for="due_date" class="text-danger" *ngIf="f.due_date.errors.DateTodayOrAfter_error" translate>
          Document_Invite_Editor.invite_editor.*Due Date must be greater than today.
        </label>
      </div>
    </div>
    <div class="form-group">
      <label for="comment" class="label">Comment</label>
      <textarea
        id="folderDescription"
        formControlName="comment"
        class="form-control cb-input-focus"
        placeholder="Write a comment"
        [maxLength]="4097"
      ></textarea>
      <div *ngIf="(f.comment.touched || f.comment.dirty) && f.comment.errors" class="text-danger">
        <label *ngIf="f.comment.errors.maxLength">*Description must be less than 4096 characters.</label>
      </div>
    </div>
  </form>

  <div class="sidenav-footer d-flex">
    <button
      type="submit"
      class="btn-save"
      [ngClass]="{ 'btn-disabled': InviteEditorForm.invalid }"
      [disabled]="InviteEditorForm.invalid"
      *ngIf="!isLoading"
      (click)="save()"
    >
      Save
    </button>
    <button type="submit" class="btn-save" disabled *ngIf="isLoading">
      <i class="fa fa-spinner fa-spin"></i>
    </button>
    <button type="button" class="px-3 cb-btn-secondary" (click)="closeNav()">Cancel</button>
  </div>
</div>
