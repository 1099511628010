<div class="cards-container">
  <ng-container *ngFor="let provider of cloudProviders">
    <div class="card-container">
      <div class="inner-card-container">
        <div>
          <!-- <img class="logo" [src]="provider?.logo" /> -->
          <img class="logo" [src]="getProviderLogo(provider?.description?.short_name)" />
        </div>
        <div class="card-details-container">
          <div class="cloud-label">{{ provider?.description?.short_name }}</div>
          <div class="categories-container">
            <ul class="categories">
              <li *ngFor="let category of provider?.description?.categories">{{ category }}</li>
            </ul>
          </div>
          <div class="active-inactive mt-1">
            <ng-container *ngIf="provider?.active_accounts">
              <span class="active-accounts mr-2"
                ><span class="active-accounts-label">Active Accounts : {{ provider?.active_accounts }}</span></span
              >
              <span class="inactive-accounts"
                ><span class="inactive-accounts-label"
                  >Inactive Accounts : {{ provider?.inactive_accounts }}</span
                ></span
              >
            </ng-container>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-center" style="height: 62px;">
        <button *ngIf="isIntegrated" (click)="manageProvider(provider)" class="button mr-4">
          Manage
        </button>
        <button *ngIf="!isIntegrated" (click)="integrateProvider(provider)" class="mat-save-btn mr-4">
          Integrate
        </button>
      </div>
    </div>
  </ng-container>
</div>
