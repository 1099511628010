<app-spinner *ngIf="submitted && isLoading"></app-spinner>
<div class="row">
  <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
    <h2 class="text-cyan m-0" translate>Document_Training_Groups.Page_Title</h2>
    <div>
      <button class="btn-cancel ml-4 w-100" [ngClass]="{ 'btn-cancel': true }" (click)="onReset()" translate>
        Document_Training_Groups.Btn_Clr
      </button>
      <button class="btn-save ml-4 w-100" [ngClass]="{ 'btn-save': true }" (click)="submit()" translate>
        Document_Training_Groups.Btn_Save
      </button>
    </div>
  </div>
</div>
<div class="row scroll-view">
  <div class="col">
    <ng-container>
      <div *ngIf="form_error">
        <div class="row mt-2" *ngIf="form_error.title">
          <div class="col-12 col-sm-12 col-lg-12 ml-5">
            <div class="text-danger" translate>* {{ form_error.title[0] }}</div>
          </div>
        </div>
        <div class="row mt-2" *ngIf="form_error.non_field_errors">
          <div class="col-12 col-sm-12 col-lg-12 ml-5">
            <div class="text-danger" translate>* {{ form_error.non_field_errors[0] }}</div>
          </div>
        </div>
      </div>

      <form [formGroup]="InviteTrainingGroupsForm" novalidate>
        <div class="row mt-4">
          <div class="col-2 col-sm-2 col-lg-2">
            <label for="start_date" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate
              >Document_Training_Groups.Name
            </label>
          </div>
          <div class="input-group col-9 col-sm-9 col-lg-9">
            <input
              id="name"
              [placeholder]="'Document_Training_Groups.Name' | translate"
              class="form-control cb-input"
              formControlName="name"
              autocomplete="name"
              required
              name="name"
              type="text"
            />
            <div class="col-12 error-mssg" *ngIf="(f.name.touched || submitted) && f.name.errors">
              <label for="name" class="text-danger" *ngIf="f.name.errors.required" translate
                >organisation_committee.add_organisation_committee.NameRequired</label
              >
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-2 col-sm-2 col-lg-2">
            <label for="groups" class="ml-3 pt-3 mt-2 cb-label text-black-3" translate
              >Document_Training_Groups.Users
            </label>
          </div>
          <div class="col-6">
            <ng-multiselect-dropdown
              class="multi-select-dropdown"
              [placeholder]="'Select users'"
              [data]="UsersList"
              [(ngModel)]="selectedItems"
              [settings]="dropdownSettings"
              (onSelect)="onItemSelect($event)"
              (onDeSelect)="onItemDeSelect($event)"
              [ngModelOptions]="{ standalone: true }"
            >
            </ng-multiselect-dropdown>

            <div class="col-12 error-mssg" *ngIf="!DocumentSelected">
              <label class="text-danger" translate>Document_Training_Groups.*At least one user is required.</label>
            </div>
            <div *ngIf="error">
              <div class="col-12 error-mssg" *ngIf="error.documents">
                <label class="text-danger" translate>* {{ error.documents[0] }}</label>
              </div>
            </div>
            <div *ngIf="error">
              <div class="col-12 error-mssg" *ngIf="error[0]">
                <label class="text-danger" translate>* {{ error[0] }}</label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
</div>
