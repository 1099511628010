<div>
  <div class="modal-header cb-modal-header">
    <h4 class="modal-title font-weight-bold text-black-1" translate>{{ title_tag }}</h4>
  </div>
  <div class="modal-body">
    <div class="row mt-1 mb-3" *ngIf="backend_error">
      <div class="col-12">
        <span class="text-danger backend_error" translate> * {{ backend_error }}</span>
      </div>
    </div>

    <form [formGroup]="groupForm">
      <div class="row mt-3">
        <div class="col-4">
          <label for="committee-name" class="text-black-1" translate>Name</label>
        </div>
        <div class="input-group col-8">
          <input
            id="committee-name"
            class="form-control cb-input"
            [placeholder]="'Enter the group name'"
            name="committeeName"
            type="text"
            formControlName="title"
            (keyup)="onChangeTitle($event)"
            [maxLength]="256"
          />
          <div class="col-12 error-mssg" *ngIf="(f.title.touched || submitted) && f.title.errors">
            <label for="title" class="text-danger" *ngIf="f.title.errors.required" translate
              >organisation_committee.add_organisation_committee.NameRequired</label
            >
          </div>
          <div class="col-12 error-mssg" *ngIf="(f.title.touched || f.title.dirty) && f.title.errors">
            <label for="title" class="text-danger" *ngIf="f.title.errors.maxLength" translate
              >organisation_committee.*Title must be 255 characters or less
            </label>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <label for="charter" class="text-black-1" ngbAutoFocus translate>Description</label>
        </div>
        <div class="input-group col-8">
          <textarea
            style="height: 100px;"
            class="form-control cb-input"
            [placeholder]="'Description'"
            formControlName="description"
            [maxLength]="4097"
          >
          </textarea>
          <div class="col-12 error-mssg" *ngIf="(f.description.touched || submitted) && f.description.errors">
            <label for="description" class="text-danger" *ngIf="f.description.errors.required" translate
              >organisation_committee.add_organisation_committee.*Description is required.</label
            >
          </div>
          <div class="col-12 error-mssg" *ngIf="(f.description.touched || f.description.dirty) && f.description.errors">
            <label for="description" class="text-danger" *ngIf="f.description.errors.maxLength" translate
              >organisation_committee.*Description must be 4096 characters or less
            </label>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer border-0 cb-modal-footer">
    <button class="btn-cancel ml-4 w-100" ngbAutoFocus (click)="activeModal.close(false)" translate>
      organisation_committee.add_organisation_committee.Cancel
    </button>
    <button
      class="btn-save ml-4 w-100"
      *ngIf="!isLoading"
      ngbAutoFocus
      (click)="save()"
      translate
      [disabled]="groupForm.invalid"
      [ngStyle]="{
        'background-color': groupForm.invalid ? 'rgba(0,0,0,.26)' : '#4db7c6'
      }"
    >
      organisation_committee.add_organisation_committee.Submit
    </button>
    <button class="btn-save ml-4 w-100" disabled *ngIf="isLoading">
      <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
    </button>
  </div>
</div>
