<div class="dialog-container">
  <div mat-dialog-title class="dialog-title">
    <h2>Restart Onboarding</h2>
    <mat-icon class="cross-icon" mat-icon-button aria-label="close dialog" mat-dialog-close>close</mat-icon>
  </div>
  <mat-dialog-content>
    <p class="note-text">
      Are you sure you want to restart onboarding? By restarting, all existing configurations, policies, procedures, and
      evidence related to all your registered frameworks will be reset.
    </p>

    <p class="note-text">
      Note : This action cannot be undone. Ensure that you have backed up any necessary data before proceeding
    </p>
    <mat-checkbox [(ngModel)]="confirm" color="primary" class="note-text"
      >I understand and confirm that I want to restart onboarding.</mat-checkbox
    >
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button class="cb-btn-secondary btn-cancel" (click)="onCancel()">
      Cancel
    </button>
    <button [disabled]="!confirm" mat-flat-button color="warn" (click)="onRestart()" class="restart-btn">
      Yes, Restart
    </button>
  </mat-dialog-actions>
</div>
