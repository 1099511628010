import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@env/environment';
import { Logger, I18nService, AuthenticationService } from '@app/core';
import { UserService } from '@app/core/user.service';
import { OrganisationService } from '@app/core/organisation.service';
import { PasswordResetService } from '@app/password-reset/password-reset.service';
import { OrganisationDashboardService } from '@app/Organisation-admin/orgDashboard/orgDashboard.service';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { ProfileConfigAuthGuard } from '@app/home/steps/profile-config-auth.guard';
import { Subscription } from 'rxjs';

const log = new Logger('Login');
const credentialsKey = 'credentials';
const googleLogoURL = 'https://raw.githubusercontent.com/fireflysemantics/logo/master/Google.svg';
interface Progress {
  program_configured_completed: boolean;
  select_applicable_ctrl_completed: boolean;
  org_assessment_completed: boolean;
  generate_pnp_doc_completed: boolean;
  is_organization_onboarded?: boolean;
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  key: any;
  check: any;
  loginError: any = false;
  snapshotParam = 'initial value';
  subscribedParam = 'initial value';
  version: string = environment.version;
  error: string;
  page_error: string | null = null;
  success: string;
  loginForm: FormGroup;
  forgotPasswordForm: FormGroup;
  isLoading = false;
  submitted = false;
  organisationID: string;
  showForgetPasswordForm = false;
  isPasswordResetEmailSent = false;
  page_state: string | null = null;
  userLoggedInorNot: boolean;
  all_cookiesFinal: any;
  conversionOutput: any;
  textToConvert: any;
  all_cookiesFinal1: any;
  all_cookiesFinal2: any;
  all_cookiesFinal3: any;
  credentials: any;
  isTokenAvailable: boolean = false;
  private all_cookies: any = '';
  roles: any;
  length: any;
  data: any = [];
  documentRole: any = [];
  incidentRole: any = [];
  allRoles: any = [];
  role: any = [];
  password_based_login: any;
  userNotExists: any;
  loading = false;
  reset_password_error: any;
  hide = true;
  loginStep: number = 1;
  token: string = 'token';
  tokenErrorString = '';
  progressData: Progress;
  isverifyLinkSubscription: Subscription;
  signUpWithGoogleError = '';
  routeMapper = {
    program_configured_completed: '/program-config',
    org_assessment_completed: '/organization-assessment',
    select_applicable_ctrl_completed: '/select-citations',
    generate_pnp_doc_completed: '/generate-documents',
    is_organization_onboarded: '/organisation/dashboard'
  };

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private i18nService: I18nService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private orgService: OrganisationService,
    private orgDashboardService: OrganisationDashboardService,
    private passwordResetService: PasswordResetService,
    private toasterService: ToasterService,
    private profileConfigAuthGuard: ProfileConfigAuthGuard
  ) {
    this.isverifyLinkSubscription = this.orgService.isverifyLink.subscribe(res => {
      this.isTokenAvailable = res;
    });
    this.route.queryParams.subscribe(params => {
      if (params['error']) {
        this.signUpWithGoogleError = params['error'];
      }
      this.page_error = params['error'];
      this.page_state = params['state'];
      this.token = params['token'] || '';
      if (this.token.length) {
        this.orgService.isverifyLink.next(true);
        this.verifyLink(this.token);
      }
    });
    this.createForm();
    if (this.page_error === 'session_timeout') {
      this.authenticationService.logoutWithoutRedirect();
    } else if (this.page_state === 'password-updated') {
      this.authenticationService.logoutWithoutRedirect();
    }
  }

  openGoogleLoginPage() {
    if (this.signUpWithGoogleError) {
      this.removeOptionalParamFromUrl(window.location.href);
      this.signUpWithGoogleError = '';
    }
    const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    let redirectUri = window.location.origin + '/api/auth/login/google/';
    if (window.location.origin.includes('127.0.0.1') || window.location.origin.includes('localhost')) {
      redirectUri = 'http://127.0.0.1:8000/api/auth/login/google/';
    }

    const scope = [
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/userinfo.profile'
    ].join(' ');

    const params = {
      response_type: 'code',
      client_id: environment.google_sso_client_id,
      redirect_uri: `${redirectUri}`,
      prompt: 'select_account',
      access_type: 'offline',
      scope
    };
    console.log(`${redirectUri}`);
    const urlParams = new URLSearchParams(params).toString();
    window.location.href = `${googleAuthUrl}?${urlParams}`;
  }

  ngOnInit() {
    this.check = JSON.parse(localStorage.getItem(credentialsKey));
    const cookies = document.cookie;
    const cookiesArray = cookies.split('; ');
    const cookiesObject = {};
    cookiesArray.forEach(cookie => {
      const [name, value] = cookie.split('=');
      cookiesObject[name] = value;
    });
    if (cookiesObject['credentials']) {
      const decodedString = decodeURIComponent(cookiesObject['credentials']);
      const jsonObject = JSON.parse(decodedString);
      this.orgService.setIsOrganisationOnboarded(jsonObject.user.is_organization_onboarded);
      localStorage.setItem('normal_org_onboarded', jsonObject.user.is_organization_onboarded);
    }

    if (this.check) {
      this.key = this.check['key'];
      if (this.key != null || 'undefined') {
        this.router.navigateByUrl('/organisation/documents');
        return;
      }
    }
    // this.cookie_name = this.cookieService.get('key');
    this.all_cookies = this.cookieService.getAll();
    this.all_cookiesFinal1 = this.all_cookies['credentials'];
    this.snapshotParam = this.route.snapshot.queryParamMap.get('error');
    // this.snapshotParam = this.route.snapshot.paramMap.get("invalid_credentials")
    console.log('this.snapshotParam', this.snapshotParam);
    if (this.snapshotParam == 'invalid_credentials') {
      this.loginError = true;
    }
    const credentials_json = JSON.parse(this.all_cookiesFinal1 || '""');
    this.all_cookiesFinal1 = JSON.stringify(credentials_json);
    if (this.all_cookiesFinal1 !== undefined) {
      const storage = localStorage;
      storage.setItem(credentialsKey, this.all_cookiesFinal1);
      const org_guid = credentials_json?.user?.org_guid;
      this.cookieService.deleteAll();
      this.orgService.setSelectedOrganisationGuid(org_guid).subscribe(data => {
        this.organisationID = data;
        if (this.organisationID) {
          this.isLoading = true;
          this.orgDashboardService.isGrcEnabled(this.organisationID).subscribe((data: Progress) => {
            this.progressData = {
              program_configured_completed: data.program_configured_completed,
              select_applicable_ctrl_completed: data.select_applicable_ctrl_completed,
              org_assessment_completed: data.org_assessment_completed,
              generate_pnp_doc_completed: data.generate_pnp_doc_completed,
              is_organization_onboarded: data.is_organization_onboarded
            };
            this.redirectBasedOnRolesAndOnboarding();
          });
        }
      });
      // this.redirectBasedOnRolesAndOnboarding();
      // }
      /**
       * Following code gets organization onboard status and set it in the organization service.
       * ( Internally it stores it into localstore)
       */
    }

    // localStorage.setItem(this.credentials, JSON.parse(this.all_cookiesFinal));
  }

  login() {
    this.submitted = true;
    if (this.loginForm.invalid) return;
    this.isLoading = true;
    const context = this.loginForm.value;
    this.authenticationService
      .login(context)
      .pipe(
        finalize(() => {
          this.loginForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        credentials => {
          this.roles = credentials['user']['role'];
          this.authenticationService.setLoginData(credentials, context);
          const org_guid = this.userService.getuserdetails().user['org_guid'];
          // if (this.userService.getUserRole() === 'organization_admin_role') {
          this.orgService.setSelectedOrganisationGuid(org_guid).subscribe(data => {
            this.organisationID = data;
          });
          this.submitted = false;
          // }
          /**
           * Following code gets organization onboard status and set it in the organization service.
           * ( Internally it stores it into localstore)
           */
          const isOrganizationOnboarded = this.userService.getuserdetails().user['is_organization_onboarded'];
          const isOrganizationType = this.userService.getuserdetails();
          this.orgService.setIsOrganisationOnboarded(isOrganizationOnboarded);
          localStorage.setItem('normal_org_onboarded', isOrganizationOnboarded);
          localStorage.setItem('org_password_based_login', credentials['user']['org_password_based_login']);
          localStorage.setItem('domain', credentials['user']['domain']);
          // Check for system admin role and redirect to respective page
          if (this.userService?.getUserRole1()['user']['role']?.includes('system_admin_role')) {
            this.isLoading = false;
            this.router.navigateByUrl('/organisation/leads');
          }
          /**
           * Checks the values of isOrganizationOnboarded and based on that it redirects to respective url
           */
          this.orgDashboardService.isGrcEnabled(this.organisationID).subscribe((data: Progress) => {
            this.progressData = {
              program_configured_completed: data.program_configured_completed,
              select_applicable_ctrl_completed: data.select_applicable_ctrl_completed,
              org_assessment_completed: data.org_assessment_completed,
              generate_pnp_doc_completed: data.generate_pnp_doc_completed
            };
            this.orgService.setIsOrganisationOnboarded(data.is_organization_onboarded || isOrganizationOnboarded);
            localStorage.setItem('select_applicable_ctrl_completed', String(data.select_applicable_ctrl_completed));
            localStorage.setItem('org_assessment_completed', String(data.org_assessment_completed));
            localStorage.setItem('isProgramConfigCompleted', JSON.stringify(data.program_configured_completed));
            this.redirectBasedOnRolesAndOnboarding();
          });
        },
        error => {
          log.debug(`Login error: ${error}`);
          this.error = error;
          if (this.error['error']['password_based_login']) {
            this.password_based_login = this.error['error']['password_based_login'];
          } else if (this.error['error']['user_not_exists']) {
            this.userNotExists = this.error['error']['user_not_exists'];
          }
        }
      );
  }

  onchange(evt: any) {
    if (evt.type == 'keyup' && this.reset_password_error) {
      this.reset_password_error = '';
    }
  }

  sendPasswordResetEmail() {
    this.error = '';
    this.success = '';
    this.isLoading = true;
    const context = this.forgotPasswordForm.value;
    this.isPasswordResetEmailSent = true;
    this.passwordResetService
      .sendPasswordResetEmail(context)
      .pipe(
        finalize(() => {
          this.forgotPasswordForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        data => {
          this.success = data.message;
        },
        error => {
          this.reset_password_error = error.error.reset_password_error;
        }
      );
  }

  toggleForgotPasswordForm() {
    this.error = '';
    this.success = '';
    this.forgotPasswordForm.reset();
    this.showForgetPasswordForm = !this.showForgetPasswordForm;
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  private createForm() {
    const remember_me_email = this.authenticationService.rememberMeEmail();
    this.loginForm = this.formBuilder.group({
      username: [remember_me_email ? remember_me_email : '', [Validators.email, Validators.required]],
      password: ['', Validators.required],
      remember: [true, Validators.required]
    });

    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]]
    });
  }

  private redirectBasedOnRolesAndOnboarding() {
    this.isLoading = false;
    this.userLoggedInorNot = this.authenticationService.isAuthenticated();
    if (!this.userLoggedInorNot) return;
    const isOrganizationOnboarded = this.userService.getuserdetails().user['is_organization_onboarded'];
    this.data = this.userService.getUserRole1();
    this.allRoles = this.userService.getUserRole1();
    this.role = this.allRoles['user']['role'];
    if (isOrganizationOnboarded) {
      if (this.role.includes('document_reader')) {
        this.router.navigateByUrl('/organisation/documents');
      } else if (this.role.includes('document_approver')) {
        this.router.navigateByUrl('/organisation/documents');
      } else if (this.role.includes('document_editor')) {
        this.router.navigateByUrl('/organisation/documents');
      } else if (this.role.includes('document_author')) {
        this.router.navigateByUrl('/organisation/documents');
      } else if (this.role.includes('organization_user_role')) {
        this.router.navigateByUrl('/organisation/documents');
      } else if (this.role.includes('incident_coordinator')) {
        this.router.navigateByUrl('/organisation/incident');
      } else if (this.role.includes('incident_operator')) {
        this.router.navigateByUrl('/organisation/incident');
      } else if (this.role.includes('incident_reviewer')) {
        this.router.navigateByUrl('/organisation/incident');
      } else if (this.role.includes('organization_admin_role')) {
        this.router.navigateByUrl('/organisation/form');
      }
    }
    if (this.userService.getUserRole() === 'organization_admin_role') {
      if (isOrganizationOnboarded) {
        this.router.navigateByUrl('/organisation/documents');
        return;
      } else {
        if (Object.values(this.progressData).every(item => !item)) {
          this.isLoading = false;
          localStorage.removeItem('verifyLinkCalled');
          this.router.navigateByUrl('/program-config');
          this.orgService.isverifyLink.next(false);
          return;
        }
        for (let key in this.routeMapper) {
          if (!this.progressData[key]) {
            this.router.navigateByUrl(this.routeMapper[key]);
            return;
          }
        }
        if (this.progressData.is_organization_onboarded) {
          this.router.navigateByUrl('/organisation/dashboard');
          return;
        }
      }
    }
    if (this.role.includes('organization_user_role')) {
      this.router.navigateByUrl('/organisation/documents');
      return;
    }
  }
  removeOptionalParamFromUrl(url: string) {
    const parsedUrl = new URL(url);
    parsedUrl.searchParams.delete('error');
    window.history.replaceState({}, document.title, parsedUrl.toString());
  }
  setStep(step: number) {
    if (this.signUpWithGoogleError) {
      this.removeOptionalParamFromUrl(window.location.href);
      this.signUpWithGoogleError = '';
    }
    this.loginStep = step;
    this.userNotExists = '';
    this.loginForm.get('password').reset();
    this.loginForm.get('username').reset();
    // this.loginForm.reset();
    this.error = '';
    this.tokenErrorString = '';
  }

  ngAfterViewInit() {
    if (localStorage.getItem('verifyLinkCalled') === 'true') {
      this.isLoading = true;
    }
  }

  verifyLink(token: string) {
    localStorage.setItem('verifyLinkCalled', 'true');
    this.authenticationService.verifyLink(token).subscribe(
      credentials => {
        let context = {
          username: credentials['user'].username,
          password: '',
          remember: true
        };
        this.roles = credentials['user']['role'];
        this.authenticationService.setLoginData(credentials, context);
        const org_guid = this.userService.getuserdetails().user['org_guid'];
        this.orgService.setSelectedOrganisationGuid(org_guid).subscribe(data => {
          this.organisationID = data;
        });

        const isOrganizationOnboarded = this.userService.getuserdetails().user['is_organization_onboarded'];
        const isOrganizationType = this.userService.getuserdetails();
        this.orgService.setIsOrganisationOnboarded(isOrganizationOnboarded);
        localStorage.setItem('org_password_based_login', credentials['user']['org_password_based_login']);
        localStorage.setItem('domain', credentials['user']['domain']);
        this.orgDashboardService.isGrcEnabled(this.organisationID).subscribe((data: Progress) => {
          this.progressData = {
            program_configured_completed: data.program_configured_completed,
            select_applicable_ctrl_completed: data.select_applicable_ctrl_completed,
            org_assessment_completed: data.org_assessment_completed,
            generate_pnp_doc_completed: data.generate_pnp_doc_completed
          };
          this.orgService.setIsOrganisationOnboarded(data.is_organization_onboarded || isOrganizationOnboarded);
          this.redirectBasedOnRolesAndOnboarding();
        });
      },
      error => {
        this.orgService.isverifyLink.next(false);
        this.setStep(2);
        this.tokenErrorString = error?.error?.error;
        this.isLoading = false;
      }
    );
  }
  ngOnDestroy(): void {
    if (this.isverifyLinkSubscription) {
      this.isverifyLinkSubscription.unsubscribe();
    }
  }
}
