import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { ShellModule } from './shells/shell/shell.module';
import { AboutModule } from './about/about.module';
import { LoginModule } from './login/login.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HomeModule } from '@app/home/home.module';
import { PasswordResetModule } from '@app/password-reset/password-reset.module';
import { OrgAdminshellModule } from '@app/shells/orgAdminshell/orgAdminshell.module';
import { OrganisationAdminModule } from '@app/Organisation-admin/organisation-admin.module';
import { OrganisationSystemAdminModule } from '@app/Organisation-system-admin/organisation-system-admin.module';
import { SlackOauthModule } from '@app/slack-oauth/slack-oauth.module';
import { PublicShellModule } from '@app/shells/publicShell/publicShell.module';
import { VersionModule } from '@app/version/version.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SettingsModule } from '@app/settings/settings.module';
import { ToastrModule } from 'ngx-toastr';
import { SignupModule } from './signup/signup.module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';

import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ChartService } from './Organisation-admin/chart.service';

import { MixpanelService } from '@app/mixpanel/mixpanel.service';

@NgModule({
  imports: [
    BrowserModule,
    MatTabsModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    NgbModule,
    MatStepperModule,
    MatButtonModule,
    SettingsModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-bottom-right'
    }),
    CoreModule,
    SharedModule,
    ShellModule,
    PublicShellModule,
    OrgAdminshellModule,
    OrganisationAdminModule,
    OrganisationSystemAdminModule,
    HomeModule,
    AboutModule,
    LoginModule,
    SignupModule,
    SlackOauthModule,
    PasswordResetModule,
    VersionModule,
    Ng2SearchPipeModule,
    FormsModule,
    CKEditorModule,
    NgxSkeletonLoaderModule.forRoot({ animation: 'pulse', loadingText: 'This item is actually loading...' }),
    AppRoutingModule // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  providers: [CookieService, ChartService, MixpanelService],
  bootstrap: [AppComponent]
})
export class AppModule {}
