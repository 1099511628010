import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { finalize } from 'rxjs/operators';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
@Component({
  selector: 'app-publish-document',
  templateUrl: './publish-document.component.html',
  styleUrls: ['./publish-document.component.scss']
})
export class PublishDocumentComponent implements OnInit {
  submitted = false;
  isModalSpinnerLoading = false;
  isScreenSpinnerLoading = true;
  OrganisationID: string | null;
  guidSubscription: any;
  success: boolean;
  error: any;

  @Input() formType: string;
  @Input() DocumentGuid: string;
  @Input() title_tag: string;
  @Input() message: string;
  @Input() docTitle: string;
  backend_error: any;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private documentsService: DocumentsService,
    private translate: TranslateService,
    private toaster: ToasterService
  ) {}

  ngOnInit() {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
    });
    this.isScreenSpinnerLoading = false;
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  save() {
    this.submitted = true;
    this.isModalSpinnerLoading = true;
    if (this.title_tag == 'Publish Document') {
      this.documentsService
        .submitPublishDocumentData(this.OrganisationID, this.DocumentGuid, '')
        .pipe(
          finalize(() => {
            this.isModalSpinnerLoading = false;
          })
        )
        .subscribe(
          (data: any) => {
            this.success = true;
            this.error = {
              remarks: ''
            };
            this.submitted = false;
            this.backend_error = '';
            this.activeModal.close({ result: data, isUpdate: true });
          },
          (error: any) => {
            this.submitted = false;
            this.isModalSpinnerLoading = false;
            this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
            this.backendErrorService.backendError$.subscribe(error => {
              this.backend_error = error;
            });
          }
        );
    } else {
      this.documentsService.archiveDocument(this.OrganisationID, this.DocumentGuid).subscribe(
        (data: any) => {
          this.success = true;
          this.error = {
            remarks: ''
          };
          this.submitted = false;
          this.backend_error = '';
          this.translate.get(data.message).subscribe(res => {
            this.toaster.showSuccess(res);
          });
          this.activeModal.close({ result: data, isUpdate: true });
        },
        (error: any) => {
          this.submitted = false;
          this.isModalSpinnerLoading = false;
          this.backendErrorService.setBackendError(error.message); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
    }
  }
}
