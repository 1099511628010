import { Component, OnInit, Input, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, I18nService } from '@app/core';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { IDropdownSettings } from 'ng-multiselect-dropdown/multiselect.model';
import { finalize, take } from 'rxjs/operators';
import { OrganisationService } from '@app/core/organisation.service';
import { SettingsService } from '@app/settings/settings.service';
import { ModalService } from '@app/core/modal/modal.service';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';

@Component({
  selector: 'app-add-administrator',
  templateUrl: './addAdministrator.component.html',
  styleUrls: ['./addAdministrator.component.scss']
})
export class AddAdministratorComponent implements OnInit, OnDestroy {
  OrganisationID: string | null;
  error: any;
  guidSubscription: any;
  statusType: any = 'Active';
  peopleList: object[];
  search_title: any = '';
  success = false;
  searchText: any;
  peopleCount = 0;
  @Input() title_tag: string;
  @Input() form_data: [];
  isModifiedUser = false;
  scrollLoader = false;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  page = 1;
  pagnationData: any;
  loading = true;
  paginatedDataUrl: any;
  constructor(
    private orgService: OrganisationService,
    private settingsService: SettingsService,
    public activeModal: NgbActiveModal,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toaster: ToasterService,
    private modalService: ModalService,
    private i18nService: I18nService,
    private translate: TranslateService
  ) {}

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getPeopleList();
    });
  }

  onScrollUp() {
    this.scrollLoader = false;
  }

  onScrollData() {
    this.scrollLoader = true;
    this.settingsService
      .getOrganizationPeopleListWithPagination(
        this.OrganisationID,
        (this.page += 1),
        this.search_title,
        this.statusType
      )
      .subscribe(
        data => {
          this.scrollLoader = false;
          this.pagnationData = data.results;
          this.paginatedDataUrl = data;
          this.peopleList = this.peopleList.concat(data.results);
        },
        error => {
          this.scrollLoader = false;
          this.error = error.error.message;
        }
      );
  }
  onScroll() {
    if (this.paginatedDataUrl?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }
  updateSearchText(x: any) {
    this.search_title = x.target.value;
    if (this.search_title == undefined) {
      this.search_title = '';
    }
  }

  Search(search_title: string) {
    this.page = 1;
    this.search_title = search_title ? search_title : '';
    this.settingsService
      .getOrganizationPeopleListWithPagination(this.OrganisationID, this.page, this.search_title, this.statusType)
      .subscribe(
        data => {
          this.peopleList = data.results;
          this.paginatedDataUrl = data;
          this.peopleCount = data['count'];
          this.loading = false;
        },
        error => {
          this.error = error.error.message;
        }
      );
  }
  organisationAdministrationAdd(guid: string, actionType: string): void {
    this.isModifiedUser = true;
    this.settingsService.revokeOrAddOrganizationAdminnistrationUser(guid, this.OrganisationID, actionType).subscribe(
      data => {
        this.activeModal.close(true);
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }

  openPeopleForm(formType: string, formData?: any) {
    this.modalService
      .custom(CustomDialogComponent, { title: 'Add people', modalType: 'people' })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        //  TODO : TO HANDLE THE SUCCESS AND ERROR MEESSAGE
        if (result) {
          this.getPeopleList();
          this.activeModal.close(true);

          this.translate.get('organisation_people.people_toaster_message.add_people').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Some error has occured.');
        }
      });
  }

  closePeopleAddModal(): void {
    if (this.isModifiedUser) {
      // earlier it was this.people > 0, changed this to resolve success message bug
      this.activeModal.close(true);
    } else {
      this.activeModal.close(false);
    }
  }

  private getPeopleList(): void {
    this.settingsService
      .getOrganizationPeopleListWithPagination(this.OrganisationID, this.page, this.search_title, this.statusType)
      .subscribe(
        data => {
          this.peopleList = data.results;
          this.paginatedDataUrl = data;
          this.peopleCount = data['count'];
          this.loading = false;
        },
        error => {
          this.error = error.error.message;
        }
      );
  }
}
