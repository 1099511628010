import { Component, OnDestroy, OnInit } from '@angular/core';
import { OrganisationService } from '@app/core/organisation.service';
import { OrganisationAdminService } from '@app/Organisation-admin/organisation-admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@app/core/user.service';
import { SettingsService } from '@app/settings/settings.service';
import { ModalService } from '@app/core/modal/modal.service';
import { ConfirmDialogComponent } from '@app/shared/modal/confirmDialog/confirmDialog.component';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { take } from 'rxjs/operators';
import { AddPeopleComponent } from '@app/shared/modal/addPeople/addPeople.component';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-administrator',
  templateUrl: './administrator.component.html',
  styleUrls: ['./administrator.component.scss']
})
export class AdministratorComponent implements OnInit, OnDestroy {
  OrganisationID: string | null;
  error: any;
  guidSubscription: any;
  administratorList: object[];
  parentTypeMessage = 'no-administrator-added';
  scrollLoader = false;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  page = 1;
  pagnationData: any;
  loading = true;
  paginatedDataUrl: any;

  constructor(
    private orgService: OrganisationService,
    private settingsService: SettingsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private toaster: ToasterService,
    private translate: TranslateService
  ) {}

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getAdministratorList();
    });
  }

  onScrollUp() {
    this.scrollLoader = false;
  }

  onScrollData() {
    this.scrollLoader = true;
    this.settingsService.getOrganizationAdminnistrationList(this.OrganisationID, (this.page += 1)).subscribe(
      data => {
        this.scrollLoader = false;
        this.pagnationData = data.results;
        this.paginatedDataUrl = data;
        this.administratorList = this.administratorList.concat(data.results);
      },
      error => {
        this.scrollLoader = false;
        this.error = error.error.message;
      }
    );
  }
  onScroll() {
    if (this.paginatedDataUrl?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }

  openModal() {
    this.modalService
      .custom(
        CustomDialogComponent,
        {
          title: 'Add administrator',
          modalType: 'administrator'
        },
        { windowClass: 'add-admin' }
      )
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getAdministratorList();
          this.translate
            .get('organisation_administration.administration_toaster_message.add_administrator')
            .subscribe(res => {
              this.toaster.showSuccess(res);
            });
        }
      });
  }

  openConfirm(firstName?: string, lastName?: string, userGuid?: string) {
    this.modalService
      .confirm('Do you want to remove ' + firstName + ' ' + lastName)
      .pipe(take(1))
      .subscribe((confirmed: any) => {
        if (confirmed) {
          this.removeUser(userGuid);
        }
      });
  }

  removeUser(guid: string): void {
    this.settingsService.revokeOrAddOrganizationAdminnistrationUser(guid, this.OrganisationID, 'revoke').subscribe(
      data => {
        this.getAdministratorList();
        this.translate
          .get('organisation_administration.administration_toaster_message.remove_administrator')
          .subscribe(res => {
            this.toaster.showSuccess(res);
          });
      },
      error => {
        this.error = error.error.message;
        this.translate
          .get('organisation_administration.administration_toaster_message.error_administrator')
          .subscribe(res => {
            this.toaster.showError(res);
          });
        console.log('Error:', error.error.message);
      }
    );
  }

  private getAdministratorList(): void {
    this.page = 1;
    this.settingsService.getOrganizationAdminnistrationList(this.OrganisationID, this.page).subscribe(
      data => {
        this.administratorList = data.results;
        this.paginatedDataUrl = data;
        this.loading = false;
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }
}
