<div class="row justify-content-center">
  <div class="login-outer-container">
    <div class="my-5">
      <div class=" text-center d-flex">
        <div class=" fs-17 w-100p cursor-pointer" routerLink="/login">
          <img src="/assets/images/logo/logo.png" class="cb-lite-logo mx-1" alt="logo" srcset="" />
          <span class="font-weight-bold fs-17">ComplianceBot</span>
        </div>
      </div>
      <div class="login-intro">
        <div class="login-container">
          <form class="example-form" (ngSubmit)="passwordReset()" [formGroup]="passwordResetForm" novalidate>
            <div class=" text-center w-100p  mb-2">
              <div class="font-weight-bold create-text">
                Welcome Back
              </div>
            </div>
            <div class="d-flex justify-content-center w-100p mb-2">
              <div class="text-left" style="width: 410px !important;">
                <div>
                  <label class="m-0">New Password</label>
                </div>
                <mat-form-field appearance="outline" class="w-400">
                  <input [type]="hide ? 'password' : 'text'" matInput formControlName="new_password1" />
                  <mat-icon class="cursor-pointer pt-2" matSuffix (click)="hide = !hide">{{
                    hide ? 'visibility_off' : 'visibility'
                  }}</mat-icon>
                </mat-form-field>
                <mat-error *ngIf="error['new_password1']"> *{{ error['new_password1'] }} </mat-error>
                <mat-error *ngIf="f.new_password1.errors && (f.new_password1.touched || submitted)">
                  <span
                    for="confirmpassword"
                    class="text-danger"
                    *ngIf="f.new_password1.errors['invalidPassword']"
                    translate
                    >password_recovery.password_type</span
                  >
                </mat-error>
              </div>
            </div>
            <div class="d-flex justify-content-center w-100p ">
              <div class="text-left" style="width: 410px !important;">
                <div>
                  <label class="m-0">Confirm Password</label>
                </div>
                <mat-form-field appearance="outline" class="w-400">
                  <input [type]="hideNewPassword ? 'password' : 'text'" matInput formControlName="new_password2" />
                  <mat-icon class="cursor-pointer pt-2" matSuffix (click)="hideNewPassword = !hideNewPassword">{{
                    hideNewPassword ? 'visibility_off' : 'visibility'
                  }}</mat-icon>
                </mat-form-field>
                <mat-error *ngIf="error['new_password2']">
                  <span for="confirmpassword" class="text-danger">*{{ error['new_password2'] }}</span>
                </mat-error>

                <mat-error *ngIf="f.new_password2.errors && (f.new_password2.touched || submitted)">
                  <span for="confirmpassword" class="text-danger" *ngIf="f.new_password2.errors.mustMatch" translate
                    >password_recovery.confirm_password_check</span
                  >
                </mat-error>
              </div>
            </div>
            <div class="mt-1 mb-1 ">
              <div class="text-right login-text mr-2" routerLink="/login">
                Login?
              </div>
            </div>
            <div class="row justify-content-center mt-4 ">
              <button
                class="auth-btn "
                [class.cb-secondary-btn]="!passwordResetForm.invalid"
                [class.cb-secondary-btn-disabled]="passwordResetForm.invalid"
                [style.pointer-events]="passwordResetForm.invalid ? 'none' : 'all'"
                [disabled]="passwordResetForm.invalid"
              >
                <!-- <span class="lite-login-btn-text" *ngIf="set_pwd == 'False'">
                  Reset Password
                </span> -->
                Set Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
