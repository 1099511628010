<div id="mySidenav" class="sidenav">
  <div class="sidenav-header">
    <span class="title">Request Change</span>
    <i class="fa fa-times text-black-1 closebtn" (click)="closeNav()" ngbAutoFocus translate></i>
  </div>
  <div class="row mt-2" *ngIf="backend_error">
    <div class="col-12 col-sm-12 col-lg-12 ml-5">
      <div class="text-danger" translate>* {{ backend_error }}</div>
    </div>
  </div>
  <form [formGroup]="InviteEditorForm" class="sidenav-body form">
    <div class="d-flex flex-column">
      <label for="folder" class="label">Document Name</label>
      <label for="folderName" class="subfolder-name">{{ docTitle }}</label>
    </div>
    <div class="form-group">
      <label for="Select Editors" class="label">
        New Editors
      </label>
      <ng-select
        class="custom-ng-select"
        [clearSearchOnAdd]="true"
        [closeOnSelect]="false"
        [multiple]="true"
        placeholder="Select new editors"
        clearAllText="Clear"
        [items]="userList"
        bindLabel="FullName"
        class="select-editors"
      >
        <ng-template ng-option-tmp let-item="item">
          <div
            class="option-item d-flex"
            (click)="$event.stopPropagation()"
            [ngClass]="{ 'ng-option-selected': item.selected }"
            [ngStyle]="{
              backgroundColor: item.selected ? '#E5F7F9' : ''
            }"
          >
            <div class="d-flex selected-option">
              <input
                class="custom-checkbox"
                id="item-{{ item.guid }}"
                type="checkbox"
                [checked]="item.selected"
                (change)="onItemSelect(item)"
              />
              <div class="d-flex cursor-default" (click)="onItemSelect(item)">
                <span class="initials" [style.backgroundColor]="getColor(item.first_name)">
                  {{ getInitials(item.first_name, item.last_name) }}
                </span>
                <div (click)="onItemSelect(item)">
                  <div class="username" (click)="onItemSelect(item)">{{ item.first_name }} {{ item.last_name }}</div>
                  <div class="email" (click)="onItemSelect(item)">({{ item.email }})</div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-select>
      <div class="selected-items" *ngIf="selectedItems.length > 0" (mouseleave)="showTooltip = false">
        <ng-container *ngFor="let item of selectedItems.slice(0, 2)">
          <span class="badge badge-primary mr-2">
            {{ item.first_name }} {{ item.last_name }} <br />
            ({{ item.email }})
            <span class="badge-remove" (click)="removeItem(item)">x</span>
          </span>
        </ng-container>
        <ng-container *ngIf="selectedItems.length > 2">
          <span (mouseenter)="showTooltip = true">
            <span class="count">+{{ selectedItems.length - 2 }}</span>
            <div
              class="tooltip"
              *ngIf="showTooltip"
              (mouseenter)="showTooltip = true"
              (mouseleave)="showTooltip = false"
            >
              <ng-container *ngFor="let item of selectedItems.slice(2)">
                <span class="badge badge-primary mr-2">
                  {{ item.first_name }} {{ item.last_name }} <br />
                  ({{ item.email }})
                  <span class="badge-remove" (click)="removeItem(item)">x</span>
                </span>
              </ng-container>
            </div>
          </span>
        </ng-container>
      </div>
    </div>
    <div class="form-group">
      <label for="Select Editors" class="label">
        Old Editors
      </label>
      <ng-select
        class="custom-ng-select"
        [clearSearchOnAdd]="true"
        [closeOnSelect]="false"
        [multiple]="true"
        placeholder="Select old editors"
        clearAllText="Clear"
        [items]="OldUsersList"
        bindLabel="FullName"
        class="select-editors"
      >
        <ng-template ng-option-tmp let-item="item">
          <div
            class="option-item d-flex"
            (click)="$event.stopPropagation()"
            [ngClass]="{ 'ng-option-selected': item.selected }"
            [ngStyle]="{
              backgroundColor: item.selected ? '#E5F7F9' : ''
            }"
          >
            <div class="d-flex selected-option">
              <input
                class="custom-checkbox"
                id="item-{{ item.user.guid }}"
                type="checkbox"
                [checked]="item.selected"
                (change)="onOldItemSelect(item)"
              />
              <div class="d-flex cursor-default" (click)="onOldItemSelect(item)">
                <span class="initials" [style.backgroundColor]="getColor(item.initials)">
                  {{ item.initials }}
                </span>
                <div (click)="onOldItemSelect(item)">
                  <div class="username" (click)="onOldItemSelect(item)">
                    {{ item.user.name }}
                  </div>
                  <div class="email" (click)="onOldItemSelect(item)">({{ item.user.email }})</div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-select>
      <div class="selected-items" *ngIf="selectedOldEditor.length > 0" (mouseleave)="showTooltip = false">
        <ng-container *ngFor="let item of selectedOldEditor.slice(0, 2)">
          <span class="badge badge-primary mr-2">
            {{ item.user.name }} <br />
            ({{ item.user.email }})
            <span class="badge-remove" (click)="removeOldItem(item)">x</span>
          </span>
        </ng-container>
        <ng-container *ngIf="selectedOldEditor.length > 2">
          <span (mouseenter)="showTooltip = true">
            <span class="count">+{{ selectedOldEditor.length - 2 }}</span>
            <div
              class="tooltip"
              *ngIf="showTooltip"
              (mouseenter)="showTooltip = true"
              (mouseleave)="showTooltip = false"
            >
              <ng-container *ngFor="let item of selectedOldEditor.slice(2)">
                <span class="badge badge-primary mr-2">
                  {{ item.user.name }} <br />
                  ({{ item.user.email }})
                  <span class="badge-remove" (click)="removeOldItem(item)">x</span>
                </span>
              </ng-container>
            </div>
          </span>
        </ng-container>
      </div>
      <div class="col-12 error-mssg" *ngIf="!selectedItems.length && !this.selectedOldEditor.length && submitted">
        <label for="editor" class="text-danger" style="margin-left: -13px;" translate>
          {{
            this.modalType == 'Invite Editors for Request Change'
              ? 'Document_Invite_Editor.invite_editor.*Atleast one editor is required.'
              : 'Atleast one responder is required.'
          }}
        </label>
      </div>
    </div>
    <div class="form-group">
      <label for="duedates" class="label">Due Date</label>
      <div class="position-relative">
        <input
          class=" form-control cb-input-focus cb-input-focus pr-5"
          id="due_date"
          placeholder="Select due date"
          formControlName="due_date"
          name="due_date"
          ngbDatepicker
          (click)="d.toggle()"
          [minDate]="minDate"
          (dateSelect)="onDateSelection($event)"
          required
          #d="ngbDatepicker"
        />
        <img src="../../../assets/images/core/calender.svg" class="calendar-icon" (click)="d.toggle()" />
      </div>
      <div
        class="col-12 error-mssg"
        style="margin-left: -13px;"
        *ngIf="(f.due_date.touched || submitted) && f.due_date.errors"
      >
        <label for="due_date" class="text-danger" *ngIf="f.due_date.errors.required" translate>
          Document_Invite_Editor.invite_editor.*Due Date is required.
        </label>
        <label for="due_date" class="text-danger" *ngIf="f.due_date.errors.DateTodayOrAfter_error" translate>
          Document_Invite_Editor.invite_editor.*Due Date must be greater than today.
        </label>
      </div>
    </div>
    <div class="form-group">
      <label for="comment" class="label">Comment</label>
      <textarea
        id="comment"
        formControlName="comment"
        class=" form-control cb-input-focus cb-input-focus"
        placeholder="Write a comment"
        [maxLength]="4097"
      ></textarea>
      <div
        *ngIf="(f.comment.touched || f.comment.dirty) && f.comment.errors"
        style="margin-left: -5px;"
        class="text-danger"
      >
        <label *ngIf="f.comment.errors.maxLength">*Comment must be less than 4096 characters.</label>
      </div>
    </div>
  </form>

  <div *ngIf="OldUsersList?.length > 0" class="w-100p p-3">
    <label class="lable">Assigned Editors</label>
    <table class="w-100p">
      <thead>
        <tr>
          <th class="p-2">NAME</th>
          <th class="p-2">DUE DATE</th>
          <th class="p-2">STATUS</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let editor of OldUsersList" style="border-bottom: 1px solid #DEE2E6;">
          <td class="d-flex tdata">
            <span class="initials ml-1" [style.backgroundColor]="getColor(editor.initials)">
              {{ editor.initials }} </span
            ><span class="mt-2">{{ editor.user.name }}</span>
          </td>
          <td class=" tdata">
            <div style="margin-left: 3px;">{{ editor.due_date | date }}</div>
          </td>
          <td class=" tdata">
            <div style="margin-left: 2px;">{{ editor.status }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="sidenav-footer d-flex">
    <button
      type="submit"
      class="btn-save cb-btn-primary"
      [ngClass]="{
        'btn-disabled cb-btn-disabled':
          InviteEditorForm.invalid || (!selectedItems.length && !this.selectedOldEditor.length)
      }"
      [disabled]="InviteEditorForm.invalid || (!selectedItems.length && !this.selectedOldEditor.length)"
      *ngIf="!isLoading"
      (click)="save()"
    >
      Save
    </button>
    <button type="submit" class="btn-save cb-btn-disabled" disabled *ngIf="isLoading">
      <i class="fa fa-spinner fa-spin"></i>
    </button>
    <button type="button" class="px-2 cb-btn-secondary" (click)="closeNav()">Cancel</button>
  </div>
</div>
