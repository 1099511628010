import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalService } from '@app/core/modal/modal.service';
import { OrganisationService } from '@app/core/organisation.service';
import { WizardService } from '@app/home/wizard.services';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';

@Component({
  selector: 'app-edit-document-dialog',
  templateUrl: './edit-document-dialog.component.html',
  styleUrls: ['./edit-document-dialog.component.scss']
})
export class EditDocumentDialogComponent implements OnInit {
  row: any;
  procedureText: string;
  policyText: string;
  OrganisationID: string;
  guidSubscription: any;
  error: string = '';
  currentTab: any = 0;
  loading: boolean = true;
  orgAssesment: any[] = [];
  progressBarWidth = '';
  asmtResponse: any[] = [];
  isRestorePolicyDisabled: boolean = false;
  isRestoreProcedureDisabled: boolean = false;
  isQuestionnaireView: boolean = false;
  items: number[];
  private intervalSubscription: Subscription | undefined;

  isFirstAttempt: boolean = false;
  selectedOrgType: string;
  backend_error: string;

  constructor(
    public dialogRef: MatDialogRef<EditDocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public wizardService: WizardService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private orgService: OrganisationService,
    private modalService: ModalService,
    private backendErrorService: BackendErrorService
  ) {}

  ngOnInit(): void {
    this.items = Array.from({ length: 30 }, () => Math.floor(Math.random() * 6) + 93);
    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
    });
    const storedData = JSON.parse(localStorage.getItem('org_type'));
    this.selectedOrgType = storedData ? storedData.guid : null;
    this.row = this.data.row;
    this.policyText = this.row.policy_text;
    this.procedureText = this.row.procedure_text;
    this.isRestorePolicyDisabled = this.row.isRestorePolicyDisabled;
    this.isRestoreProcedureDisabled = this.row.isRestoreProcedureDisabled;
    this.getCtrlWiseAsmt();
  }

  getCtrlWiseAsmt() {
    this.wizardService.getCtrlWiseAsmt(this.OrganisationID, this.row.code).subscribe(
      data => {
        this.loading = false;
        this.orgAssesment = data?.results;
        if (this.orgAssesment) {
          let answers = this.orgAssesment.filter((item: any) => item.answer_text?.options?.length > 0);
          if (answers?.length > 0) {
            this.isFirstAttempt = false;
          } else {
            this.isFirstAttempt = true;
          }
        }
        this.orgAssesment.forEach((item: any) => {
          // //To test outer questons other option
          // item.question_details.options.push({id:item.question_details.options.length+1,opt:'Other'})

          item['isOtherActive'] =
            typeof this.isArraySubset(item.question_details.options, item?.answer_text?.options) == 'string'
              ? true
              : false;
          if (item.isOtherActive) {
            item['otherOption'] = this.isArraySubset(item.question_details.options, item?.answer_text?.options);
          } else {
            item['otherOption'] = '';
          }
          if (item.answer_text == null) {
            item.answer_text = [];
            item['answers'] = [];
          } else {
            item['answers'] = item?.answer_text?.options ? item.answer_text.options : [];
          }
          if (
            item.question_details.best_response.length &&
            item.question_details.best_response.length > 0 &&
            this.isFirstAttempt
          ) {
            if (item.answers.length > 0) {
              item.question_details.best_response.forEach((best_response: string) => {
                const bstResOpt = item.question_details.options.find((option: any) => option.opt == best_response);
                if (!item.answers.find((eachAns: any) => eachAns.opt == bstResOpt.opt)) {
                  item['answers'].push(bstResOpt);
                }
              });
            } else {
              item.question_details.best_response.forEach((best_response: string) => {
                const bstResOpt = item.question_details.options.find((option: any) => option.opt == best_response);
                if (bstResOpt) {
                  item['answers'].push(bstResOpt);
                }
              });
            }
          }
          if (item.question_details.follow_up && item.question_details.follow_up.Yes.length > 0) {
            item.question_details.follow_up.Yes.forEach((element: any) => {
              const question = item?.answer_text?.follow_up?.Yes?.find(
                (eachQuestion: any) => eachQuestion.question == element.question
              );
              if (element.type == 'multiple_choice') {
                element['answers'] = question?.options ? question?.options : [];
              }
              if (element.type == 'text') {
                element['answers'] = question?.options ? question?.options : [''];
              }

              //To test inner questons other option
              // if (element.hasOwnProperty('options')) {
              //   element.options.push({id:element.options.length+1,opt:'Other'});
              // }

              if (element) {
                element['isOtherActive'] =
                  typeof this.isArraySubset(element?.options, element['answers']) == 'string' ? true : false;
                if (element.isOtherActive) {
                  element['otherOption'] = this.isArraySubset(element?.options, element['answers']);
                } else {
                  element['otherOption'] = '';
                }

                if (element?.best_response?.length && element?.best_response?.length > 0 && this.isFirstAttempt) {
                  if (element.answers.length > 0) {
                    element?.best_response.forEach((best_response: string) => {
                      const bstResOpt = element.options.find((option: any) => option.opt == best_response);
                      if (!element.answers.find((eachAns: any) => eachAns.opt == bstResOpt.opt)) {
                        element['answers'].push(bstResOpt);
                      }
                    });
                  } else {
                    element?.best_response.forEach((best_response: string) => {
                      const bstResOpt = element.options.find((option: any) => option.opt == best_response);
                      if (bstResOpt) {
                        element['answers'].push(bstResOpt);
                      }
                    });
                  }
                }
              }
            });
          }
        });
        this.asmtResponse = cloneDeep(this.orgAssesment);
      },
      (error: any) => {
        this.loading = false;
        console.log('Error:', error.error.message);
      }
    );
  }

  saveChangesPolicy(row: any) {
    this.row.policy_text = this.policyText;
    row.isPolicyEditable = !row.isPolicyEditable;
  }

  saveChangesProcedure(row: any) {
    this.row.procedure_text = this.procedureText;
    row.isProcedureEditable = !row.isProcedureEditable;
  }

  restorePolicyText(row: any, isRestore: boolean) {
    let payload;
    if (isRestore) {
      payload = {
        org_program_unit_control: row?.policy?.org_program_unit_control,
        policy_title_updated: null,
        policy_text_updated: null
      };
    } else {
      payload = {
        org_program_unit_control: row?.policy?.org_program_unit_control,
        policy_title_updated: this.row.control,
        policy_text_updated: this.policyText
      };
    }
    this.wizardService.restoreAndEditPolicy(this.OrganisationID, payload, row?.policy?.id).subscribe(
      data => {
        if (isRestore) {
          this.row.policy_text = data.policy_text_edited ? data.policy_text_edited : data.policy_text_ai;
          this.policyText = data.policy_text_edited ? data.policy_text_edited : data.policy_text_ai;

          this.row['isRestorePolicyDisabled'] = data.policy_text_edited ? false : true;
          this.isRestorePolicyDisabled = data.policy_text_edited ? false : true;
          row.isPolicyEditable = !row.isPolicyEditable;
        } else {
          this.row.policy_text = data.policy_text_edited;
          this.policyText = this.row.policy_text;
          row.isPolicyEditable = !row.isPolicyEditable;
          this.row['isRestorePolicyDisabled'] = data.policy_text_edited ? false : true;
          this.isRestorePolicyDisabled = data.policy_text_edited ? false : true;
        }
        this.translate.get(isRestore ? 'Policy restored' : 'Policy updated').subscribe(res => {
          this.toaster.showSuccess(res);
        });
      },
      error => {
        this.error = error.error;
        this.translate.get(error.error).subscribe(res => {
          this.toaster.showError(res);
        });
        console.log('Error:', error.error.message);
      }
    );
  }

  changeTab(index: any) {
    this.currentTab = index;
  }

  cancelPolicyEdit() {
    if (this.row.policy_text != this.policyText) {
      this.modalService
        .custom(CustomDialogComponent, {
          title: 'Discard Changes',
          modalType: 'Discard Changes',
          message: 'Are you sure you want to discard the changes made to Policy text?',
          centered: true
        })
        .pipe(take(1))
        .subscribe((isConfirmed: any) => {
          if (isConfirmed) {
            this.policyText = this.row.policy_text;
            this.isRestorePolicyDisabled = this.row.isRestorePolicyDisabled;
            this.row.isPolicyEditable = !this.row.isPolicyEditable;
          }
        });
    } else {
      this.policyText = this.row.policy_text;
      this.row.isPolicyEditable = !this.row.isPolicyEditable;
    }
  }

  cancelProcedureEdit() {
    if (this.row.procedure_text != this.procedureText) {
      this.modalService
        .custom(CustomDialogComponent, {
          title: 'Discard Changes',
          modalType: 'Discard Changes',
          message: 'Are you sure you want to discard the changes made to Procedure text?',
          centered: true
        })
        .pipe(take(1))
        .subscribe((isConfirmed: any) => {
          if (isConfirmed) {
            this.procedureText = this.row.procedure_text;
            this.isRestoreProcedureDisabled = this.row.isRestoreProcedureDisabled;
            this.row.isProcedureEditable = !this.row.isProcedureEditable;
          }
        });
    } else {
      this.procedureText = this.row.procedure_text;
      this.row.isProcedureEditable = !this.row.isProcedureEditable;
    }
  }

  restoreProcedureText(row: any, isRestore: boolean) {
    let payload;
    if (isRestore) {
      payload = {
        org_program_unit_control: row?.procedure?.org_program_unit_control,
        procedure_title_updated: null,
        procedure_text_updated: null
      };
    } else {
      payload = {
        org_program_unit_control: row?.procedure?.org_program_unit_control,
        procedure_title_updated: this.row?.control,
        procedure_text_updated: this.procedureText
      };
    }
    this.wizardService.restoreAndEditProcedure(this.OrganisationID, payload, row?.procedure?.id).subscribe(
      data => {
        if (isRestore) {
          this.row.procedure_text = data.procedure_text_edited ? data.procedure_text_edited : data.procedure_text_ai;
          this.procedureText = data.procedure_text_edited ? data.procedure_text_edited : data.procedure_text_ai;
          this.row['isRestoreProcedureDisabled'] = data.procedure_text_edited ? false : true;
          this.isRestoreProcedureDisabled = data.procedure_text_edited ? false : true;
          row.isProcedureEditable = !row.isProcedureEditable;
        } else {
          this.row.procedure_text = data.procedure_text_edited;
          this.procedureText = this.row.procedure_text;
          row.isProcedureEditable = !row.isProcedureEditable;
          this.row['isRestoreProcedureDisabled'] = data.procedure_text_edited ? false : true;
          this.isRestoreProcedureDisabled = data.procedure_text_edited ? false : true;
        }
        this.translate.get(isRestore ? 'Procedure restored' : 'Procedure updated').subscribe(res => {
          this.toaster.showSuccess(res);
        });
      },
      error => {
        this.error = error.error;
        this.translate.get(error.error).subscribe(res => {
          this.toaster.showError(res);
        });
        console.log('Error:', error.error.message);
      }
    );
  }

  onCloseDialog(isClickOnAnswerQuestionnaire: boolean): void {
    if (!this.isQuestionnaireView) {
      const hasPolicyChanges = this.row.policy_text !== this.policyText;
      const hasProcedureChanges = this.row.procedure_text !== this.procedureText;

      if (hasPolicyChanges || hasProcedureChanges) {
        let message = '';
        if (hasPolicyChanges && hasProcedureChanges) {
          message = 'Are you sure you want to discard the changes made to Policy & Procedure text?';
        } else if (hasPolicyChanges) {
          message = 'Are you sure you want to discard the changes made to Policy text?';
        } else if (hasProcedureChanges) {
          message = 'Are you sure you want to discard the changes made to Procedure text?';
        }

        this.modalService
          .custom(CustomDialogComponent, {
            title: 'Discard Changes',
            modalType: 'Discard Changes',
            message: message,
            centered: true
          })
          .pipe(take(1))
          .subscribe((isConfirmed: any) => {
            if (isConfirmed) {
              this.policyText = this.row.policy_text;
              this.procedureText = this.row.procedure_text;
              this.row.isPolicyEditable = false;
              this.row.isProcedureEditable = false;
              isClickOnAnswerQuestionnaire ? (this.isQuestionnaireView = true) : this.dialogRef.close();
            }
          });
      } else {
        this.row.isPolicyEditable = false;
        this.row.isProcedureEditable = false;
        isClickOnAnswerQuestionnaire ? (this.isQuestionnaireView = true) : this.dialogRef.close();
      }
    } else if (this.isQuestionnaireView) {
      if (!this.compareAnswers(this.asmtResponse, this.orgAssesment)) {
        // this.modalService
        //   .custom(CustomDialogComponent, {
        //     title: 'Save Responses',
        //     modalType: 'Save Responses',
        //     message: 'Do You want to save your responses? <br>This will not regenerate your policy and procedure text',
        //     centered: true
        //   })
        //   .pipe(take(1))
        //   .subscribe((res: any) => {
        //     if (res.isConfirmed) {
        //       this.saveCtrlWiseAsmt();
        //     } else if (res && !res.isConfirmed) {
        //       this.questions = cloneDeep(this.asmtResponse);
        //       this.dialogRef.close();
        //     }
        //   });
        this.modalService
          .custom(CustomDialogComponent, {
            title: 'Discard Changes',
            modalType: 'Discard Changes',
            message: 'Are you sure you want to discard the changes made to Questionnaire?',
            centered: true
          })
          .pipe(take(1))
          .subscribe((isConfirmed: any) => {
            if (isConfirmed) {
              this.orgAssesment = cloneDeep(this.asmtResponse);
              this.isQuestionnaireView = false;
              this.dialogRef.close();
            }
          });
      } else {
        this.dialogRef.close();
      }
    }
  }

  saveCtrlWiseAsmt() {
    const payload = this.orgAssesment.map(item => {
      const { guid, control_unit, answer_text, question_details } = item;
      return {
        question: guid,
        control_unit: control_unit.guid,
        answer_text: {
          answers: answer_text,
          question: question_details.question
        },
        organization: this.OrganisationID
      };
    });

    this.wizardService.saveCtrlWiseAsmt(this.OrganisationID, payload, true).subscribe(
      data => {
        this.regeneratePnp();
        this.getCtrlWiseAsmt();
        this.translate.get('Assessment saved successfully').subscribe(res => {
          this.toaster.showSuccess(res);
        });
      },
      error => {
        this.error = error.error.message;
        this.translate.get(this.error).subscribe(res => {
          this.toaster.showSuccess(res);
        });
        console.log('Error:', error.error.message);
      }
    );
  }

  regeneratePnp() {
    this.wizardService.regeneratePnp(this.OrganisationID, this.row.guid).subscribe(
      data => {
        this.policyText = '';
        this.procedureText = '';
        this.row.policy_text = '';
        this.row.procedure_text = '';
        this.isQuestionnaireView = false;
        this.isRestorePolicyDisabled = false;
        this.isRestoreProcedureDisabled = false;
        this.row.isRestorePolicyDisabled = false;
        this.row.isRestoreProcedureDisabled = false;
        this.intervalSubscription = interval(15000).subscribe(() => {
          this.getPnpStatusForSpecificCtrl();
        });
        this.translate.get(data.msg).subscribe(res => {
          this.toaster.showSuccess(res);
        });
      },
      error => {
        this.error = error.error;
        this.translate.get(error.error).subscribe(res => {
          this.toaster.showSuccess(res);
        });
        console.log('Error:', error.error.message);
      }
    );
  }

  getPnpStatusForSpecificCtrl() {
    this.wizardService.getPnpStatusForSpecificCtrl(this.OrganisationID, this.row.code).subscribe(
      data => {
        if (data.policy_status && data.procedure_status) {
          this.getSpecificPolicyProcedure();
        }
      },
      error => {
        this.error = error.error;
        this.translate.get(error.error).subscribe(res => {
          this.toaster.showSuccess(res);
        });
        console.log('Error:', error.error.message);
      }
    );
  }

  getSpecificPolicyProcedure() {
    this.wizardService.getSpecificPolicyProcedure(this.OrganisationID, this.row?.guid).subscribe(
      data => {
        if (data) {
          this.row.procedure_text = data.procedure[0].procedure_text_edited
            ? data.procedure[0].procedure_text_edited
            : data.procedure[0].procedure_text_ai;
          this.row.policy_text = data.policy[0].policy_text_edited
            ? data.policy[0].policy_text_edited
            : data.policy[0].policy_text_ai;

          this.policyText = this.row.policy_text;
          this.procedureText = this.row.procedure_text;

          this.row['isRestoreProcedureDisabled'] = data.procedure[0].procedure_text_edited ? false : true;
          this.row['isRestorePolicyDisabled'] = data.policy[0].policy_text_edited ? false : true;
          this.isRestoreProcedureDisabled = data.procedure[0].procedure_text_edited ? false : true;
          this.isRestorePolicyDisabled = data.policy[0].policy_text_edited ? false : true;

          this.intervalSubscription?.unsubscribe();
        }
      },
      (error: any) => {
        this.loading = false;
        console.log('Error:', error.error.message);
      }
    );
  }

  backToManualEditing() {
    if (!this.compareAnswers(this.asmtResponse, this.orgAssesment)) {
      // this.modalService
      //   .custom(CustomDialogComponent, {
      //     title: 'Save Responses',
      //     modalType: 'Save Responses',
      //     message: 'Do You want to save your responses? <br>This will not regenerate your policy and procedure text',
      //     centered: true
      //   })
      //   .pipe(take(1))
      //   .subscribe((res: any) => {
      //     if (res.isConfirmed) {
      //       this.saveCtrlWiseAsmt();
      //       this.questions = cloneDeep(this.asmtResponse);
      //       this.isQuestionnaireView = false;
      //     } else if (res && !res.isConfirmed) {
      //       this.questions = cloneDeep(this.asmtResponse);
      //       this.isQuestionnaireView = false;
      //     }
      //   });

      this.modalService
        .custom(CustomDialogComponent, {
          title: 'Discard Changes',
          modalType: 'Discard Changes',
          message: 'Are you sure you want to discard the changes made to Questionnaire?',
          centered: true
        })
        .pipe(take(1))
        .subscribe((isConfirmed: any) => {
          if (isConfirmed) {
            this.orgAssesment = cloneDeep(this.asmtResponse);
            this.isQuestionnaireView = false;
          }
        });
    } else {
      this.isQuestionnaireView = false;
    }
  }

  ngOnDestroy(): void {
    // this.intervalSubscription?.unsubscribe();
  }

  isArraySubset(arr1: any[], arr2: any[]) {
    for (let i = 0; i < arr2?.length; i++) {
      const elementToLower = arr2[i]?.opt?.toLowerCase();
      if (arr1?.findIndex(el => el?.opt?.toLowerCase() === elementToLower) === -1) {
        return arr2[i].opt;
      }
    }
    return true;
  }

  getAlphabet(index: number): string {
    const alphabetArray: string[] = 'abcdefghijklmnopqrstuvwxyz'.split('');
    const alphabetIndex = index % 26;
    return alphabetArray[alphabetIndex];
  }

  updateOrgAssesment() {
    let payload: any = {
      org_type: null,
      assessment: []
    };
    this.orgAssesment.forEach((eachQuestion: any) => {
      const otherOpt = eachQuestion.answers.find((answer: any) => answer?.opt?.toLowerCase() === 'other');
      if (otherOpt) {
        otherOpt.opt = eachQuestion.otherOption;
      }
      let obj: any = {
        question: eachQuestion.guid,
        answer_text: {
          options: eachQuestion.answers,
          question: eachQuestion.question_details.question
        },
        control_unit: null,
        organization: this.OrganisationID
      };
      if (eachQuestion?.question_details?.follow_up?.Yes && eachQuestion?.answers[0]?.opt?.toLowerCase() === 'yes') {
        let subQuestions: any = [];
        eachQuestion.question_details.follow_up.Yes.forEach((subQuestion: any) => {
          const otherOpt = subQuestion?.answers?.find((answer: any) => answer?.opt?.toLowerCase() === 'other');
          if (otherOpt) {
            otherOpt.opt = subQuestion.otherOption;
          }
          subQuestions.push({
            question: subQuestion.question,
            options: subQuestion.answers
          });
        });
        obj.answer_text['follow_up'] = {
          Yes: subQuestions
        };
      }
      payload.assessment.push(obj);
    });
    this.loading = true;
    this.wizardService.saveCtrlWiseAsmt(this.OrganisationID, payload, true).subscribe(
      response => {
        this.loading = false;
        this.regeneratePnp();
        this.getCtrlWiseAsmt();
        this.translate.get('Assessment saved successfully').subscribe(res => {
          this.toaster.showSuccess(res);
        });
      },
      (error: any) => {
        this.loading = false;
        this.backendErrorService.setBackendError(error);
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
          this.toaster.showError(this.backend_error);
        });
      }
    );
  }

  onselectedOrgTypeChange(guid: any) {
    this.selectedOrgType = guid;
  }

  getselectedOrgType(guid: any) {
    return guid == this.selectedOrgType ? true : false;
  }

  validateAnswers(questions: any) {
    for (let question of questions) {
      if (
        !question.answers ||
        question.answers.length === 0 ||
        (question.isOtherActive && !question.otherOption?.trim())
      ) {
        return true;
      }
      if (question.question_details.type === 'nested_questions' && question.answers[0].opt === 'Yes') {
        if (question.question_details.follow_up && question.question_details.follow_up.Yes) {
          for (let nestedQuestion of question.question_details.follow_up.Yes) {
            if (
              !nestedQuestion.answers ||
              nestedQuestion.answers.length === 0 ||
              (nestedQuestion.isOtherActive && !nestedQuestion.otherOption?.trim())
            ) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  isOptionSelected(option: any, answers: any[]): boolean {
    return answers?.find(answer => answer.opt == option.opt);
  }

  onCheckboxChange(event: any, option: any, group: any) {
    if (event.checked) {
      if (option?.opt?.toLowerCase() === 'other') {
        group.isOtherActive = true;
      }
      if (group.answers == null) {
        group.answers = [];
      }
      group.answers.push(option);
    } else {
      if (option?.opt?.toLowerCase() === 'other') {
        group.isOtherActive = false;
        group.otherOption = '';
      }
      const index = group?.answers?.findIndex((answer: any) => answer.opt == option.opt);
      if (index !== -1) {
        group.answers.splice(index, 1);
      }
    }
  }

  onRadioChange(event: any, option: any, group: any) {
    if (event?.value?.toLowerCase() === 'other') {
      group.isOtherActive = true;
    } else {
      group.isOtherActive = false;
      group.otherOption = '';
    }
    group.answers = [option];
  }

  compareAnswers(asmtResponse: any[], orgAssesment: any[]): boolean {
    if (asmtResponse.length !== orgAssesment.length) {
      return false;
    }

    for (let i = 0; i < asmtResponse.length; i++) {
      const asmtItem = asmtResponse[i];
      const orgItem = orgAssesment[i];
      if (orgItem.question_details.type === 'nested_questions' && orgItem.answers[0].opt == 'Yes') {
        if (!this.compareNestedAnswers(asmtItem, orgItem)) {
          return false;
        }
      } else {
        if (!this.arraysMatch(asmtItem.answers, orgItem.answers)) {
          return false;
        }
      }
    }
    return true;
  }

  // arraysMatch(arr1: any[], arr2: any[]): boolean {
  //   console.log(arr1,arr2)
  //   if (arr1.length !== arr2.length) {
  //     return false;
  //   }
  //   const sortedArr1 = arr1.slice().sort();
  //   const sortedArr2 = arr2.slice().sort();
  //   for (let i = 0; i < arr1.length; i++) {
  //     if (sortedArr1[i]?.opt !== sortedArr2[i]?.opt) {
  //       return false;
  //     }
  //   }

  //   return true;
  // }
  arraysMatch(arr1: any[], arr2: any[]): boolean {
    const normalize = (arr: any[]) =>
      arr
        .map(item => item.opt)
        .sort()
        .join('|');
    return normalize(arr1) === normalize(arr2);
  }
  compareNestedAnswers(asmtItem: any, orgItem: any): boolean {
    const nestedAsmtAnswers = asmtItem.question_details.follow_up['Yes'][0].answers;
    const nestedOrgAnswers = orgItem.question_details.follow_up['Yes'][0].answers;
    return this.arraysMatch(nestedAsmtAnswers, nestedOrgAnswers);
  }

  isBestResponse(best_response: any[], option: any) {
    return best_response.includes(option);
  }
}
