<div class="main-title-container">
  <div class="main-title">Integrations</div>
</div>
<app-spinner *ngIf="loading"></app-spinner>
<div class="search-menu-container ">
  <div class="search-people position-relative">
    <div class="input-group search-container">
      <div class="input-group">
        <input
          id="search"
          [(ngModel)]="search_title"
          class="form-control cb-input"
          placeholder="Search"
          name="search"
          type="text"
          (keyup)="updateSearchText($event)"
          (keyDown.enter)="searchByTitle()"
          #titleInput
        />
      </div>
      <i (click)="searchByTitle()" class="fa fa-search search-icon"></i>
    </div>
  </div>
  <div ngbDropdown class="d-inline-block">
    <!-- <button type="button" class="btn filter-btn" id="dropdownBasic1" ngbDropdownToggle>
      Cloud Providers
    </button>
    <div class="dropdown-opt" ngbDropdownMenu aria-labelledby="dropdownBasic1">
      <li ngbDropdownItem>Cloud Providers</li>
      <li ngbDropdownItem>Collaboration Tools</li>
      <li ngbDropdownItem>Networking</li>
      <li ngbDropdownItem>Database</li>
    </div> -->
  </div>
</div>

<div class="integrated-avaliable" *ngIf="!loading">INTEGRATED ({{ cloudProviders?.active_account?.length }})</div>

<ng-container *ngIf="cloudProviders && cloudProviders?.active_account?.length > 0">
  <app-cloud-integration-card
    (onManage)="onManage($event)"
    (onIntegrate)="onIntegrate($event)"
    [cloudProviders]="cloudProviders?.active_account"
    [isIntegrated]="true"
  >
  </app-cloud-integration-card>
</ng-container>

<div style="border: 1px solid #DEE2E6;margin-top: 16px;" *ngIf="!loading"></div>

<div class="integrated-avaliable" *ngIf="!loading">AVAILABLE ({{ cloudProviders?.available_account?.length }})</div>

<ng-container *ngIf="cloudProviders && cloudProviders?.available_account?.length > 0">
  <app-cloud-integration-card
    (onManage)="onManage($event)"
    (onIntegrate)="onIntegrate($event)"
    [cloudProviders]="cloudProviders?.available_account"
    [isIntegrated]="false"
  >
  </app-cloud-integration-card>
</ng-container>
