<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div class="modal-header cb-modal-header">
    <h4 class="modal-title font-weight-bold text-black-1" translate>add_event.Add Event</h4>
  </div>
  <div class="modal-body cb-modal-body">
    <form [formGroup]="eventForm">
      <div class="row mt-3">
        <div class="col-4">
          <label for="name" class="text-black-1" translate>add_event.Name of Event</label>
        </div>
        <div class="col-8">
          <div class="row">
            <div class="col-12 input-group">
              <input
                id="name"
                formControlName="name"
                class="form-control cb-input"
                placeholder="eg. Anti-virus and anti-spyware reports review"
                name="name"
                type="text"
                (blur)="makeSlackChannelName($event.target.value)"
              />
            </div>
          </div>
          <div class="row">
            <div
              class="col-12"
              *ngIf="(eventForm.controls.name.touched || submitted) && eventForm.controls.name.errors"
            >
              <label for="name" class="text-danger" *ngIf="eventForm.controls.name.errors.required" translate
                >add_event.*Event name is required</label
              >
            </div>
            <div class="col-12" *ngIf="error.name">
              <label for="purpose" class="text-danger" *ngIf="error.name" translate>*{{ error['name'] }}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-4">
          <label for="purpose" class="text-black-1" translate>add_event.Purpose</label>
        </div>
        <div class="col-8">
          <div class="row">
            <div class="col-12 input-group">
              <input
                id="purpose"
                formControlName="purpose"
                class="form-control cb-input"
                placeholder="eg. Review reports by nnti-virus and anti-spyware"
                name="purpose"
                type="text"
              />
            </div>
          </div>
          <div class="row">
            <div
              class="col-12"
              *ngIf="(eventForm.controls.purpose.touched || submitted) && eventForm.controls.purpose.errors"
            >
              <label for="purpose" class="text-danger" *ngIf="eventForm.controls.purpose.errors.required" translate
                >add_event.*Purpose is required</label
              >
            </div>
            <div class="col-12" *ngIf="error.purpose">
              <label for="purpose" class="text-danger" *ngIf="error.purpose" translate>*{{ error['purpose'] }}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-4">
          <label for="description" class="text-black-1" translate>add_event.Description</label>
        </div>
        <div class="col-8">
          <div class="row">
            <div class="col-12 input-group">
              <textarea
                id="description"
                class="form-control cb-input"
                formControlName="description"
                placeholder="eg. An event to review the anti-virus and anti-spyware reports daily."
              ></textarea>
            </div>
          </div>
          <div class="row">
            <div
              class="col-12"
              *ngIf="(eventForm.controls.description.touched || submitted) && eventForm.controls.description.errors"
            >
              <label for="name" class="text-danger" *ngIf="eventForm.controls.description.errors.required" translate
                >add_event.*Event description is required</label
              >
            </div>
            <div class="col-12" *ngIf="error.description">
              <label for="purpose" class="text-danger" *ngIf="error.description" translate
                >*{{ error['description'] }}</label
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-4">
          <label for="slack-name" class="text-black-1" translate>add_event.Slack Channel Name</label>
        </div>

        <div class="col-8">
          <div class="row">
            <div class="col-12 input-group">
              <input
                id="slack-name"
                class="form-control cb-input"
                placeholder="eg. anti-virus-spy-report"
                formControlName="channelName"
                type="text"
                [(ngModel)]="slack_channel_name"
              />
            </div>
          </div>
          <div class="row">
            <div
              class="col-12"
              *ngIf="(eventForm.controls.channelName.touched || submitted) && eventForm.controls.channelName.errors"
            >
              <label
                for="name"
                class="text-danger"
                *ngIf="
                  (eventForm.controls.channelName.pristine || submitted) &&
                  eventForm.controls.channelName.errors.required
                "
                translate
                >add_event.*Event channel name is required</label
              >
              <label for="name" class="text-danger" *ngIf="eventForm.controls.channelName.errors.nameError" translate
                >add_event.*Channel name may only contain lowercase letters, numbers, hyphens, and underscores, and must
                be 80 characters or less
              </label>
            </div>
            <div class="col-12" *ngIf="error.channelName">
              <label for="purpose" class="text-danger" *ngIf="error.channelName" translate
                >*{{ error['channelName'] }}</label
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-4">
          <label for="org_rev_committee_guid" class="text-black-1" translate>add_event.Committee</label>
        </div>
        <div class="col-8">
          <div class="row">
            <div class="col-12 input-group">
              <select
                class="form-control cb-input"
                id="org_rev_committee_guid"
                formControlName="org_rev_committee_guid"
              >
                <option value="" translate>add_event.Please Select a committee</option>
                <option *ngFor="let committee of committeeList" [value]="committee.org_review_com_guid">
                  {{ committee.committee_name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div
              class="col-12"
              *ngIf="
                (eventForm.controls.org_rev_committee_guid.touched || submitted) &&
                eventForm.controls.org_rev_committee_guid.errors
              "
            >
              <label
                for="name"
                class="text-danger"
                *ngIf="eventForm.controls.org_rev_committee_guid.errors.required"
                translate
                >add_event.Committee is required</label
              >
            </div>
            <div class="col-12" *ngIf="error.org_rev_committee_guid">
              <label for="purpose" class="text-danger" *ngIf="error.org_rev_committee_guid" translate
                >*{{ error['org_rev_committee_guid'] }}</label
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-4">
          <label for="org_compliance_program_guid" class="text-black-1" translate>add_event.Program</label>
        </div>
        <div class="col-8">
          <div class="row">
            <div class="col-12 input-group">
              <select
                class="form-control cb-input"
                formControlName="org_compliance_program_guid"
                id="org_compliance_program_guid"
              >
                <!-- [(ngModel)]="programList[0].guid" -->

                <option value="" translate>add_event.Please Select a program</option>
                <option *ngFor="let program of programList" [value]="program.guid">
                  {{ program.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div
              class="col-12"
              *ngIf="
                (eventForm.controls.org_compliance_program_guid.touched || submitted) &&
                eventForm.controls.org_compliance_program_guid.errors
              "
            >
              <label
                for="name"
                class="text-danger"
                *ngIf="eventForm.controls.org_compliance_program_guid.errors.required"
                translate
                >add_event.*Event program is required</label
              >
            </div>
          </div>
          <div class="col-12" *ngIf="error.org_compliance_program_guid">
            <label for="purpose" class="text-danger" *ngIf="error.org_compliance_program_guid" translate
              >*{{ error['org_compliance_program_guid'] }}</label
            >
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-4">
          <label class="text-black-1" translate>add_event.Event Status</label>
        </div>
        <div class="col-8">
          <div class="row">
            <div class="col-12 input-group pt-1">
              <label class="form-check-label radio-1 text-black-1 pl-4 pr-4" for="inlineRadio1">
                <input
                  class="form-check-input"
                  type="radio"
                  formControlName="event_status"
                  id="inlineRadio1"
                  value="enabled"
                  [(ngModel)]="default_event_status"
                />
                <span class="checkmark"></span>
                {{ 'add_event.Enable' | translate }}
              </label>
              <label class="form-check-label radio-1 text-black-1 pl-4 pr-4" for="inlineRadio2">
                <input
                  class="form-check-input pl-1"
                  type="radio"
                  formControlName="event_status"
                  id="inlineRadio2"
                  value="disabled"
                  [(ngModel)]="default_event_status"
                />
                <span class="checkmark"></span>
                {{ 'add_event.Disable' | translate }}
              </label>
              <label class="form-check-label radio-1 text-black-1 pl-4" for="inlineRadio3">
                <input
                  class="form-check-input pl-1"
                  type="radio"
                  formControlName="event_status"
                  id="inlineRadio3"
                  value="disregard"
                  [(ngModel)]="default_event_status"
                />
                <span class="checkmark"></span>
                {{ 'add_event.Disregard' | translate }}
              </label>
            </div>
          </div>
          <div class="row">
            <div
              class="col-12"
              *ngIf="(eventForm.controls.event_status.touched || submitted) && eventForm.controls.event_status.errors"
            >
              <label for="name" class="text-danger" *ngIf="eventForm.controls.event_status.errors.required" translate
                >add_event.*Event Status is required</label
              >
            </div>
            <div class="col-12" *ngIf="error.event_status">
              <label for="purpose" class="text-danger" *ngIf="error.event_status" translate
                >*{{ error['event_status'] }}</label
              >
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3 modal-body cb-modal-body">
        <div class="row">
          <div class="col-12">
            <label class="text-black-1" translate>add_event.Event Schedule</label>
          </div>
          <div class="col-12">
            <app-events-schedule [seperateDiag]="true"></app-events-schedule>
          </div>
        </div>
      </div>
      <!--    <div class="row mt-3 modal-body cb-modal-body" *ngIf="error">-->
      <!--      <div class="col-12 text-danger">{{ error }}</div>-->
      <!--    </div>-->

      <div class="row">
        <div class="col-12">
          <label for="first_name" class="text-black-1" translate>add_event.Review Documents</label>
        </div>
        <div class="input-group col" formArrayName="document_urls_FA">
          <div style="width: 100%; margin-top: 5px;" *ngFor="let opt of optionsData.controls; let i = index">
            <div class="row" [formGroupName]="i">
              <div class="col-1 d-flex-col position-doc-url-type">
                <img src="../../../../assets/images/logo/{{ opt.get('urltype').value }}.png" width="20px" />
              </div>
              <div class="col-5 d-flex-col">
                <label for="first_name" class="text-black-1" translate>add_event.Title</label>
                <input
                  id="title"
                  class="form-control cb-input"
                  placeholder="Review document title."
                  formControlName="title"
                  name="title"
                  type="text"
                />
                <div class="error-mssg" *ngIf="(opt.get('title').touched || submitted) && opt.get('title').errors">
                  <label for="title" class="text-danger" *ngIf="opt.get('title').errors"
                    >*This field is required.</label
                  >
                </div>
              </div>
              <div class="col-5 d-flex-col">
                <label for="first_name" class="text-black-1" translate>add_event.Url</label>
                <input
                  id="url"
                  class="form-control cb-input"
                  placeholder="Document link."
                  formControlName="url"
                  name="url"
                  type="text"
                  (keyup)="setDocumentUrlType(i)"
                />
                <div
                  class="error-mssg url-top-margin"
                  *ngIf="(opt.get('url').touched || submitted) && opt.get('url').errors"
                >
                  <label for="url" class="text-danger" *ngIf="opt.get('url').errors.url"
                    >*{{ opt.get('url').errors.url.msg }}.</label
                  >
                </div>
              </div>
              <div class="col-1 delete-box">
                <i
                  (click)="removeOption(i)"
                  class="fa fa-trash fa fa-trash btn-edit delete_doc_url"
                  aria-hidden="true"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- </ng-container> -->
    <div class="row mb-2">
      <div class="col-3">
        <p class="text-cyan add_document_url" (click)="addDocumentUrl()" aria-hidden="true" translate>
          add_event.+ Add URL
        </p>
      </div>
    </div>
    <div class="modal-footer border-0 cb-modal-footer">
      <button class="btn-cancel ml-4 w-100" (click)="close()" translate>add_event.Cancel</button>
      <button class="btn-save ml-4 w-100" (click)="submit()" translate>add_event.Submit</button>
    </div>
  </div>
</ng-container>
