<app-spinner *ngIf="submitted && isLoading"></app-spinner>
<div class="training-container">
  <div class="row">
    <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
      <h2 class="text-cyan m-0" style="cursor:default" translate>Document_Invite_Training_Groups.Page_Title</h2>
      <div>
        <button class="btn-cancel ml-4 w-100" [ngClass]="{ 'btn-cancel': true }" (click)="onReset()" translate>
          Create_Document.Btn_Clr
        </button>
        <button
          class="btn-save ml-4 w-100"
          [disabled]="
            InviteTrainingGroupsForm.invalid ||
            groupUserDisablity ||
            (!selectedGroupDocuments.length && !selectedDocuments.length && !externalDocuments.length)
          "
          [ngStyle]="{
            'background-color':
              InviteTrainingGroupsForm.invalid ||
              groupUserDisablity ||
              (!selectedGroupDocuments.length && !selectedDocuments.length && !externalDocuments.length)
                ? 'rgba(0,0,0,.26)'
                : '#4db7c6'
          }"
          [ngClass]="{ 'btn-save': true }"
          (click)="submit()"
          translate
        >
          Create_Document.Create
        </button>
      </div>
    </div>
  </div>
  <div class="row scroll-view">
    <div class="col">
      <ng-container>
        <div *ngIf="form_error">
          <div class="row mt-2" *ngIf="form_error.title">
            <div class="col-12 col-sm-12 col-lg-12 ml-5">
              <div class="text-danger" translate>* {{ form_error.title[0] }}</div>
            </div>
          </div>
        </div>
        <form [formGroup]="InviteTrainingGroupsForm" novalidate>
          <div class="row mt-4">
            <div class="col-2 col-sm-2 col-lg-2">
              <label for="title" class="ml-3 pt-2 cb-label text-black-3" translate
                >Document_Invite_Training_Groups.Title
              </label>
            </div>
            <div class="input-group col-9 col-sm-9 col-lg-9">
              <input
                id="title"
                [placeholder]="'Document_Invite_Training_Groups.Title' | translate"
                class="form-control cb-input"
                formControlName="title"
                autocomplete="title"
                required
                name="title"
                type="text"
                (keyup)="onChangeTitle($event)"
                [maxLength]="256"
                required
              />
              <div class="col-12 error-mssg" *ngIf="(f.title.touched || f.title.dirty) && f.title.errors">
                <label for="title" class="text-danger" *ngIf="f.title.errors.required" translate
                  >Document_Invite_Training_Groups.*Title is required.</label
                >
                <label for="title" class="text-danger" *ngIf="f.title.errors.maxLength" translate
                  >Document_Invite_Training_Groups.Title must be 255 characters or less</label
                >
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-2 col-sm-2 col-lg-2">
              <label for="description" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate
                >Document_Invite_Training_Groups.Description
              </label>
            </div>
            <div class="input-group col-9 col-sm-9 col-lg-9">
              <textarea
                id="description"
                class="form-control cb-input"
                [placeholder]="'Document_Invite_Training_Groups.Description' | translate"
                name="description"
                type="text"
                formControlName="description"
                cols="64"
                rows="5"
                [maxLength]="4097"
              ></textarea>
              <div
                class="col-12 error-mssg"
                *ngIf="(f.description.touched || f.description.dirty) && f.description.errors"
              >
                <label for="description" class="text-danger" *ngIf="f.description.errors.required" translate
                  >Document_Invite_Training_Groups.*Description is required.</label
                >
                <label for="description" class="text-danger" *ngIf="f.description.errors.maxLength" translate
                  >Document_Invite_Training_Groups.Description must be 4096 characters or less</label
                >
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-2 col-sm-2 col-lg-2">
              <label for="slack" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate
                >Document_Invite_Training_Groups.Welcome_Slack
              </label>
              <img class="slack" src="../../../../assets/images/slack/slack.png" />
            </div>
            <div class="input-group col-9 col-sm-9 col-lg-9">
              <textarea
                id="slack"
                class="form-control cb-input"
                [placeholder]="'Document_Invite_Training_Groups.Welcome_Slack' | translate"
                name="slack"
                type="text"
                formControlName="slack_message"
                [maxLength]="4097"
                cols="64"
                rows="5"
              ></textarea>
              <div
                class="col-12 error-mssg"
                *ngIf="(f.slack_message.touched || f.slack_message.dirty) && f.slack_message.errors"
              >
                <label for="slack_message" class="text-danger" *ngIf="f.slack_message.errors.maxLength" translate
                  >Document_Invite_Training_Groups.Message must be 4096 characters or less</label
                >
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-2 col-sm-2 col-lg-2">
              <label for="email" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate
                >Document_Invite_Training_Groups.Welcome_email
              </label>
              <mat-icon> email</mat-icon>
            </div>
            <div class="input-group col-9 col-sm-9 col-lg-9">
              <textarea
                id="email"
                class="form-control cb-input"
                [placeholder]="'Document_Invite_Training_Groups.Welcome_email' | translate"
                name="email"
                type="text"
                formControlName="email_message"
                [maxLength]="4097"
                cols="64"
                rows="5"
              ></textarea>
              <div
                class="col-12 error-mssg"
                *ngIf="(f.email_message.touched || f.email_message.dirty) && f.email_message.errors"
              >
                <label for="email_message" class="text-danger" *ngIf="f.email_message.errors.maxLength" translate
                  >Document_Invite_Training_Groups.Message must be 4096 characters or less</label
                >
              </div>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-2 col-sm-2 col-lg-2">
              <label for="start_date" class="ml-3 pt-2 cb-label text-black-3" translate
                >Document_Invite_Training_Groups.Start_Date
              </label>
            </div>
            <div class="input-group col-3 col-sm-3 col-lg-3">
              <input
                class="form-control cb-input"
                [placeholder]="'Document_Invite_Training_Groups.Date' | translate"
                formControlName="start_date"
                name="start_date"
                (dateSelect)="onChange($event, 'change')"
                ngbDatepicker
                #d="ngbDatepicker"
                (click)="d.toggle()"
                required
                [minDate]="minDate"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary fa fa-calendar-alt cb-input"
                  (click)="d.toggle()"
                  type="button"
                ></button>
              </div>
              <div class="col-12 error-mssg" *ngIf="f.start_date.touched && f.start_date.errors">
                <label for="start_date" class="text-danger" *ngIf="f.start_date.errors.required" translate>
                  Document_Invite_Training_Groups.*Start Date is required.</label
                >
                <label
                  for="start_date"
                  class="text-danger"
                  *ngIf="f.start_date.errors.DateGreaterEqualToday_error"
                  translate
                >
                  Document_Invite_Training_Groups.*Start Date must be today and greater than today.</label
                >
                <label
                  for="start_date"
                  class="text-danger"
                  *ngIf="!f.start_date.errors.invalid && dateRangeValidator()"
                  translate
                >
                  Document_Invite_Training_Groups.Start_date</label
                >
              </div>
              <div *ngIf="form_error">
                <div class="col-12 error-mssg" *ngIf="form_error.start_date">
                  <label class="text-danger" translate>* {{ form_error.start_date }}</label>
                </div>
                <div class="col-12 error-mssg" *ngIf="form_error.non_field_errors">
                  <label class="text-danger" translate>* {{ form_error.non_field_errors[0] }}</label>
                </div>
              </div>
            </div>
            <div class="col-2 col-sm-2 col-lg-2">
              <label for="end_date" class="ml-5 pt-2 cb-label text-black-3" translate
                >Document_Invite_Training_Groups.End_Date
              </label>
            </div>
            <div class="input-group col-3 col-sm-3 col-lg-3" style="margin-left: -2em;">
              <input
                class="form-control cb-input"
                [placeholder]="'Document_Invite_Training_Groups.Date' | translate"
                formControlName="end_date"
                name="end_date"
                (dateSelect)="onChange($event, 'change')"
                ngbDatepicker
                #d1="ngbDatepicker"
                (click)="d1.toggle()"
                required
                [minDate]="minDate"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary fa fa-calendar-alt cb-input"
                  (click)="d1.toggle()"
                  type="button"
                ></button>
              </div>
              <div class="col-12 error-mssg" *ngIf="f.end_date.touched && f.end_date.errors">
                <label for="end_date" class="text-danger" *ngIf="f.end_date.errors.required" translate>
                  Document_Invite_Training_Groups.*End Date is required.</label
                >
                <label for="end_date" class="text-danger" *ngIf="f.end_date.errors.DateTodayOrAfter_error" translate>
                  Document_Invite_Training_Groups.*End Date must be greater than today.</label
                >
                <label for="end_date" class="text-danger" *ngIf="!f.end_date.errors.invalid" translate>
                  Document_Invite_Training_Groups.End_date</label
                >
              </div>
              <div *ngIf="error">
                <div class="col-12 error-mssg" *ngIf="error.end_date">
                  <label class="text-danger" translate>* {{ error.end_date[0] }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-2 col-sm-2 col-lg-2">
              <label for="groups" class="ml-3 pt-2 cb-label text-black-3" translate
                >Document_Invite_Training_Groups.Select_Groups
              </label>
            </div>
            <div class="col-6" (click)="onGroupClick($event)">
              <ng-multiselect-dropdown
                class="multi-select-dropdown"
                [placeholder]="'Select trainee groups'"
                [data]="GroupsList"
                [(ngModel)]="selectedGroups"
                [settings]="dropdownSettingsForGroup"
                (onSelect)="onGroupSelect($event); updateUsersDisable($event)"
                (onDeSelect)="onDeSelectTrainingGroup($event)"
                [ngModelOptions]="{ standalone: true }"
              >
              </ng-multiselect-dropdown>
            </div>

            <div>
              <i class="fa fa-spinner fa-spin refresh" *ngIf="groupListLoader"></i>
            </div>
            <div *ngIf="error">
              <div class="col-12 error-mssg" *ngIf="error.groups">
                <label class="text-danger" translate>* {{ error.groups[0] }}</label>
              </div>
            </div>
            <div *ngIf="error">
              <div class="col-12 error-mssg" *ngIf="error[0]">
                <label class="text-danger" translate>* {{ error[0] }}</label>
              </div>
            </div>
            <div *ngIf="form_error">
              <div class="col-12 error-mssg" *ngIf="form_error.training_groups">
                <label class="text-danger" translate>* {{ form_error.training_groups[0] }}</label>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-2 col-sm-2 col-lg-2">
              <label for="documents" class="ml-3 pt-2 cb-label text-black-3" translate>Users </label>
            </div>
            <div class="col-6">
              <ng-multiselect-dropdown
                class="multi-select-dropdown"
                [placeholder]="'Select Users'"
                [settings]="dropdownSettingsForUsers"
                [data]="users"
                [(ngModel)]="assignedUsersDisabled"
                [ngModelOptions]="{ standalone: true }"
              >
              </ng-multiselect-dropdown>
            </div>
            <!-- <div>
            <i class="fa fa-spinner fa-spin refresh" *ngIf="userListLoading"></i>
          </div> -->
          </div>
          <!-- </div> -->
          <div class="row mt-4">
            <div class="col-2 col-sm-2 col-lg-2">
              <label for="groups" class="ml-3 pt-2 cb-label text-black-3" translate
                >Document_Invite_Training_Groups.Select_Documents_Groups
              </label>
            </div>
            <div class="col-6" (click)="onDocumentGroupClick($event)">
              <ng-multiselect-dropdown
                class="multi-select-dropdown"
                [placeholder]="'Select document groups'"
                [data]="DocumentsGroupList"
                [(ngModel)]="selectedGroupDocuments"
                [settings]="dropdownSettingsForDocumentsGroups"
                (onSelect)="onDocumentsGroupSelect($event)"
                (ngModelChange)="updateDocumentsDisable($event)"
                [ngModelOptions]="{ standalone: true }"
              >
              </ng-multiselect-dropdown>
            </div>
            <div>
              <i class="fa fa-spinner fa-spin refresh" *ngIf="documentGroupListLoader"></i>
            </div>
            <div *ngIf="error">
              <div class="col-12 error-mssg" *ngIf="error.documents">
                <label class="text-danger" translate>* {{ error.documents[0] }}</label>
              </div>
            </div>
            <div *ngIf="error">
              <div class="col-12 error-mssg" *ngIf="error[0]">
                <label class="text-danger" translate>* {{ error[0] }}</label>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-2 col-sm-2 col-lg-2">
              <label for="groups" class="ml-3 pt-2 cb-label text-black-3" translate
                >Document_Invite_Training_Groups.Select_Documents
              </label>
            </div>
            <div class="col-6">
              <ng-multiselect-dropdown
                class="multi-select-dropdown"
                [placeholder]="'Select documents'"
                [data]="DocumentsList"
                [(ngModel)]="selectedDocuments"
                [settings]="dropdownSettingsForDocuments"
                [ngModelOptions]="{ standalone: true }"
              >
              </ng-multiselect-dropdown>
            </div>
            <div>
              <i class="fa fa-spinner fa-spin refresh" *ngIf="documentListLoader"></i>
            </div>
            <div *ngIf="error">
              <div class="col-12 error-mssg" *ngIf="error.documents">
                <label class="text-danger" translate>* {{ error.documents[0] }}</label>
              </div>
            </div>
            <div *ngIf="error">
              <div class="col-12 error-mssg" *ngIf="error[0]">
                <label class="text-danger" translate>* {{ error[0] }}</label>
              </div>
            </div>
          </div>
          <!-- </div> -->

          <!-- </div> -->

          <div class="row mt-4">
            <div class="col-2 col-sm-2 col-lg-2">
              <label for="external_links" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate
                >Document_Invite_Training_Groups.External documents
                <i
                  class="fas fa-question-circle cb-tooltip-btn"
                  ngbTooltip="Add documents that are not managed by ComplianceBot."
                  triggers="hover:blur"
                  tooltipClass="cb-tooltip"
                  placement="bottom"
                ></i
              ></label>
            </div>
            <div
              class="input-group col"
              formArrayName="external_links"
              *ngIf="externalDocuments.controls.length > 0; else no_content"
            >
              <div style="width: 100%; margin-top: 5px;" *ngFor="let opt of externalDocuments.controls; let i = index">
                <div class="row" [formGroupName]="i" *ngIf="externalDocuments.controls.length > 0; else no_content">
                  <div class="col-2 d-flex-col">
                    <input
                      id="ext-doc-title"
                      class="form-control cb-input"
                      placeholder="Document title"
                      formControlName="title"
                      name="title"
                      type="text"
                      (ngModelChange)="modelChange($event)"
                    />
                    <div class="error-mssg" *ngIf="opt.get('title').touched && opt.get('title').errors">
                      <label for="title" class="text-danger" *ngIf="opt.get('title').errors"
                        >*This field is required.</label
                      >
                    </div>
                  </div>
                  <div class="col-5 d-flex-col">
                    <input
                      id="url"
                      class="form-control cb-input"
                      placeholder="Document link."
                      formControlName="url"
                      name="url"
                      type="text"
                      (ngModelChange)="urlChange($event)"
                    />
                    <div class="error-mssg url-top-margin" *ngIf="opt.get('url').touched && opt.get('url').errors">
                      <label for="url" class="text-danger" *ngIf="opt.get('url').errors.url">
                        *{{ opt.get('url').errors.url.msg }}
                      </label>
                    </div>
                    <div class="error-mssg url-top-margin" *ngIf="duplicateError && duplicateIndex === i">
                      <label for="url" class="text-danger">*Duplicate URL entered.</label>
                    </div>
                  </div>
                  <div class="col-1 delete-box">
                    <i
                      (click)="removeOption(i)"
                      class="fa fa-trash fa fa-trash btn-edit cursor-pointer mt-2"
                      aria-hidden="true"
                      style="font-size: 17px;"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <ng-template #no_content>
              <div class="col-9">
                <label
                  for="groups"
                  class="pt-2 mt-1 text-cyan cursor-pointer"
                  (click)="addDocumentUrl()"
                  translate
                  style="font-size: 14px;"
                >
                  Document_Invite_Training_Groups.Add_New_External_Document
                </label>

                <label
                  class="text-danger ml-3"
                  translate
                  *ngIf="selectedDocuments.length === 0 && selectedGroupDocuments.length === 0 && submitted"
                  >Document_Invite_Training_Groups.*Atleast one document is required.</label
                >
              </div>
            </ng-template>
          </div>
          <div class="row mt-4" *ngIf="externalDocuments.controls.length > 0">
            <div class="col-2"></div>
            <div class="col-9 justify-content-between d-flex">
              <button
                style="background-color: transparent;"
                for="groups"
                class="ml-1 text-cyan add_document_url cursor-pointer"
                (click)="addDocumentUrl()"
                [disabled]="disabledAddNew"
                [ngStyle]="{ color: disabledAddNew ? 'rgba(0,0,0,.26)' : '#4db7c6' }"
                translate
              >
                Document_Invite_Training_Groups.Add_External_Document
              </button>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-6 col-sm-12 col-lg-6">
              <div class="input-group pt-2 mb-2">
                <label for="Category" for="renewal_recurring" class="ml-3 mt-1 cb-label text-black-3" translate
                  >Create_Document.Recurring_renewal</label
                >
                <mat-checkbox
                  class="mt-1"
                  formControlName="renewal_recurring"
                  id="renewal_recurring"
                  name="renewal_recurring"
                  style="margin-left: 10em;"
                  (change)="(true)"
                  #checkBox
                ></mat-checkbox>
              </div>
            </div>
          </div>

          <div *ngIf="checkBox.checked">
            <div class="row">
              <div class="col-6 col-sm-12 col-lg-6">
                <span class="text-black-3 ml-3 mt-2 cb-label" translate>Create_Document.Sel_Rec</span>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-4 col-sm-6 col-lg-4">
                <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                  >Create_Document.Select_Time</label
                >
              </div>
              <div class="col-6 col-sm-6 col-lg-6">
                <div class="cb-input cb-time">
                  <i class="fa fa-clock"></i>
                  <input
                    placeholder="{{ this.time }}"
                    aria-label="Time"
                    class="time_input"
                    [ngxTimepicker]="darkPicker"
                    [value]="data.time"
                    readonly
                  />
                  <ngx-material-timepicker
                    class="timepicker"
                    #darkPicker
                    [ngxMaterialTimepickerTheme]="darkTheme"
                    (timeSet)="timeSet($event)"
                  ></ngx-material-timepicker>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-4 col-sm-6 col-lg-4">
                <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                  >Create_Document.Select_Schedule</label
                >
              </div>
              <div class="input-group col-6 col-sm-6 col-lg-6">
                <select
                  class="form-control cb-input"
                  [value]="data.renewal_schedule"
                  (change)="dataRefresh($event.target.value)"
                >
                  <option *ngFor="let schedule of schedules" [value]="schedule.value">
                    {{ schedule.display_value }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-4 col-sm-6 col-lg-4">
                <label class="text-black-3 ml-3 mt-2 cb-label-2" translate>Create_Document.Allowed_duration</label>
              </div>
              <div class="input-group col-6 col-sm-6 col-lg-6">
                <select
                  class="form-control cb-input"
                  [(ngModel)]="allowed_duration_selected"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <option value="1" *ngFor="let duration of allowed_duration" [value]="duration" translate>{{
                    duration
                  }}</option>
                </select>
              </div>
            </div>

            <div class="row mt-3" *ngIf="data.renewal_schedule === 'WEEKLY'">
              <div class="col-4 col-sm-6 col-lg-4">
                <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate>Create_Document.Day</label>
              </div>
              <div class="input-group col-6 col-sm-6 col-lg-6">
                <select
                  class="form-control cb-input"
                  [(ngModel)]="data.day_of_week"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <option *ngFor="let day of days" [value]="day.value">
                    {{ day.display_string }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row mt-3" *ngIf="data.renewal_schedule === 'BI-WEEKLY'">
              <div class="col-4 col-sm-6 col-lg-4">
                <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                  >Create_Document.Week</label
                >
              </div>
              <div class="input-group col-6 col-sm-6 col-lg-6">
                <select class="form-control cb-input" [(ngModel)]="data.week" [ngModelOptions]="{ standalone: true }">
                  <option *ngFor="let week of numbered_bi" [value]="week.value">
                    {{ week.display_string }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row mt-3" *ngIf="data.renewal_schedule === 'MONTHLY'">
              <div class="col-4 col-sm-6 col-lg-4">
                <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate>
                  Create_Document.Week_Month</label
                >
              </div>
              <div class="input-group col-6 col-sm-6 col-lg-6">
                <select class="form-control cb-input" [(ngModel)]="data.day" [ngModelOptions]="{ standalone: true }">
                  <option *ngFor="let week of numbered_week" [value]="week.value">
                    {{ week.display_string }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row mt-3" *ngIf="data.renewal_schedule === 'MONTHLY'">
              <div class="col-4 col-sm-6 col-lg-4">
                <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate>Create_Document.Day</label>
              </div>
              <div class="input-group col-6 col-sm-6 col-lg-6">
                <select
                  class="form-control cb-input"
                  [(ngModel)]="data.day_of_week"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <option *ngFor="let day of days" [value]="day.value">
                    {{ day.display_string }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row mt-3" *ngIf="data.renewal_schedule === 'BI-MONTHLY'">
              <div class="col-4 col-sm-6 col-lg-4">
                <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                  >Create_Document.Month</label
                >
              </div>
              <div class="input-group col-6 col-sm-6 col-lg-6">
                <select class="form-control cb-input" [(ngModel)]="data.month" [ngModelOptions]="{ standalone: true }">
                  <option *ngFor="let month of numbered_bi" [value]="month.value">
                    {{ month.display_string }}
                  </option>
                </select>
              </div>
              <div class="col-4 col-sm-6 col-lg-4">
                <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                  >Create_Document.Week_Month</label
                >
              </div>
              <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
                <select class="form-control cb-input" [(ngModel)]="data.day" [ngModelOptions]="{ standalone: true }">
                  <option *ngFor="let week of numbered_week" [value]="week.value">
                    {{ week.display_string }}
                  </option>
                </select>
              </div>
              <div class="col-4 col-sm-6 col-lg-4">
                <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate>Create_Document.Day</label>
              </div>
              <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
                <select
                  class="form-control cb-input"
                  [(ngModel)]="data.day_of_week"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <option *ngFor="let day of days" [value]="day.value" translate>
                    {{ day.display_string }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row mt-3" *ngIf="data.renewal_schedule === 'QUARTERLY'">
              <div class="col-4 col-sm-6 col-lg-4">
                <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                  >Create_Document.Month</label
                >
              </div>
              <div class="input-group col-6 col-sm-6 col-lg-6">
                <select class="form-control cb-input" [(ngModel)]="data.month" [ngModelOptions]="{ standalone: true }">
                  <option *ngFor="let month of numbered_quarter" [value]="month.value" translate>
                    {{ month.display_string }}
                  </option>
                </select>
              </div>
              <div class="col-4 col-sm-6 col-lg-4 mt-3">
                <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                  >Create_Document.Week_Month</label
                >
              </div>
              <div class="input-group col-6 col-sm-6 col-lg-6">
                <select class="form-control cb-input" [(ngModel)]="data.day" [ngModelOptions]="{ standalone: true }">
                  <option *ngFor="let week of numbered_week" [value]="week.value" translate>
                    {{ week.display_string }}
                  </option>
                </select>
              </div>
              <div class="col-4 col-sm-6 col-lg-4 mt-3">
                <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate>Create_Document.Day</label>
              </div>
              <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
                <select
                  class="form-control cb-input"
                  [(ngModel)]="data.day_of_week"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <option *ngFor="let day of days" [value]="day.value" translate>
                    {{ day.display_string }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row mt-3" *ngIf="data.renewal_schedule === 'SEMI-ANNUALLY'">
              <div class="col-4 col-sm-6 col-lg-4">
                <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                  >Create_Document.Month</label
                >
              </div>
              <div class="input-group col-6 col-sm-6 col-lg-6">
                <select class="form-control cb-input" [(ngModel)]="data.month" [ngModelOptions]="{ standalone: true }">
                  <option *ngFor="let months of months_half_year" [value]="months.value" translate>
                    {{ months.display_string }}
                  </option>
                </select>
              </div>
              <div class="col-4 col-sm-6 col-lg-4 mt-3">
                <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                  >Create_Document.Week_Month</label
                >
              </div>
              <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
                <select class="form-control cb-input" [(ngModel)]="data.day" [ngModelOptions]="{ standalone: true }">
                  <option *ngFor="let week of numbered_week" [value]="week.value" translate>
                    {{ week.display_string }}
                  </option>
                </select>
              </div>
              <div class="col-4 col-sm-6 col-lg-4 mt-3">
                <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate>Create_Document.Day</label>
              </div>
              <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
                <select
                  class="form-control cb-input"
                  [(ngModel)]="data.day_of_week"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <option *ngFor="let day of days" [value]="day.value" translate>
                    {{ day.display_string }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row mt-3" *ngIf="data.renewal_schedule === 'ANNUALLY'">
              <div class="col-4 col-sm-6 col-lg-4">
                <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                  >Create_Document.Month</label
                >
              </div>
              <div class="input-group col-6 col-sm-6 col-lg-6">
                <select class="form-control cb-input" [(ngModel)]="data.month" [ngModelOptions]="{ standalone: true }">
                  <option *ngFor="let months of months_year" [value]="months.value" translate>
                    {{ months.display_string }}
                  </option>
                </select>
              </div>
              <div class="col-4 col-sm-6 col-lg-4 mt-3">
                <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                  >Create_Document.Week_Month</label
                >
              </div>
              <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
                <select class="form-control cb-input" [(ngModel)]="data.day" [ngModelOptions]="{ standalone: true }">
                  <option *ngFor="let week of numbered_week" [value]="week.value" translate>
                    {{ week.display_string }}
                  </option>
                </select>
              </div>
              <div class="col-4 col-sm-6 col-lg-4 mt-3">
                <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate>Create_Document.Day</label>
              </div>
              <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
                <select
                  class="form-control cb-input"
                  [(ngModel)]="data.day_of_week"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <option *ngFor="let day of days" [value]="day.value" translate>
                    {{ day.display_string }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row mt-3" *ngIf="data.renewal_schedule === 'EVERY TWO YEARS'">
              <div class="col-4 col-sm-6 col-lg-4">
                <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                  >Create_Document.Year</label
                >
              </div>
              <div class="input-group col-6 col-sm-6 col-lg-6">
                <select class="form-control cb-input" [(ngModel)]="data.year" [ngModelOptions]="{ standalone: true }">
                  <option *ngFor="let year of numbered_bi" [value]="year.value" translate>
                    {{ year.display_string }}
                  </option>
                </select>
              </div>
              <div class="col-4 col-sm-6 col-lg-4 mt-3">
                <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                  >Create_Document.Month</label
                >
              </div>
              <div class="input-groupcol-5 col-6 col-sm-6 col-lg-6 mt-3">
                <select class="form-control cb-input" [(ngModel)]="data.month" [ngModelOptions]="{ standalone: true }">
                  <option *ngFor="let months of months_year" [value]="months.value" translate>
                    {{ months.display_string }}
                  </option>
                </select>
              </div>
              <div class="col-4 col-sm-6 col-lg-4 mt-3">
                <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                  >Create_Document.Week_Month</label
                >
              </div>
              <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
                <select class="form-control cb-input" [(ngModel)]="data.day" [ngModelOptions]="{ standalone: true }">
                  <option *ngFor="let week of numbered_week" [value]="week.value" translate>
                    {{ week.display_string }}
                  </option>
                </select>
              </div>
              <div class="col-4 col-sm-6 col-lg-4 mt-3">
                <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate>Create_Document.Day</label>
              </div>
              <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
                <select
                  class="form-control cb-input"
                  [(ngModel)]="data.day_of_week"
                  [ngModelOptions]="{ standalone: true }"
                >
                  <option *ngFor="let day of days" [value]="day.value" translate>
                    {{ day.display_string }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </ng-container>
    </div>
  </div>
</div>
