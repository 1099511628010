import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OrganisationService } from '@app/core/organisation.service';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { finalize, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { ModalService } from '@app/core/modal/modal.service';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { SettingsService } from '@app/settings/settings.service';
import { UserService } from '@app/core/user.service';
import { OrganisationAdminService } from '@app/Organisation-admin/organisation-admin.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { GoogleDriveUploadService } from '../google-drive-upload.service';
import * as _ from 'lodash';
import stringInfo from '../../../../translations/en-US.json';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { SidenavService } from '@app/core/modal/sidenav.service';
import { CustomsidenavComponent } from '@app/shared/modal/customsidenav/customsidenav.component';
@Component({
  selector: 'app-import-document-and-template',
  templateUrl: './import-document-and-template.component.html',
  styleUrls: ['./import-document-and-template.component.scss']
})
export class ImportDocumentAndTemplateComponent implements OnInit {
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  isImportDocument: boolean = false;
  fonts = ['italic 32px Pacifico', 'italic 32px Yellowtail', 'italic 32px Damion', 'italic 32px Lobster'];
  signature: any;
  file_size_error: boolean = false;
  loginUser: any;
  userName: any;
  @Input() dialogData: any = null;
  buttonDisable = true;
  userSignatureCheck: boolean = false;
  UserSignature: any = null;
  isSignatureLoading = true;
  signature_error: boolean = false;
  agreement_accepted: boolean = true;
  statusData: any;
  loading = true;
  isloading = false;
  docTitle = stringInfo.Create_Document.Doc_Title;
  time = stringInfo.Create_Document.Time;
  version = stringInfo.Create_Document.Version_Placeholder;
  FolderList: object[];
  SubFolderList: object[];
  document_types: any = [];
  OrganisationID: string;
  DocStoreguid: string;
  addDocumentForm: FormGroup;
  selectFolderForm: FormGroup;
  submitted = false;
  allowed_duration_data: {};
  allowed_duration_selected: string;
  allowed_duration: any = [];
  training_allowed_duration_data: {};
  training_allowed_duration_selected: string;
  training_allowed_duration: any = [];
  form_error: any;
  document_type: any;
  error_message: any;
  folder_name: any;
  template_type: any = '';
  selectedFolderDetails: any;

  oldFolderName: any;
  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#fff',
      buttonColor: '#FFFFFF'
    },
    dial: {
      dialBackgroundColor: '#555'
    },
    clockFace: {
      clockFaceBackgroundColor: '#fff',
      clockHandColor: 'black',
      clockFaceTimeInactiveColor: 'black'
    }
  };

  error: any;
  selected_option: any;
  subfolder: any[];
  checkedItem: string;
  parent_folder: any;
  template_name: any = '';
  template_guid: any;
  createDocStorageId: string = 'create_doc_folder_data';

  DocCreate_Options = [{ id: 'upload_document', name: 'Upload a document' }];
  document_guid: any;

  upload_template_name: any = '';
  checkFileFormat: any;
  guidSubscription: any;
  backend_error: any;
  time_12: string = '';
  selectedTemplateType: any;
  templateTypeForm = 'form';
  errorMessage = false;
  templateTypeList: any;

  DocOptionsChange(option: any) {
    this.selected_option = option;
  }

  openSelectTemplateForm() {
    this.SelectTemplateOpenModal();
  }

  SelectTemplateOpenModal(): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Select Template',
        modalType: 'Select Template'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
        } else {
        }
      });
  }

  schedules = [
    {
      value: 'SEMI-ANNUALLY',
      display_value: 'Semi Annually'
    },
    {
      value: 'ANNUALLY',
      display_value: 'Annually'
    },
    {
      value: 'EVERY TWO YEARS',
      display_value: 'Every Two Years'
    }
  ];

  numbered_week = [
    {
      display_string: 'first',
      value: '1st'
    },
    {
      display_string: 'second',
      value: '2nd'
    },
    {
      display_string: 'third',
      value: '3rd'
    },
    {
      display_string: 'fourth',
      value: '4th'
    },
    {
      display_string: 'last',
      value: 'last'
    }
  ];

  days = [
    {
      display_string: 'Monday',
      value: 'mon'
    },
    {
      display_string: 'Tuesday',
      value: 'tue'
    },
    {
      display_string: 'Wednesday',
      value: 'wed'
    },
    {
      display_string: 'Thursday',
      value: 'thu'
    },
    {
      display_string: 'Friday',
      value: 'fri'
    },
    {
      display_string: 'Saturday',
      value: 'sat'
    },
    {
      display_string: 'Sunday',
      value: 'sun'
    }
  ];

  numbered_bi = [
    {
      display_string: 'Odd',
      value: '1/2'
    },
    {
      display_string: 'Even',
      value: '2/2'
    }
  ];

  numbered_quarter = [
    {
      display_string: 'first',
      value: '1/3'
    },
    {
      display_string: 'second',
      value: '2/3'
    },
    {
      display_string: 'third',
      value: '3/3'
    }
  ];

  months_half_year = [
    {
      display_string: 'January And July',
      value: '1/6'
    },
    {
      display_string: 'February And August',
      value: '2/6'
    },
    {
      display_string: 'March And September',
      value: '3/6'
    },
    {
      display_string: 'April And October',
      value: '4/6'
    },
    {
      display_string: 'May And November',
      value: '5/6'
    },
    {
      display_string: 'June And December',
      value: '6/6'
    }
  ];

  months_year = [
    {
      display_string: 'January',
      value: '1'
    },
    {
      display_string: 'February',
      value: '2'
    },
    {
      display_string: 'March',
      value: '3'
    },
    {
      display_string: 'April',
      value: '4'
    },
    {
      display_string: 'May',
      value: '5'
    },
    {
      display_string: 'June',
      value: '6'
    },
    {
      display_string: 'July',
      value: '7'
    },
    {
      display_string: 'August',
      value: '8'
    },
    {
      display_string: 'September',
      value: '9'
    },
    {
      display_string: 'October',
      value: '10'
    },
    {
      display_string: 'November',
      value: '11'
    },
    {
      display_string: 'December',
      value: '12'
    }
  ];

  public data = {
    renewal_schedule: '',
    day_of_week: '',
    day: '',
    month: '*',
    year: '*',
    time: '09:00',
    week: ''
  };

  docx = 'docx';
  doc = 'doc';
  pptx = 'pptx';
  xlsx = 'xlsx';
  pdf = 'pdf';
  xls = 'xls';
  ppt = 'ppt';
  constructor(
    private backendErrorService: BackendErrorService,
    private orgAdminService: OrganisationAdminService,
    private formBuilder: FormBuilder,
    private orgService: OrganisationService,
    private documentsService: DocumentsService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private modalService: ModalService,
    private http: HttpClient,
    private router: Router,
    private settingsService: SettingsService,
    private googleDriveService: GoogleDriveUploadService,
    private userservice: UserService,
    private sidenavService: SidenavService
  ) {}

  // static convertTimeTo24Format(time: any) {
  //   return moment(time, ['h:mm A']).format('HH:mm');
  // }

  // convertTimeTo24Format(time: any) {
  //   return moment(time, ['h:mm A']).format('HH:mm');
  // }

  onChange(evt: any) {
    if (evt.type == 'keyup' && this.backend_error) {
      this.backend_error = '';
    }
  }

  get f() {
    return this.addDocumentForm.controls;
  }

  ngOnInit() {
    setTimeout(() => {
      document.getElementById('mySidenavImport').classList.toggle('open');
    }, 100);
    this.documentsService.isImportDocument.subscribe((isImportDocument: any) => {
      this.isImportDocument = isImportDocument;
    });
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
    });
    this.oldFolderName = this.folder_name;
    this.selected_option = 'select_doc_opt';
    localStorage.removeItem(this.createDocStorageId);
    this.selectFolderForm = this.formBuilder.group({
      select_folder: ['', [Validators.required()]],
      select_subfolder: ['']
    });
    this.getUserSignature();
    // this.getImportTemplateForm();
    this.getDocumentTypes();
    this.getDocumentStoreID();
    this.getTemplateTypeList();
    this.loginUser = this.userservice.getuserdetails();
    this.userName = this.loginUser.user['first_name'] + ' ' + this.loginUser.user['last_name'];
    this.addDocumentForm = this.formBuilder.group({
      title: ['', [Validators.required(), Validators.maxLength(255)]],
      description: ['', [Validators.required(), Validators.maxLength(4096)]],
      document_type: [''],
      version: ['', [Validators.required(), Validators.restrictZeroNumber()]],
      renewal_recurring: [''],
      file: [''],
      agreement_accepted: ['', Validators.required],
      remarks: [''],
      remarks_check: [''],
      remark: ['', Validators.maxLength(4096)],
      template_type: ['']
    });

    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
      this.documentsService.getOrganizationFolderList(this.OrganisationID).subscribe(
        data => {
          this.FolderList = data.results;
        },
        error => {
          this.error = error.error.message;
        }
      );
      this.documentsService.getOrganizationDocumentTypesList().subscribe(data => {
        this.document_types = data;
      });
    });

    this.documentsService.getDocStoreID(this.OrganisationID).subscribe(
      data => {
        this.DocStoreguid = data['organization_document_store_guid'];
      },
      error => {
        this.error = error.error.message;
      }
    );

    if (this.dialogData) {
      this.data = _.cloneDeep(this.dialogData.event.schedule);
      this.getAllowedDuration(this.dialogData.event.schedule.renewal_schedule, this.dialogData.event.allowed_duration);
      if (_.indexOf(this.dialogData.event.schedule.day, ' ') > 0) {
        const split_day = this.data.day.split(' ');
        this.data.day = split_day[0];
        this.data.day_of_week = split_day[1];
      }
      if (this.data.renewal_schedule === 'EVERY TWO YEARS') {
        const year_data = this.data.year.split('/');
        if (+year_data[0] % 2 === 1) {
          this.data.year = '1/2';
        } else {
          this.data.year = '2/2';
        }
      }
    } else {
      this.getAllowedDuration();
      this.data = {
        renewal_schedule: 'SEMI-ANNUALLY',
        time: '09:00',
        day_of_week: 'mon',
        week: null,
        day: '1st',
        month: '1/6',
        year: '*'
      };
    }
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
    localStorage.removeItem(this.createDocStorageId);
  }

  onReset() {
    this.submitted = false;
    this.addDocumentForm.reset();
    this.folder_name = '';
    this.template_name = '';
    this.selected_option = '';
    localStorage.removeItem(this.createDocStorageId);
    this.backend_error = '';
  }

  dataRefresh(event: any) {
    const renewal_schedule: string = event;
    const time: string = this.data.time;
    switch (renewal_schedule) {
      case 'WEEKLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: null,
          month: '*',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'BI-WEEKLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: null,
          month: '*',
          year: '*',
          time: time,
          week: '1/2'
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'MONTHLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '*',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'BI-MONTHLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1/2',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'QUARTERLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1/3',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'SEMI-ANNUALLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1/6',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'ANNUALLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'EVERY TWO YEARS':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1',
          year: '1/2',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      default:
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: null,
          day: null,
          month: '*',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
    }
  }

  getAllowedDuration(schedule?: string, duration_value?: string) {
    this.isloading = true;
    this.orgAdminService.getEventAllowedDuration().subscribe(result => {
      this.isloading = false;
      if (result && result.allowed_durations) {
        this.allowed_duration_data = result.allowed_durations;
        this.allowed_duration = schedule
          ? this.getCurrentAllowedDuration(result.allowed_durations, schedule)
          : this.getCurrentAllowedDuration(result.allowed_durations, 'SEMI-ANNUALLY');
        this.allowed_duration_selected = duration_value ? duration_value : '1 Day';
      }
    });
  }

  getCurrentAllowedDuration(data: {}, type: string) {
    for (const key in data) {
      if (`${key}` === type) {
        return `${data[key]}`.split(',');
      }
    }
  }

  timeSet(time: any) {
    this.data.time = time;
  }

  getPreparedData() {
    const schedule_data = _.cloneDeep(this.data);
    schedule_data['allowed_duration'] = this.allowed_duration_selected;
    if (schedule_data.renewal_schedule === 'DAILY') {
      schedule_data.day_of_week = 'mon-fri';
    } else if (schedule_data.renewal_schedule === 'EVERY TWO YEARS') {
      const year_data = schedule_data.year.split('/');
      if (+year_data[0] % 2 === 1) {
        schedule_data.year = '2019/2';
      } else {
        schedule_data.year = '2020/2';
      }
    }

    if (schedule_data.day !== null && schedule_data.day_of_week) {
      schedule_data.day = schedule_data.day + ' ' + schedule_data.day_of_week;
      schedule_data.day_of_week = null;
    }

    return this.orgAdminSchedularData(Object.assign({}, schedule_data));
  }

  public orgAdminSchedularData(scheduler_data: any): object {
    for (const key in scheduler_data) {
      if (scheduler_data.hasOwnProperty(key) && scheduler_data[key] === '') {
        scheduler_data[key] = null;
      } else {
      }
    }
    return scheduler_data;
  }

  file: any;
  size: any;
  triggerFileInput() {
    this.fileInput.nativeElement.click();
  }
  fileEvent(input: any) {
    if (input.type == 'change' && this.backend_error) {
      this.backend_error = '';
    }
    this.file = input.target.files[0];
    this.document_type = this.file.name.substr(this.file.name.lastIndexOf('.') + 1);
    this.size = this.file.size;
    if (
      this.document_type == 'docx' ||
      this.document_type == 'doc' ||
      this.document_type == 'pptx' ||
      this.document_type == 'xlsx' ||
      this.document_type == 'pdf'
    ) {
      this.upload_error_message = '';
    } else {
      this.upload_error_message = 'Please select .docx,.ppt,.xlsx,.pdf format only.';
    }
  }

  fileEvents(input: any) {
    this.upload_error_message = '';
    this.errorMessage = false;
    if (input.type == 'change' && this.backend_error) {
      this.backend_error = '';
    }
    this.file = input.target.files[0];
    this.file = input.target.files[0];
    if (this.file) {
      this.buttonDisable = false;
    } else {
      this.buttonDisable = true;
    }
    this.document_type = this.file.name.substr(this.file.name.lastIndexOf('.') + 1);
    this.size = this.file.size;
    if (
      this.selectedTemplateType == this.templateTypeForm &&
      (this.document_type == this.doc || this.document_type == this.pptx || this.document_type == this.pdf)
    ) {
      this.upload_error_message = 'Please select .docx,.xlsx format only.';
    } else if (!this.isValidFileType(this.document_type)) {
      this.upload_error_message = 'Please select .docx,.ppt,.xlsx,.pdf format only.';
    }
  }

  isValidFileType(checkFileFormat: any) {
    let validType = false;
    if (
      checkFileFormat == this.docx ||
      checkFileFormat == this.doc ||
      checkFileFormat == this.pptx ||
      checkFileFormat == this.xlsx ||
      checkFileFormat == this.pdf
    ) {
      validType = true;
    }
    return validType;
  }

  ngAfterViewInit() {
    this.documentsService.shareSelectedFolder$.subscribe(shareSelectedFolder => {
      let storedFolderObj = localStorage.getItem(this.createDocStorageId);
      if (storedFolderObj) {
        let storedFolderData = JSON.parse(storedFolderObj);
        this.selectedFolderDetails = shareSelectedFolder;
        this.folder_name = storedFolderData.folderPath;
        this.parent_folder = storedFolderData.selectedFolderGuid;
      }
    });
    this.documentsService
      .getTemplateName()
      .subscribe((item: any) => (this.template_name = this.documentsService.getTemplate()));
    this.documentsService
      .getTemplateName()
      .subscribe((item: any) => (this.template_guid = this.documentsService.getTemplateGuid()));
  }

  upload_error_message = '* Please upload document.';

  submit() {
    if (this.folder_name != this.oldFolderName) {
      this.backend_error = '';
    }
    this.submitted = true;

    if (this.addDocumentForm.valid) {
      this.isloading = true;
      const document_data = Object.assign({}, this.addDocumentForm.value);

      document_data['org_document_store'] = this.DocStoreguid;

      if (document_data.renewal_recurring) {
        document_data['renewal_schedule'] = this.getPreparedData();
      } else {
        document_data['renewal_schedule'] = null;
      }

      if (this.folder_name !== '') {
        let data = this.folder_name;
        document_data['parent_folder'] = this.parent_folder;
      } else {
        let data1 = this.folder_name;
        document_data['parent_folder'] = this.parent_folder;
      }
      this.error_message = '';
      this.createDocumentUploadPayload(document_data);
      // if (document_data['agreement_accepted']) {
      //   this.agreement_accepted = true;
      // this.modalService
      //   .custom(CustomDialogComponent, {
      //     title: 'Import Document',
      //     modalType: 'Import Document',
      //     docTitle: document_data.title
      //   })
      //   .pipe(take(1)) // take() manages unsubscription for us
      //   .subscribe((result: any) => {
      //     if (result) {
      //       this.submitted = false;

      //       this.createDocumentUploadPayload(document_data);
      //     }
      //   });

      localStorage.removeItem(this.createDocStorageId);
      // } else {
      this.submitted = false;
      // this.agreement_accepted = false;
      // if (!this.UserSignature) {
      //   this.signature_error = true;
      // } else {
      //   this.signature_error = false;
      // }
      // return;
    }
  }

  createDocumentUploadPayload(document_data: any): void {
    if (this.isImportDocument) {
      this.isloading = true;
      const data = {
        title: document_data.title,
        filename: this.file.name,
        size: this.size,
        folder_guid: document_data['parent_folder']
      };
      this.checkFileFormat = data.filename.substr(this.file.name.lastIndexOf('.') + 1);
      if (
        this.checkFileFormat == 'docx' ||
        this.checkFileFormat == 'doc' ||
        this.checkFileFormat == 'pptx' ||
        this.checkFileFormat == 'xlsx' ||
        this.checkFileFormat == 'pdf'
      ) {
        this.upload_error_message = '';
        this.documentsService.uploadDocument(this.OrganisationID, data).subscribe(
          async (data: any) => {
            // this.isloading = false;
            if (data['store_type'] == 'GOOGLE_DRIVE') {
              document_data['document_type'] = data.type;
              try {
                const { fileId, fileType } = await this.googleDriveService.uploadFileAndGetInfo(
                  this.file,
                  data['temp_token'],
                  data['folder_id'],
                  document_data.title
                );
                document_data['file'] = fileId;
                this.submitCreateDocumentData(document_data);
                // Handle successful upload
              } catch (error) {
                this.backend_error = error;
                this.toaster.showError(this.backend_error);
                console.error('Error uploading file:', error);
              }
            } else {
              let attributes = {
                name: data.title,
                parent: { id: data.restricted_to }
              };
              document_data['document_type'] = data.type;
              this.documentsService.uploadDocumentToBox(data.url, this.file, data.access_token, attributes).subscribe(
                (box: any) => {
                  document_data['file'] = box.entries[0].id;
                  this.submitCreateDocumentData(document_data);
                },
                (error: any) => {
                  this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
                  this.backendErrorService.backendError$.subscribe(error => {
                    this.backend_error = error;
                    this.toaster.showError(this.backend_error);
                  });
                }
              );
            }
          },
          (error: any) => {
            this.isloading = false;
            this.submitted = false;
            this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
            this.backendErrorService.backendError$.subscribe(error => {
              this.backend_error = error;
              this.toaster.showError(this.backend_error);
            });
          }
        );
      }
    } else {
      this.isloading = true;
      const data = {
        title: document_data.title,
        filename: this.file.name,
        size: this.size,
        template_type: document_data.template_type,
        is_template: true
      };
      this.checkFileFormat = data.filename.substr(this.file.name.lastIndexOf('.') + 1);
      if (this.isValidFileType(this.checkFileFormat)) {
        this.upload_error_message = '';
        this.documentsService.uploadDocument(this.OrganisationID, data).subscribe(
          async (data: any) => {
            if (data['store_type'] == 'GOOGLE_DRIVE') {
              document_data['document_type'] = data.type;
              try {
                const { fileId, fileType } = await this.googleDriveService.uploadFileAndGetInfo(
                  this.file,
                  data['temp_token'],
                  data['folder_id'],
                  document_data.title
                );
                document_data['file'] = fileId;
                this.submitCreateDocumentData(document_data);
                // Handle successful upload
              } catch (error) {
                this.backend_error = error;
                this.toaster.showError(this.backend_error);
                console.error('Error uploading file:', error);
              }
              // this.isloading = false;
            } else {
              let attributes = {
                name: data.title,
                parent: { id: data.restricted_to }
              };
              document_data['document_type'] = data.type;
              this.documentsService.uploadDocumentToBox(data.url, this.file, data.access_token, attributes).subscribe(
                (box: any) => {
                  document_data['file'] = box.entries[0].id;
                  this.submitCreateDocumentData(document_data);
                },
                (error: any) => {
                  this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
                  this.backendErrorService.backendError$.subscribe(error => {
                    this.backend_error = error;
                    this.toaster.showError(this.backend_error);
                  });
                }
              );
            }
          },
          (error: any) => {
            this.isloading = false;
            this.submitted = false;
            this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
            this.backendErrorService.backendError$.subscribe(error => {
              this.backend_error = error;
              this.sidenavService.updateData(this.backend_error, true);
            });
          }
        );
      }
    }
  }

  submitCreateDocumentData(document_data: any): void {
    delete document_data['folder'];
    if (this.isImportDocument) {
      delete document_data['template_type'];
    } else {
      delete document_data['parent_folder'];
      document_data['is_template'] = true;
    }
    if (this.addDocumentForm.get('renewal_recurring')?.value) {
      document_data.renewal_schedule.time = this.time_12;
    }
    this.documentsService.submitOrganisationImportDocumentData(this.OrganisationID, document_data).subscribe(
      data => {
        this.isloading = false;
        this.error = {
          title: '',
          version: '',
          folder: ''
        };
        let msg = this.isImportDocument
          ? 'Approve_Changes.approve_changes_toaster_message.upload'
          : 'Approve_Changes.approve_changes_toaster_message.Upload_Template';
        this.translate.get(msg).subscribe(res => {
          this.toaster.showSuccess(res);
          this.addDocumentForm.reset();
          this.selectFolderForm.reset();
          this.folder_name = '';
          this.template_name = '';
          this.selected_option = '';
          this.backend_error = '';
        });
        this.sidenavService.updateData({ isUpdate: true, result: data });
        this.sidenavService.close(false);
      },
      (error: any) => {
        this.isloading = false;
        this.submitted = false;
        this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
          this.sidenavService.updateData(this.backend_error, true);
        });
      }
    );
  }
  goToSignatureUpload() {
    this.router.navigate(['organisation/settings/profile']);
  }
  SelectFolderDialog(): void {
    if (this.backend_error) {
      this.backend_error = '';
    }
    this.documentsService.setSelectedFolderData(this.selectedFolderDetails);
    this.sidenavService
      .open(CustomsidenavComponent, {
        title: 'Select a folder',
        storage_id: this.createDocStorageId,
        modalType: 'document'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {});
  }

  //upload signature

  OpenChangeSignatureModal(): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Upload signature',
        modalType: 'Upload signature'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getUserSignature();
          this.translate.get('organisation_profile.profile_toaster_message.signature_uploaded').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  OpenGenerateSignatureModal(user_name: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Generate Signature',
        modalType: 'Generate Signature',
        user_name: user_name,
        google_fonts: this.fonts
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getUserSignature();
          this.translate.get('organisation_profile.profile_toaster_message.signature_uploaded').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  OpenDrawSignatureModal(): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Draw Signature',
        modalType: 'Draw Signature'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getUserSignature();
          this.translate.get('organisation_profile.profile_toaster_message.signature_uploaded').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }
  getUserSignature() {
    this.settingsService.getUserSignature(this.OrganisationID).subscribe(
      data => {
        this.UserSignature = data.url;
        if (!this.UserSignature) {
          this.uploadSignature();
        }
        this.isSignatureLoading = false;
        this.loading = false;
      },
      error => {
        this.isSignatureLoading = false;
      }
    );
  }
  uploadSignature() {
    this.isSignatureLoading = true;
    this.signature = this.settingsService.GenerateInitialSignature(this.userName, this.fonts);
    this.file = this.convertBase64ToFile(this.signature[2], 'signature');
    this.size = this.file.size;
    this.file_size_error = false;
    if (this.size < 1024 * 1024 * 5 || this.size == 1024 * 1024 * 5) {
      this.file_size_error = false;
    } else {
      this.file_size_error = true;
    }
    const data = { size: this.size, comment: '' };
    this.settingsService.uploadUserSignature(this.OrganisationID, data).subscribe((data: any) => {
      this.settingsService.uploadUserSignatureToGCP(data.url, this.file).subscribe((data: any) => {
        this.getUserSignature();
      });
    });
  }
  convertBase64ToFile(base64String: any, fileName: any) {
    let arr = base64String.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let uint8Array = new Uint8Array(n);
    while (n--) {
      uint8Array[n] = bstr.charCodeAt(n);
    }
    let file = new File([uint8Array], fileName, { type: mime });
    return file;
  }

  convertTo12HourFormat(time24: string): string {
    const [hour, minute] = time24.split(':').map(Number);
    const meridian = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 === 0 ? 12 : hour % 12;
    return `${hour12.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${meridian}`;
  }

  onChangeTime(event: any): void {
    // const input = event.target as HTMLInputElement;
    // this.data.time = input.value;
    const time24 = event.target.value;
    const time12 = this.convertTo12HourFormat(time24);
    this.time_12 = time12;
    this.data.time = time24;
  }

  onTemplateTypeChange(event: any) {
    this.errorMessage = false;
    this.selectedTemplateType = event.target.value;
    this.template_type = event.target.value;
    if (this.selectedTemplateType == this.templateTypeForm) {
      if (this.document_type == this.doc || this.document_type == this.pptx || this.document_type == this.pdf) {
        this.errorMessage = true;
        this.fileInput.nativeElement.value = null;
      }
    }
  }

  getDocumentTypes() {
    this.isloading = true;
    this.documentsService.getOrganizationDocumentTypesList().subscribe(data => {
      this.document_types = data;
      this.isloading = false;
    });
  }

  getDocumentStoreID() {
    this.documentsService.getDocStoreID(this.OrganisationID).subscribe(
      data => {
        this.DocStoreguid = data['organization_document_store_guid'];
      },
      error => {
        this.error = error.error.message;
      }
    );
  }

  getTemplateTypeList() {
    this.documentsService.getTemplateTypeList(this.OrganisationID).subscribe((data: any) => {
      this.templateTypeList = data;
      this.isloading = false;
      this.selectedTemplateType = this.templateTypeList[0]['value'];
    });
  }

  closeNav() {
    this.backend_error = '';
    this.addDocumentForm.reset();
    this.sidenavService.close(true);
  }
}
