import { Component, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { OrganisationService } from '@app/core/organisation.service';
import { OrganisationAdminService } from '@app/Organisation-admin/organisation-admin.service';
import { ActivatedRoute } from '@angular/router';
import { SpinnerService } from '@app/shared/spinner/spinner.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit, OnDestroy {
  OrganisationID: string | null;
  Events: object[];
  all_url_types: Array<object>;
  error: any;
  orgSelect = true;
  guidSubscription: any;
  searchText: any;
  is_editable = false;
  schedules: any = [];
  program: any = [];
  schedule_filter: string;
  programFilter: string;
  role: any;
  userRole: any;
  key: any;
  schedule_loading = false;
  search_title: any;
  status: any = [];
  statusFilter: string;
  parentTypeMessage = 'organisation-not-selected';
  loading = true;
  scrollLoader = false;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  page = 1;
  pagnationData: any;
  paginatedDataUrl: any;
  selectedProgram: any;
  constructor(
    private orgService: OrganisationService,
    private orgAdminService: OrganisationAdminService,
    private activatedRoute: ActivatedRoute,
    public spinner: SpinnerService
  ) {
    this.is_editable = this.activatedRoute.data['is_editable'];
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  ngOnInit() {
    // this.spinner.show();
    this.getRole();
    this.loading = false;
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      //  this.userOnbboard();
      this.getScheduleTypes(guid);
      this.getProgramTypes(guid);
    });
    this.orgAdminService.getUrlTypes().subscribe(data => {
      this.all_url_types = data;
    });
  }

  onScrollUp() {
    this.scrollLoader = false;
  }

  onScrollData() {
    if (this.search_title === undefined) {
      this.search_title = '';
    }
    if (this.schedule_filter == null || (undefined && this.selectedProgram == null) || undefined) {
      this.schedule_filter = '';
      this.selectedProgram = '';
      this.orgAdminService
        .getOrganizationMeetingWithStatus(
          this.OrganisationID,
          (this.schedule_filter = this.schedule_filter),
          (this.page += 1),
          this.search_title,
          this.selectedProgram
        )
        .subscribe(
          data => {
            this.scrollLoader = false;
            this.paginatedDataUrl = data;
            this.pagnationData = data.results;
            this.Events = this.Events.concat(data.results);
          },
          error => {
            this.scrollLoader = false;
            this.error = error.error.message;
          }
        );
    } else {
      this.orgAdminService
        .getOrganizationMeetingWithStatus(
          this.OrganisationID,
          this.schedule_filter,
          (this.page += 1),
          this.search_title,
          this.selectedProgram
        )
        .subscribe(
          data => {
            this.scrollLoader = false;
            this.paginatedDataUrl = data;
            this.pagnationData = data.results;
            this.Events = this.Events.concat(data.results);
          },
          error => {
            this.scrollLoader = false;
            this.error = error.error.message;
          }
        );
    }
  }
  onScroll() {
    if (this.paginatedDataUrl?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }
  updateSearchText(x: any) {
    this.search_title = x.target.value;
    if (this.search_title == undefined) {
      this.search_title = '';
    }
  }

  Search(search_title: string) {
    this.search_title = search_title;
    for (let i = 0; i < this.Events.length; i++) {
      if (this.Events[i]['title'] != this.search_title) {
        this.page = 1;
      }
    }
    if (this.schedule_filter === undefined) {
      this.schedule_filter = '';
    }
    if (this.selectedProgram === undefined) {
      this.selectedProgram = '';
    }
    this.orgAdminService
      .getOrganizationMeetingWithStatus(
        this.OrganisationID,
        this.schedule_filter,
        (this.page = 1),
        this.search_title,
        this.selectedProgram
      )
      .subscribe(
        data => {
          this.loading = false;
          this.Events = data.results;
        },
        error => {
          this.error = error.error.message;
          this.loading = false;
          console.log('Error:', error.error.message);
        }
      );
  }

  getRole() {
    this.role = JSON.parse(localStorage.getItem('credentials'));
    this.userRole = this.role['user']['role'];
    for (let i = 0; i < 10; i++) {
      if (this.userRole[i] == 'system_admin_role') {
        this.key = this.userRole[i];
        console.log('key', this.key);
      }
    }
  }

  getScheduleTypes(guid: string) {
    if (this.OrganisationID !== null) {
      this.schedule_loading = true;
      this.orgAdminService.getOrganisationScheduleTypes(guid).subscribe(data => {
        this.schedules = _.map(data, schedule => {
          let display_value = schedule['value'].replace('-', ' ');
          display_value = display_value.charAt(0).toUpperCase() + display_value.slice(1).toLowerCase();
          return {
            display_value: display_value,
            value: schedule['name']
          };
        });
        if (_.findIndex(this.schedules, ['value', this.schedule_filter]) < 0) {
          this.schedule_filter = '';
          console.log('filter' + this.schedule_filter);
          this.change_schedule('');
        }
        this.schedule_loading = false;
      });
    }
  }

  getProgramTypes(guid: any) {
    this.orgAdminService;
    this.orgAdminService.getOrganisationProgramTypes(guid).subscribe(
      data => {
        this.loading = false;
        this.program = data;
        this.programFilter = '';
      },
      (error: any) => {
        this.error = error;
      }
    );
  }
  changeProgram(progarmtype: any) {
    this.selectedProgram = progarmtype.target.value;
    this.getAllEvents();
  }

  load_schedule_list() {
    this.getScheduleTypes(this.OrganisationID);
  }

  change_schedule(schedule: any) {
    this.getAllEvents();
  }

  // userOnbboard() {
  //   if (this.OrganisationID !== null) {
  //     this.orgSelect = true;
  //     this.orgAdminService
  //       .isOrganisationOnboarded(this.OrganisationID)
  //       .pipe()
  //       .subscribe(data => {
  //         const isOrganisationOnboarded = data.isorganizationonboarded;
  //         this.orgService.setIsOrganisationOnboarded(isOrganisationOnboarded);
  //         if (!isOrganisationOnboarded) {
  //           this.router.navigateByUrl('/home');
  //         }
  //       });
  //   } else {
  //     this.orgSelect = false;
  //   }
  // }

  private getAllEvents() {
    // if(this.schedule_filter == ""){
    if (this.search_title === undefined) {
      this.search_title = '';
    }
    this.orgAdminService
      .getOrganizationMeetingWithStatus(
        this.OrganisationID,
        this.schedule_filter,
        (this.page = 1),
        this.search_title,
        this.selectedProgram
      )
      .subscribe(
        data => {
          this.loading = false;
          this.Events = data.results;
          this.paginatedDataUrl = data;
        },
        error => {
          this.error = error.error.message;
          this.loading = false;
          console.log('Error:', error.error.message);
        }
      );
  }
}
