import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { SlackOauthComponent } from './slack-oauth.component';
import { RouterModule } from '@angular/router';

import { AboutComponent } from '@app/about/about.component';
import { AboutModule } from '@app/about/about.module';
import { DemoMaterialModule } from '../materials.module';
import { SlackOauthRoutingModule } from '@app/slack-oauth/slack-oauth-routing.module';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, SlackOauthRoutingModule, DemoMaterialModule],
  declarations: [SlackOauthComponent]
})
export class SlackOauthModule {}
