import { TrainingCampaign } from './../training-report-page/training-report-page.component';
import { Component, OnInit, Input, ChangeDetectorRef, Type } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { OrganisationService } from '@app/core/organisation.service';
import { ModalService } from '@app/core/modal/modal.service';
import { take, map } from 'rxjs/operators';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { DocumentsService } from '../documents.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { OrganisationAdminService } from '@app/Organisation-admin/organisation-admin.service';
import { SettingsService } from '@app/settings/settings.service';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { TranslateService } from '@ngx-translate/core';
declare var require: any;
const moment = require('moment');
@Component({
  selector: 'app-edit-training-campaign',
  templateUrl: './edit-training-campaign.component.html',
  styleUrls: ['./edit-training-campaign.component.scss']
})
export class EditTrainingCampaignComponent implements OnInit {
  buttonReopenDisabled: boolean = true;
  actionReopen: any;
  backend_error: any;
  reopenDisabled: any = false;
  duplicateIndex: number | null = null;
  duplicateError: any = false;
  DocumentsGroupList: any;
  documentsGroupsList: any = [];
  updateddocumentsGroupsList: any = [];
  dropdownSettingsForDocumentsGroups = {};
  minDate: NgbDateStruct;
  formUnchanged = true;
  checkRecurrence: any = true;
  disable: any = true;
  maxDate: NgbDateStruct;
  selectedDocumentsCopy: any = [];
  campaignForm: FormGroup;
  campaignDetails: any[];
  submitted = false;
  isLoading = false;
  loading = false;
  start_date: any = '';
  end_date: any = '';
  success: boolean;
  organisationID: string | null;
  guidSubscription: any;
  default_doc_url_type: any;
  error_message: any;
  selected_option: any;
  groupsList: any = [];
  groupsList1: any = [];
  documentsList: any;
  documentsList1: any = [];
  disabledAddNew = false;
  error: {};
  form_error: any;
  externalTitle: any;
  externalLink: any = [];
  dropdownList: any = [];
  selectedGroups: any = [];
  selectedGroupDisabledData: any = [];
  selectedDocumentDisabledData: any = [];
  selectedDocumentGroupDisabled: any = [];
  selectedGroupDocuments: any = [];
  selectedDocuments: any = [];
  selectedUpdatedGroups: any = [];
  documentListLoader = false;
  documentGroupListLoader = false;
  gropuListLoader = false;
  unSelectedDocuments: any = [];
  unSelectedGroupDocuments: any = [];
  dropdownSettingsForDocuments = {};
  dropdownSettingsForGroup = {};
  documentGroupSelected = true;
  singleExternalLink: string;
  singleExternalTitle: string;
  camGuid: string;
  isDisabled = false;
  selectedGroups1: any = [];
  disableTextbox = false;
  externalData: any = [];
  updatedGroupsList: any = [];
  updatedDocumentsList: any = [];
  startDate: any;
  allowed_duration_selected: string;
  disabled = false;
  users: any = [];
  public data = {
    renewal_schedule: '',
    day_of_week: '',
    day: '',
    month: '*',
    year: '*',
    time: '',
    week: ''
  };

  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#fff',
      buttonColor: '#FFFFFF'
    },
    dial: {
      dialBackgroundColor: '#555'
    },
    clockFace: {
      clockFaceBackgroundColor: '#fff',
      clockHandColor: 'black',
      clockFaceTimeInactiveColor: 'black'
    }
  };

  allowed_duration_data: {};
  allowed_duration: any = [];
  training_allowed_duration_data: {};
  training_allowed_duration_selected: string;
  training_allowed_duration: any = [];
  trainingCampaignGroupUsers: any = [];

  @Input() dialogData: any = null;

  schedules = [
    {
      value: 'SEMI-ANNUALLY',
      display_value: 'Semi Annually'
    },
    {
      value: 'ANNUALLY',
      display_value: 'Annually'
    },
    {
      value: 'EVERY TWO YEARS',
      display_value: 'Every Two Years'
    }
  ];

  numbered_week = [
    {
      display_string: 'first',
      value: '1st'
    },
    {
      display_string: 'second',
      value: '2nd'
    },
    {
      display_string: 'third',
      value: '3rd'
    },
    {
      display_string: 'fourth',
      value: '4th'
    },
    {
      display_string: 'last',
      value: 'last'
    }
  ];

  days = [
    {
      display_string: 'Monday',
      value: 'mon'
    },
    {
      display_string: 'Tuesday',
      value: 'tue'
    },
    {
      display_string: 'Wednesday',
      value: 'wed'
    },
    {
      display_string: 'Thursday',
      value: 'thu'
    },
    {
      display_string: 'Friday',
      value: 'fri'
    },
    {
      display_string: 'Saturday',
      value: 'sat'
    },
    {
      display_string: 'Sunday',
      value: 'sun'
    }
  ];

  numbered_bi = [
    {
      display_string: 'Odd',
      value: '1/2'
    },
    {
      display_string: 'Even',
      value: '2/2'
    }
  ];

  numbered_quarter = [
    {
      display_string: 'first',
      value: '1/3'
    },
    {
      display_string: 'second',
      value: '2/3'
    },
    {
      display_string: 'third',
      value: '3/3'
    }
  ];

  months_half_year = [
    {
      display_string: 'January And July',
      value: '1/6'
    },
    {
      display_string: 'February And August',
      value: '2/6'
    },
    {
      display_string: 'March And September',
      value: '3/6'
    },
    {
      display_string: 'April And October',
      value: '4/6'
    },
    {
      display_string: 'May And November',
      value: '5/6'
    },
    {
      display_string: 'June And December',
      value: '6/6'
    }
  ];

  months_year = [
    {
      display_string: 'January',
      value: '1'
    },
    {
      display_string: 'February',
      value: '2'
    },
    {
      display_string: 'March',
      value: '3'
    },
    {
      display_string: 'April',
      value: '4'
    },
    {
      display_string: 'May',
      value: '5'
    },
    {
      display_string: 'June',
      value: '6'
    },
    {
      display_string: 'July',
      value: '7'
    },
    {
      display_string: 'August',
      value: '8'
    },
    {
      display_string: 'September',
      value: '9'
    },
    {
      display_string: 'October',
      value: '10'
    },
    {
      display_string: 'November',
      value: '11'
    },
    {
      display_string: 'December',
      value: '12'
    }
  ];
  recurring_renwal: any;
  renewalRecurring: any;
  recurringSchedule: any;
  recurringMonth: any;
  recurringDay: any;
  recurringWeek: any;
  time: any;
  isChecked = false;
  year: any;
  getTitle: any;
  getUrl: any;
  unassignedUsers: any = [];
  unassignedGroups: any = [];
  dropdownSettingsForUsers = {};
  selectedTrainingUsers: any = [];
  userListLoading = false;
  assignedUsers: any = [];
  assignedUsersDisabled: any = [];
  currentAssignedUsers: any = [];
  currentAssignedDocuments: any = [];
  selectedDocumentGroupsGuids: any = [];
  selectedUserGuids: any = [];
  permanentDisabledUsersGuids: any = [];
  permanentDisabledDocumentsGuids: any = [];
  removeUsersFromOngoingTraining: string[] = [];

  constructor(
    private orgService: OrganisationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private documentsService: DocumentsService,
    private route: ActivatedRoute,
    private orgAdminService: OrganisationAdminService,
    private settingsService: SettingsService,
    private backendErrorService: BackendErrorService,
    private translate: TranslateService,
    private toaster: ToasterService
  ) {}
  static convertTimeTo24Format(time: any) {
    return moment(time, ['h:mm A']).format('HH:mm');
  }

  convertTimeTo24Format(time: any) {
    return moment(time, ['h:mm A']).format('HH:mm');
  }

  ngOnInit() {
    this.dropdownSettingsForDocuments = {
      singleSelection: false,
      idField: 'id',
      textField: 'text',
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.dropdownSettingsForGroup = {
      singleSelection: false,
      idField: 'id',
      textField: 'text',
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.dropdownSettingsForDocumentsGroups = {
      singleSelection: false,
      idField: 'id',
      textField: 'text',
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.dropdownSettingsForUsers = {
      singleSelection: false,
      idField: 'id',
      textField: 'text',
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.camGuid = this.route.snapshot.queryParamMap.get('guid');
    this.actionReopen = this.route.snapshot.queryParamMap.get('action');
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.organisationID = guid;
      if (this.camGuid) {
        this.getCampaignDetails(this.camGuid);
        this.reopenDisabled = this.actionReopen === 'Reopen Campaign' ? true : false;

        const currentDate = new Date();
        this.minDate = {
          year: currentDate.getFullYear(),
          month: currentDate.getMonth() + 1,
          day: currentDate.getDate()
        };
      }
    });
    this.campaignForm = this.formBuilder.group({
      title: [
        { value: '', disabled: this.actionReopen === 'Reopen' },
        [Validators.required, Validators.maxLength(255)]
      ],
      description: ['', [Validators.required(), Validators.maxLength(4096)]],
      external_links: this.formBuilder.array([]),
      start_date: [' ', [Validators.required(), Validators.DateGreaterEqualToday(), this.dateRangeValidator]],
      end_date: [' ', [Validators.required(), Validators.DateIsAfterToday(), this.dateRangeValidator]],
      renewal_recurring: ['']
    });

    this.default_doc_url_type = 'Others';
  }
  getAllowedDuration(schedule?: string, duration_value?: string) {
    this.orgAdminService.getEventAllowedDuration().subscribe(result => {
      if (result && result.allowed_durations) {
        this.allowed_duration_data = result.allowed_durations;
        this.allowed_duration = schedule
          ? this.getCurrentAllowedDuration(result.allowed_durations, schedule)
          : this.getCurrentAllowedDuration(result.allowed_durations, 'SEMI-ANNUALLY');
        this.allowed_duration_selected = duration_value ? duration_value : '1 Day';
      }
    });
  }
  getCurrentAllowedDuration(data: {}, type: string) {
    for (const key in data) {
      if (`${key}` === type) {
        return `${data[key]}`.split(',');
      }
    }
  }
  onChangeForm(event: any) {
    this.disable = false;
    if (event.type == 'keyup' && event.target.value == '') {
      this.disable = true;
      this.buttonReopenDisabled = false;
    }
  }

  onUserChange(event: any) {
    if (event.type == 'change') {
      this.buttonReopenDisabled = false;
    }
  }

  deleteUserFromOngoingTraining(event: any) {
    const user = this.campaignDetails['assigned_users'].find((x: any) => x.guid == event.id);
    if (user?.user_guid) {
      this.removeUsersFromOngoingTraining.push(event.id);
    }
  }

  removeUserFromOngoingTraining() {
    this.documentsService
      .removeUserFromOngoingTraining(this.organisationID, this.camGuid, {
        users: this.removeUsersFromOngoingTraining
      })
      .subscribe(
        data => {
          this.toaster.showSuccess(data.message);
        },
        error => {
          let err = '';
          if (error.error.non_field_errors) {
            err = error.error.non_field_errors[0];
          } else if (error.error.detail) {
            err = error.error.detail;
          } else if (error.error) {
            err = error.error;
          } else if (error.error.message) {
            err = error.error.message;
          }
          this.toaster.showError(err);
          console.log('Error:', error.detail);
        }
      );
  }

  getPreparedData() {
    const schedule_data = _.cloneDeep(this.data);
    schedule_data['allowed_duration'] = this.allowed_duration_selected;

    if (schedule_data.renewal_schedule === 'DAILY') {
      schedule_data.day_of_week = 'mon-fri';
    } else if (schedule_data.renewal_schedule === 'EVERY TWO YEARS') {
      const year_data = schedule_data.year.split('/');
      if (+year_data[0] % 2 === 1) {
        schedule_data.year = '2019/2';
      } else {
        schedule_data.year = '2020/2';
      }
    }

    if (schedule_data.day !== null && schedule_data.day_of_week) {
      schedule_data.day = schedule_data.day + ' ' + schedule_data.day_of_week;
      schedule_data.day_of_week = null;
    }

    return this.orgAdminSchedularData(Object.assign({}, schedule_data));
  }

  public orgAdminSchedularData(scheduler_data: any): object {
    for (const key in scheduler_data) {
      if (scheduler_data.hasOwnProperty(key) && scheduler_data[key] === '') {
        scheduler_data[key] = null;
      } else {
      }
    }
    return scheduler_data;
  }

  timeSet(time: any) {
    this.disable = false;
    this.data.time = time;
  }

  dataRefresh(event: any) {
    this.disable = false;
    const renewal_schedule: string = event;
    const time: string = this.data.time;
    switch (renewal_schedule) {
      case 'WEEKLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: null,
          month: '*',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'BI-WEEKLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: null,
          month: '*',
          year: '*',
          time: time,
          week: '1/2'
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'MONTHLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '*',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'BI-MONTHLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1/2',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'QUARTERLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1/3',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'SEMI-ANNUALLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1/6',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'ANNUALLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'EVERY TWO YEARS':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1',
          year: '1/2',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      default:
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: null,
          day: null,
          month: '*',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
    }
  }
  getCampaignDataForm() {
    if (this.campaignDetails['status'] == 'in_progress') {
      this.campaignForm = this.formBuilder.group({
        title: [
          this.campaignDetails ? (this.campaignDetails['title'] ? this.campaignDetails['title'] : '') : '',
          [Validators.required(), Validators.maxLength(255)]
        ],
        description: [
          this.campaignDetails ? (this.campaignDetails['description'] ? this.campaignDetails['description'] : '') : '',
          [Validators.required()]
        ],
        external_links: this.formBuilder.array(this.init_docs_urls()),
        start_date: [this.start_date],
        end_date: [this.end_date, [Validators.required(), Validators.DateIsAfterToday(), this.dateRangeValidator]],

        renewal_recurring: [
          this.campaignDetails
            ? this.campaignDetails['renewal_schedule']
              ? this.campaignDetails['renewal_schedule']
              : ''
            : '',
          [Validators.required()]
        ]
      });
    } else {
      this.campaignForm = this.formBuilder.group({
        title: [
          this.campaignDetails
            ? {
                disabled: this.reopenDisabled,
                value: this.campaignDetails['title'] ? this.campaignDetails['title'] : ''
              }
            : '',
          [Validators.required(), Validators.maxLength(255)]
        ],
        description: [
          this.campaignDetails
            ? {
                disabled: this.reopenDisabled,
                value: this.campaignDetails['description'] ? this.campaignDetails['description'] : ''
              }
            : '',
          [Validators.required(), Validators.maxLength(4096)]
        ],
        external_links: this.formBuilder.array(this.init_docs_urls()),
        start_date: [
          { value: this.start_date, disabled: this.reopenDisabled },
          [Validators.required(), Validators.DateGreaterEqualToday(), this.dateRangeValidator]
        ],
        end_date: [this.end_date, [Validators.required(), Validators.DateIsAfterToday(), this.dateRangeValidator]],

        assigned_users: [],

        renewal_recurring: [
          this.campaignDetails
            ? {
                value: this.campaignDetails['renewal_schedule'] ? this.campaignDetails['renewal_schedule'] : '',
                disabled: this.reopenDisabled
              }
            : '',
          [Validators.required()]
        ],
        slack_message: ['', Validators.maxLength(4096)],
        email_message: ['', Validators.maxLength(4096)]
      });
    }
  }

  onDocumentClick(item: any) {
    if (this.documentsList) {
      this.documentListLoader = false;
    } else {
      this.documentListLoader = true;
    }
  }
  onDocumentSelect(item: any) {
    this.disable = false;
  }

  onDocumentGroupSelect(item: any) {
    this.disable = false;
  }
  onGroupSelect(item: any) {
    this.disable = false;
    this.checkGroupSelection();
  }
  onGroupClick(item: any) {
    if (this.groupsList) {
      this.gropuListLoader = false;
    } else {
      this.gropuListLoader = true;
    }
    this.checkGroupSelection();
  }

  checkGroupSelection() {
    if (this.form_error?.training_groups) {
      this.form_error.training_groups = '';
    }
  }
  get f() {
    return this.campaignForm?.controls;
  }

  removeOption(i: number) {
    this.optionsData.removeAt(i);
    this.disabledAddNew = false;
    this.disabled = false;
  }
  private getCampaignDetails(camGuid: string) {
    this.documentsService.retriveCampaignData(this.organisationID, camGuid).subscribe(
      data => {
        this.campaignDetails = data;
        this.renewalRecurring = this.campaignDetails['renewal_schedule'].length;
        this.getCampaignDataForm();
        if (this.campaignDetails['renewal_schedule'].length > 0) {
          this.data = _.cloneDeep(this.campaignDetails['renewal_schedule'][0]);
          this.getAllowedDuration(
            this.campaignDetails['renewal_schedule'][0]['renewal_schedule'],
            this.campaignDetails['renewal_schedule'][0]['allowed_duration']
          );

          if (_.indexOf(this.campaignDetails['renewal_schedule'][0]['day'], ' ') > 0) {
            const split_day = this.data.day.split(' ');
            this.data.day = split_day[0];
            this.data.day_of_week = split_day[1];
          }
          if (this.data.renewal_schedule === 'EVERY TWO YEARS') {
            const year_data = this.data.year.split('/');
            if (+year_data[0] % 2 === 1) {
              this.data.year = '1/2';
            } else {
              this.data.year = '2/2';
            }
          }
        } else {
          this.getAllowedDuration();
          this.data = {
            renewal_schedule: 'SEMI-ANNUALLY',
            time: '09:00 AM',
            day_of_week: 'mon',
            week: null,
            day: '1st',
            month: '1/6',
            year: '*'
          };
        }
        this.camGuid = this.campaignDetails['guid'];
        if (this.renewalRecurring <= 0) {
          this.campaignDetails['renewal_schedule'] = null;
        }

        if (this.campaignDetails['status'] == 'in_progress') {
          this.disableTextbox = true;
        }
        this.externalLink = [];
        for (let i = 0; i < data.documents.length; i++) {
          if (this.campaignDetails['documents'][i]['type'] == 'external') {
            this.externalLink.push({
              link: this.campaignDetails['documents'][i]['external_link'],
              title: this.campaignDetails['documents'][i]['name']
            });
          }
        }
        const start_date_arr = data['start_date'].split('-');
        this.start_date = new NgbDate(
          parseInt(start_date_arr[0]),
          parseInt(start_date_arr[1]),
          parseInt(start_date_arr[2])
        );
        const end_date_arr = data['end_date'].split('-');
        this.end_date = new NgbDate(parseInt(end_date_arr[0]), parseInt(end_date_arr[1]), parseInt(end_date_arr[2]));
        this.getCampaignDataForm();
        this.ShowSelectedGroups();
        this.ShowSelectedDocuments();
        this.ShowSelectedDocumentsGroups();
        this.documentsService.getAllgroups(this.organisationID).subscribe(data => {
          this.groupsList = data;
          this.gropuListLoader = false;
          this.userListLoading = true;
          this.groupsList = this.groupsList.filter((item: any) => item.users_count > 0);

          for (let group of this.groupsList) {
            group = { id: group.guid, text: group.title };
            let selected_group_guids = this.selectedGroups.map((x: any) => x.id);
            if (selected_group_guids.includes(group.id)) {
              group['isDisabled'] = true;
            } else {
              group['isDisabled'] = false;
            }
            this.groupsList1.push(group);
            this.updatedGroupsList = this.groupsList1;
          }
          for (let group of this.selectedGroups) {
            group['isDisabled'] = true;
          }
          this.selectedGroupDisabledData = this.selectedGroups.map((item: any) => {
            const container = {};
            container['isDisabled'] = true;
            container['text'] = item.text;
            container['id'] = item.id;
            return container;
          });
          this.selectedGroupDisabledData.forEach((item: any) => {
            for (let el of this.selectedGroups) {
              if (item.id == el.id) {
                item['isDisabled'] = true;
              }
            }
          });

          this.updatedGroupsList.sort((a: any, b: any) => {
            const isSelectedA = this.selectedGroupDisabledData.map((x: any) => x.id).includes(a.id);
            const isSelectedB = this.selectedGroupDisabledData.map((x: any) => x.id).includes(b.id);
            if (isSelectedA && !isSelectedB) {
              return -1;
            } else if (!isSelectedA && isSelectedB) {
              return 1;
            } else {
              return 0;
            }
          });

          this.selectedUserGuids = this.campaignDetails['assigned_users'].map((x: any) => x.guid);
          this.settingsService
            .getTrainingGroupUserList(
              this.organisationID,
              true,
              this.campaignDetails['groups'].map((x: any) => x.guid)
            )
            .subscribe((data: any) => {
              this.permanentDisabledUsersGuids = [
                ...new Set(data.map((item: any) => item.users.map((user: any) => user.guid)).flat())
              ];

              this.settingsService.getOrganizationPeopleListLite(this.organisationID, true).subscribe(
                (res: any) => {
                  this.userListLoading = false;
                  this.users = res.data;
                  let users = this.users.map((user: any) => {
                    return {
                      id: user.guid,
                      text: user.first_name + ' ' + user.last_name + ' (' + user.email + ')',
                      isDisabled: false
                      // this.selectedUserGuids.includes(user.guid) ||
                      // this.permanentDisabledUsersGuids.includes(user.guid)
                    };
                  });
                  let usersGroups = new Array();
                  this.campaignDetails['groups'].forEach((group: any) => {
                    usersGroups.push(...group.users);
                  });
                  this.campaignDetails['assigned_users'] = [...this.campaignDetails['assigned_users'], ...usersGroups];
                  this.assignedUsersDisabled = this.campaignDetails['assigned_users'].map((item: any) => {
                    return { isDisabled: false, text: item.name, id: item.guid };
                  });
                  this.assignedUsersDisabled = this.assignedUsersDisabled;
                  this.currentAssignedUsers = JSON.parse(JSON.stringify(this.assignedUsersDisabled));
                  users = [...users, ...this.assignedUsersDisabled];
                  users = users.filter((v: any, i: any, a: any) => a.findIndex((v2: any) => v2.id === v.id) === i); // duplicate remove
                  this.unassignedUsers = users;
                  this.unassignedUsers.sort((a: any, b: any) => {
                    const isSelectedA = this.assignedUsersDisabled.map((x: any) => x.id).includes(a.id);
                    const isSelectedB = this.assignedUsersDisabled.map((x: any) => x.id).includes(b.id);
                    if (isSelectedA && !isSelectedB) {
                      return -1;
                    } else if (!isSelectedA && isSelectedB) {
                      return 1;
                    } else {
                      return 0;
                    }
                  });
                  this.userListLoading = false;
                },
                error => {
                  this.userListLoading = false;
                }
              );
            });
        });

        this.documentsService.getAllDocumentGroups(this.organisationID).subscribe(data => {
          this.documentListLoader = true;
          this.documentGroupListLoader = false;
          this.DocumentsGroupList = data.filter((item: any) => item.doc_count > 0);
          this.selectedDocumentGroupsGuids = this.campaignDetails['document_groups'].map((x: any) => x.guid);
          this.documentsService
            .getDocumentList(this.organisationID, 1, true, '', this.selectedDocumentGroupsGuids)
            .subscribe((data: any) => {
              this.permanentDisabledDocumentsGuids = [
                ...new Set(data.map((item: any) => item.documents.map((doc: any) => doc.doc_version_guid)).flat())
              ];
            });
          this.updateddocumentsGroupsList = this.DocumentsGroupList.map((group: any) => {
            return {
              id: group.guid,
              text: group.title,
              isDisabled: this.selectedDocumentGroupsGuids.includes(group.guid)
            };
          });
          this.selectedDocumentGroupDisabled = this.campaignDetails['document_groups'].map((group: any) => {
            return { isDisabled: true, text: group.title, id: group.guid };
          });

          this.updateddocumentsGroupsList.sort((a: any, b: any) => {
            const isSelectedA = this.selectedDocumentGroupDisabled.map((x: any) => x.id).includes(a.id);
            const isSelectedB = this.selectedDocumentGroupDisabled.map((x: any) => x.id).includes(b.id);
            if (isSelectedA && !isSelectedB) {
              return -1;
            } else if (!isSelectedA && isSelectedB) {
              return 1;
            } else {
              return 0;
            }
          });

          this.documentsService.getAllPublishedDocsLite(this.organisationID, true).subscribe(data => {
            this.documentsList = data;
            this.documentListLoader = false;
            let selectedDocumentsGuids = this.campaignDetails['documents'].map((x: any) => x.document_version_guid);
            let documents = this.documentsList.map((doc: any) => {
              return {
                id: doc.document_version,
                text: `${doc.title} \n (${doc.version})`,
                isDisabled:
                  selectedDocumentsGuids.includes(doc.document_version) ||
                  this.permanentDisabledDocumentsGuids.includes(doc.document_version)
              };
            });
            this.currentAssignedDocuments = [];
            this.campaignDetails['documents'].map((doc: any) => {
              if (doc.type == 'cb_document') {
                this.currentAssignedDocuments.push({
                  isDisabled: true,
                  text: `${doc.name} \n (${doc.version})`,
                  id: doc.document_version_guid
                });
              }
            });
            this.selectedDocumentDisabledData = JSON.parse(JSON.stringify(this.currentAssignedDocuments));
            documents = [...documents, ...this.selectedDocumentDisabledData];
            documents = documents.filter((v: any, i: any, a: any) => a.findIndex((v2: any) => v2.id === v.id) === i); // duplicate remove
            this.updatedDocumentsList = documents;

            this.updatedDocumentsList.sort((a: any, b: any) => {
              const isSelectedA = this.selectedDocumentDisabledData.map((x: any) => x.id).includes(a.id);
              const isSelectedB = this.selectedDocumentDisabledData.map((x: any) => x.id).includes(b.id);
              if (isSelectedA && !isSelectedB) {
                return -1;
              } else if (!isSelectedA && isSelectedB) {
                return 1;
              } else {
                return 0;
              }
            });
          });
        });
      },
      error => {
        this.error = error.error.message;
      }
    );
  }

  onUserSelect(event: any): void {
    if (event.id) {
      this.buttonReopenDisabled = false;
      if (this.removeUsersFromOngoingTraining.includes(event.id)) {
        const index = this.removeUsersFromOngoingTraining.indexOf(event.id);
        if (index !== -1) {
          this.removeUsersFromOngoingTraining.splice(index, 0);
        }
      }
    }
    this.disable = false;
  }

  onUserDeSelect(event: any): void {
    const user = this.campaignDetails['assigned_users'].find((x: any) => x.guid == event.id);
    if (user?.user_guid) {
      this.removeUsersFromOngoingTraining.push(event.id);
    }
    if (event.id && this.assignedUsersDisabled.length == 0) {
      this.buttonReopenDisabled = true;
    }
    this.currentAssignedUsers = this.currentAssignedUsers.filter((u: any) => u.id !== event.id);
  }

  changeRecurrence(event: any) {
    this.disable = false;
  }
  updateUsersDisable(event: any) {
    this.settingsService.getTrainingGroupUserList(this.organisationID, true, [event.id]).subscribe(data => {
      let groupUser: any[] = [...new Set(data.map((item: any) => item?.users.map((user: any) => user.guid)).flat())];
      this.trainingCampaignGroupUsers = [...groupUser];
      let users = new Array();
      data[0].users?.forEach((user: any) => {
        if (!this.assignedUsersDisabled.map((x: any) => x.id).includes(user.guid)) {
          users.push({ isDisabled: false, text: user.name, id: user.guid });
        }
      });
      this.assignedUsersDisabled = [...this.assignedUsersDisabled, ...users];
    });
  }

  onDeSelectTrainingGroup(event: any) {
    this.settingsService.getTrainingGroupUserList(this.organisationID, true, [event.id]).subscribe(data => {
      data[0].users?.forEach((user: any) => {
        const index = this.assignedUsersDisabled.findIndex((assignUser: any) => assignUser.id == user.guid);
        if (index !== -1) {
          this.assignedUsersDisabled.splice(index, 1);
        }
      });
      this.assignedUsersDisabled = [...this.assignedUsersDisabled];
    });
  }

  updateDocumentsDisable(event: any) {
    this.disable = false;
    let ids = event.map((item: any) => item.id);
    this.documentsService.getDocumentList(this.organisationID, 1, true, '', ids).subscribe((data: any) => {
      let groupDocuments: any[] = [
        ...new Set(data.map((item: any) => item.documents.map((doc: any) => doc.doc_version_guid)).flat())
      ];
      const unassignedDocuments = this.updatedDocumentsList.map((doc: any) => ({
        id: doc.id,
        text: doc.text,
        isDisabled:
          groupDocuments.includes(doc.id) ||
          this.permanentDisabledDocumentsGuids.includes(doc.id) ||
          this.currentAssignedDocuments.map((x: any) => x.id).includes(doc.id)
      }));
      this.updatedDocumentsList = unassignedDocuments;
      this.selectedDocumentDisabledData = [];
      this.selectedDocumentDisabledData = [
        ...this.selectedDocumentDisabledData.filter((doc: any) => !groupDocuments.includes(doc.id)),
        ...this.currentAssignedDocuments
      ];
    });
  }

  submit() {
    this.submitted = true;
    this.form_error = false;
    this.loading = true;
    if (this.campaignForm['start_date'] == this.campaignForm['start_date']) {
      this.campaignForm.get('start_date').clearValidators();
      this.campaignForm.get('start_date').setErrors(null);
      this.campaignForm.get('start_date').updateValueAndValidity();
    }
    if (this.campaignForm['end_date'] == this.campaignForm['end_date']) {
      this.campaignForm.get('end_date').clearValidators();
      this.campaignForm.get('end_date').setErrors(null);
      this.campaignForm.get('end_date').updateValueAndValidity();
    }
    this.campaignForm.getRawValue();

    if (this.campaignForm.valid) {
      let campaign_create_data = Object.assign({}, this.campaignForm.getRawValue());
      if (campaign_create_data.renewal_recurring) {
        campaign_create_data['renewal_schedule'] = this.getPreparedData();
        campaign_create_data['renewal_schedule']['day_of_week'] = this.data['day_of_week'];
        campaign_create_data['renewal_schedule']['week'] = this.data['week'];
      } else {
        campaign_create_data['renewal_schedule'] = null;
        campaign_create_data['day_of_week'] = null;
      }
      const start_date = new Date(
        campaign_create_data['start_date'].year,
        campaign_create_data['start_date'].month - 1,
        campaign_create_data['start_date'].day + 1
      );
      const end_date = new Date(
        campaign_create_data['end_date'].year,
        campaign_create_data['end_date'].month - 1,
        campaign_create_data['end_date'].day + 1
      );
      const unEditableGroupGuids = this.selectedGroupDisabledData.map((x: any) => x.id);
      let selectedUpdatedGroupsGuids = this.selectedUpdatedGroups.map((x: any) => x.id);
      selectedUpdatedGroupsGuids = selectedUpdatedGroupsGuids.concat(unEditableGroupGuids);
      const unEditableDocumentsVersion = this.selectedDocumentDisabledData.map((x: any) => x.id);
      let selectedDocumentsDocumentVersion = this.unSelectedDocuments.map((x: any) => x.id);
      selectedDocumentsDocumentVersion = selectedDocumentsDocumentVersion.concat(unEditableDocumentsVersion);
      const unEditableDocumentsGroups = this.selectedDocumentGroupDisabled.map((x: any) => x.id);
      let selectedDocumentsDocumentGroups = this.unSelectedGroupDocuments.map((x: any) => x.id);
      selectedDocumentsDocumentGroups = selectedDocumentsDocumentGroups.concat(unEditableDocumentsGroups);
      // We are passing users from user groups inside 'users' array
      // instead of passing in training group, hence empty user groups
      // selectedUpdatedGroupsGuids
      campaign_create_data['training_groups'] = [];
      campaign_create_data['users'] = this.assignedUsersDisabled.map((x: any) => x.id);
      campaign_create_data['documents'] = this.selectedDocumentDisabledData.map((x: any) => x.id);
      campaign_create_data['document_groups'] = this.selectedDocumentGroupDisabled.map((x: any) => x.id);
      this.isLoading = true;
      campaign_create_data['start_date'] = start_date.toISOString().split('T')[0];
      campaign_create_data['end_date'] = end_date.toISOString().split('T')[0];
      this.isLoading = true;
      if (this.reopenDisabled) {
        this.submitReopenTrainingForm(campaign_create_data, this.camGuid);
      } else {
        this.submitTrainingForm(campaign_create_data, this.camGuid);
      }
    } else {
    }
  }

  submitReopenTrainingForm(campaign_create_data: any, camGuid: any) {
    this.documentsService.reOpenCampaignOnClick(this.organisationID, camGuid, campaign_create_data).subscribe(
      data => {
        this.isLoading = false;
        this.loading = false;
        this.error = '';
        this.backend_error = false;
        this.modalService
          .custom(CustomDialogComponent, {
            title: 'Campaign edited',
            modalType: 'Campaign edited',
            DocumentGuid: data.guid,
            DocumentTitle: data.title
          })
          .pipe(take(1)) // take() manages unsubscription for us
          .subscribe((result: any) => {
            if (result.isConfirmed) {
              this.router.navigate(['/organisation/training']);
            } else if (!result.isConfirmed) {
              this.goToEditTrainingCampaign(data.guid, '/organisation/edit-training-campaign');
            }
          });
      },
      (error: any) => {
        this.isLoading = false;
        this.error = error;
        this.form_error = error.error;
        this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
        });
        this.loading = false;
        this.submitted = false;
      }
    );
  }

  goToEditTrainingCampaign(guid: any, url: string) {
    this.router
      .navigate(['/organisation/edit-training-campaign'], {
        queryParams: { guid: guid }
      })
      .then(() => {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([url], { queryParams: { guid: guid } });
        });
      });
  }

  ShowSelectedGroups() {
    if (this.campaignDetails['groups']) {
      this.selectedGroups = this.campaignDetails['groups'].map((item: any) => {
        let container = {};
        container[`id`] = item['guid'];
        container[`text`] = item['title'];
        return container;
      });
    }
  }
  ShowSelectedDocumentsGroups() {
    if (this.campaignDetails['document_groups']) {
      this.selectedGroupDocuments = this.campaignDetails['document_groups'].map((item: any) => {
        let container = {};
        container[`id`] = item['guid'];
        container[`text`] = item['title'];
        return container;
      });
    }
  }
  ShowSelectedDocuments() {
    if (this.campaignDetails['documents']) {
      this.selectedDocuments = this.campaignDetails['documents']
        .filter(function(item: any) {
          if (item['type'] == 'cb_document') {
            return true;
          }
          return false; // skip
        })
        .map((item: any) => {
          let container = {};
          container['id'] = item['document_version_guid'];
          container['text'] = item['name'];
          return container;
        });
    }
  }
  onChangeEndDate(event: any, type: string) {
    this.disable = false;
    this.error = '';
    if (type == 'change' && event != '') {
      if (this.campaignForm.controls['end_date'].errors) {
        this.disable = true;
      }
      if (this.error && type == 'change') {
        this.error = '';
        this.error = false;
      }
    }
  }
  onChangeStartDate(event: any, type: string) {
    this.disable = false;
    this.error = '';
    if (type == 'change' && event != '') {
      if (this.campaignForm.controls['start_date'].errors) {
        this.disable = true;
      }
      if (this.error && type == 'change') {
        this.error = '';
        this.error = false;
      }
    }
  }
  submitTrainingForm(campaign_create_data: any, camGuid: any) {
    this.documentsService.edittedCampaignDetails(this.organisationID, campaign_create_data, camGuid).subscribe(
      data => {
        this.isLoading = false;
        this.loading = false;
        this.error = false;
        this.assignedUsersDisabled = data['assigned_users'].map((item: any) => {
          return { isDisabled: false, text: item.name, id: item.guid };
        });
        this.modalService
          .custom(CustomDialogComponent, {
            title: 'Campaign edited',
            modalType: 'Campaign edited',
            DocumentGuid: data.guid,
            DocumentTitle: data.title
          })
          .pipe(take(1)) // take() manages unsubscription for us
          .subscribe((result: any) => {
            if (result.isConfirmed) {
              this.router.navigate(['/organisation/training']);
            } else if (!result.isConfirmed) {
              location.reload();
            }
          });
      },
      (error: any) => {
        this.isLoading = false;
        this.error = error;
        this.form_error = error.error;
        this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
        });
        this.loading = false;
        this.submitted = false;
      }
    );
  }

  addDocumentUrl() {
    if (this.getTitle != '' && this.getUrl != '') {
      this.disabled = true;
      this.disabledAddNew = false;
    }
    this.submitted = false;
    this.disabledAddNew = true;
    this.optionsData.push(this.newDocumentUrl());
  }

  private dateRangeValidator: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const end_dt = this.campaignForm && this.campaignForm.get('end_date').value;
    const start_dt = this.campaignForm && this.campaignForm.get('start_date').value;

    if (start_dt && end_dt) {
      if (end_dt.year < start_dt.year) {
        return { invalidRange: true };
      } else if (end_dt.year === start_dt.year && end_dt.month < start_dt.month) {
        return { invalidRange: true };
      } else if (end_dt.year === start_dt.year && end_dt.month === start_dt.month && end_dt.day < start_dt.day) {
        return { invalidRange: true };
      } else if (end_dt.year === start_dt.year && end_dt.month === start_dt.month && end_dt.day === start_dt.day) {
        return { invalidSameDate: true };
      }
    }
    return invalid ? { invalidRange: { start_dt, end_dt } } : null;
  };

  create_document_url(data: any = null): FormGroup {
    let url = '';
    let urltype = this.default_doc_url_type;
    let title = '';
    if (data) {
      url = data['link'];
      urltype = data['urltype'];
      title = data['title'];
    }
    return this.formBuilder.group({
      url: [{ value: url, disabled: true }, [Validators.httpsURL()]],
      urltype: [{ value: 'others', disabled: true }, [Validators.required()]],
      title: [{ value: title, disabled: true }, [Validators.required()]]
    });
  }

  init_docs_urls() {
    let external_links: Array<FormGroup | null>;
    external_links = [];
    if (this.externalLink) {
      for (const data of this.externalLink) {
        external_links.push(this.create_document_url(data));
      }
    }
    return external_links;
  }

  get optionsData(): FormArray {
    return this.campaignForm.get('external_links') as FormArray;
  }

  modelChange(event: any): void {
    this.getTitle = event;
    this.checkExternalLink();
  }

  urlChange(event: any) {
    this.getUrl = event;
    this.checkExternalLink();
  }

  checkExternalLink() {
    if (this.getTitle != '' && this.getUrl != '') {
      this.disabled = false;
      this.disabledAddNew = false;
    } else {
      this.disabled = true;
      this.disabledAddNew = true;
    }
    let duplicateIndex: number | null = null;
    const duplicateUrl = this.optionsData.controls.some((control, index) => {
      const currentUrl = control.get('url')?.value;
      if (
        currentUrl &&
        this.optionsData.controls.findIndex((c, i) => c.get('url')?.value === currentUrl && i !== index) !== -1
      ) {
        duplicateIndex = index;
        return true;
      }
      return false;
    });

    if (duplicateUrl) {
      this.duplicateError = true;
      this.disabledAddNew = true;
      this.duplicateIndex = duplicateIndex;
    } else {
      this.duplicateError = false;
      this.duplicateIndex = null;
    }
  }

  newDocumentUrl(): FormGroup {
    if (this.optionsData.length >= 0) {
      this.disabled = false;
      this.getTitle = '';
      this.getUrl = '';
      if (this.getTitle != '' && this.getUrl != '') {
        this.disabledAddNew = false;
      } else {
        this.disabledAddNew = true;
      }
    }
    return this.formBuilder.group({
      urltype: [this.default_doc_url_type],
      url: [this.singleExternalLink, [Validators.httpsURL()]],
      title: [this.singleExternalTitle, [Validators.required()]]
    });
  }
}
