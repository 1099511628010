import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Label, SingleDataSet } from 'ng2-charts';
import { ChartType } from 'chart.js';

@Component({
  selector: 'app-graph-component',
  templateUrl: './complianceGraph.component.html',
  styleUrls: ['./complianceGraph.component.scss']
})
export class ComplianceGraphComponent implements OnInit {
  @Input() graphData: any;
  @Input() graphDataEventType: any;
  public doughnutChartLabels: Label[] = [];
  public doughnutChartData: SingleDataSet[] = [];
  public doughnutChartType: ChartType = 'doughnut';
  public graphStyleBoxContainerChanged = false;
  public chartColors: Array<any> = [
    {
      backgroundColor: []
    }
  ];
  graphClickedElement = {};

  /** Color sequence of labels **/
  labelColor = {
    Inactive: 'rgb(224, 224, 224)',
    'No participation': 'rgb(202, 79, 64)',
    'Good standing': 'rgb(71, 154, 95)',
    'Pending meetings': 'rgb(234, 182, 62)',
    Disregard: 'rgb(128, 0, 0)'
  };

  chartOptions = {
    responsive: true,
    legend: {
      display: false
    },
    tooltips: {
      enabled: true
    },
    cutoutPercentage: 70,
    maintainAspectRatio: false
  };

  @Output() graphDataDrillDown = new EventEmitter<{ event_desc: any; event_type: any }>();

  constructor() {}

  ngOnInit() {
    this.seperateGraphData(this.graphData.event_details);
  }

  /** Function to seperate labels and it's value **/
  seperateGraphData(data: any): void {
    if (this.graphDataEventType === 'overall') {
      this.graphStyleBoxContainerChanged = true;
    } else {
      this.graphStyleBoxContainerChanged = false;
    }
    for (const obj of data) {
      this.doughnutChartLabels.push(obj.label);
      this.chartColors[0]['backgroundColor'].push(this.labelColor[obj.label]);
      this.doughnutChartData.push(obj.value);
    }
  }

  public chartClicked(e: any): void {
    if (e.active.length > 0) {
      const chart = e.active[0]._chart;
      const activePoints = chart.getElementAtEvent(e.event);
      if (activePoints.length > 0) {
        // get the internal index of slice in pie chart
        const clickedElementIndex = activePoints[0]._index;
        const label = chart.data.labels[clickedElementIndex];
        // get value by index
        const value = chart.data.datasets[0].data[clickedElementIndex];
        this.graphClickedElement = this.graphData['event_details'].find((obj: any) => {
          return obj.label === label;
        });
        // tslint:disable-next-line:max-line-length
        this.graphDataDrillDown.emit({
          event_desc: this.graphClickedElement,
          event_type: this.graphData['event_type'].replace(/[ \-]/g, '_')
        });
      }
    }
  }

  /** TODO : CHART hover function **/
  public chartHovered({ event, active }: { event: MouseEvent; active: {}[] }): void {
    console.log(event, active);
  }
}
