<app-spinner *ngIf="loading"></app-spinner>
<div class="header-container row">
  <div class="header-inner-container">
    <div class="d-flex justify-content-between align-item-center">
      <div>
        <div class="mb-2" style="display: flex !important;align-items: center !important;">
          <a routerLink="/organisation/settings/integration" class="mr-2 integration">Integrations</a>
          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.7125 10.273L6 6.5605L7.0605 5.5L11.8335 10.273L7.0605 15.046L6 13.9855L9.7125 10.273Z"
              fill="#002244"
            />
          </svg>
          <span class="service-provider-breadcrum-label" class="ml-2">{{
            serviceProvider?.description?.short_name
          }}</span>
        </div>
        <div class="cloud-provider-title">{{ serviceProvider?.description?.short_name }}</div>
      </div>
      <!-- <button class="mat-add-acc-btn align-self-center">Add Account</button> -->
    </div>
  </div>
</div>
<div class="guid-title">Guide to integrate {{ serviceProvider?.description?.short_name }} with ComplianceBot</div>
<ng-container *ngIf="!loading && accountsList?.length > 0; else no_content">
  <div class="table-container row mt-4">
    <table>
      <thead>
        <tr>
          <th style="width: 209px;">ACCOUNT NAME</th>
          <th style="width: 155px;">SYNC TYPE</th>
          <th style="width: 150px;">SYNC LAST RUN</th>
          <th style="width: 151px;">NEXT SYNC</th>
          <th style="width: 150px;">SYNC STATUS</th>
          <th style="width: 90px;"></th>
          <th style="width: 50px;"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let account of accountsList">
          <td>
            {{ account?.name }}
            <span *ngIf="account.is_active" class="active-pill">Active</span>
            <span *ngIf="!account.is_active" class="inactive-pill">Inactive</span>
          </td>
          <td>{{ account?.sync_type | titlecase }}</td>
          <td>{{ account?.last_run_time ? account?.last_run_time : '-' }}</td>
          <td>{{ account?.next_run_time ? account?.next_run_time : '-' }}</td>
          <td>
            <ng-container *ngIf="account?.status">
              <span class="success-indicator" [ngClass]="getStatusClass(account?.status)"></span>
              {{ account?.status.replace('_', ' ') | titlecase }}
            </ng-container>
            <ng-container *ngIf="!account?.status">-</ng-container>
          </td>
          <td>
            <div class="tooltip-container">
              <button
                [disabled]="
                  account?.status?.toLowerCase() == 'in_progress' || account?.status?.toLowerCase() == 'queued'
                "
                (click)="syncNow(account)"
                class="sync-now"
              >
                Sync Now
              </button>
              <span *ngIf="account?.status?.toLowerCase() == 'in_progress'" class="tooltip-text">
                Sync is in progress
              </span>
            </div>
          </td>
          <td>
            <div class="three-dot" [matMenuTriggerFor]="menu" (click)="selectedAccount = account">
              <mat-icon>more_vert</mat-icon>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <mat-menu #menu="matMenu">
    <button *ngIf="selectedAccount?.is_active" (click)="reConfigure()" mat-menu-item>Reconfigure</button>
    <button *ngIf="selectedAccount?.is_active" (click)="disconnectIntegration()" mat-menu-item>Disconnect</button>
    <button *ngIf="!selectedAccount?.is_active" (click)="reConfigure()" mat-menu-item>Connect</button>
  </mat-menu>
</ng-container>

<ng-template #no_content>
  <div class="row scroll-view">
    <div class="col" *ngIf="!loading">
      <img class="empty-doc-list-image" src="../../../assets/images/documents/empty.svg" />
      <div class="text-empty-doc-list text-black-1" translate>No account found</div>
    </div>
  </div>
</ng-template>
