<div class="container-fluid">
  <div class="row mb-2">
    <input type="file" accept=".png,.jpg" (change)="fileChangeEvent($event)" />
  </div>
  <div class="row  mb-2" *ngIf="showContext">
    <button class="col btn-test mr-1" (click)="rotateLeft()">Rotate left</button>
    <button class="col btn-test mr-1" (click)="rotateRight()">Rotate right</button>
    <button class="col btn-test mr-1" (click)="flipHorizontal()">Flip horizontal</button>
    <button class="col btn-test" (click)="flipVertical()">Flip vertical</button>
  </div>
  <div class="row mb-2" *ngIf="showContext">
    <div class="col">
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="1 / 1"
        [resizeToWidth]="150"
        [cropperMinWidth]="150"
        [onlyScaleDown]="true"
        [roundCropper]="false"
        format="png"
        outputType="both"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
        [style.display]="showCropper ? null : 'none'"
        [alignImage]="'left'"
      ></image-cropper>
    </div>
    <div class="col">
      <img [src]="croppedImage" />
    </div>
  </div>
  <div class="row" *ngIf="showContext">
    <button class="btn-save" (click)="saveImage()">Save</button>
  </div>
</div>
