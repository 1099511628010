import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { finalize } from 'rxjs/operators';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';

@Component({
  selector: 'app-mark-edit-done',
  templateUrl: './mark-edit-done.component.html',
  styleUrls: ['./mark-edit-done.component.scss']
})
export class MarkEditDoneComponent implements OnInit {
  MarkEditDoneForm: FormGroup;
  submitted = false;
  isLoading = false;
  OrganisationID: string | null;
  guidSubscription: any;
  success: boolean;
  error: any;
  loading = true;

  @Input() formType: string;
  @Input() DocumentGuid: string;
  @Input() title_tag: string;
  @Input() docTitle: string;
  @Input() modalType: string;
  backend_error: string;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private documentsService: DocumentsService
  ) {}

  ngOnInit() {
    console.log(this.modalType);

    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getMarkEditDoneForm();
    });
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  getMarkEditDoneForm() {
    this.MarkEditDoneForm = this.formBuilder.group({
      remarks: ['', [Validators.maxLength(4096)]]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.MarkEditDoneForm.controls;
  }

  save() {
    this.submitted = true;
    this.isLoading = true;
    if (this.MarkEditDoneForm.valid) {
      const MarkEditDoneData = this.MarkEditDoneForm.value;
      this.submitMarkEditDone(MarkEditDoneData);
    } else {
      this.submitted = false;
      this.isLoading = false;
      return;
    }
  }

  submitMarkEditDone(MarkEditDoneData: any): void {
    let apiToCall: any = {
      'Form Edit Complete': 'submitMarkEditDoneForForm',
      'Mark Editing as Done': 'submitMarkEditingDoneData'
    };
    this.documentsService[apiToCall[this.modalType]](this.OrganisationID, this.DocumentGuid, MarkEditDoneData)
      .pipe(
        finalize(() => {
          this.MarkEditDoneForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.error = {
            remarks: ''
          };
          this.submitted = false;
          this.backend_error = '';
          this.activeModal.close({ result: data, isUpdate: true });
        },
        (error: any) => {
          this.submitted = false;
          this.isLoading = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
  }
}
