import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { finalize } from 'rxjs/operators';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';

@Component({
  selector: 'app-del-group-user',
  templateUrl: './del-group-user.component.html',
  styleUrls: ['./del-group-user.component.scss']
})
export class DelGroupUserComponent implements OnInit {
  RevokeEditForm: FormGroup;
  submitted = false;
  isLoading = false;
  OrganisationID: string | null;
  guidSubscription: any;
  success: boolean;
  error: any;

  @Input() formType: string;
  @Input() userGuid: string;
  @Input() title_tag: string;
  @Input() message: string;
  @Input() guid: string;
  backend_error: string;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private documentsService: DocumentsService
  ) {}

  ngOnInit() {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      console.log(this.OrganisationID);
    });
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  save() {
    this.submitted = true;
    this.isLoading = true;
    const data = { users: [this.userGuid] };
    this.submitRevokeData(data);
  }
  submitRevokeData(data: any): void {
    console.log();
    this.documentsService
      .submitDelGroupUserData(this.OrganisationID, this.guid, data)
      .pipe(
        finalize(() => {
          this.RevokeEditForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.error = {};
          this.submitted = false;
          this.activeModal.close(true);
          this.backend_error = '';
        },
        (error: any) => {
          this.isLoading = false;
          this.submitted = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
  }
}
