import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

export interface UserInterface {
  // Customize received credentials here
  user: object;
}

const userdataKey = 'usreskey';

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable()
export class UserService {
  _userDetails: UserInterface = { user: {} };
  length: any;
  _userRole: any;
  showDocumentData: boolean;
  showIncidentData: boolean;
  showEventsData: boolean;
  documentRole = [
    'document_reader',
    'document_approver',
    'document_editor',
    'document_author',
    'organization_user_role',
    'organization_admin_role'
  ];
  incidentRole = ['incident_coordinator', 'incident_reviewer', 'incident_coordinator'];

  constructor() {}

  saveUserDetails(userdata: UserInterface): UserInterface | null {
    this._userDetails = userdata;

    if (userdata?.user && _.indexOf(userdata.user['role'], 'organization_admin_role') > -1) {
      this._userRole = 'organization_admin_role';
    } else if (userdata?.user && _.indexOf(userdata.user['role'], 'system_admin_role') > -1) {
      this._userRole = 'system_admin_role';
    }

    return this._userDetails;
  }

  getuserdetails(): UserInterface | null {
    return this._userDetails;
  }

  getUserRole1(): any | null {
    return this._userDetails;
  }
  getUserRole(): string | null {
    return this._userRole;
  }
}
