import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-common-pagination',
  templateUrl: './common-pagination.component.html',
  styleUrls: ['./common-pagination.component.scss']
})
export class CommonPaginationComponent {
  @Input() totalLength: number;
  @Input() pageSize: number;
  @Input() page: number;
  @Input() pageSizes: { value: number; label: string }[];

  @Output() pageSizeChange = new EventEmitter<number>();
  @Output() pageChange = new EventEmitter<number>();
  @Output() goToPageChange = new EventEmitter<number>();

  pageText: string;

  changePageSize(value: number) {
    this.pageText = '';
    this.pageSizeChange.emit(value);
  }
  onPageTextChange(value: number) {
    if (value < 1) {
      this.pageText = '1';
    }
  }
  onPageChange(page: number) {
    this.pageChange.emit(page);
  }

  goToPage(value: string) {
    const page = parseInt(value, 10);
    if (!isNaN(page)) {
      this.goToPageChange.emit(page);
      this.pageText = '';
    }
  }
}
