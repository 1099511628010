import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger, I18nService, AuthenticationService } from '@app/core';
import { UserService } from '@app/core/user.service';
import { OrganisationService } from '@app/core/organisation.service';

const log = new Logger('Login');

@Component({
  selector: 'app-slack-oauth-message',
  templateUrl: './slack-oauth.component.html',
  styleUrls: ['./slack-oauth.component.scss']
})
export class SlackOauthComponent implements OnInit {
  message: string;
  type: string;

  constructor(private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.message = params['message'];
      this.type = params['type'];
    });
  }

  ngOnInit() {}

  done() {
    window.close();
  }
}
