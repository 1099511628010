import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganisationDashboardService } from '@app/Organisation-admin/orgDashboard/orgDashboard.service';
import { ModalService } from '@app/core/modal/modal.service';
import { OrganisationService } from '@app/core/organisation.service';
import { Subscription } from 'rxjs';
import { WizardService } from '@app/home/wizard.services';
import { MixpanelService } from '@app/mixpanel/mixpanel.service';

@Component({
  selector: 'app-program-configuration',
  templateUrl: './program-configuration.component.html',
  styleUrls: ['./program-configuration.component.scss']
})
export class ProgramConfigurationComponent implements OnInit, OnDestroy {
  programConfigform: FormGroup;
  guidSubscription: Subscription;
  organisationID: string = '';
  loading = false;
  isPanelOpen: boolean = false;
  chips: { guid: string; name: string; selected: boolean }[] = [];
  orgTypes: { guid: string; title: string; tooltip?: string }[] = [];
  selectedEmployeeAmt = '';
  error: string = '';
  orgName = '';
  userName: any = '';
  step = 1;
  frameworks: any = [
    {
      name: 'HIPAA',
      img: 'hippa.svg',
      disabled: false,
      selected: false,
      comingSoon: true
    },
    {
      name: 'SOC2',
      img: 'soc2.svg',
      disabled: false,
      selected: false,
      comingSoon: true
    },
    {
      name: 'ISO 27001',
      img: 'iso.svg',
      disabled: true,
      selected: false,
      comingSoon: true
    },
    {
      name: 'GDPR',
      img: 'gdpr.svg',
      disabled: true,
      selected: false,
      comingSoon: true
    },
    {
      name: 'FISMA',
      img: 'fisma.svg',
      disabled: true,
      selected: false,
      comingSoon: true
    },
    {
      name: 'CMMC',
      img: 'cmmc.svg',
      disabled: true,
      selected: false,
      comingSoon: true
    }
  ];
  skippedOnboarding = false;
  compliancePrograms: any = [];
  comaplianceArrays: any = [];
  selectedFramework: string = '';
  constructor(
    private formBuilder: FormBuilder,
    private orgService: OrganisationService,
    private orgDashboardService: OrganisationDashboardService,
    private router: Router,
    private modalService: ModalService,
    private wizard: WizardService,
    private mixpanel: MixpanelService,
    private activatedRoute: ActivatedRoute
  ) {
    if (sessionStorage.getItem('skippedOnboarding')) {
      this.skippedOnboarding = sessionStorage.getItem('skippedOnboarding') == 'false' ? false : true;
    } else {
      this.skippedOnboarding = false;
    }
    this.activatedRoute.queryParams.subscribe(res => {
      if (res['step'] == '2') {
        this.step = 2;
      }
      if (res['framework']) {
        this.selectedFramework = res['framework'];
      }
    });
    this.programConfigform = formBuilder.group({
      org_name: ['', Validators.required],
      cmp_programs: [[]]
    });
  }

  ngOnInit(): void {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(org_guid => {
      this.organisationID = org_guid;
      this.orgDashboardService.organisationalProgram(org_guid).subscribe((res: any) => {
        this.compliancePrograms = res;
        this.comaplianceArrays = res.map((item: any) => item.name);
        if (res && res.length) {
          this.updateFrameworkSelection();
        }
      });
      this.getOrganisationType();
      const credentialsString = localStorage.getItem('credentials');
      const credentials = JSON.parse(credentialsString);
      this.userName = credentials.user.first_name.charAt(0).toUpperCase() + credentials.user.first_name.slice(1);
      const user_id = credentials.user.guid;
      this.mixpanel.identify(user_id);
      this.mixpanel.setPeople({
        $email: credentials.user.username
      });
      const sign_up_event = localStorage.getItem('sign_up_event');
      if (sign_up_event !== 'true') {
        localStorage.setItem('sign_up_event', 'true');
        this.mixpanel.sendCBEvent('signup');
      }
    });
  }

  private updateFrameworkSelection(): void {
    this.frameworks = this.frameworks.map((framework: any) => {
      const exists = this.compliancePrograms.some(
        (item: any) => item.parent_compliance_program.name === framework.name
      );

      return {
        ...framework,
        selected: exists,
        comingSoon: exists
      };
    });
    this.frameworks.forEach((element: any) => {
      if (this.selectedFramework == element.name) {
        element.selected = true;
      }
    });
  }

  toggleSelected(chip: any) {
    chip.selected = !chip.selected;
  }

  onChipClick(event: Event, chip: any): void {
    if (chip.name?.toLowerCase() !== 'HIPAA'?.toLowerCase()) {
      this.toggleSelected(chip);
    } else {
      event.preventDefault();
    }
  }

  selectOpened(opened: boolean) {
    this.isPanelOpen = opened;
  }

  getTooltip(selectedOrgType: string): string {
    const selectedItem = this.orgTypes.find(item => item.guid === selectedOrgType);
    return selectedItem ? selectedItem.tooltip : '';
  }

  getselectedTitle(selectedOrgType: string) {
    const selectedItem = this.orgTypes.find(item => item.guid === selectedOrgType);
    return selectedItem ? selectedItem.title : '';
  }

  get checkChipsSelected() {
    return this.chips?.some(item => item?.selected);
  }

  get checkFrameworkSelected() {
    return this.frameworks?.some((item: any) => item?.selected);
  }

  getOrganisationType() {
    this.loading = true;
    this.orgDashboardService.getProfileConfiguration(this.organisationID).subscribe(
      res => {
        this.orgTypes = res['data'];

        // First disable all frameworks
        this.frameworks.forEach((element: any) => {
          element.disabled = true;
        });

        // Enable frameworks based on available compliance programs
        res.data.compliance_programs.forEach((item: any) => {
          const matchingFramework = this.frameworks.find((element: any) => element.name === item.name);
          if (matchingFramework) {
            matchingFramework.guid = item.guid;
            matchingFramework.disabled = false;
          }
        });

        // Update framework selection based on existing compliance programs
        if (this.compliancePrograms && this.compliancePrograms.length) {
          this.updateFrameworkSelection();
        }

        this.programConfigform.patchValue({
          org_name: res['data'].organization_details?.name
            ? res['data'].organization_details?.name.charAt(0).toUpperCase() +
              res['data'].organization_details?.name.slice(1)
            : ''
        });
        this.orgName = res['data'].organization_details?.name
          ? res['data'].organization_details?.name.charAt(0).toUpperCase() +
            res['data'].organization_details?.name.slice(1)
          : '';
        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    );
  }

  saveForm() {
    if (this.programConfigform.invalid || !this.checkFrameworkSelected) return;
    this.programConfigform.patchValue({
      cmp_programs: this.frameworks.filter((item: any) => item.selected).map((item: any) => item.guid)
    });
    this.loading = true;
    let payload = { ...this.programConfigform.value };
    if (this.programConfigform.value.org_name.trim().toLowerCase() == this.orgName.trim().toLowerCase()) {
      delete payload['org_name'];
    }

    this.orgDashboardService.saveProfileConfiguration(this.organisationID, payload).subscribe(
      res => {
        localStorage.setItem('isProgramConfigCompleted', 'true');
        this.wizard.getOrganizationDetails(this.organisationID).subscribe(data => {
          this.orgName = data['name'];
          localStorage.setItem('org_password_based_login', data['org_password_based_login']);
          localStorage.setItem('domain', data['domain']);
          localStorage.setItem('org_name', this.orgName);
          localStorage.setItem('org_id', this.organisationID);
          localStorage.setItem('compliance_programs', JSON.stringify(data['compliance_programs']));
          localStorage.setItem('org_type', JSON.stringify(data['org_type']));
          this.mixpanel.sendCBEvent('configure_program');
        });
        if (payload.org_name) {
          this.wizard.getUserOrganizations().subscribe(data => {
            this.wizard.userOrganizations.next(data);
          });
        }
        this.orgDashboardService.isGrcEnabled(this.organisationID).subscribe((grcRes: any) => {
          this.loading = false;
          this.orgService.setIsOrganisationOnboarded(grcRes.is_organization_onboarded);
          localStorage.setItem('select_applicable_ctrl_completed', String(grcRes.select_applicable_ctrl_completed));
          localStorage.setItem('org_assessment_completed', String(grcRes.org_assessment_completed));
          localStorage.setItem('isProgramConfigCompleted', JSON.stringify(grcRes.program_configured_completed));
          sessionStorage.setItem('skippedOnboarding', 'false');
          this.router.navigateByUrl('/organization-assessment');
        });
      },
      error => {
        this.loading = false;
        let errorkey = Object.keys(error.error)[0];
        this.error = error.error[errorkey][errorkey] ? error.error[errorkey][errorkey] : error.error[errorkey]?.error;
      }
    );
  }

  skip() {
    sessionStorage.setItem('skippedOnboarding', 'true');
    this.orgDashboardService.changeOnBoardingFlag(this.organisationID, true).subscribe(data => {
      this.router.navigateByUrl('/organisation/dashboard?cert=false');
    });
  }

  handleFramework(framework: any) {
    if (!framework.disabled) {
      framework.selected = !framework.selected;
    }
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }
}
