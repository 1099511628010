<div>
  <div class="modal-header cb-modal-header">
    <h4 class="modal-title font-weight-bold text-black-1" translate>organisation_people.Title</h4>
  </div>
  <div class="modal-body">
    <div class="col-12 error-mssg" *ngIf="error['error']">
      <label class="text-danger">*{{ error['error'] }}</label>
    </div>
    <form [formGroup]="peopleForm" novalidate>
      <div class="row mt-3">
        <div class="col-4">
          <label for="first_name" class="text-black-1" translate
            >organisation_people.add_organisation_people.First name</label
          >
        </div>
        <div class="input-group col-8">
          <input
            id="first_name"
            class="form-control cb-input"
            [placeholder]="'organisation_people.add_organisation_people.First name' | translate"
            formControlName="first_name"
            name="firstname"
            type="text"
          />
          <div class="col-12 error-mssg" *ngIf="(f.first_name.touched || submitted) && f.first_name.errors">
            <label for="first_name" class="text-danger" *ngIf="f.first_name.errors.required" translate
              >organisation_people.add_organisation_people.*First name is required.</label
            >
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <label for="last_name" class="text-black-1" translate
            >organisation_people.add_organisation_people.Last name</label
          >
        </div>
        <div class="input-group col-8">
          <input
            id="last_name"
            class="form-control cb-input"
            [placeholder]="'organisation_people.add_organisation_people.Last name' | translate"
            formControlName="last_name"
            name="lastname"
            type="text"
          />
          <div class="col-12 error-mssg" *ngIf="(f.last_name.touched || submitted) && f.last_name.errors">
            <label for="first_name" class="text-danger" *ngIf="f.last_name.errors.required" translate
              >organisation_people.add_organisation_people.*Last name is required.</label
            >
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <label for="email" class="text-black-1" translate>organisation_people.add_organisation_people.Email</label>
        </div>

        <div class="input-group col-8">
          <input
            id="email"
            formControlName="email"
            class="form-control cb-input"
            [placeholder]="'organisation_people.add_organisation_people.Email address' | translate"
            placeholder="Email address"
            name="email"
            type="text"
          />
          <div class="col-12 error-mssg" *ngIf="(f.email.touched || submitted) && f.email.errors">
            <label for="first_name" class="text-danger" *ngIf="f.email.errors.email" translate
              >organisation_people.add_organisation_people.*Email address in not valid.</label
            >
          </div>
          <div class="col-12 error-mssg" *ngIf="error['email']">
            <label for="email" class="text-danger">*{{ error['email'] }}</label>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <label for="phone-number" class="text-black-1" translate
            >organisation_people.add_organisation_people.Phone number</label
          >
        </div>

        <div class="input-group col-8">
          <input
            id="phone-number"
            formControlName="phone"
            class="form-control cb-input"
            [placeholder]="'organisation_people.add_organisation_people.Phone number' | translate"
            name="phone number"
            type="text"
          />
          <!-- TODO: In case you want to make first name required. -->
          <!-- <div class="col-12 error-mssg" *ngIf="(f.phone.touched || submitted) && f.phone.errors">
            <label for="first_name" class="text-danger" *ngIf="f.phone.errors.required" translate
              >organisation_people.add_organisation_people.*Phone number is required.</label
            >
          </div> -->
          <div class="col-12 error-mssg" *ngIf="error['phone']">
            <label for="phone-number" class="text-danger">*{{ error['phone'] }}</label>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <label for="role" class="text-black-1" translate>organisation_people.add_organisation_people.Role</label>
        </div>
        <div class="input-group col-8">
          <ng-multiselect-dropdown
            formControlName="selectedItems"
            class="multi-select-dropdown"
            [placeholder]="'Select role'"
            [(ngModel)]="selectedItems"
            (onSelect)="onItemSelect($event)"
            (onDeSelect)="onItemDeSelect($event)"
            [settings]="dropdownSettings"
            [data]="GroupsList"
          >
          </ng-multiselect-dropdown>

          <div class="col-12 error-mssg" *ngIf="error['invalid_role']">
            <label for="phone-number" class="text-danger" translate>*{{ error['invalid_role'] }}</label>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <label for="role" class="text-black-1" translate
            >organisation_people.add_organisation_people.external_sharing</label
          >
        </div>
        <div class="input-group col-8">
          <mat-slide-toggle formControlName="external_sharing"> </mat-slide-toggle>
        </div>
      </div>
      <div class="row mt-3" *ngIf="!orgPasswordBasedLogin">
        <div class="col-4">
          <label for="role" class="text-black-1" translate
            >organisation_people.add_organisation_people.user_level_login</label
          >
        </div>
        <div class="input-group col-8">
          <mat-slide-toggle formControlName="password_based_login"> </mat-slide-toggle>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer border-0 cb-modal-footer">
    <button class="btn-cancel ml-4 w-100" (click)="activeModal.close(false)" ngbAutoFocus translate>
      organisation_people.add_organisation_people.Cancel
    </button>
    <button class="btn-save ml-4 w-100" *ngIf="!isLoading" (click)="save()" translate>
      organisation_people.add_organisation_people.Submit
    </button>
    <button class="btn-save ml-4 w-100" disabled *ngIf="isLoading">
      <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
    </button>
  </div>
</div>
