import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { OrganisationService } from '@app/core/organisation.service';
import { UserService } from '@app/core/user.service';
import { NgbActiveModal, NgbDate, NgbDateStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { finalize } from 'rxjs/operators';
import { SidenavService } from '@app/core/modal/sidenav.service';

@Component({
  selector: 'app-reassign-editor-approver',
  templateUrl: './reassign-editor-approver.component.html',
  styleUrls: ['./reassign-editor-approver.component.scss']
})
export class ReassignEditorApproverComponent implements OnInit {
  loginUser: any;
  loginUsername: string;
  loginUserEmail: string;
  OrganisationID: string | null;
  guidSubscription: any;
  minDate: NgbDateStruct;
  reassignEditorApproverForm: FormGroup;
  backend_error: string;
  statusData: any;
  isLoading: boolean = false;
  private nameColors = new Map<string, string>();
  store_due_date = '';
  @Input() due_date: any;
  @Input() role: any;
  @Input() DocumentGuid: string;
  @Input() guid: string;
  @Input() user: string;
  @Input() userEmail: string;

  constructor(
    private userservice: UserService,
    private documentsService: DocumentsService,
    private orgService: OrganisationService,
    private formBuilder: FormBuilder,
    private backendErrorService: BackendErrorService,
    private sidenavService: SidenavService
  ) {}

  ngOnInit(): void {
    this.loginUser = this.userservice.getuserdetails();
    this.loginUsername = this.loginUser.user['username'];
    this.loginUserEmail = this.loginUser.user['email'];

    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.minDate = this.documentsService.getTomorrowDate();
    });
    if (this.due_date != null) {
      this.store_due_date = this.due_date;
      const due_date_arr = this.due_date.split('-');
      this.due_date = new NgbDate(parseInt(due_date_arr[0]), parseInt(due_date_arr[1]), parseInt(due_date_arr[2]));
    }
    this.getreassignEditorApproverForm();
  }
  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  getreassignEditorApproverForm() {
    this.reassignEditorApproverForm = this.formBuilder.group({
      due_date: [this.due_date, [Validators.required(), Validators.DateIsAfterToday()]],
      comment: ['', Validators.maxLength(4096)]
    });
  }

  get f() {
    return this.reassignEditorApproverForm.controls;
  }

  getInitials(fullName: string): string {
    const names = fullName.split(' ');
    let initials = names[0].charAt(0).toUpperCase();
    if (names.length > 1) {
      initials += names[1].charAt(0).toUpperCase();
    }
    return initials;
  }
  getColor(name: string): string {
    if (this.nameColors.has(name)) {
      return this.nameColors.get(name)!;
    } else {
      const color = this.getRandomColor();
      this.nameColors.set(name, color);
      return color;
    }
  }
  getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  onDateSelection(date: any) {
    if (date) {
      let myDate = new Date(date.year, date.month - 1, date.day + 1);
      this.store_due_date = myDate.toISOString().split('T')[0];
    } else {
      this.store_due_date = this.store_due_date;
    }
  }
  save() {
    let editor: any = [];
    if (this.reassignEditorApproverForm.valid) {
      editor = this.guid;
      this.isLoading = true;
      const reassignData = this.reassignEditorApproverForm.value;
      reassignData['organization_users'] = [editor];
      reassignData['role'] = this.role;
      reassignData['due_date'] = this.store_due_date;
      // const myDate = new Date(
      //   reassignData['due_date'].year,
      //   reassignData['due_date'].month - 1,
      //   reassignData['due_date'].day + 1
      // );
      // reassignData['due_date'] = myDate.toISOString().split('T')[0];
      this.submitDueDateInviteEditor(reassignData);
    } else {
      return;
    }
  }
  closeNav() {
    this.sidenavService.close(true);
  }

  submitDueDateInviteEditor(reassignData: any): void {
    this.documentsService
      .submitInviteDocumentEditorData(this.OrganisationID, this.DocumentGuid, reassignData)
      .pipe(
        finalize(() => {
          this.reassignEditorApproverForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.statusData = data;
          this.backend_error = '';
          this.sidenavService.updateData({ isUpdate: true, result: data });
          this.sidenavService.close(false);
        },
        (error: any) => {
          this.isLoading = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
            this.sidenavService.updateData(error, true);
          });
        }
      );
  }
}
