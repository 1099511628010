import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { finalize } from 'rxjs/operators';
import { SettingsService } from '@app/settings/settings.service';
import { Router } from '@angular/router';
import { IncidentService } from '@app/Organisation-admin/incident/incident.service';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
@Component({
  selector: 'app-approve-incident',
  templateUrl: './approve-incident.component.html',
  styleUrls: ['./approve-incident.component.scss']
})
export class ApproveIncidentComponent implements OnInit {
  approveIncidentForm: FormGroup;
  submitted = false;
  isLoading = false;
  OrganisationID: string | null;
  guidSubscription: any;
  success: boolean;
  error: any;
  UserSignature: any = null;
  isSignatureLoading = true;
  signature_error: boolean = false;
  agreement_accepted: boolean = true;
  @Input() formType: string;
  @Input() title_tag: string;
  @Input() incidentGuid: string;
  @Input() incidentTitle: string;
  @Input() status_to_be_sent: string;
  @Input() action: string;
  statusData: any;
  backend_error: string;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private incidentService: IncidentService,
    private settingsService: SettingsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getApproveIncidentForm();
    });

    this.settingsService.getUserSignature(this.OrganisationID).subscribe(
      data => {
        this.UserSignature = data.url;
        this.isSignatureLoading = false;
      },
      error => {
        this.isSignatureLoading = false;
      }
    );
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  getApproveIncidentForm() {
    this.approveIncidentForm = this.formBuilder.group({
      status: this.status_to_be_sent,
      action: this.action,
      remarks: [''],
      agreement_accepted: ['']
    });
  }

  changeName(statusData: any) {
    this.incidentService.setStatusforApprove(statusData);
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.approveIncidentForm.controls;
  }

  save() {
    this.submitted = true;
    this.isLoading = true;
    if (this.approveIncidentForm.valid && this.UserSignature) {
      const approveIncidentData = this.approveIncidentForm.value;
      if (approveIncidentData['agreement_accepted']) {
        this.agreement_accepted = true;
        this.submitApproveChanges(approveIncidentData);
      } else {
        this.isLoading = false;
        this.submitted = false;
        this.agreement_accepted = false;
      }
    } else {
      this.submitted = false;
      this.isLoading = false;
      if (!this.UserSignature) {
        this.signature_error = true;
      } else {
        this.signature_error = false;
      }
      return;
    }
  }

  submitApproveChanges(approveIncidentData: any): void {
    this.incidentService
      .submitApproveIncidentData(this.OrganisationID, this.incidentGuid, approveIncidentData)
      .pipe(
        finalize(() => {
          this.approveIncidentForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.statusData = data;
          this.changeName(this.statusData);
          this.success = true;
          this.error = {
            remarks: ''
          };
          this.backend_error = '';
          this.submitted = false;
          this.activeModal.close(true);
        },
        (error: any) => {
          this.submitted = false;
          this.isLoading = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
  }

  goToSignatureUpload() {
    this.activeModal.close(false);
    this.router.navigate(['organisation/settings/profile']);
  }
}
