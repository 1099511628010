<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div class="row">
    <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
      <h2 class="text-cyan m-0" style="cursor:default" translate>Incident Details</h2>
    </div>
  </div>
  <div class="class2">
    <div class="row scroll-view">
      <div class="col">
        <div class="my-5 mx-3">
          <div class="row mt-4">
            <div class="card " style="height: 10em !important;width: 90%;margin-left: 100px;">
              <div>
                <div>
                  <div class="modal-header cb-modal-header">
                    <div class="row">
                      <h4 class="modal-title font-weight-bold text-1">Details</h4>
                      <div class="action-button" *ngIf="jiraTicketAssignee['author_actions'].length != '0'">
                        <mat-icon class="action_button" [matMenuTriggerFor]="menu">more_vert</mat-icon>
                        <mat-menu xPosition="before" #menu="matMenu" class="fixed-menu-panel">
                          <ng-container *ngFor="let actions of jiraTicketAssignee['author_actions']">
                            <button
                              mat-menu-item
                              (click)="
                                getClickEvents(
                                  actions,
                                  jiraTicketAssignee['guid'],
                                  jiraTicketAssignee['created_by']['email'],
                                  jiraTicketAssignee['title'],
                                  jiraTicketAssignee['created_by']['name'],
                                  actions.status_to_be_sent,
                                  jiraTicketAssignee['document_store_identifier']
                                )
                              "
                            >
                              {{ actions['value'] }}
                            </button>
                          </ng-container>
                        </mat-menu>
                      </div>
                    </div>
                  </div>
                  <table class="table table-borderless">
                    <tbody>
                      <tr>
                        <td class="col-12">
                          <h4 class="text-black-1 text-left" scope="row">
                            <div class=" text-black-2 mr-2 " style="font-size: 14px; ">
                              <span class="text-black-1 mr-2 font-weight-bold ">
                                Title:
                                <span style="margin-left: 9px;" class="text-black-1 mr-2  ">
                                  {{ jiraTicketAssignee['title'] }}
                                </span>
                              </span>
                              <span style="margin-left: 54px;" class="text-black-1 mr-2 font-weight-bold ">
                                Status:
                                <span style="margin-left: 9px;" class="text-black-1 mr-2  ">
                                  {{ jiraTicketAssignee['status'] }}
                                </span>
                              </span>
                              <span style="margin-left: 80px !important;" class="text-black-1 mr-2 font-weight-bold ">
                                Severity:
                                <span style="margin-left: 9px;" class="text-black-1 mr-2 ">
                                  {{ jiraTicketAssignee['severity'] }}
                                </span>
                              </span>
                              <br />
                              <span class="text-black-1 mr-2 font-weight-bold ">
                                Category:
                                <span style="margin-left: 10px;" class="text-black-1 font-weight-bold mr-2 ">
                                  {{ jiraTicketAssignee['category'] }}
                                </span>
                              </span>
                              <span style="margin-left: 70px !important;" class="text-black-1 mr-2 font-weight-bold ">
                                Created By:
                                <span style="margin-left: 10px;" class="text-black-1 font-weight-bold mr-2 ">
                                  {{ jiraTicketAssignee['created_by']['name'] }}
                                </span>
                              </span>
                              <span style="margin-left: 70px !important;" class="text-black-1 mr-2 font-weight-bold">
                                Created Date:
                                <span style="margin-left: 10px;" class="text-black-1 font-weight-bold mr-2 ">
                                  {{ jiraTicketAssignee['created_timestamp'] | date: 'yyyy-MM-dd' }}
                                </span>
                              </span>
                              <br />
                              <span class="text-black-1 mr-2 font-weight-bold ">
                                Reported By:
                                <span style="margin-left: 9px;" class="text-black-1 mr-2  ">
                                  {{ jiraTicketAssignee['reported_by'] }}
                                </span>
                              </span>
                              <span class="text-black-1 mr-2 font-weight-bold " style="margin-left: 60px !important;">
                                Reported Date:
                                <span style="margin-left: 9px;" class="text-black-1 mr-2  ">
                                  {{ jiraTicketAssignee['reported_date'] | date: 'yyyy-MM-dd' }}
                                </span>
                              </span>
                              <br />
                              <span class="text-black-1 font-weight-bold mr-2 ">
                                Description:
                              </span>
                              <span class="text-black font-weight-bold mr-2 ">
                                {{ jiraTicketAssignee['description'] }}
                              </span>
                            </div>
                          </h4>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    style="margin-top: 250px;width: 77%;margin-left: 99px;"
    class="cb-accordian "
    [ngClass]="!accArray.includes(1) ? '' : 'expand'"
    [attr.data-index]="1"
  >
    <div class="acc-header d-flex justify-content-between">
      <div class="col-lg-8">
        <h5 class="text-black-1 font-weight-bold">
          Jira tickets
        </h5>
      </div>
      <div class="col-33" *ngIf="jiraTicketAssignee['author_actions'].length != '0'">
        <mat-icon class="action_button" [matMenuTriggerFor]="menu1">more_vert</mat-icon>
        <mat-menu xPosition="before" #menu1="matMenu" class="fixed-menu-panel">
          <ng-container *ngFor="let actions of jiraTicketAssignee['author_actions']">
            <button
              mat-menu-item
              (click)="
                getClickEvents(
                  actions,
                  jiraTicketAssignee['guid'],
                  jiraTicketAssignee['created_by']['email'],
                  jiraTicketAssignee['title'],
                  jiraTicketAssignee['created_by']['name'],
                  actions.status_to_be_sent,
                  jiraTicketAssignee['document_store_identifier']
                )
              "
            >
              {{ actions['value'] }}
            </button>
          </ng-container>
        </mat-menu>
      </div>
      <div class="d-flex align-items-center">
        <button class="btn-acc-toggle ml-4 mr-3" (click)="toggleDetails(1)">
          <i
            [ngClass]="!accArray.includes(1) ? 'fas fa-chevron-down text-black-10' : 'fas fa-chevron-up text-black-10'"
          ></i>
        </button>
      </div>
    </div>
    <div class="acc-body px-3">
      <table
        style="width: 130%;margin-left: -76px;"
        class="w-100p bg-white dm-table table table-responsive"
        *ngIf="jiraTicketAssignee['jira_ticket_details']?.length > 0; else no_content"
      >
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td class="col-12">
                <h4 class="text-black-1 text-left" scope="row">
                  <div class=" text-black-2 mr-2 " *ngFor="let ticket of jiraTicketAssignee['jira_ticket_details']">
                    <a
                      [href]="ticket['jira_url']"
                      target="_blank"
                      style="margin-left: 50px !important;font-size: 14px"
                      class="text-black-4 dm-text-2 mt-3 "
                    >
                      {{ ticket['jira_id'] }}
                    </a>
                    <hr />
                  </div>
                </h4>
              </td>
            </tr>
          </tbody>
        </table>
      </table>
    </div>
  </div>
  <div
    style="margin-top: 50px;width: 77%;margin-left: 99px;"
    class="cb-accordian "
    [ngClass]="!accArray.includes(0) ? '' : 'expand'"
    [attr.data-index]="0"
  >
    <div class="acc-header d-flex justify-content-between">
      <div class="col-lg-8">
        <h5 class="text-black-1 font-weight-bold">{{ reviewerList.length }} Reviewers</h5>
      </div>
      <div class="action-button" *ngIf="jiraTicketAssignee['reviewer_actions'].length != '0'">
        <mat-icon class="action_button" [matMenuTriggerFor]="menu2">more_vert</mat-icon>
        <mat-menu xPosition="before" #menu2="matMenu" class="fixed-menu-panel">
          <ng-container *ngFor="let actions of jiraTicketAssignee['reviewer_actions']">
            <button
              mat-menu-item
              (click)="
                getClickEvents(
                  actions,
                  jiraTicketAssignee['guid'],
                  jiraTicketAssignee['created_by']['email'],
                  jiraTicketAssignee['title'],
                  jiraTicketAssignee['created_by']['name'],
                  actions.status_to_be_sent,
                  jiraTicketAssignee['document_store_identifier']
                )
              "
            >
              {{ actions['value'] }}
            </button>
          </ng-container>
        </mat-menu>
      </div>

      <div class="d-flex align-items-center">
        <button class="btn-acc-toggle ml-4 mr-3" (click)="toggleDetails(0)">
          <i
            [ngClass]="!accArray.includes(0) ? 'fas fa-chevron-down text-black-10' : 'fas fa-chevron-up text-black-10'"
          ></i>
        </button>
      </div>
    </div>
    <div class="acc-body px-3">
      <table
        style="width: 130%;margin-left: -76px;"
        class="w-100p bg-white dm-table table table-responsive"
        *ngIf="reviewerList?.length > 0; else no_content_1"
      >
        <thead>
          <tr style="height: 65px;">
            <th scope="col" class="font-weight-bold text-black-3 text-center" translate>
              Reviewer
            </th>
            <th scope="col" class="font-weight-bold text-black-9 " translate>
              Status
            </th>
            <th scope="col" class="font-weight-bold text-black-3" translate>
              Due date
            </th>
            <th scope="col" class="font-weight-bold text-black-3 text-center"></th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-center" *ngFor="let reviewers of reviewerList">
            <td>
              <h4 class="text-black-3 dm-text-2 mt-3 ">{{ reviewers['user']['name'] }}</h4>
            </td>
            <td>
              <h4 class="text-black-3 dm-text-2 mt-3 ">{{ reviewers['status'] }}</h4>
            </td>
            <td>
              <h4 class="text-black-3 dm-text-2 mt-3">{{ reviewers['due_date'] }}</h4>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div
    style="margin-top: 50px;width: 77%;margin-left: 99px;"
    class="cb-accordian "
    [ngClass]="!accArray.includes(2) ? '' : 'expand'"
    [attr.data-index]="2"
  >
    <div class="acc-header d-flex justify-content-between">
      <div class="col-lg-8">
        <h5 class="text-black-1 font-weight-bold">{{ approverList.length }} Approvers</h5>
      </div>
      <div class="action-button" *ngIf="jiraTicketAssignee['approver_actions'].length != '0'">
        <mat-icon class="action_button" [matMenuTriggerFor]="menu3">more_vert</mat-icon>
        <mat-menu xPosition="before" #menu3="matMenu" class="fixed-menu-panel">
          <ng-container *ngFor="let actions of jiraTicketAssignee['approver_actions']">
            <button
              mat-menu-item
              (click)="
                getClickEvents(
                  actions,
                  jiraTicketAssignee['guid'],
                  jiraTicketAssignee['created_by']['email'],
                  jiraTicketAssignee['title'],
                  jiraTicketAssignee['created_by']['name'],
                  actions.status_to_be_sent,
                  jiraTicketAssignee['document_store_identifier']
                )
              "
            >
              {{ actions['value'] }}
            </button>
          </ng-container>
        </mat-menu>
      </div>
      <div class="d-flex align-items-center">
        <button class="btn-acc-toggle ml-4 mr-3" (click)="toggleDetails(2)">
          <i
            [ngClass]="!accArray.includes(2) ? 'fas fa-chevron-down text-black-10' : 'fas fa-chevron-up text-black-10'"
          ></i>
        </button>
      </div>
    </div>
    <div class="acc-body px-3">
      <table
        style="width: 130%;margin-left: -76px;"
        class="w-100p bg-white dm-table table table-responsive"
        *ngIf="approverList?.length > 0; else no_content_2"
      >
        <thead>
          <tr style="height: 65px;">
            <th scope="col" class="font-weight-bold text-black-3 text-center" translate>
              Approver
            </th>
            <th scope="col" class="font-weight-bold text-black-9 " translate>
              Status
            </th>
            <th scope="col" class="font-weight-bold text-black-3" translate>
              Due date
            </th>
            <th scope="col" class="font-weight-bold text-black-3 text-center"></th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-center" *ngFor="let approver of approverList">
            <td>
              <h4 class="text-black-3 dm-text-2 mt-3 ">{{ approver['user']['name'] }}</h4>
            </td>
            <td>
              <h4 class="text-black-3 dm-text-2 mt-3 ">{{ approver['status'] }}</h4>
            </td>
            <td>
              <h4 class="text-black-3 dm-text-2 mt-3">{{ approver['due_date'] }}</h4>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div
    style="margin-top: 50px;width: 77%;margin-left: 99px;"
    class="cb-accordian "
    [ngClass]="!accArray.includes(4) ? '' : 'expand'"
    [attr.data-index]="4"
  >
    <div class="acc-header d-flex justify-content-between">
      <div class="col-lg-8">
        <h5 class="text-black-1 font-weight-bold">
          History
        </h5>
      </div>
      <div class="d-flex align-items-center">
        <button class="btn-acc-toggle ml-4 mr-3" (click)="toggleDetails(4)">
          <i
            [ngClass]="!accArray.includes(4) ? 'fas fa-chevron-down text-black-10' : 'fas fa-chevron-up text-black-10'"
          ></i>
        </button>
      </div>
    </div>
    <hr style="width:100%;text-align:left;margin-top:0" />
    <div class="row mt-4">
      <div>
        <div>
          <table class="table table-borderless">
            <tbody>
              <tr *ngFor="let history of history_data">
                <td class="col-12">
                  <h4 class="text-black-1 text-left" scope="row">
                    <div
                      class=" text-black-1 mr-2 "
                      style="font-size: 14px; "
                      *ngIf="history.history.action_type == 'create_incident'"
                    >
                      <span class="i-circle text-white mr-2 font-weight-bold col-12">{{
                        history.created_by_Initial
                      }}</span>
                      <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                        history.created_by
                      }}</span
                      >created a new incident on {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }} at
                      {{ history.history['action_timestamp'] | date: ' h:mm a' }}.
                    </div>

                    <div
                      class=" text-black-1 mr-2 "
                      style="font-size: 14px; "
                      *ngIf="history.history.action_type == 'create_tickets'"
                    >
                      <span class="i-circle text-white mr-2 font-weight-bold col-12">{{
                        history.created_by_Initial
                      }}</span>
                      <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                        history.created_by
                      }}</span
                      >created a new ticket on {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }} at
                      {{ history.history['action_timestamp'] | date: ' h:mm a' }}.
                    </div>

                    <div
                      class=" text-black-1 mr-2 "
                      style="font-size: 14px; "
                      *ngIf="history.history.action_type == 'link_tickets'"
                    >
                      <span class="i-circle text-white mr-2 font-weight-bold col-12">{{
                        history.created_by_Initial
                      }}</span>
                      <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                        history.created_by
                      }}</span
                      >Linked a ticket on {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }} at
                      {{ history.history['action_timestamp'] | date: ' h:mm a' }}.
                    </div>

                    <div
                      class=" text-black-1 mr-2"
                      style="font-size: 14px;"
                      *ngIf="history.history.action_type == 'invite_reviewer'"
                    >
                      <span
                        class="i-circle text-white mr-2 font-weight-bold col-12"
                        *ngIf="history.username.assignedTo?.length == 1; else groupIcon3"
                        >{{ history.inviteEditorInitial }}</span
                      >
                      <ng-template #groupIcon3>
                        <img src="../../../assets/images/documents/group_user.png" class="cb-icon" />
                      </ng-template>
                      <span translate class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                        history.initiatedby
                      }}</span
                      >added {{ history.username.assignedTo }}
                      <span
                        class=" text-black-1 mr-2  ml-1"
                        style="font-size: 14px; font-weight: bold;"
                        *ngIf="history.username.assignedTo?.length == 1; else groupUser3"
                        >{{ history.username.assignedTo }}</span
                      >
                      <ng-template ngFor let-user [ngForOf]="history.username" let-last="last">
                        <span class="text-black-1 mr-2 ml-1" style="font-size: 14px; font-weight: bold;">
                          {{ user.assignedTo }}
                        </span>
                        <span *ngIf="!last">,</span>
                      </ng-template>
                      as a reviewer on {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }}&nbsp; at
                      {{ history.history['action_timestamp'] | date: ' h:mm a' }}.
                      <i
                        *ngIf="history.comment"
                        ngbTooltip="{{ history.comment }}"
                        style="color:gray;font-size:17px"
                        tooltipClass="cb-tooltip"
                        class="far fa-comment-alt"
                      ></i>
                      <div
                        class="text-black-1"
                        style="font-size: 14px; margin-top: 2.2em;margin-left: 0.11em;
                         margin-right: 0.11em;"
                        *ngIf="history.history['currentState'] != history.history['previousState']"
                      >
                        <span class="i-circle text-white mr-2 font-weight-bold col-12">{{
                          history.inviteEditorInitial
                        }}</span>
                        <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                          history.initiatedby
                        }}</span
                        >{{ this.changeStatus }} {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }}&nbsp;
                        {{ this.at }} {{ history.history['action_timestamp'] | date: ' h:mm a' }}.
                      </div>
                      <div
                        class="mt-2 ml-5"
                        *ngIf="history.history['currentState'] != history.history['previousState']"
                      >
                        <button
                          class="ml-4 color col-5 text-black-1"
                          style="font-size: 14px; width: fit-content;"
                          translate
                        >
                          {{ history.history['previousState'] }}
                        </button>
                        <span class="col-2 w-100" style="margin-left: 1em">
                          <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        </span>
                        <button
                          class="ml-3 color col-5 text-black-1"
                          style="font-size: 14px; width: fit-content;"
                          translate
                        >
                          {{ history.history['currentState'] }}
                        </button>
                      </div>
                    </div>

                    <div
                      class=" text-black-1 mr-2 "
                      style="font-size: 14px; "
                      *ngIf="history.history.action_type == 'revoke_reviewer'"
                    >
                      <span class="i-circle text-white mr-2 font-weight-bold col-12">{{
                        history.revokeEditorInitial
                      }}</span>
                      <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                        history.editorRevokedBy
                      }}</span
                      >removed
                      <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                        history.editorRevoke
                      }}</span
                      >as a reviewer on {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }} at
                      {{ history.history['action_timestamp'] | date: ' h:mm a' }}.<i
                        *ngIf="history.comment"
                        ngbTooltip="{{ history.comment }}"
                        style="color:gray;font-size:17px"
                        tooltipClass="cb-tooltip"
                        class="far fa-comment-alt"
                      ></i>
                      <div
                        class="mt-2 ml-5"
                        *ngIf="history.history['currentState'] != history.history['previousState']"
                      >
                        <button
                          class="ml-4 color col-5 text-black-1"
                          style="font-size: 14px; width: fit-content;"
                          translate
                        >
                          {{ history.history['previousState'] }}
                        </button>
                        <span class="col-2 w-100" style="margin-left: 1em">
                          <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        </span>
                        <button
                          class="ml-3 color col-5 text-black-1"
                          style="font-size: 14px; width: fit-content;"
                          translate
                        >
                          {{ history.history['currentState'] }}
                        </button>
                      </div>
                    </div>
                    <div
                      class=" text-black-1 mr-2 "
                      style="font-size: 14px; "
                      *ngIf="history.history.action_type == 'revoke_approver'"
                    >
                      <span class="i-circle text-white mr-2 font-weight-bold col-12">{{
                        history.revokeApproverInitial
                      }}</span>
                      <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                        history.approverRevokedBy
                      }}</span
                      >removed
                      <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                        history.approverRevoke
                      }}</span>
                      as a approver on {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }} at
                      {{ history.history['action_timestamp'] | date: ' h:mm a' }}.
                      <i
                        *ngIf="history.comment"
                        ngbTooltip="{{ history.comment }}"
                        style="color:gray;font-size:17px"
                        tooltipClass="cb-tooltip"
                        class="far fa-comment-alt"
                      ></i>
                      <div
                        class="mt-2 ml-5"
                        *ngIf="history.history['currentState'] != history.history['previousState']"
                      >
                        <button
                          class="ml-4 color col-5 text-black-1"
                          style="font-size: 14px; width: fit-content;"
                          translate
                        >
                          {{ history.history['previousState'] }}
                        </button>
                        <span class="col-2 w-100" style="margin-left: 1em">
                          <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        </span>
                        <button
                          class="ml-3 color col-5 text-black-1"
                          style="font-size: 14px; width: fit-content;"
                          translate
                        >
                          {{ history.history['currentState'] }}
                        </button>
                      </div>
                    </div>

                    <div
                      class=" text-black-1 mr-2"
                      style="font-size: 14px;"
                      *ngIf="history.history.action_type == 'invite_approver'"
                    >
                      <span
                        class="i-circle text-white mr-2 font-weight-bold col-12"
                        *ngIf="history.assignedTo?.length == 1; else groupIcon4"
                        >{{ history.inviteApproverInitial }}</span
                      >
                      <ng-template #groupIcon4>
                        <img src="../../../assets/images/documents/group_user.png" class="cb-icon" />
                      </ng-template>
                      <span translate class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                        history.initiatedby
                      }}</span
                      >added {{ history.username.assignedTo }}
                      <span
                        class=" text-black-1 mr-2  ml-1"
                        style="font-size: 14px; font-weight: bold;"
                        *ngIf="history.username.assignedTo?.length == 1; else groupUser3"
                        >{{ history.username.assignedTo }}</span
                      >
                      <ng-template ngFor let-user [ngForOf]="history.username" let-last="last">
                        <span class="text-black-1 mr-2 ml-1" style="font-size: 14px; font-weight: bold;">
                          {{ user.assignedTo }}
                        </span>
                        <span *ngIf="!last">,</span>
                      </ng-template>
                      as an approver {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }}&nbsp; at
                      {{ history.history['action_timestamp'] | date: ' h:mm a' }}.<i
                        *ngIf="history.comment"
                        ngbTooltip="{{ history.comment }}"
                        style="color:gray;font-size:17px"
                        tooltipClass="cb-tooltip"
                        class="far fa-comment-alt"
                      ></i>

                      <div
                        class="text-black-1"
                        style="font-size: 14px; margin-top: 2.2em;margin-left: 0.11em;
                            margin-right: 0.11em;"
                        *ngIf="history.history['currentState'] != history.history['previousState']"
                      >
                        <span class="i-circle text-white mr-2 font-weight-bold col-12">{{
                          history.inviteApproverInitial
                        }}</span>
                        <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                          history.initiatedby
                        }}</span
                        >{{ this.changeStatus }} {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }}&nbsp;
                        {{ this.at }} {{ history.history['action_timestamp'] | date: ' h:mm a' }}.
                      </div>

                      <div
                        class="mt-2 ml-5"
                        *ngIf="history.history['currentState'] != history.history['previousState']"
                      >
                        <button
                          class="ml-4 color col-5 text-black-1"
                          style="font-size: 14px; width: fit-content;"
                          translate
                        >
                          {{ history.history['previousState'] }}
                        </button>
                        <span class="col-2 w-100" style="margin-left: 1em">
                          <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        </span>
                        <button
                          class="ml-3 color col-5 text-black-1"
                          style="font-size: 14px; width: fit-content;"
                          translate
                        >
                          {{ history.history['currentState'] }}
                        </button>
                      </div>
                    </div>

                    <div
                      class=" text-black-1 mr-2"
                      style="font-size: 14px;"
                      *ngIf="history.history.action_type == 'reopen_incident'"
                    >
                      <span
                        class="i-circle text-white mr-2 font-weight-bold col-12"
                        *ngIf="history.changeRequestAssignTo?.length == 1; else groupIcon1"
                        >{{ history.reqChangeInitiatedIntial }}</span
                      >
                      <ng-template #groupIcon1>
                        <img src="../../../assets/images/documents/group_user.png" class="cb-icon" />
                      </ng-template>
                      <span class="text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;"
                        >{{ history.changeRequestInitiatedBy }} </span
                      >reopened incident and added {{ history.username.assignedTo }}
                      <span
                        class=" text-black-1 mr-2  ml-1"
                        style="font-size: 14px; font-weight: bold;"
                        *ngIf="history.username.assignedTo?.length == 1; else groupUser3"
                        >{{ history.username.assignedTo }}</span
                      >
                      <ng-template ngFor let-user [ngForOf]="history.username" let-last="last">
                        <span class="text-black-1 mr-2 ml-1" style="font-size: 14px; font-weight: bold;">
                          {{ user.assignedTo }}
                        </span>
                        <span *ngIf="!last">,</span>
                      </ng-template>
                      as a reviewer on
                      {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }}&nbsp; at
                      {{ history.history['action_timestamp'] | date: ' h:mm a' }}.
                      <i
                        *ngIf="history.comment"
                        ngbTooltip="{{ history.comment }}"
                        style="color:gray;font-size:17px"
                        tooltipClass="cb-tooltip"
                        class="far fa-comment-alt"
                      ></i>

                      <div
                        class="text-black-1"
                        style="font-size: 14px; margin-top: 2.2em;margin-left: 0.11em;
                    margin-right: 0.11em;"
                        *ngIf="history.history['currentState'] != history.history['previousState']"
                      >
                        <span class="i-circle text-white mr-2 font-weight-bold col-12">{{
                          history.reqChangeInitiatedIntial
                        }}</span>
                        <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                          history.changeRequestInitiatedBy
                        }}</span
                        >{{ this.changeStatus }} {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }}&nbsp;
                        {{ this.at }} {{ history.history['action_timestamp'] | date: ' h:mm a' }}.
                      </div>

                      <div
                        class="mt-2 ml-5"
                        *ngIf="history.history['currentState'] != history.history['previousState']"
                      >
                        <button
                          class="ml-4 color col-5 text-black-1"
                          style="font-size: 14px; width: fit-content;"
                          translate
                        >
                          {{ history.history['previousState'] }}
                        </button>
                        <span class="col-2 w-100" style="margin-left: 1em">
                          <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        </span>
                        <button
                          class="ml-3 color col-5 text-black-1"
                          style="font-size: 14px; width: fit-content;"
                          translate
                        >
                          {{ history.history['currentState'] }}
                        </button>
                      </div>
                    </div>

                    <div
                      class=" text-black-1 mr-2 "
                      style="font-size: 14px; "
                      *ngIf="history.history.action_type == 'move_to_in_progress'"
                    >
                      <span class="i-circle text-white mr-2 font-weight-bold col-12">{{
                        history.editingDoneInitial
                      }}</span>
                      <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                        history.completed_by
                      }}</span
                      >moved the incident to in progress on
                      {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }} at
                      {{ history.history['action_timestamp'] | date: ' h:mm a' }}.<i
                        *ngIf="history.comment"
                        ngbTooltip="{{ history.comment }}"
                        style="color:gray;font-size:17px"
                        tooltipClass="cb-tooltip"
                        class="far fa-comment-alt"
                      ></i>
                      <div
                        class="mt-2 ml-5"
                        *ngIf="history.history['currentState'] != history.history['previousState']"
                      >
                        <button
                          class="ml-4 color col-5 text-black-1"
                          style="font-size: 14px; width: fit-content;"
                          translate
                        >
                          {{ history.history['previousState'] }}
                        </button>
                        <span class="col-2 w-100" style="margin-left: 1em">
                          <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        </span>
                        <button
                          class="ml-3 color col-5 text-black-1"
                          style="font-size: 14px; width: fit-content;"
                          translate
                        >
                          {{ history.history['currentState'] }}
                        </button>
                      </div>
                    </div>

                    <div
                      class=" text-black-1 mr-2 "
                      style="font-size: 14px; "
                      *ngIf="history.history.action_type == 'edit_incident'"
                    >
                      <span class="i-circle text-white mr-2 font-weight-bold col-12">{{
                        history.editingDoneInitial
                      }}</span>
                      <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                        history.completed_by
                      }}</span
                      >editted the incident on {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }} at
                      {{ history.history['action_timestamp'] | date: ' h:mm a' }}.
                      <div
                        class="mt-2 ml-5"
                        *ngIf="history.history['currentState'] != history.history['previousState']"
                      >
                        <button
                          class="ml-4 color col-5 text-black-1"
                          style="font-size: 14px; width: fit-content;"
                          translate
                        >
                          {{ history.history['previousState'] }}
                        </button>
                        <span class="col-2 w-100" style="margin-left: 1em">
                          <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        </span>
                        <button
                          class="ml-3 color col-5 text-black-1"
                          style="font-size: 14px; width: fit-content;"
                          translate
                        >
                          {{ history.history['currentState'] }}
                        </button>
                      </div>
                    </div>
                    <div
                      class=" text-black-1 mr-2"
                      style="font-size: 14px;"
                      *ngIf="history.history.action_type == 'all_moved_to_resolved'"
                    >
                      <img src="../../../assets/images/documents/group_user.png" class="cb-icon" />
                      All
                      <span class="ml-1 text-black-1" style="font-weight: bold; font-size: 14px;" translate
                        >Reviewers</span
                      >
                      {{ this.complete }}
                      {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }}&nbsp; {{ this.at }}
                      {{ history.history['action_timestamp'] | date: ' h:mm a' }}.
                      <div
                        class="mt-2 ml-5"
                        *ngIf="history.history['currentState'] != history.history['previousState']"
                      >
                        <button
                          class="ml-4 color col-5 text-black-1"
                          style="font-size: 14px; width: fit-content;"
                          translate
                        >
                          {{ history.history['previousState'] }}
                        </button>
                        <span class="col-2 w-100" style="margin-left: 1em">
                          <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        </span>
                        <button
                          class="ml-3 color col-5 text-black-1"
                          style="font-size: 14px; width: fit-content;"
                          translate
                        >
                          {{ history.history['currentState'] }}
                        </button>
                      </div>
                    </div>
                    <div
                      class=" text-black-1 mr-2"
                      style="font-size: 14px;"
                      *ngIf="history.history.action_type == 'resolve_incident'"
                    >
                      <span class="i-circle text-white mr-2 font-weight-bold col-12">{{ history.alleditInitial }}</span>

                      <span class="ml-1 text-black-1" style="font-weight: bold; font-size: 14px;" translate>{{
                        history.allcompleteedit
                      }}</span>
                      resolved the incident on
                      {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }}&nbsp; at
                      {{ history.history['action_timestamp'] | date: ' h:mm a' }}.<i
                        *ngIf="history.comment"
                        ngbTooltip="{{ history.comment }}"
                        style="color:gray;font-size:17px"
                        tooltipClass="cb-tooltip"
                        class="far fa-comment-alt"
                      ></i>
                      <div
                        class="mt-2 ml-5"
                        *ngIf="history.history['currentState'] != history.history['previousState']"
                      >
                        <button
                          class="ml-4 color col-5 text-black-1"
                          style="font-size: 14px; width: fit-content;"
                          translate
                        >
                          {{ history.history['previousState'] }}
                        </button>
                        <span class="col-2 w-100" style="margin-left: 1em">
                          <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        </span>
                        <button
                          class="ml-3 color col-5 text-black-1"
                          style="font-size: 14px; width: fit-content;"
                          translate
                        >
                          {{ history.history['currentState'] }}
                        </button>
                      </div>
                    </div>

                    <div
                      class=" text-black-1 mr-2 "
                      style="font-size: 14px; "
                      *ngIf="history.history.action_type == 'block_incident'"
                    >
                      <span class="i-circle text-white mr-2 font-weight-bold col-12">{{
                        history.created_by_Initial
                      }}</span>
                      <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                        history.created_by
                      }}</span
                      >blocked the incident on {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }} at
                      {{ history.history['action_timestamp'] | date: ' h:mm a' }}.<i
                        *ngIf="history.comment"
                        ngbTooltip="{{ history.comment }}"
                        style="color:gray;font-size:17px"
                        tooltipClass="cb-tooltip"
                        class="far fa-comment-alt"
                      ></i>
                    </div>
                    <div
                      class=" text-black-1 mr-2 "
                      style="font-size: 14px; "
                      *ngIf="history.history.action_type == 'close_incident'"
                    >
                      <span class="i-circle text-white mr-2 font-weight-bold col-12">{{
                        history.created_by_Initial
                      }}</span>
                      <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                        history.created_by
                      }}</span
                      >closed the incident on {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }} at
                      {{ history.history['action_timestamp'] | date: ' h:mm a' }}.<i
                        *ngIf="history.comment"
                        ngbTooltip="{{ history.comment }}"
                        style="color:gray;font-size:17px"
                        tooltipClass="cb-tooltip"
                        class="far fa-comment-alt"
                      ></i>
                    </div>
                  </h4>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #no_content>
  <div class="row ">
    <div class="col">
      <div style="font-size: 18px;" class="text-empty-doc-list text-black-1" translate>
        No jira tickets linked to this incident
      </div>
      <div class="sub-text-empty-doc-list ">
        <a
          class="cursor-pointer text-cyan-force"
          (click)="goToLinkTicket(jiraTicketAssignee['guid'], jiraTicketAssignee['title'])"
          style="font-size: 16px;"
          translate
        >
          link ticket
        </a>
        or
        <a
          class="cursor-pointer text-cyan-force"
          (click)="
            goToCreateTicket(
              jiraTicketAssignee['guid'],
              jiraTicketAssignee['title'],
              jiraTicketAssignee['author_actions']['status_to_be_sent']
            )
          "
          translate
        >
          create new ticket
        </a>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #no_content_1>
  <div class="row ">
    <div class="col">
      <div style="font-size: 18px;" class="text-empty-doc-list text-black-1" translate>
        No reviewer added to this incident
      </div>
      <div *ngIf="jiraTicketAssignee['status'] != 'Closed'" class="sub-text-empty-doc-list ">
        <a
          *ngIf="show_add_new_button"
          class="cursor-pointer text-cyan-force"
          (click)="
            goToAddReviewerApprover(
              jiraTicketAssignee['guid'],
              jiraTicketAssignee['created_by']['email'],
              jiraTicketAssignee['title'],
              'Manage Reviewers'
            )
          "
          style="font-size: 16px;"
          translate
        >
          Add reviewer
        </a>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #no_content_2>
  <div class="row ">
    <div class="col">
      <div style="font-size: 18px;" class="text-empty-doc-list text-black-1" translate>
        No approver added to this incident
      </div>
      <div
        *ngIf="
          jiraTicketAssignee['status'] != 'Closed' ||
          jiraTicketAssignee['status'] == 'Resolved' ||
          jiraTicketAssignee['status'] == 'Pending Closure'
        "
        class="sub-text-empty-doc-list "
      >
        <a
          *ngIf="show_add_new_button"
          class="cursor-pointer text-cyan-force"
          (click)="
            goToAddReviewerApprover(
              jiraTicketAssignee['guid'],
              jiraTicketAssignee['created_by']['email'],
              jiraTicketAssignee['title'],
              'Invite Approvers'
            )
          "
          style="font-size: 16px;"
          translate
        >
          Add approver
        </a>
      </div>
    </div>
  </div>
</ng-template>
