<app-spinner *ngIf="isScreenSpinnerLoading"></app-spinner>
<ng-container *ngIf="!isScreenSpinnerLoading">
  <div class="publish-doc-container">
    <div class="publish-doc-label">
      <div class="label">{{ title_tag == 'Publish Documents' ? 'Publish Document' : title_tag }}</div>
      <div>
        <i
          class="fa fa-times text-black-1"
          style="width: 12px;
                  height: 12px;
                  right: 1em;
                  cursor: pointer;
                  position: absolute;
                  top: 1.3em;"
          (click)="activeModal.close({ isUpdate: false })"
          ngbAutoFocus
          translate
        ></i>
      </div>
    </div>
    <div class="text-body">
      <div class="text" [innerHtml]="message"></div>
    </div>
    <div class="actions-container">
      <button type="button" class=" px-2 cb-btn-secondary" (click)="activeModal.close({ isUpdate: false })">
        Cancel
      </button>
      <button type="submit" class="btn-save cb-btn-primary" *ngIf="!isLoading" (click)="save()">
        {{ title_tag == 'Publish Documents' ? 'Yes, Publish' : 'Yes, Archive' }}
      </button>
      <button type="submit" class="btn-save cb-btn-disabled " disabled *ngIf="isLoading">
        <i class="fa fa-spinner fa-spin"></i>
      </button>
    </div>
  </div>
</ng-container>
