import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { OrganisationService } from '@app/core/organisation.service';
import { SettingsService } from '@app/settings/settings.service';

@Component({
  selector: 'app-expanded-certificate-modal',
  templateUrl: './expanded-certificate-modal.component.html',
  styleUrls: ['./expanded-certificate-modal.component.scss']
})
export class ExpandedCertificateModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ExpandedCertificateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = {},
    private settingsService: SettingsService,
    private orgService: OrganisationService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    const itemsPerColumn =
      this.data.certificateData.documents_list.length > 1
        ? Math.ceil(this.data.certificateData.documents_list.length / 2)
        : this.data.certificateData.documents_list.length;
    const firstColumn = document.getElementById('first-column-expand');
    const secondColumn = document.getElementById('second-column-expand');
    console.log(firstColumn);
    this.data.certificateData.documents_list.forEach((item: any, index: number) => {
      const listItem = document.createElement('li');
      listItem.textContent = item;
      // listItem.classList.add('m-1');
      if (index < itemsPerColumn) {
        firstColumn.appendChild(listItem);
      } else {
        secondColumn.appendChild(listItem);
      }
    });
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
