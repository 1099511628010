import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { SettingsService } from '@app/settings/settings.service';
import { finalize } from 'rxjs/operators';
import { UserService } from '@app/core/user.service';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-templates',
  templateUrl: './select-templates.component.html',
  styleUrls: ['./select-templates.component.scss']
})
export class SelectTemplatesComponent implements OnInit {
  selectTemplateForm: FormGroup;
  submitted = false;
  isLoading = false;
  success: boolean;
  OrganisationID: string | null;
  guidSubscription: any;
  TemplatesList: object[];
  error: any;
  template_name: any;
  selected: any;
  @Input() formType: string;
  @Input() title_tag: string;
  @Input() docTitle: string;
  @Input() template_guid: string;
  @Input() storage_id: string;
  @Input() type: string;
  @Input() template_type: string;
  completeData: any;
  scrollLoader = false;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  page = 1;
  pagnationData: any;
  submittedTemplateData: any;
  loading = true;
  selectedGUID: any = '';
  dataList: object[];
  selectedTemplate: any;
  paginatedDataUrl: any;
  loginUser: any;
  user_guid: any;
  backend_error: any;
  clickedRow: any;
  isViewLoading = false;
  docx = 'docx';
  xlsx = 'xlsx';

  constructor(
    private userservice: UserService,
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private documentsService: DocumentsService,
    private settingsService: SettingsService,
    private backendErrorService: BackendErrorService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loginUser = this.userservice.getuserdetails();
    if (this.loginUser) {
      this.user_guid = this.loginUser.user['org_user_guid'];
    }

    let selectedID = '';
    selectedID = localStorage.getItem(this.storage_id);
    if (selectedID) {
      this.selectedGUID = JSON.parse(selectedID);
      this.selectedGUID = this.selectedGUID['selectedTemplateGuid'];
    }

    this.completeData = this.documentsService.getCompleteData();
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
    });

    this.selectTemplateForm = this.formBuilder.group({
      template: ['', [Validators.required]]
    });

    this.getTemplatesList();
  }

  ngAfterViewInit() {}

  onScrollUp() {
    this.scrollLoader = false;
  }

  onScrollData() {
    this.scrollLoader = true;
    this.documentsService.getOrganisationsTemplatesList(this.OrganisationID, (this.page += 1), this.type).subscribe(
      data => {
        this.scrollLoader = false;
        this.paginatedDataUrl = data;
        if (this.template_type == 'form') {
          data = data.filter((item: any) => {
            const extension = item.title.split('.').pop();
            return extension === this.docx || extension === this.xlsx;
          });
        }
        data = data.results;
        for (let i = 0; i < data.length; i++) {
          data[i]['id'] = i;
          data[i]['checked'] = false;
          data[i]['org_uuid'] = this.OrganisationID;
        }
        this.pagnationData = data.results;
        this.TemplatesList = this.TemplatesList.concat(data);
        this.checkforSelectedValue();
      },
      error => {
        this.scrollLoader = false;
        this.error = error.error.message;
      }
    );
  }
  onScroll() {
    if (this.paginatedDataUrl?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  checkforSelectedValue() {
    let selectedData;
    if (this.TemplatesList) {
      selectedData = this.TemplatesList.find((x: any) => x['guid'] === this.selectedGUID);
      if (selectedData) {
        this.selectedTemplate = selectedData['guid'];
      }
      for (let i = 0; i < this.TemplatesList.length; i++) {
        if (this.selectedTemplate == this.TemplatesList[i]['guid']) {
          this.TemplatesList[i]['id'] = i;
          this.TemplatesList[i]['checked'] = true;
          this.TemplatesList[i]['org_uuid'] = this.OrganisationID;
          break;
        }
      }
    }
  }
  goToCreateTemplate() {
    this.activeModal.close(false);
    this.router.navigate(['organisation/documents/create-template']);
  }

  private getTemplatesList() {
    this.documentsService.getOrganisationsTemplatesList(this.OrganisationID, this.page, this.type).subscribe(
      data => {
        this.paginatedDataUrl = data;
        data = data.results;
        for (let i = 0; i < data.length; i++) {
          data[i]['id'] = i;
          data[i]['checked'] = false;
          data[i]['org_uuid'] = this.OrganisationID;
        }
        this.TemplatesList = data;
        if (this.template_type == 'form') {
          this.TemplatesList = data.filter((item: any) => {
            const extension = item.title.split('.').pop();
            return extension === 'docx' || extension === 'xlsx';
          });
        }
        console.log(this.TemplatesList);
        this.loading = false;
        if (this.selectedGUID) {
          this.checkforSelectedValue();
        }
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }

  SelectChanged(item: any, $event: any) {
    if ($event.target.checked) {
      this.documentsService.updateTemplates(item, $event);
    }
    this.template_guid = item.guid;
  }

  save() {
    this.submittedTemplateData = {
      selectedTemplateGuid: this.template_guid
    };

    if (this.storage_id) {
      let menustringify = JSON.stringify(this.submittedTemplateData);
      localStorage.setItem(this.storage_id, menustringify);
    }
    this.activeModal.close(false);
    this.documentsService.emitEventWithLatestTemplateData(this.documentsService.getTemplate());
    this.documentsService.emitEventWithLatestTemplateData(this.documentsService.getTemplateGuid());
  }

  ViewTemplate(version_guid: any, doc_guid: any, url: any) {
    this.clickedRow = version_guid;
    this.isViewLoading = !this.isViewLoading;
    const payload_data = { organization_users: [this.user_guid] };
    this.documentsService.check_collaborator(this.OrganisationID, version_guid).subscribe(
      (data: any) => {
        if (data['success'] == true) {
          this.clickedRow = null;
          this.isViewLoading = false;
          window.open(url, '_blank');
        } else {
          this.documentsService.SubmitAddReadersData(this.OrganisationID, version_guid, payload_data).subscribe(
            (data: any) => {
              if (data) {
                this.clickedRow = null;
                this.isViewLoading = false;
                window.open(url, '_blank');
              }
            },
            (error: any) => {
              this.clickedRow = null;
              this.isViewLoading = false;
              this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
              this.backendErrorService.backendError$.subscribe(error => {
                this.backend_error = error;
              });
            }
          );
        }
      },
      (error: any) => {
        this.clickedRow = null;
        this.isViewLoading = false;
        this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
        });
      }
    );
  }
}
