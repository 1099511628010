<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <ng-container *ngIf="cbAgentShowData && cbAgentShowData.length > 0; else noCbAgent">
    <div class="row">
      <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
        <h2 class="text-cyan m-0" translate>cb_agent.cb_agent_title</h2>
      </div>
    </div>
    <!-- <div class="row align-items-center warning-message" *ngIf="showCbAgentUpdateWarning">
      <div class="col-4">
        <span class="float-right"><mat-icon translate>cb_agent.environment.env_staging_warning</mat-icon></span>
      </div>
      <div class="col-8 no-padding">
        <span class="text-warning float-left" translate>
          cb_agent.unregister.unregister_warning_message
        </span>
      </div>
    </div> -->
    <div class="row mt-2" *ngIf="showCbAgentUpdateWarning">
      <span class="ml-4"><mat-icon translate>cb_agent.environment.env_staging_warning</mat-icon></span>
      <div class="col">
        <span class="text-warning" translate>
          cb_agent.unregister.unregister_warning_message
        </span>
      </div>
    </div>
    <div class="row scroll-view">
      <div class="col mt-3">
        <div class="mx-3">
          <div class="row">
            <div class="col">
              <div class="table_wrapper mr-5">
                <div class="row">
                  <div class="col-3 ml-2 mt-2">
                    <h4 class="text-black-1 font-weight-bold" translate>cb_agent.active_cbAgent_title</h4>
                    <p class="text-black-1" translate>cb_agent.active_cbAgent_select</p>
                  </div>
                  <div class="col-8 mt-1">
                    <div class="w-70p-Max">
                      <div class="row">
                        <div class="col">
                          <div class="row">
                            <div class="col-sm-1">
                              <span class="mat-toggle commonPointer" (click)="openConfirm('switch')">
                                <img
                                  class="logo ml-2"
                                  src="../../../assets/images/logo/logo-grey.png"
                                  height="30px"
                                /> </span
                              ><br />
                              <span translate>cb_agent.environment.env_staging</span>
                            </div>
                            <div class="col-sm-1">
                              <mat-slide-toggle
                                class="mt-1 ml-1"
                                (change)="openConfirm('switch', $event)"
                                [checked]="isProdAgent"
                              ></mat-slide-toggle>
                            </div>
                            <div class="col-sm-2">
                              <span class="mat-toggle commonPointer" (click)="openConfirm('switch')">
                                <img
                                  class="logo ml-3"
                                  src="../../../assets/images/logo/logo.png"
                                  height="30px"
                                /> </span
                              ><br />
                              <span translate>cb_agent.environment.env_production</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2" *ngIf="selectedCbAgent['environment'] === 'staging'">
                  <span class="ml-2"><mat-icon translate>cb_agent.environment.env_staging_warning</mat-icon></span>
                  <div class="col">
                    <span class="text-warning" translate>
                      cb_agent.environment.env_staging_warning_message
                    </span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3 ml-2 mt-2">
                    <h4 class="text-black-1 font-weight-bold" translate>
                      cb_agent.unregister.unregister_title
                      <i
                        class="fas fa-question-circle ml-2 mt-2 cb-tooltip-btn"
                        [ngbTooltip]="'cb_agent.unregister.unregister_tooltip_message' | translate"
                        triggers="hover:blur"
                        tooltipClass="cb-tooltip"
                        placement="bottom"
                      ></i>
                    </h4>
                    <p class="text-black-1" translate>
                      <span translate>cb_agent.unregister.unregister_message_one </span
                      ><span>{{ selectedCbAgent['environment'] }}</span
                      ><span translate> cb_agent.unregister.unregister_message_two</span>
                    </p>
                  </div>
                  <div class="col-8">
                    <button class="mt-3 btn-save" translate (click)="openConfirm('unregister')">
                      cb_agent.unregister.unregister_button_text
                    </button>
                  </div>
                </div>
                <table class="table-2">
                  <thead class="bg-yellow-2">
                    <tr class="border-0">
                      <td>
                        <h4 class="font-weight-bold text-black-2 p-1 mt-2" translate>
                          cb_agent.action_pallet.main_table_header_env
                        </h4>
                      </td>
                      <td>
                        <h4 class="font-weight-bold text-black p-1 mt-2" translate>
                          cb_agent.action_pallet.main_table_header_version
                        </h4>
                      </td>
                      <td *ngIf="isSystemAdmin">
                        <h4 class="font-weight-bold text-black-2 ml-2 mt-2" translate>
                          cb_agent.action_pallet.main_table_header_code
                        </h4>
                      </td>
                      <td>
                        <h4 class="font-weight-bold text-black-2 ml-2 mr-2 mt-2" translate>
                          cb_agent.action_pallet.main_table_header_action_command
                        </h4>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let cbAgentEnvironmentChoices of cbAgentShowData">
                      <td class="textCapitalize">
                        <h4 class="text-black-1 p-3 pr-3 w-240 nullify-margin">
                          <img
                            class="logo mr-2"
                            [src]="
                              cbAgentEnvironmentChoices['environment'] !== 'production'
                                ? '../../../assets/images/logo/logo-grey.png'
                                : '../../../assets/images/logo/logo.png'
                            "
                            height="30px"
                          />
                          {{ cbAgentEnvironmentChoices['environment'] }}
                        </h4>
                      </td>
                      <td class="textCapitalize">
                        <h4 class="text-black-1 p-3 pr-3 w-240 nullify-margin">
                          {{ cbAgentEnvironmentChoices['version'] }}
                        </h4>
                      </td>
                      <td *ngIf="isSystemAdmin">
                        <button
                          class="w-70px mr-3"
                          translate
                          (click)="updateCbCode(cbAgentEnvironmentChoices.node_id)"
                          [disabled]="cbAgentEnvironmentChoices['allow_update'] !== true"
                          [ngClass]="cbAgentEnvironmentChoices['allow_update'] !== true ? 'btn-disabled' : 'btn-save'"
                        >
                          cb_agent.action_pallet.main_table_body_button_update
                        </button>
                      </td>
                      <td>
                        <button
                          class="w-70px ml-4"
                          translate
                          (click)="
                            getActionCommands(
                              cbAgentEnvironmentChoices['environment'],
                              cbAgentEnvironmentChoices.node_id
                            )
                          "
                          [disabled]="cbAgentEnvironmentChoices['environment'] == currentAction"
                          [ngClass]="
                            cbAgentEnvironmentChoices['environment'] == currentAction ? 'btn-disabled' : 'btn-save'
                          "
                        >
                          cb_agent.action_pallet.main_table_body_button_view
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <hr class="action-border" *ngIf="showActionTable" />
          <div class="row table-background">
            <div
              class="col search-results"
              infiniteScroll
              [scrollWindow]="false"
              (scrolled)="onScroll()"
              [infiniteScrollDistance]="modalScrollDistance"
              [infiniteScrollThrottle]="modalScrollThrottle"
              (scrolledUp)="onScrollUp()"
            >
              <div class="col" *ngIf="showActionTable">
                <div class="row table-2 ml-1">
                  <div class="col-10">
                    <h4 class="text-black-1 font-weight-bold nullify-margin mt-2 textCapitalize" translate>
                      <span translate class="overide-span">{{ currentAction }}</span
                      ><span translate class="overide-span">
                        cb_agent.action_pallet.second_table_main_heading_action_commands</span
                      >
                    </h4>
                  </div>
                  <div class="col-2">
                    <button type="button" class="btn-cancel ml-4 w-100 mb-2" (click)="closeActionPallet()" translate>
                      cb_agent.action_pallet.second_table_main_heading_close
                    </button>
                  </div>
                </div>
                <div class="row mt-2 table-background">
                  <table class="w-90p table-2 mt-4 ml-5">
                    <thead>
                      <tr>
                        <th class="table2-first-padding">
                          <h4 class="font-weight-bold text-black-2" translate>
                            cb_agent.action_table.table_header_action_command
                          </h4>
                        </th>
                        <th>
                          <h4 class="font-weight-bold text-black-2" translate>
                            cb_agent.action_table.table_header_created_time
                          </h4>
                        </th>
                        <th>
                          <h4 class="font-weight-bold text-black-2" translate>
                            cb_agent.action_table.table_header_retrieved_time
                          </h4>
                        </th>
                        <th>
                          <h4 class="font-weight-bold text-black-2" translate>
                            cb_agent.action_table.table_header_completed_time
                          </h4>
                        </th>
                        <th>
                          <h4 class="font-weight-bold text-black-2" translate>
                            cb_agent.action_table.table_header_status
                          </h4>
                        </th>
                        <th>
                          <h4 class="font-weight-bold text-black-2" translate>
                            cb_agent.action_table.table_header_data_response
                          </h4>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of actionCommand">
                        <td>
                          <h4 class="text-black-1 p-2 mt-2" *ngIf="item.action_command; else noTableData">
                            {{ item.action_command }}
                          </h4>
                        </td>
                        <td>
                          <h4 class="text-black-1 p-2 mt-2" *ngIf="item.created_time; else noTableData">
                            {{ item.created_time | date: 'short' }}
                          </h4>
                        </td>
                        <td>
                          <h4 class="text-black-1 p-2 mt-2" *ngIf="item.retrieved_time; else noTableData">
                            {{ item.retrieved_time | date: 'short' }}
                          </h4>
                        </td>
                        <td>
                          <h4 class="text-black-1 p-2 mt-2" *ngIf="item.completed_time; else noTableData">
                            {{ item.completed_time | date: 'short' }}
                          </h4>
                        </td>
                        <td>
                          <h4 class="text-black-1 p-2 mt-2" *ngIf="item.action_command_status; else noTableData">
                            {{ item.action_command_status }}
                          </h4>
                        </td>
                        <td>
                          <h4
                            class="text-black-1 p-2 mt-2 commonPointer"
                            (click)="
                              openActionModal(action_command, item.action_command_data, item.action_command_response)
                            "
                          >
                            <i class="fas fa-eye ml-5"></i>
                          </h4>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col mt-5" *ngIf="showUndraw">
              <img class="no-action-image" src="../../../assets/images/no-action/no-action.svg" />
              <div class="text-no-action" translate>cb_agent.null_value_handing.no_action_commands</div>
            </div>
          </div>
          <h2 *ngIf="scrollLoader" class="font-weight-bold text-black-3 text-center">
            <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': scrollLoader }" style="font-size:large;"></i> &nbsp;
            Loading...
          </h2>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #noCbAgent>
    <div class=" h-100 d-flex justify-content-center align-items-center">
      <div><h5 translate>cb_agent.null_value_handing.no_agent</h5></div>
    </div>
  </ng-template>
</ng-container>
<!-- Modal container -->
<ng-template #action_command let-modal>
  <div class="modal-header cb-modal-header">
    <h4 class="modal-title font-weight-bold text-black-1" translate>cb_agent.view_data_response_popup.title</h4>
  </div>
  <div class="modal-body" ngbAutofocus>
    <div class="row">
      <div class="col-sm-8">
        <span translate>cb_agent.view_data_response_popup.message_cb_server</span><br />
        <div class="border text-black-1 p-2 action-data">
          <ng-container *ngIf="actionCommandData; else noModalData">
            <h4 class="mt-2">
              <pre>{{ actionCommandData | json }}</pre>
            </h4>
          </ng-container>
        </div>
      </div>
      <div class="col-sm-4"></div>
    </div>
    <div class="row mt-4">
      <div class="col-sm-4"></div>
      <div class="col-sm-8">
        <span translate>cb_agent.view_data_response_popup.message_cb_agent</span><br />
        <div class="border text-black-1 p-2 action-response">
          <ng-container *ngIf="actionCommandResponse; else noModalResponse">
            <h4 class="mt-2">
              <pre>{{ actionCommandResponse | json }}</pre>
            </h4>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer border-0 cb-modal-footer">
    <button type="button" class="btn-cancel ml-4 w-100" (click)="modal.close('Save click')" translate>
      cb_agent.view_data_response_popup.cancel_button
    </button>
  </div>
</ng-template>
<!-- Null value handling -->
<ng-template #noTableData
  ><td><h4 class="text-black-1 p-2">-</h4></td></ng-template
>
<ng-template #noModalData>
  <h4 class="mt-2" translate>cb_agent.null_value_handing.no_modal_data</h4>
</ng-template>
<ng-template #noModalResponse>
  <h4 class="mt-2" translate>cb_agent.null_value_handing.no_response</h4>
</ng-template>

<ng-template #other_content class="no-org-selected">
  <app-no-organisation-selected [typeMessage]="parentTypeMessage"></app-no-organisation-selected>
</ng-template>
