import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { OrganisationService } from '@app/core/organisation.service';
import { ModalService } from '@app/core/modal/modal.service';
import { take } from 'rxjs/operators';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { DocumentsService } from '../documents.service';
import _ from 'lodash';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { OrganisationAdminService } from '@app/Organisation-admin/organisation-admin.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from '@app/settings/settings.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-create-training-campaign',
  templateUrl: './create-training-campaign.component.html',
  styleUrls: ['./create-training-campaign.component.scss']
})
export class CreateTrainingCampaignComponent implements OnInit {
  documentListLoader = false;
  documentGroupListLoader = false;
  groupListLoader = false;
  duplicateIndex: number | null = null;
  duplicateError: any = false;
  minDate: NgbDateStruct;
  InviteTrainingGroupsForm: FormGroup;
  startDate: any;
  submitted = false;
  isLoading = false;
  loading = false;
  success: boolean;
  organisationID: string | null;
  guidSubscription: any;
  default_doc_url_type: any;
  folder_name: string;
  parent_folder: any;
  // addCampaignForm: any;
  DocStoreguid: any;
  error_message: any;
  selected_option: any;
  template_name: any;
  GroupsList: any;
  DocumentsList: any = [];
  DocumentsGroupList: any;
  selectedItems: any;
  error: {};
  form_error: any;

  dropdownList: any = [];
  selectedGroups: any = [];
  selectedDocuments: any = [];
  selectedGroupDocuments: any = [];
  dropdownSettingsForDocuments = {};
  dropdownSettingsForDocumentsGroups = {};
  dropdownSettingsForGroup = {};
  disabledAddNew = true;
  disabledBtn = true;
  getTitle: any;
  getUrl: any;
  allowed_duration_selected: string;
  unassignedUsers: any = [];
  dropdownSettingsForUsers = {};
  users: any = [];
  userListLoading = false;
  assignedUsers: any = [];
  assignedUsersDisabled: any = [];

  public data = {
    renewal_schedule: '',
    day_of_week: '',
    day: '',
    month: '*',
    year: '*',
    time: '',
    week: ''
  };

  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#fff',
      buttonColor: '#FFFFFF'
    },
    dial: {
      dialBackgroundColor: '#555'
    },
    clockFace: {
      clockFaceBackgroundColor: '#fff',
      clockHandColor: 'black',
      clockFaceTimeInactiveColor: 'black'
    }
  };

  allowed_duration_data: {};
  allowed_duration: any = [];
  training_allowed_duration_data: {};
  training_allowed_duration_selected: string;
  training_allowed_duration: any = [];
  @Input() dialogData: any = null;

  schedules = [
    {
      value: 'SEMI-ANNUALLY',
      display_value: 'Semi Annually'
    },
    {
      value: 'ANNUALLY',
      display_value: 'Annually'
    },
    {
      value: 'EVERY TWO YEARS',
      display_value: 'Every Two Years'
    }
  ];

  numbered_week = [
    {
      display_string: 'first',
      value: '1st'
    },
    {
      display_string: 'second',
      value: '2nd'
    },
    {
      display_string: 'third',
      value: '3rd'
    },
    {
      display_string: 'fourth',
      value: '4th'
    },
    {
      display_string: 'last',
      value: 'last'
    }
  ];

  days = [
    {
      display_string: 'Monday',
      value: 'mon'
    },
    {
      display_string: 'Tuesday',
      value: 'tue'
    },
    {
      display_string: 'Wednesday',
      value: 'wed'
    },
    {
      display_string: 'Thursday',
      value: 'thu'
    },
    {
      display_string: 'Friday',
      value: 'fri'
    },
    {
      display_string: 'Saturday',
      value: 'sat'
    },
    {
      display_string: 'Sunday',
      value: 'sun'
    }
  ];

  numbered_bi = [
    {
      display_string: 'Odd',
      value: '1/2'
    },
    {
      display_string: 'Even',
      value: '2/2'
    }
  ];

  numbered_quarter = [
    {
      display_string: 'first',
      value: '1/3'
    },
    {
      display_string: 'second',
      value: '2/3'
    },
    {
      display_string: 'third',
      value: '3/3'
    }
  ];

  months_half_year = [
    {
      display_string: 'January And July',
      value: '1/6'
    },
    {
      display_string: 'February And August',
      value: '2/6'
    },
    {
      display_string: 'March And September',
      value: '3/6'
    },
    {
      display_string: 'April And October',
      value: '4/6'
    },
    {
      display_string: 'May And November',
      value: '5/6'
    },
    {
      display_string: 'June And December',
      value: '6/6'
    }
  ];

  months_year = [
    {
      display_string: 'January',
      value: '1'
    },
    {
      display_string: 'February',
      value: '2'
    },
    {
      display_string: 'March',
      value: '3'
    },
    {
      display_string: 'April',
      value: '4'
    },
    {
      display_string: 'May',
      value: '5'
    },
    {
      display_string: 'June',
      value: '6'
    },
    {
      display_string: 'July',
      value: '7'
    },
    {
      display_string: 'August',
      value: '8'
    },
    {
      display_string: 'September',
      value: '9'
    },
    {
      display_string: 'October',
      value: '10'
    },
    {
      display_string: 'November',
      value: '11'
    },
    {
      display_string: 'December',
      value: '12'
    }
  ];
  groupMapper: any = {};
  constructor(
    private orgService: OrganisationService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private documentsService: DocumentsService,
    private orgAdminService: OrganisationAdminService,
    private settingsService: SettingsService,
    private router: Router
  ) {
    const currentDate = new Date();
    this.minDate = { year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate() };
  }

  ngOnInit() {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.organisationID = guid;
      this.getInviteTrainingGroupsForm();
    });
    this.documentsService.getAllgroups(this.organisationID).subscribe(data => {
      this.GroupsList = data;
      this.groupListLoader = false;
      // Filter out items with users.length === 0
      this.GroupsList = this.GroupsList.filter((item: any) => item.users_count > 0);
      this.getPeopleList();
    });

    this.documentsService.getAllDocumentGroups(this.organisationID).subscribe(data => {
      this.DocumentsGroupList = data;
      this.documentGroupListLoader = false;
      this.documentListLoader = true;
      this.documentsService.getAllPublishedDocsLite(this.organisationID, true).subscribe(data => {
        this.DocumentsList = data;
        this.DocumentsList.forEach((item: any) => {
          item.title = `${item.title} \n (${item.version})`;
        });
        this.documentListLoader = false;
      });
      this.DocumentsGroupList = this.DocumentsGroupList.filter((item: any) => item.doc_count > 0);
    });
    this.dropdownSettingsForUsers = {
      singleSelection: false,
      idField: 'id',
      textField: 'text',
      enableCheckAll: false,
      allowSearchFilter: true
    };

    this.default_doc_url_type = 'Others';

    this.dropdownSettingsForDocuments = {
      singleSelection: false,
      idField: 'document_version',
      textField: 'title',
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.dropdownSettingsForDocumentsGroups = {
      singleSelection: false,
      idField: 'guid',
      textField: 'title',
      enableCheckAll: false, // Enable the check all option
      allowSearchFilter: true
    };
    this.dropdownSettingsForGroup = {
      singleSelection: false,
      idField: 'guid',
      textField: 'title',
      enableCheckAll: false,
      allowSearchFilter: true
    };

    if (this.dialogData) {
      this.data = _.cloneDeep(this.dialogData.event.schedule);
      this.getAllowedDuration(this.dialogData.event.schedule.renewal_schedule, this.dialogData.event.allowed_duration);
      if (_.indexOf(this.dialogData.event.schedule.day, ' ') > 0) {
        const split_day = this.data.day.split(' ');
        this.data.day = split_day[0];
        this.data.day_of_week = split_day[1];
      }
      if (this.data.renewal_schedule === 'EVERY TWO YEARS') {
        const year_data = this.data.year.split('/');
        if (+year_data[0] % 2 === 1) {
          this.data.year = '1/2';
        } else {
          this.data.year = '2/2';
        }
      }
    } else {
      this.getAllowedDuration();
      this.data = {
        renewal_schedule: 'SEMI-ANNUALLY',
        time: '09:00 AM',
        day_of_week: 'mon',
        week: null,
        day: '1st',
        month: '1/6',
        year: '*'
      };
    }
  }

  onChange(event: any, type: string) {
    if (type == 'change' && event != '') {
      if (
        this.InviteTrainingGroupsForm.controls['start_date'].errors ||
        this.InviteTrainingGroupsForm.controls['end_date'].errors
      ) {
        this.form_error = false;
        this.InviteTrainingGroupsForm.controls['start_date'].setErrors(null);
        this.InviteTrainingGroupsForm.controls['start_date'].updateValueAndValidity();
        this.InviteTrainingGroupsForm.controls['end_date'].setErrors(null);
        this.InviteTrainingGroupsForm.controls['end_date'].updateValueAndValidity();
      }
    }
  }
  onChangeTitle(evt: any) {
    if (evt.type == 'keyup' && this.form_error) {
      this.form_error = '';
    }
  }

  getAllowedDuration(schedule?: string, duration_value?: string) {
    this.orgAdminService.getEventAllowedDuration().subscribe(result => {
      if (result && result.allowed_durations) {
        this.allowed_duration_data = result.allowed_durations;
        this.allowed_duration = schedule
          ? this.getCurrentAllowedDuration(result.allowed_durations, schedule)
          : this.getCurrentAllowedDuration(result.allowed_durations, 'SEMI-ANNUALLY');
        this.allowed_duration_selected = duration_value ? duration_value : '1 Day';
      }
    });
  }

  onDocumentClick(item: any) {
    if (this.DocumentsList) {
      this.documentListLoader = false;
    } else {
      this.documentListLoader = true;
    }
  }

  onDocumentGroupClick(item: any) {
    if (this.DocumentsGroupList) {
      this.documentGroupListLoader = false;
    } else {
      this.documentGroupListLoader = true;
    }
  }

  onDocumentSelect(item: any) {
    if (
      (this.selectedDocuments.length > 0 || this.selectedGroupDocuments.length > 0) &&
      this.selectedGroups.length > 0
    ) {
      this.disabledBtn = false;
    }
  }

  onDocumentDeSelect(items: any) {}

  onGroupSelect(item: any) {
    if (
      (this.selectedDocuments.length > 0 || this.selectedGroupDocuments.length > 0) &&
      this.selectedGroups.length > 0
    ) {
      this.disabledBtn = false;
    }
  }
  // updateUsersDisable(event: any) {
  //   let ids = event.map((item: any) => item.guid);
  //   console.log(event);
  //   this.settingsService.getTrainingGroupUserList(this.organisationID, true, ids).subscribe(data => {
  //     let groupUser: any[] = [...new Set(data.map((item: any) => item.users.map((user: any) => user.guid)).flat())];
  //     console.log(groupUser);
  //     const unassignedUsers = this.users.map((user: any) => ({
  //       id: user.id,
  //       text: user.text,
  //       isDisabled: groupUser.includes(user.id)
  //     }));
  //     this.users = unassignedUsers;
  //     this.assignedUsersDisabled = this.assignedUsersDisabled.filter((user: any) => !groupUser.includes(user.id));
  //   });
  // }

  updateUsersDisable(event: any) {
    this.settingsService.getTrainingGroupUserList(this.organisationID, true, [event.guid]).subscribe(data => {
      let groupUser: any[] = [...new Set(data.map((item: any) => item?.users.map((user: any) => user.guid)).flat())];
      let users = new Array();
      data[0].users?.forEach((user: any) => {
        if (!this.assignedUsersDisabled.map((x: any) => x.id).includes(user.guid)) {
          users.push({ isDisabled: false, text: user.name, id: user.guid });
        }
      });
      this.assignedUsersDisabled = [...this.assignedUsersDisabled, ...users];
    });
  }

  onDeSelectTrainingGroup(event: any) {
    this.settingsService.getTrainingGroupUserList(this.organisationID, true, [event.guid]).subscribe(data => {
      data[0].users?.forEach((user: any) => {
        const index = this.assignedUsersDisabled.findIndex((assignUser: any) => assignUser.id == user.guid);
        if (index !== -1) {
          this.assignedUsersDisabled.splice(index, 1);
        }
      });
      this.assignedUsersDisabled = [...this.assignedUsersDisabled];
    });
  }

  updateDocumentsDisable(event: any) {
    let ids = event.map((item: any) => item.guid);
    this.documentsService.getDocumentList(this.organisationID, 1, true, '', ids).subscribe((data: any) => {
      let groupDocuments: any[] = [
        ...new Set(data.map((item: any) => item.documents.map((doc: any) => doc.doc_version_guid)).flat())
      ];
      const unassignedDocuments = this.DocumentsList.map((doc: any) => ({
        document_version: doc.document_version,
        title: doc.title,
        isDisabled: groupDocuments.includes(doc.document_version)
      }));
      this.DocumentsList = unassignedDocuments;
      this.selectedDocuments = this.selectedDocuments.filter(
        (doc: any) => !groupDocuments.includes(doc.document_version)
      );
    });
  }

  onGroupClick(items: any) {
    if (this.GroupsList) {
      this.groupListLoader = false;
    } else {
      this.groupListLoader = true;
    }
    if (
      (this.selectedDocuments.length > 0 || this.selectedGroupDocuments.length > 0) &&
      this.selectedGroups.length > 0
    ) {
      this.disabledBtn = false;
    }
    this.checkGroupSelection();
  }

  onDocumentsGroupSelect(selectedItems: any[]) {
    if (
      (this.selectedDocuments.length > 0 || this.selectedGroupDocuments.length > 0) &&
      this.selectedGroups.length > 0
    ) {
      this.disabledBtn = false;
    }
  }

  onDocumentsGroupDeSelect(deSelectedItems: any[]) {}

  checkGroupSelection() {
    if (this.form_error) {
      if (this.form_error.training_groups) {
        this.form_error.training_groups = '';
      }
    }
  }

  getInviteTrainingGroupsForm() {
    this.InviteTrainingGroupsForm = this.formBuilder.group({
      title: ['', [Validators.required(), Validators.maxLength(255)]],
      description: ['', [Validators.required(), Validators.maxLength(4096)]],
      start_date: ['', [Validators.required(), Validators.DateGreaterEqualToday(), this.dateRangeValidator]],
      end_date: ['', [Validators.required(), Validators.DateIsAfterToday(), this.dateRangeValidator]],
      external_links: this.formBuilder.array([]),
      renewal_recurring: [''],
      slack_message: ['', Validators.maxLength(4096)],
      email_message: ['', Validators.maxLength(4096)]
    });
  }

  private dateRangeValidator: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const end_dt = this.InviteTrainingGroupsForm && this.InviteTrainingGroupsForm.get('end_date').value;
    const start_dt = this.InviteTrainingGroupsForm && this.InviteTrainingGroupsForm.get('start_date').value;

    if (start_dt && end_dt) {
      if (end_dt.year < start_dt.year) {
        return { invalidRange: true };
      } else if (end_dt.year === start_dt.year && end_dt.month < start_dt.month) {
        return { invalidRange: true };
      } else if (end_dt.year === start_dt.year && end_dt.month === start_dt.month && end_dt.day < start_dt.day) {
        return { invalidRange: true };
      } else if (end_dt.year === start_dt.year && end_dt.month === start_dt.month && end_dt.day === start_dt.day) {
        return { invalidSameDate: true };
      }
    }
    return invalid ? { invalidRange: { start_dt, end_dt } } : null;
  };

  // convenience getter for easy access to form fields
  get f() {
    return this.InviteTrainingGroupsForm.controls;
  }

  get externalDocuments(): FormArray {
    return this.InviteTrainingGroupsForm.get('external_links') as FormArray;
  }

  removeOption(index: number) {
    this.duplicateError = false;
    this.externalDocuments.removeAt(index);
    if (this.selectedDocuments.length > 0 || this.externalDocuments.length > 0) {
      this.disabledBtn = false;
    } else {
      this.disabledBtn = true;
    }
    this.disabledAddNew = false;
  }

  getPreparedData() {
    const schedule_data = _.cloneDeep(this.data);
    schedule_data['allowed_duration'] = this.allowed_duration_selected;
    if (schedule_data.renewal_schedule === 'DAILY') {
      schedule_data.day_of_week = 'mon-fri';
    } else if (schedule_data.renewal_schedule === 'EVERY TWO YEARS') {
      const year_data = schedule_data.year.split('/');
      if (+year_data[0] % 2 === 1) {
        schedule_data.year = '2019/2';
      } else {
        schedule_data.year = '2020/2';
      }
    }

    if (schedule_data.day !== null && schedule_data.day_of_week) {
      schedule_data.day = schedule_data.day + ' ' + schedule_data.day_of_week;
      schedule_data.day_of_week = schedule_data.day_of_week;
    }

    return this.orgAdminSchedularData(Object.assign({}, schedule_data));
  }

  public orgAdminSchedularData(scheduler_data: any): object {
    for (const key in scheduler_data) {
      if (scheduler_data.hasOwnProperty(key) && scheduler_data[key] === '') {
        scheduler_data[key] = null;
      } else {
      }
    }
    return scheduler_data;
  }

  timeSet(time: any) {
    this.data.time = time;
  }

  dataRefresh(event: any) {
    const renewal_schedule: string = event;
    const time: string = this.data.time;
    switch (renewal_schedule) {
      case 'WEEKLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: null,
          month: '*',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'BI-WEEKLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: null,
          month: '*',
          year: '*',
          time: time,
          week: '1/2'
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'MONTHLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '*',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'BI-MONTHLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1/2',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'QUARTERLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1/3',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'SEMI-ANNUALLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1/6',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'ANNUALLY':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      case 'EVERY TWO YEARS':
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: 'mon',
          day: '1st',
          month: '1',
          year: '1/2',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
        break;
      default:
        this.data = {
          renewal_schedule: renewal_schedule,
          day_of_week: null,
          day: null,
          month: '*',
          year: '*',
          time: time,
          week: null
        };
        this.allowed_duration.length = 0;
        this.allowed_duration = this.getCurrentAllowedDuration(this.allowed_duration_data, renewal_schedule);
    }
  }

  SelectDocumentDialog(): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Select a document',
        modalType: 'List all document version'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.documentsService.getAllPublishedDocsLite(this.organisationID, true);
        } else {
          console.log('Cancelled.');
        }
      });
  }

  getCurrentAllowedDuration(data: {}, type: string) {
    for (const key in data) {
      if (`${key}` === type) {
        return `${data[key]}`.split(',');
      }
    }
  }

  submit() {
    this.submitted = true;
    this.form_error = false;
    if (this.groupUserDisablity) return;
    if (this.InviteTrainingGroupsForm.valid || this.externalDocuments.length > 0) {
      const campaign_create_data = Object.assign({}, this.InviteTrainingGroupsForm.value);
      if (
        this.selectedDocuments.length > 0 ||
        campaign_create_data['external_links'].length > 0 ||
        this.selectedGroupDocuments?.length > 0
      ) {
        const start_date = new Date(
          campaign_create_data['start_date'].year,
          campaign_create_data['start_date'].month - 1,
          campaign_create_data['start_date'].day + 1
        );
        const end_date = new Date(
          campaign_create_data['end_date'].year,
          campaign_create_data['end_date'].month - 1,
          campaign_create_data['end_date'].day + 1
        );
        campaign_create_data['start_date'] = start_date.toISOString().split('T')[0];
        campaign_create_data['end_date'] = end_date.toISOString().split('T')[0];
        campaign_create_data['training_groups'] = [];
        campaign_create_data['documents'] = this.selectedDocuments.map((x: any) => x.document_version);
        campaign_create_data['document_groups'] = this.selectedGroupDocuments.map((x: any) => x.guid);
        campaign_create_data['users'] = this.assignedUsersDisabled.map((x: any) => x.id);
        this.isLoading = true;
        if (campaign_create_data.renewal_recurring) {
          campaign_create_data['renewal_schedule'] = this.getPreparedData();
        } else {
          campaign_create_data['renewal_schedule'] = null;
        }
        console.log(campaign_create_data['external_links']);
        this.submitTrainingForm(campaign_create_data);
      }
    } else {
      this.checkGroupSelection();
      this.isLoading = false;
      return;
    }
  }

  submitTrainingForm(campaign_create_data: any) {
    this.documentsService.createCampaign(this.organisationID, campaign_create_data).subscribe(
      data => {
        this.isLoading = false;
        this.loading = false;
        this.error = {
          title: '',
          version: '',
          folder: ''
        };
        this.modalService
          .custom(CustomDialogComponent, {
            title: 'Campaign created',
            modalType: 'Campaign created',
            DocumentGuid: data.guid,
            DocumentTitle: data.title
          })
          .pipe(take(1)) // take() manages unsubscription for us
          .subscribe((result: any) => {
            if (result.isConfirmed) {
              this.router.navigate(['/organisation/training']);
            } else if (!result.isConfirmed) {
              console.log('Cancelled.');
            }
          });
        this.submitted = false;
        this.InviteTrainingGroupsForm.reset();
        this.selected_option = '';
        this.selectedGroups.length = 0;
        this.selectedDocuments.length = 0;
        this.selectedGroupDocuments.length = 0;
        this.selectedDocuments = [];
        this.selectedGroups = [];
        this.selectedGroupDocuments = [];
        this.assignedUsersDisabled = [];
        this.externalDocuments.reset();
        this.getInviteTrainingGroupsForm();
      },
      (error: any) => {
        this.isLoading = false;
        this.form_error = error.error;
      }
    );
  }

  onReset() {
    this.submitted = false;
    this.InviteTrainingGroupsForm.reset();
    this.selectedDocuments = [];
    this.selectedGroups = [];
    this.selectedGroupDocuments = [];
    this.externalDocuments.reset();
    this.disabledAddNew = true;
    this.getTitle = '';
    this.getUrl = '';
    this.getInviteTrainingGroupsForm();
  }

  addDocumentUrl() {
    if (this.externalDocuments.valid) {
      this.externalDocuments.push(this.newDocumentUrl());
    } else {
      if (this.externalDocuments.value != '') {
        this.externalDocuments.push(this.newDocumentUrl());
      }
      return;
    }
  }

  modelChange(event: any): void {
    this.getTitle = event;
    this.checkExternalLink();
  }

  urlChange(event: any) {
    this.getUrl = event;
    this.checkExternalLink();
  }

  checkExternalLink() {
    if (this.getTitle != '' && this.getUrl != '') {
      this.disabledAddNew = false;
    } else {
      this.disabledAddNew = true;
    }
    let duplicateIndex: number | null = null;
    const duplicateUrl = this.externalDocuments.controls.some((control, index) => {
      const currentUrl = control.get('url')?.value;
      if (
        currentUrl &&
        this.externalDocuments.controls.findIndex((c, i) => c.get('url')?.value === currentUrl && i !== index) !== -1
      ) {
        duplicateIndex = index;
        return true;
      }
      return false;
    });

    if (duplicateUrl) {
      this.duplicateError = true;
      this.disabledAddNew = true;
      this.duplicateIndex = duplicateIndex;
    } else {
      this.duplicateError = false;
      this.duplicateIndex = null;
    }
  }
  newDocumentUrl(): FormGroup {
    if (this.externalDocuments.length >= 0) {
      this.disabledBtn = false;
      this.getTitle = '';
      this.getUrl = '';
      if (this.getTitle != '' && this.getUrl != '') {
        this.disabledAddNew = false;
      } else {
        this.disabledAddNew = true;
      }
    }
    return this.formBuilder.group({
      urltype: [this.default_doc_url_type, [Validators.required()]],
      url: ['', [Validators.required(), Validators.httpsURL()]],
      title: ['', [Validators.required()]]
    });
  }
  private getPeopleList(): void {
    this.settingsService.getOrganizationPeopleListLite(this.organisationID, true).subscribe(
      data => {
        this.users = data.data;
        const unassignedUsers = this.users.map((user: any) => ({
          id: user.guid,
          text: user.first_name + ' ' + user.last_name + ' (' + user.email + ')',
          isDisabled: false
        }));
        this.users = unassignedUsers;
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }
  get groupUserDisablity() {
    return !(this.assignedUsersDisabled.length || this.selectedGroups.length);
  }
}
