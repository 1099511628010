import { Component, OnInit, Input, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { OrganisationService } from '@app/core/organisation.service';
import { SettingsService } from '@app/settings/settings.service';
import { ModalService } from '@app/core/modal/modal.service';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';

@Component({
  selector: 'app-add-committee-members',
  templateUrl: './addCommitteeMembers.component.html',
  styleUrls: ['./addCommitteeMembers.component.scss']
})
export class AddCommitteeMembersComponent implements OnInit, OnDestroy {
  OrganisationID: string | null;
  error: any;
  peopleCount = 0;
  search_title: any = '';
  statusType: any = 'Active';
  guidSubscription: any;
  peopleList: object[];
  newEmailListOfCommitteeMembers: any[] = [];
  success = false;
  searchText: any;
  apiError = false;
  @Input() title_tag: string;
  @Input() new_committee: boolean;
  @Input() committee_guid: string;
  @Input() form_data: any[] = [];
  scrollLoader = false;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  page = 1;
  pagnationData: any;
  loading = true;
  paginatedDataUrl: any;
  backend_error: any;

  constructor(
    private orgService: OrganisationService,
    private settingsService: SettingsService,
    public activeModal: NgbActiveModal,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private backendErrorService: BackendErrorService
  ) {}

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getPeopleList();
    });
  }

  onScrollUp() {
    this.scrollLoader = false;
  }

  onScrollData() {
    this.scrollLoader = true;
    this.settingsService
      .getOrganizationPeopleListWithPagination(
        this.OrganisationID,
        (this.page += 1),
        this.search_title,
        this.statusType
      )
      .subscribe(
        data => {
          this.scrollLoader = false;
          this.pagnationData = data.results;
          this.peopleList = this.peopleList.concat(data.results);
          this.paginatedDataUrl = data;
        },
        error => {
          this.scrollLoader = false;
        }
      );
  }
  onScroll() {
    console.log(this.paginatedDataUrl, 'this.paginatedDataUrl' + this.page);
    if (this.paginatedDataUrl?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }

  Search(search_title: string) {
    this.page = 1;
    this.search_title = search_title ? search_title : '';
    this.settingsService
      .getOrganizationPeopleListWithPagination(this.OrganisationID, this.page, this.search_title, this.statusType)
      .subscribe(
        data => {
          this.peopleList = data.results;
          this.paginatedDataUrl = data;
          this.peopleCount = data['count'];
          this.loading = false;
        },
        error => {
          this.error = error.error.message;
        }
      );
  }

  organisationNewCommitteeMemberAdd(email: string, actionType: string): void {
    switch (actionType) {
      case 'assign':
        this.newEmailListOfCommitteeMembers.push(email);
        break;
      case 'revoke':
        this.newEmailListOfCommitteeMembers.splice(this.newEmailListOfCommitteeMembers.indexOf(email), 1);
        break;
      default:
        this.newEmailListOfCommitteeMembers = [];
        break;
    }
  }

  organisationCommitteeMemberAdd(email: any, actionType: string, index: any): void {
    this.peopleList[index]['isLoading'] = true;
    this.backend_error = '';
    this.settingsService
      .revokeOrAddOrganizationCommitteeMember(this.committee_guid, this.OrganisationID, email, actionType)
      .subscribe(
        data => {
          this.peopleList[index]['isLoading'] = false;
          switch (actionType) {
            case 'assign':
              this.form_data.push(email);
              this.translate.get('organisation_people.people_toaster_message.add_people_committee').subscribe(res => {
                this.toaster.showSuccess(res);
              });
              break;
            case 'revoke':
              this.form_data.splice(this.form_data.indexOf(email), 1);
              this.translate
                .get('organisation_people.people_toaster_message.remove_people_committee')
                .subscribe(res => {
                  this.toaster.showSuccess(res);
                });
              break;
            default:
              this.form_data = [];
              break;
          }
        },
        error => {
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
            this.peopleList[index]['isLoading'] = false;
          });
        }
      );
  }

  openPeopleForm(formType: string, formData?: any) {
    this.modalService
      .custom(CustomDialogComponent, { title: 'Add people', modalType: 'people' })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getPeopleList();

          this.translate.get('organisation_people.people_toaster_message.add_people').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Some error has occcured.');
        }
      });
  }

  closePeopleAddModal(): void {
    this.activeModal.close(this.newEmailListOfCommitteeMembers);
  }

  private getPeopleList(): void {
    this.settingsService
      .getOrganizationPeopleListWithPagination(this.OrganisationID, this.page, this.search_title, this.statusType)
      .subscribe(
        data => {
          this.peopleList = data.results;
          this.paginatedDataUrl = data;
          this.peopleCount = data['count'];
          this.loading = false;
        },
        error => {
          this.error = error.error.message;
        }
      );
  }
}
