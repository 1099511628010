import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';

@Component({
  selector: 'app-view-approvers',
  templateUrl: './view-approvers.component.html',
  styleUrls: ['./view-approvers.component.scss']
})
export class ViewApproversComponent implements OnInit {
  OrganisationID: string | null;
  guidSubscription: any;
  ApproverList: object[];
  error: any;
  loading = true;

  @Input() formType: string;
  @Input() DocumentGuid: string;
  @Input() title_tag: string;
  @Input() docTitle: string;

  constructor(
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private documentsService: DocumentsService
  ) {}

  ngOnInit() {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getApproverList();
    });
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  private getApproverList(): void {
    this.documentsService.getDocumentApproverList(this.OrganisationID, this.DocumentGuid).subscribe(
      data => {
        this.ApproverList = data;
        if (this.ApproverList) {
          this.ApproverList.forEach(approver => {
            var initials = approver['user']['name'].match(/\b(\w)/g);
            approver['initials'] = initials.join('').toUpperCase();
          });
          this.ApproverList.sort((a, b) => a['user']['name'].localeCompare(b['user']['name']));
          this.loading = false;
        }
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
        this.loading = false;
      }
    );
  }
}
