import { EventEmitter, Injectable } from '@angular/core';
import { Subject, Observable, forkJoin, of } from 'rxjs';
import { map, concatMap, mergeMap, tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { delay } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class IncidentService {
  constructor(private http: HttpClient) {}

  private statusSource = new BehaviorSubject<string>('');
  updateApiCall = this.statusSource.asObservable();
  setStatus(updateApiCall: string) {
    this.statusSource.next(updateApiCall);
  }

  private statusSourceForPick = new BehaviorSubject<string>('');
  updateApiCallForPick = this.statusSourceForPick.asObservable();
  setStatusforPick(updateApiCallForPick: string) {
    this.statusSourceForPick.next(updateApiCallForPick);
  }

  private statusSourceForBlock = new BehaviorSubject<string>('');
  updateApiCallForBlock = this.statusSourceForBlock.asObservable();
  setStatusforBlock(updateApiCallForBlock: string) {
    this.statusSourceForBlock.next(updateApiCallForBlock);
  }
  private statusSourceForResolve = new BehaviorSubject<string>('');
  updateApiCallForResolve = this.statusSourceForResolve.asObservable();
  setStatusforResolve(updateApiCallForResolve: string) {
    this.statusSourceForResolve.next(updateApiCallForResolve);
  }
  private statusSourceForclose = new BehaviorSubject<string>('');
  updateApiCallForClose = this.statusSourceForclose.asObservable();
  setStatusforClose(updateApiCallForClose: string) {
    this.statusSourceForclose.next(updateApiCallForClose);
  }
  private statusSourceForUnblock = new BehaviorSubject<string>('');
  updateApiCallForUnblock = this.statusSourceForUnblock.asObservable();
  setStatusforUnblock(updateApiCallForUnblock: string) {
    this.statusSourceForUnblock.next(updateApiCallForUnblock);
  }

  private statusSourceForApprove = new BehaviorSubject<string>('');
  updateApiCallForApprove = this.statusSourceForApprove.asObservable();
  setStatusforApprove(updateApiCallForApprove: string) {
    this.statusSourceForApprove.next(updateApiCallForApprove);
  }
  private statusSourceForChangeRequest = new BehaviorSubject<string>('');
  updateApiCallForChangeRequest = this.statusSourceForChangeRequest.asObservable();
  setStatusChangeRequest(updateApiCallForChangeRequest: string) {
    this.statusSourceForChangeRequest.next(updateApiCallForChangeRequest);
  }
  private statusSourceForManageReviewer = new BehaviorSubject<string>('');
  updateApiCallForManageReviewer = this.statusSourceForManageReviewer.asObservable();
  setStatusforManageReviewer(updateApiCallForManageReviewer: string) {
    this.statusSourceForManageReviewer.next(updateApiCallForManageReviewer);
  }

  private statusSourceForManageApprover = new BehaviorSubject<string>('');
  updateApiCallForManageApprover = this.statusSourceForManageApprover.asObservable();
  setStatusforManageApprover(updateApiCallForManageApprover: string) {
    this.statusSourceForManageApprover.next(updateApiCallForManageApprover);
  }
  private statusSourceForRevokeReviewer = new BehaviorSubject<string>('');
  updateApiCallForRevokeReviewer = this.statusSourceForRevokeReviewer.asObservable();
  setStatusforRevokeReviewer(updateApiCallForRevokeReviewer: string) {
    this.statusSourceForRevokeReviewer.next(updateApiCallForRevokeReviewer);
  }
  private statusSourceForRevokeApprover = new BehaviorSubject<string>('');
  updateApiCallForRevokeApprover = this.statusSourceForRevokeApprover.asObservable();
  setStatusforRevokeApprover(updateApiCallForRevokeApprover: string) {
    this.statusSourceForRevokeApprover.next(updateApiCallForRevokeApprover);
  }
  private statusSourceForReassignApprover = new BehaviorSubject<string>('');
  updateApiCallForReassignApprover = this.statusSourceForReassignApprover.asObservable();
  setStatusforReassignApprover(updateApiCallForReassignApprover: string) {
    this.statusSourceForReassignApprover.next(updateApiCallForReassignApprover);
  }
  private statusSourceForReassignReviewer = new BehaviorSubject<string>('');
  updateApiCallForReassignReviewer = this.statusSourceForReassignReviewer.asObservable();
  setStatusforReassignReviewer(updateApiCallForReassignReviewer: string) {
    this.statusSourceForReassignReviewer.next(updateApiCallForReassignReviewer);
  }
  getJiraAssigneeList(org_uuid: string): Observable<any> {
    if (org_uuid != null) {
      return this.http.get(`/api/organizations/${org_uuid}/incident/jira_assignee/`, {
        withCredentials: true
      });
    }
  }
  get_jira_issue_type(org_uuid: string): Observable<any> {
    if (org_uuid != null) {
      return this.http.get(`/api/organizations/${org_uuid}/incident/jira_issue_type/`, {
        withCredentials: true
      });
    }
  }
  // getIncidentApproverList(org_uuid: string, incident_guid: string): Observable<any> {
  //   return this.http.get(`/api/organizations/${org_uuid}/incident/${incident_guid}/approvers/`, {
  //     withCredentials: true
  //   });
  // }

  createJiraTicket(org_uuid: string, incident_guid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/incident/${incident_guid}/create_jira_ticket/`, payload, {
      withCredentials: true
    });
  }
  linkJiraTicket(org_uuid: string, incident_guid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/incident/${incident_guid}/link_jira_issue/`, payload, {
      withCredentials: true
    });
  }
  // Create Incident API Call
  SubmitIncidentData(org_uuid: string, incident_guid: string = '', payload: any): Observable<any> {
    let api = incident_guid
      ? `/api/organizations/${org_uuid}/incident/${incident_guid}/`
      : `/api/organizations/${org_uuid}/incident/`;
    let method = incident_guid ? 'put' : 'post';
    return this.http[method](api, payload, {
      withCredentials: true
    });
  }
  SubmitEditData(org_uuid: string, incident_guid: string, payload: any): Observable<any> {
    return this.http.put(`/api/organizations/${org_uuid}/incident/${incident_guid}/`, payload, {
      withCredentials: true
    });
  }
  getIncidentData(org_uuid: string, incident_guid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/incident/${incident_guid}/`, {
      withCredentials: true
    });
  }

  getOrganizationIncidentData(org_uuid: string): Observable<any> {
    if (org_uuid != null) {
      return this.http.get(`/api/organizations/${org_uuid}/incident/`, {
        withCredentials: true
      });
    }
  }
  getOrganizationIncidentList(org_uuid: string, status: any, search: any, page: number, role: string): Observable<any> {
    if (org_uuid != null) {
      return this.http.get(
        `/api/organizations/${org_uuid}/incident/?status=${status}&search_title=${search}&page=${page}&role=${role}`,
        {
          withCredentials: true
        }
      );
    }
  }
  getIncidentSearchList(org_uuid: string, status: any, search: any, page: number, role: string): Observable<any> {
    if (org_uuid != null) {
      return this.http.get(
        `/api/organizations/${org_uuid}/incident/?status=${status}&search_title=${search}&page=${page}&role=${role}`,
        {
          withCredentials: true
        }
      );
    }
  }
  getJiraIdData(org_uuid: string, incident_guid: string, jira_id: string): Observable<any> {
    if (org_uuid != null) {
      return this.http.get(
        `/api/organizations/${org_uuid}/incident/${incident_guid}/get_jira_issue/?jira_id=${jira_id}`,
        {
          withCredentials: true
        }
      );
    }
  }
  getIncidentSearchFilterList(
    org_uuid: string,
    search_title: string,
    status: string,
    page: number,
    role: string
  ): Observable<any> {
    return this.http.get(
      `/api/organizations/${org_uuid}/incident/?search_title=${search_title}&status=${status}&page=${page}&role=${role}`,
      {
        withCredentials: true
      }
    );
  }
  getPaginatedIncidentList(org_uuid: string, page: any, search: any, status: string, role: string): Observable<any> {
    return this.http.get(
      `/api/organizations/${org_uuid}/incident/?page=${page}&search_title=${search}&status=${status}&role=${role}`,
      {
        withCredentials: true
      }
    );
  }
  getSearchResultList(
    org_uuid: string,
    search_title: string,
    page: number,
    status: string,
    role: string
  ): Observable<any> {
    return this.http.get(
      `/api/organizations/${org_uuid}/incident/?role=${role}&page=${page}&search_title=${search_title}&status=${status}`,
      {
        withCredentials: true
      }
    );
  }
  //Manage Incident Reviewer api call
  submitIncidentReviewerData(org_uuid: string, doc_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/incident/${doc_uuid}/incident_user_relationship/`, payload, {
      withCredentials: true
    });
  }
  createIncidentCategory(org_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/incident/category/`, payload, {
      withCredentials: true
    });
  }
  getIncidentListData(
    org_uuid: string,
    search_title: string,

    status: string,
    role: string
  ): Observable<any> {
    if (org_uuid != null) {
      return this.http.get(
        `/api/organizations/${org_uuid}/incident/?role=${role}&search_title=${search_title}&status=${status}`,
        {
          withCredentials: true
        }
      );
    }
  }
  getSearchAndFilterList(
    org_uuid: string,
    search_title: string,
    status: string,
    page: number,
    role: string
  ): Observable<any> {
    return this.http.get(
      `/api/organizations/${org_uuid}/incident/?search_title=${search_title}&status=${status}&page=${page}&role=${role}`,
      {
        withCredentials: true
      }
    );
  }
  getCategoryList(org_uuid: string): Observable<any> {
    if (org_uuid != null) {
      return this.http.get(`/api/organizations/${org_uuid}/incident/category/`, {
        withCredentials: true
      });
    }
  }
  getSeverityList(org_uuid: string): Observable<any> {
    if (org_uuid != null) {
      return this.http.get(`/api/organizations/${org_uuid}/severity/`, {
        withCredentials: true
      });
    }
  }
  getCategoryListWithPagination(org_uuid: string, page: number): Observable<any> {
    if (org_uuid != null) {
      return this.http.get(`/api/organizations/${org_uuid}/incident/category/?page=${page}`, {
        withCredentials: true
      });
    }
  }

  getIncidentReviewerList(org_uuid: string, doc_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/incident/${doc_uuid}/reviewers/`, {
      withCredentials: true
    });
  }
  getIncidentApproverList(org_uuid: string, doc_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/incident/${doc_uuid}/approvers/`, {
      withCredentials: true
    });
  }
  getUnassignedApproverList(org_uuid: string, doc_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/incident/${doc_uuid}/approvers?status=unassigned`, {
      withCredentials: true
    });
  }

  getUnassignedReviewerList(org_uuid: string, doc_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/incident/${doc_uuid}/reviewers?status=unassigned`, {
      withCredentials: true
    });
  }

  deleteCategoryList(org_uuid: string, doc_uuid: string): Observable<any> {
    return this.http.delete(`/api/organizations/${org_uuid}/incident/category/${doc_uuid}`, {
      withCredentials: true
    });
  }
  updateCategoryDescription(org_uuid: string, group_guid: string, payload: any): Observable<any> {
    return this.http.put(`/api/organizations/${org_uuid}/incident/category/${group_guid}/`, payload, {
      withCredentials: true
    });
  }

  collaborationTask(org_uuid: string, incidentGuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/incident/${incidentGuid}/update_status/`, payload, {
      withCredentials: true
    });
  }

  getJiraTicketsAssignees(org_uuid: string, guid: any): Observable<any> {
    if (org_uuid != null) {
      return this.http.get(`/api/organizations/${org_uuid}/incident/${guid}/`, {
        withCredentials: true
      });
    }
  }

  getJiraTickets(org_uuid: string, guid: any): Observable<any> {
    if (org_uuid != null) {
      return this.http.get(`/api/organizations/${org_uuid}/incident/${guid}/get_tickets/`, {
        withCredentials: true
      });
    }
  }

  submitApproveIncidentData(org_uuid: string, doc_uuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/document/${doc_uuid}/approve_incident/`, payload, {
      withCredentials: true
    });
  }

  closeIncident(org_uuid: string, incidentGuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/incident/${incidentGuid}/close_incident/`, payload, {
      withCredentials: true
    });
  }

  submitRevokeReviewerApproverData(org_uuid: string, incidentGuid: string, payload: any): Observable<any> {
    return this.http.post(`/api/organizations/${org_uuid}/incident/${incidentGuid}/revoke_collaboration/`, payload, {
      withCredentials: true
    });
  }
  submitChangeDuedateFromData(org_uuid: string, doc_uuid: string, doc_guid: string, payload: any): Observable<any> {
    return this.http.put(
      `/api/organizations/${org_uuid}/incident/${doc_uuid}/edit_incident_collaboration/${doc_guid}/`,
      payload,
      { withCredentials: true }
    );
  }
}
