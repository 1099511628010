import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { finalize } from 'rxjs/operators';
import { SettingsService } from '@app/settings/settings.service';
import { OrganisationService } from '@app/core/organisation.service';
import { ToasterService } from '@app/shared/toaster/toastr.service';

@Component({
  selector: 'app-add-people',
  templateUrl: './addPeople.component.html',
  styleUrls: ['./addPeople.component.scss']
})
export class AddPeopleComponent implements OnInit, OnDestroy {
  peopleForm: FormGroup;
  submitted = false;
  error = {
    first_name: '',
    last_name: '',
    phone: '',
    email: ''
  };
  success: boolean;
  // multiselect dropdown
  GroupsList: any = [];
  selectedRoles: any = [];
  selectedItems: any = [];
  dropdownSettings = {};
  isLoading = false;
  OrganisationID: string | null;
  guidSubscription: any;
  abcd: any;
  userGuid: string;
  roleSelected = true;
  roles = {
    organization_user_role: 'User',
    organization_admin_role: 'Administrator'
  };

  default_roles: any = ['organization_user_role', 'document_reader'];

  @Input() title_tag: string;
  @Input() formType: string;
  @Input() form_data: [];
  loading = true;
  domainName = '';
  orgPasswordBasedLogin: boolean = true;
  constructor(
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private settingsService: SettingsService,
    private toaster: ToasterService
  ) {
    this.orgPasswordBasedLogin = localStorage.getItem('org_password_based_login') == 'true' ? true : false;
    this.getDomainName(localStorage.getItem('domain'));
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  ngOnInit() {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
    });
    this.getAddPeopleForm(this.form_data);
    this.getRolesList();
    this.userGuid = this.form_data ? this.form_data['guid'] : null;
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'key',
      textField: 'value',
      itemsShowLimit: 10,
      enableCheckAll: false,
      allowSearchFilter: false
    };

    if (this.formType === 'edit' && this.form_data['roles'] !== undefined) {
      this.selectedItems = this.form_data['roles'].map((item: any) => {
        const container: any = {};
        container['key'] = item;
        // Modify the value based on specific keys
        if (item === 'incident_operator') {
          container['value'] = 'Incident reporter';
        } else if (item === 'incident_coordinator') {
          container['value'] = 'Incident approver';
        } else {
          container['value'] = item
            .toLowerCase()
            .replace(/_/g, ' ')
            .replace(/(?: |\b)(\w)/g, function(key: any) {
              return key.toUpperCase();
            });
        }

        return container;
      });
    } else {
      this.selectedItems = this.default_roles.map((item: any) => {
        const container: any = {};
        container['isDisabled'] = true;
        container['key'] = item;
        if (item === 'incident_operator') {
          container['value'] = 'Incident reporter';
        } else if (item === 'incident_coordinator') {
          container['value'] = 'Incident approver';
        } else {
          container['value'] = item
            .toLowerCase()
            .replace(/_/g, ' ')
            .replace(/(?: |\b)(\w)/g, function(key: any) {
              return key.toUpperCase();
            });
        }

        return container;
      });
    }
    this.selectedItems.forEach((item: any) => {
      if (item.key == 'organization_user_role' || item.key == 'document_reader') {
        item['isDisabled'] = true;
      }
    });
    this.peopleForm.get('email').valueChanges.subscribe(value => {
      if (this.checkNonDomain(value) || this.f.email.invalid) {
        this.peopleForm.get('external_sharing').setValue(false);
        this.peopleForm.get('password_based_login').setValue(false);
        this.peopleForm.get('external_sharing').disable();
        this.peopleForm.get('password_based_login').disable();
      } else {
        this.peopleForm.get('external_sharing').enable();
        this.peopleForm.get('password_based_login').enable();
      }
    });
  }

  getSelectedRolesValues(GroupsList: any) {
    for (var group of GroupsList) {
      group = { key: group.key, value: group.value };
      let selected_group_guids = this.selectedItems.map((x: any) => x.key);
      if (
        selected_group_guids.includes(group.key) &&
        (group.key == 'organization_user_role' || group.key == 'document_reader')
      ) {
        group.isDisabled = true;
      } else {
        group.isDisabled = false;
      }
      this.selectedRoles.push(group);
    }
    this.GroupsList = this.selectedRoles;
  }

  private getRolesList(): void {
    this.settingsService.getOrganizationGroupsList().subscribe(
      data => {
        this.GroupsList = data;
        this.GroupsList = this.GroupsList.map((userList: any) => {
          if (userList.key === 'incident_operator') {
            userList.value = 'Incident reporter';
          } else if (userList.key === 'incident_coordinator') {
            userList.value = 'Incident approver';
          }
          return userList;
        });
        if (this.formType === 'edit' && this.form_data['roles'] !== undefined) {
          let datalist = this.GroupsList;
          this.getSelectedRolesValues(datalist);
        } else {
          let datalist = this.GroupsList;
          this.getSelectedRolesValues(datalist);
        }
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }

  onItemSelect(item: any) {
    this.checkRoleSelection();
  }

  onItemDeSelect(items: any) {
    this.checkRoleSelection();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.peopleForm.controls;
  }

  save() {
    let role: any = [];
    this.submitted = true;
    if (this.selectedItems.length > 0 && this.peopleForm.valid) {
      role = this.selectedItems.map((item: any) => {
        return item.key;
      });
      this.isLoading = true;
      const peopleData = this.peopleForm.value;
      peopleData['roles'] = role;
      this.userGuid ? this.submitOrganisationEditProfile(peopleData) : this.submitOrganisationAddProfile(peopleData);
    } else {
      this.roleSelected = false;
      return;
    }
  }

  submitOrganisationAddProfile(peopleData: any): void {
    this.settingsService
      .submitOrganisationAddPeopleProfile(this.OrganisationID, peopleData)
      .pipe(
        finalize(() => {
          this.peopleForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.error = {
            first_name: '',
            last_name: '',
            phone: '',
            email: ''
          };
          this.submitted = false;
          this.activeModal.close(true);
        },
        (error: any) => {
          this.error = error.error;
        }
      );
  }

  submitOrganisationEditProfile(peopleData: any): void {
    this.settingsService
      .submitOrganisationEditPeopleProfile(this.userGuid, this.OrganisationID, peopleData)
      .pipe(
        finalize(() => {
          this.peopleForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.error = {
            first_name: '',
            last_name: '',
            phone: '',
            email: ''
          };
          this.submitted = false;
          this.activeModal.close({ result: data, isUpdate: true });
        },
        (error: any) => {
          this.error = error.error;
          this.toaster.showError(error.error);
        }
      );
  }

  checkRoleSelection() {
    this.selectedItems.length > 0 ? (this.roleSelected = true) : (this.roleSelected = false);
  }

  getAddPeopleForm(data?: []) {
    this.peopleForm = this.formBuilder.group({
      first_name: [data ? (data['first_name'] ? data['first_name'] : '') : '', [Validators.required()]],
      last_name: [data ? (data['last_name'] ? data['last_name'] : '') : '', [Validators.required()]],
      phone: [data ? (data['phone'] ? data['phone'] : '') : '', []],
      email: [data ? (data['email'] ? data['email'] : '') : '', [Validators.email()]],
      selectedItems: [''],
      password_based_login: [data ? (data['password_based_login'] ? data['password_based_login'] : false) : false],
      external_sharing: [data ? (data['external_sharing'] ? data['external_sharing'] : false) : false]
    });
  }
  checkNonDomain(email = '') {
    if (!email) return false;
    let splitArr = email.split('@');
    if (splitArr && splitArr[1] !== this.domainName) return false;
    return true;
  }
  getDomainName(url: string) {
    const domainRegex = /^(?:https?:\/\/)?(?:www\.)?([^/?]+).*/;
    const match = url?.match(domainRegex);
    this.domainName = match ? match[1] || '' : null;
  }
}
