<ng-container>
  <div class="modal-content">
    <div class="modal-header cb-modal-header">
      <div>
        <h2 translate>
          Invite Collaborator
        </h2>
        <i class="fa fa-times close-modal" (click)="activeModal.close(false)" ngbAutoFocus translate></i>
      </div>
    </div>

    <div class="modal-body">
      <form [formGroup]="form">
        <div style="display: inline-block;">
          <input
            type="email"
            formControlName="email"
            ngModel
            required
            #inviteEmail
            placeholder="Enter Email"
            class="cb-input"
          />
          <button
            *ngIf="!isInviting"
            type="submit"
            [disabled]="form.get('email').invalid && (form.get('email').dirty || form.get('email').touched)"
            (click)="submitOrganisationAddProfile(inviteEmail.value)"
            class="btn-invite"
            [ngClass]="{
              'disabled-invite': form.get('email').invalid && (form.get('email').dirty || form.get('email').touched)
            }"
          >
            Invite
          </button>
          <button *ngIf="isInviting" type="submit" class="btn-invite">
            <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
          </button>
        </div>
        <div
          class="error-mssg pt-1"
          *ngIf="form.get('email').invalid && (form.get('email').dirty || form.get('email').touched)"
        >
          <div class="text-danger font-size-14" *ngIf="form.get('email').hasError('required')">Email is required.</div>
          <div class="text-danger font-size-14" *ngIf="form.get('email').hasError('email')">Invalid email format.</div>
        </div>
      </form>
      <label class="invited-collaborators-label">Invited Collaborators</label>
      <div
        *ngIf="isLoading"
        style="width: 95% !important;"
        class="font-weight-bold text-black-3 w-100 text-center position-absolute"
      >
        <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': isLoading }" style="font-size:large;"></i> &nbsp;
      </div>
      <div class="invited-collaborators-container" *ngIf="invitedCollaborators.length > 0 && !isLoading; else nomember">
        <div *ngFor="let collaborator of invitedCollaborators">
          <div class="d-flex align-items-center">
            <div class="initial-container">
              <span class="initial">{{ getInitials(collaborator.email) }}</span>
            </div>
            <div style="width: 55%;">
              <!-- <div style="font-size: 16px; font-weight: 400; color: #000000;">
                {{ collaborator.first_name }} {{ collaborator.last_name }}
              </div> -->
              <div class="email">{{ collaborator.email }}</div>
            </div>
            <div style="width: 140px !important;">
              <button *ngIf="collaborator['acceptance_pending']" class="btn-acceptance-pending">
                Acceptance Pending
              </button>
            </div>
            <div
              style="margin-left: auto;"
              class="text-black-1 p-3 pr-3 d-flex align-items-center link-1 cursor-pointer"
              (click)="openConfirm(collaborator['email'], collaborator['guid'])"
            >
              <span class="material-icons">delete_outline</span>
            </div>
          </div>
        </div>
      </div>
      <ng-template #nomember>
        <ng-container *ngIf="invitedCollaborators.length == 0 && !isLoading">
          <div class="no-collaborators-container">
            <div class="no-collaborators-container-center">
              <mat-icon class="no-collaborators-icon">people</mat-icon>
            </div>
            <div class="no-collaborators-yet-text">
              No Collaborators Yet...
            </div>
            <div class="no-collaborators-text">
              Invite team members to work together
            </div>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </div>
</ng-container>
