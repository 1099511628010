import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { finalize } from 'rxjs/operators';
import { SettingsService } from '@app/settings/settings.service';
import { Router } from '@angular/router';
import { IncidentService } from '@app/Organisation-admin/incident/incident.service';
import { ModalService } from '@app/core/modal/modal.service';
import { take } from 'rxjs/operators';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { UserService } from '@app/core/user.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';

@Component({
  selector: 'app-close-incident',
  templateUrl: './close-incident.component.html',
  styleUrls: ['./close-incident.component.scss']
})
export class CloseIncidentComponent implements OnInit {
  closeIncidentForm: FormGroup;
  submitted = false;
  isLoading = false;
  OrganisationID: string | null;
  guidSubscription: any;
  success: boolean;
  error: any;
  UserSignature: any = null;
  isSignatureLoading = true;
  signature_error: boolean = false;
  agreement_accepted: boolean = true;
  loading = true;
  loginUser: any;
  userName: any;

  fonts = ['italic 32px Pacifico', 'italic 32px Yellowtail', 'italic 32px Damion', 'italic 32px Lobster'];

  @Input() formType: string;
  @Input() title_tag: string;
  @Input() incidentGuid: string;
  @Input() incidentTitle: string;
  @Input() status_to_be_sent: string;
  @Input() action: string;
  backend_error: string;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private incidentService: IncidentService,
    private settingsService: SettingsService,
    private router: Router,
    private toaster: ToasterService,
    private translate: TranslateService,
    private modalService: ModalService,
    private userservice: UserService
  ) {}

  ngOnInit() {
    this.loginUser = this.userservice.getuserdetails();
    this.userName = this.loginUser.user['first_name'] + ' ' + this.loginUser.user['last_name'];
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getcloseIncidentForm();
      this.loadGoogleFonts(this.userName);
    });

    this.settingsService.getUserSignature(this.OrganisationID).subscribe(
      data => {
        this.UserSignature = data.url;
        this.isSignatureLoading = false;
      },
      error => {
        this.isSignatureLoading = false;
      }
    );
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  getcloseIncidentForm() {
    this.closeIncidentForm = this.formBuilder.group({
      remarks: ['', Validators.maxLength(4096)],
      agreement_accepted: ['']
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.closeIncidentForm.controls;
  }

  save() {
    this.submitted = true;
    this.isLoading = true;
    if (this.closeIncidentForm.valid && this.UserSignature) {
      const closeIncidentData = this.closeIncidentForm.value;
      if (closeIncidentData['agreement_accepted']) {
        this.agreement_accepted = true;
        this.submitApproveChanges(closeIncidentData);
      } else {
        this.isLoading = false;
        this.submitted = false;
        this.agreement_accepted = false;
      }
    } else {
      this.submitted = false;
      this.isLoading = false;
      if (!this.UserSignature) {
        this.signature_error = true;
      } else {
        this.signature_error = false;
      }
      return;
    }
  }

  submitApproveChanges(closeIncidentData: any): void {
    this.incidentService
      .closeIncident(this.OrganisationID, this.incidentGuid, closeIncidentData)
      .pipe(
        finalize(() => {
          this.closeIncidentForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.error = {
            remarks: ''
          };
          this.submitted = false;
          this.backend_error = '';
          this.activeModal.close({ result: data, isUpdate: true });
        },
        (error: any) => {
          this.submitted = false;
          this.isLoading = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
  }
  loadGoogleFonts(user_name: string) {
    console.log(user_name);
    this.settingsService.GenerateSignature(user_name, this.fonts);
  }

  goToSignatureUpload() {
    this.activeModal.close(false);
    this.router.navigate(['organisation/settings/profile']);
  }
  getUserSignature() {
    this.settingsService.getUserSignature(this.OrganisationID).subscribe(
      data => {
        this.UserSignature = data.url;
        this.isSignatureLoading = false;
        this.loading = false;
      },
      error => {
        this.isSignatureLoading = false;
      }
    );
  }
  OpenChangeSignatureModal(): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Upload signature',
        modalType: 'Upload signature'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getUserSignature();
          this.translate.get('organisation_profile.profile_toaster_message.signature_uploaded').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }
  OpenDrawSignatureModal(): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Draw Signature',
        modalType: 'Draw Signature'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getUserSignature();
          this.translate.get('organisation_profile.profile_toaster_message.signature_uploaded').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }
  OpenGenerateSignatureModal(user_name: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Generate Signature',
        modalType: 'Generate Signature',
        user_name: user_name,
        google_fonts: this.fonts
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getUserSignature();
          this.translate.get('organisation_profile.profile_toaster_message.signature_uploaded').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }
}
