import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbDateStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { SettingsService } from '@app/settings/settings.service';
import { finalize } from 'rxjs/operators';
import { UserService } from '@app/core/user.service';
import { ModalService } from '@app/core/modal/modal.service';
import { CustomDialogComponent } from '../customDialog/customDialog.component';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import stringInfo from '../../../../translations/en-US.json';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { SidenavService } from '@app/core/modal/sidenav.service';

@Component({
  selector: 'app-request-change',
  templateUrl: './request-change.component.html',
  styleUrls: ['./request-change.component.scss']
})
export class RequestChangeComponent implements OnInit {
  minDate: NgbDateStruct;
  InviteEditorForm: FormGroup;
  submitted = false;
  isLoading = false;
  success: boolean;
  OrganisationID: string | null;
  guidSubscription: any;
  dropdownList: any = [];
  selectedItems: any = [];
  selectedOldEditor: any = [];
  dropdownSettingsForOldUsers = {};
  dropdownSettingsForNewUsers = {};
  error: any;
  statusData: any;

  OldEditorSelected = true;
  EditorSelected = true;
  EditorList: object[];
  userList: object[];
  DataList: object[];
  loginUser: any;
  loginUsername: string;
  loginUserEmail: string;
  checked = false;
  OldUsersList: object[];
  loading = true;
  date = stringInfo.Document_Invite_Editor.Date;
  store_due_date = '';
  public emailList: any = [];
  @Input() formType: string;
  @Input() DocumentGuid: string;
  @Input() title_tag: string;
  @Input() DocCreatedBy: string;
  @Input() docTitle: string;
  @Input() modalType: string;

  oldEditorListGuid: any;
  editorlength: any;
  backend_error: any;
  status8: string;
  status12: string;
  isEmailvalid = false;
  searchString = '';
  private nameColors = new Map<string, string>();

  @ViewChild('multiSelectDropdown') multiSelectDropdown: any;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    private formBuilder: FormBuilder,
    private documentsService: DocumentsService,
    private userservice: UserService,
    private modalService: ModalService,
    private translate: TranslateService,
    private sidenavService: SidenavService
  ) {}

  ngOnInit() {
    this.loginUser = this.userservice.getuserdetails();
    this.loginUsername = this.loginUser.user['username'];
    this.loginUserEmail = this.loginUser.user['email'];

    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.minDate = this.documentsService.getTomorrowDate();
      this.getInviteEditorChangeReqForm();
      this.getEditorList();
      this.getUnassignedEditorsList();
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  onItemSelect(item: any) {
    item.selected = !item.selected;
    if (item.selected) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems = this.selectedItems.filter((x: any) => x.guid !== item.guid);
    }
    this.checkEditorSelection();
  }
  removeItem(item: any) {
    this.selectedItems = this.selectedItems.filter((x: any) => x.guid !== item.guid);
    const userIndex = this.userList.findIndex((x: any) => x.guid === item.guid);
    if (userIndex !== -1) {
      this.userList[userIndex]['selected'] = false;
    }
    this.checkEditorSelection();
  }
  onOldItemSelect(item: any) {
    item.selected = !item.selected;
    if (item.selected) {
      this.selectedOldEditor.push(item);
    } else {
      this.selectedOldEditor = this.selectedOldEditor.filter((x: any) => x.guid !== item.guid);
    }
    this.checkOldEditorSelection();
  }
  removeOldItem(item: any) {
    this.selectedOldEditor = this.selectedOldEditor.filter((x: any) => x.guid !== item.guid);
    const userIndex = this.OldUsersList.findIndex((x: any) => x.guid === item.guid);
    if (userIndex !== -1) {
      this.OldUsersList[userIndex]['selected'] = false;
    }
    this.checkOldEditorSelection();
  }

  checkEditorSelection() {
    this.selectedItems.length > 0 ? (this.EditorSelected = true) : (this.EditorSelected = false);
  }
  checkOldEditorSelection() {
    this.selectedOldEditor.length > 0 ? (this.OldEditorSelected = true) : (this.OldEditorSelected = false);
  }

  getInviteEditorChangeReqForm() {
    this.InviteEditorForm = this.formBuilder.group({
      due_date: ['', [Validators.required(), Validators.DateIsAfterToday()]],
      comment: ['', [Validators.maxLength(4096)]]
    });
  }
  getColor(name: string): string {
    if (this.nameColors.has(name)) {
      return this.nameColors.get(name)!;
    } else {
      const color = this.getRandomColor();
      this.nameColors.set(name, color);
      return color;
    }
  }
  getInitials(first_name: string, last_name: string): string {
    const firstNameInitial = first_name.charAt(0);
    const lastNameInitial = last_name.charAt(0);
    return `${firstNameInitial}${lastNameInitial}`;
  }
  getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.InviteEditorForm.controls;
  }

  private getEditorList(): void {
    this.documentsService.getDocumentEditorList(this.OrganisationID, this.DocumentGuid).subscribe(
      (data: any) => {
        this.OldUsersList = data;
        if (this.OldUsersList) {
          this.OldUsersList.forEach(user => {
            // var initials = user['user']['name'].match(/\b(\w)/g);
            // user['initials'] = initials.join('').toUpperCase();
            user['initials'] = user['user']['name']
              ? user['user']['name']
                  .match(/\b(\w)/g)
                  .join('')
                  .toUpperCase()
              : user['user']['email'].charAt(0).toUpperCase();
          });
        }
        this.OldUsersList.forEach(user => {
          user['FullName'] = user['user']['name'];
          if (this.modalType == 'Form Change Request') {
            user['org_user_guid'] = user['user']['guid'];
          }
        });
        this.OldUsersList.sort((a, b) => a['FullName'].localeCompare(b['FullName']));
        console.log(this.OldUsersList, 'OldUsersList');
      },

      (error: any) => {
        this.error = error.error.message;
      }
    );
  }

  private getUnassignedEditorsList(): void {
    this.documentsService.getListUnassignedEditors(this.OrganisationID, this.DocumentGuid).subscribe(
      (data: any) => {
        this.DataList = data;
        this.userList = this.DataList.filter(x => {
          return x['roles'].includes(
            'document_editor' || (this.DocCreatedBy == this.loginUserEmail && this.loginUserEmail == x['email'])
          );
        });
        this.userList.forEach(user => {
          user['FullName'] = user['first_name'] + ' ' + user['last_name'];
        });
        this.userList.sort((a, b) => a['FullName'].localeCompare(b['FullName']));
        console.log(this.userList);
      },
      (error: any) => {
        this.error = error.error.message;
      }
    );
  }

  onDateSelection(date: any) {
    if (date) {
      let myDate = new Date(date.year, date.month - 1, date.day + 1);
      this.store_due_date = myDate.toISOString().split('T')[0];
    } else {
      this.store_due_date = this.store_due_date;
    }
    this.checkEditorSelection();
    this.checkOldEditorSelection();
  }

  save() {
    let editor: any = [];
    let oldeditors: any = [];

    this.submitted = true;
    if (!this.store_due_date) return;
    let selectedItemsToSend = {
      selectedEmails: this.selectedItems
        .filter((item: any) => item.guid == item.FullName)
        .map((item: any) => item.guid),
      selectedUsers: this.selectedItems.filter((item: any) => item.guid !== item.FullName).map((item: any) => item.guid)
    };
    if ((this.selectedItems.length > 0 || this.selectedOldEditor.length > 0) && this.InviteEditorForm.valid) {
      editor = selectedItemsToSend.selectedUsers;
      oldeditors = this.selectedOldEditor.map((item: any) => {
        return item.org_user_guid;
      });
      this.editorlength = oldeditors.length;
      this.checkEditorSelection();
      this.isLoading = true;
      let editorData = this.InviteEditorForm.value;
      editorData['organization_users'] = editor;
      editorData['organization_old_editors'] = oldeditors;
      editorData['role'] = 'editor';
      editorData['organization_users'] = [
        ...editorData['organization_users'],
        ...editorData['organization_old_editors']
      ];
      editorData['due_date'] = this.store_due_date;
      // if (this.modalType !== 'Invite Editors for Request Change') {
      //   editorData['newly_added_users'] = selectedItemsToSend.selectedEmails;
      // }
      this.submitInviteEditor(editorData);
    } else {
      this.checkEditorSelection();
      return;
    }
  }
  changeName(statusData: any) {
    this.documentsService.setStatus(statusData);
  }

  submitInviteEditor(editorData: any): void {
    this.documentsService
      .submitInviteDocumentEditorData(this.OrganisationID, this.DocumentGuid, editorData)
      .pipe(
        finalize(() => {
          this.InviteEditorForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.error = {
            due_date: ''
          };
          this.backend_error = '';
          this.submitted = false;
          this.sidenavService.updateData({ isUpdate: true, result: data });
          this.sidenavService.close(false);
        },
        (error: any) => {
          this.isLoading = false;
          this.submitted = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
            this.sidenavService.updateData(this.backend_error, true);
          });
        }
      );
  }
  closeNav() {
    this.sidenavService.close(true);
  }
}
