import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { AboutModule } from '@app/about/about.module';
import { ConnectSlackComponent } from './steps/connectSlack/connectSlack.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectProgramComponent } from '@app/home/steps/selectProgram/selectProgram.component';
import { InviteCommitteeMembersComponent } from '@app/home/steps/inviteCommitteeMembers/inviteCommitteeMembers.component';
import { WizardService } from '@app/home/wizard.services';
import { CreateBackendAutomationComponent } from '@app/home/steps/createBackendAutomation/createBackendAutomation.component';
import { ReviewScheduleComponent } from '@app/home/steps/reviewSchedule/reviewSchedule.component';
import { ReviewRunFinishComponent } from '@app/home/steps/reviewRunFinish/reviewRunFinish.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AddMembersDialogComponent } from '@app/home/steps/inviteCommitteeMembers/addMembersDialog.component';
import { SelectOrganisationComponent } from '@app/home/steps/selectOrganisation/selectOrganisation.component';
import { OnboardHeaderComponent } from '@app/home/onboard-header/onboard-header.component';
import { OnboardFooterComponent } from '@app/home/onboard-footer/onboard-footer.component';
import { Onboard7FinalComponent } from '@app/home/steps/onboard7-final/onboard7-final.component';
import { CheckboxScheduleComponent } from '@app/home/steps/reviewRunFinish/checkboxSchedule/checkboxSchedule.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ProgramCheckboxComponent } from '@app/home/steps/selectProgram/checkboxComponent/programCheckbox.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DemoMaterialModule } from '../materials.module';
import { ProgramConfigurationComponent } from './steps/program-configuration/program-configuration.component';
import { ProgramCreationComponent } from '../home/steps/program-creation/program-creation.component';
import { GenerateDocumentsComponent } from '../home/steps/generate-documents/generate-documents.component';
import { CbLiteHeaderComponent } from '../home/steps/cb-lite-header/cb-lite-header.component';
import { QuickSurveyComponent } from '../home/steps/quick-survey/quick-survey.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatDialogModule } from '@angular/material/dialog';
import { EditDocumentDialogComponent } from './steps/generate-documents/edit-document-dialog/edit-document-dialog.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DownloadCertificateComponent } from './steps/download-certificate/download-certificate.component';
@NgModule({
  imports: [
    NgbModule,
    CommonModule,
    NgSelectModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    HomeRoutingModule,
    AboutModule,
    ReactiveFormsModule,
    FormsModule,
    DemoMaterialModule,
    NgxSkeletonLoaderModule,
    MatDialogModule,
    Ng2SearchPipeModule
  ],
  declarations: [
    CheckboxScheduleComponent,
    ProgramCheckboxComponent,
    Onboard7FinalComponent,
    OnboardFooterComponent,
    OnboardHeaderComponent,
    HomeComponent,
    ConnectSlackComponent,
    SelectProgramComponent,
    InviteCommitteeMembersComponent,
    CreateBackendAutomationComponent,
    ReviewScheduleComponent,
    ReviewRunFinishComponent,
    AddMembersDialogComponent,
    SelectOrganisationComponent,
    ProgramConfigurationComponent,
    ProgramCreationComponent,
    GenerateDocumentsComponent,
    CbLiteHeaderComponent,
    QuickSurveyComponent,
    EditDocumentDialogComponent,
    DownloadCertificateComponent
  ],
  providers: [WizardService],
  entryComponents: [AddMembersDialogComponent]
})
export class HomeModule {}
