import { Component, OnInit } from '@angular/core';
import stringInfo from '../../../../translations/en-US.json';
import { Subscription } from 'rxjs';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '../documents.service';
import { OrganisationAdminService } from '@app/Organisation-admin/organisation-admin.service';
import { UserService } from '@app/core/user.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '@app/core/modal/modal.service';
import { finalize } from 'rxjs/operators';

export interface ArchiveDocuments {
  count: number;
  next?: any;
  previous?: any;
  results: ArchiveDocumentsResult[];
}

export interface ArchiveDocumentsResult {
  guid: string;
  title: string;
  version: string;
  created_by: ArchivedBy;
  allowed_actions_list: string[];
  archived_on_timestamp?: string;
  archived_by: ArchivedBy;
  document_type: string;
}

export interface ArchivedBy {
  guid: string;
  name: string;
  email: string;
}

@Component({
  selector: 'app-archive-document',
  templateUrl: './archive-document.component.html',
  styleUrls: ['./archive-document.component.scss']
})
export class ArchiveDocumentComponent implements OnInit {
  [x: string]: any;
  organisationID: string;
  isloading: boolean = false;
  isShowRefresh: boolean = true;
  spnning: boolean = false;
  page: number = 1;
  searchText: string = '';
  placeholderSearch = stringInfo.Archive_Documents.PlaceHolder_Search;
  scrollLoader: boolean = false;
  paginatedDataUrl: any;
  modalScrollDistance: number = 0.1;
  modalScrollThrottle: number = 50;
  guidSubscription: Subscription;
  loginUser: any;
  loginUsername: string;
  loginUserEmail: string;
  archiveDocumentData: ArchiveDocuments;
  error: string;
  constructor(
    private orgService: OrganisationService,
    private documentsService: DocumentsService,
    private orgAdminService: OrganisationAdminService,
    private userservice: UserService,
    private ModalService: ModalService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.loginUser = this.userservice.getuserdetails();
    this.loginUsername = this.loginUser.user['username'];
    this.loginUserEmail = this.loginUser.user['email'];
    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe((guid: any) => {
      this.organisationID = guid;
      this.getArchiveDocuments();
      this.getRole();
    });
  }

  getRole() {
    this.role = JSON.parse(localStorage.getItem('credentials'));
    this.userRole = this.role['user']['role'];
    for (let i = 0; i < 10; i++) {
      if (this.userRole[i] == 'organization_admin_role' || this.userRole[i] == 'document_author') {
        this.key = this.userRole[i];
        this.show_add_new_button = true;
      }
    }
  }

  private getArchiveDocuments(): void {
    if (this.organisationID != null) {
      if (this.isShowRefresh) {
        this.isloading = true;
      }
      this.documentsService.getArchiveDocumentsList(this.organisationID, this.page, this.searchText).subscribe(
        data => {
          this.isShowRefresh = true;
          this.spnning = false;
          this.isloading = false;
          this.paginatedDataUrl = data;
          this.archiveDocumentData = data;
          this.archiveDocumentData.results.forEach((item: any) => {
            if (item['archived_on_timestamp']) {
              item['archived_on_timestamp'] = this.getFormattedDate(item['archived_on_timestamp']);
            }
          });
        },
        error => {
          this.error = error.error.message;
          this.isloading = false;
          this.spnning = false;
          this.isShowRefresh = true;
        }
      );
    }
  }

  getFormattedDate(archived_on_timestamp: string): Date {
    // Split the date-time string
    const parts = archived_on_timestamp.split(/[- :]/);

    // Create a Date object
    const formattedDate = new Date(
      parseInt(parts[0], 10), // year
      parseInt(parts[1], 10) - 1, // month (months are zero-indexed)
      parseInt(parts[2], 10), // day
      parseInt(parts[3], 10) + (parts[5] === 'PM' && parts[3] !== '12' ? 12 : 0), // hour
      parseInt(parts[4], 10), // minute
      parseInt(parts[5], 10) // second
    );

    return formattedDate;
  }

  unarchiveDocument(archiveDocument: ArchiveDocumentsResult) {
    this.isloading = true;
    this.documentsService
      .unarchiveDocument(this.organisationID, archiveDocument.guid)
      .pipe(
        finalize(() => {
          this.isloading = false;
          this.getArchiveDocuments();
        })
      )
      .subscribe(
        data => {
          this.translate.get(data.message).subscribe(res => {
            this.toaster.showSuccess(res);
          });
        },
        error => {
          this.error = error.error.message;
          this.toaster.showError(this.error);
        }
      );
  }

  refresh() {
    this.isShowRefresh = false;
    this.spnning = true;
    this.page = 1;
    this.searchText = '';
    this.getArchiveDocuments();
  }

  onScroll() {
    if (this.paginatedDataUrl?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }

  onScrollData() {
    this.scrollLoader = true;
    if (this.search_text == null || undefined) {
      this.search_text = '';
      this.documentsService.getArchiveDocumentsList(this.organisationID, (this.page += 1), this.searchText).subscribe(
        data => {
          this.scrollLoader = false;
          this.paginatedDataUrl = data;
          this.pagnationData = data.results;
          this.archiveDocumentData.results = this.archiveDocumentData.results.concat(data.results);
        },
        error => {
          this.scrollLoader = false;
          this.error = error.error.message;
        }
      );
    } else {
      this.scrollLoader = true;

      this.documentsService.getArchiveDocumentsList(this.organisationID, (this.page += 1), this.searchText).subscribe(
        data => {
          this.scrollLoader = false;
          this.paginatedDataUrl = data;
          this.pagnationData = data.results;
          this.archiveDocumentData.results = this.archiveDocumentData.results.concat(data.results);
        },
        error => {
          this.scrollLoader = false;
          this.error = error.error.message;
        }
      );
    }
  }

  onScrollUp() {
    this.scrollLoader = false;
  }
}
