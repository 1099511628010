import { Component, OnInit } from '@angular/core';
import AttentionJson from 'app/Organisation-admin/attention_table.json';
import { Router, ActivatedRoute } from '@angular/router';
import { OrganisationService } from '@app/core/organisation.service';
import { SettingsService } from '@app/settings/settings.service';
import { ModalService } from '@app/core/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { IncidentService } from '../incident.service';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { take } from 'rxjs/operators';
import stringInfo from '../../../../translations/en-US.json';

interface Form {
  id: Number;
  title: String;
  created_by: String;
  status: String;
  last_changed: String;
  due_date: String;
  assigned_users: String;
}

@Component({
  selector: 'app-incident-details',
  templateUrl: './incident-details.component.html',
  styleUrls: ['./incident-details.component.scss']
})
export class IncidentDetailsComponent implements OnInit {
  complete = stringInfo.Document_Details.Complete_Review;
  loaderArr: any = [];
  historyLog: any;
  accArray: any[] = [];
  jiraTicketAssignee: any;
  loading = true;
  guidSubscription: any;
  OrganisationID: string | null;
  error: any;
  TrainingGroupList: Array<any> = [];
  page = 1;
  reviewerList: Array<any> = [];
  approverList: Array<any> = [];
  jiraTicket: Array<any> = [];
  ticket: any;
  publishdocInitial: string;
  editDoc: any;
  editDocInitiatedBy: any;
  editDocumentInitial: string;
  currentState: any;
  editingDoneStatus: any;
  prevState: any = [];
  reqChangeStatus: any;
  prevRequest: any;
  prevApprove: any;
  approveStatus: any;
  inviteApproverStatus: any;
  allEditCompleteStatus: any;
  editDocumentStatus: any;
  allApprovedStatus: any;
  publishDocStatus: any;
  editorRevoke: any;
  editorRevokedBy: any;
  revokeEditorInitial: any;
  inviteApproverInitial: any;
  reqChangeInitiatedIntial: any;
  allowed_actions_list: any;
  authorName: any;
  allowedActionListLenght: any;
  detailInfo: any;
  details: any;
  version: any = [];
  title: any;
  status: any;
  editors: any = [];
  editor: any;
  editorsInitial: any;
  approvers: any = [];
  editorslength: any;
  approverlength: any;
  description: any;
  createdDate: any;
  publishedDate: any;
  historyversion: any;
  versionKey: any;
  initiatedby: any;
  assignedTo: any = [];
  completed_by: any;
  allcompleteedit: any;
  changeRequestAssignTo: any;
  changeRequestInitiatedBy: any;
  invitedBy: any;
  invitedTo: any;
  docApprover: any;
  allApprover: any;
  revokerName: any;
  revokedBy: any;
  approverinitial: any;
  allcompleteInitials: string;
  approverRevoke: any;
  approverRevokedBy: any;
  draft: any;
  draftInitial: any;
  inviteEditorInitial: string;
  editingDoneInitial: string;
  reqChangeIntial: string;
  approveDocInitial: string;
  inviteapproverInitial: string;
  revokededitorInitial: string;
  revokeApproverInitial: string;
  alleditInitial: string;
  versionGuid: any;
  setVersion: boolean;
  author: any;
  history_data: Array<any> = [];
  document_version: any;
  workspace: any;
  selectedVersion: any;
  role: any;
  userRole: any;
  show_add_new_button = false;
  key: any;
  loginUserEmail: any;
  comment: any;

  constructor(
    private orgService: OrganisationService,
    private router: Router,
    private incidentService: IncidentService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private toaster: ToasterService
  ) {}

  ngOnInit(): void {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      const CamGuid: string = this.route.snapshot.queryParamMap.get('guid');
      if (CamGuid) {
        this.getJiraTicketsAssignees(CamGuid);
        this.getReviewerList(CamGuid);
        this.getApproverList(CamGuid);
      } else {
        this.workspace = this.route.snapshot.params['guid'];
        this.getJiraTicketsAssignees(this.workspace);
        this.getReviewerList(this.workspace);
        this.getApproverList(this.workspace);
      }
    });
  }

  getJiraTicketsAssignees(camGuid: any): void {
    this.role = JSON.parse(localStorage.getItem('credentials'));
    this.incidentService.getJiraTicketsAssignees(this.OrganisationID, camGuid).subscribe(
      data => {
        this.show_add_new_button = data['created_by']['email'] == this.role['user']['email'] ? true : false;
        this.history_data = [];
        this.jiraTicketAssignee = data;
        this.loading = false;
        this.detailInfo = data;
        this.title = data.title;
        this.status = data.status.replace(/_/g, ' ');
        this.historyLog = data.history_log;
        for (let i = 0; i < data.history_log.length; i++) {
          let currentState = '';
          let previousState = '';
          let showStateChange = false;

          if (i > 0) {
            currentState = this.historyLog[i].action_log.display_status;
            previousState = this.historyLog[i - 1].action_log.display_status;
            this.historyLog[i].previousState = previousState;
            this.historyLog[i].currentState = currentState;
          }

          if (currentState !== previousState) {
            showStateChange = true;
          }
          if (
            this.historyLog[i].action_type == 'create_incident' ||
            this.historyLog[i].action_type == 'create_tickets' ||
            this.historyLog[i].action_type == 'link_tickets'
          ) {
            this.draft = this.historyLog[i]['action_log']['author'];
            var draftInit = String(this.draft).match(/\b(\w)/g);
            this.draftInitial = draftInit.join('').toUpperCase();
            this.history_data.push({
              history: this.historyLog[i],
              created_by: this.draft,
              created_by_Initial: this.draftInitial
            });
          } else if (this.historyLog[i].action_type == 'invite_reviewer') {
            let username: Array<any> = [];
            this.historyLog[i]['action_log']['assigned_to']['names'].forEach((j: any) => {
              this.assignedTo = j['username'];
              this.comment = this.historyLog[i]['action_log']['assigned_to']['comment'];
              this.initiatedby = data.history_log[i].action_log.initiated_by.name;
              var inviteEditorIntial = String(this.initiatedby).match(/\b(\w)/g);
              this.inviteEditorInitial = inviteEditorIntial.join('').toUpperCase();
              username.push({
                assignedTo: this.assignedTo
              });
            });
            this.history_data.push({
              history: this.historyLog[i],
              username: username,
              initiatedby: this.initiatedby,
              inviteEditorInitial: this.inviteEditorInitial,
              comment: this.comment
            });
          } else if (this.historyLog[i].action_type == 'invite_approver') {
            let username: Array<any> = [];
            this.historyLog[i]['action_log']['assigned_to']['names'].forEach((j: any) => {
              this.assignedTo = j['username'];
              this.initiatedby = data.history_log[i].action_log.initiated_by.name;
              this.comment = this.historyLog[i]['action_log']['assigned_to']['comment'];
              var inviteApproverIntial = String(this.initiatedby).match(/\b(\w)/g);
              this.inviteApproverInitial = inviteApproverIntial.join('').toUpperCase();
              username.push({
                assignedTo: this.assignedTo
              });
            });
            this.history_data.push({
              history: this.historyLog[i],
              username: username,
              initiatedby: this.initiatedby,
              inviteApproverInitial: this.inviteApproverInitial,
              comment: this.comment
            });
          } else if (this.historyLog[i].action_type == 'block_incident') {
            this.draft = this.historyLog[i]['action_log']['completed_by']['name'][0]['username'];
            var draftInit = String(this.draft).match(/\b(\w)/g);
            this.comment = this.historyLog[i]['action_log']['completed_by']['comment'];
            this.draftInitial = draftInit.join('').toUpperCase();
            this.history_data.push({
              history: this.historyLog[i],
              created_by: this.draft,
              created_by_Initial: this.draftInitial,
              comment: this.comment
            });
          } else if (this.historyLog[i].action_type == 'close_incident') {
            if (data.history_log[i].action_log.type == 'collaboration_task_completed') {
              this.draft = this.historyLog[i]['action_log']['completed_by']['name'][0]['username'];
              var draftInit = String(this.draft).match(/\b(\w)/g);
              this.comment = this.historyLog[i]['action_log']['completed_by']['comment'];
              this.draftInitial = draftInit.join('').toUpperCase();
              this.history_data.push({
                history: this.historyLog[i],
                created_by: this.draft,
                created_by_Initial: this.draftInitial,
                comment: this.comment
              });
            }
          } else if (
            this.historyLog[i].action_type == 'revoke_approver' ||
            this.historyLog[i].action_type == 'Unblock Incident'
          ) {
            this.approverRevoke = this.historyLog[i]['action_log']['revoked_details']['name'];
            this.approverRevokedBy = this.historyLog[i]['action_log']['revoked_details']['revoked_by'];
            var revokeApprover = String(this.approverRevokedBy).match(/\b(\w)/g);
            this.comment = this.historyLog[i]['action_log']['revoked_details']['comment'];
            this.revokeApproverInitial = revokeApprover.join('').toUpperCase();
            this.history_data.push({
              history: this.historyLog[i],
              approverRevoke: this.approverRevoke,
              approverRevokedBy: this.approverRevokedBy,
              revokeApproverInitial: this.revokeApproverInitial,
              comment: this.comment
            });
          } else if (this.historyLog[i].action_type == 'revoke_approver') {
            this.approverRevoke = this.historyLog[i]['action_log']['revoked_details']['name'];
            this.approverRevokedBy = this.historyLog[i]['action_log']['revoked_details']['revoked_by'];
            var revokeApprover = String(this.approverRevokedBy).match(/\b(\w)/g);
            this.revokeApproverInitial = revokeApprover.join('').toUpperCase();
            this.history_data.push({
              history: this.historyLog[i],
              approverRevoke: this.approverRevoke,
              approverRevokedBy: this.approverRevokedBy,
              revokeApproverInitial: this.revokeApproverInitial
            });
          } else if (this.historyLog[i].action_type == 'revoke_reviewer') {
            if (this.historyLog[i]['action_log']['revoked_details']) {
              this.editorRevoke = this.historyLog[i]['action_log']['revoked_details']['name'];
              this.editorRevokedBy = this.historyLog[i]['action_log']['revoked_details']['revoked_by'];
              this.comment = this.historyLog[i]['action_log']['revoked_details']['comment'];
            } else if (this.historyLog[i]['action_log']['users']) {
              this.editorRevoke = this.historyLog[i]['action_log']['users'][0]['username'];
              this.editorRevokedBy = this.jiraTicketAssignee['created_by']['name'];
            }
            // this.editorRevokedBy = this.historyLog[i]['action_log']['revoked_details']['revoked_by'];
            var revokeEditor = String(this.editorRevokedBy).match(/\b(\w)/g);
            this.revokeEditorInitial = revokeEditor.join('').toUpperCase();
            this.history_data.push({
              history: this.historyLog[i],
              editorRevoke: this.editorRevoke,
              editorRevokedBy: this.editorRevokedBy,
              revokeEditorInitial: this.revokeEditorInitial,
              comment: this.comment
            });
          } else if (this.historyLog[i].action_type == 'resolve_incident') {
            if (data.history_log[i].action_log.type == 'collaboration_task_completed') {
              this.allcompleteedit = this.historyLog[i]['action_log']['completed_by']['name'][0]['username'];
              var alledit = String(this.allcompleteedit).match(/\b(\w)/g);
              this.alleditInitial = alledit.join('').toUpperCase();
              this.comment = this.historyLog[i]['action_log']['completed_by']['comment'];
              this.allEditCompleteStatus = this.historyLog[i]['action_log']['display_status'];
              this.history_data.push({
                history: this.historyLog[i],
                allcompleteedit: this.allcompleteedit,
                alleditInitial: this.alleditInitial,
                allEditCompleteStatus: this.allEditCompleteStatus,
                comment: this.comment
              });
            }
          } else if (this.historyLog[i].action_type == 'all_moved_to_resolved') {
            this.allApprover = this.historyLog[i]['action_log']['users'][0]['username'];
            var initials1 = String(this.allApprover).match(/\b(\w)/g);
            this.approverinitial = initials1.join('').toUpperCase();
            this.allApprovedStatus = this.historyLog[i]['action_log']['display_status'];
            this.history_data.push({
              history: this.historyLog[i],
              allApprover: this.allApprover,
              approverinitial: this.approverinitial,
              allApprovedStatus: this.allApprovedStatus
            });
          } else if (this.historyLog[i].action_type == 'move_to_in_progress') {
            this.completed_by = this.historyLog[i]['action_log']['completed_by']['name'][0]['username'];
            this.comment = this.historyLog[i]['action_log']['completed_by']['comment'];
            var editingDone = String(this.completed_by).match(/\b(\w)/g);
            this.editingDoneInitial = editingDone.join('').toUpperCase();
            this.history_data.push({
              history: this.historyLog[i],
              completed_by: this.completed_by,
              editingDoneInitial: this.editingDoneInitial,
              comment: this.comment
            });
          } else if (this.historyLog[i].action_type == 'reopen_incident') {
            //Handle Invite Editor For Request Change
            if (data.history_log[i].action_log.type == 'create_collaboration_task') {
              let username: Array<any> = [];
              this.historyLog[i]['action_log']['assigned_to']['names'].forEach((j: any) => {
                this.assignedTo = j['username'];
                this.comment = this.historyLog[i]['action_log']['assigned_to']['comment'];
                this.changeRequestInitiatedBy = data.history_log[i].action_log.initiated_by.name;
                var reqChange = String(this.changeRequestAssignTo).match(/\b(\w)/g);
                this.reqChangeIntial = reqChange.join('').toUpperCase();
                var reqchangeinitiated = String(this.changeRequestInitiatedBy).match(/\b(\w)/g);
                this.reqChangeInitiatedIntial = reqchangeinitiated.join('').toUpperCase();
                username.push({
                  assignedTo: this.assignedTo
                });
              });
              this.history_data.push({
                history: this.historyLog[i],
                changeRequestInitiatedBy: this.changeRequestInitiatedBy,
                username: username,
                reqChangeIntial: this.reqChangeIntial,
                reqChangeInitiatedIntial: this.reqChangeInitiatedIntial,
                comment: this.comment
              });
            }
          }
        }
        if (this.detailInfo) {
          var initials = this.detailInfo['created_by']['name'].match(/\b(\w)/g);
          this.details = initials.join('').toUpperCase();
          this.authorName = this.detailInfo['created_by']['name'];
          this.editors.forEach((editor: { [x: string]: any }) => {
            var initials = editor['user']['name'].match(/\b(\w)/g);
            editor['initials'] = initials.join('').toUpperCase();
          });

          this.approvers.forEach((approver: { [x: string]: any }) => {
            var initials = approver['user']['name'].match(/\b(\w)/g);
            approver['initials'] = initials.join('').toUpperCase();
          });
        }
      },
      error => {
        this.error = error.error.message;
      }
    );
  }

  private getReviewerList(camGuid: any): void {
    this.incidentService.getIncidentReviewerList(this.OrganisationID, camGuid).subscribe(
      data => {
        this.reviewerList = data;
        if (this.reviewerList) {
          this.reviewerList.forEach(reviewer => {
            var initials = reviewer['user']['name'].match(/\b(\w)/g);
            reviewer['initials'] = initials.join('').toUpperCase();
          });
        }
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }

  private getApproverList(camGuid: any): void {
    this.incidentService.getIncidentApproverList(this.OrganisationID, camGuid).subscribe(
      data => {
        this.approverList = data;
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }
  displayStyle = 'none';
  LogdisplayStyle = 'none';

  OpenFormRequest() {
    this.displayStyle = 'block';
  }
  CloseFormRequest() {
    this.displayStyle = 'none';
  }
  clearLoader(value: any): void {
    const index = this.loaderArr.indexOf(value);
    if (index > -1) {
      this.loaderArr.splice(index, 1);
    }
  }

  toggleDetails(index: any): void {
    if (this.accArray.includes(index)) {
      const pos = this.accArray.indexOf(index);
      if (index > -1) {
        this.accArray.splice(pos, 1);
      }
    } else {
      this.accArray.push(index);
    }
  }

  pickIncident(incidentGuid: string, incidentTitle: string, status_to_be_sent: string, action: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Pick Incident',
        modalType: 'Pick Incident',
        incidentGuid: incidentGuid,
        incidentTitle: incidentTitle,
        status_to_be_sent: status_to_be_sent,
        action: action
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          this.getJiraTicketsAssignees(incidentGuid);
          this.getReviewerList(incidentGuid);
          this.getApproverList(incidentGuid);
          this.translate.get('pick_incident.incident_picked_successfully').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
        }
      });
  }

  blockIncident(incidentGuid: string, incidentTitle: string, status_to_be_sent: string, action: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Block Incident',
        modalType: 'Block Incident',
        incidentGuid: incidentGuid,
        incidentTitle: incidentTitle,
        status_to_be_sent: status_to_be_sent,
        action: action
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          this.getJiraTicketsAssignees(incidentGuid);
          this.getReviewerList(incidentGuid);
          this.getApproverList(incidentGuid);
          this.translate.get('block_incident.incident_blocked_successfully').subscribe(res => {
            this.toaster.showSuccess(res);
            this.getJiraTicketsAssignees(incidentGuid);
          });
        } else {
        }
      });
  }
  resolveIncident(incidentGuid: string, incidentTitle: string, status_to_be_sent: string, action: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Resolve Incident',
        modalType: 'Resolve Incident',
        incidentGuid: incidentGuid,
        incidentTitle: incidentTitle,
        status_to_be_sent: status_to_be_sent,
        action: action
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          this.getJiraTicketsAssignees(incidentGuid);
          this.getReviewerList(incidentGuid);
          this.getApproverList(incidentGuid);
          this.translate.get('resolve_incident.incident_resolve_successfully').subscribe(res => {
            this.toaster.showSuccess(res);
            this.getJiraTicketsAssignees(incidentGuid);
          });
        } else {
        }
      });
  }

  closeIncident(incidentGuid: string, incidentTitle: string, status_to_be_sent: string, action: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Are you sure you want to close the incident ?',
        modalType: 'Close Incident',
        incidentGuid: incidentGuid,
        incidentTitle: incidentTitle,
        status_to_be_sent: status_to_be_sent,
        action: action
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          this.getJiraTicketsAssignees(incidentGuid);
          this.getReviewerList(incidentGuid);
          this.getApproverList(incidentGuid);
          this.translate.get('close_incident.incident_close_successfully').subscribe(res => {
            this.toaster.showSuccess(res);
            this.getJiraTicketsAssignees(incidentGuid);
          });
        } else {
        }
      });
  }
  linkIncident(incidentGuid: string, incidentTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Link an Issue ',
        modalType: 'Link an Issue',
        incidentGuid: incidentGuid,
        incidentTitle: incidentTitle
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.translate.get('reopen_incident.incident_reopen_successfully').subscribe(res => {
            this.toaster.showSuccess(res);
            this.getJiraTicketsAssignees(incidentGuid);
          });
        } else {
        }
      });
  }

  opneCreateJiraTicketForm(incidentGuid: any, incidentTitle: string, status_to_be_sent: string) {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Create jira ticket',
        modalType: 'Create jira ticket',
        incidentGuid: incidentGuid,
        incidentTitle: incidentTitle,
        status_to_be_sent: status_to_be_sent
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        this.getJiraTicketsAssignees(incidentGuid);
        this.getApproverList(incidentGuid);
        this.getReviewerList(incidentGuid);
      });
  }

  reviewerApproverOpenModal(incidentGuid: string, incCreatedBy: string, incidentTitle: string, action: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: action == 'Manage Reviewers' ? 'Manage Reviewer' : 'Manage Approvers',
        modalType: action == 'Manage Reviewers' ? 'Manage Reviewer' : 'Invite Approvers',
        incidentGuid: incidentGuid,
        incCreatedBy: incCreatedBy,
        incidentTitle: incidentTitle
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          if (result.show_msg == true) {
            const toastMessage =
              action == 'Manage Reviewers'
                ? 'Incident_Manage_Reviewer.invite_reviewer_toaster_message.invite_reviewer'
                : 'Incident_Manage_Approver.invite_approver_toaster_message.invite_approver';

            this.translate.get(toastMessage).subscribe(res => {
              this.toaster.showSuccess(res);
            });
          }

          action == 'Manage Reviewers' ? this.getReviewerList(incidentGuid) : this.getApproverList(incidentGuid);
          this.getJiraTicketsAssignees(incidentGuid);
          this.getApproverList(incidentGuid);
          this.getReviewerList(incidentGuid);
        } else {
          this.getJiraTicketsAssignees(incidentGuid);
          this.getApproverList(incidentGuid);
          this.getReviewerList(incidentGuid);
        }
      });
  }

  approveIncident(incidentGuid: string, incidentTitle: string, status_to_be_sent: string, action: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Are you sure you want to approve changes ?',
        modalType: 'Approve Incident',
        incidentGuid: incidentGuid,
        incidentTitle: incidentTitle,
        status_to_be_sent: status_to_be_sent,
        action: action
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          this.translate.get('approve_incident.incident_approved_successfully').subscribe(res => {
            this.toaster.showSuccess(res);
            this.getJiraTicketsAssignees(incidentGuid);
            this.getReviewerList(incidentGuid);
            this.getApproverList(incidentGuid);
          });
        } else {
        }
      });
  }
  goTOEditIncident(incidentGuid: any) {
    this.router.navigate(['/organisation/incident/edit-incident'], { queryParams: { incidentGuid: incidentGuid } });
  }

  unblockIncident(
    incidentGuid: string,
    incidentTitle: string,
    name: string,
    status_to_be_sent: string,
    action: string
  ): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Unblock Incident',
        modalType: 'Unblock Incident',
        incidentGuid: incidentGuid,
        message: 'Are you sure you want to unblock incident ' + incidentTitle + ' requested by ' + name + ' ?',
        status_to_be_sent: status_to_be_sent,
        action: action
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          this.translate.get('unblock_incident.unblocked_incident').subscribe(res => {
            this.toaster.showSuccess(res);
            this.getJiraTicketsAssignees(incidentGuid);
            this.getReviewerList(incidentGuid);
            this.getApproverList(incidentGuid);
          });
        } else {
        }
      });
  }

  onNavigate(jira_url: string) {
    window.open(jira_url, '_blank');
  }

  goToLinkTicket(incidentGuid: any, incidentTitle: any) {
    this.linkIncident(incidentGuid, incidentTitle);
  }

  goToCreateTicket(incidentGuid: any, incidentTitle: any, status_to_be_sent: any) {
    this.opneCreateJiraTicketForm(incidentGuid, incidentTitle, status_to_be_sent);
  }

  goToAddReviewerApprover(incidentGuid: any, incCreatedBy: any, incidentTitle: any, action: string) {
    this.reviewerApproverOpenModal(incidentGuid, incCreatedBy, incidentTitle, action);
  }

  InviteEditorsForChangeReqOpenModal(incidentGuid: string, incCreatedBy: string, incidentTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Request Change',
        modalType: 'Request Change',
        incidentGuid: incidentGuid,
        incidentTitle: incidentTitle,
        incCreatedBy: incCreatedBy
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          this.translate
            .get('change_request_for_incident.change_request_toaster_message.change_request_submitted')
            .subscribe(res => {
              this.toaster.showSuccess(res);
              this.getJiraTicketsAssignees(incidentGuid);
              this.getReviewerList(incidentGuid);
              this.getApproverList(incidentGuid);
            });
        } else {
        }
      });
  }

  getClickEvents(
    action: string,
    incidentGuid: string,
    incCreatedBy: string,
    incidentTitle: string,
    name: string,
    status_to_be_sent: string,
    jira_url: string
  ): void {
    console.log('status_to_be_sent', status_to_be_sent);
    if (action['value'] == 'Create Ticket') {
      this.opneCreateJiraTicketForm(incidentGuid, incidentTitle, status_to_be_sent);
    } else if (action['value'] == 'Link Ticket') {
      this.linkIncident(incidentGuid, incidentTitle);
    } else if (action['value'] == 'Edit Incident') {
      this.goTOEditIncident(incidentGuid);
    } else if (action['value'] == 'Resolve Incident') {
      this.resolveIncident(incidentGuid, incidentTitle, status_to_be_sent, action);
    } else if (action['value'] == 'Manage Reviewers' || action['value'] == 'Manage Approvers') {
      this.reviewerApproverOpenModal(incidentGuid, incCreatedBy, incidentTitle, action['value']);
    } else if (action['value'] == 'Block Incident') {
      this.blockIncident(incidentGuid, incidentTitle, status_to_be_sent, action);
    } else if (action['value'] == 'Approve Incident') {
      this.approveIncident(incidentGuid, incidentTitle, status_to_be_sent, action);
    } else if (action['value'] == 'Close Incident') {
      this.closeIncident(incidentGuid, incidentTitle, status_to_be_sent, action);
    } else if (action['value'] == 'View Incident') {
      this.onNavigate(jira_url);
    } else if (action['value'] == 'Unblock Incident') {
      this.unblockIncident(incidentGuid, incidentTitle, name, status_to_be_sent, action);
    } else if (action['value'] == 'Move to In Progress') {
      this.pickIncident(incidentGuid, incidentTitle, status_to_be_sent, action);
    } else if (action['value'] == 'Reopen Incident') {
      this.InviteEditorsForChangeReqOpenModal(incidentGuid, incCreatedBy, incidentTitle);
    }
  }
}
