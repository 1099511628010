import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ModalService } from '@app/core/modal/modal.service';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { take } from 'rxjs/operators';
import { OrganisationService } from '@app/core/organisation.service';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { IncidentService } from '../incident.service';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { ActivatedRoute } from '@angular/router';
import { NgbDate, NgbDateStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-create-incident',
  templateUrl: './create-incident.component.html',
  styleUrls: ['./create-incident.component.scss']
})
export class CreateIncidentComponent implements OnInit {
  addIncidentForm: FormGroup;
  selectedIncidentOption: any;
  selectedSeverityOption: any;
  submitted = false;
  isloading = true;
  categoryList: any;
  success: boolean;
  templateName: any = '';
  guidSubscription: any;
  OrganisationID: string | null;
  templateGuid: any;
  categoryGuid: any;
  severityList: any;
  titleLengthExceeded = false;
  titleCount: any;
  incidentGuid: string | null;
  type: string;
  incidentData: any;
  createDocStorageId: string = 'create_doc_folder_data';
  error = {
    title: '',
    description: ''
  };
  formError: any;
  backend_error: string;
  templateErrorMessage = 'Please select at least one template.';
  store_due_date = '';
  maxDate: NgbDateStruct;

  constructor(
    private backendErrorService: BackendErrorService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private documentsService: DocumentsService,
    private incidentService: IncidentService,
    private orgService: OrganisationService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private route: ActivatedRoute
  ) {
    this.incidentGuid = this.route.snapshot.queryParamMap.get('incidentGuid');
  }
  ngOnInit(): void {
    const currentDate = new Date();
    this.maxDate = {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
      day: currentDate.getDate()
    };
    localStorage.removeItem('create_doc_folder_data');
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
    });
    this.getCategoryList();
    this.getSeverityList();
    this.incidentGuid && this.getIncidentData();
    this.selectedIncidentOption = 'select_incident_opt';
    this.selectedSeverityOption = 'select_severity_opt';

    this.addIncidentForm = this.formBuilder.group({
      title: ['', [Validators.required(), Validators.maxLength(255)]],
      description: ['', [Validators.required(), Validators.maxLength(4096)]],
      category: ['', [Validators.required()]],
      severity: ['', [Validators.required()]],
      reported_by: ['', [Validators.required(), Validators.maxLength(255)]],
      reported_date: ['', [Validators.required(), Validators.DateMustBeEqualOrBeforeToday()]]
    });
  }
  // checkTitleLength(evt: any) {
  //   this.titleCount = evt.target.value;
  //   this.titleLengthExceeded = this.titleCount.length > 254;
  //   console.log(this.titleLengthExceeded);
  // }

  onDateSelection(date: any) {
    if (date) {
      let myDate = new Date(date.year, date.month - 1, date.day + 1);
      this.store_due_date = myDate.toISOString().split('T')[0];
    } else {
      this.store_due_date = this.store_due_date;
    }
  }

  ngAfterViewInit() {
    this.documentsService
      .getTemplateName()
      .subscribe((item: any) => (this.templateName = this.documentsService.getTemplate()));
    this.documentsService
      .getTemplateName()
      .subscribe((item: any) => (this.templateGuid = this.documentsService.getTemplateGuid()));
  }
  categoryOptionsChange(option: any) {
    this.selectedIncidentOption = option;
    for (let i = 0; i < this.categoryList.length; i++) {
      if (this.selectedIncidentOption == this.categoryList[i]['title']) {
        this.categoryGuid = this.categoryList[i]['guid'];
      }
    }
  }
  severityOptionsChange(option: any) {
    this.selectedSeverityOption = option;
    // option.toLowerCase();
  }
  onChangeTitle(evt: any) {
    if (evt.type == 'keyup' && this.backend_error) {
      this.backend_error = '';
    }
  }

  onReset() {
    this.submitted = false;
    this.addIncidentForm.reset();
    this.selectedIncidentOption = '';
    this.selectedSeverityOption = '';
    this.templateName = '';
    this.backend_error = '';
  }
  getIncidentData() {
    this.incidentService.getIncidentData(this.OrganisationID, this.incidentGuid).subscribe((data: any) => {
      this.incidentData = data;
      this.isloading = false;
      this.templateName = this.incidentData['template_title'];
      this.selectedSeverityOption = this.incidentData['severity'];
      if (this.incidentData['reported_date']) {
        this.store_due_date = this.incidentData['reported_date'];
        const due_date_arr = this.incidentData['reported_date'].split('-');
        this.incidentData['reported_date'] = new NgbDate(
          parseInt(due_date_arr[0]),
          parseInt(due_date_arr[1]),
          parseInt(due_date_arr[2])
        );
      }
      this.addIncidentForm.patchValue({ ...this.incidentData });
      this.addIncidentForm.markAsPristine();
    });
  }
  getCategoryList() {
    this.incidentService.getCategoryList(this.OrganisationID).subscribe((data: any) => {
      this.categoryList = data.results;
      this.isloading = false;
    });
  }
  getSeverityList() {
    this.incidentService.getSeverityList(this.OrganisationID).subscribe((data: any) => {
      this.severityList = data;
    });
  }
  SaveIncidentForm() {
    this.submitted = true;
    if (this.addIncidentForm.valid) {
      this.isloading = true;
      const FolderData = Object.assign({}, this.addIncidentForm.value);
      FolderData['category'] = this.categoryGuid;
      FolderData['severity'] = this.selectedSeverityOption.toLowerCase();
      FolderData['template'] = this.templateGuid; // need to discuss
      this.incidentGuid && delete FolderData['template'];
      FolderData['reported_date'] = this.store_due_date;
      this.submit(FolderData);
    } else {
      return;
    }
  }
  submit(FolderData: any): void {
    this.incidentService
      .SubmitIncidentData(this.OrganisationID, this.incidentGuid, FolderData)
      .pipe(
        finalize(() => {
          this.addIncidentForm.markAsPristine();
          this.isloading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.modalService.custom(CustomDialogComponent, {
            title: this.incidentGuid ? 'Incident Edited' : 'Incident Created',
            modalType: this.incidentGuid ? 'Incident Edited' : 'Incident Created',
            incidentGuid: data.guid,
            incidentTitle: data.title
          });
          this.error = {
            title: '',
            description: ''
          };
          this.submitted = false;
          if (this.incidentGuid) return;
          this.addIncidentForm.reset();
          this.templateName = '';
        },
        (error: any) => {
          this.isloading = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
  }

  openSelectTemplateForm() {
    this.SelectTemplateOpenModal();
  }

  SelectTemplateOpenModal(): void {
    let data = {
      title: 'Select Template',
      modalType: 'Select Template',
      template_guid: this.templateGuid,
      storage_id: this.createDocStorageId,
      type: 'incident'
    };
    if (this.incidentGuid) {
      delete data['storage_id'];
      delete data['template_guid'];
    }
    this.modalService
      .custom(CustomDialogComponent, {
        ...data
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
        } else {
          console.log('Cancelled.');
        }
      });
  }
}
