<app-spinner *ngIf="isloading"></app-spinner>
<ng-container *ngIf="!isloading">
  <div class="row">
    <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
      <h2 class="text-cyan m-0" style=" cursor:default" translate>Archive_Documents.Title</h2>
      <div class="col top-header-filters">
        <button mat-icon-button *ngIf="isShowRefresh" (click)="refresh()">
          <mat-icon class="refresh">refresh</mat-icon>
        </button>
        <i class="fa fa-spinner fa-spin refresh" style="margin-right: 11px;" *ngIf="spnning"></i>
        <div class="search-people position-relative">
          <div class="input-group w-330">
            <input
              id="search"
              [(ngModel)]="searchText"
              class="form-control cb-input"
              [placeholder]="placeholderSearch"
              name="search"
              (keyDown.enter)="getArchiveDocuments()"
              type="text"
              #titleInput
            />
          </div>
          <i (click)="getArchiveDocuments()" class="fa fa-search search-icon"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="archiveDocumentData?.results?.length > 0; else no_content">
    <div
      class="col p-0 search-results"
      infiniteScroll
      [scrollWindow]="false"
      (scrolled)="onScroll()"
      [infiniteScrollDistance]="modalScrollDistance"
      [infiniteScrollThrottle]="modalScrollThrottle"
      (scrolledUp)="onScrollUp()"
    >
      <div class="table_wrapper">
        <table class="w-100p bg-white dm-table table table-responsive">
          <thead>
            <tr style="text-align: left ;" style="height: 65px;">
              <th
                style="text-align: left;padding-left: 45px;"
                scope="col"
                class="font-weight-bold text-black-3"
                translate
              >
                Archive_Documents.Table_title
              </th>
              <th scope="col" class="font-weight-bold text-black-3" translate>
                Archive_Documents.Table_type
              </th>
              <th scope="col" class="font-weight-bold text-black-3" translate>
                Archive_Documents.Table_archived_by
              </th>
              <th scope="col" class="font-weight-bold text-black-3" translate>
                Archive_Documents.Table_archived_date
              </th>
              <th scope="col" class="font-weight-bold text-black-3" translate>
                Archive_Documents.Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style="text-align: left;" *ngFor="let archive of archiveDocumentData?.results; let rowIndex = index">
              <td class="css-b8r45" style="text-align: left;padding-left: 45px;">
                <abbr style="text-decoration: none !important;" title="{{ archive['title'] }}">
                  <h4 class="text-black-4 dm-text-1 mt-3">{{ archive['title'] }}</h4>
                </abbr>
              </td>
              <td>
                <h4 class="text-black-3 dm-text-2 mt-3">
                  {{ archive['document_type'] ? archive['document_type'] : '-' }}
                </h4>
              </td>
              <td>
                <h4 class="text-black-9 dm-text-2 mt-3">
                  {{ archive['archived_by']['name'] ? archive['archived_by']['name'] : '-' }}
                </h4>
              </td>

              <td>
                <h4 class="text-black-3 dm-text-2 mt-3">
                  {{ archive['archived_on_timestamp'] | date }}
                </h4>
              </td>
              <td class="unarchive">
                <button
                  class="btn-save w-100"
                  (click)="unarchiveDocument(archive)"
                  [ngStyle]="{ 'background-color': '#4db7c6' }"
                >
                  Unarchive
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #no_content>
  <div class="row scroll-view">
    <div class="col">
      <img class="empty-doc-list-image" src="../../../assets/images/documents/empty.svg" />
      <div class="text-empty-doc-list text-black-1" translate>Archive_Documents.No_documents</div>
    </div>
  </div>
</ng-template>
&nbsp;
<h2 *ngIf="scrollLoader" class="font-weight-bold text-black-3 text-center">
  <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': scrollLoader }" style="font-size:large;"></i> &nbsp; Loading...
</h2>
