import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { finalize } from 'rxjs/operators';
import { SettingsService } from '@app/settings/settings.service';
import { Router } from '@angular/router';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { TranslateService } from '@ngx-translate/core';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { ModalService } from '@app/core/modal/modal.service';
import { take } from 'rxjs/operators';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { UserInterface, UserService } from '@app/core/user.service';

@Component({
  selector: 'app-approve-training-document',
  templateUrl: './approve-training-document.component.html',
  styleUrls: ['./approve-training-document.component.scss']
})
export class ApproveTrainingDocumentComponent implements OnInit {
  ApproveTrainingDocumentForm: FormGroup;
  submitted = false;
  isLoading = false;
  OrganisationID: string | null;
  guidSubscription: any;
  success: boolean;
  error = {
    remarks: '',
    agreement_accepted: ''
  };
  UserSignature: any = null;
  isSignatureLoading = true;
  signature_error: boolean = false;
  agreement_accepted: boolean = true;
  statusData1: any;
  loading = true;
  loginUser: UserInterface;
  userName: string;
  fonts: string[] = ['italic 32px Pacifico', 'italic 32px Yellowtail', 'italic 32px Damion', 'italic 32px Lobster'];

  @Input() formType: string;
  @Input() DocumentGuid: string;
  @Input() TrackingGuid: string;
  @Input() CampaignGuid: string;
  @Input() CampaignName: string;
  backend_error: string;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private documentsService: DocumentsService,
    private settingsService: SettingsService,
    private router: Router,
    private toaster: ToasterService,
    private translate: TranslateService,
    private modalService: ModalService,
    private userservice: UserService
  ) {}

  ngOnInit() {
    this.loginUser = this.userservice.getuserdetails();
    this.userName = this.loginUser.user['first_name'] + ' ' + this.loginUser.user['last_name'];
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getApproveTrainingDocumentForm();
    });

    this.settingsService.getUserSignature(this.OrganisationID).subscribe(
      data => {
        this.UserSignature = data.url;
        this.isSignatureLoading = false;
        this.loading = false;
      },
      error => {
        this.isSignatureLoading = false;
        this.loading = false;
      }
    );
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  getApproveTrainingDocumentForm() {
    this.ApproveTrainingDocumentForm = this.formBuilder.group({
      remarks: ['', Validators.maxLength(4096)],
      agreement_accepted: ['']
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.ApproveTrainingDocumentForm.controls;
  }

  save() {
    this.submitted = true;
    this.isLoading = true;
    if (this.ApproveTrainingDocumentForm.valid && this.UserSignature) {
      const ApproveTrainingDocumentData = this.ApproveTrainingDocumentForm.value;
      ApproveTrainingDocumentData['guid'] = this.TrackingGuid;
      if (ApproveTrainingDocumentData['agreement_accepted']) {
        this.agreement_accepted = true;
        delete ApproveTrainingDocumentData['agreement_accepted'];
        this.submitApproveTrainingDocument(ApproveTrainingDocumentData);
      } else {
        this.isLoading = false;
        this.submitted = false;
        this.agreement_accepted = false;
      }
    } else {
      this.submitted = false;
      this.isLoading = false;
      if (!this.UserSignature) {
        this.signature_error = true;
      } else {
        this.signature_error = false;
      }
      return;
    }
  }

  changeName(statusData1: any) {
    this.documentsService.setStatus(statusData1);
  }

  submitApproveTrainingDocument(ApproveTrainingDocumentData: any): void {
    this.documentsService
      .submitCompleteTrainingData(this.OrganisationID, this.CampaignGuid, ApproveTrainingDocumentData)
      .pipe(
        finalize(() => {
          this.ApproveTrainingDocumentForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.statusData1 = data;
          this.changeName(this.statusData1);
          this.success = true;
          this.error = {
            remarks: '',
            agreement_accepted: ''
          };
          this.submitted = false;
          this.backend_error = '';
          this.activeModal.close(true);
        },
        (error: any) => {
          this.isLoading = false;
          this.submitted = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
  }
  getUserSignature() {
    this.settingsService.getUserSignature(this.OrganisationID).subscribe(
      data => {
        this.UserSignature = data.url;
        this.isSignatureLoading = false;
        this.loading = false;
      },
      error => {
        this.isSignatureLoading = false;
      }
    );
  }
  OpenChangeSignatureModal(): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Upload signature',
        modalType: 'Upload signature'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getUserSignature();
          this.translate.get('organisation_profile.profile_toaster_message.signature_uploaded').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        }
      });
  }
  OpenDrawSignatureModal(): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Draw Signature',
        modalType: 'Draw Signature'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getUserSignature();
          this.translate.get('organisation_profile.profile_toaster_message.signature_uploaded').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        }
      });
  }
  OpenGenerateSignatureModal(user_name: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Generate Signature',
        modalType: 'Generate Signature',
        user_name: user_name,
        google_fonts: this.fonts
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getUserSignature();
          this.translate.get('organisation_profile.profile_toaster_message.signature_uploaded').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        }
      });
  }
}
