<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div>
    <div class="modal-header cb-modal-header">
      <div>
        <h4 class="modal-title font-weight-bold text-black-1" translate>{{ title_tag }}</h4>
        <i
          class="fa fa-times text-black-1"
          style="width: 12px;
                height: 12px;
                right: 1em;
                cursor: pointer;
                position: absolute;
                top: 1.3em;"
          (click)="activeModal.close({ isUpdate: false })"
          ngbAutoFocus
          translate
        ></i>
      </div>
    </div>
    <h3 for="due_date" class="text-black-3 font-weight-bold p-2">{{ docTitle }}</h3>
    <div class="modal-body">
      <div class="row mt-1 mb-3" *ngIf="backend_error">
        <div class="col-12">
          <span class="text-danger backend_error" translate> * {{ backend_error }}</span>
        </div>
      </div>
      <h4 class="modal-title text-black-1 text-center m-auto p-4 font-weight-bold" [innerHtml]="message"></h4>
    </div>
    <form [formGroup]="RevokeReviewerForm" novalidate>
      <div class="row mt-3">
        <div class="col-3">
          <label for="comment" class="text-black-2 p-2" style="font-size: 14px;" translate
            >Revoke_Incident_Reviewer.Comment
          </label>
        </div>
        <div class="input-group-1">
          <textarea
            id="comment"
            class="form-control cb-input"
            [placeholder]="'Comment'"
            name="comment"
            type="text"
            formControlName="comment"
            cols="64"
            rows="5"
            [maxLength]="4097"
          ></textarea>
          <div class=" col-12 error-mssg" *ngIf="(f.comment.touched || f.comment.dirty) && f.comment.errors">
            <label for="name" class="text-danger" *ngIf="f.comment.errors.maxLength" translate>
              Mark_Editing_Done.* Ensure that the comment has no more than 4096 characters.</label
            >
          </div>
        </div>
      </div>
    </form>
    <div class="modal-footer border-0 cb-modal-footer">
      <button class="btn-cancel ml-4 w-100" (click)="activeModal.close({ isUpdate: false })" ngbAutoFocus translate>
        Revoke_Incident_Reviewer.revoke_reviewer.Cancel
      </button>
      <button class="btn-save ml-4 w-100" *ngIf="!isLoading" (click)="save()" translate>
        Revoke_Incident_Reviewer.revoke_reviewer.Submit
      </button>
      <button class="btn-save ml-4 w-100" disabled *ngIf="isLoading">
        <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
      </button>
    </div>
  </div>
</ng-container>
