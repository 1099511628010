<app-spinner *ngIf="loading"></app-spinner>
<div class="bg-white m-3">
  <div class=" document-view">
    <div class="title-text p-3">
      Policy Management
    </div>
    <hr class="mx-0" />
    <ul class="nav nav-tabs px-3" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          (click)="changeActiveDocumentTab('overview')"
          class="nav-link active"
          [ngClass]="{ active: activeDocTab === 'overview' }"
          id="home-tab"
          data-toggle="tab"
          data-target="#home"
          type="button"
          role="tab"
          aria-controls="home"
          aria-selected="true"
        >
          Overview
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          (click)="changeActiveDocumentTab('folders')"
          class="nav-link"
          [ngClass]="{ active: activeDocTab === 'folders' }"
          id="profile-tab"
          data-toggle="tab"
          data-target="#profile"
          type="button"
          role="tab"
          aria-controls="profile"
          aria-selected="false"
        >
          Folders
        </button>
      </li>
    </ul>
    <div class="tab-content " id="myTabContent">
      <div
        *ngIf="activeDocTab == 'overview'"
        class="tab-pane fade"
        [ngClass]="{ 'show active': activeDocTab === 'overview' }"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <ng-container *ngIf="!loading">
          <div class="row" *ngIf="false">
            <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
              <h2 class="text-cyan m-0" style="cursor:default" translate>Document Management</h2>
            </div>
          </div>
        </ng-container>
        <div class="row mt-4 px-3 bg">
          <ng-container *ngFor="let item of tabsData">
            <div
              class="doc-tab document-tab mx-3"
              [class.doc-active-tab]="item.active && checkTabStatus(item)"
              (click)="changeActiveTab(item)"
            >
              <div class="doc-tab-title">
                {{ item.title }}
              </div>
              <div class="doc-tab-number">
                {{ item.total }}
              </div>
            </div>
          </ng-container>
        </div>
        <ul class="nav nav-tabs mt-4 px-3" id="myTab" role="tablist">
          <li class="nav-item" role="document-tab">
            <button
              class="nav-link d-flex"
              [class.active]="selectedDocumentTab == 'attention-required'"
              id="attention-tab"
              data-toggle="tab"
              data-target="#attention"
              type="button"
              role="tab"
              aria-controls="attention"
              aria-selected="true"
              (click)="changeDocumentTab('attention-required')"
            >
              Attention Required
              <div
                *ngIf="notificationCount?.attention_notification_count"
                class="notification-badge text-center rounded mx-2 p-1"
              >
                {{ notificationCount?.attention_notification_count }}
              </div>
            </button>
          </li>
          <li class="nav-item" role="document-tab">
            <button
              class="nav-link d-flex"
              [class.active]="selectedDocumentTab == 'published-document'"
              id="published-tab"
              data-toggle="tab"
              data-target="#published"
              type="button"
              role="tab"
              aria-controls="published"
              aria-selected="false"
              (click)="changeDocumentTab('published-document')"
            >
              Published Documents
              <div
                *ngIf="notificationCount?.published_notification_count"
                class="notification-badge text-center rounded mx-2 p-1"
              >
                {{ notificationCount?.published_notification_count }}
              </div>
            </button>
          </li>
          <li class="nav-item" role="document-tab">
            <button
              class="nav-link"
              [class.active]="selectedDocumentTab == 'archived-document'"
              id="attention-tab"
              data-toggle="tab"
              data-target="#attention"
              type="button"
              role="tab"
              aria-controls="attention"
              aria-selected="true"
              (click)="changeDocumentTab('archived-document')"
            >
              Archived
            </button>
          </li>
          <li class="nav-item" role="document-tab">
            <button
              class="nav-link"
              [class.active]="selectedDocumentTab == 'ready-renewal'"
              id="attention-tab"
              data-toggle="tab"
              data-target="#attention"
              type="button"
              role="tab"
              aria-controls="attention"
              aria-selected="true"
              (click)="changeDocumentTab('ready-renewal')"
            >
              Ready For Renewal
            </button>
          </li>
        </ul>
        <div class="tab-content " id="myTabContent">
          <div class="tab-pane fade show active " id="attention" role="tabpanel" aria-labelledby="attention">
            <div class="d-flex justify-content-between">
              <div class="row status-pill-container  mt-3 mx-3" *ngIf="selectedDocumentTab === 'attention-required'">
                <ng-container *ngFor="let item of documentStatus">
                  <div
                    class="status-pills"
                    [class.pills-active]="item.isSelected"
                    (click)="onStatusChange(item.key, item.value)"
                  >
                    {{ item.value }} ({{ this.statusCount[item.key] }})
                  </div>
                </ng-container>
              </div>
              <div class="row status-pill-container  mt-3 mx-3" *ngIf="selectedDocumentTab === 'published-document'">
                <ng-container *ngFor="let item of publishedStatus">
                  <div
                    class="status-pills"
                    [class.pills-active]="item.isSelected"
                    (click)="onPublishStatusChange(item.key, item.value)"
                  >
                    {{ item.value }}
                  </div>
                </ng-container>
              </div>
              <button
                *ngIf="selectedDocumentTab == 'attention-required' && (checkCreateAccess || createTemplateAccess)"
                class=" btn-doc cb-btn-primary m-0 mr-4 mt-3"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                id="dropdownMenuButton"
              >
                Create<i class="fas fa-sm fa-chevron-down ml-2"></i>
              </button>
              <div
                *ngIf="selectedDocumentTab == 'attention-required' && (checkCreateAccess || createTemplateAccess)"
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdownMenuButton"
              >
                <a *ngIf="checkCreateAccess" class="dropdown-item dropdown-menu-option" (click)="openCreateDocument()"
                  >Create Document</a
                >
                <a
                  *ngIf="createTemplateAccess"
                  class="dropdown-item dropdown-menu-option"
                  (click)="openCreateTemplate()"
                  >Create Template</a
                >
              </div>
              <ng-container *ngIf="selectedDocumentTab == 'published-document' && checkCreateAccess">
                <button
                  class=" btn-doc cb-btn-primary m-0 mr-4 mt-3"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  id="dropdownMenuButton"
                >
                  Import<i class="fas fa-sm fa-chevron-down ml-2"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item dropdown-menu-option" (click)="openNav(true)">Import Document</a>
                  <a class="dropdown-item dropdown-menu-option" (click)="openNav(false)">Import Template</a>
                </div>
              </ng-container>
            </div>

            <div class="row justify-content-between mx-3 mt-3 ">
              <div class="search-people position-relative">
                <div class="input-group">
                  <div class="input-group">
                    <input
                      id="search"
                      placeholder="Search"
                      [(ngModel)]="searchText"
                      class="form-control cb-input-focus search-field cb-input"
                      name="search"
                      type="text"
                      (keyup)="updateSearchText($event)"
                      (keyDown.enter)="searchDocumentByTitle(searchText)"
                      #titleInput
                    />
                  </div>
                  <i (click)="searchDocumentByTitle(titleInput.value)" class="fa fa-search search-icon"></i>
                </div>
              </div>
              <div class="">
                <button
                  *ngIf="filtersSelectedCount"
                  type="button"
                  class="mx-1 btn btn-sm clr-filter"
                  (click)="clearFilters()"
                >
                  Clear filters
                </button>
                <button
                  type="button"
                  class="mx-1 btn btn-sm filter-btns position-relative"
                  #menuTrigger="matMenuTrigger"
                  [matMenuTriggerFor]="filtersPopover"
                  [class.filter-btns-active]="menuTrigger.menuOpen"
                  (menuClosed)="noFilterAction()"
                >
                  Filters<img class="ml-2" src="../../../assets/images/core/filter-funnel.svg" />
                  <span *ngIf="filtersSelectedCount" class="dot position-absolute"></span>
                </button>
                <mat-menu #filtersPopover="matMenu" yPosition="below" class="mat-elevation-z1">
                  <div class="filter-container p-0 " (click)="$event.stopPropagation()">
                    <div class="filter-header w-100p d-flex justify-content-between">
                      <div class="filter-header-title">
                        Filters
                      </div>
                      <div>
                        <mat-icon class="mat-icon-effect" (click)="menuTrigger.closeMenu(); noFilterAction()"
                          >close</mat-icon
                        >
                      </div>
                    </div>
                    <div class="d-flex w-100p filters">
                      <div class="col-5 p-0 filter-option">
                        <div
                          *ngFor="let item of filtersMenu"
                          class="cursor-pointer d-flex justify-content-between dark-custom-text filter-item"
                          [class.active-filter-item]="item.active"
                          (click)="setFilter(item)"
                        >
                          <div>
                            {{ item.title }}
                          </div>
                          <div class="mr-1">
                            <mat-icon>arrow_right</mat-icon>
                          </div>
                        </div>
                      </div>
                      <div class="col-7  pt-3">
                        <div [ngSwitch]="selectedFilter">
                          <div *ngSwitchCase="'Recurrence'">
                            <div class="dark-custom-text mt-2">Recurrence</div>
                            <div class="recurrence-column">
                              <div
                                class="form-check d-flex align-items-center "
                                *ngFor="let item of recurrenceSchedules"
                                [ngStyle]="{
                                  backgroundColor: item['recurrenceSelected'] ? '#E5F7F9' : ''
                                }"
                              >
                                <input
                                  class="form-check-input custom-checkbox"
                                  type="checkbox"
                                  [(ngModel)]="item['recurrenceSelected']"
                                  [value]="item.value"
                                  [id]="item.display_value"
                                />
                                <label
                                  class="form-check-label mt-1 filter-checkbox-option-label"
                                  [for]="item.display_value"
                                  >{{ item.display_value }}
                                </label>
                              </div>
                            </div>
                          </div>

                          <div *ngSwitchCase="'Type'">
                            <div class="dark-custom-text mt-2">Type</div>
                            <div
                              class="form-check mt-2 d-flex align-items-center"
                              *ngFor="let item of document_types"
                              [ngStyle]="{
                                backgroundColor: item['typeSelected'] ? '#E5F7F9' : ''
                              }"
                            >
                              <input
                                class="form-check-input custom-checkbox"
                                type="checkbox"
                                [(ngModel)]="item['typeSelected']"
                                [value]="item.slug"
                                [id]="item.value"
                              />
                              <label class="form-check-label mt-1 filter-checkbox-option-label" [for]="item.value"
                                >{{ item.value }}
                              </label>
                            </div>
                          </div>

                          <div *ngSwitchCase="'Folder'">
                            <div class="dark-custom-text mt-2">Folder</div>
                            <div class="mt-2 search-people position-relative">
                              <div class="input-group">
                                <div class="input-group">
                                  <input
                                    id="search"
                                    [(ngModel)]="folderSearchText"
                                    class="form-control search-field cb-input"
                                    name="search"
                                    type="text"
                                    placeholder="Search"
                                    #editorInput
                                  />
                                </div>
                                <i
                                  (click)="searchDocumentByTitle(editorInput.value)"
                                  class="fa fa-search search-icon"
                                ></i>
                              </div>
                            </div>
                            <div class="w-100p people-column ">
                              <div
                                class="form-check mt-2 d-flex align-items-center"
                                *ngFor="let item of folderList | filter: folderSearchText; let i = index"
                                [ngStyle]="{
                                  backgroundColor: item['folderSelected'] ? '#E5F7F9' : ''
                                }"
                              >
                                <input
                                  class="form-check-input custom-checkbox"
                                  type="checkbox"
                                  [value]="item.guid"
                                  [attr.data-index]="i"
                                  [(ngModel)]="item['folderSelected']"
                                  [id]="item.title"
                                />
                                <label class="form-check-label mt-1 filter-checkbox-option-label" [for]="item.title"
                                  >{{ item.title }}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div *ngSwitchCase="'Editor'">
                            <div class="dark-custom-text mt-2">Editor</div>
                            <div class="mt-2 search-people position-relative">
                              <div class="input-group">
                                <div class="input-group">
                                  <input
                                    id="search"
                                    [(ngModel)]="editorSearchText"
                                    class="form-control search-field cb-input"
                                    name="search"
                                    type="text"
                                    placeholder="Search"
                                    #editorInput
                                  />
                                </div>
                                <i
                                  (click)="searchDocumentByTitle(editorInput.value)"
                                  class="fa fa-search search-icon"
                                ></i>
                              </div>
                            </div>
                            <div class="w-100p people-column ">
                              <ng-container *ngFor="let item of peopleList | filter: editorSearchText; let i = index">
                                <div
                                  class="form-check d-flex align-items-center people-select "
                                  *ngIf="item.roles?.includes('document_editor')"
                                  [ngStyle]="{
                                    backgroundColor: item['editorSelected'] ? '#E5F7F9' : ''
                                  }"
                                >
                                  <div class="d-flex selected-option" (click)="toggleCheckbox(i, 'editor')">
                                    <input
                                      class="form-check-input custom-checkbox"
                                      type="checkbox"
                                      [value]="item.guid"
                                      [attr.data-index]="i"
                                      [(ngModel)]="item['editorSelected']"
                                    />
                                    <div class="d-flex cursor-default">
                                      <span
                                        class="initials"
                                        [style.backgroundColor]="getColor(item.first_name + ' ' + item.last_name)"
                                      >
                                        {{ getInitials(item.first_name + ' ' + item.last_name) }}
                                      </span>
                                      <div class="email-username">
                                        <div class="username">{{ item.first_name }} {{ item.last_name }}</div>
                                        <div class="email">({{ item.email }})</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </div>

                          <div *ngSwitchCase="'Author'">
                            <div class="dark-custom-text mt-2">Author</div>
                            <div class="mt-2 search-people position-relative">
                              <div class="input-group">
                                <div class="input-group">
                                  <input
                                    id="search"
                                    [(ngModel)]="authorSearchText"
                                    class="form-control search-field cb-input"
                                    name="search"
                                    type="text"
                                    placeholder="Search"
                                    #editorInput
                                  />
                                </div>
                                <i
                                  (click)="searchDocumentByTitle(editorInput.value)"
                                  class="fa fa-search search-icon"
                                ></i>
                              </div>
                            </div>
                            <div class="w-100p people-column ">
                              <ng-container *ngFor="let item of peopleList | filter: authorSearchText; let i = index">
                                <div
                                  class="form-check d-flex align-items-center people-select "
                                  *ngIf="item.roles?.includes('document_author')"
                                  [ngStyle]="{
                                    backgroundColor: item['authorSelected'] ? '#E5F7F9' : ''
                                  }"
                                >
                                  <div class="d-flex selected-option" (click)="toggleCheckbox(i, 'author')">
                                    <input
                                      class="form-check-input custom-checkbox"
                                      type="checkbox"
                                      [value]="item.guid"
                                      [attr.data-index]="i"
                                      [(ngModel)]="item['authorSelected']"
                                    />
                                    <div class="d-flex cursor-default">
                                      <span
                                        class="initials"
                                        [style.backgroundColor]="getColor(item.first_name + ' ' + item.last_name)"
                                      >
                                        {{ getInitials(item.first_name + ' ' + item.last_name) }}
                                      </span>
                                      <div class="email-username">
                                        <div class="username">{{ item.first_name }} {{ item.last_name }}</div>
                                        <div class="email">({{ item.email }})</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </div>

                          <div *ngSwitchCase="'Approver'">
                            <div class="dark-custom-text mt-2">Approver</div>
                            <div class="mt-2 search-people position-relative">
                              <div class="input-group">
                                <div class="input-group">
                                  <input
                                    id="search"
                                    [(ngModel)]="approverSearchText"
                                    class="form-control search-field cb-input"
                                    name="search"
                                    type="text"
                                    placeholder="Search"
                                    #editorInput
                                  />
                                </div>
                                <i
                                  (click)="searchDocumentByTitle(editorInput.value)"
                                  class="fa fa-search search-icon"
                                ></i>
                              </div>
                            </div>
                            <div class="w-100p people-column ">
                              <ng-container *ngFor="let item of peopleList | filter: approverSearchText; let i = index">
                                <div
                                  class="form-check d-flex align-items-center people-select "
                                  *ngIf="item.roles?.includes('document_approver')"
                                  [ngStyle]="{
                                    backgroundColor: item['approverSelected'] ? '#E5F7F9' : ''
                                  }"
                                >
                                  <div class="d-flex selected-option" (click)="toggleCheckbox(i, 'approver')">
                                    <input
                                      class="form-check-input custom-checkbox"
                                      type="checkbox"
                                      [value]="item.guid"
                                      [attr.data-index]="i"
                                      [(ngModel)]="item['approverSelected']"
                                    />
                                    <div class="d-flex cursor-default">
                                      <span
                                        class="initials"
                                        [style.backgroundColor]="getColor(item.first_name + ' ' + item.last_name)"
                                      >
                                        {{ getInitials(item.first_name + ' ' + item.last_name) }}
                                      </span>
                                      <div class="email-username">
                                        <div class="username">{{ item.first_name }} {{ item.last_name }}</div>
                                        <div class="email">({{ item.email }})</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class=" w-100p ">
                      <hr style="width: 100%;" class="mb-1 mt-0" />
                      <div class="footer-subtitle">
                        {{ filtersSelectedCount + ' filters selected' }}
                      </div>
                      <hr style="width: 100%;" class="my-1 mb-1 mt-0" />
                      <div class="text-left mt-2 filter-footer">
                        <button (click)="applyCustomFilters(true); menuTrigger.closeMenu()" class="btn apply-btn ">
                          Apply
                        </button>
                        <button
                          (click)="menuTrigger.closeMenu(); clearFilters()"
                          class="btn btn-light clear-filter-btn bg-white border-0"
                        >
                          Clear All
                        </button>
                      </div>
                    </div>
                  </div>
                </mat-menu>

                <button
                  type="button"
                  class=" btn btn-sm filter-btns mr-2"
                  [matMenuTriggerFor]="columnsPopover"
                  #column="matMenuTrigger"
                  [class.filter-btns-active]="column.menuOpen"
                  *ngIf="selectedDocumentTab == 'attention-required'"
                >
                  Columns<i class="fa fa-columns fa-sm mx-2" yPosition="below" aria-hidden="true"></i>
                </button>
                <mat-menu #columnsPopover="matMenu" class="mat-elevation-z1">
                  <div class="popover-container-column p-2">
                    <ul *ngFor="let item of docFilterColOptions">
                      <li class="d-flex align-items-center ">
                        <input
                          type="checkbox"
                          (click)="$event.stopPropagation()"
                          [(ngModel)]="showHideColumns[item.key]"
                          [value]="showHideColumns[item.key]"
                        />
                        <div class="text-label">{{ item.label }}</div>
                      </li>
                    </ul>
                  </div>
                </mat-menu>
              </div>
            </div>
            <div class="d-flex flex-wrap mx-2">
              <ng-container *ngFor="let key of getFilteredKeys().slice(0, 3); let i = index">
                <div [ngSwitch]="key">
                  <div *ngSwitchCase="'recurrence'">
                    <div
                      class="filters-selected-status mt-3 mr-2  d-flex cursor-pointer"
                      (click)="$event.stopPropagation(); toggleMenu('Recurrence')"
                      *ngIf="customFilters?.recurrence?.length"
                    >
                      <div class="selected-filter-name">
                        Recurrence
                      </div>
                      <div class="selected-filter-name border-left-0 rounded-0">
                        {{ customFilters?.recurrence?.length > 1 ? 'includes' : 'is' }}
                      </div>
                      <div class="selected-filter-name d-flex align-items-center border-left-0 rounded-0">
                        <ng-container *ngIf="selectedRecurrence.length < 4; else truncate">
                          {{ selectedRecurrence }}
                        </ng-container>
                        <ng-template #truncate>
                          <ng-container *ngFor="let item of selectedRecurrence.slice(0, 3); let i = index">
                            {{ item }},
                          </ng-container>
                          <ng-container *ngIf="selectedRecurrence.length > 3">
                            <span [matTooltip]="selectedRecurrence" class="option-count"
                              >+{{ selectedRecurrence.length - 3 }}
                            </span>
                          </ng-container>
                        </ng-template>
                      </div>
                      <div class="filter-cross border-left-0 rounded-right">
                        <mat-icon style="font-size: 12px;" (click)="$event.preventDefault(); removeFilter('recurrence')"
                          >close</mat-icon
                        >
                      </div>
                    </div>
                  </div>
                  <div *ngSwitchCase="'parent_folder'">
                    <div
                      class="filters-selected-status mt-3 mr-2 d-flex cursor-pointer"
                      (click)="$event.stopPropagation(); toggleMenu('Folder')"
                      *ngIf="customFilters?.parent_folder?.length"
                    >
                      <div class="selected-filter-name">
                        Folder
                      </div>
                      <div class="selected-filter-name border-left-0 rounded-0">
                        {{ customFilters?.parent_folder?.length > 1 ? 'includes' : 'is' }}
                      </div>
                      <div class="selected-filter-name d-flex align-items-center border-left-0 rounded-0">
                        <ng-container *ngIf="selectedFolder.length < 4; else truncate">
                          {{ selectedFolder }}
                        </ng-container>
                        <ng-template #truncate>
                          <ng-container *ngFor="let item of selectedFolder.slice(0, 3); let i = index">
                            {{ item }},
                          </ng-container>
                          <ng-container *ngIf="selectedFolder.length > 3">
                            <span [matTooltip]="selectedFolder" class="option-count"
                              >+{{ selectedFolder.length - 3 }}
                            </span>
                          </ng-container>
                        </ng-template>
                      </div>
                      <div class="filter-cross border-left-0 rounded-right">
                        <mat-icon style="font-size: 12px;" (click)="removeFilter('folder')">close</mat-icon>
                      </div>
                    </div>
                  </div>
                  <div *ngSwitchCase="'document_types'">
                    <div
                      class="filters-selected-status mt-3 mr-2 d-flex cursor-pointer"
                      (click)="$event.stopPropagation(); toggleMenu('Type')"
                      *ngIf="customFilters?.document_types?.length"
                    >
                      <div class="selected-filter-name">
                        Type
                      </div>
                      <div class="selected-filter-name border-left-0 rounded-0">
                        {{ customFilters?.document_types?.length > 1 ? 'includes' : 'is' }}
                      </div>
                      <div class="selected-filter-name d-flex align-items-center border-left-0 rounded-0">
                        <ng-container *ngIf="selectedType.length < 4; else truncate">
                          {{ selectedType }}
                        </ng-container>
                        <ng-template #truncate>
                          <ng-container *ngFor="let item of selectedType.slice(0, 3); let i = index">
                            {{ item }},
                          </ng-container>
                          <ng-container *ngIf="selectedType.length > 3">
                            <span [matTooltip]="selectedType" class="option-count"
                              >+{{ selectedType.length - 3 }}
                            </span>
                          </ng-container>
                        </ng-template>
                      </div>
                      <div class="filter-cross border-left-0 rounded-right">
                        <mat-icon style="font-size: 12px;" (click)="removeFilter('type')">close</mat-icon>
                      </div>
                    </div>
                  </div>
                  <div *ngSwitchCase="'editor'">
                    <div
                      class="filters-selected-status mt-3 mr-2 d-flex cursor-pointer"
                      (click)="$event.stopPropagation(); toggleMenu('Editor')"
                      *ngIf="customFilters?.editor?.length"
                    >
                      <div class="selected-filter-name">
                        Editor
                      </div>
                      <div class="selected-filter-name border-left-0 rounded-0">
                        {{ customFilters?.editor?.length > 1 ? 'includes' : 'is' }}
                      </div>
                      <div class="selected-filter-name d-flex align-items-center border-left-0 rounded-0">
                        <ng-container *ngIf="selectedEditor.length < 4; else truncate">
                          {{ selectedEditor }}
                        </ng-container>
                        <ng-template #truncate>
                          <ng-container *ngFor="let item of selectedEditor.slice(0, 3); let i = index">
                            {{ item }},
                          </ng-container>
                          <ng-container *ngIf="selectedEditor.length > 3">
                            <span [matTooltip]="selectedEditor" class="option-count"
                              >+{{ selectedEditor.length - 3 }}
                            </span>
                          </ng-container>
                        </ng-template>
                      </div>
                      <div class="filter-cross border-left-0 rounded-right">
                        <mat-icon style="font-size: 12px;" (click)="removeFilter('editor')">close</mat-icon>
                      </div>
                    </div>
                  </div>
                  <div *ngSwitchCase="'author'">
                    <div
                      class="filters-selected-status mt-3 mr-2 d-flex cursor-pointer"
                      (click)="$event.stopPropagation(); toggleMenu('Author')"
                      *ngIf="customFilters?.author?.length"
                    >
                      <div class="selected-filter-name">
                        Author
                      </div>
                      <div class="selected-filter-name border-left-0 rounded-0">
                        {{ customFilters?.author?.length > 1 ? 'includes' : 'is' }}
                      </div>
                      <div class="selected-filter-name d-flex align-items-center border-left-0 rounded-0">
                        <ng-container *ngIf="selectedAuthor.length < 4; else truncate">
                          {{ selectedAuthor }}
                        </ng-container>
                        <ng-template #truncate>
                          <ng-container *ngFor="let item of selectedAuthor.slice(0, 3); let i = index">
                            {{ item }},
                          </ng-container>
                          <ng-container *ngIf="selectedAuthor.length > 3">
                            <span [matTooltip]="selectedAuthor" class="option-count"
                              >+{{ selectedAuthor.length - 3 }}
                            </span>
                          </ng-container>
                        </ng-template>
                      </div>
                      <div class="filter-cross border-left-0 rounded-right">
                        <mat-icon style="font-size: 12px;" (click)="removeFilter('author')">close</mat-icon>
                      </div>
                    </div>
                  </div>
                  <div *ngSwitchCase="'approver'">
                    <div
                      class="filters-selected-status mt-3 mr-2 d-flex cursor-pointer"
                      (click)="$event.stopPropagation(); toggleMenu('Approver')"
                      *ngIf="customFilters?.approver?.length"
                    >
                      <div class="selected-filter-name">
                        Approver
                      </div>
                      <div class="selected-filter-name border-left-0 rounded-0">
                        {{ customFilters?.approver?.length > 1 ? 'includes' : 'is' }}
                      </div>
                      <div class="selected-filter-name d-flex align-items-center border-left-0 rounded-0">
                        <ng-container *ngIf="selectedApprover.length < 4; else truncate">
                          {{ selectedApprover }}
                        </ng-container>
                        <ng-template #truncate>
                          <ng-container *ngFor="let item of selectedApprover.slice(0, 3); let i = index">
                            {{ item }},
                          </ng-container>
                          <ng-container *ngIf="selectedApprover.length > 3">
                            <span [matTooltip]="selectedApprover" class="option-count"
                              >+{{ selectedApprover.length - 3 }}
                            </span>
                          </ng-container>
                        </ng-template>
                      </div>
                      <div class="filter-cross border-left-0 rounded-right">
                        <mat-icon style="font-size: 12px;" (click)="removeFilter('approver')">close</mat-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div
                class="option-count"
                style="width: 21px; height: 21px; padding: 5px; margin-top: 16px;font-size: 10px;"
                *ngIf="getFilteredKeys().length > 3"
              >
                +{{ getFilteredKeys().length - 3 }}
              </div>
            </div>
            <div class="table-outer-container">
              <div class="mt-3 table-container">
                <table class="w-100p table-hover">
                  <thead>
                    <tr>
                      <th scope="col" class="td-title" translate>
                        TITLE
                      </th>
                      <th
                        scope="col"
                        class="td-type"
                        translate
                        *ngIf="
                          showHideColumns.showType ||
                          selectedDocumentTab == 'published-document' ||
                          selectedDocumentTab == 'archived-document' ||
                          selectedDocumentTab == 'ready-renewal'
                        "
                      >
                        TYPE
                      </th>
                      <th
                        scope="col"
                        class="td-status"
                        translate
                        *ngIf="
                          selectedDocumentTab == 'published-document' || selectedDocumentTab == 'archived-document'
                        "
                      >
                        VERSION
                      </th>
                      <th scope="col" class="td-status" translate *ngIf="selectedDocumentTab == 'attention-required'">
                        STATUS
                      </th>
                      <th scope="col" class="td-due-date" translate *ngIf="selectedDocumentTab == 'ready-renewal'">
                        CREATED DATE
                      </th>
                      <th
                        scope="col"
                        class="td-recurrence"
                        translate
                        *ngIf="
                          (showHideColumns.showReccurence && selectedDocumentTab == 'attention-required') ||
                          selectedDocumentTab == 'ready-renewal'
                        "
                      >
                        RECURRENCE
                      </th>
                      <th
                        scope="col"
                        class="td-due-date"
                        translate
                        *ngIf="selectedDocumentTab == 'attention-required' || selectedDocumentTab == 'ready-renewal'"
                      >
                        DUE DATE
                      </th>
                      <th scope="col" class="td-due-date" translate *ngIf="selectedDocumentTab == 'published-document'">
                        PUBLISHED DATE
                      </th>
                      <th
                        scope="col"
                        class="td-folder"
                        translate
                        *ngIf="showHideColumns.showFolder || selectedDocumentTab == 'published-document'"
                      >
                        FOLDER
                      </th>
                      <th scope="col" class="td-author" translate *ngIf="selectedDocumentTab == 'archived-document'">
                        ARCHIVED BY
                      </th>
                      <th scope="col" class="td-due-date" translate *ngIf="selectedDocumentTab == 'archived-document'">
                        ARCHIVED DATE
                      </th>
                      <th scope="col" class="td-due-date" translate *ngIf="selectedDocumentTab == 'ready-renewal'">
                        CREATED BY
                      </th>
                      <th
                        scope="col"
                        class="td-author"
                        translate
                        *ngIf="selectedDocumentTab !== 'archived-document' && selectedDocumentTab !== 'ready-renewal'"
                      >
                        AUTHOR
                      </th>
                      <th
                        scope="col"
                        class="td-author"
                        translate
                        *ngIf="showHideColumns.showEditors && selectedDocumentTab == 'attention-required'"
                      >
                        EDITORS
                      </th>
                      <th
                        scope="col"
                        class="td-author"
                        translate
                        *ngIf="showHideColumns.showApprovers && selectedDocumentTab == 'attention-required'"
                      >
                        APPROVERS
                      </th>
                      <th scope="col" class="td-action" translate></th>
                    </tr>
                  </thead>
                  <tbody class="table-body">
                    <tr class="table-row-at" *ngFor="let attention of AttentionList; let rowIndex = index">
                      <td
                        [ngClass]="{
                          'font-weight-bold': attention['is_attention_required'],
                          'font-weight-light': !attention['is_attention_required'],
                          'cursor-pointer': selectedDocumentTab !== 'archived-document'
                        }"
                        [ngStyle]="{ 'pointer-events': selectedDocumentTab !== 'archived-document' ? 'auto' : 'none' }"
                        class="custom-text text-truncate td-title"
                        data-toggle="tooltip"
                        data-placement="top"
                        (click)="
                          getClickEvents(
                            'View Document Details',
                            attention['guid'],
                            attention?.created_by?.email,
                            attention['title'],
                            attention['version'],
                            attention['document_store_identifier'],
                            attention?.created_by?.name,
                            attention['in_progress']
                              ? attention['in_progress']
                              : attention.document?.document_version_guid
                              ? attention['document']['document_version_guid']
                              : attention['document_version'],
                            rowIndex,
                            attention
                          )
                        "
                        [title]="attention['title']"
                      >
                        {{ attention['title'] }}
                      </td>
                      <td
                        class="td-type"
                        *ngIf="
                          showHideColumns.showType ||
                          selectedDocumentTab == 'published-document' ||
                          selectedDocumentTab == 'archived-document' ||
                          selectedDocumentTab == 'ready-renewal'
                        "
                      >
                        {{ attention['document_type'] ? attention['document_type'] : attention['document'].type }}
                      </td>
                      <td class="td-status" *ngIf="selectedDocumentTab == 'attention-required'">
                        {{ attention['status']?.value }}
                      </td>
                      <td
                        class="td-status"
                        *ngIf="
                          selectedDocumentTab == 'published-document' || selectedDocumentTab == 'archived-document'
                        "
                      >
                        {{ attention['version'] }}
                      </td>
                      <td *ngIf="selectedDocumentTab == 'ready-renewal'">
                        {{ attention['creation_date'] ? (attention['creation_date'] | date: 'dd-MM-YY') : '' }}
                      </td>
                      <td
                        class="td-recurrence"
                        *ngIf="showHideColumns.showReccurence && selectedDocumentTab == 'attention-required'"
                      >
                        {{ attention.recurrence ? attention.recurrence : '-' }}
                      </td>
                      <td *ngIf="selectedDocumentTab == 'ready-renewal'">
                        {{ attention?.renewal_type }}
                      </td>
                      <td
                        class="td-due-date"
                        *ngIf="selectedDocumentTab == 'attention-required' || selectedDocumentTab == 'ready-renewal'"
                      >
                        {{ attention['due_date'] ? (attention['due_date'] | date: 'dd-MM-YY') : '-' }}
                      </td>
                      <td class="td-due-date" *ngIf="selectedDocumentTab == 'published-document'">
                        {{
                          attention['published_on_timestamp']
                            ? (attention['published_on_timestamp'] | date: 'dd-MM-YY')
                            : '-'
                        }}
                      </td>
                      <td
                        [title]="attention['parent_folder']"
                        class="td-folder text-truncate"
                        *ngIf="showHideColumns.showFolder || selectedDocumentTab == 'published-document'"
                      >
                        {{ attention['parent_folder'] }}
                      </td>
                      <td
                        class=" td-author"
                        *ngIf="selectedDocumentTab !== 'archived-document' && selectedDocumentTab !== 'ready-renewal'"
                      >
                        <div class="d-flex align-items-center">
                          <div class="initials-wrapper mr-1">
                            <div class="initials" [style.backgroundColor]="getColor(attention['created_by']?.name)">
                              {{ getInitials(attention['created_by']?.name) }}
                            </div>
                          </div>
                          {{ attention['created_by']?.name }}
                        </div>
                      </td>
                      <td class=" " *ngIf="selectedDocumentTab == 'archived-document'">
                        <div class="d-flex align-items-center">
                          <div class="initials-wrapper mr-1">
                            <div class="initials" [style.backgroundColor]="getColor(attention['archived_by']?.name)">
                              {{ getInitials(attention['archived_by']?.name) }}
                            </div>
                          </div>
                          {{ attention['archived_by']?.name }}
                        </div>
                      </td>
                      <td class="td-due-date" *ngIf="selectedDocumentTab == 'archived-document'">
                        {{ attention['archived_on_timestamp'] | date: 'dd-MM-YY' }}
                      </td>
                      <td
                        class=" td-author"
                        *ngIf="showHideColumns.showEditors && selectedDocumentTab == 'attention-required'"
                      >
                        <div class="d-flex align-items-center" *ngFor="let item of attention['editors']?.slice(0, 1)">
                          <div class="initials-wrapper mr-1">
                            <div class="initials" [style.backgroundColor]="getColor(item)">
                              {{ getInitials(item) }}
                            </div>
                          </div>
                          {{ item }}
                          <ng-container *ngIf="attention['editors']?.length > 1">
                            ...<span
                              [matTooltip]="attention['editors']"
                              class="badge badge-pill badge-primary badge-color"
                              >{{ attention['editors']?.length - 1 }}</span
                            >
                          </ng-container>
                        </div>
                        <ng-container *ngIf="!attention['editors']?.length">
                          <div class="d-flex align-items-center">-</div>
                        </ng-container>
                      </td>
                      <td
                        class=" td-author"
                        *ngIf="showHideColumns.showApprovers && selectedDocumentTab == 'attention-required'"
                      >
                        <div class="d-flex align-items-center" *ngFor="let item of attention['approvers']?.slice(0, 1)">
                          <div class="initials-wrapper mr-1">
                            <div class="initials" [style.backgroundColor]="getColor(item)">
                              {{ getInitials(item) }}
                            </div>
                          </div>
                          {{ item }}
                          <ng-container *ngIf="attention['approvers'].length > 1">
                            ...<span
                              [matTooltip]="attention['approvers']"
                              class="badge badge-pill badge-primary badge-color"
                              >{{ attention['approvers'].length - 1 }}</span
                            >
                          </ng-container>
                          <ng-container *ngIf="!attention['approvers']?.length">
                            <div class="d-flex align-items-center">-</div>
                          </ng-container>
                        </div>
                      </td>
                      <td class=" td-author" *ngIf="selectedDocumentTab == 'ready-renewal'">
                        <div class="d-flex align-items-center">
                          <div class="initials-wrapper mr-1">
                            <div class="initials" [style.backgroundColor]="getColor(attention['author'])">
                              {{ getInitials(attention['author']) }}
                            </div>
                          </div>
                          {{ attention['author'] }}
                        </div>
                      </td>
                      <td
                        class="td-action align-items-center d-flex justify-content-end"
                        *ngIf="selectedDocumentTab !== 'archived-document'"
                      >
                        <button class="action-btn rounded d-flex mr-3" [matMenuTriggerFor]="menu">
                          <mat-icon style="font-size: 13px;" class="mt-1">more_vert</mat-icon>
                        </button>

                        <mat-menu xPosition="before" #menu="matMenu" class="fixed-menu-panel">
                          <ng-container
                            *ngFor="
                              let actions of attention['allowed_actions_list']
                                ? attention['allowed_actions_list']
                                : attention['actions']
                            "
                          >
                            <button
                              *ngIf="actions !== 'View Document Details'"
                              mat-menu-item
                              (click)="
                                getClickEvents(
                                  actions,
                                  attention['guid'],
                                  attention?.created_by?.email,
                                  attention['title'],
                                  attention['version'],
                                  attention['document_store_identifier'],
                                  attention?.created_by?.name,
                                  attention['in_progress']
                                    ? attention['in_progress']
                                    : attention.document?.document_version_guid
                                    ? attention['document']['document_version_guid']
                                    : attention['document_version'],
                                  rowIndex,
                                  attention
                                )
                              "
                            >
                              {{ actions }}
                              <mat-icon *ngIf="actions == 'Edit Document' || actions == 'View Document'"
                                >launch</mat-icon
                              >
                            </button>
                          </ng-container>
                          <ng-container *ngIf="selectedDocumentTab === 'published-document'">
                            <button
                              *ngIf="attention['document_type'] === 'document' || attention['document_type'] === 'pdf'"
                              mat-menu-item
                              (click)="
                                getPublishedClickEvents(
                                  attention['export_status'],
                                  attention['guid'],
                                  attention['version'],
                                  attention['title'],
                                  attention
                                )
                              "
                            >
                              <ng-container [ngSwitch]="attention['export_status']">
                                {{ exportStatusMapper[attention['export_status']] }}
                                <mat-icon *ngSwitchCase="'INPROGRESS'">access_time</mat-icon>
                                <mat-icon *ngSwitchCase="'COMPLETED'">cloud_download</mat-icon>
                                <mat-icon *ngSwitchCase="'PENDING'"> import_export</mat-icon>
                                <mat-icon *ngSwitchCase="'FAILED'"> import_export</mat-icon>
                              </ng-container>
                            </button>
                          </ng-container>
                        </mat-menu>
                      </td>
                      <td class="text-center" *ngIf="selectedDocumentTab == 'archived-document'">
                        <button class="btn btn-sm unarch-btn" (click)="unarchiveDocument(attention)">
                          Unarchive
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <app-common-pagination
                [totalLength]="totalLength"
                [pageSize]="pageSize"
                [page]="page"
                [pageSizes]="pageSizes"
                (pageSizeChange)="changePageSize($event)"
                (pageChange)="pageChange($event)"
                (goToPageChange)="goToPageChange($event)"
              ></app-common-pagination>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="activeDocTab == 'folders'"
        class="tab-pane fade"
        [ngClass]="{ 'show active': activeDocTab === 'folders' }"
        id="profile"
        role="tabpanel"
        aria-labelledby="profile-tab"
      >
        <app-folders></app-folders>
      </div>
    </div>
  </div>
</div>
