<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div>
    <div class="modal-header cb-modal-header">
      <div>
        <h4 class="modal-title font-weight-bold text-black-1" translate>Start_Training.Title</h4>
        <i
          class="fa fa-times text-black-1"
          style="width: 12px;
                  height: 12px;
                  right: 1em;
                  cursor: pointer;
                  position: absolute;
                  top: 1.3em;"
          (click)="activeModal.close(false)"
          ngbAutoFocus
          translate
        ></i>
      </div>
    </div>
    <div class="modal-body">
      <div class="row mt-1 mb-3" *ngIf="backend_error">
        <div class="col-12">
          <span class="text-danger backend_error" translate> * {{ backend_error }}</span>
        </div>
      </div>
      <h4 class="modal-title text-black-1 text-center m-auto p-4" [innerHtml]="message"></h4>
    </div>
    <div class="modal-footer border-0 cb-modal-footer">
      <button class="btn-cancel ml-4 w-100" (click)="activeModal.close(false)" ngbAutoFocus translate>
        Start_Training.Cancel
      </button>
      <button class="btn-save ml-4 w-100" *ngIf="!isLoading" (click)="save()" translate>
        Start_Training.Start
      </button>
      <button class="btn-save ml-4 w-100" disabled *ngIf="isLoading">
        <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
      </button>
    </div>
  </div>
</ng-container>
