import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { ToasterService } from '@app/shared/toaster/toastr.service';

@Component({
  selector: 'app-remove-approver-editor',
  templateUrl: './remove-approver-editor.component.html',
  styleUrls: ['./remove-approver-editor.component.scss']
})
export class RemoveApproverEditorComponent implements OnInit {
  RevokeEditForm: FormGroup;
  submitted = false;
  isLoading = false;
  OrganisationID: string | null;
  guidSubscription: any;
  success: boolean;
  error: any;
  loading = true;

  @Input() formType: string;
  @Input() guid: string;
  @Input() title_tag: string;
  @Input() message: string;
  @Input() DocumentGuid: string;
  @Input() docTitle: string;
  backend_error: any;
  statusData: any;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private documentsService: DocumentsService,
    private toaster: ToasterService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getRevokeEditForm();
    });
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }
  getRevokeEditForm() {
    this.RevokeEditForm = this.formBuilder.group({
      comment: ['', Validators.maxLength(4096)]
    });
  }
  get f() {
    return this.RevokeEditForm.controls;
  }

  save() {
    this.submitted = true;
    this.isLoading = true;
    // const data = { guid: this.guid };

    if (this.RevokeEditForm.valid) {
      const RevokeData = this.RevokeEditForm.value;
      RevokeData['guid'] = this.guid;
      this.submitRevokeData(RevokeData);
    } else {
      this.submitted = false;
      this.isLoading = false;
      return;
    }
  }

  changeName(statusData: any) {
    this.documentsService.setStatus8(statusData);
  }

  submitRevokeData(RevokeData: any): void {
    this.documentsService
      .submitRevokeEditorApproverData(this.OrganisationID, this.DocumentGuid, RevokeData)
      .pipe(
        finalize(() => {
          this.RevokeEditForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.error = {
            comment: ''
          };
          this.statusData = data;
          this.changeName(this.statusData);
          this.submitted = false;
          this.backend_error = '';
          this.activeModal.close(true);
        },
        (error: any) => {
          this.activeModal.close(false);
          this.submitted = false;
          this.isLoading = false;
          this.loading = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
          this.toaster.showError(this.backend_error);
        }
      );
  }
}
