<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div class="row">
    <h2 class="text-cyan mt-4 mx-3" translate>{{ sharedDataTitle }}</h2>
    <div class="col setting-actions justify-content-end d-flex h-70 align-items-center">
      <div class="search-people position-relative">
        <div class="input-group w-330">
          <input
            id="search"
            [(ngModel)]="searchText"
            class="form-control cb-input"
            placeholder="{{ this.placeholderSearch }}"
            name="search"
            type="text"
            (keyup)="updateSearchText($event)"
            (keyDown.enter)="Search(searchText)"
            #titleInput
          />
        </div>
        <i (click)="Search(titleInput.value)" class="fa fa-search search-icon"></i>
      </div>
      <div *ngIf="sharedDataTitle?.toLowerCase() === 'templates'">
        <select class="cb-input w-140 ml-3" [(ngModel)]="templateFilter" (change)="changeTemplateFilter()">
          <option translate value="" selected>All</option>
          <option
            *ngFor="let filter of templateFilterOptions; let i = index"
            [attr.data-index]="i"
            value="{{ filter.value }}"
          >
            {{ filter.title }}
          </option>
        </select>
      </div>
      <div *ngIf="sharedDataTitle?.toLowerCase() === 'forms' || sharedDataTitle?.toLowerCase() === 'incidents'">
        <select class="cb-input w-140 ml-3" [(ngModel)]="yearFilter" (change)="changeMonthYearFilter()">
          <option translate value="" selected>All Years</option>
          <option *ngFor="let filter of yearFilterFinal; let i = index" [attr.data-index]="i" value="{{ filter }}">
            {{ filter }}
          </option>
        </select>
      </div>
      <div *ngIf="sharedDataTitle?.toLowerCase() === 'forms' || sharedDataTitle?.toLowerCase() === 'incidents'">
        <select class="cb-input w-140 ml-3" [(ngModel)]="monthFilter" (change)="changeMonthYearFilter()">
          <option translate value="" selected>All Months</option>
          <option
            *ngFor="let filter of monthFilterOptions; let i = index"
            [attr.data-index]="i"
            value="{{ filter.value }}"
          >
            {{ filter.title }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="folderDocList?.length > 0; else no_content">
    <div
      class="col p-0 search-results"
      infiniteScroll
      [scrollWindow]="false"
      (scrolled)="onScroll()"
      [infiniteScrollDistance]="modalScrollDistance"
      [infiniteScrollThrottle]="modalScrollThrottle"
      (scrolledUp)="onScrollUp()"
    >
      <div class="table_wrapper">
        <table class="w-100p bg-white dm-table table table-responsive">
          <mat-toolbar
            color="primary"
            style="height: 67px; font-size: 16px; font-weight: bold; color: #E6E8EA; border-bottom: 1px solid;overflow-x: auto;white-space: initial;
                        word-wrap: break-word; margin-left: 4px;"
            class="container"
            *ngIf="showBreadCrumb"
          >
            <span style="border-bottom: 1px black;"
              ><a
                mat-button
                (click)="breadCrumbMain()"
                style="color: white;"
                scope="col-8"
                class=" text-black-3 text-left dm-text-2"
                ><span class="text-black-3 dm-text-2 text-left" style="font-size: 14px;">{{ sharedDataTitle }}</span></a
              >
            </span>

            <span
              *ngFor="let m of menuHeader; let indx = index"
              style="color: white;white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;"
            >
              <a mat-button (click)="breadCrumb(menuHeader, indx)">
                <mat-icon style="margin-left: -1em;">keyboard_arrow_right</mat-icon
                ><mat-icon style="margin-left: 6px;">folder_open</mat-icon
                ><span class="text-black-3 dm-text-2 text-left" style="margin-left: 10px;font-size: 14px;">{{
                  m.label
                }}</span>
              </a>
            </span>
          </mat-toolbar>
          <thead>
            <tr style="height: 65px; ">
              <th
                scope="col"
                class="font-weight-bold text-black-3 text-left"
                style="vertical-align: inherit;padding-left: 6em;"
                translate
              >
                folder-document-view.folder-table-name.title
              </th>
              <th
                scope="col"
                class="font-weight-bold text-black-3 text-left"
                style="vertical-align: inherit;padding-left: 0em;"
                translate
              >
                folder-document-view.folder-table-name.type
              </th>
              <th
                scope="col"
                class="font-weight-bold text-black-3 text-left"
                style="vertical-align: inherit;padding-left: 0em;"
                translate
              >
                folder-document-view.folder-table-name.author
              </th>
              <th
                *ngIf="sharedDataTitle.toLowerCase() === 'templates'"
                scope="col"
                class="font-weight-bold text-black-3 text-left"
                style="vertical-align: inherit;padding-left: 0em;"
                translate
              >
                Template Type
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-left" *ngFor="let menu of folderDocList; let rowIndex = index">
              <td style="vertical-align: inherit;padding-left: 0em; line-break: anywhere;">
                <h4 class="dm-text-1 text-black-3 text-left" style="padding-left: 5em;">
                  <mat-list role="list">
                    <mat-list-item (click)="menuChange(menu, menu.guid)" role="listitem">
                      <mat-icon *ngIf="menu.type != 'folder'"> description </mat-icon>
                      <mat-icon *ngIf="menu.type == 'folder'"> folder_open </mat-icon>
                      <span class=" text-black-1 mr-2  ml-1 text-left" style="font-size: 14px; font-weight: bold; ">
                        <a
                          class="text-black-1 mr-2 ml-1 text-left"
                          *ngIf="menu.type !== 'folder'"
                          style="font-size: 14px; font-weight: bold;"
                          [routerLink]="
                            sharedDataTitle.toLowerCase() === 'incidents'
                              ? ['/organisation/incident/incident-details', menu.workspace_template_guid]
                              : sharedDataTitle.toLowerCase() === 'forms'
                              ? ['/organisation/form/form-details', menu.workspace_template_guid]
                              : ['/organisation/documents/detailPage', menu.guid]
                          "
                        >
                          {{ menu.title }}
                        </a>
                        <span
                          class=" text-black-1 mr-2  ml-1 text-left"
                          *ngIf="menu.type == 'folder'"
                          style="font-size: 14px; font-weight: bold;cursor: pointer; "
                          >{{ menu.title }}</span
                        >
                      </span>
                    </mat-list-item>
                  </mat-list>
                </h4>
              </td>
              <td
                style="padding: 0rem;
                        vertical-align: inherit;padding-left: 10px;"
              >
                <h4 class="dm-text-1 text-black-1 mt-3 text-left">
                  <span class="text-black-3 dm-text-2 mt-3">{{ menu.type }}</span>
                </h4>
              </td>
              <td
                style="padding: 0rem;
                        vertical-align: inherit;"
              >
                <h4 class="dm-text-1 text-black-3 mt-3 text-left">
                  <span class="text-black-3 dm-text-2 mt-3" *ngIf="!menu.author"> - </span>
                  <span class="text-black-3 dm-text-2 mt-3" *ngIf="menu.author"> {{ menu.author }}</span>
                </h4>
              </td>
              <td
                style="padding: 0rem; vertical-align: inherit;"
                *ngIf="sharedDataTitle?.toLowerCase() === 'templates'"
              >
                {{ menu?.template_type }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #no_content>
  <mat-toolbar
    color="primary"
    style="height: 67px; font-size: 16px; font-weight: bold; color: #E6E8EA; border-bottom: 1px solid;overflow-x: auto;white-space: initial;
              word-wrap: break-word; margin-left: 4px;"
    class="container"
    *ngIf="showBreadCrumb"
  >
    <span style="border-bottom: 1px black;"
      ><a
        mat-button
        (click)="breadCrumbMain()"
        style="color: white;"
        scope="col-8"
        class=" text-black-3 text-left dm-text-2"
        ><span class="text-black-3 dm-text-2 text-left" style="font-size: 14px;">{{ sharedDataTitle }}</span></a
      >
    </span>

    <span
      *ngFor="let m of menuHeader; let indx = index"
      style="color: white;white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;"
    >
      <a
        mat-button
        (click)="breadCrumb(menuHeader, indx)"
        [ngClass]="folderDocList == 0 ? 'breadcrumb-css' : 'breadcrumb-css1'"
        title="{{ m.label }}"
      >
        <mat-icon style="margin-left: -1em;">keyboard_arrow_right</mat-icon
        ><mat-icon style="margin-left: 6px;">folder_open</mat-icon
        ><span class="text-black-3 dm-text-2 text-left" style="margin-left: 10px;font-size: 14px;">{{ m.label }} </span>
      </a>
    </span>
  </mat-toolbar>
  <div class="row scroll-view">
    <div class="col">
      <img class="empty-doc-list-image" src="../../../assets/images/documents/empty.svg" />
      <div class="text-empty-doc-list text-black-1" translate>Attention_Required.No_documents</div>
    </div>
  </div>
</ng-template>

&nbsp;
<div *ngIf="scrollLoader" class="w-100p d-flex justify-content-center">
  <h2 class="font-weight-bold  ">
    <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': scrollLoader }" style="font-size:large;"></i> &nbsp; Loading...
  </h2>
</div>
