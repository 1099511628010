import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

export interface Template {
  value: String;
  viewValue: string;
}
export interface Folder {
  value: String;
  viewValue: string;
}

@Component({
  selector: 'app-create-form',
  templateUrl: './create-form.component.html',
  styleUrls: ['./create-form.component.scss']
})
export class CreateFormComponent implements OnInit {
  CreateForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.CreateForm = this.formBuilder.group({
      form_title: ['', Validators.required],
      form_template: ['', Validators.required],
      destination_folder: ['', Validators.required]
    });
  }

  templates: Template[] = [
    { value: 'Template 1', viewValue: 'Template 1' },
    { value: 'Template 2', viewValue: 'Template 2' },
    { value: 'Template 3', viewValue: 'Template 3' }
  ];
  SelectedTemplate = '';

  folders: Folder[] = [
    { value: 'Hr', viewValue: 'Hr' },
    { value: 'Folder 1', viewValue: 'Folder 1' },
    { value: 'Folder 2', viewValue: 'Folder 2' },
    { value: 'Folder 3', viewValue: 'Folder 3' }
  ];
  SelectedFolder = '';
}
