import { Component, OnInit } from '@angular/core';
import { SidenavService } from '@app/core/modal/sidenav.service';
import { ServiceProviderIntegrationComponent } from './service-provider-integration/service-provider-integration.component';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';

@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.scss']
})
export class IntegrationComponent implements OnInit {
  cloudProviders: any;
  search_title: string = '';
  OrganisationID: string | null;
  orgGuidSubscription: any;
  loading: boolean = false;
  activeAccount: any;

  constructor(
    private sidenavService: SidenavService,
    private router: Router,
    private orgService: OrganisationService,
    private documentsService: DocumentsService
  ) {}

  ngOnInit(): void {
    this.orgGuidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
    });
    if (this.OrganisationID) {
      this.getAllCloudProvider();
    }
  }

  onManage(event: any) {
    localStorage.setItem('activeServiceProvider', JSON.stringify(event));
    this.router.navigate(['/organisation/settings/integration/manage-account']);
  }

  onIntegrate(event: any) {
    localStorage.setItem('activeServiceProvider', JSON.stringify(event));
    this.sidenavService.open(ServiceProviderIntegrationComponent).subscribe((result: any) => {
      if (result) {
        if (this.OrganisationID) {
          this.getAllCloudProvider();
        }
      }
    });
  }

  searchByTitle() {
    this.getAllCloudProvider();
  }

  updateSearchText(text: any) {}

  getAllCloudProvider() {
    this.loading = true;
    this.documentsService.getAllCloudProvider(this.OrganisationID, this.search_title).subscribe(res => {
      this.cloudProviders = res;
      this.documentsService.cloudProvidersSubject.next(res);
      this.loading = false;
    });
  }
}
