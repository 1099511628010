import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { AuthenticationService, I18nService } from '@app/core';
import { OrganisationAdminService } from '@app/Organisation-admin/organisation-admin.service';
import { UserService } from '@app/core/user.service';
import { OrganisationService } from '@app/core/organisation.service';

@Component({
  selector: 'app-onboard-footer',
  templateUrl: './onboard-footer.component.html',
  styleUrls: ['./onboard-footer.component.scss']
})
export class OnboardFooterComponent implements OnInit, OnDestroy {
  @Output() public goToNext = new EventEmitter();
  @Output() public goToPrevious = new EventEmitter();
  @Output() public goToFinish = new EventEmitter();
  @Output() public runSelected = new EventEmitter();
  @Input() activeStep = 1;
  isOrganizationOnboarded = false;
  guidSubscription: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private i18nService: I18nService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private orgService: OrganisationService
  ) {}

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.getOrganisationBoarded();
    });
  }

  getOrganisationBoarded() {
    this.isOrganizationOnboarded = this.userService.getuserdetails().user['is_organization_onboarded'];
  }

  next() {
    this.goToNext.emit(true);
  }

  back() {
    this.goToPrevious.emit(true);
  }
  finish() {
    this.goToFinish.emit(true);
  }
  run() {
    this.runSelected.emit(true);
  }
}
