import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@app/shared';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { OrganisationSystemAdminRoutingModule } from './organisation-system-admin-routing.module';
import { LeadComponent } from './Lead-generation/lead.component';
import { LeadService } from './Lead-generation/lead.service';
import { OrganisationSystemAdminService } from '@app/Organisation-system-admin/organisation-system-admin.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DemoMaterialModule } from '../materials.module';
import { GrcDataComponent } from './grc-data/grc-data.component';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    Ng2SearchPipeModule,
    NgMultiSelectDropDownModule.forRoot(),
    ReactiveFormsModule,
    TranslateModule,
    NgbModule,
    OrganisationSystemAdminRoutingModule,
    DemoMaterialModule,
    FormsModule,
    SharedModule,
    DemoMaterialModule
  ],
  declarations: [LeadComponent, GrcDataComponent],
  providers: [LeadService, OrganisationSystemAdminService],
  entryComponents: [],
  exports: [LeadComponent]
})
export class OrganisationSystemAdminModule {}
