import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService, I18nService } from 'app/core/index';
import { UserService } from 'app/core/user.service';

@Component({
  selector: 'app-public-shell-header',
  templateUrl: './publicShellHeader.component.html',
  styleUrls: ['./publicShellHeader.component.scss']
})
export class PublicShellHeaderComponent implements OnInit {
  menuHidden = false;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private i18nService: I18nService
  ) {}

  ngOnInit() {}

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }
}
