import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '../documents.service';
import stringInfo from '../../../../translations/en-US.json';

@Component({
  selector: 'app-folder-document-view',
  templateUrl: './folder-document-view.component.html',
  styleUrls: ['./folder-document-view.component.scss']
})
export class FolderDocumentViewComponent implements OnInit, AfterViewInit {
  [x: string]: any;
  menuHeader: any = [];
  templateFilter = '';
  templateFilterOptions: any = [
    { value: 'incident', title: 'Incident' },
    { value: 'form', title: 'Form' },
    { value: 'general', title: 'General' }
  ];
  monthFilter = '';
  monthFilterOptions: any = [
    { value: '1', title: 'Jan' },
    { value: '2', title: 'Feb' },
    { value: '3', title: 'Mar' },
    { value: '4', title: 'Apr' },
    { value: '5', title: 'May' },
    { value: '6', title: 'Jun' },
    { value: '7', title: 'Jul' },
    { value: '8', title: 'Aug' },
    { value: '9', title: 'Sep' },
    { value: '10', title: 'Oct' },
    { value: '11', title: 'Nov' },
    { value: '12', title: 'Dec' }
  ];
  page = 1;
  public pageSize = 10;
  totalLength: any;
  paginatedData: any;
  currentPage: number = 1;
  pagingNumber = 0;
  loaderArr: any = [];
  accArray: any[] = [];
  search_title: any = '';
  status: any = [];
  documentStatus: [];
  status_loading = false;
  statusFilter: any = '';
  search_text: any;
  update_status: any;
  loading = true;
  @Input() title_tag: string;
  guidSubscription: any;
  OrganisationID: string;
  folder: any;
  error: any;
  folderDocList: any;
  folderId: any;
  folderGuid: string;
  folderTitle: string;
  folderDocList1: any;
  name: string;
  sharedData: any;
  showBreadCrumb: boolean;
  sharedDataTitle: any = '';
  sharedDataGuid: any;
  sharedDataBlank: any;
  workSpaceList: any = [];
  placeholderSearch = stringInfo.Attention_Required.PlaceHolder_Search;
  scrollLoader = false;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  pagnationData: any;
  paginatedDataUrl: any;
  show_add_new_button: boolean = false;
  yearFilterFinal: any;
  workspaceTemplateType: any;

  constructor(
    private router: Router,
    private orgService: OrganisationService,
    private documentsService: DocumentsService,
    private actRoute: ActivatedRoute
  ) {
    this.actRoute.queryParams.subscribe(params => {
      this.sharedDataTitle = params['folder'];
      if (params['folder'].toLowerCase() !== 'templates') {
        this.templateFilter = '';
      }
      if (params['folder'].toLowerCase() !== 'forms' || params['folder'].toLowerCase() !== 'incidents') {
        this.monthFilter = '';
        this.yearFilter = '';
      }
    });
    this.actRoute.params.subscribe(params => {
      this.folderGuid = params['guid'];
      this.sharedDataGuid = params['guid'];
    });
  }

  ngOnInit() {
    this.showBreadCrumb = false;
    this.menuHeader = [];
    this.appitemsTravel = this.folderDocList;
    this.page = 1;
    this.searchText = '';

    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
      this.getFilterYear();
      this.getRole();
      if (this.folder.toLowerCase() == 'templates') {
        this.getTemplateTypeList();
      }
    });
    this.workspaceTemplateType =
      this.sharedDataTitle.toLowerCase() === 'forms'
        ? 'form'
        : this.sharedDataTitle.toLowerCase() === 'incidents'
        ? 'incident'
        : '';
    this.getFolderDocumentViewList();
  }

  getFilterYear() {
    this.documentsService.getFilteredYearList(this.OrganisationID, this.folderGuid).subscribe(data => {
      this.yearFilterFinal = data.years;
    });
  }

  getRole() {
    this.role = JSON.parse(localStorage.getItem('credentials'));
    this.userRole = this.role['user']['role'];
    for (let i = 0; i < 10; i++) {
      if (this.userRole[i] == 'organization_admin_role' || this.userRole[i] == 'document_author') {
        this.key = this.userRole[i];
        this.show_add_new_button = true;
      }
    }
  }
  getTemplateType() {
    this.documentsService.getTemplateTypeList(this.OrganisationID).subscribe(res => {
      this.templateFilterOptions = res;
    });
  }

  onScrollUp() {
    this.scrollLoader = false;
  }

  onScrollData() {
    this.scrollLoader = true;
    if ((this.search_title = '')) {
      this.documentsService
        .getFolderDocumentViewListPagination(
          this.OrganisationID,
          this.folderGuid,
          (this.page += 1),
          (this.search_title = this.search_title),
          this.pageSize,
          this.templateFilter,
          this.monthFilter,
          this.yearFilter,
          this.workspaceTemplateType
        )
        .subscribe(
          data => {
            this.scrollLoader = false;
            this.pagnationData = data.results;
            this.paginatedDataUrl = data;
            this.folderDocList = this.folderDocList.concat(data.results);
          },
          error => {
            this.scrollLoader = false;
            this.error = error.error.message;
          }
        );
    } else {
      this.scrollLoader = true;
      this.documentsService
        .getFolderDocumentViewListPagination(
          this.OrganisationID,
          this.folderGuid,
          (this.page += 1),
          this.search_title,
          this.pageSize,
          this.templateFilter,
          this.monthFilter,
          this.yearFilter,
          this.workspaceTemplateType
        )
        .subscribe(
          data => {
            this.scrollLoader = false;
            this.pagnationData = data.results;
            this.paginatedDataUrl = data;
            this.folderDocList = this.folderDocList.concat(data.results);
          },
          error => {
            this.scrollLoader = false;
            this.error = error.error.message;
          }
        );
    }
  }
  onScroll() {
    if (this.paginatedDataUrl?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }

  ngAfterViewInit() {
    this.sharedDataBlank = this.documentsService.sharedData$.subscribe(sharedData => {
      this.page = 1;
      this.loading = false;
      this.sharedData = sharedData.workspace;
      this.sharedDataGuid = sharedData.workspace.guid;
      this.folderDocList = sharedData.treeApiList.results;
      this.totalLength = sharedData.treeApiList.count;
      this.searchText = '';
    });
  }

  goToCreateDoc() {
    this.router.navigate(['organisation/documents/create-document']);
  }

  updateSearchText(x: any) {
    this.search_text = x.target.value;
    if (this.search_title == undefined) {
      this.search_text = '';
    }
  }

  getFolderDocumentViewList() {
    console.log('this.workspaceTemplateType', this.workspaceTemplateType);
    this.documentsService
      .getFolderDocumentViewList(this.OrganisationID, this.folderGuid, this.workspaceTemplateType)
      .subscribe(
        data => {
          this.treeApiList = data;
          if (this.treeApiList != 0) {
            this.documentsService.setworkspaceData(this.treeApiList);
          }
          this.folderDocList = this.documentsService.getworkspaceData();
          this.paginatedDataUrl = this.folderDocList;
          this.folderDocList = this.folderDocList.results;
          this.totalLength = data.count;
        },
        error => {
          this.error = error.error.message;
          this.loading = false;
          console.log('Error:', error.error.message);
        }
      );
  }

  Search(search_title: string) {
    this.search_title = search_title;
    for (let i = 0; i < this.folderDocList.length; i++) {
      if (this.folderDocList[i]['title'] != this.search_title) {
        this.page = 1;
      }
    }
    if (this.statusFilter === undefined) {
      this.statusFilter = '';
    }
    this.page = 1;
    this.documentsService
      .getSearchList(
        this.OrganisationID,
        this.folderGuid,
        this.search_title,
        this.page,
        this.templateFilter,
        this.monthFilter,
        this.yearFilter,
        this.workspaceTemplateType
      )
      .subscribe(
        data => {
          this.totalLength = data.count;
          this.folderDocList = data.results;
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
  }

  breadCrumbMain() {
    this.documentsService.getFolderDocumentViewList(this.OrganisationID, this.folderGuid).subscribe(
      data => {
        this.loading = false;
        this.showBreadCrumb = false;
        this.folderDocList = data.results;
        this.totalLength = data.count;
      },
      error => {
        this.error = error.error.message;
        this.loading = false;
        console.log('Error:', error.error.message);
      }
    );
    this.appitemsTravel = this.folderDocList;
    this.menuHeader = [];
  }

  appitemsTravel: any;

  breadCrumb(menu: any, index: any) {
    this.menuHeader.splice(index + 1, this.menuHeader.length - 1);
    if (menu[index] && menu[index].items && menu[index].items.length) {
      this.appitemsTravel = menu[index].items;
    }
    this.getSubfolderList(this.appitemsTravel);
  }

  navigateTodetailPage() {
    this.router.navigate(['/organisation/documents/vendors/detailPage']);
  }

  menuChange(menuChange: any, guid: any) {
    this.searchText = '';
    if (menuChange.type == 'folder') {
      this.menuHeader.push({
        label: menuChange.title,
        items: menuChange.guid
      });
      this.getSubfolderList(guid);
      this.showBreadCrumb = true;
    }
  }
  getSubfolderList(subfolderGuid: any) {
    this.folderGuid = subfolderGuid;
    this.documentsService.getFolderDocumentViewList(this.OrganisationID, this.folderGuid).subscribe(
      data => {
        this.loading = false;
        this.folderDocList = data.results;
        this.totalLength = data.count;
      },
      error => {
        this.error = error.error.message;
        this.loading = false;
        console.log('Error:', error.error.message);
      }
    );
  }
  changeTemplateFilter() {
    this.page = 1;
    this.documentsService
      .getSearchList(this.OrganisationID, this.folderGuid, this.search_title, this.page, this.templateFilter)
      .subscribe(
        data => {
          this.totalLength = data.count;
          this.folderDocList = data.results;
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
  }
  changeMonthYearFilter() {
    this.page = 1;
    this.documentsService
      .getSearchList(
        this.OrganisationID,
        this.folderGuid,
        this.search_title,
        this.page,
        this.templateFilter,
        this.monthFilter,
        this.yearFilter,
        this.workspaceTemplateType
      )
      .subscribe(
        data => {
          this.totalLength = data.count;
          this.folderDocList = data.results;
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
  }
}
