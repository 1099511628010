<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div>
    <div class="modal-header cb-modal-header">
      <div>
        <h4 class="modal-title font-weight-bold text-black-1" translate>change_request_for_incident.pop_up_title</h4>
        <i
          class="fa fa-times text-black-1"
          style="width: 12px;
            height: 12px;
            right: 1em;
            cursor: pointer;
            position: absolute;
            top: 1.3em;"
          (click)="activeModal.close({ isUpdate: false })"
          ngbAutoFocus
          translate
        ></i>
      </div>
    </div>

    <div class="modal-body">
      <div class="row mt-1 mb-3" *ngIf="backend_error">
        <div class="col-12">
          <span class="text-danger backend_error" translate> * {{ backend_error }}</span>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-3">
          <label class="text-black-4 p-2" translate>change_request_for_incident.title</label>
        </div>
        <div class="col-9">
          <div class="input-group">
            <input class="form-control cb-input" value="{{ incidentTitle }}" disabled />
          </div>
        </div>
      </div>

      <form [formGroup]="InviteEditorForm" novalidate>
        <div class="row mt-2">
          <div class="col-3">
            <label class="text-black-2 p-2" translate>change_request_for_incident.Add_Old_Editor </label>
          </div>
          <div class="col-12">
            <ng-multiselect-dropdown
              class="multi-select-dropdown"
              [placeholder]="'Select old reviewers'"
              [data]="OldUsersList"
              [(ngModel)]="selectedOldEditor"
              [settings]="dropdownSettingsForOldUsers"
              (onSelect)="onOldEditorSelect($event)"
              (onDeSelect)="onOldEditorDeSelect($event)"
              [ngModelOptions]="{ standalone: true }"
            >
            </ng-multiselect-dropdown>
          </div>
        </div>
        <div class="row mt-6">
          <div class="col-3">
            <label class="text-black-2 p-2" translate>change_request_for_incident.Select_New_Editors </label>
          </div>
          <div class="col-12">
            <ng-multiselect-dropdown
              class="multi-select-dropdown"
              [placeholder]="'Select new reviewers'"
              Change
              Due
              Date
              for
              invite
              approver
              [data]="UsersList"
              [(ngModel)]="selectedItems"
              [settings]="dropdownSettingsForNewUsers"
              (onSelect)="onItemSelect($event)"
              (onDeSelect)="onItemDeSelect($event)"
              [ngModelOptions]="{ standalone: true }"
            >
            </ng-multiselect-dropdown>

            <div class="col-17 error-mssg" *ngIf="!EditorSelected && this.selectedOldEditor.length == 0">
              <label for="editor" class="text-danger1" translate
                >Document_Invite_Editor.invite_editor.*Atleast one reviewer is required.</label
              >
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-3">
            <label for="due_date" class="text-black-1 p-2" translate>change_request_for_incident.Due_Date </label>
          </div>
          <div class="col-9">
            <div class="input-group">
              <input
                class="form-control cb-input"
                placeholder="{{ this.date }}"
                formControlName="due_date"
                name="due_date"
                ngbDatepicker
                #d="ngbDatepicker"
                (click)="d.toggle()"
                (dateSelect)="onDateSelection($event)"
                required
                [minDate]="minDate"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary fa fa-calendar-alt cb-input"
                  (click)="d.toggle()"
                  type="button"
                ></button>
              </div>
              <div class="col-12 error-mssg" *ngIf="(f.due_date.touched || submitted) && f.due_date.errors">
                <label for="due_date" class="text-danger" *ngIf="f.due_date.errors.required" translate>
                  Document_Invite_Editor.invite_editor.*Due Date is required.</label
                >
                <label for="due_date" class="text-danger" *ngIf="f.due_date.errors.DateTodayOrAfter_error" translate>
                  Document_Invite_Editor.invite_editor.*Due Date must be greater than today.</label
                >
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-3">
            <label for="comment" class="text-black-1 p-2" translate>change_request_for_incident.Comments </label>
          </div>
          <div class="col-9">
            <div class="input-group">
              <textarea
                id="comment"
                class="form-control cb-input"
                [placeholder]="'Comment'"
                name="comment"
                type="text"
                formControlName="comment"
                cols="64"
                rows="5"
                [maxLength]="4097"
              ></textarea>
              <div class=" col-12 error-mssg" *ngIf="(f.comment.touched || f.comment.dirty) && f.comment.errors">
                <label
                  for="name"
                  style="margin-left: -15px;"
                  class="text-danger"
                  *ngIf="f.comment.errors.maxLength"
                  translate
                >
                  Mark_Editing_Done.* Ensure that the comment has no more than 4096 characters.</label
                >
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <button class="btn-save ml-4 w-100 mt-3 mb-3 float-right" *ngIf="!isLoading" (click)="save()" translate>
      change_request_for_incident.Send_Invite
    </button>
    <button class="btn-save ml-4 w-100 mt-3 mb-3 float-right" disabled *ngIf="isLoading">
      <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
    </button>
    <button
      class="btn-cancel ml-4 w-100 mt-3 float-right"
      (click)="activeModal.close({ isUpdate: false })"
      ngbAutoFocus
      translate
    >
      change_request_for_incident.Cancel
    </button>
  </div>
  <div class="editor-modal-footer cb-modal-footer mt-5" *ngIf="OldUsersList?.length > 0">
    <table class="table table-borderless">
      <thead>
        <tr>
          <th scope="col-6">
            <h4 class="modal-title font-weight-bold text-black-1" translate>change_request_for_incident.Reviewers</h4>
          </th>
          <th scope="col-2">
            <h4 class="modal-title font-weight-bold text-black-1" translate>change_request_for_incident.Due_Date</h4>
          </th>
          <th scope="col-2">
            <h4 class="modal-title font-weight-bold text-black-1" translate>change_request_for_incident.Status</h4>
          </th>
          <th scope="col-2" class="text-black-1"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let editor of OldUsersList">
          <td>
            <h4 class="text-black-1 text-left" scope="row">
              <span class="i-circle text-black-1 mr-2 font-weight-bold">{{ editor.initials }}</span
              >{{ editor.user.name }}
            </h4>
          </td>
          <td>
            <h4 class="text-black-1">{{ editor.due_date | date }}</h4>
          </td>
          <td>
            <h4 class="text-black-1 capitalize">{{ editor.status }}</h4>
          </td>
          <td class="p-2">
            <mat-icon
              class="cursor-pointer"
              *ngIf="editor.status.toLowerCase() == 'yet to start'; else Editing"
              (click)="
                openChangeDueFrom(
                  DocumentGuid,
                  editor.guid,
                  editor.DocCreatedBy,
                  editor.user.name,
                  editor.start_message,
                  editor.due_date
                )
              "
              >edit</mat-icon
            >
            <mat-icon
              class="cursor-pointer"
              *ngIf="editor.status.toLowerCase() == 'yet to start'"
              (click)="openRevokeEditorForm(DocumentGuid, editor.guid, editor.user.name)"
              >delete_outline</mat-icon
            ><ng-template #Editing>
              <a
                class="cursor-pointer"
                *ngIf="editor.status.toLowerCase() == 'editing'"
                (click)="
                  openChangeDueFrom(
                    DocumentGuid,
                    editor.guid,
                    editor.DocCreatedBy,
                    editor.user.name,
                    editor.comment,
                    editor.due_date
                  )
                "
                ><mat-icon>edit</mat-icon></a
              >
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
  </div>
</ng-container>

<ng-template #other_content>
  <div *ngIf="OldUsersList?.length > 0; else no_content">
    <div class="modal-header cb-modal-header">
      <div>
        <h3 class="modal-title font-weight-bold text-black-1" translate>change_request_for_incident.Editors_List</h3>
        <i
          class="fa fa-times text-black-1"
          style="width: 12px;
        height: 12px;
        right: 1em;
        cursor: pointer;
        position: absolute;
        top: 1.3em;"
          (click)="activeModal.close(false)"
          ngbAutoFocus
          translate
        ></i>
      </div>
    </div>
    <div class="modal-body">
      <table class="table table-borderless">
        <thead>
          <tr>
            <th scope="col-6">
              <h4 class="modal-title font-weight-bold text-black-1" translate>change_request_for_incident.Editors</h4>
            </th>
            <th scope="col-2">
              <h4 class="modal-title font-weight-bold text-black-1" translate>change_request_for_incident.Due_Date</h4>
            </th>
            <th scope="col-2">
              <h4 class="modal-title font-weight-bold text-black-1" translate>change_request_for_incident.Status</h4>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let editor of OldUsersList">
            <td>
              <h4 class="text-black-1 text-left" scope="row">
                <span class="i-circle text-black-1 mr-2 font-weight-bold">{{ editor.initials }}</span
                >{{ editor.user.name }}
              </h4>
            </td>
            <td>
              <h4 class="text-black-1">{{ editor.due_date | date }}</h4>
            </td>
            <td>
              <h4 class="text-black-1 capitalize">{{ editor.status }}</h4>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="modal-footer border-0 cb-modal-footer">
      <button class="btn-cancel ml-4 w-100" (click)="activeModal.close({ isUpdate: false })" ngbAutoFocus translate>
        change_request_for_incident.Close
      </button>
    </div>
  </div>
</ng-template>

<ng-template #no_content>
  <div class="modal-body">
    <h2 class="modal-title font-weight-bold text-black-1 p-5 text-center" translate>
      change_request_for_incident.Close
    </h2>
  </div>
  <div class="modal-footer border-0 cb-modal-footer">
    <button class="btn-cancel ml-4 w-100" (click)="activeModal.close({ isUpdate: false })" ngbAutoFocus translate>
      change_request_for_incident.Close
    </button>
  </div>
</ng-template>
