import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, ComponentRef } from '@angular/core';
import { BehaviorSubject, from, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Renderer2, RendererFactory2 } from '@angular/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  private overlayElem: HTMLElement;
  private componentRef: ComponentRef<any>;
  componentRefs: ComponentRef<any>[] = [];
  private renderer: Renderer2;
  private resultSubject = new Subject<any>();
  public ServiceProviderAccountDetails = new BehaviorSubject<any>(null);

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    rendererFactory: RendererFactory2,
    private toasterService: ToasterService
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  open(component: any, content?: any) {
    if (this.componentRefs.length === 0) {
      this.overlayElem = document.createElement('div');
      this.overlayElem.style.position = 'fixed';
      this.overlayElem.style.top = '0';
      this.overlayElem.style.left = '0';
      this.overlayElem.style.width = '100%';
      this.overlayElem.style.height = '100%';
      this.overlayElem.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
      this.overlayElem.style.zIndex = '999';
      document.body.appendChild(this.overlayElem);
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    this.componentRef = componentFactory.create(this.injector);
    Object.assign(this.componentRef.instance, content);

    const domElem = (this.componentRef.hostView as any).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
    this.appRef.attachView(this.componentRef.hostView);
    this.componentRefs.push(this.componentRef);

    return from(
      new Promise((resolve, reject) => {
        this.resultSubject.subscribe(
          (result: any) => {
            resolve(result);
            // this.close(this.componentRef);
            this.resultSubject.complete();
            this.resultSubject = new Subject<any>();
          },
          (error: any) => {
            reject(error);
            // this.close(this.componentRef);
            this.toasterService.showError(error);
            this.resultSubject.complete();
            this.resultSubject = new Subject<any>();
          }
        );
      })
    );
  }

  updateData(result: any, isError: boolean = false) {
    if (isError) {
      this.resultSubject.error(result);
    } else {
      this.resultSubject.next(result);
    }
  }
  close(isCancel: boolean = false, componentClass?: any) {
    let componentRefToClose: ComponentRef<any> | null = null;
    if (componentClass) {
      componentRefToClose = this.findComponentRefByClass(componentClass);
    } else if (this.componentRefs.length > 0) {
      componentRefToClose = this.componentRefs[this.componentRefs.length - 1];
    }
    if (componentRefToClose) {
      this.appRef.detachView(componentRefToClose.hostView);
      componentRefToClose.destroy();
      const index = this.componentRefs.indexOf(componentRefToClose);
      if (index > -1) {
        this.componentRefs.splice(index, 1);
      }
      if (this.componentRefs.length === 0 && this.overlayElem) {
        document.body.removeChild(this.overlayElem);
        this.overlayElem = null;
      }
    }
    if (isCancel) {
      this.resultSubject.complete();
      this.resultSubject = new Subject<any>();
    }
  }
  Cancel() {
    this.resultSubject.complete();
    this.resultSubject = new Subject<any>();
  }
  private findComponentRefByClass(componentClass: any): ComponentRef<any> | null {
    for (const componentRef of this.componentRefs) {
      if (componentRef.location.nativeElement.tagName.toLowerCase() === 'app-customsidenav') {
        return componentRef;
      }
    }
    return null;
  }
}
