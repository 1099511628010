import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OrganisationService } from '@app/core/organisation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { SettingsService } from '@app/settings/settings.service';
import SignaturePad from 'signature_pad';
import { DomSanitizer } from '@angular/platform-browser';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';

@Component({
  selector: 'app-draw-signature',
  templateUrl: './draw-signature.component.html',
  styleUrls: ['./draw-signature.component.scss']
})
export class DrawSignatureComponent implements OnInit {
  GenerateSignatureForm: FormGroup;
  OrganisationID: string | null;
  guidSubscription: any;
  isLoading = false;
  submitted: boolean = false;
  error: any;
  form_error: any;
  empty_signature_error = false;
  size: any;
  file: any;

  @Input() title_tag: string;
  @ViewChild('canvas', { static: true }) canvas: ElementRef;
  context: any;
  sigPadElement: SignaturePad;
  image_file: any;
  file_size_error: boolean;
  isShowCommentSection: boolean = false;
  backend_error: any;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private settingsService: SettingsService,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.isShowCommentSection = localStorage.getItem('is-org-onboarded') === 'true' ? true : false;
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
    });
    this.getDrawSignatureForm();
    this.sigPadElement = new SignaturePad(this.canvas.nativeElement);
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  get f() {
    return this.GenerateSignatureForm.controls;
  }

  getDrawSignatureForm() {
    this.GenerateSignatureForm = this.formBuilder.group({
      Comment: ['', [Validators.maxLength(4096)]],
      Signature: ['']
    });
  }

  clear() {
    this.sigPadElement.clear();
  }

  save() {
    this.submitted = true;
    if (this.GenerateSignatureForm.valid && !this.sigPadElement.isEmpty()) {
      this.image_file = this.sigPadElement.toDataURL('image/png');
      this.file = this.convertBase64ToFile(this.image_file, 'signature');
      this.size = this.file.size;
      this.file_size_error = false;
      if (this.size < 1024 * 1024 * 5 || this.size == 1024 * 1024 * 5) {
        this.file_size_error = false;
      } else {
        this.file_size_error = true;
      }
      this.isLoading = true;
      const signatureData = this.GenerateSignatureForm.value;
      const data = { size: this.size, comment: signatureData['Comment'] };
      this.submitDrawSignature(data, this.file);
    } else {
      if (this.sigPadElement.isEmpty()) {
        this.empty_signature_error = true;
      }
      return;
    }
  }

  convertBase64ToFile(base64String: any, fileName: any) {
    let arr = base64String.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let uint8Array = new Uint8Array(n);
    while (n--) {
      uint8Array[n] = bstr.charCodeAt(n);
    }
    let file = new File([uint8Array], fileName, { type: mime });
    return file;
  }

  submitDrawSignature(data: any, file: any): void {
    this.settingsService.uploadUserSignature(this.OrganisationID, data).subscribe(
      (data: any) => {
        console.log(data.url);
        this.settingsService.uploadUserSignatureToGCP(data.url, file).subscribe(
          (data: any) => {
            this.error = {
              Comment: ''
            };
            this.submitted = false;
            this.activeModal.close(true);
            console.log('Signature uploaded.');
          },
          (error: any) => {
            this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
            this.backendErrorService.backendError$.subscribe(error => {
              this.backend_error = error;
            });
          }
        );
      },
      (error: any) => {
        this.isLoading = false;
        this.submitted = false;
        this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
        });
      }
    );
  }
}
