<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div id="mySidenav" class="sidenav">
    <div class="sidenav-header d-flex align-items-center">
      <mat-icon class="cursor-pointer" (click)="closeNav()">arrow_back</mat-icon>
      <div class="hedeartitle ml-2">{{ title_tag }}</div>
      <i class="fa fa-times text-black-1 closebtn" (click)="closeAll()" ngbAutoFocus translate></i>
    </div>
    <div class="row mt-2" *ngIf="backend_error">
      <div class="col-12 col-sm-12 col-lg-12 ml-5">
        <div class="text-danger" translate>* {{ backend_error }}</div>
      </div>
    </div>
    <div class="border-bottom mt-2">
      <label class="folder-label">Select a folder or subfolder</label>
    </div>
    <div class="tree-container">
      <div
        class="col p-0 search-results"
        infiniteScroll
        [scrollWindow]="false"
        (scrolled)="onScroll()"
        [infiniteScrollDistance]="modalScrollDistance"
        [infiniteScrollThrottle]="modalScrollThrottle"
        (scrolledUp)="onScrollUp()"
      >
        <ul class="tree">
          <ng-container *ngFor="let folder of folderList">
            <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: folder }"></ng-container>
          </ng-container>
        </ul>
        <ng-template #recursiveList let-folder>
          <li [ngClass]="{ 'main-folder-border': folder.isMainFolder }">
            <div class="tree-list" [ngClass]="{ selected: highlightedFolder === folder }">
              <img src="../../../assets/images/core/folder-icon.svg" />
              <span
                class="title"
                style=" cursor: pointer;"
                title="{{ folder.title }}"
                (click)="handleFolderClick(folder)"
                >{{ folder.title }}</span
              >

              <i
                *ngIf="folder.is_child_folder"
                class="toggle-icon"
                (click)="handleFolderClick(folder)"
                [ngClass]="isToggled(folder) ? 'fa fa-caret-up' : 'fa fa-caret-right'"
              ></i>
            </div>
            <ul *ngIf="isToggled(folder)" class="child-ul">
              <ng-container *ngFor="let child of folder.children">
                <ng-container *ngTemplateOutlet="recursiveList; context: { $implicit: child }"></ng-container>
              </ng-container>
            </ul>
          </li>
        </ng-template>
      </div>
    </div>
    <div *ngIf="scrollLoader" class="w-100p d-flex justify-content-center">
      <h6 class="font-weight-bold ">
        <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': scrollLoader }"></i> &nbsp; Loading...
      </h6>
    </div>
    <div class="sidenav-footer d-flex">
      <button
        type="submit"
        class="btn-save cb-btn-primary"
        [ngClass]="{ 'btn-disabled cb-btn-disabled': disableSelectButton }"
        [disabled]="disableSelectButton"
        *ngIf="!isLoading"
        (click)="save()"
      >
        Done
      </button>
      <button type="submit" class="btn-save" disabled *ngIf="isLoading">
        <i class="fa fa-spinner fa-spin"></i>
      </button>
      <button type="button" class="btn-cancel cb-btn-secondary" (click)="closeNav()">Cancel</button>
    </div>
  </div>
</ng-container>
