<label
  class="form-check-label text-black-1 d-inline mr-3"
  [ngClass]="{
    'checkbox-4': schedule['selection'] == 'partial',
    'checkbox-3': schedule['selection'] == 'all',
    'default-checkbox': schedule['selection'] == 'none'
  }"
  for="inlinecheck-{{ schedule['value'] }}"
>
  <input
    (click)="box_clicked()"
    class="form-check-input"
    type="checkbox"
    name="inlineRadioOptions"
    id="inlinecheck-{{ schedule['value'] }}"
    [checked]="schedule['selection'] == 'all' || schedule['selection'] == 'partial'"
  />
  <span class="checkmark"></span>
</label>
