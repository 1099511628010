import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OrganisationService } from '@app/core/organisation.service';
import { OrganisationDashboardService } from '@app/Organisation-admin/orgDashboard/orgDashboard.service';
import Chart from 'chart.js';
import { Subscription } from 'rxjs';
import { RestartProgramDialogComponent } from './restart-program-dialog/restart-program-dialog.component';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { MixpanelService } from '@app/mixpanel/mixpanel.service';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { TranslateService } from '@ngx-translate/core';
interface ComplianceStatus {
  [key: string]: boolean;
}
@Component({
  selector: 'app-frameworks',
  templateUrl: './frameworks.component.html',
  styleUrls: ['./frameworks.component.scss']
})
export class FrameworksComponent implements OnInit, OnDestroy {
  chartManager = {};
  overallSummary: any = {};
  searchDocumentTitle = '';
  policiesProgress = 0;
  compliancePrograms: any = [];
  comaplianceArrays: any = [];
  guidSubscription: Subscription;
  organisationID: string = '';
  loading = false;
  summaryData: any = {};
  backend_error = '';
  complianceStatus: ComplianceStatus = {
    HIPAA: false,
    SOC2: false
  };

  frameworks: any = [
    {
      name: 'HIPAA',
      img: 'hippa.svg',
      disabled: true,
      chartId: 'hipaa',
      policies: 0,
      totalPolicies: 0,
      evidences: 0,
      totalEvidences: 0,
      alreadyAddedProgram: false,
      hidden: false,
      data: {}
    },
    {
      name: 'SOC2',
      img: 'soc2.svg',
      disabled: true,
      chartId: 'soc2',
      policies: 0,
      totalPolicies: 0,
      evidences: 0,
      totalEvidences: 0,
      alreadyAddedProgram: false,
      data: {},
      hidden: false
    },
    {
      name: 'ISO 27001',
      img: 'iso.svg',
      disabled: true,
      chartId: 'iso',
      policies: 0,
      totalPolicies: 0,
      evidences: 0,
      totalEvidences: 0,
      alreadyAddedProgram: false,
      data: {},
      hidden: false
    }
    // {
    //   name: 'GDPR',
    //   img: 'gdpr.svg',
    //   disabled: true,
    //   chartId: 'gdpr',
    //   policies: 0,
    //   totalPolicies: 0,
    //   evidences: 0,
    //   totalEvidences: 0,
    //   alreadyAddedProgram: false,
    //   data: {},
    //   hidden: false
    // },
    // {
    //   name: 'FISMA',
    //   img: 'fisma.svg',
    //   disabled: true,
    //   chartId: 'fisma',
    //   policies: 0,
    //   totalPolicies: 0,
    //   evidences: 0,
    //   totalEvidences: 0,
    //   alreadyAddedProgram: false,
    //   data: {},
    //   hidden: false
    // },
    // {
    //   name: 'CMMC',
    //   img: 'cmmc.svg',
    //   disabled: true,
    //   chartId: 'cmmc',
    //   policies: 0,
    //   totalPolicies: 0,
    //   evidences: 0,
    //   totalEvidences: 0,
    //   alreadyAddedProgram: false,
    //   data: {},
    //   hidden: false
    // }
  ];
  orgTypes: { guid: string; title: string; tooltip?: string }[] = [];
  constructor(
    private orgDashboardService: OrganisationDashboardService,
    private router: Router,
    private orgService: OrganisationService,
    private dialog: MatDialog,
    private toasterService: ToasterService,
    private mixpanel: MixpanelService,
    private backendErrorService: BackendErrorService,
    private toaster: ToasterService,
    private translate: TranslateService // Add MatDialog service
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(org_guid => {
      this.organisationID = org_guid;
      this.orgService.getProgramLiteSummary(org_guid).subscribe((res: any) => {
        const transformedData = res.reduce((acc: any, curr: any) => {
          acc[curr.framework] = curr.summary;
          return acc;
        }, {});
        this.summaryData = transformedData;
      });
      this.orgDashboardService.organisationalProgram(org_guid).subscribe((res: any) => {
        this.compliancePrograms = res;
        this.comaplianceArrays = res.map((item: any) => item.name);
        if (res && res.length) {
          this.updateFrameworkSelection();
        }
        this.fetchData();
        this.getOrganisationType();
      });
    });
  }
  getOrganisationType() {
    this.loading = true;
    this.orgDashboardService.getProfileConfiguration(this.organisationID).subscribe(
      res => {
        this.orgTypes = res['data'];

        // First disable all frameworks but preserve alreadyAddedProgram status
        this.frameworks.forEach((element: any) => {
          element.disabled = true;
          // Don't reset alreadyAddedProgram here
        });

        // Enable frameworks based on available compliance programs
        res.data.compliance_programs.forEach((item: any) => {
          const matchingFramework = this.frameworks.find((element: any) => element.name === item.name);
          if (matchingFramework) {
            matchingFramework.guid = item.guid;
            matchingFramework.disabled = false;
          }
        });

        // Update framework selection based on existing compliance programs
        if (this.compliancePrograms && this.compliancePrograms.length) {
          this.updateFrameworkSelection();
        }
        const savedStatus = localStorage.getItem('complianceStatus');
        if (savedStatus) {
          this.complianceStatus = JSON.parse(savedStatus);
        }

        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    );
  }
  private updateFrameworkSelection(): void {
    this.frameworks = this.frameworks.map((framework: any) => {
      // Check if this framework exists in compliancePrograms
      const exists = this.compliancePrograms.some(
        (item: any) => item.parent_compliance_program.name === framework.name
      );

      return {
        ...framework,
        alreadyAddedProgram: exists // Set alreadyAddedProgram based on existence
      };
    });
  }
  getComplianceItems(): Array<{ name: string; value: boolean }> {
    return Object.entries(this.complianceStatus).map(([name, value]) => ({
      name,
      value
    }));
  }

  createDoughnutChart(chartId: string, labels: string[], data: number[], backgroundColor: string[], titleText: string) {
    let options = {
      responsive: true,
      legend: {
        display: false
      },
      tooltips: {
        enabled: false
      },
      cutoutPercentage: 82,
      maintainAspectRatio: false,
      title: {
        display: false
      }
    };
    const canvas = document.getElementById(chartId) as HTMLCanvasElement;

    if (this.chartManager[chartId]) {
      this.chartManager[chartId].destroy();
    }
    const ctx = canvas?.getContext('2d');
    var myChart = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: backgroundColor,
            borderColor: backgroundColor,
            borderWidth: 1
          }
        ]
      },
      options: options
    });
    this.chartManager[chartId] = myChart;
  }
  getChartData(labels: any, data: any, colors: any) {
    const isEmpty = data.every((value: any) => value === 0);

    if (isEmpty) {
      return {
        labels: ['No Data'],
        data: [1],
        colors: ['#E1E6EB']
      };
    }

    return {
      labels: labels,
      data: data,
      colors: colors
    };
  }
  checkZero(data = {}) {
    return Object.values(data).reduce((accumulator: number, currentValue: number) => {
      return accumulator + currentValue;
    }, 0);
  }

  percentage(percent: number, total: number) {
    if (percent == 0 || total == 0) return 0;
    return ((percent / total) * 100).toFixed(1);
  }

  fetchData() {
    this.orgDashboardService.getFrameworkData().subscribe(
      (res: any) => {
        // Update data for all frameworks
        const frameworkMappings = {
          hipaa: { index: 0, key: 'hipaa' },
          soc2: { index: 1, key: 'soc2' },
          iso27001: { index: 2, key: 'iso27001' }
          // gdpr: { index: 3, key: 'gdpr' },
          // fisma: { index: 4, key: 'fisma' },
          // cmmc: { index: 5, key: 'cmmc' }
        };

        // Update framework data
        Object.entries(frameworkMappings).forEach(([name, config]) => {
          if (res[config.key]) {
            this.frameworks[config.index] = {
              ...this.frameworks[config.index],
              policies: parseInt(res[config.key].policies || '0', 10),
              totalPolicies: parseInt(res[config.key].totalPolicies || '0', 10),
              evidences: parseInt(res[config.key].evidences || '0', 10),
              totalEvidences: parseInt(res[config.key].totalEvidences || '0', 10),
              data: res[config.key]
            };
          }
        });

        // Create/update all charts
        setTimeout(() => {
          this.frameworks.forEach((framework: any) => {
            if (!framework.disabled) {
              const controlChartData = this.getChartData(
                ['Completed', 'Total'],
                [
                  this.summaryData[framework.name].pgm_unit_status['COMPLETED'],
                  this.summaryData[framework.name].pgm_unit_status['INCOMPLETE'] +
                    this.summaryData[framework.name].pgm_unit_status['PENDING'] +
                    this.summaryData[framework.name].pgm_unit_status['COMPLETED']
                ],
                ['#0D9647', '#E1E6EB']
              );
              this.createDoughnutChart(
                framework.chartId,
                controlChartData.labels,
                controlChartData.data,
                controlChartData.colors,
                `${framework.name} Progress`
              );
            } else {
              const controlChartData = this.getChartData(['Completed', 'Total'], [1], ['#E1E6EB']);
              this.createDoughnutChart(
                framework.chartId,
                controlChartData.labels,
                controlChartData.data,
                controlChartData.colors,
                `${framework.name} Progress`
              );
            }
          });
          this.loading = false;
        }, 0);
      },
      error => {
        console.error('Error fetching framework data', error);
      }
    );
  }
  gotoSelectFramework(framework: string = '') {
    this.orgDashboardService.changeOnBoardingFlag(this.organisationID, false).subscribe(
      data => {
        this.router.navigateByUrl('/program-config?step=2&framework=' + framework);
      },
      error => {
        this.backendErrorService.setBackendError(error);
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
          this.translate.get(this.backend_error).subscribe(res => {
            this.toaster.showError(res);
          });
        });
      }
    );
  }
  isFrameworkEnabled(framework: any): boolean {
    return !framework.disabled;
  }

  canAddProgram(framework: any): boolean {
    return this.isFrameworkEnabled(framework) && !framework.alreadyAddedProgram;
  }

  getButtonText(framework: any): string {
    return framework.alreadyAddedProgram ? 'Restart Program' : 'Add Program';
  }
  openRestartDialog() {
    const dialogRef = this.dialog.open(RestartProgramDialogComponent, {
      width: '500px',
      height: '310px',
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.loading = true;
        this.orgService.restartOnboarding(this.organisationID).subscribe(
          res => {
            this.loading = false;
            this.toaster.showInfo("You're onboarding has restarted");
            this.gotoSelectFramework();
          },
          error => {
            this.loading = false;
            this.backendErrorService.setBackendError(error);
            this.backendErrorService.backendError$.subscribe(error => {
              this.backend_error = error;
              this.translate.get(this.backend_error).subscribe(res => {
                this.toaster.showError(res);
              });
            });
          }
        );
      }
    });
  }
  searchFrameworks(): void {
    const searchTerm = this.searchDocumentTitle.toLowerCase().trim();
    if (!searchTerm) {
      this.frameworks.forEach((framework: any) => (framework.hidden = false));
    } else {
      this.frameworks.forEach((framework: any) => {
        framework.hidden = !framework.name.toLowerCase().includes(searchTerm);
      });
    }

    setTimeout(() => {
      this.frameworks.forEach((framework: any) => {
        let controlChartData;
        // Check if framework is disabled OR summaryData doesn't exist for this framework
        if (framework.disabled || !this.summaryData[framework.name]?.pgm_unit_status) {
          controlChartData = this.getChartData(['Completed', 'Total'], [1], ['#E1E6EB']);
        } else {
          const completed = this.summaryData[framework.name].pgm_unit_status['COMPLETED'] || 0;
          const incomplete =
            (this.summaryData[framework.name].pgm_unit_status['INCOMPLETE'] || 0) +
            (this.summaryData[framework.name].pgm_unit_status['PENDING'] || 0) +
            (this.summaryData[framework.name].pgm_unit_status['COMPLETED'] || 0);
          controlChartData = this.getChartData(['Completed', 'Total'], [completed, incomplete], ['#0D9647', '#E1E6EB']);
        }
        this.createDoughnutChart(
          framework.chartId,
          controlChartData.labels,
          controlChartData.data,
          controlChartData.colors,
          `${framework.name} Progress`
        );
      });
    });
  }
  handleEvent(framework: any) {
    if (this.complianceStatus[framework.name]) {
      this.toaster.showError('Already joined the waiting list');
      return;
    }
    this.mixpanel.sendCBEvent('joined_waitlist' + framework.name);
    this.complianceStatus[framework.name] = true;
    localStorage.setItem('complianceStatus', JSON.stringify(this.complianceStatus));
    this.toaster.showSuccess('Successfully joined the waiting list');
  }
  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }
}
