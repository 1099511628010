import { Component, OnInit } from '@angular/core';
import { OrganisationService } from '@app/core/organisation.service';
import { UserService } from '@app/core/user.service';
import { DocumentsService } from '../documents.service';
import { ModalService } from '@app/core/modal/modal.service';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { OrganisationAdminService } from '@app/Organisation-admin/organisation-admin.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { switchMap, take, takeWhile } from 'rxjs/operators';
import stringInfo from '../../../../translations/en-US.json';
import { timer } from 'rxjs';
import { OrganisationDashboardService } from '@app/Organisation-admin/orgDashboard/orgDashboard.service';
import { MixpanelService } from '@app/mixpanel/mixpanel.service';

interface ShowHideColumns {
  isShowFolderName: boolean;
  isShowVersion: boolean;
  isShowType: boolean;
}
interface configOption {
  label: string;
  key: string;
}
@Component({
  selector: 'app-published-document',
  templateUrl: './published-document.component.html',
  styleUrls: ['./published-document.component.scss']
})
export class PublishedDocumentComponent implements OnInit {
  [x: string]: any;
  OrganisationID: string;
  PublishedDocuments: object[];
  guidSubscription: any;
  error: any;
  DocumentDetails: any;
  loginUser: any;
  loginUsername: string;
  loginUserEmail: string;
  PublishedDocumentData: object[];
  page = 1;
  public pageSize = 10;
  totalLength: any;
  paginatedData: any;
  currentPage: number = 1;
  pagingNumber = 0;
  loaderArr: any = [];
  accArray: any[] = [];
  search_title: any;
  status: any = [];
  documentStatus: [];
  status_loading = false;
  statusFilter: any;
  search_text: any;
  placeholderSearch = stringInfo.Published_Documents.PlaceHolder_Search;
  scrollLoader = false;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  isloading = true;
  status2: any;
  pagnationData: any;
  clickedGuid: any;
  spnning = false;
  show = true;
  searchText: string;
  show_add_new_button: boolean = false;
  paginatedDataUrl: any;
  showHideColumns: ShowHideColumns = {
    isShowFolderName: false,
    isShowVersion: false,
    isShowType: false
  };
  filterColumnOptions: configOption[] = [
    { label: 'Folder Name', key: 'isShowFolderName' },
    { label: 'Type', key: 'isShowType' },
    { label: 'Version', key: 'isShowVersion' }
  ];
  exportStatusMapper = {
    PENDING: 'Export',
    COMPLETED: 'Download Document',
    FAILED: 'Export (Failed)',
    INPROGRESS: 'Export'
  };
  documentsStatus: any = [];
  conditionMet: boolean = false;
  controlsData: any = [];
  isdefaultDocNameDisplayed: boolean = false;

  constructor(
    private orgService: OrganisationService,
    private documentsService: DocumentsService,
    private orgAdminService: OrganisationAdminService,
    private userservice: UserService,
    private ModalService: ModalService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private orgDashboardService: OrganisationDashboardService,
    private mixpanel: MixpanelService
  ) {}

  ngOnInit() {
    this.loginUser = this.userservice.getuserdetails();
    this.loginUsername = this.loginUser.user['username'];
    this.loginUserEmail = this.loginUser.user['email'];

    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
      this.orgDashboardService.isGrcEnabled(this.OrganisationID).subscribe((data: boolean) => {
        this.isdefaultDocNameDisplayed = data['show_default_doc_name'];
        if (this.isdefaultDocNameDisplayed) {
          this.startPeriodicFetch();
        } else {
          this.getPublishList();
        }
      });
      this.getStatusTypes();
      this.getRole();
    });
    const enteredSearchValue: string = '';
    const action: string = this.route.snapshot.queryParamMap.get('action');
    const DocGuid: string = this.route.snapshot.queryParamMap.get('doc_guid');
    if (DocGuid) {
      this.getDocumentDetail(action, DocGuid);
    }
  }

  getRole() {
    this.role = JSON.parse(localStorage.getItem('credentials'));
    this.userRole = this.role['user']['role'];
    for (let i = 0; i < 10; i++) {
      if (this.userRole[i] == 'organization_admin_role' || this.userRole[i] == 'document_author') {
        this.key = this.userRole[i];
        this.show_add_new_button = true;
      }
    }
  }

  onScrollUp() {
    this.scrollLoader = false;
  }

  onScrollData() {
    this.scrollLoader = true;
    if (this.search_text == null || undefined) {
      this.search_text = '';
      this.documentsService
        .getAllDocumentVersionsPagination(this.OrganisationID, (this.page += 1), (this.search_text = this.search_text))
        .subscribe(
          data => {
            this.scrollLoader = false;
            this.paginatedDataUrl = data;
            this.pagnationData = data.results;
            this.PublishedDocumentData = this.PublishedDocumentData.concat(data.results);
          },
          error => {
            this.scrollLoader = false;
            this.error = error.error.message;
          }
        );
    } else {
      this.scrollLoader = true;
      this.documentsService
        .getAllDocumentVersionsPagination(this.OrganisationID, (this.page += 1), (this.search_text = this.search_text))
        .subscribe(
          data => {
            this.scrollLoader = false;
            this.pagnationData = data.results;
            this.paginatedDataUrl = data;
            this.PublishedDocumentData = this.PublishedDocumentData.concat(data.results);
          },
          error => {
            this.scrollLoader = false;
            this.error = error.error.message;
          }
        );
    }
  }

  onScroll() {
    if (this.paginatedDataUrl?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }
  //New Version code
  openNewVersionForm(DocumentGuid: string, DocumentVersion: string, docTitle: string) {
    this.NewVersionOpenModal(DocumentGuid, DocumentVersion, docTitle);
  }
  NewVersionOpenModal(DocumentGuid: string, DocumentVersion: string, docTitle: string): void {
    this.ModalService.custom(CustomDialogComponent, {
      title: 'Create New Version',
      modalType: 'Create New Version',
      DocumentGuid: DocumentGuid,
      docTitle: docTitle,
      DocumentVersion: DocumentVersion
    })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.mixpanel.sendCBEvent('new_document');
          this.documentsService.status.subscribe(data => {
            this.status2 = data;
            console.log('this.status1', this.status2);
          });
          for (let i = 0; i <= this.PublishedDocumentData.length; i++) {
            if (this.clickedGuid == this.PublishedDocumentData[i]['guid']) {
              this.PublishedDocumentData[i]['allowed_actions_list'] = this.status2['allowed_actions_list'];
              break;
            }
          }
          // this.ApplySearchFilter(this.page, this.search_title);
          this.translate;
          this.translate.get('Create_new_version.new_version_toaster_message.new_version').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }
  updateSearchText(x: any) {
    this.search_text = x.target.value;
    if (this.search_title == undefined) {
      this.search_text = '';
    }
  }

  Search(search_title: string) {
    this.isloading = true;
    this.search_title = search_title;
    for (let i = 0; i < this.PublishedDocumentData.length; i++) {
      if (this.PublishedDocumentData[i]['title'] != this.search_title) {
        this.page = 1;
      }
    }
    if (this.statusFilter == undefined) {
      this.statusFilter = '';
    }
    this.documentsService
      .getSearchResultListForPublishedDocuments(this.OrganisationID, this.search_title, this.page, this.statusFilter)
      .subscribe(
        data => {
          this.isloading = false;
          this.totalLength = data.count;
          this.PublishedDocumentData = data.results;
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
  }
  getStatusTypes() {
    if (this.OrganisationID !== null) {
      this.status_loading = true;
      this.orgAdminService.getOrganisationStatusTypes().subscribe(data => {
        this.documentStatus = data;
        this.status_loading = false;
      });
    }
  }

  load_status_list() {
    this.getStatusTypes();
  }

  clearLoader(value: any): void {
    const index = this.loaderArr.indexOf(value);
    if (index > -1) {
      this.loaderArr.splice(index, 1);
    }
  }

  goToCreateDoc() {
    this.router.navigate(['organisation/documents/create-document']);
  }

  onNavigate(docGuid: string) {
    this.documentsService.getOrganizationDocumentEditURl(this.OrganisationID, docGuid, '').subscribe(
      data => {
        this.OpenDocument(data.doc_store_url);
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }

  OpenDocument(url: string) {
    window.open(url, '_blank');
  }

  viewDocument(version: string) {
    this.router.navigate(['organisation/documents/published-documents/' + version]);
  }

  private getDocumentDetail(action: string, DocGuid: string) {
    this.documentsService.getDocumentDetailPage(this.OrganisationID, DocGuid).subscribe(
      data => {
        this.DocumentDetails = data;
        if (
          action == 'view_document' &&
          DocGuid == this.DocumentDetails['guid'] &&
          this.DocumentDetails['allowed_actions_list'].includes('View Document')
        ) {
          this.OpenDocument(this.DocumentDetails['document_store_identifier']);
        }
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }

  private getPublishList(): void {
    if (this.OrganisationID != null) {
      this.documentsService
        .getAllDocumentVersionsPagination(this.OrganisationID, this.page, (this.search_text = ''))
        .subscribe(
          data => {
            this.show = true;
            this.spnning = false;
            this.isloading = false;
            this.paginatedDataUrl = data;
            this.PublishedDocumentData = data.results;
            this.totalLength = data.length;
          },
          error => {
            this.error = error.error.message;
            this.isloading = false;
            this.spnning = false;
            this.show = true;
          }
        );
    }
  }
  startPeriodicFetch() {
    this.PublishedDocumentData = [];
    const fetchInterval = 15000; // 15 seconds
    let statusSub = timer(0, fetchInterval)
      .pipe(
        switchMap(() => this.orgDashboardService.getOrgDocumentStatusData(this.OrganisationID)),
        takeWhile(() => !this.conditionMet)
      )
      .subscribe(
        res => {
          this.documentsStatus = res['data'];
          this.isloading = false;
          if (res['data'].every((item: any) => item.is_uploaded)) {
            this.getPublishList();
            this.conditionMet = true;
            this.isdefaultDocNameDisplayed = false;
            statusSub?.unsubscribe();
          } else {
            const template: any = {
              guid: '',
              version: '',
              created_by: {
                guid: '',
                name: '',
                email: ''
              },
              published_on_timestamp: new Date(),
              allowed_actions_list: [],
              appendix: '',
              doc_store_identifier: '',
              document_version: '',
              document_type: '',
              parent_folder: '',
              users: [],
              is_uploaded: false
            };
            let credentials;
            if (localStorage.getItem('credentials')) {
              credentials = JSON.parse(localStorage.getItem('credentials'));
              template.created_by.name = credentials.user.first_name + ' ' + credentials.user.last_name;
              template.created_by.email = credentials.user.username;
              template.created_by.guid = credentials.user.guid;
            }
            this.PublishedDocumentData.push(
              ...res['data'].map((item: any) => ({
                ...template,
                title: item.category_name,
                is_uploaded: item.is_uploaded
              }))
            );
          }
        },
        error => {
          console.error('Error fetching data:', error);
        }
      );
  }

  goTOEditDocument(DocGuid: any) {
    console.log(DocGuid);
    this.router.navigate(['/organisation/documents/edit-documents'], { queryParams: { DocGuid: DocGuid } });
  }

  refresh() {
    this.show = false;
    this.spnning = true;
    this.page = 1;
    if (this.isdefaultDocNameDisplayed) {
      this.startPeriodicFetch();
    } else {
      this.getPublishList();
    }
    this.searchText = '';
  }
  ApplySearchFilter(page: any, search_title: string) {
    if (search_title == undefined) {
      search_title = '';
    }
    this.documentsService.getPaginatedDocumentsList2(this.OrganisationID, page, search_title).subscribe(
      data => {
        this.PublishedDocumentData = data.results;
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }

  // getClickEvents(action: string, DocumentGuid: string, DocumentVersion: string, docTitle: string, Publish: string) {
  //   console.log('url', Publish['appendix']);
  // }
  OpenAddReadersModal(DocumentObj: any, DocumentVersion: any): void {
    const readerEmails = DocumentObj.users.map((x: any) => x.email);
    this.ModalService.custom(
      CustomDialogComponent,
      {
        title: 'Add Readers',
        modalType: 'Add Readers',
        guid: DocumentObj.guid,
        readerEmails: readerEmails,
        DocumentVersion: DocumentVersion,
        requestedAccess: DocumentObj.requested_access
      },
      { windowClass: 'add-admin' }
    )
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        // this.getEditorList();
        if (this.isdefaultDocNameDisplayed) {
          this.startPeriodicFetch();
        } else {
          this.getPublishList();
        }
        // this.getUnassignedEditorsList();
        if (result) {
          this.translate.get('Revoke_Editor.revoke_editor_toaster_message.revoke_editor_submitted').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }
  requestAccess(DocumentVersion: any) {
    this.documentsService.getaccess(this.OrganisationID, DocumentVersion).subscribe(
      data => {
        console.log(data);
        this.translate.get('Request has been sent').subscribe(res => {
          this.toaster.showSuccess(res);
        });
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }
  exportDocument(doc_version_guid: string, data = {}) {
    let payload = {
      document_type: data['document_type'],
      document_id: data['guid'],
      owner: data['created_by']['name']
    };
    this.documentsService.exportDocument(this.OrganisationID, doc_version_guid, payload).subscribe(
      data => {
        for (let i = 0; i <= this.PublishedDocumentData.length; i++) {
          if (this.clickedGuid == this.PublishedDocumentData[i]['guid']) {
            this.PublishedDocumentData[i]['export_status'] = data['export_status'];
            break;
          }
        }
        this.toaster.showSuccess('Export is in progress.');
      },
      error => {
        this.toaster.showError(error.error);
      }
    );
  }

  downloadDocument(url: string) {
    window.open(url, '_blank');
  }

  getClickEvents(action: string, DocumentGuid: string, DocumentVersion: string, docTitle: string, Publish: any) {
    this.clickedGuid = DocumentGuid;
    if (action == 'Create New Version') {
      this.openNewVersionForm(DocumentGuid, DocumentVersion, docTitle);
    } else if (action == 'View Document') {
      this.OpenDocument(Publish['doc_store_identifier']);
    } else if (action == 'View Document Details') {
      this.viewDocument(Publish['document_version']);
    } else if (action == 'Add Readers') {
      this.OpenAddReadersModal(Publish, Publish['document_version']);
    } else if (action == 'Edit Document Details') {
      this.goTOEditDocument(Publish['document_version']);
    } else if (action == 'Request Access') {
      this.requestAccess(Publish['document_version']);
    } else if (action == 'PENDING' || action == 'INPROGRESS' || action == 'FAILED') {
      this.exportDocument(Publish['document_version'], Publish);
    } else if (action == 'COMPLETED') {
      this.downloadDocument(Publish['exported_doc_store_id']);
    } else if (action == 'Archive Document') {
      this.archiveDocument(Publish.document_version, DocumentGuid);
    }
  }

  archiveDocument(DocumentVersion: string, guid: string) {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Archive Document',
        modalType: 'Archive Document',
        DocumentGuid: DocumentVersion,
        message:
          'The action will archive all published versions of the document, and they will not be available for future training campaigns. Are you sure you want to archive the document?'
      })
      .pipe(take(1))
      .subscribe((result: any) => {
        if (result.isUpdate) {
          this.PublishedDocumentData = this.PublishedDocumentData.filter((item: any) => item.guid !== guid);
        }
      });
  }
  isDisabled() {
    if (this.isdefaultDocNameDisplayed) {
      return true;
    } else {
      return false;
    }
  }
}
