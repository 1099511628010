import { Component, OnInit } from '@angular/core';
import { UserService } from '@app/core/user.service';
import { DocumentsService } from '../documents/documents.service';
import { OrganisationService } from '@app/core/organisation.service';

@Component({
  selector: 'app-incident',
  templateUrl: './incident.component.html',
  styleUrls: ['./incident.component.scss']
})
export class IncidentComponent implements OnInit {
  loginUser: any;
  can_add_new: boolean = false;
  searchText = '';
  page = 1;
  scrollLoader = false;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  pagnationData: any;
  guidSubscription: any;
  error: any;
  treeApiList: any;
  organisationID: string;
  storedFilteredIncidentData: string;
  filteredIncidentData: string;

  constructor(
    private userservice: UserService,
    private documentsService: DocumentsService,
    private orgService: OrganisationService
  ) {}

  ngOnInit() {
    this.loginUser = this.userservice.getuserdetails();
    if (
      this.loginUser.user.role.includes('incident_operator') ||
      this.loginUser.user.role.includes('organization_admin_role')
    ) {
      this.can_add_new = true;
    } else {
      this.can_add_new = false;
    }
    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.organisationID = guid;
    });
    this.storedFilteredIncidentData = localStorage.getItem('filteredWorkspaceIncidentData');
    this.filteredIncidentData = JSON.parse(this.storedFilteredIncidentData);
  }
  ngAfterViewInit() {
    this.getClickedEventsOnWorkspaceElement(this.filteredIncidentData);
  }
  getClickedEventsOnWorkspaceElement(workspace: any) {
    this.documentsService.getFolderDocumentViewList(this.organisationID, workspace[0].guid, 'incident').subscribe(
      data => {
        this.treeApiList = data;
        if (this.treeApiList != 0) {
          this.documentsService.setData({ workspace: workspace, treeApiList: this.treeApiList });
          this.documentsService.setworkspaceData(this.treeApiList);
        }
      },
      error => {
        this.error = error.error.message;
      }
    );
  }
}
