<app-spinner *ngIf="isloading"></app-spinner>

<div class="row">
  <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
    <h2 class="text-cyan m-0" style="cursor:default" translate>
      {{ incidentGuid ? 'Create_Incident.Edit_Incident' : 'Create_Incident.Incident_Title' }}
    </h2>
    <div>
      <button
        *ngIf="!incidentGuid"
        class="btn-cancel ml-4 w-100"
        [ngClass]="{ 'btn-cancel': true }"
        (click)="onReset()"
        translate
      >
        Create_Incident.clear
      </button>
      <button
        class="btn-save ml-4 w-100"
        [disabled]="addIncidentForm.invalid || !templateName || addIncidentForm.pristine"
        [ngStyle]="{
          'background-color':
            addIncidentForm.invalid || !templateName || addIncidentForm.pristine ? 'rgba(0,0,0,.26)' : '#4db7c6'
        }"
        [ngClass]="{ 'btn-save': true }"
        (click)="SaveIncidentForm()"
        translate
      >
        Create_Incident.save
      </button>
    </div>
  </div>
</div>
<div class="row mt-2" *ngIf="backend_error">
  <div class="col-12 col-sm-12 col-lg-12 ml-5">
    <div class="text-danger" translate>* {{ backend_error }}</div>
  </div>
</div>

<form [formGroup]="addIncidentForm" novalidate autocomplete="off">
  <div class="col">
    <div class="row mt-5">
      <div class="col-2 col-sm-2 col-lg-2">
        <label for="title" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate>Create_Incident.Title</label>
      </div>
      <div class="input-group col-9 col-sm-9 col-lg-9">
        <input
          id="title"
          class="form-control cb-input"
          formControlName="title"
          [placeholder]="'Incident Title'"
          autocomplete="title"
          required
          name="title"
          type="text"
          style="height: 40px;"
          (keyup)="onChangeTitle($event)"
          (keydown.enter)="$event.preventDefault()"
          [maxLength]="256"
          required
        />
        <div
          class="col-12 error-mssg"
          *ngIf="
            (addIncidentForm.controls.title.touched || addIncidentForm.controls.title.dirty) &&
            addIncidentForm.controls.title.errors
          "
        >
          <label for="name" class="text-danger" *ngIf="addIncidentForm.controls.title.errors.required" translate
            >Create_Incident.*Incident Title is Required</label
          >
          <label for="name" class="text-danger" *ngIf="addIncidentForm.controls.title.errors.maxLength" translate
            >Create_Document.Title must be 255 characters or less</label
          >
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-2 col-sm-2 col-lg-2">
        <label for="description" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate
          >Create_Incident.Description</label
        >
      </div>
      <div class="input-group col-9 col-sm-9 col-lg-9">
        <textarea
          iid="description"
          class="form-control cb-input"
          [placeholder]="'Create_Document.Description' | translate"
          name="description"
          type="text"
          formControlName="description"
          cols="64"
          rows="10"
          [maxLength]="4097"
        ></textarea>
        <div
          class="col-12 error-mssg"
          *ngIf="
            (addIncidentForm.controls.description.touched || addIncidentForm.controls.description.dirty) &&
            addIncidentForm.controls.description.errors
          "
        >
          <label for="name" class="text-danger" *ngIf="addIncidentForm.controls.description.errors.required" translate
            >Create_Incident.*Incident description is Required</label
          >
          <label for="name" class="text-danger" *ngIf="addIncidentForm.controls.description.errors.maxLength" translate
            >Create_Incident.*Description must be 4096 characters or less</label
          >
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-2 col-sm-2 col-lg-2">
        <label for="folder" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate>Create_Incident.Category</label>
      </div>
      <div class="input-group col-2 col-sm-3 col-lg-3">
        <select
          class="form-control cb-input"
          (change)="categoryOptionsChange($event.target.value)"
          style="height: 40px;"
          formControlName="category"
          (keydown.enter)="$event.preventDefault()"
          name="category"
          required
        >
          <option value="" selected>--- Select Category Option ---</option>
          <option
            *ngFor="let category of categoryList; let i = index"
            [attr.data-index]="i"
            [value]="category['title']"
          >
            {{ category['title'] }}
          </option>
        </select>
        <div
          class="col-12 error-mssg"
          *ngIf="
            (addIncidentForm.controls.category.touched || addIncidentForm.controls.category.dirty) &&
            addIncidentForm.controls.category.errors
          "
        >
          <label for="name" class="text-danger" *ngIf="addIncidentForm.controls.category.errors.required" translate>
            Create_Incident.*Incident Category is Required
          </label>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-2 col-sm-2 col-lg-2">
        <label for="folder" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate>Create_Incident.Severity</label>
      </div>
      <div class="input-group col-2 col-sm-3 col-lg-3">
        <select
          class="form-control cb-input"
          (change)="severityOptionsChange($event.target.value)"
          style="height: 40px;"
          formControlName="severity"
          (keydown.enter)="$event.preventDefault()"
          name="severity"
          required
        >
          <option value="" selected>--- Select Severity Option ---</option>
          <option *ngFor="let severity of severityList; let i = index" [attr.data-index]="i" [value]="severity">
            {{ severity }}
          </option>
        </select>
        <div
          class="col-12 error-mssg"
          *ngIf="
            (addIncidentForm.controls.severity.touched || addIncidentForm.controls.severity.dirty) &&
            addIncidentForm.controls.severity.errors
          "
        >
          <label for="name" class="text-danger" *ngIf="addIncidentForm.controls.severity.errors.required" translate>
            Create_Incident.*Incident Severity is Required
          </label>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-2 col-sm-2 col-lg-2">
        <label for="reported_by" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate
          >Create_Incident.Reported_By</label
        >
      </div>
      <div class="input-group col-3 col-sm-3 col-lg-3">
        <input
          id="reported_by"
          class="form-control cb-input"
          formControlName="reported_by"
          [placeholder]="'Incident Reported By'"
          autocomplete="reported_by"
          required
          name="reported_by"
          type="text"
          style="height: 40px;"
          (keydown.enter)="$event.preventDefault()"
          [maxLength]="256"
          required
        />
        <div
          class="col-12 error-mssg"
          *ngIf="
            (addIncidentForm.controls.reported_by.touched || addIncidentForm.controls.reported_by.dirty) &&
            addIncidentForm.controls.reported_by.errors
          "
        >
          <label for="name" class="text-danger" *ngIf="addIncidentForm.controls.reported_by.errors.required" translate
            >Create_Incident.*Incident Reported By is Required</label
          >
          <label for="name" class="text-danger" *ngIf="addIncidentForm.controls.reported_by.errors.maxLength" translate
            >Create_Incident.*Reported By must be 255 characters or less</label
          >
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-2 col-sm-2 col-lg-2">
        <label for="reported_date" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate
          >Create_Incident.Reported_Date</label
        >
      </div>
      <div class="input-group col-3 col-sm-3 col-lg-3">
        <input
          id="reported_date"
          class="form-control cb-input"
          placeholder="Reported Date"
          formControlName="reported_date"
          name="reported_date"
          ngbDatepicker
          #d="ngbDatepicker"
          style="height: 40px;"
          (click)="d.toggle()"
          required
          [maxDate]="maxDate"
          (dateSelect)="onDateSelection($event)"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary fa fa-calendar-alt cb-input"
            (click)="d.toggle()"
            type="button"
            style="height: 40px;"
          ></button>
        </div>

        <div
          class="col-12 error-mssg"
          *ngIf="
            (addIncidentForm.controls.reported_date.touched || addIncidentForm.controls.reported_date.dirty) &&
            addIncidentForm.controls.reported_date.errors
          "
        >
          <label
            for="due_date"
            class="text-danger"
            *ngIf="addIncidentForm.controls.reported_date.errors.required"
            translate
          >
            Create_Incident.*Reported Date is Required</label
          >
          <label
            for="due_date"
            class="text-danger"
            *ngIf="addIncidentForm.controls.reported_date.errors.DateTodayOrBefore_error"
            translate
          >
            Create_Incident.*Reported Date must be less than or equals to today</label
          >
        </div>
      </div>
    </div>

    <ng-container *ngIf="!incidentGuid; else normalLink">
      <div class="row mt-6">
        <div class="col-2 col-sm-2 col-lg-2">
          <label for="title" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate>Create_Incident.Template</label>
        </div>
        <div class="input-group col-8 col-sm-3 col-lg-3">
          <button
            class="cb-cancel-button text-black-1"
            (click)="openSelectTemplateForm()"
            style="border: solid 1px #d6dce2; border-radius: 5px; font-size: 12px; font-weight: normal;"
            translate
          >
            Create_Document.Sel_template
          </button>
          <div class="col-12 error-mssg" *ngIf="submitted && !templateName">
            <label for="folder" class="text-danger" translate>* {{ templateErrorMessage }}</label>
          </div>
          <p
            class="col-12 error-mssg text-black-3"
            *ngIf="templateName"
            style="font-size: 14px; line-height: 18px; font-weight: 400;padding-top: 0.7em;"
            translate
          >
            Create_Document.Selected_template <strong> {{ templateName }}</strong>
          </p>
        </div>
      </div>
    </ng-container>
    <ng-template #normalLink>
      <div class="row mt-6">
        <div class="col-2 col-sm-2 col-lg-2">
          <label for="title" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate>Edit_Incident.Template</label>
        </div>
        <a
          class="ml-3 pt-2 mt-1 cb-label"
          style="text-decoration:underline;cursor: pointer;"
          target="_blank"
          [href]="incidentData?.document_store_identifier"
          >{{ templateName }}
        </a>
      </div>
    </ng-template>
  </div>
</form>
