<div>
  <div class="modal-header cb-modal-header">
    <h4 class="modal-title font-weight-bold text-black-1" translate>organisation_committee.Title</h4>
  </div>
  <div class="modal-body">
    <div class="col-12 error-mssg" *ngIf="error['error']">
      <label class="text-danger">*{{ error['error'] }}</label>
    </div>
    <form [formGroup]="addCommitteeForm">
      <div class="row mt-3">
        <div class="col-4">
          <label for="committee-name" class="text-black-1" translate
            >organisation_committee.add_organisation_committee.Name of Committee</label
          >
        </div>
        <div class="input-group col-8">
          <input
            id="committee-name"
            class="form-control cb-input"
            [placeholder]="'organisation_committee.add_organisation_committee.Enter the committee name' | translate"
            name="committeeName"
            type="text"
            [maxLength]="256"
            formControlName="name"
          />
          <div class="col-12 error-mssg" *ngIf="(f.name.touched || submitted) && f.name.errors">
            <label for="name" class="text-danger" *ngIf="f.name.errors.required" translate
              >organisation_committee.add_organisation_committee.NameRequired</label
            >
            <label for="name" class="text-danger" *ngIf="f.name.errors.maxLength" translate
              >Document_Invite_Training_Groups.Title must be 255 characters or less</label
            >
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <label for="charter" class="text-black-1" translate
            >organisation_committee.add_organisation_committee.Charter/Goal</label
          >
        </div>
        <div class="input-group col-8">
          <textarea
            class="form-control cb-input"
            [placeholder]="'organisation_committee.add_organisation_committee.committee_description' | translate"
            formControlName="description"
            [maxLength]="4097"
          >
          </textarea>
          <div class="col-12 error-mssg" *ngIf="(f.description.touched || submitted) && f.description.errors">
            <label for="description" class="text-danger" *ngIf="f.description.errors.required" translate
              >organisation_committee.add_organisation_committee.*Description is required.</label
            >
            <label for="description" class="text-danger" *ngIf="f.description.errors.maxLength" translate
              >Document_Invite_Training_Groups.Description must be 4096 characters or less</label
            >
          </div>
        </div>
      </div>
      <div class="row" style="padding-top: 20px;" *ngIf="newlyAddedMembersInCommittee.length > 0">
        <div class="col">
          <div class="table_wrapper mt-0 mx-1">
            <table class="w-100p table-2">
              <thead class="bg-yellow-2">
                <tr class="border-0">
                  <td>
                    <h4 class="font-weight-bold text-black-2 p-3 mt-2" translate>
                      organisation_committee.add_organisation_committee.Email
                    </h4>
                  </td>
                  <td>
                    <h4 class="font-weight-bold text-black-2 p-3 mt-2" translate>
                      organisation_committee.add_organisation_committee.Action
                    </h4>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let members of newlyAddedMembersInCommittee; let i = index" [attr.data-index]="i">
                  <td>
                    <h4 class="text-black-1 p-3 pr-3 w-240">{{ members }}</h4>
                  </td>
                  <td class="text-right">
                    <a (click)="removeMemberFromAddNewCommitteeForm(members)" class="mr-4 w-80 btn-small"
                      ><u translate>organisation_committee.add_organisation_committee.Remove</u></a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-4">
        <label
          (click)="addCommitteeParticipants()"
          for="charter"
          class="text-green-1 hide-add-people-from-committee"
          translate
          ><i class="fa fa-plus" aria-hidden="true"></i>organisation_committee.add_organisation_committee.Add
          participants</label
        >
      </div>
    </form>
  </div>
  <div class="modal-footer border-0 cb-modal-footer">
    <button class="btn-cancel ml-4 w-100" (click)="activeModal.close(false)" translate>
      organisation_committee.add_organisation_committee.Cancel
    </button>
    <button class="btn-save ml-4 w-100" *ngIf="!isLoading" (click)="save()" translate>
      organisation_committee.add_organisation_committee.Submit
    </button>
    <button class="btn-save ml-4 w-100" disabled *ngIf="isLoading">
      <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
    </button>
  </div>
</div>
