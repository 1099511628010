import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { OrgAdminSettingsShell, OrgAdminShell } from '@app/shells/orgAdminshell/orgAdminshell.service';
import { ProfileComponent } from '@app/settings/Profile/profile.component';
import { AdministratorComponent } from '@app/settings/Administrator/administrator.component';
import { CommitteesComponent } from '@app/settings/Committees/committees.component';
import { OperationComponent } from '@app/settings/Operations/operation.component';
import { CommunicationComponent } from '@app/settings/Communication/communication.component';
import { IntegrationsComponent } from '@app/settings/Integrations/integrations.component';
import { PeopleComponent } from '@app/settings/People/people.component';
import { CbAgentComponent } from '@app/settings/cb-agent/cb-agent.component';
import { EventsComponent as EC1 } from '@app/Organisation-admin/Events/events.component';
import { EventsComponent as EC2 } from '@app/settings/Events/events.component';
import { TrainingGroupsComponent } from './training-groups/training-groups.component';
import { CreateTrainingGroupComponent } from '@app/Organisation-admin/documents/create-training-group/create-training-group.component';
import { CreateCategoryComponent } from './create-category/create-category.component';
import { DocumentGroupsComponent } from './document-groups/document-groups.component';
import { IntegrationComponent } from './integration/integration.component';
import { ManageAccountComponent } from './integration/manage-account/manage-account.component';

const routes: Routes = [
  OrgAdminShell.childRoutes([
    OrgAdminSettingsShell.childRoutes([
      { path: 'administrator', component: AdministratorComponent, data: { title: extract('Administrator') } },
      { path: 'committees', component: CommitteesComponent, data: { title: extract('Committees') } },
      { path: 'operations', component: OperationComponent, data: { title: extract('Operations') } },
      { path: 'training', component: TrainingGroupsComponent, data: { title: extract('Training') } },
      { path: 'document-groups', component: DocumentGroupsComponent, data: { title: extract('Document-Groups') } },
      { path: 'category', component: CreateCategoryComponent, data: { title: extract('category') } },
      { path: 'communication', component: CommunicationComponent, data: { title: extract('Communication') } },
      { path: 'events2', component: EC1, data: { title: extract('Tasks') } },
      { path: 'events', component: EC2, data: { title: extract('Tasks'), is_editable: true } },
      // { path: 'integration', component: IntegrationsComponent, data: { title: extract('Integration') } },
      { path: 'integration', component: IntegrationComponent, data: { title: extract('Integration') } },
      { path: 'integration/manage-account', component: ManageAccountComponent, data: { title: 'Manage Account' } },
      { path: 'people', component: PeopleComponent, data: { title: extract('People') } },
      { path: '', redirectTo: 'profile', pathMatch: 'full' },
      { path: 'profile', component: ProfileComponent, data: { title: extract('Profile'), is_editable: false } },
      { path: 'cbagent', component: CbAgentComponent, data: { title: extract('cbagent'), is_editable: false } },
      {
        path: 'create-training-group',
        component: CreateTrainingGroupComponent,
        data: { title: extract('Create Training Group') }
      }
    ])
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class SettingsRoutingModule {}
