import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { extract } from '@app/core';
import { SignUpComponent } from './signup.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

const routes: Routes = [{ path: 'signup', component: SignUpComponent, data: { title: extract('SignUp') } }];

@NgModule({
  imports: [NgMultiSelectDropDownModule, RouterModule.forChild(routes), RecaptchaModule, RecaptchaFormsModule],
  exports: [RouterModule, NgMultiSelectDropDownModule, RecaptchaModule, RecaptchaFormsModule],
  providers: []
})
export class SignupRoutingModule {}
