<div class="onboarding-footer text-right">
  <button class="btn-cancel ml-4 w-100" (click)="back()" *ngIf="activeStep != 1" translate>
    onboard.onboard_footer.Back
  </button>
  <button
    routerLink="/organisation/events"
    class="btn-add ml-4 w-100"
    (click)="back()"
    *ngIf="activeStep == 1 && isOrganizationOnboarded"
    translate
  >
    onboard.onboard_footer.Home
  </button>
  <button class="btn-add ml-4 w-auto" (click)="run()" *ngIf="activeStep == 6" translate>
    onboard.onboard_footer.Run Selected
  </button>
  <button class="btn-save ml-4 w-100" (click)="next()" *ngIf="activeStep != 6 && activeStep != 7" translate>
    onboard.onboard_footer.Next
  </button>
  <button class="btn-save ml-4 w-100" (click)="finish()" *ngIf="activeStep == 6 || activeStep == 7" translate>
    onboard.onboard_footer.Finish
  </button>
</div>
