import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';

@Component({
  selector: 'app-start-training',
  templateUrl: './start-training.component.html',
  styleUrls: ['./start-training.component.scss']
})
export class StartTrainingComponent implements OnInit {
  submitted = false;
  isLoading = false;
  OrganisationID: string | null;
  guidSubscription: any;
  success: boolean;
  error: any;
  statusData: any;
  loading = true;

  @Input() formType: string;
  @Input() DocumentGuid: string;
  @Input() TrackingGuid: string;
  @Input() CampaignGuid: string;
  @Input() CampaignName: string;
  @Input() message: string;
  @Input() tracking: string;
  backend_error: string;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private documentsService: DocumentsService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
    });
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  changeName(statusData: any) {
    this.documentsService.setStatus(statusData);
  }

  save() {
    this.submitted = true;
    this.isLoading = true;
    let data = { guid: this.TrackingGuid };
    this.documentsService
      .submitStartTrainingData(this.OrganisationID, this.CampaignGuid, data)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.statusData = data;
          this.changeName(this.statusData);
          this.success = true;
          this.error = {
            remarks: ''
          };
          this.submitted = false;
          this.backend_error = '';
          this.activeModal.close(true);
        },
        (error: any) => {
          this.submitted = false;
          this.isLoading = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
  }
}
