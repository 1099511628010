import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  ChangeDetectorRef,
  ViewChildren,
  QueryList,
  HostListener
} from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '@app/core/modal/modal.service';
import { take } from 'rxjs/operators';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { WizardService } from '@app/home/wizard.services';
import { WebsocketService } from '@app/core/websocket.service';
import { OrganisationService } from '@app/core/organisation.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrganisationDashboardService } from '@app/Organisation-admin/orgDashboard/orgDashboard.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { EditDocumentDialogComponent } from './edit-document-dialog/edit-document-dialog.component';
import { Subscription, interval } from 'rxjs';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-generate-documents',
  templateUrl: './generate-documents.component.html',
  styleUrls: ['./generate-documents.component.scss']
})
export class GenerateDocumentsComponent implements OnInit {
  @ViewChild('panelsSide') panelsSide: ElementRef;
  @ViewChildren(MatTooltip) tooltips: QueryList<MatTooltip>;
  editingPolicyText: boolean[] = [];
  editingProcedureText: boolean[] = [];
  progress: number = 0;
  documentProgress: number = 0;
  totalDocument: any;
  spinner: boolean = true;
  completetick: boolean = false;
  documentTitle: string;
  isViewButton: boolean[] = [];
  disabledFinish: boolean = true;
  tabledatastorage: any;
  OrganisationID: string | null;
  orgGuidSubscription: any;
  pnpControlData: any[] = [];
  pnpPolicyData: any;
  pnpProcedureData: any;
  pnpControlDataResult: any;
  loading: boolean = true;
  private intervalSubscription: Subscription | undefined;
  currentlyExpandedCtrl: string | null = '';
  pnpStatus: any[] = [];
  items: number[];
  ngOnInit() {
    this.items = Array.from({ length: 20 }, () => Math.floor(Math.random() * 2) + 99);
    this.orgGuidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
      if (this.OrganisationID) {
        this.getOrganizationPnpData()
          .then(() => {
            this.getPnpStatus();
            this.intervalSubscription = interval(15000).subscribe(() => {
              this.getPnpStatus();
              this.manageProgress();
            });
          })

          // .then(() => {
          //   this.websocketService.getMessage().subscribe(data => {
          //     if (data.message) {
          //       data.message.forEach((control: any) => {
          //         this.pnpControlData.forEach((outerObj: any) => {
          //           const innerIndex = outerObj.rows.findIndex(
          //             (innerObj: any) => innerObj.code === control.org_program_unit_control.code
          //           );
          //           if (innerIndex !== -1) {
          //             if (control.policy_text_ai) {
          //               outerObj.rows[innerIndex].policy_text = control.policy_text_ai;
          //               outerObj.rows[innerIndex].title = control.policy_title_ai;
          //             } else if (control.procedure_text_ai) {
          //               outerObj.rows[innerIndex].procedure_text = control.procedure_text_ai;
          //             }
          //           }
          //         });
          //       });
          //     }
          //   });
          //   this.manageProgress();
          // })
          .catch((error: any) => {
            console.error('Error:', error);
          });
      }
    });
    setInterval(() => {
      this.progress = Math.floor(Math.random() * 101);
    }, 1000);
    setInterval(() => this.manageProgress(), 2000);
  }
  constructor(
    private modalService: ModalService,
    public wizardService: WizardService,
    public websocketService: WebsocketService,
    private orgService: OrganisationService,
    private orgDashboardService: OrganisationDashboardService,
    public dialog: MatDialog,
    private elRef: ElementRef,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnDestroy(): void {
    this.websocketService.close();
    this.intervalSubscription?.unsubscribe();
  }
  getOrganizationPnpData(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.wizardService.getOrganizationPnpData(this.OrganisationID, true).subscribe(
        data => {
          this.pnpControlDataResult = data.results;
          this.loading = false;

          // New organization structure based on categories
          const organizedData = {};
          data.results.forEach((item: any) => {
            const category = item.cb_common_ctrl_details.common_control.category_title;
            if (!organizedData[category]) {
              organizedData[category] = [];
            }

            // Updated structure to match new response
            organizedData[category].push({
              title: item.cb_common_ctrl_details.common_control.cc_name,
              code: item.cb_common_ctrl_details.common_control.cc_id,
              guid: item.guid,
              regulatoryText: item.cb_common_ctrl_details.control[0].regulatory_text,
              commonControl: {
                id: item.cb_common_ctrl_details.common_control.cc_id,
                name: item.cb_common_ctrl_details.common_control.cc_name,
                description: item.cb_common_ctrl_details.common_control.cc_description,
                objective: item.cb_common_ctrl_details.common_control.cc_objective
              }
            });
          });

          // Transform into array structure
          this.pnpControlData = Object.keys(organizedData).map(category => ({
            header: category,
            rows: organizedData[category].map((item: any) => {
              item.id = item.title.replace(/[\s\-.]/g, '');
              return item;
            })
          }));
          this.currentlyExpandedCtrl = this.pnpControlData[0]?.rows[0]?.code;
          this.wizardService.DocuementGenaratedTitles.next(this.pnpControlData);
          resolve();
        },
        (error: any) => {
          this.loading = false;
          console.log('Error:', error.error.message);
          reject(error);
        }
      );
    });
  }

  getOrganizationPnpPolicyData() {
    return new Promise<void>((resolve, reject) => {
      this.wizardService.getOrganizationPnpPolicyData(this.OrganisationID).subscribe(
        data => {
          this.pnpPolicyData = data;
          const policyTextMap = {};
          this.pnpPolicyData.forEach((item: any) => {
            policyTextMap[item.code] = item.policy_text_edited ? item.policy_text_edited : item.policy_text_ai;
          });
          this.pnpControlData.forEach((category: any) => {
            category.rows.forEach((row: any) => {
              const policyText = policyTextMap[row.code];
              row.policy_text = policyText ? policyText : '';
              row.isPolicyEditable = false;
              resolve();
            });
          });
        },
        (error: any) => {
          this.loading = false;
          console.log('Error:', error.error.message);
          reject(error);
        }
      );
    });
  }
  getOrganizationPnpProcedureData() {
    return new Promise<void>((resolve, reject) => {
      this.wizardService.getOrganizationPnpProcedureData(this.OrganisationID).subscribe(
        data => {
          this.pnpProcedureData = data;
          const procedureTextMap = {};
          this.pnpProcedureData.forEach((item: any) => {
            procedureTextMap[item.code] = item.procedure_text_edited
              ? item.procedure_text_edited
              : item.procedure_text_ai;
          });
          this.pnpControlData.forEach((category: any) => {
            category.rows.forEach((row: any) => {
              const procedureText = procedureTextMap[row.code];
              row.procedure_text = procedureText ? procedureText : '';
              row.isProcedureEditable = false;
              resolve();
            });
          });
        },
        (error: any) => {
          this.loading = false;
          console.log('Error:', error.error.message);
          reject(error);
        }
      );
    });
  }

  getPnpStatus() {
    if (this.OrganisationID) {
      this.wizardService.getPnpStatus(this.OrganisationID).subscribe(
        data => {
          if (data) {
            this.pnpStatus = data;
            // Helper function to find outerIndex and innerIndex
            const findIndices = (code: string): { outerIndex: number; innerIndex: number } | null => {
              for (let i = 0; i < this.pnpControlData.length; i++) {
                const foundInnerIndex = this.pnpControlData[i]?.rows?.findIndex((row: any) => row.code === code);
                if (foundInnerIndex !== -1) {
                  return { outerIndex: i, innerIndex: foundInnerIndex };
                }
              }
              return null;
            };
            // Find current control status
            const ctrlStatus = this.pnpStatus.find(item => item.code === this.currentlyExpandedCtrl);
            let indices = ctrlStatus ? findIndices(this.currentlyExpandedCtrl) : null;
            const shouldOpenPanel = (ctrl: any, row: any) =>
              ctrl?.code === row?.code &&
              !row?.procedure_text &&
              !row?.policy_text &&
              ctrl?.policy_status &&
              ctrl?.procedure_status;

            if (
              indices &&
              shouldOpenPanel(ctrlStatus, this.pnpControlData[indices.outerIndex]?.rows[indices.innerIndex])
            ) {
              this.onPanelOpened(this.pnpControlData[indices.outerIndex]?.rows[indices.innerIndex]);
            } else {
              const findCtrlStatus = this.pnpStatus.find(item => item.policy_status === item.procedure_status);
              if (findCtrlStatus) {
                indices = findIndices(findCtrlStatus.code);
                if (
                  indices &&
                  shouldOpenPanel(findCtrlStatus, this.pnpControlData[indices.outerIndex]?.rows[indices.innerIndex])
                ) {
                  this.onPanelOpened(this.pnpControlData[indices.outerIndex]?.rows[indices.innerIndex], true);
                }
              }
            }
          }
        },
        (error: any) => {
          this.loading = false;
          console.log('Error:', error.error.message);
        }
      );
    }
  }

  isGeneratedCategoryCtrls(category: any) {
    return category.rows.every((row: any) => {
      const statusObj = this.pnpStatus.find(obj => obj.code === row.code);
      return statusObj && statusObj.policy_status && statusObj.procedure_status;
    });
  }

  isGeneratedCategoryCtrlsCount(category: any) {
    let count = 0;
    category.rows.forEach((row: any) => {
      const statusObj = this.pnpStatus.find(obj => obj.code === row.code);
      if (statusObj && statusObj.policy_status && statusObj.procedure_status) {
        count++;
      }
    });
    return count;
  }

  isGeneratedCtrl(code: string): boolean {
    const ctrl = this.pnpStatus.find(item => item.code === code);
    if (ctrl) {
      if (ctrl.policy_status && ctrl.procedure_status) {
        return true;
      }
    }
    return false;
  }

  filterGeneratedCtrl(rows: any) {
    return rows.filter((row: any) => {
      const statusObj = this.pnpStatus.find(obj => obj.code === row.code);
      return statusObj && statusObj.policy_status && statusObj.procedure_status;
    });
  }

  manageProgress() {
    this.totalDocument = this.pnpControlData?.reduce((total: any, data: any) => total + data.rows.length, 0);
    const filteredCtrls = [];
    this.pnpControlData.forEach(category => {
      filteredCtrls.push(...this.filterGeneratedCtrl(category.rows));
    });
    this.documentProgress = filteredCtrls?.length;
    if ((this.documentProgress / this.totalDocument) * 100 == 100) {
      this.disabledFinish = false;
      this.intervalSubscription.unsubscribe();
      if (this.tooltips && this.tooltips.length > 0) {
        this.cdr?.detectChanges();
        this.tooltips.forEach(tooltip => {
          tooltip.hide();
        });
      }
    }
  }

  get progressPercentage() {
    return (this.documentProgress / this.totalDocument) * 100;
  }

  openDialog(row: any, key: string) {
    row[key] = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(EditDocumentDialogComponent, {
      data: { row: row },
      height: '52rem',
      width: '85rem',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  onPanelOpened(row: any, isCallMenu: boolean = false) {
    this.setItems();
    this.currentlyExpandedCtrl = row.code;
    this.wizardService.getSpecificPolicyProcedure(this.OrganisationID, row?.guid).subscribe(
      data => {
        if (data) {
          row.procedure_text = data.procedure[0]?.procedure_text_edited
            ? data.procedure[0]?.procedure_text_edited
            : data.procedure[0]?.procedure_text_ai;
          row.policy_text = data.policy[0]?.policy_text_edited
            ? data.policy[0]?.policy_text_edited
            : data.policy[0]?.policy_text_ai;

          if (data.procedure?.length > 0 && data.policy?.length > 0) {
            row['isRestoreProcedureDisabled'] = data.procedure[0]?.procedure_text_edited ? false : true;
            row['isRestorePolicyDisabled'] = data.policy[0]?.policy_text_edited ? false : true;
          } else {
            row['isRestoreProcedureDisabled'] = false;
            row['isRestorePolicyDisabled'] = false;
          }

          row['policy'] = {
            id: data.policy[0]?.id,
            org_program_unit_control: data.policy[0]?.org_program_unit_control
          };
          row['procedure'] = {
            id: data.procedure[0]?.id,
            org_program_unit_control: data.procedure[0]?.org_program_unit_control
          };
          if (isCallMenu) {
            // if (this.currentlyExpandedCtrl) {
            //   setTimeout(() => {
            //     this.tooltips.forEach(tooltip => {
            //       tooltip.show();
            //     });
            //   }, 5000);
            // }
            this.onMenuClicked(row);
          }
        }
      },
      (error: any) => {
        this.loading = false;
        console.log('Error:', error.error.message);
      }
    );
  }

  onMenuClicked(row: any) {
    this.currentlyExpandedCtrl = row.code;
    setTimeout(() => {
      this.scrollToElement(row.id);
    }, 400);
  }

  // isTooltipDisabled(): boolean {
  //   return this.documentProgress === this.totalDocument;
  // }

  // @HostListener('scroll', ['$event'])
  // onScroll(event: Event) {
  //   if (this.tooltips && this.tooltips.length > 0) {
  //     this.cdr?.detectChanges();
  //     this.tooltips.forEach(tooltip => {
  //       tooltip.hide();
  //     });
  //   }
  // }

  scrollToElement(id: string): void {
    const element = this.elRef.nativeElement.querySelector('#' + id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  setItems() {
    this.items = Array.from({ length: 20 }, () => Math.floor(Math.random() * 2) + 99);
    this.cdr.detectChanges();
  }
}
