import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { OrganisationService } from '@app/core/organisation.service';
import { WizardService } from '@app/home/wizard.services';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { OrganisationDashboardService } from '@app/Organisation-admin/orgDashboard/orgDashboard.service';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { CbLiteHeaderComponent } from '../cb-lite-header/cb-lite-header.component';

@Component({
  selector: 'app-quick-survey',
  templateUrl: './quick-survey.component.html',
  styleUrls: ['./quick-survey.component.scss']
})
export class QuickSurveyComponent implements OnInit {
  isOtherOptionSelected: boolean[] = [];
  isOtherOptionChecked: boolean[] = [];
  orgAssesment: any[] = [];
  groupedMultiquestions: any;
  otherOptionText: string = '';
  OrganisationID: string | null;
  orgGuidSubscription: any;
  loading: boolean = true;
  disabledSaveContinue: boolean = false;
  answerTextValue: any;
  answerText: any;
  answeredQuestions: number = 0;
  otherAns: string;
  backend_error: string;
  orgAssesAnsProgrssBar: any[] = [];
  disabledStepper: boolean = false;
  updatedAssement: boolean = false;
  organiationTypes: any[] = [];
  selectedOrgType: string = '';
  defaultOrgType: any;
  checkComplianceProgramType: boolean = false;
  orgTypeData: any;
  programMapper = {
    'Business Associate': 'Business Associate',
    Provider: 'Covered Entity - Provider',
    Payer: 'Covered Entity - Payer',
    'Healthcare Clearinghouse': 'Covered Entity - Healthcare Clearinghouse'
  };
  programTooltipMapper = {
    'Business Associate':
      'Entities that provide services to Covered Entities and handle Protected Health Information (PHI) in the course of their work. Examples include consultants, billing companies, and legal firms.',
    Provider:
      "Healthcare providers that transmit any health information in electronic form in connection with transactions covered by HIPAA. This includes hospitals, clinics, and doctors' offices.",
    Payer:
      'Entities that process health insurance claims and/or health information in electronic form. Examples include health insurance companies, Medicare, and Medicaid.',
    'Healthcare Clearinghouse':
      'Entities that process nonstandard health information into standard, uniform electronic formats. They may also aggregate health information received from other entities. Examples include billing services and community health management information systems.'
  };
  constructor(
    private wizardService: WizardService,
    private orgService: OrganisationService,
    private backendErrorService: BackendErrorService,
    private toaster: ToasterService,
    private router: Router,
    private orgDashboardService: OrganisationDashboardService
  ) {}

  ngOnInit(): void {
    this.orgGuidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
    });
    if (this.OrganisationID) {
      this.getOrganisationType();
    }
  }

  getOrganisationType() {
    this.orgDashboardService.getOrganizationType(this.OrganisationID).subscribe(res => {
      this.organiationTypes = res;
      this.organiationTypes.forEach((item: any) => {
        item['tooltip'] = this.programTooltipMapper[item['title']];
        item['title'] = this.programMapper[item['title']];
      });
      this.defaultOrgType = this.organiationTypes.find((org: any) => org.title === 'Business Associate');
      if (!this.selectedOrgType && this.defaultOrgType) {
        this.selectedOrgType = this.defaultOrgType.guid;
      }
      const compliancePrograms = JSON.parse(localStorage.getItem('compliance_programs'));
      const isHIPAAComplianceProgram = compliancePrograms.some((program: any) => program.name === 'HIPAA');
      this.checkComplianceProgramType = isHIPAAComplianceProgram;
      this.getOrganizationAssement();
    });
  }

  getOrganizationAssement(): void {
    this.wizardService.getOrganizationAssement(this.OrganisationID).subscribe(
      data => {
        this.loading = false;

        this.orgAssesment = data.results;
        this.selectedOrgType = data.org_type?.guid || this.defaultOrgType?.guid;

        if (this.orgAssesment) {
          if (localStorage.getItem('org_assessment_completed') === 'true') {
            this.disabledStepper = false;
          } else {
            this.disabledStepper = true;
          }
        }
        if (this.orgAssesment?.length > 0) {
          this.disabledSaveContinue = true;
        } else {
          // this.updateOrgAssesment();
          this.disabledSaveContinue = false;
        }
        this.orgAssesment.forEach((item: any) => {
          // //To test outer questons other option
          // item.question_details.options.push({id:item.question_details.options.length+1,opt:'Other'})

          item['isOtherActive'] =
            typeof this.isArraySubset(item.question_details.options, item?.answer_text?.options) == 'string'
              ? true
              : false;
          if (item.isOtherActive) {
            item['otherOption'] = this.isArraySubset(item.question_details.options, item?.answer_text?.options);
          } else {
            item['otherOption'] = '';
          }
          if (item.answer_text == null) {
            item.answer_text = [];
            item['answers'] = [];
          } else {
            item['answers'] = item?.answer_text?.options ? item.answer_text.options : [];
          }
          if (
            item.question_details.best_response.length &&
            item.question_details.best_response.length > 0 &&
            this.disabledStepper
          ) {
            if (item.answers.length > 0) {
              item.question_details.best_response.forEach((best_response: string) => {
                const bstResOpt = item.question_details.options.find((option: any) => option.opt == best_response);
                if (!item.answers.find((eachAns: any) => eachAns.opt == bstResOpt.opt)) {
                  item['answers'].push(bstResOpt);
                }
              });
            } else {
              item.question_details.best_response.forEach((best_response: string) => {
                const bstResOpt = item.question_details.options.find((option: any) => option.opt == best_response);
                if (bstResOpt) {
                  item['answers'].push(bstResOpt);
                }
              });
            }
          }
          if (item.question_details.follow_up && item.question_details.follow_up.Yes.length > 0) {
            item.question_details.follow_up.Yes.forEach((element: any) => {
              const question = item?.answer_text?.follow_up?.Yes?.find(
                (eachQuestion: any) => eachQuestion.question == element.question
              );
              if (element.type == 'multiple_choice') {
                element['answers'] = question?.options ? question?.options : [];
              }
              if (element.type == 'text') {
                element['answers'] = question?.options ? question?.options : [''];
              }

              //To test inner questons other option
              // if (element.hasOwnProperty('options')) {
              //   element.options.push({id:element.options.length+1,opt:'Other'});
              // }

              if (element) {
                element['isOtherActive'] =
                  typeof this.isArraySubset(element?.options, element['answers']) == 'string' ? true : false;
                if (element.isOtherActive) {
                  element['otherOption'] = this.isArraySubset(element?.options, element['answers']);
                } else {
                  element['otherOption'] = '';
                }

                if (element?.best_response?.length && element?.best_response?.length > 0 && this.disabledStepper) {
                  if (element.answers.length > 0) {
                    element?.best_response.forEach((best_response: string) => {
                      const bstResOpt = element.options.find((option: any) => option.opt == best_response);
                      if (!element.answers.find((eachAns: any) => eachAns.opt == bstResOpt.opt)) {
                        element['answers'].push(bstResOpt);
                      }
                    });
                  } else {
                    element?.best_response.forEach((best_response: string) => {
                      const bstResOpt = element.options.find((option: any) => option.opt == best_response);
                      if (bstResOpt) {
                        element['answers'].push(bstResOpt);
                      }
                    });
                  }
                }
              }
            });
          }
        });
      },
      (error: any) => {
        this.loading = false;
        console.log('Error:', error.error.message);
      }
    );
  }

  isArraySubset(arr1: any[], arr2: any[]) {
    for (let i = 0; i < arr2?.length; i++) {
      const elementToLower = arr2[i]?.opt?.toLowerCase();
      if (arr1?.findIndex(el => el?.opt?.toLowerCase() === elementToLower) === -1) {
        return arr2[i].opt;
      }
    }
    return true;
  }

  getAlphabet(index: number): string {
    const alphabetArray: string[] = 'abcdefghijklmnopqrstuvwxyz'.split('');
    const alphabetIndex = index % 26;
    return alphabetArray[alphabetIndex];
  }

  updateOrgAssesment() {
    let payload: any = {
      org_type: this.selectedOrgType,
      assessment: []
    };
    // console.log('orgAssesment', this.orgAssesment);
    this.orgAssesment.forEach((eachQuestion: any) => {
      const otherOpt = eachQuestion.answers.find((answer: any) => answer?.opt?.toLowerCase() === 'other');
      if (otherOpt) {
        otherOpt.opt = eachQuestion.otherOption;
      }
      let obj: any = {
        question: eachQuestion.guid,
        answer_text: {
          options: eachQuestion.answers,
          question: eachQuestion.question_details.question
        },
        control_unit: null,
        organization: this.OrganisationID
      };
      if (eachQuestion?.question_details?.follow_up?.Yes && eachQuestion?.answers[0]?.opt?.toLowerCase() === 'yes') {
        let subQuestions: any = [];
        eachQuestion.question_details.follow_up.Yes.forEach((subQuestion: any) => {
          const otherOpt = subQuestion?.answers?.find((answer: any) => answer?.opt?.toLowerCase() === 'other');
          if (otherOpt) {
            otherOpt.opt = subQuestion.otherOption;
          }
          subQuestions.push({
            question: subQuestion.question,
            options: subQuestion.answers
          });
        });
        obj.answer_text['follow_up'] = {
          Yes: subQuestions
        };
      }
      payload.assessment.push(obj);
    });
    // console.log('payload', payload);
    this.disabledSaveContinue = true;
    this.loading = true;
    this.wizardService.updateOrganizationAssement(this.OrganisationID, payload).subscribe(
      response => {
        this.loading = false;
        this.disabledSaveContinue = false;
        this.updatedAssement = true;
        localStorage.setItem('org_assessment_completed', 'true');
        this.toaster.showSuccess('Organization answers saved successfully .');
      },
      (error: any) => {
        this.disabledSaveContinue = true;
        this.loading = false;
        this.backendErrorService.setBackendError(error);
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
          this.toaster.showError(this.backend_error);
        });
      }
    );
  }
  onselectedOrgTypeChange(guid: any) {
    this.selectedOrgType = guid;
  }
  getselectedOrgType(guid: any) {
    return guid == this.selectedOrgType ? true : false;
  }
  validateAnswers(questions: any) {
    if (this.checkComplianceProgramType && !this.selectedOrgType) {
      return true;
    }
    for (let question of questions) {
      if (
        !question.answers ||
        question.answers.length === 0 ||
        (question.isOtherActive && !question.otherOption?.trim())
      ) {
        return true;
      }
      if (question.question_details.type === 'nested_questions' && question.answers[0].opt === 'Yes') {
        if (question.question_details.follow_up && question.question_details.follow_up.Yes) {
          for (let nestedQuestion of question.question_details.follow_up.Yes) {
            if (
              !nestedQuestion.answers ||
              nestedQuestion.answers.length === 0 ||
              (nestedQuestion.isOtherActive && !nestedQuestion.otherOption?.trim())
            ) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  isOptionSelected(option: any, answers: any[]): boolean {
    return answers?.find(answer => answer.opt == option.opt);
  }

  onCheckboxChange(event: any, option: any, group: any) {
    if (event.checked) {
      if (option?.opt?.toLowerCase() === 'other') {
        group.isOtherActive = true;
      }
      if (group.answers == null) {
        group.answers = [];
      }
      group.answers.push(option);
    } else {
      if (option?.opt?.toLowerCase() === 'other') {
        group.isOtherActive = false;
        group.otherOption = '';
      }
      const index = group?.answers?.findIndex((answer: any) => answer.opt == option.opt);
      if (index !== -1) {
        group.answers.splice(index, 1);
      }
    }
  }

  onRadioChange(event: any, option: any, group: any) {
    console.log('event?.value', event?.value, option);
    if (event?.value?.toLowerCase() === 'other') {
      group.isOtherActive = true;
    } else {
      group.isOtherActive = false;
      group.otherOption = '';
    }
    group.answers = [option];
  }
}
