import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { SidenavService } from '@app/core/modal/sidenav.service';
import { OrganisationService } from '@app/core/organisation.service';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-service-provider-integration',
  templateUrl: './service-provider-integration.component.html',
  styleUrls: ['./service-provider-integration.component.scss']
})
export class ServiceProviderIntegrationComponent implements OnInit {
  arnValue: string;
  error: string;
  isIntegrated: boolean = true;
  serviceProvider: any;
  reConfig: boolean;
  accountDetails: any;
  scheduleChoices: any[] = [];
  OrganisationID: string | null;
  orgGuidSubscription: any;
  loading: boolean = false;
  isTestConnection: boolean = false;
  isSubmitting: boolean = false;
  accountName: any;
  accessKey: any;
  secretAccessKey: any;
  syncType: any;
  integrationForm: FormGroup;
  testConnRes: any;
  isShowSecretAccessKey: boolean = false;
  jsonFileName: string | null = null;
  file: any;
  jsonUploaded: boolean = false;
  scheduleTimeMsg: string = '';
  scheduleTime = 'DAILY';
  changeJson: boolean = false;
  connectionLinkDetails: any;

  constructor(
    private orgService: OrganisationService,
    private sidenavService: SidenavService,
    private documentsService: DocumentsService,
    private fb: FormBuilder,
    private toaster: ToasterService,
    private backendErrorService: BackendErrorService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.integrationForm = this.fb.group({
      accountName: ['', [Validators.required, Validators.maxLength(255)]],
      syncType: ['automatic', Validators.required],
      accessKey: ['', Validators.required],
      secretAccessKey: ['', Validators.required],
      time: ['DAILY']
    });
    this.orgGuidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
    });
    if (this.OrganisationID) {
      this.getScheduleChoices();
    }
    this.serviceProvider = JSON.parse(localStorage.getItem('activeServiceProvider'));
    if (this.serviceProvider?.description?.short_name?.toLowerCase() == 'gcp') {
      this.integrationForm.get('accessKey').clearValidators();
      this.integrationForm.get('secretAccessKey').clearValidators();
    }
    this.sidenavService.ServiceProviderAccountDetails.subscribe(account => {
      this.accountDetails = account;
      if (this.accountDetails) {
        this.reConfig = true;
      } else {
        this.reConfig = false;
      }
    });
    if (this.accountDetails) {
      this.getConnectionDetails();
    }
    if (this.integrationForm.get('syncType').value == 'automatic') {
      this.getRunSchedule();
    }
    this.documentsService.cloudProvidersSubject.subscribe(
      (cloudProviders: { active_account: any[]; available_account: any[] }) => {
        const shortName = this.serviceProvider?.description?.short_name;

        if (shortName) {
          const activeAccount = cloudProviders.active_account?.find(
            (account: any) => account.description.short_name === shortName
          );
          if (activeAccount) {
            this.connectionLinkDetails = activeAccount;
          } else {
            const availableAccount = cloudProviders.available_account?.find(
              (account: any) => account.description.short_name === shortName
            );
            if (availableAccount) {
              this.connectionLinkDetails = availableAccount;
            } else {
              this.connectionLinkDetails = null;
            }
          }
        }
      }
    );
  }

  get f() {
    return this.integrationForm.controls;
  }

  getScheduleChoices() {
    this.documentsService.getScheduleChoices().subscribe(res => {
      this.scheduleChoices = res;
    });
  }

  getConnectionDetails() {
    this.documentsService.getConnectionDetails(this.OrganisationID, this.accountDetails?.guid).subscribe(res => {
      if (res) {
        this.integrationForm.patchValue({
          accountName: res?.name,
          syncType: res?.sync_type,
          accessKey: res?.cloud_config?.access_key,
          secretAccessKey: res?.cloud_config?.secret_access_key,
          time: res?.schedule
        });
        if (this.integrationForm.get('syncType').value == 'automatic') {
          this.getRunSchedule();
        }
      }
    });
  }

  closeNav() {
    this.sidenavService.ServiceProviderAccountDetails.next(null);
    this.sidenavService.close();
  }

  onSubmit(): void {
    if (this.integrationForm.valid) {
      this.reConfig ? this.reconfigServiceProvider() : this.integrateServiceProvider();
    }
  }

  integrateServiceProvider() {
    this.isSubmitting = true;
    let payload = {
      name: this.integrationForm.get('accountName').value,
      access_key: this.integrationForm.get('accessKey').value,
      secret_access_key: this.integrationForm.get('secretAccessKey').value,
      connector_type: this.serviceProvider?.guid,
      sync_type: this.integrationForm.get('syncType').value,
      run_schedule: this.integrationForm.get('time').value
    };
    if (this.serviceProvider?.description?.short_name?.toLowerCase() == 'gcp') {
      delete payload.access_key;
      delete payload.secret_access_key;
    }
    if (this.integrationForm.get('syncType').value !== 'automatic') {
      payload.run_schedule = '';
    }
    this.loading = true;
    this.documentsService.integrateServiceProvider(this.OrganisationID, payload, this.file).subscribe(
      res => {
        this.toaster.showSuccess('Account integrated successfully');
        this.loading = false;
        this.isSubmitting = false;
        this.sidenavService.updateData({ isUpdate: true, result: res });
        this.closeNav();
      },
      error => {
        this.loading = false;
        this.isSubmitting = false;
        this.backendErrorService.setBackendError(error);
        this.backendErrorService.backendError$.subscribe(error => {
          this.translate.get(error).subscribe(res => {
            this.toaster.showError(res);
          });
        });
      }
    );
  }

  reconfigServiceProvider() {
    this.isSubmitting = true;
    let payload = {
      name: this.integrationForm.get('accountName').value,
      access_key: this.integrationForm.get('accessKey').value,
      secret_access_key: this.integrationForm.get('secretAccessKey').value,
      connector_type: this.serviceProvider?.guid,
      sync_type: this.integrationForm.get('syncType').value,
      run_schedule: this.integrationForm.get('time').value
    };
    if (this.serviceProvider?.description?.short_name?.toLowerCase() == 'gcp') {
      delete payload.access_key;
      delete payload.secret_access_key;
    }
    if (this.integrationForm.get('syncType').value !== 'automatic') {
      payload.run_schedule = '';
    }
    if (this.integrationForm.get('syncType').value == 'automatic') {
      this.getRunSchedule();
    }
    this.loading = true;
    this.documentsService
      .reconfigServiceProvider(this.OrganisationID, payload, this.accountDetails?.guid, this.file)
      .subscribe(
        res => {
          this.toaster.showSuccess('Account reconfigured successfully');
          this.loading = false;
          this.isSubmitting = false;
          this.sidenavService.updateData({ isUpdate: true, result: res });
          this.closeNav();
        },
        error => {
          this.loading = false;
          this.isSubmitting = false;
          this.backendErrorService.setBackendError(error);
          this.backendErrorService.backendError$.subscribe(error => {
            this.translate.get(error).subscribe(res => {
              this.toaster.showError(res);
            });
          });
        }
      );
  }

  testServiceProviderConnection() {
    if (this.serviceProvider?.description?.short_name?.toLowerCase() == 'aws') {
      this.testAwsConnection();
    } else {
      this.testGcpConnection();
    }
  }
  testAwsConnection() {
    this.isTestConnection = true;
    let payload = {
      access_key: this.integrationForm.get('accessKey').value,
      secret_access_key: this.integrationForm.get('secretAccessKey').value
    };
    this.loading = true;
    this.documentsService.testServiceProviderConnection(this.OrganisationID, payload).subscribe(
      res => {
        this.testConnRes = res;
        this.changeJson = true;
        this.loading = false;
        this.isTestConnection = false;
      },
      error => {
        this.loading = false;
        this.isTestConnection = false;
        this.backendErrorService.setBackendError(error);
        this.backendErrorService.backendError$.subscribe(error => {
          this.translate.get(error).subscribe(res => {
            this.toaster.showError(res);
          });
        });
      }
    );
  }

  testGcpConnection() {
    if (this.file) {
      this.loading = true;
      this.isTestConnection = true;
      this.documentsService.uploadJsonFile(this.OrganisationID, this.file).subscribe(
        res => {
          this.testConnRes = res;
          this.loading = false;
          this.changeJson = true;
          this.isTestConnection = false;
        },
        error => {
          this.loading = false;
          this.isTestConnection = false;
          this.backendErrorService.setBackendError(error);
          this.backendErrorService.backendError$.subscribe(error => {
            this.translate.get(error).subscribe(res => {
              this.toaster.showError(res);
            });
          });
        }
      );
    } else {
      this.toaster.showError('Upload a JSON file to test the connection.');
    }
  }

  copyInputMessage(inputElement: any) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }
  copyLink(link: string): void {
    if (link) {
      navigator.clipboard.writeText(link).then(
        () => {
          console.log('Link copied to clipboard!');
        },
        err => {
          console.error('Failed to copy the link: ', err);
        }
      );
    }
  }

  triggerFileInput() {
    const fileInput = document.getElementById('jsonFile') as HTMLInputElement;
    fileInput.click();
  }

  onFileChange(event: Event) {
    this.changeJson = !this.changeJson;
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.jsonUploaded = true;
      this.jsonFileName = input.files[0].name;
      this.file = input.files[0];
    }
  }

  getRunSchedule() {
    this.documentsService
      .getRunSchedule(this.OrganisationID, this.integrationForm.get('time')?.value)
      .subscribe(res => {
        if (res) {
          this.scheduleTimeMsg = res?.next_run_time;
        }
      });
    let found = this.scheduleChoices.find((schedule: any) => schedule.key == this.integrationForm.get('time').value);
    if (found) {
      this.scheduleTime = found?.value;
    }
  }
}
