import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { OrganisationService } from '@app/core/organisation.service';
import { SettingsService } from '@app/settings/settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '@app/core/modal/modal.service';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { take, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-training-groups',
  templateUrl: './training-groups.component.html',
  styleUrls: ['./training-groups.component.scss']
})
export class TrainingGroupsComponent implements OnInit, OnDestroy {
  @Input() event: object;
  OrganisationID: string | null;
  error: any;
  guidSubscription: any;
  trainingGroupEmailList: Array<any> = [];
  docTitle: object[];
  accArray: any[] = [];
  roles = {
    organization_user_role: 'User',
    organization_admin_role: 'Administrator'
  };
  key: any;
  close = false;
  key2: any;
  showDetails = false;
  showSecondDetails = false;
  NotificationForm: FormGroup;
  submitted = false;
  groupName: string;
  groupDescription: string;
  updatedGuid: any;
  loading = true;
  TrainingGroupList: Array<any> = [];
  TrainingGroupUserList: any = {};
  trainingGroupListCopy: Array<any> = [];
  TrainingGroupUserListCopy: Array<any> = [];
  createdBy: any;
  history_data: Array<any> = [];
  TrainingGroupUsers: object[];
  userGuid: any;
  groupguid: any;
  email: any;
  editing: boolean = false;
  role: any;
  userRole: any;
  paginatedDataUrl: any;
  @Input() is_editable = true;
  form = {
    description: { data: '', edit: false, error: '' }
  };
  scrollLoader = false;
  fetch_users = false;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  page = 1;
  pagnationData: any;
  groupEmails: any;
  groupObj: any;
  public descriptionFieldsValues: string[] = [];

  constructor(
    private orgService: OrganisationService,
    private settingsService: SettingsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private translate: TranslateService,
    private toaster: ToasterService
  ) {}

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getTrainingGroupList();
      this.getRole();
    });
  }

  onScrollUp() {
    this.scrollLoader = false;
  }

  onScrollData() {
    this.scrollLoader = true;
    this.fetch_users = false;
    this.settingsService
      .getTrainingGroupListWithPagination(this.OrganisationID, (this.page += 1), this.fetch_users)
      .subscribe(
        data => {
          this.scrollLoader = false;
          this.pagnationData = data.results;
          this.paginatedDataUrl = data;
          this.TrainingGroupList = this.TrainingGroupList.concat(data.results);
        },
        error => {
          this.scrollLoader = false;
          this.error = error.error.message;
        }
      );
  }
  onScroll() {
    if (this.paginatedDataUrl?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }

  getRole() {
    this.role = JSON.parse(localStorage.getItem('credentials'));
    this.userRole = this.role['user']['role'];
    for (let i = 0; i < 10; i++) {
      if (this.userRole[i] == 'organization_admin_role' || this.userRole[i] == 'document_author') {
        this.key = this.userRole[i];
      }
    }
  }

  private getTrainingGroupList(): void {
    this.page = 1;
    if (this.OrganisationID != null) {
      this.settingsService
        .getTrainingGroupListWithPagination(this.OrganisationID, this.page, this.fetch_users)
        .subscribe(
          data => {
            this.paginatedDataUrl = data;
            if (this.page > 1) {
              this.TrainingGroupList = this.TrainingGroupList.concat(
                data.results.filter((data: any) => this.TrainingGroupList.every(item => item['guid'] !== data['guid']))
              );
            } else {
              this.TrainingGroupList = JSON.parse(JSON.stringify(data.results));
            }
            this.loading = false;
            this.trainingGroupListCopy = JSON.parse(JSON.stringify(this.TrainingGroupList));
            for (let i = 0; i < this.TrainingGroupList.length; i++) {
              this.TrainingGroupList[i].edit = false;
            }

            this.loading = false;
          },
          error => {
            this.error = error.error.message;
            this.loading = false;
          }
        );
    }
  }

  openAddUser(groupObj: any) {
    this.addUserModal(groupObj);
  }

  resetField(field_name: string, index: any) {
    this.TrainingGroupList[index]['description'] = this.trainingGroupListCopy[index]['description'];
    if (field_name == this.TrainingGroupList[index]['description']) {
      this.form['description'].edit = !this.form['description'].edit;
    }
  }

  addTrainingGroup() {
    this.modalService
      .custom(CustomDialogComponent, { title: 'Create Training Group', modalType: 'training group' })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getTrainingGroupList();
        }
      });
  }

  submitPartForm(description: any, groupguid: any) {
    this.edit(description, groupguid);
  }

  edit(element: any, groupguid: any) {
    const data = { description: element };
    this.settingsService.updateTrainingGroupTitle(this.OrganisationID, groupguid, data).subscribe(
      data => {
        this.getTrainingGroupList();
        this.form.description.edit = !this.form.description.edit;
        this.translate.get('Training_Page.Description_edited').subscribe(res => {
          this.toaster.showSuccess(res);
        });
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }

  addUserModal(groupObj: any): void {
    this.groupObj = groupObj;
    if (this.TrainingGroupUserList[this.groupObj.guid]) {
      this.groupEmails = this.TrainingGroupUserList[this.groupObj.guid].map((x: any) => x.email);
      this.addUserModalCopy(groupObj);
    } else {
      this.fetch_users = true;
      this.settingsService
        .getTrainingGroupUserList(this.OrganisationID, this.fetch_users, [groupObj.guid], this.page)
        .subscribe(data => {
          this.groupEmails = data[0]['users'].map((x: any) => x.email);
          this.addUserModalCopy(groupObj);
        });
    }
  }
  addUserModalCopy(groupObj: any) {
    this.modalService
      .custom(
        CustomDialogComponent,
        {
          title: 'Add Group User',
          message: 'Are you sure you want to Add user to training group.',
          modalType: 'Add Group User',
          guid: groupObj.guid,
          groupName: groupObj.name,
          groupEmails: this.groupEmails
        },
        { windowClass: 'add-admin' }
      )
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          for (let i = 0; i < this.TrainingGroupList.length; i++) {
            if (groupObj.guid == this.TrainingGroupList[i]['guid']) {
              this.TrainingGroupList[i] = result.result;
              this.fetch_users = true;
              this.settingsService
                .getTrainingGroupUserList(this.OrganisationID, this.fetch_users, [groupObj.guid], this.page)
                .subscribe(data => {
                  this.TrainingGroupUserList[groupObj.guid] = data[0]['users'];
                });
              break;
            }
          }
        }
      });
  }
  goToCreateForms() {
    this.router.navigate(['organisation/settings/create-training-group']);
  }

  toggleDetails(index: any, clickedGuid: any): void {
    if (this.accArray.includes(index)) {
      const pos = this.accArray.indexOf(index);
      if (index > -1) {
        this.accArray.splice(pos, 1);
      }
    } else {
      this.accArray.push(index);
      for (let i = 0; i <= this.TrainingGroupList.length; i++) {
        if (clickedGuid == this.TrainingGroupList[i]['guid']) {
          this.fetch_users = true;
          this.settingsService
            .getTrainingGroupUserList(this.OrganisationID, this.fetch_users, [clickedGuid], this.page)
            .subscribe(data => {
              this.TrainingGroupUserList[clickedGuid] = data[0]['users'];
            });
          break;
        }
      }
    }
  }
}
