import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { OrganisationService } from '@app/core/organisation.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { SettingsService } from '@app/settings/settings.service';
import { ExpandedCertificateModalComponent } from './expanded-certificate-modal/expanded-certificate-modal.component';

@Component({
  selector: 'app-download-certificate-modal',
  templateUrl: './download-certificate-modal.component.html',
  styleUrls: ['./download-certificate-modal.component.scss']
})
export class DownloadCertificateModalComponent implements OnInit, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<DownloadCertificateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = {},
    private settingsService: SettingsService,
    private orgService: OrganisationService,
    private dialog: MatDialog
  ) {}

  @ViewChild('callAPIDialog') callAPIDialog: TemplateRef<any>;
  orgGuidSubscription: any;
  orgGuid: string | null;
  isSignatureLoading = false;
  loading = false;
  isDownloading = false;
  file: any;
  size: any;
  file_type: any;
  file_type_error: boolean = false;
  file_size_error: boolean = false;
  signature: any;
  userName: any;
  frameworkString: string = '';
  certificateData: any = {};
  fonts = ['italic 32px Pacifico', 'italic 32px Yellowtail', 'italic 32px Damion', 'italic 32px Lobster'];
  UserSignature: any = null;
  documentListItems: any = [];
  ngOnInit(): void {
    this.orgGuidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.orgGuid = guid;
      this.getCertificateDetails();
    });
    this.getUserSignature();
  }
  generatePdf(certificate: string) {
    const data = document.getElementById(`${certificate}-certificate-content`);
    if (data) {
      html2canvas(data).then(canvas => {
        const imgWidth = 208;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const contentDataURL = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const marginLeft = 2;
        const position = (pageHeight - imgHeight) / 2;
        pdf.addImage(contentDataURL, 'PNG', marginLeft, position, imgWidth - marginLeft, imgHeight);
        pdf.save('compliance-certificate.pdf');
      });
    }
    this.isDownloading = false;
  }
  loadGoogleFonts(user_name: string) {
    this.settingsService.GenerateSignature(user_name, this.fonts);
  }

  getUserSignature() {
    this.isSignatureLoading = true;
    this.settingsService.getUserSignature(this.orgGuid).subscribe(
      data => {
        this.UserSignature = data.url;
        this.isSignatureLoading = false;
        this.loading = false;
      },
      error => {
        this.isSignatureLoading = false;
      }
    );
  }
  async addImageToCanvas(certificate: string) {
    const imgElement = document.getElementById(`${certificate}-onlineImage-hidden`) as HTMLImageElement;
    const imgUrl = imgElement.src;
    imgElement.src = await this.convertImageToBase64(imgUrl);
  }
  async convertImageToBase64(url: string): Promise<string> {
    return fetch(url)
      .then(response => response.blob())
      .then(blob => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      });
  }
  async generatePdfWithOnlineImages(certificate: string) {
    this.isDownloading = true;
    await this.addImageToCanvas(certificate);
    setTimeout(() => {
      this.generatePdf(certificate);
    }, 3000);
  }
  getCertificateDetails() {
    this.orgService.getCertificateDetails(this.orgGuid).subscribe((data: any) => {
      let result = Object.entries(data.documents_list).map(([framework, documents]) => ({
        documents_list: documents,
        framework: framework,
        org_name: data.org_name
      }));
      this.certificateData = result;
      const frameworks = this.certificateData.map((item: any) => item.framework);
      this.frameworkString =
        frameworks.slice(0, -1).join(', ') + (frameworks.length > 1 ? ' and ' : '') + frameworks.slice(-1);
      setTimeout(() => {
        for (let certificate of this.certificateData) {
          const itemsPerColumn =
            certificate.documents_list.length > 1
              ? Math.ceil(certificate.documents_list.length / 2)
              : certificate.documents_list.length;
          const firstColumn = document.getElementById(`${certificate.framework}-first-column-small`);
          const secondColumn = document.getElementById(`${certificate.framework}-second-column-small`);
          certificate.documents_list.forEach((item: any, index: number) => {
            const listItem = document.createElement('li');
            listItem.textContent = item;
            if (index < itemsPerColumn) {
              firstColumn.appendChild(listItem);
            } else {
              secondColumn.appendChild(listItem);
            }
          });
          const itemsPerColumnHidden =
            certificate.documents_list.length > 1
              ? Math.ceil(certificate.documents_list.length / 2)
              : certificate.documents_list.length;
          const firstColumnHidden = document.getElementById(`${certificate.framework}-first-column-hidden`);
          const secondColumnHidden = document.getElementById(`${certificate.framework}-second-column-hidden`);
          certificate.documents_list.forEach((item: any, index: number) => {
            const listItem = document.createElement('li');
            listItem.textContent = item;
            listItem.classList.add('m-1');
            if (index < itemsPerColumnHidden) {
              firstColumnHidden.appendChild(listItem);
            } else {
              secondColumnHidden.appendChild(listItem);
            }
          });
        }
      }, 1000);
    });
  }
  closeDialog() {
    this.dialogRef.close();
  }

  expandCertificate(certificate: any) {
    const dialogRef = this.dialog.open(ExpandedCertificateModalComponent, {
      height: '640px',
      width: '685px',
      hasBackdrop: true,
      data: {
        certificateData: certificate,
        signature: this.UserSignature,
        org_name: certificate.org_name
      }
    });
  }

  ngOnDestroy(): void {
    this.orgGuidSubscription.unsubscribe();
  }
}
