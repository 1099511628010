import { Component, OnInit, Input, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { take, map } from 'rxjs/operators';
import { OrganisationService } from '@app/core/organisation.service';
import { SettingsService } from '@app/settings/settings.service';
import { ModalService } from '@app/core/modal/modal.service';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';

@Component({
  selector: 'app-add-group-user',
  templateUrl: './add-group-user.component.html',
  styleUrls: ['./add-group-user.component.scss']
})
export class AddGroupUserComponent implements OnInit, OnDestroy {
  @Input() title_tag: string;
  @Input() form_data: [];
  @Input() formType: string;
  @Input() message: string;
  @Input() guid: string;
  @Input() groupName: string;
  @Input() groupEmails: any;

  peopleCount = 0;
  RevokeEditForm: FormGroup;
  peopleList: object[];
  statusType: any = 'Active';
  list: object[];
  newEmailListOfCommitteeMembers: any[] = [];
  success = false;
  searchText: any;
  apiError = false;
  TrainingGroupList: object[];
  search_title: any = '';
  form = {
    purpose: { data: '', edit: false, error: '' }
  };
  submitted = false;
  isLoading = false;
  OrganisationID: string | null;
  guidSubscription: any;
  backend_error: any;
  editing: boolean = false;
  @Input() is_editable = true;
  scrollLoader = false;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  page = 1;
  pagnationData: any;
  loading = true;
  paginatedDataUrl: any;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    private settingsService: SettingsService,
    public activeModal: NgbActiveModal,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private documentsService: DocumentsService,
    private modalService: ModalService,
    private translate: TranslateService,
    private toaster: ToasterService
  ) {}

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      let groupName = this.groupName;
      let groupEmails = this.groupEmails;
      this.getPeopleList();
    });
  }

  onScrollUp() {
    this.scrollLoader = false;
  }

  onScrollData() {
    this.scrollLoader = true;
    this.settingsService
      .getOrganizationPeopleListWithPagination(
        this.OrganisationID,
        (this.page += 1),
        this.search_title,
        this.statusType
      )
      .subscribe(
        data => {
          this.scrollLoader = false;
          this.pagnationData = data.results;
          this.paginatedDataUrl = data;
          this.peopleList = this.peopleList.concat(data.results);
        },
        error => {
          this.scrollLoader = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe((error: any) => {
            this.backend_error = error;
          });
        }
      );
  }
  onScroll() {
    if (this.paginatedDataUrl?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }

  trainingGroupMemberAdd(id: any, email: any): void {
    const data = { users: [id] };
    this.settingsService.trainingGroupMemberAdd(this.OrganisationID, this.guid, data).subscribe(
      data => {
        this.groupEmails.push(email);
        this.activeModal.close({ result: data, isUpdate: true });
        this.translate.get('Training_Group.people_toaster_message.add_people').subscribe(res => {
          this.toaster.showSuccess(res);
        });
      },
      error => {
        this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
        this.backendErrorService.backendError$.subscribe((error: any) => {
          this.backend_error = error;
        });
      }
    );
  }

  openDelUser(userGuid: string) {
    const data = { users: [userGuid] };
    this.submitRevokeData(data);
  }

  submitRevokeData(data: any): void {
    this.documentsService
      .submitDelGroupUserData(this.OrganisationID, this.guid, data)
      .pipe(
        finalize(() => {
          this.RevokeEditForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.submitted = false;
          this.translate.get('Training_Group.people_toaster_message.remove_people').subscribe(res => {
            this.toaster.showSuccess(res);
          });
          this.activeModal.close({ result: data, isUpdate: true });
        },
        (error: any) => {
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe((error: any) => {
            this.backend_error = error;
          });
        }
      );
  }

  updateSearchText(x: any) {
    this.search_title = x.target.value;
    if (this.search_title == undefined) {
      this.search_title = '';
    }
  }

  Search(search_title: string) {
    this.page = 1;
    this.search_title = search_title ? search_title : '';
    this.settingsService
      .getOrganizationPeopleListWithPagination(this.OrganisationID, this.page, this.search_title, this.statusType)
      .subscribe(
        data => {
          this.peopleList = data.results;
          this.paginatedDataUrl = data;
          this.peopleCount = data['count'];
          this.loading = false;
        },
        error => {
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe((error: any) => {
            this.backend_error = error;
          });
        }
      );
  }
  openPeopleForm(formType: string, formData?: any) {
    this.modalService
      .custom(CustomDialogComponent, { title: 'Add people', modalType: 'people' })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.translate.get('organisation_people.people_toaster_message.add_people').subscribe(res => {
            this.toaster.showSuccess(res);
          });
          this.getPeopleList();
        }
      });
  }

  closePeopleAddModal(): void {
    this.newEmailListOfCommitteeMembers;
    this.activeModal.close(false);
  }

  private getPeopleList(): void {
    this.settingsService
      .getOrganizationPeopleListWithPagination(this.OrganisationID, this.page, this.search_title, this.statusType)
      .subscribe(
        data => {
          this.peopleList = data.results;
          this.paginatedDataUrl = data;
          this.peopleCount = data['count'];
          this.loading = false;
        },
        error => {
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe((error: any) => {
            this.backend_error = error;
          });
        }
      );
  }
}
