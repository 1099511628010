import { Component, Input, OnInit } from '@angular/core';
import { SidenavService } from '@app/core/modal/sidenav.service';

import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
@Component({
  selector: 'app-folder-selection',
  templateUrl: './folder-selection.component.html',
  styleUrls: ['./folder-selection.component.scss']
})
export class FolderSelectionComponent implements OnInit {
  error = {};
  OrganisationID: string | null;
  guidSubscription: any;
  FolderList: any = [];
  SubFolderList: object[];
  @Input() title_tag: string;
  @Input() storage_id: string;
  @Input() formType: string;
  @Input() form_data: [];
  @Input() docTitle: string;
  list: any[];
  subfolder: any;
  subscription: any;
  message: string;
  checkedItem: string;
  folder_name: String;
  menuHeader: any = [];
  highlightedFolder: any;
  folderList: any;
  folderGuid: any;
  loading = true;
  folderDocList: any;
  totalLength: any;
  shareSelectedFolder: any;
  titleOfmenu: any = [];
  storeMenuTitle: any = [];
  selectedFolderGuid: any;
  submittedFoderData: any;
  selectedFolder: any;
  setButtonTitle = true;
  setButtonTitleAfterMenuChange: boolean;
  page = 1;
  scrollLoader = false;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  pagnationData: any;
  sourceElement: any;
  paginatedDataUrl: any;
  disableSelectButton = true;
  setMenu: any;
  selectedParentFolderName: any;
  folderPathArray: any;
  isLoading: boolean = false;
  toggledNodes: Set<string> = new Set();
  constructor(
    private orgService: OrganisationService,
    private documentsService: DocumentsService,
    private sidenavService: SidenavService
  ) {}
  ngOnInit() {
    this.disableSelectButton = true;
    let storedObj = localStorage.getItem(this.storage_id);
    let restoredDataObj = undefined;
    this.highlightedFolder = undefined;
    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
    });
    if (storedObj) {
      restoredDataObj = JSON.parse(storedObj);
    }

    // if (restoredDataObj && restoredDataObj.folderHighrarchy.length > 0) {
    //   this.setButtonTitleAfterMenuChange = true;
    //   this.folderPathArray = restoredDataObj.folderHighrarchy;
    //   this.getSubFolderList(restoredDataObj.selectedFolderGuid);
    // } else {
    this.documentsService.getOrganizationFolderListPagination(this.OrganisationID, this.page).subscribe(
      data => {
        this.disableSelectButton = true;
        this.loading = false;
        this.folderList = data.results.map((item: any) => ({
          ...item,
          isMainFolder: true
        }));
        this.paginatedDataUrl = data;
      },
      error => {
        this.error = error.error.message;
      }
    );
  }

  onScrollUp() {
    this.scrollLoader = false;
  }

  onScrollData() {
    this.scrollLoader = true;
    this.documentsService.getOrganizationFolderListPagination(this.OrganisationID, (this.page += 1)).subscribe(
      data => {
        this.scrollLoader = false;
        this.pagnationData = data.results;
        this.paginatedDataUrl = data;
        this.folderList = this.folderList.concat(
          data.results.map((item: any) => ({
            ...item,
            isMainFolder: true
          }))
        );
      },
      error => {
        this.scrollLoader = false;
        this.error = error.error.message;
      }
    );
  }
  onScroll() {
    if (this.paginatedDataUrl?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }

  isToggled(folder: any): boolean {
    return this.toggledNodes.has(folder.guid);
  }
  handleFolderClick(folder: any): void {
    this.disableSelectButton = false;
    this.highlightedFolder = folder;
    const folderPath = this.findFolderPath(this.folderList, folder.guid);
    if (folderPath) {
      this.folderPathArray = folderPath;
      this.selectedParentFolderName = folderPath[0].title;
    } else {
      this.selectedParentFolderName = folder.title;
    }
    if (!folder.parent_folder) {
      this.folderPathArray = [folder];
    } else {
      const parentFolder = this.getFolderByGuid(folder.parent_folder);
      if (parentFolder) {
        this.folderPathArray = this.updateFolderPath(parentFolder, folder);
      } else {
        this.folderPathArray = [folder];
      }
    }
    this.getSubFolderList(folder);
  }
  updateFolderPath(parentFolder: any, folder: any): any[] {
    let path = [parentFolder];
    while (parentFolder.parent_folder) {
      parentFolder = this.getFolderByGuid(parentFolder.parent_folder);
      if (parentFolder) {
        path.unshift(parentFolder);
      } else {
        break;
      }
    }
    path.push(folder);
    return path;
  }
  getFolderByGuid(guid: string): any {
    const stack = [...this.folderList];
    while (stack.length > 0) {
      const current = stack.pop();
      if (current.guid === guid) {
        return current;
      }
      if (current.children) {
        stack.push(...current.children);
      }
    }
    return null;
  }

  findFolderPath(folders: any[], childGuid: string): any[] | null {
    for (const folder of folders) {
      if (folder.guid === childGuid) {
        return [folder];
      }
      if (folder.children) {
        for (const child of folder.children) {
          const path = this.findFolderPath(folder.children, childGuid);
          if (path) {
            return [folder, ...path];
          }
        }
      }
    }
    return null;
  }
  getSubFolderList(folder: any): void {
    if (this.toggledNodes.has(folder.guid)) {
      this.toggledNodes.delete(folder.guid);
    } else {
      this.toggledNodes.add(folder.guid);
      if (!folder.children) {
        this.documentsService.getOrganizationSubFolderList(this.OrganisationID, folder.guid).subscribe(children => {
          folder.children = children;
          this.loading = false;
        });
      }
    }
  }

  ngAfterViewInit() {}
  closeNav() {
    // this.sidenavService.close(FolderSelectionComponent)
    this.sidenavService.close(true);
  }

  closeAll() {
    this.sidenavService.close(true);
    this.sidenavService.close(true);
  }
  save() {
    this.isLoading = true;
    let folderPath = '';
    for (let i = 0; i < this.folderPathArray.length; i++) {
      this.storeMenuTitle.push(this.folderPathArray[i].title);
      folderPath = folderPath + this.folderPathArray[i].title;
      if (i < this.folderPathArray.length - 1) {
        folderPath = folderPath + '/';
      }
    }

    if (this.highlightedFolder) {
      if (folderPath != this.highlightedFolder.title) {
        if (folderPath.includes(this.highlightedFolder.title)) {
          folderPath = folderPath.replace(this.highlightedFolder.title, ' ');
          folderPath = folderPath + this.highlightedFolder.title;
        } else {
          folderPath = folderPath + '/' + this.highlightedFolder.title;
        }
      } else {
        folderPath = this.highlightedFolder.title;
      }
    } else {
      if (folderPath != this.highlightedFolder.title) {
        if (folderPath.includes(this.highlightedFolder.title)) {
          folderPath = folderPath.replace(this.highlightedFolder.title, ' ');
          folderPath = folderPath + this.highlightedFolder.title;
        } else {
          folderPath = folderPath + '/' + this.highlightedFolder.title;
        }
      }
    }

    if (this.folderPathArray && this.folderPathArray.length > 0) {
      this.selectedFolderGuid = this.folderPathArray[this.folderPathArray.length - 1].guid;
    }

    this.submittedFoderData = {
      folderPath: folderPath,
      selectedFolderGuid: this.selectedFolderGuid ? this.selectedFolderGuid : this.highlightedFolder['guid'],
      folderHighrarchy: this.folderPathArray
    };
    if (this.storage_id) {
      let menustringify = JSON.stringify(this.submittedFoderData);
      localStorage.setItem(this.storage_id, menustringify);
    }
    this.sidenavService.close(false, FolderSelectionComponent);
    this.documentsService.setSelectedFolderData(this.submittedFoderData);
    this.documentsService.emitEventWithLatestData(this.documentsService.getcheckedItem());
    this.documentsService.emitEventWithLatestDataGuid(this.documentsService.getcheckedItemGuid());
    this.isLoading = false;
  }
  ngOnDestroy(): void {}
}
