import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-custom-dialog',
  templateUrl: './customDialog.component.html',
  styleUrls: ['./customDialog.component.scss']
})
export class CustomDialogComponent implements OnInit {
  title: string;
  modalType: string;
  formType: string;
  formData: [];
  guid: string;
  newCommittee: string;
  message: string;
  DocumentGuid: string;
  DocCreatedBy: string;
  DocName: string;
  DocumentVersion: string;
  DocumentTitle: string;
  docTitle: string;
  user_name: string;
  google_fonts: any;
  TrackingGuid: string;
  CampaignGuid: string;
  CampaignName: string;
  groupName: string;
  groupEmails: [];
  incidentTitle: any;
  incidentGuid: any;
  status_to_be_sent: string;
  incCreatedBy: string;
  readerEmails: [];
  module: string;
  role: string;
  type: string;
  formGuid: string;
  formCreatedBy: string;
  formTitle: string;
  email: string;
  requestedAccess: any = [];
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}
