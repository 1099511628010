import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { environment } from '@env/environment';

// import * as mixpanel from 'mixpanel-browser';

@Injectable()
export class MixpanelService {
  private mixpanelToken: string;

  /**
   * constructor
   * get mixpanel token and initialize
   */
  constructor() {
    this.mixpanelToken = 'aaa';

    this.init();
  }

  /**
   * Initialize mixpanel.
   */
  init(): void {
    console.log('Init Mix Panel');
    // mixpanel.init(this.mixpanelToken);
    mixpanel.init(this.mixpanelToken, { api_host: environment.serverUrl + '/api/mp' });
  }

  /**
   * Create new Alias for user
   * Call this method only once in user lifecycle
   *
   *
   */
  createAlias(alias: string) {
    mixpanel.alias(alias, mixpanel.get_distinct_id());
  }

  /**
   * Identify User
   *
   *
   */
  identify(alias: string) {
    mixpanel.identify(alias);
  }

  /**
   * setup mixpannel
   *
   */
  setup() {
    // mixpanel.loggingEnabled = false;
    this.setSuperProperties({ Platform: 'web' });
  }

  /**
   * setPeople
   * Store user profiles in Mixpanel's People Analytics product
   */
  setPeople(properties: any = {}): void {
    mixpanel.people.set(properties);
  }

  /**
   * setSuperProperties
   *
   */
  setSuperProperties(properties: any) {
    mixpanel.register(properties);
  }

  /**
   * sendCBEvent
   *
   */
  sendCBEvent(event: string) {
    try {
      const credentials = JSON.parse(localStorage.getItem('credentials'));
      const org_type_json = localStorage.getItem('org_type');
      let org_type = '';
      if (org_type_json != null) {
        org_type = org_type_json['title'];
      }
      let compliance_program_names = '';
      const compliance_programs = localStorage.getItem('compliance_programs');
      if (compliance_programs != null) {
        const compliance_programs_json = JSON.parse(compliance_programs);
        compliance_program_names = compliance_programs_json.map((program: any) => program.name).join(', ');
      }
      this.sendEvent(event, {
        'Organization Name': localStorage.getItem('org_name'),
        'Organization ID': localStorage.getItem('org-guid'),
        'Organization Domain': localStorage.getItem('domain'),
        'Organization User Email': credentials.user.username,
        'Organization User First Name': credentials.user.first_name,
        'Organization User Last Name': credentials.user.last_name,
        'Organization User ID': credentials.user.guid,
        'Organization Type': org_type,
        'Organization Compliance Programs': compliance_program_names
      });
    } catch (error) {
      this.sendEvent('track_error', {
        Event: event,
        Error: String(error)
      });
    }
  }

  /**
   * sendEvent
   *
   */
  sendEvent(event: string, properties: any) {
    if (properties) {
      mixpanel.track(event, properties);
    } else {
      this.trackEvent(event);
    }
  }

  /**
   * trackEvent
   */
  trackEvent(event: string) {
    mixpanel.track(event);
  }

  timeEvent(eventName: string) {
    mixpanel.time_event(eventName);
  }

  /**
   * Reset Mixpanel
   */
  logout() {
    mixpanel.reset();
  }
}
