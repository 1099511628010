<app-spinner *ngIf="isLoading"></app-spinner>
<div class="row">
  <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
    <h2 class="text-cyan m-0" style="cursor:default" translate>document_folder.add_sub_folder.Create_SubFolder</h2>
    <div>
      <button class="btn-cancel ml-4 w-100" [ngClass]="{ 'btn-cancel': true }" (click)="onReset()" translate>
        document_folder.add_sub_folder.Cancel
      </button>
      <button
        class="btn-save ml-4 w-100"
        [disabled]="FolderForm.invalid || !folder_name"
        [ngStyle]="{ 'background-color': FolderForm.invalid || !folder_name ? 'rgba(0,0,0,.26)' : '#4db7c6' }"
        [ngClass]="{ 'btn-save': true }"
        (click)="submit()"
        translate
      >
        document_folder.add_sub_folder.Create
      </button>
    </div>
  </div>
</div>
<div class="row scroll-view">
  <div class="col">
    <ng-container>
      <div class="row mt-2" *ngIf="backend_error">
        <div class="col-12 col-sm-12 col-lg-12 ml-5">
          <div class="text-danger" translate>* {{ backend_error }}</div>
        </div>
      </div>
      <form [formGroup]="FolderForm" novalidate>
        <div class="row mt-5">
          <div class="col-2 col-sm-2 col-lg-2">
            <label for="title" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate
              >document_folder.add_sub_folder.Title</label
            >
          </div>
          <div class="input-group col-8 col-sm-8 col-lg-8">
            <input
              id="title"
              class="form-control cb-input"
              formControlName="title"
              [placeholder]="'document_folder.add_sub_folder.Title' | translate"
              name="title"
              type="text"
              style="height: 40px;"
              (keyup)="onChange($event)"
              (keydown.enter)="$event.preventDefault()"
              [maxLength]="256"
              required
            />
            <div class="col-12 error-mssg" *ngIf="(f.title.touched || f.title.dirty) && f.title.errors">
              <label for="title" class="text-danger" *ngIf="f.title.errors.required" translate
                >document_folder.add_folder.*Title is required.</label
              >
              <label for="title" class="text-danger" *ngIf="f.title.errors.maxLength" translate
                >Create_Document.Title must be 255 characters or less</label
              >
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-2 col-sm-2 col-lg-2">
            <label for="description" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate
              >document_folder.add_sub_folder.Description</label
            >
          </div>
          <div class="input-group col-8 col-sm-8 col-lg-8">
            <textarea
              id="description"
              class="form-control cb-input"
              [placeholder]="'document_folder.add_sub_folder.Description' | translate"
              name="description"
              type="text"
              formControlName="description"
              cols="64"
              rows="10"
              [maxLength]="4097"
            ></textarea>
            <div
              class="col-12 error-mssg"
              *ngIf="(f.description.touched || f.description.dirty) && f.description.errors"
            >
              <label for="description" class="text-danger" *ngIf="f.description.errors.required" translate
                >document_folder.add_sub_folder.*Description is required.</label
              >
              <label for="description" class="text-danger" *ngIf="f.description.errors.maxLength" translate
                >Create_Document.Description must be 4096 characters or less</label
              >
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-2 col-sm-2 col-lg-2">
            <label for="parent_folder_guid" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate
              >document_folder.add_sub_folder.Folder</label
            >
          </div>
          <div class="input-group ml-2 col-8 col-sm-8 col-lg-8">
            <button
              class="cb-cancel-button text-black-1"
              (click)="SelectFolderDialog()"
              style="border: solid 1px #d6dce2; border-radius: 5px;font-size: 12px; font-weight: normal;"
              translate
            >
              document_folder.add_sub_folder.Select_Folder
            </button>
            <div class="col-12 error-mssg" *ngIf="submitted && !folder_name">
              <label for="name" class="text-danger" style="margin-left: -6px;" translate>
                document_folder.add_sub_folder.Select_Folder_Anyone</label
              >
            </div>
            <p
              *ngIf="folder_name"
              class="ml-4 text-black-3"
              style=" font-size: 14px; line-height: 18px; font-weight: 400;padding-top: 0.7em;"
              translate
            >
              document_folder.add_sub_folder.Selected_Folder <strong>{{ folder_name }}</strong>
            </p>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
</div>
