import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { finalize } from 'rxjs/operators';
import { IncidentService } from '@app/Organisation-admin/incident/incident.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
@Component({
  selector: 'app-delete-category',
  templateUrl: './delete-category.component.html',
  styleUrls: ['./delete-category.component.scss']
})
export class DeleteCategoryComponent implements OnInit {
  submitted = false;
  isLoading = false;
  OrganisationID: string | null;
  guidSubscription: any;
  success: boolean;
  error: any;

  @Input() title_tag: string;
  @Input() message: string;
  @Input() categoryGuid: string;
  backend_error: string;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private incidentService: IncidentService,
    private translate: TranslateService,
    private toaster: ToasterService
  ) {}

  ngOnInit() {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      // this.getDeleteCategoryForm();
    });
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  save(): void {
    if (this.title_tag == 'Delete Document Group') {
      this.activeModal.close(true);
      return;
    }
    console.log('hello' + this.categoryGuid);
    this.incidentService
      .deleteCategoryList(this.OrganisationID, this.categoryGuid)

      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.error = {
            comment: ''
          };
          this.submitted = false;
          this.translate.get('Category.category_toaster_message.incident_category_deleted').subscribe(res => {
            this.toaster.showSuccess(res);
          });
          this.activeModal.close(true);
          this.backend_error = '';
        },
        (error: any) => {
          this.isLoading = false;
          this.submitted = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
  }
}
