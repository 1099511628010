import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Logger } from '@app/core';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { finalize } from 'rxjs/operators';
import { LeadService } from '@app/Organisation-system-admin/Lead-generation/lead.service';

const log = new Logger('ConfirmLeadAcceptDialogComponent');

@Component({
  selector: 'app-lead-accept-confirm-dialog',
  templateUrl: './confirmLeadAcceptDialog.component.html',
  styleUrls: ['./confirmLeadAcceptDialog.component.scss']
})
export class ConfirmLeadAcceptDialogComponent implements OnInit {
  acceptLeadDialogForm: FormGroup;
  error: string;
  isLoading = false;
  isConfirmLeadAcceptDialogSuccess = false;
  @Input() title: string;
  @Input() message: string;
  @Input() userGuid: string;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder, private leadService: LeadService) {}

  ngOnInit() {
    if (!this.title) {
      this.title = 'Remove people';
    }
    this.createForm();
  }

  get f() {
    return this.acceptLeadDialogForm.controls;
  }

  acceptLeadDialogFormSubmit() {
    this.isLoading = true;
    const context = this.acceptLeadDialogForm.value;
    console.log('signup form', context);
    this.leadService
      .rejectOrAcceptOrganizationLeadGeneratedUser(this.userGuid, context)
      .pipe(
        finalize(() => {
          this.acceptLeadDialogForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (response: any) => {
          console.log('response', response);
          this.activeModal.close(true);
          this.isConfirmLeadAcceptDialogSuccess = true;
        },
        (error: any) => {
          log.debug(`Signup error: ${error}`);
          this.isConfirmLeadAcceptDialogSuccess = false;
          this.error = error.error;
        }
      );
  }

  private createForm() {
    this.acceptLeadDialogForm = this.formBuilder.group({
      status: 'approved',
      agent_repo_url: ['', Validators.required()],
      is_trial: [false]
    });
  }
}
