import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-cloud-integration-card',
  templateUrl: './cloud-integration-card.component.html',
  styleUrls: ['./cloud-integration-card.component.scss']
})
export class CloudIntegrationCardComponent implements OnInit {
  @Input() cloudProviders: any[] = [];
  @Output() onManage: EventEmitter<any> = new EventEmitter();
  @Output() onIntegrate: EventEmitter<any> = new EventEmitter();
  @Input() isIntegrated: boolean = false;

  constructor() {}

  ngOnInit(): void {}
  manageProvider(provider: any) {
    this.onManage.emit(provider);
  }

  integrateProvider(provider: any) {
    this.onIntegrate.emit(provider);
  }
  getProviderLogo(providerName: string): string {
    switch (providerName.toLowerCase()) {
      case 'aws':
        return './../../../../assets/images/core/aws.svg';
      case 'azure':
        return './../../../../assets/images/core/azure.svg';
      case 'gcp':
        return './../../../../assets/images/core/gcp.svg';
      default:
        return './../../../../assets/images/core/default.svg'; // Optional: return a default image if none match
    }
  }
}
