import { Component, OnInit } from '@angular/core';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '../documents.service';
import { ModalService } from '@app/core/modal/modal.service';
import { take } from 'rxjs/operators';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import stringInfo from '../../../../translations/en-US.json';
import { DomSanitizer } from '@angular/platform-browser';

interface configOption {
  label: string;
  key: string;
}

interface ShowHideColumns {
  isShowRequestedBy: boolean;
  isshowEndDate: boolean;
  isShowCampaign: boolean;
  isShowType: boolean;
}
@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {
  selected = new FormControl(0);
  OrganisationID: string | null;
  TrainingList: object[];
  TrackingList: object[];
  guidSubscription: any;
  error: any;
  page = 1;
  key: any;
  key2: any;
  loading = true;
  loadingForDocuments = true;
  search_text: any;
  public pageSize = 10;
  totalLength: any;
  search_title: any;
  statusFilter: any;
  currentPage: number = 1;
  role: any;
  viewDocumentEnabled = false;
  documentLink: any = '';
  userRole: any;
  placeholderSearch = stringInfo.Attention_Required.PlaceHolder_Search;
  training_campaignTitle: any;
  dueDate: any;
  scrollLoader = false;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  pagnationData: any;
  currentTab: any = 0;
  searchText: string;
  clickedTrackingGuid: any;
  statusData: any;
  status1: any;
  status2: any;
  spnning = false;
  show = true;
  paginatedDataUrlTraining: any;
  paginatedDataUrlTracking: any;
  message: string;
  searchTitleformyDocument: any;
  loadingCampaignSearch: boolean = false;
  loadingdocumentSearch: boolean = false;
  viewReoport = false;
  campaignsFilterColOptions: configOption[] = [
    { label: 'Requested By', key: 'isShowRequestedBy' },
    { label: 'Start Date', key: 'isshowStartDate' }
  ];

  showHideColumns: ShowHideColumns = {
    isShowRequestedBy: false,
    isshowEndDate: false,
    isShowCampaign: false,
    isShowType: false
  };

  docFilterColOptions: configOption[] = [
    { label: 'Campaign', key: 'isShowCampaign' },
    { label: 'Type', key: 'isShowType' }
  ];
  selectedStatus: any;
  trainingStatus: any[];
  dropdownSetting: any = {};
  // currentDate = new Date().toLocaleDateString().split('/').reverse().join('-');
  constructor(
    private orgService: OrganisationService,
    private documentsService: DocumentsService,
    private modalService: ModalService,
    private toaster: ToasterService,
    private translate: TranslateService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
      this.getRole();
      this.getTrainingStatus();
      this.dropdownSetting = {
        singleSelection: false,
        idField: 'key',
        textField: 'value'
      };
      // this.getTrackingList();
    });
  }

  getTrainingStatus(): void {
    this.documentsService.getTrainingStatus().subscribe(
      data => {
        this.loading = false;
        this.trainingStatus = data;
        let index = this.trainingStatus.findIndex((item: any) => item.key.toLowerCase() === 'overdue');
        this.trainingStatus.splice(index, 1);
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }

  onScrollUp() {
    this.scrollLoader = false;
  }

  scrollFirstTab() {
    this.scrollLoader = true;
    if (this.search_title == null || undefined) {
      this.search_title = '';
      this.documentsService
        .getOrganizationTrainingDocumentsListPagination(
          this.OrganisationID,
          (this.page += 1),
          (this.search_title = this.search_title),
          this.convertArrayToQueryParams(this.selectedStatus)
        )
        .subscribe(
          data => {
            if (this.page == 1 && this.selectedStatus && this.selectedStatus.length > 0) {
              this.pagnationData = [];
              this.paginatedDataUrlTraining = [];
              this.TrainingList = [];
            }
            this.scrollLoader = false;
            this.pagnationData = data.results;
            this.paginatedDataUrlTraining = data;
            this.TrainingList = this.TrainingList.concat(data.results);
          },
          error => {
            this.scrollLoader = false;
            this.error = error.error.message;
          }
        );
    } else {
      this.scrollLoader = true;
      this.documentsService
        .getOrganizationTrainingDocumentsListPagination(
          this.OrganisationID,
          (this.page += 1),
          (this.search_title = this.search_title),
          this.convertArrayToQueryParams(this.selectedStatus)
        )
        .subscribe(
          data => {
            if (this.page == 1 && this.selectedStatus && this.selectedStatus.length > 0) {
              this.pagnationData = [];
              this.paginatedDataUrlTraining = [];
              this.TrainingList = [];
            }
            this.scrollLoader = false;
            this.pagnationData = data.results;
            this.paginatedDataUrlTraining = data;
            this.TrainingList = this.TrainingList.concat(data.results);
          },
          error => {
            this.scrollLoader = false;
            this.error = error.error.message;
          }
        );
    }
  }

  scrollSecondTab() {
    this.scrollLoader = true;
    if (this.searchTitleformyDocument == null || undefined) {
      this.searchTitleformyDocument = '';
      this.documentsService
        .getOrganizationTrainingTrackerListPagination(
          this.OrganisationID,
          (this.page += 1),
          (this.search_title = this.searchTitleformyDocument),
          this.convertArrayToQueryParams(this.selectedStatus)
        )
        .subscribe(
          data => {
            if (this.page == 1 && this.selectedStatus && this.selectedStatus.length > 0) {
              this.pagnationData = [];
              this.paginatedDataUrlTracking = [];
              this.TrackingList = [];
            }
            this.scrollLoader = false;
            this.pagnationData = data.results;
            this.paginatedDataUrlTracking = data;
            this.TrackingList = this.TrackingList.concat(data.results);
          },
          error => {
            this.scrollLoader = false;
            this.error = error.error.message;
          }
        );
    } else {
      this.scrollLoader = true;
      this.documentsService
        .getOrganizationTrainingTrackerListPagination(
          this.OrganisationID,
          (this.page += 1),
          (this.search_title = this.searchTitleformyDocument),
          this.convertArrayToQueryParams(this.selectedStatus)
        )
        .subscribe(
          data => {
            if (this.page == 1 && this.selectedStatus && this.selectedStatus.length > 0) {
              this.pagnationData = [];
              this.paginatedDataUrlTracking = [];
              this.TrackingList = [];
            }
            this.scrollLoader = false;
            this.pagnationData = data.results;
            this.paginatedDataUrlTracking = data;
            this.TrackingList = this.TrackingList.concat(data.results);
          },
          error => {
            this.scrollLoader = false;
            this.error = error.error.message;
          }
        );
    }
  }

  onScroll() {
    if (this.currentTab == '0') {
      if (this.paginatedDataUrlTraining?.next) {
        this.scrollFirstTab();
      } else {
        this.scrollLoader = false;
      }
    } else if (this.currentTab == '1') {
      if (this.paginatedDataUrlTracking?.next) {
        this.scrollSecondTab();
      } else {
        this.scrollLoader = false;
      }
    }
  }
  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  changeTab(index: any) {
    this.currentTab = index;
    this.searchText = '';
    this.search_title = '';
    this.loadingForDocuments = true;
    this.documentLink = '';

    this.viewDocumentEnabled = false;
    if (this.currentTab == '1') {
      this.selectedStatus = [];
      this.getTrackingList();
    }
    if (this.currentTab == '0') {
      this.selectedStatus = [];
      this.getTrainingList();
    }
  }
  refresh() {
    this.show = false;
    this.spnning = true;
    if (this.currentTab == '1') {
      this.page = 1;
      this.getTrackingList();
      this.searchText = '';
    }
    if (this.currentTab == '0') {
      this.page = 1;
      this.getTrainingList();
      this.searchText = '';
    }
    // this.page = 1;
  }
  goToCreateDoc() {
    this.router.navigate(['organisation/documents/create-training-campaign']);
  }

  onNavigate(link: any) {
    window.open(link, '_blank');
  }

  updateSearchText(x: any) {
    this.search_text = x.target.value;
    if (this.search_title == undefined) {
      this.search_text = '';
    }
  }

  getRole() {
    this.role = JSON.parse(localStorage.getItem('credentials'));
    this.userRole = this.role['user']['role'];
    for (let i = 0; i < 10; i++) {
      if (this.userRole[i] == 'organization_admin_role' || this.userRole[i] == 'document_author') {
        this.key = this.userRole[i];
      }
    }
    if ((this.key == 'organization_admin_role' || this.key == 'document_author') && this.currentTab === 0) {
      this.getTrainingList();
    } else {
      this.currentTab = 1;
      this.getTrackingList();
    }
  }

  Search(search_title: string) {
    this.search_title = search_title;
    if (this.currentTab === 0) {
      this.SearchForMyCampaign(this.search_title);
    } else {
      this.SearchForMyDocument(this.search_title);
    }
  }

  onStatusChange(evt: any) {
    this.page = 1;
    this.getRole();
    // this.onScroll();
  }

  SearchForMyCampaign(search_title?: string) {
    this.search_title = search_title;
    this.loadingCampaignSearch = true;
    this.documentsService
      .getOrganizationTrainingDocumentsListPagination(
        this.OrganisationID,
        this.page,
        (this.search_title = this.search_title),
        this.convertArrayToQueryParams(this.selectedStatus)
      )
      .subscribe(
        data => {
          this.loadingCampaignSearch = false;
          this.totalLength = data.count;
          this.TrainingList = data.results;
        },
        error => {
          this.error = error.error.message;
        }
      );
  }

  SearchForMyDocument(search_title?: string) {
    this.loadingdocumentSearch = true;
    this.searchTitleformyDocument = search_title;
    this.documentsService
      .getOrganizationTrainingTrackerListPagination(
        this.OrganisationID,
        this.page,
        this.searchTitleformyDocument,
        this.convertArrayToQueryParams(this.selectedStatus)
      )
      .subscribe(
        data => {
          this.loadingdocumentSearch = false;
          this.totalLength = data.count;
          this.TrackingList = data.results;
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
  }

  private getTrainingList(): void {
    this.page = 1;
    if (this.OrganisationID != null) {
      this.documentsService
        .getOrganizationTrainingDocumentsListPagination(
          this.OrganisationID,
          this.page,
          (this.search_title = this.search_title),
          this.convertArrayToQueryParams(this.selectedStatus)
        )
        .subscribe(
          data => {
            this.paginatedDataUrlTraining = data;
            this.show = true;
            this.spnning = false;
            this.loading = false;
            this.TrainingList = data.results;
            if (this.TrainingList.length > 0) {
              this.viewReoport = true;
            }
            this.totalLength = data.count;
          },
          error => {
            this.error = error.error.message;
            this.loading = false;
            this.spnning = false;
            this.show = true;
            console.log('Error:', error.error.message);
          }
        );
    }
  }

  private getTrackingList(): void {
    this.page = 1;
    this.documentsService
      .getOrganizationTrainingTrackerListPagination(
        this.OrganisationID,
        this.page,
        (this.search_title = this.search_title),
        this.convertArrayToQueryParams(this.selectedStatus)
      )
      .subscribe(
        data => {
          this.paginatedDataUrlTracking = data;
          this.show = true;
          this.spnning = false;
          this.loadingForDocuments = false;
          this.loading = false;
          this.TrackingList = data.results;
          this.totalLength = data.count;
          this.dueDate = data.completion_date;
          for (let i = 0; i < data.length; i++) {
            this.training_campaignTitle = data[i].training_campaign.title;
          }
        },
        error => {
          this.error = error.error.message;
          this.loading = false;
          this.spnning = false;
          this.show = true;
          console.log('Error:', error.error.message);
        }
      );
  }

  // Complete training Code
  ApproveTrainingDocumentOpenModal(TrackingGuid: string, CampaignGuid: string, CampaignName: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        modalType: 'Complete Training',
        TrackingGuid: TrackingGuid,
        CampaignGuid: CampaignGuid,
        CampaignName: CampaignName
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.documentsService.status.subscribe(data => {
            this.status2 = data;
          });
          for (let i = 0; i <= this.TrackingList.length; i++) {
            if (this.clickedTrackingGuid == this.TrackingList[i]['guid']) {
              this.TrackingList[i]['status_description'] = this.status2['status_description'];
              this.TrackingList[i]['allowed_actions'] = this.status2['allowed_actions'];
              break;
            }
          }
          this.translate
            .get('Approve_Training_Document.approve_training_document_toaster_message.training_completed')
            .subscribe(res => {
              this.toaster.showSuccess(res);
            });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  // Start training Code
  StartTrainingOpenModal(
    Tracking: any,
    TrackingGuid: string,
    CampaignGuid: string,
    CampaignName: string,
    DocumentTitle: string,
    Type: string,
    DueDate: Date,
    tracking: any = {}
  ): void {
    if (Type == 'cb_document') {
      this.message =
        'You are starting the training for the document <b class="text-break">' +
        DocumentTitle +
        '</b>.' +
        ' You need to complete the training on or before ' +
        DueDate;
    } else if (Type == 'external') {
      this.message =
        'You are starting the training for the ' +
        Tracking.training_campaign_material.external_link.title +
        '.' +
        ' You need to complete the training on or before  ' +
        DueDate;
    }
    this.modalService
      .custom(CustomDialogComponent, {
        modalType: 'Start Training',
        Tracking: Tracking,
        TrackingGuid: TrackingGuid,
        CampaignGuid: CampaignGuid,
        CampaignName: CampaignName,
        DocumentTitle: DocumentTitle,
        Type: Type,
        DueDate: DueDate,
        message: this.message
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.viewDocument(Type, tracking);
          this.documentsService.status.subscribe(data => {
            this.status1 = data;
          });
          for (let i = 0; i <= this.TrackingList.length; i++) {
            if (TrackingGuid == this.TrackingList[i]['guid']) {
              this.TrackingList[i]['status_description'] = this.status1['status_description'];
              this.TrackingList[i]['allowed_actions'] = this.status1['allowed_actions'];
              break;
            }
          }

          this.translate
            .get('Start_Training.start_training_toaster_message.start_training_submitted')
            .subscribe(res => {
              this.toaster.showSuccess(res);
            });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  getStatus(TrackingGuid: any, CampaignGuid: any) {
    let data = { guid: TrackingGuid };
    this.documentsService.submitStartTrainingData(this.OrganisationID, CampaignGuid, data).subscribe(
      (data: any) => {
        this.statusData = data;
      },
      (error: any) => {
        this.error = error.error;
      }
    );
  }

  goTOCreateTrainingCampaign(guid: any) {
    this.router.navigate(['/organisation/edit-training-campaign'], { queryParams: { guid: guid } });
  }

  goToReopenTrainingCampaign(guid: any, action: string = '') {
    this.router.navigate(['/organisation/edit-training-campaign'], {
      queryParams: { guid: guid, action: action }
    });
  }

  OpenDocument(url: string) {
    this.documentLink = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.viewDocumentEnabled = true;
  }

  viewDocument(type: any, tracking: any) {
    if (type == 'external') {
      window.open(tracking.external_link.url, '_blank');
    } else {
      if (tracking.document.is_box) {
        this.documentsService.getTrainingSharableLink(this.OrganisationID, tracking.document.guid).subscribe(res => {
          this.OpenDocument(res['shared_link']);
        });
      } else {
        window.open(tracking.document.document_store_identifier, '_blank');
      }
    }
  }

  viewReport(guid: any) {
    this.router.navigate(['/organisation/training/' + guid]);
  }

  getClickEvents(
    tracking: any,
    action: string,
    TrackingGuid: string,
    Campaign: any,
    training_campaign_material: any,
    document_store_identifier: string,
    Document: any,
    Type: string,
    DueDate: Date
  ): void {
    let DocumentTitle;
    this.clickedTrackingGuid = TrackingGuid;
    if (training_campaign_material.type == 'cb_document') {
      DocumentTitle = Document.title;
    } else if (training_campaign_material.type == 'external') {
      DocumentTitle = training_campaign_material.external_link.title;
    }
    if (action == 'View Document' && training_campaign_material.type == 'cb_document') {
      this.viewDocument(training_campaign_material.type, training_campaign_material);
    } else if (action == 'Complete Training') {
      this.ApproveTrainingDocumentOpenModal(TrackingGuid, Campaign.guid, Campaign.title);
    } else if (action == 'Start Training') {
      this.StartTrainingOpenModal(
        tracking,
        tracking['guid'],
        tracking['training_campaign'].guid,
        tracking['training_campaign'].title,
        DocumentTitle,
        tracking['training_campaign_material']['type'],
        tracking['training_campaign'].due_date,
        training_campaign_material
      );
    } else if (action == 'View Document' && training_campaign_material.type == 'external') {
      this.viewDocument(tracking['training_campaign_material']['type'], training_campaign_material);
    }
  }

  getOnClickEvents(action: string, training: any): void {
    if (action == 'View Report') {
      this.viewReport(training['guid']);
    } else if (action == 'Edit Campaign') {
      this.goTOCreateTrainingCampaign(training['guid']);
    } else if (action == 'Reopen Campaign') {
      this.goToReopenTrainingCampaign(training['guid'], action);
    }
  }

  convertArrayToQueryParams(array: { key: string; value: string }[]): string {
    return array?.map(item => `status=${item.key}`).join('&');
  }
}
