<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div class="row">
    <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
      <h2 class="text-cyan m-0" style="cursor:default" translate>Form Details</h2>
    </div>
  </div>

  <div class="class2">
    <div class="row scroll-view">
      <div class="col">
        <div class="my-5 mx-3">
          <div class="row mt-4">
            <div class="col-10 pr-3">
              <div class="row mt-2" *ngIf="backend_error">
                <div class="col-12 col-sm-12 col-lg-12 ml-5">
                  <div class="text-danger" translate>* {{ backend_error }}</div>
                </div>
              </div>
              <h4 class="text-black-1 font-weight-bold" translate style="font-size: 17px;">
                {{ formDetails.title }}
              </h4>
              <p class="text-black-1 capitalize" translate style="font-size: 14px;">
                Status : {{ formDetails.status }}
              </p>
            </div>
            <div class="col-2" *ngIf="formDetails['allowed_actions_list'].length">
              <button
                class="dropdown-btn cb-input w-80"
                mat-stroked-button
                [matMenuTriggerFor]="menu"
                style="line-height: 27px;"
                translate
              >
                Actions
                <i class="fa fa-angle-down ml-2"></i>
              </button>
              <mat-menu #menu="matMenu" class="fixed-menu-panel">
                <ng-container *ngFor="let actions of formDetails['allowed_actions_list']">
                  <button
                    *ngIf="actions.key !== 'view_form_details'"
                    mat-menu-item
                    (click)="getClickEvents(actions.value, formDetails)"
                  >
                    {{ actions.value }}
                    <mat-icon
                      *ngIf="
                        actions.value == 'Edit Document' ||
                        actions.value == 'View Document' ||
                        actions.value == 'Edit Form Template'
                      "
                      >launch</mat-icon
                    >
                  </button>
                </ng-container>
              </mat-menu>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col max-w-500 pr-3">
              <h4 class="text-black-1 font-weight-bold" translate style="font-size: 14px;">
                Description :
              </h4>
              <div class="text-black-1" style="font-size: 14px; " translate>
                {{ formDetails.description }}
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col max-w-500 pr-3">
              <h4 class="text-black-1 font-weight-bold" translate style="font-size: 14px;">
                History
              </h4>
            </div>
          </div>
          <div class="row">
            <div
              style="width: 98%;"
              class="cb-accordian mx-auto mt-4"
              [ngClass]="accArray.includes(2) ? '' : 'expand'"
              [attr.data-index]="2"
            >
              <div class="acc-header d-flex justify-content-end">
                <div class="col-5 col-lg-4 ">
                  <h5 class="font-weight-bold text-black-10" style="font-size: 14px;" translate>
                    Created Date: {{ formDetails.created_timestamp | date: 'yyyy-MM-dd' }}
                  </h5>
                </div>
              </div>
              <hr style="width:100%;text-align:left;margin-top:0" />
              <table class="table table-borderless">
                <tbody>
                  <tr *ngFor="let history of history_data; let indexOfelement = index">
                    <td class="col-12" style="padding-left: 50px;">
                      <h4 class="text-black-1 text-left" scope="row">
                        <div
                          class=" text-black-1 mr-2 "
                          style="font-size: 14px; "
                          *ngIf="history.history.action_type == 'create_form'"
                        >
                          <span
                            [ngClass]="history.created_by_Initial.length === 1 ? 'i-circleinitial' : ''"
                            class="i-circle text-white mr-2 font-weight-bold col-12"
                          >
                            {{ history.created_by_Initial }}
                          </span>
                          <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                            history.created_by
                          }}</span
                          >created a new form on {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }} at
                          {{ history.history['action_timestamp'] | date: ' h:mm a' }}.
                        </div>

                        <div
                          class=" text-black-1 mr-2"
                          style="font-size: 14px;"
                          *ngIf="history.history.action_type == 'manage_responder'"
                        >
                          <span
                            [ngClass]="history.inviteResponderInitial.length === 1 ? 'i-circleinitial' : ''"
                            class="i-circle text-white mr-2 font-weight-bold col-12"
                            *ngIf="history.assignedTo?.length == 1; else groupIcon3"
                            >{{ history.inviteResponderInitial }}
                          </span>
                          <ng-template #groupIcon3>
                            <img src="../../../assets/images/documents/group_user.png" class="cb-icon" />
                          </ng-template>
                          <span
                            translate
                            class=" text-black-1 mr-2  ml-1"
                            style="font-size: 14px; font-weight: bold;"
                            >{{ history.initiatedby }}</span
                          >
                          added
                          <span
                            class=" text-black-1 mr-2  ml-1"
                            style="font-size: 14px; font-weight: bold;"
                            *ngIf="history.assignedTo?.length == 1; else groupUser3"
                          >
                            {{ history.assignedTo[0] }}
                          </span>
                          <ng-template #groupUser3>
                            <span
                              class=" text-black-1 mr-2  ml-1"
                              style="font-size: 14px; font-weight: bold;"
                              translate
                            >
                              {{ history.assignedTo.toString() }}
                            </span>
                          </ng-template>
                          {{ history.assignedTo?.length !== 1 ? 'as responders on' : 'as a responder on' }}
                          {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }}&nbsp; at
                          {{ history.history['action_timestamp'] | date: ' h:mm a' }}.
                          <i
                            *ngIf="history.comment"
                            ngbTooltip="{{ history.comment }}"
                            style="color:gray;font-size:14px"
                            tooltipClass="cb-tooltip"
                            class="far fa-comment-alt mt-2"
                          ></i>

                          <div
                            class="text-black-1"
                            style="font-size: 14px; margin-top: 2.2em;margin-left: 0.11em;
                         margin-right: 0.11em;"
                            *ngIf="history.history['currentState'] != history.history['previousState']"
                          >
                            <span
                              [ngClass]="history.inviteResponderInitial.length === 1 ? 'i-circleinitial' : ''"
                              class="i-circle text-white mr-2 font-weight-bold col-12"
                              >{{ history.inviteResponderInitial }}</span
                            >

                            <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                              history.initiatedby
                            }}</span
                            >{{ this.changeStatus }}
                            {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }}&nbsp; at
                            {{ history.history['action_timestamp'] | date: ' h:mm a' }}.
                          </div>

                          <div
                            class="mt-2 ml-5"
                            *ngIf="history.history['currentState'] != history.history['previousState']"
                          >
                            <button
                              class="ml-4 color col-5 text-black-1"
                              style="font-size: 14px; width: fit-content;"
                              translate
                            >
                              {{ history.history['previousState'] }}
                            </button>
                            <span class="col-2 w-100" style="margin-left: 1em">
                              <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </span>
                            <button
                              class="ml-3 color col-5 text-black-1"
                              style="font-size: 14px; width: fit-content;"
                              translate
                            >
                              {{ history.history['currentState'] }}
                            </button>
                          </div>
                        </div>

                        <div
                          class=" text-black-1 mr-2"
                          style="font-size: 14px;"
                          *ngIf="history.history.action_type == 'manage_approver'"
                        >
                          <span
                            [ngClass]="history.inviteApproverInitial.length === 1 ? 'i-circleinitial' : ''"
                            class="i-circle text-white mr-2 font-weight-bold col-12"
                            *ngIf="history.assignedTo?.length == 1; else groupIcon4"
                            >{{ history.inviteApproverInitial }}</span
                          >
                          <ng-template #groupIcon4>
                            <img src="../../../assets/images/documents/group_user.png" class="cb-icon" />
                          </ng-template>
                          <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                            history.initiatedby
                          }}</span>
                          added
                          <span
                            class=" text-black-1 mr-2  ml-1"
                            style="font-size: 14px; font-weight: bold;"
                            *ngIf="history.assignedTo?.length == 1; else groupUser4"
                            >{{ history.assignedTo[0] }}
                          </span>
                          <ng-template #groupUser4>
                            <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;"
                              >{{ history.assignedTo.toString() }}
                            </span>
                          </ng-template>
                          {{ history.assignedTo?.length !== 1 ? 'as approvers' : 'as an approver' }}
                          on {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }}&nbsp; at
                          {{ history.history['action_timestamp'] | date: ' h:mm a' }}.<i
                            *ngIf="history.comment"
                            ngbTooltip="{{ history.comment }}"
                            style="color:gray;font-size:14px"
                            tooltipClass="cb-tooltip"
                            class="far fa-comment-alt mt-2"
                          ></i>

                          <div
                            class="text-black-1"
                            style="font-size: 14px; margin-top: 2.2em;margin-left: 0.11em;
                          margin-right: 0.11em;"
                            *ngIf="history.history['currentState'] != history.history['previousState']"
                          >
                            <span
                              [ngClass]="history.inviteApproverInitial.length === 1 ? 'i-circleinitial' : ''"
                              class="i-circle text-white mr-2 font-weight-bold col-12"
                              >{{ history.inviteApproverInitial }}</span
                            >
                            <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                              history.initiatedby
                            }}</span>
                            on {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }}&nbsp; at
                            {{ history.history['action_timestamp'] | date: ' h:mm a' }}.
                          </div>

                          <div
                            class="mt-2 ml-5"
                            *ngIf="history.history['currentState'] != history.history['previousState']"
                          >
                            <button
                              class="ml-4 color col-5 text-black-1"
                              style="font-size: 14px; width: fit-content;"
                              translate
                            >
                              {{ history.history['previousState'] }}
                            </button>
                            <span class="col-2 w-100" style="margin-left: 1em">
                              <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </span>
                            <button
                              class="ml-3 color col-5 text-black-1"
                              style="font-size: 14px; width: fit-content;"
                              translate
                            >
                              {{ history.history['currentState'] }}
                            </button>
                          </div>
                        </div>

                        <div
                          class=" text-black-1 mr-2"
                          style="font-size: 14px;"
                          *ngIf="history.history.action_type == 'all_moved_to_submit'"
                        >
                          <img src="../../../assets/images/documents/group_user.png" class="cb-icon" />
                          <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                            history.completed_by
                          }}</span>
                          All
                          <span class="ml-1 text-black-1" style="font-weight: bold; font-size: 14px;" translate
                            >Responders</span
                          >
                          completed editing on

                          {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }} at
                          {{ history.history['action_timestamp'] | date: ' h:mm a' }}.<i
                            *ngIf="history.comment"
                            ngbTooltip="{{ history.comment }}"
                            style="color:gray;font-size:14px"
                            tooltipClass="cb-tooltip"
                            class="far fa-comment-alt mt-2"
                          ></i>
                          <div
                            class="mt-2 ml-5"
                            *ngIf="history.history['currentState'] != history.history['previousState']"
                          >
                            <button
                              class="ml-4 color col-5 text-black-1"
                              style="font-size: 14px; width: fit-content;"
                              translate
                            >
                              {{ history.history['previousState'] }}
                            </button>
                            <span class="col-2 w-100" style="margin-left: 1em">
                              <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </span>
                            <button
                              class="ml-3 color col-5 text-black-1"
                              style="font-size: 14px; width: fit-content;"
                              translate
                            >
                              {{ history.history['currentState'] }}
                            </button>
                          </div>
                        </div>

                        <div
                          class=" text-black-1 mr-2"
                          style="font-size: 14px;"
                          *ngIf="history.history.action_type == 'all_completed_edit'"
                        >
                          <img src="../../../assets/images/documents/group_user.png" class="cb-icon" />
                          All
                          <span class="ml-1 text-black-1" style="font-weight: bold; font-size: 14px;" translate
                            >Document_Details.Editors</span
                          >
                          {{ this.complete }}
                          {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }}&nbsp; at
                          {{ history.history['action_timestamp'] | date: ' h:mm a' }}.
                          <div
                            class="mt-2 ml-5"
                            *ngIf="history.history['currentState'] != history.history['previousState']"
                          >
                            <button
                              class="ml-4 color col-5 text-black-1"
                              style="font-size: 14px; width: fit-content;"
                              translate
                            >
                              {{ history.history['previousState'] }}
                            </button>
                            <span class="col-2 w-100" style="margin-left: 1em">
                              <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </span>
                            <button
                              class="ml-3 color col-5 text-black-1"
                              style="font-size: 14px; width: fit-content;"
                              translate
                            >
                              {{ history.history['currentState'] }}
                            </button>
                          </div>
                        </div>

                        <div
                          class=" text-black-1 mr-2"
                          style="font-size: 14px;"
                          *ngIf="history.history.action_type == 'reassign_form'"
                        >
                          <span
                            [ngClass]="history.reassigneResponderIntial.length === 1 ? 'i-circleinitial' : ''"
                            class="i-circle text-white mr-2 font-weight-bold col-12"
                            *ngIf="history.changeRequestAssignTo?.length == 1; else groupIcon1"
                            >{{ history.reassigneResponderIntial }}</span
                          >
                          <ng-template #groupIcon1>
                            <img src="../../../assets/images/documents/group_user.png" class="cb-icon" />
                          </ng-template>
                          <span class="text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                            history.changeRequestInitiatedBy
                          }}</span>
                          reassigned form to
                          <span
                            class="text-black-1 ml-1"
                            style="font-size: 14px; font-weight: bold;"
                            *ngIf="history.changeRequestAssignTo?.length == 1; else groupUser1"
                            >{{ history.changeRequestAssignTo[0] }}</span
                          >
                          <ng-template #groupUser1>
                            <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                              history.changeRequestAssignTo.toString()
                            }}</span>
                          </ng-template>
                          on {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }}&nbsp; at
                          {{ history.history['action_timestamp'] | date: ' h:mm a' }}.
                          <i
                            *ngIf="history.comment"
                            ngbTooltip="{{ history.comment }}"
                            style="color:gray;font-size:14px"
                            tooltipClass="cb-tooltip"
                            class="far fa-comment-alt mt-2"
                          ></i>

                          <div
                            class="text-black-1"
                            style="font-size: 14px; margin-top: 2.2em;margin-left: 0.11em;
                          margin-right: 0.11em;"
                            *ngIf="history.history['currentState'] != history.history['previousState']"
                          >
                            <span
                              [ngClass]="history.reqChangeInitiatedIntial.length === 1 ? 'i-circleinitial' : ''"
                              class="i-circle text-white mr-2 font-weight-bold col-12"
                              >{{ history.reqChangeInitiatedIntial }}</span
                            >
                            <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                              history.changeRequestInitiatedBy
                            }}</span
                            >{{ this.changeStatus }}
                            {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }}&nbsp; at
                            {{ history.history['action_timestamp'] | date: ' h:mm a' }}.
                          </div>

                          <div
                            class="mt-2 ml-5"
                            *ngIf="history.history['currentState'] != history.history['previousState']"
                          >
                            <button
                              class="ml-4 color col-5 text-black-1"
                              style="font-size: 14px; width: fit-content;"
                              translate
                            >
                              {{ history.history['previousState'] }}
                            </button>
                            <span class="col-2 w-100" style="margin-left: 1em">
                              <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </span>
                            <button
                              class="ml-3 color col-5 text-black-1"
                              style="font-size: 14px; width: fit-content;"
                              translate
                            >
                              {{ history.history['currentState'] }}
                            </button>
                          </div>
                        </div>

                        <div
                          class=" text-black-1 mr-2"
                          style="font-size: 14px;"
                          *ngIf="history.history.action_type == 'all_approvers_approved'"
                        >
                          <img src="../../../assets/images/documents/group_user.png" class="cb-icon" />
                          All
                          <span class="ml-1 text-black-1" style="font-weight: bold; font-size: 14px;" translate
                            >Document_Details.Approvers</span
                          >
                          approved the form on
                          {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }}&nbsp; at
                          {{ history.history['action_timestamp'] | date: ' h:mm a' }}.

                          <div
                            class="mt-2 ml-5"
                            *ngIf="history.history['currentState'] != history.history['previousState']"
                          >
                            <button
                              class="ml-4 color col-5 text-black-1"
                              style="font-size: 14px; width: fit-content;"
                              translate
                            >
                              {{ history.history['previousState'] }}
                            </button>
                            <span class="col-2 w-100" style="margin-left: 1em">
                              <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </span>
                            <button
                              class="ml-3 color col-5 text-black-1"
                              style="font-size: 14px; width: fit-content;"
                              translate
                            >
                              {{ history.history['currentState'] }}
                            </button>
                          </div>
                        </div>

                        <div
                          class=" text-black-1 mr-2 "
                          style="font-size: 14px; "
                          *ngIf="history.history.action_type == 'revoke_approver'"
                        >
                          <span
                            [ngClass]="history.revokeApproverInitial.length === 1 ? 'i-circleinitial' : ''"
                            class="i-circle text-white mr-2 font-weight-bold col-12"
                            >{{ history.revokeApproverInitial }}</span
                          >
                          <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                            history.approverRevokedBy
                          }}</span
                          >revoked
                          <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                            history.approverRevoke
                          }}</span>
                          {{ this.docOn }} {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }} at
                          {{ history.history['action_timestamp'] | date: ' h:mm a' }}.
                          <i
                            *ngIf="history.comment"
                            ngbTooltip="{{ history.comment }}"
                            style="color:gray;font-size:14px"
                            tooltipClass="cb-tooltip"
                            class="far fa-comment-alt mt-2"
                          ></i>
                          <div
                            class="mt-2 ml-5"
                            *ngIf="history.history['currentState'] != history.history['previousState']"
                          >
                            <button
                              class="ml-4 color col-5 text-black-1"
                              style="font-size: 14px; width: fit-content;"
                              translate
                            >
                              {{ history.history['previousState'] }}
                            </button>
                            <span class="col-2 w-100" style="margin-left: 1em">
                              <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </span>
                            <button
                              class="ml-3 color col-5 text-black-1"
                              style="font-size: 14px; width: fit-content;"
                              translate
                            >
                              {{ history.history['currentState'] }}
                            </button>
                          </div>
                        </div>

                        <div
                          class=" text-black-1 mr-2 "
                          style="font-size: 14px; "
                          *ngIf="history.history.action_type == 'revoke_responder'"
                        >
                          <span
                            [ngClass]="history.revokeResponderInitial.length === 1 ? 'i-circleinitial' : ''"
                            class="i-circle text-white mr-2 font-weight-bold col-12"
                            >{{ history.revokeResponderInitial }}</span
                          >
                          <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                            history.editorRevokedBy
                          }}</span>
                          revoked
                          <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                            history.editorRevoke
                          }}</span>
                          from the form on {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }} at
                          {{ history.history['action_timestamp'] | date: ' h:mm a' }}.
                          <i
                            *ngIf="history.comment"
                            ngbTooltip="{{ history.comment }}"
                            style="color:gray;font-size:14px"
                            tooltipClass="cb-tooltip"
                            class="far fa-comment-alt mt-2"
                          ></i>
                          <div
                            class="mt-2 ml-5"
                            *ngIf="history.history['currentState'] != history.history['previousState']"
                          >
                            <button
                              class="ml-4 color col-5 text-black-1"
                              style="font-size: 14px; width: fit-content;"
                              translate
                            >
                              {{ history.history['previousState'] }}
                            </button>
                            <span class="col-2 w-100" style="margin-left: 1em">
                              <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </span>
                            <button
                              class="ml-3 color col-5 text-black-1"
                              style="font-size: 14px; width: fit-content;"
                              translate
                            >
                              {{ history.history['currentState'] }}
                            </button>
                          </div>
                        </div>

                        <div
                          class=" text-black-1 mr-2 "
                          style="font-size: 14px; "
                          *ngIf="history.history.action_type == 'approve_form'"
                        >
                          <span
                            [ngClass]="history.approveFormInitial.length === 1 ? 'i-circleinitial' : ''"
                            class="i-circle text-white mr-2 font-weight-bold col-12"
                            >{{ history.approveFormInitial }} {{ history.approveBy }}</span
                          >

                          <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;"
                            >{{ history.published_by }}
                          </span>
                          approved the form on
                          {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }} at
                          {{ history.history['action_timestamp'] | date: ' h:mm a' }}.

                          <div
                            class="text-black-1"
                            style="font-size: 14px; margin-top: 2.2em;margin-left: 0.11em;
                          margin-right: 0.11em;"
                            *ngIf="history.history['currentState'] != history.history['previousState']"
                          >
                            <span
                              [ngClass]="history.approveFormInitial.length === 1 ? 'i-circleinitial' : ''"
                              class="i-circle text-white mr-2 font-weight-bold col-12"
                              >{{ history.approveFormInitial }}</span
                            >
                            <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                              history.published_by
                            }}</span
                            >{{ this.changeStatus }}
                            {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }}&nbsp; at
                            {{ history.history['action_timestamp'] | date: ' h:mm a' }}.
                          </div>

                          <div
                            class="mt-2 ml-5"
                            *ngIf="history.history['currentState'] != history.history['previousState']"
                          >
                            <button
                              class="ml-4 color col-5 text-black-1"
                              style="font-size: 14px; width: fit-content;"
                              translate
                            >
                              {{ history.history['previousState'] }}
                            </button>
                            <span class="col-2 w-100" style="margin-left: 1em">
                              <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </span>
                            <button
                              class="ml-3 color col-5 text-black-1"
                              style="font-size: 14px; width: fit-content;"
                              translate
                            >
                              {{ history.history['currentState'] }}
                            </button>
                          </div>
                        </div>

                        <div
                          class=" text-black-1 mr-2 "
                          style="font-size: 14px; "
                          *ngIf="history.history.action_type == 'pending_submission'"
                        >
                          <span
                            [ngClass]="history.editFormInitial.length === 1 ? 'i-circleinitial' : ''"
                            class="i-circle text-white mr-2 font-weight-bold col-12"
                            >{{ history.editFormInitial }}</span
                          >
                          <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                            history.editFormInitiatedBy
                          }}</span
                          >started editing the form on {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }} at
                          {{ history.history['action_timestamp'] | date: ' h:mm a' }}.
                          <div
                            class="mt-2 ml-5"
                            *ngIf="history.history['currentState'] != history.history['previousState']"
                          >
                            <button
                              class="ml-4 color col-5 text-black-1"
                              style="font-size: 14px; width: fit-content;"
                              translate
                            >
                              {{ history.history['previousState'] }}
                            </button>
                            <span class="col-2 w-100" style="margin-left: 1em">
                              <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </span>
                            <button
                              class="ml-3 color col-5 text-black-1"
                              style="font-size: 14px; width: fit-content;"
                              translate
                            >
                              {{ history.history['currentState'] }}
                            </button>
                          </div>
                        </div>

                        <div
                          class=" text-black-1 mr-2 "
                          style="font-size: 14px; "
                          *ngIf="history.history.action_type == 'complete_edit'"
                        >
                          <span
                            [ngClass]="history.editingDoneInitial.length === 1 ? 'i-circleinitial' : ''"
                            class="i-circle text-white mr-2 font-weight-bold col-12"
                            >{{ history.editingDoneInitial }}</span
                          >
                          <span class=" text-black-1 mr-2  ml-1" style="font-size: 14px; font-weight: bold;">{{
                            history.completed_by
                          }}</span
                          >completed editing the form on
                          {{ history.history['action_timestamp'] | date: 'MM-dd-yyyy' }} at
                          {{ history.history['action_timestamp'] | date: ' h:mm a' }}.
                          <div
                            class="mt-2 ml-5"
                            *ngIf="history.history['currentState'] != history.history['previousState']"
                          >
                            <button
                              class="ml-4 color col-5 text-black-1"
                              style="font-size: 14px; width: fit-content;"
                              translate
                            >
                              {{ history.history['previousState'] }}
                            </button>
                            <span class="col-2 w-100" style="margin-left: 1em">
                              <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            </span>
                            <button
                              class="ml-3 color col-5 text-black-1"
                              style="font-size: 14px; width: fit-content;"
                              translate
                            >
                              {{ history.history['currentState'] }}
                            </button>
                          </div>
                        </div>
                      </h4>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="class3">
    <div class="card">
      <div class="container">
        <div class="row mt-4">
          <div class="col max-w-500 pr-3 setting-actions">
            <h4 class="text-black-1 text-left">
              <img src="../../../../assets/images/documents/author.png" class="cb-icon" style="margin-left: -17px;" />
              <label class="text-black-1 p-2 mr-2 col-5" style="font-size: 14px;" translate>Requestor </label>
              <div class="col-12 max-w-500 pr-3 mb-4 ">
                <h4 class="text-black-3 text-left" scope="row">
                  <span
                    [ngClass]="formDetails['initials'].length === 1 ? 'i-circleinitial' : ''"
                    class="i-circle text-white mr-2 font-weight-bold col-12 "
                    style="margin-left: 30px;"
                    >{{ formDetails['initials'] }}</span
                  ><label class="text-black-3 p-2 mr-2 col-5" style="font-size: 14px; margin-left: -1px;" translate>{{
                    formDetails.created_by.name ? formDetails.created_by.name : formDetails.created_by.email
                  }}</label>
                </h4>
              </div>
            </h4>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col max-w-500 pr-3  pl-1 setting-actions">
            <label class="text-black-1 p-2 mr-2 col-10" style="font-size: 14px; margin-left: -7px;">
              <img src="../../../assets/images/documents/group_user.png" class="cb-icon editoricon" />
              Responder
            </label>

            <span class="badge badge-info badge-pill " style="background: #E6E6E6;border-radius: 50%; ">
              <i class="fa fa-angle-down ripple" aria-hidden="true" (click)="expand()" *ngIf="!isOpen"></i>
              <i class="fa fa-angle-up ripple" aria-hidden="true" (click)="shrink()" *ngIf="isOpen"></i>
            </span>

            <div class="row mt-4" *ngIf="isOpen">
              <div
                class="col-12 max-w-500 pr-3 mb-4 "
                *ngFor="let responder of formDetails['responders']; let i = index"
              >
                <h4 class="text-black-1 text-left editorlist" scope="row">
                  <span
                    [ngClass]="responder?.initials.length === 1 ? 'i-circleinitial' : ''"
                    class="i-circle text-white mr-2 font-weight-bold col-12"
                    >{{ responder?.initials }}</span
                  >{{ responder['user']['name'] ? responder['user']['name'] : responder['user']['email'] }}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col max-w-500 pr-3  pl-1 setting-actions">
            <label class="text-black-1 p-2 mr-2 col-10" style="font-size: 14px; margin-left: -7px;">
              <img src="../../../assets/images/documents/group_user.png" class="cb-icon editoricon" />
              Approver
            </label>
            <span
              class="badge badge-info badge-pill "
              style="background: #E6E6E6;
                                    border-radius: 50%; "
            >
              <i
                class="fa fa-angle-down ripple"
                aria-hidden="true"
                (click)="expandApprover()"
                *ngIf="!isApproverOpen"
              ></i>
              <i class="fa fa-angle-up ripple" aria-hidden="true" (click)="shrinkApprover()" *ngIf="isApproverOpen"></i>
            </span>
            <div class="row mt-4" *ngIf="isApproverOpen">
              <div class="col-12 max-w-500 pr-3 mb-4 " *ngFor="let approver of formDetails['approvers']; let i = index">
                <h4 class="text-black-1 text-left editorlist" scope="row">
                  <span
                    [ngClass]="approver?.initials.length === 1 ? 'i-circleinitial' : ''"
                    class="i-circle text-white mr-2 font-weight-bold col-12"
                    >{{ approver?.initials }}</span
                  >{{ approver['user']['name'] ? approver['user']['name'] : approver['user']['email'] }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
