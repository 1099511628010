import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganisationAdminService } from '@app/Organisation-admin/organisation-admin.service';
import { AuthenticationService, Logger } from '@app/core';
import { OrganisationDashboardService } from '@app/Organisation-admin/orgDashboard/orgDashboard.service';
import { OrganisationService } from '@app/core/organisation.service';
import { finalize } from 'rxjs/operators';
import { filter } from 'rxjs/operators';
import 'rxjs/add/operator/filter';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';

const log = new Logger('Dashboard history');

@Component({
  selector: 'app-org-dashboard',
  templateUrl: './orgDashboardHistory.component.html',
  styleUrls: ['./orgDashboardHistory.scss']
})
export class OrgDashboardHistoryComponent implements OnInit, OnDestroy {
  loaderArr: any = [];
  drillDownEventData: any = [];
  drillDownEventHistoryData: any = [];
  upcomingEventData: any = [];
  nextPage: any = null;
  drillDownTableData: any;
  drillDownEventTypeSelected: string;
  organisationID: string;
  isLoading = false;
  error: any;
  event_Type: any;
  selectedProgram = '';
  listOfCompliance: any;
  guidSubscription: any;
  historyData: object;
  sub: any;
  accArray: any[] = [];
  loading = true;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private orgAdminService: OrganisationAdminService,
    private authenticationService: AuthenticationService,
    private orgDashboardService: OrganisationDashboardService,
    private orgService: OrganisationService,
    private translate: TranslateService,
    private toaster: ToasterService
  ) {}

  ngOnInit() {
    this.sub = this.route.queryParams.subscribe(params => {
      if (params) {
        this.historyData = params;
      } else {
        this.historyData['type'] = 'OVERALL_EVENTS';
        this.historyData['status'] = 'pending_events';
      }
    });
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(org_guid => {
      this.organisationID = org_guid;
      this.programType(this.organisationID);
      this.closeDrillDown();
    });
  }

  /** Detect change in program on selection **/
  programChange(event: any) {
    this.selectedProgram = event;
    this.selectedProgramRequest(this.selectedProgram);
    this.closeDrillDown();
  }

  closeDrillDown() {
    this.drillDownEventData = [];
  }

  /** Reference call to get list of programs **/
  programType(id: any) {
    this.isLoading = true;
    this.orgDashboardService
      .organisationalProgram(id)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any = []) => {
          this.loading = false;
          this.listOfCompliance = data;
          this.selectedProgram = this.listOfCompliance[0]['guid'];
          this.selectedProgramRequest(this.selectedProgram);
        },
        (error: any) => {
          log.debug(`Select program error: ${error}`);
          this.error = error;
        }
      );
  }

  selectedProgramRequest(programGuid: any) {
    this.drillDownData(this.historyData);
  }

  // Running event for (No Participation events)
  runEvent(guid: string, position: any) {
    // To map which button is loading
    this.loaderArr.push(position);
    this.orgAdminService.runReviewEventNow(guid).subscribe(
      (data: any) => {
        this.drillDownEventData[position]['is_running'] = 'Running';
        this.clearLoader(position);
      },
      error => {
        console.log('Some error has occcurred. running');
        if (error.error.is_archived[0]) {
          this.translate.get(error.error.is_archived[0]).subscribe(res => {
            this.toaster.showError(res);
          });
        } else {
          this.translate.get('organisation_add_event.add_event_toaster_message.event_run_error').subscribe(res => {
            this.toaster.showError(res);
          });
        }
        this.clearLoader(position);
      }
    );
  }

  stopEvent(guid: string, position: any) {
    this.loaderArr.push(position);
    this.orgAdminService.stopReviewEventNow(guid).subscribe(
      (data: any) => {
        this.clearLoader(position);
        this.drillDownEventData[position]['is_running'] = 'Not Running';
      },
      error => {
        if (error.error.is_archived[0]) {
          this.translate.get(error.error.is_archived[0]).subscribe(res => {
            this.toaster.showError(res);
          });
        } else {
          this.translate.get('organisation_add_event.add_event_toaster_message.event_stop_error').subscribe(res => {
            this.toaster.showError(res);
          });
        }
        this.clearLoader(position);
      }
    );
  }

  // Function to clear loader array.
  clearLoader(value: any): void {
    const index = this.loaderArr.indexOf(value);
    if (index > -1) {
      this.loaderArr.splice(index, 1);
    }
  }
  // Running event ends

  drillDownData(historyData: any) {
    this.drillDownTableData = historyData;
    this.drillDownEventTypeSelected = this.drillDownTableData['type'].replace('_', ' ');
    this.drillDownTable(
      this.organisationID,
      this.selectedProgram,
      this.drillDownTableData['type'],
      this.drillDownTableData['status']
    );
  }

  drillDownTable(org_uuid: string, prog_uuid: string, frequency: string, slug: string) {
    this.event_Type = slug;
    this.isLoading = true;
    this.orgDashboardService
      .organisationalDrillDownData(org_uuid, prog_uuid, frequency, slug)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any = []) => {
          this.drillDownEventData = data;
        },
        (error: any) => {
          log.debug(`Select program error: ${error}`);
          this.error = error;
        }
      );
  }

  /** To show drill down history data **/
  drillDownHistoryData(org_uuid: string, event_uuid: string, page: string) {
    this.isLoading = true;
    this.orgDashboardService
      .organisationalDrillDownHistoryData(org_uuid, event_uuid, page)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any = []) => {
          this.drillDownEventHistoryData = this.drillDownEventHistoryData.concat(data['past_events']['results']);
          this.upcomingEventData = data['next_run_time'];
          if (data['past_events']['next'] === null) {
            this.nextPage = null;
          } else {
            this.nextPage = (data['past_events']['current_page'] + 1).toString();
          }
        },
        (error: any) => {
          log.debug(`Select program error: ${error}`);
          this.error = error;
        }
      );
  }

  /** To toggle accordian (For display and hide history information) **/
  toggle(index: any, event_id: string): void {
    this.drillDownEventHistoryData = [];
    this.nextPage = null;
    if (this.accArray.includes(index)) {
      const pos = this.accArray.indexOf(index);
      if (index > -1) {
        this.accArray.splice(pos, 1);
      }
    } else {
      this.accArray = [];
      this.accArray.push(index);
      this.drillDownHistoryData(this.organisationID, event_id, '1');
    }
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
    this.sub.unsubscribe();
  }

  showMorePastEvents(index: any, event_id: string): void {
    console.log('I am here...');
    // this.accArray.push(index);
    this.drillDownHistoryData(this.organisationID, event_id, this.nextPage);
  }
}
