import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-control-switch-dialog',
  templateUrl: './control-switch-dialog.component.html',
  styleUrls: ['./control-switch-dialog.component.scss']
})
export class ControlSwitchDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ControlSwitchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any = {}
  ) {}

  ngOnInit(): void {}

  sendResponse(value: boolean): void {
    this.dialogRef.close(value);
  }
}
