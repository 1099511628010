import { Routes, Route } from '@angular/router';

import { AuthenticationGuard } from 'app/core/index';
import { OrgAdminShellComponent } from './orgAdminshell.component';
import { SettingsSubShellComponent } from '@app/shells/shell/settingsSubShell';

/**
 * Provides helper methods to create routes.
 */
export class OrgAdminShell {
  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */
  static childRoutes(routes: Routes): Route {
    return {
      path: 'organisation',
      component: OrgAdminShellComponent,
      children: routes,
      canActivate: [AuthenticationGuard],
      // Reuse ShellComponent instance when navigating between child views
      data: { reuse: true }
    };
  }
}

export class OrgAdminSettingsShell {
  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */
  static childRoutes(routes: Routes): Route {
    return {
      path: 'settings',
      component: SettingsSubShellComponent,
      children: routes,
      canActivate: [AuthenticationGuard],
      // Reuse ShellComponent instance when navigating between child views
      data: { reuse: true }
    };
  }
}
