import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, ValidatorFn, AbstractControl } from '@angular/forms';
import { ModalService } from '@app/core/modal/modal.service';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { take } from 'rxjs/operators';
import { OrganisationService } from '@app/core/organisation.service';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { ActivatedRoute } from '@angular/router';
import { NgbDate, NgbDateStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from '@app/settings/settings.service';

@Component({
  selector: 'app-create-form-management',
  templateUrl: './create-form-management.component.html',
  styleUrls: ['./create-form-management.component.scss']
})
export class CreateFormManagementComponent implements OnInit {
  minDate: NgbDateStruct;
  addFormRequest: FormGroup;
  submitted = false;
  isloading = false;
  success: boolean;
  templateName: any = '';
  guidSubscription: any;
  organisationID: string | null;
  templateGuid: any;
  formGuid: string | null;
  type: string;
  formData: any;
  createDocStorageId: string = 'create_doc_folder_data';
  error: any;
  formError: any;
  backend_error: string;
  templateErrorMessage = 'Please select at least one template.';
  store_due_date = '';
  showVendorDetails = false;

  constructor(
    private backendErrorService: BackendErrorService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private documentsService: DocumentsService,
    private orgService: OrganisationService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private route: ActivatedRoute,
    private settingsService: SettingsService
  ) {
    this.formGuid = this.route.snapshot.queryParamMap.get('formGuid');
    const currentDate = new Date();
    this.minDate = { year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate() };
  }

  ngOnInit(): void {
    const currentDate = new Date();
    localStorage.removeItem('create_doc_folder_data');
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.organisationID = guid;
    });

    this.formGuid && this.getFormData();

    this.addFormRequest = this.formBuilder.group({
      title: ['', [Validators.required, Validators.maxLength(255)]],
      description: ['', [Validators.required, Validators.maxLength(4096)]],
      email_message: ['', Validators.maxLength(4096)],
      template: ['', [Validators.required]],
      vendor: ['', [Validators.maxLength(255)]],
      vendor_website: ['', [Validators.maxLength(255), Validators.httpsURL()]],
      vendor_address: ['', [Validators.maxLength(255)]],
      vendor_contact: ['', Validators.ValidatePhoneNumber()],
      vendor_email: ['', [Validators.maxLength(255), Validators.email()]]
    });
  }

  toggleVendorDetails() {
    this.showVendorDetails = !this.showVendorDetails;
  }

  onDateSelection(date: any) {
    if (date) {
      let myDate = new Date(date.year, date.month - 1, date.day + 1);
      this.store_due_date = myDate.toISOString().split('T')[0];
    } else {
      this.store_due_date = this.store_due_date;
    }
  }

  getFormData() {
    this.isloading = true;
    this.documentsService.getFormData(this.organisationID, this.formGuid).subscribe((data: any) => {
      this.formData = data;
      this.isloading = false;
      this.templateName = this.formData['source_template'];
      if (this.formData['due_date']) {
        this.store_due_date = this.formData['due_date'];
        const due_date_arr = this.formData['due_date'].split('-');
        this.formData['due_date'] = new NgbDate(
          parseInt(due_date_arr[0]),
          parseInt(due_date_arr[1]),
          parseInt(due_date_arr[2])
        );
      }
      this.addFormRequest.patchValue({ ...this.formData });
      this.addFormRequest.markAsPristine();
    });
  }

  ngAfterViewInit() {
    this.documentsService
      .getTemplateName()
      .subscribe((item: any) => (this.templateName = this.documentsService.getTemplate()));
    this.documentsService
      .getTemplateName()
      .subscribe((item: any) => (this.templateGuid = this.documentsService.getTemplateGuid()));
  }

  onChangeTitle(evt: any) {
    if (evt.type == 'keyup' && this.backend_error) {
      this.backend_error = '';
    }
  }

  onReset() {
    this.submitted = false;
    this.addFormRequest.reset();
    localStorage.removeItem(this.createDocStorageId);
    this.templateName = '';
    this.backend_error = '';
  }

  saveForm() {
    this.submitted = true;
    this.templateGuid = this.templateGuid;
    if (this.addFormRequest.valid) {
      this.isloading = true;
      const finalFormData = Object.assign({}, this.addFormRequest.value);
      finalFormData['template'] = this.templateGuid;
      this.formGuid && delete finalFormData['template'];
      this.submit(finalFormData);
    } else {
      return;
    }
  }

  submit(finalFormData: any): void {
    this.documentsService
      .submitFormData(this.organisationID, this.formGuid, finalFormData)
      .pipe(
        finalize(() => {
          this.addFormRequest.markAsPristine();
          this.isloading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.modalService.custom(CustomDialogComponent, {
            title: this.formGuid ? 'Form Edited' : 'Form Created',
            modalType: this.formGuid ? 'Form Edited' : 'Form Created',
            formGuid: data.guid,
            formTitle: data.title,
            requestorGuid: data.created_by.guid
          });
          this.error = {
            title: '',
            description: ''
          };
          this.submitted = false;
          if (this.formGuid) return;
          this.addFormRequest.reset();
          this.templateName = '';
        },
        (error: any) => {
          this.isloading = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
  }

  openSelectTemplateForm() {
    this.selectTemplateOpenModal();
  }

  selectTemplateOpenModal(): void {
    let data = {
      title: 'Select Template',
      modalType: 'Select Template',
      template_guid: this.templateGuid,
      storage_id: this.createDocStorageId,
      type: 'form'
    };
    if (this.formGuid) {
      delete data['storage_id'];
      delete data['template_guid'];
    }
    this.modalService
      .custom(CustomDialogComponent, {
        ...data
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
        } else {
          console.log('Cancelled.');
        }
      });
  }
}
