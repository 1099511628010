<!--<ng-template #commonPopup let-modal>-->
<div class="modal-header cb-modal-header" *ngIf="!seperateDiag">
  <h4 class="modal-title font-weight-bold text-black-1">{{ dialogData.event.meeting_name }}</h4>
</div>
<div class="modal-body cb-modal-body">
  <form>
    <div class="row mt-3">
      <div class="col-4">
        <label for="dateOfBirth" class="text-black-1">Select a time to run the task</label>
      </div>
      <div class="col-8">
        <div class="cb-input cb-time">
          <i class="fa fa-clock"></i>
          <input
            placeholder="Time"
            aria-label="Time"
            class="time_input"
            [ngxTimepicker]="darkPicker"
            [value]="data.time"
            readonly
          />
          <ngx-material-timepicker
            class="timepicker"
            #darkPicker
            [ngxMaterialTimepickerTheme]="darkTheme"
            (timeSet)="timeSet($event)"
          ></ngx-material-timepicker>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-4">
        <label for="dateOfBirth" class="text-black-1">Select a schedule</label>
      </div>
      <div class="input-group col-8">
        <select
          class="form-control cb-input"
          [(ngModel)]="data.event_schedule"
          [ngModelOptions]="{ standalone: true }"
          (change)="dataRefresh($event.target.value)"
        >
          <option *ngFor="let schedule of schedules" [value]="schedule.value">
            {{ schedule.display_value }}
          </option>
        </select>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-4">
        <label class="text-black-1">Allowed duration</label>
      </div>
      <div class="input-group col-8">
        <select
          class="form-control cb-input"
          [(ngModel)]="allowed_duration_selected"
          [ngModelOptions]="{ standalone: true }"
        >
          <option value="1" *ngFor="let duration of allowed_duration" [value]="duration">{{ duration }}</option>
        </select>
      </div>
    </div>

    <!--      <div class="modal-header cb-modal-header" *ngIf="data.event_schedule === 'DAILY'">-->
    <!--      <h4 class="modal-title font-weight-bold text-black-1">The event will run once every day.</h4>-->
    <!--      </div>-->

    <!--    <div class="modal-header cb-modal-header" *ngIf="data.event_schedule === 'WEEKLY'">-->
    <!--      <h4 class="modal-title font-weight-bold text-black-1">The event will run once every week.</h4>-->
    <!--    </div>-->
    <div class="row mt-3" *ngIf="data.event_schedule === 'WEEKLY'">
      <div class="col-4">
        <label for="dateOfBirth" class="text-black-1">Day Of the Week</label>
      </div>
      <div class="input-group col-8">
        <select class="form-control cb-input" [(ngModel)]="data.day_of_week" [ngModelOptions]="{ standalone: true }">
          <option *ngFor="let day of days" [value]="day.value">
            {{ day.display_string }}
          </option>
        </select>
      </div>
    </div>

    <!--    <div class="modal-header cb-modal-header" *ngIf="data.event_schedule === 'BI-WEEKLY'">-->
    <!--      <h4 class="modal-title font-weight-bold text-black-1">The event will run once every two weeks.<br />-->
    <!--        Start from</h4>-->
    <!--    </div>-->
    <div class="row mt-3" *ngIf="data.event_schedule === 'BI-WEEKLY'">
      <div class="col-4">
        <label for="dateOfBirth" class="text-black-1">Week</label>
      </div>
      <div class="input-group col-8">
        <select class="form-control cb-input" [(ngModel)]="data.week" [ngModelOptions]="{ standalone: true }">
          <option *ngFor="let week of numbered_bi" [value]="week.value">
            {{ week.display_string }}
          </option>
        </select>
      </div>
    </div>

    <!--    <div class="modal-header cb-modal-header" *ngIf="data.event_schedule === 'MONTHLY'">-->
    <!--      <h4 class="modal-title font-weight-bold text-black-1">The event will run once every month. <br />-->
    <!--        On the</h4>-->
    <!--    </div>-->
    <div class="row mt-3" *ngIf="data.event_schedule === 'MONTHLY'">
      <div class="col-4">
        <label for="dateOfBirth" class="text-black-1">Week Of the Month</label>
      </div>
      <div class="input-group col-8">
        <select class="form-control cb-input" [(ngModel)]="data.day" [ngModelOptions]="{ standalone: true }">
          <option *ngFor="let week of numbered_week" [value]="week.value">
            {{ week.display_string }}
          </option>
        </select>
      </div>
    </div>

    <div class="row mt-3" *ngIf="data.event_schedule === 'MONTHLY'">
      <div class="col-4">
        <label for="dateOfBirth" class="text-black-1">Day Of the Week</label>
      </div>
      <div class="input-group col-8">
        <select class="form-control cb-input" [(ngModel)]="data.day_of_week" [ngModelOptions]="{ standalone: true }">
          <option *ngFor="let day of days" [value]="day.value">
            {{ day.display_string }}
          </option>
        </select>
      </div>
    </div>

    <!--    <div class="modal-header cb-modal-header" *ngIf="data.event_schedule === 'BI-MONTHLY'">-->
    <!--      <h4 class="modal-title font-weight-bold text-black-1">The event will run once every month. <br />-->
    <!--        Start from</h4>-->
    <!--    </div>-->
    <div class="row mt-3" *ngIf="data.event_schedule === 'BI-MONTHLY'">
      <div class="col-4">
        <label for="dateOfBirth" class="text-black-1">Month</label>
      </div>
      <div class="input-group col-8">
        <select class="form-control cb-input" [(ngModel)]="data.month" [ngModelOptions]="{ standalone: true }">
          <option *ngFor="let month of numbered_bi" [value]="month.value">
            {{ month.display_string }}
          </option>
        </select>
      </div>
      <div class="col-4 mt-3">
        <label for="dateOfBirth" class="text-black-1">Week Of the Month</label>
      </div>
      <div class="input-group col-8 mt-3">
        <select class="form-control cb-input" [(ngModel)]="data.day" [ngModelOptions]="{ standalone: true }">
          <option *ngFor="let week of numbered_week" [value]="week.value">
            {{ week.display_string }}
          </option>
        </select>
      </div>
      <div class="col-4 mt-3">
        <label for="dateOfBirth" class="text-black-1">Day Of the Week</label>
      </div>
      <div class="input-group col-8 mt-3">
        <select class="form-control cb-input" [(ngModel)]="data.day_of_week" [ngModelOptions]="{ standalone: true }">
          <option *ngFor="let day of days" [value]="day.value">
            {{ day.display_string }}
          </option>
        </select>
      </div>
    </div>

    <!--    <div class="modal-header cb-modal-header" *ngIf="data.event_schedule === 'QUARTERLY'">-->
    <!--      <h4 class="modal-title font-weight-bold text-black-1">The event will run once every quarter.<br />-->
    <!--        On</h4>-->
    <!--    </div>-->
    <div class="row mt-3" *ngIf="data.event_schedule === 'QUARTERLY'">
      <div class="col-4">
        <label for="dateOfBirth" class="text-black-1">Month</label>
      </div>
      <div class="input-group col-8">
        <select class="form-control cb-input" [(ngModel)]="data.month" [ngModelOptions]="{ standalone: true }">
          <option *ngFor="let month of numbered_quarter" [value]="month.value">
            {{ month.display_string }}
          </option>
        </select>
      </div>
      <div class="col-4 mt-3">
        <label for="dateOfBirth" class="text-black-1">Week Of the Month</label>
      </div>
      <div class="input-group col-8 mt-3">
        <select class="form-control cb-input" [(ngModel)]="data.day" [ngModelOptions]="{ standalone: true }">
          <option *ngFor="let week of numbered_week" [value]="week.value">
            {{ week.display_string }}
          </option>
        </select>
      </div>
      <div class="col-4 mt-3">
        <label for="dateOfBirth" class="text-black-1">Day Of the Week</label>
      </div>
      <div class="input-group col-8 mt-3">
        <select class="form-control cb-input" [(ngModel)]="data.day_of_week" [ngModelOptions]="{ standalone: true }">
          <option *ngFor="let day of days" [value]="day.value">
            {{ day.display_string }}
          </option>
        </select>
      </div>
    </div>

    <!--    <div class="modal-header cb-modal-header" *ngIf="data.event_schedule === 'SEMI-ANNUALLY'">-->
    <!--      <h4 class="modal-title font-weight-bold text-black-1">The event will run once every half year.<br />-->
    <!--        In the months of</h4>-->
    <!--    </div>-->
    <div class="row mt-3" *ngIf="data.event_schedule === 'SEMI-ANNUALLY'">
      <div class="col-4">
        <label for="dateOfBirth" class="text-black-1">Months</label>
      </div>
      <div class="input-group col-8">
        <select class="form-control cb-input" [(ngModel)]="data.month" [ngModelOptions]="{ standalone: true }">
          <option *ngFor="let months of months_half_year" [value]="months.value">
            {{ months.display_string }}
          </option>
        </select>
      </div>
      <div class="col-4 mt-3">
        <label for="dateOfBirth" class="text-black-1">Week Of the Month</label>
      </div>
      <div class="input-group col-8 mt-3">
        <select class="form-control cb-input" [(ngModel)]="data.day" [ngModelOptions]="{ standalone: true }">
          <option *ngFor="let week of numbered_week" [value]="week.value">
            {{ week.display_string }}
          </option>
        </select>
      </div>
      <div class="col-4 mt-3">
        <label for="dateOfBirth" class="text-black-1">Day Of the Week</label>
      </div>
      <div class="input-group col-8 mt-3">
        <select class="form-control cb-input" [(ngModel)]="data.day_of_week" [ngModelOptions]="{ standalone: true }">
          <option *ngFor="let day of days" [value]="day.value">
            {{ day.display_string }}
          </option>
        </select>
      </div>
    </div>

    <!--    <div class="modal-header cb-modal-header" *ngIf="data.event_schedule === 'ANNUALLY'">-->
    <!--      <h4 class="modal-title font-weight-bold text-black-1">The event will run once every year.<br />-->
    <!--        In the month of</h4>-->
    <!--    </div>-->
    <div class="row mt-3" *ngIf="data.event_schedule === 'ANNUALLY'">
      <div class="col-4">
        <label for="dateOfBirth" class="text-black-1">Month</label>
      </div>
      <div class="input-group col-8">
        <select class="form-control cb-input" [(ngModel)]="data.month" [ngModelOptions]="{ standalone: true }">
          <option *ngFor="let months of months_year" [value]="months.value">
            {{ months.display_string }}
          </option>
        </select>
      </div>
      <div class="col-4 mt-3">
        <label for="dateOfBirth" class="text-black-1">Week Of the Month</label>
      </div>
      <div class="input-group col-8 mt-3">
        <select class="form-control cb-input" [(ngModel)]="data.day" [ngModelOptions]="{ standalone: true }">
          <option *ngFor="let week of numbered_week" [value]="week.value">
            {{ week.display_string }}
          </option>
        </select>
      </div>
      <div class="col-4 mt-3">
        <label for="dateOfBirth" class="text-black-1">Day Of the Week</label>
      </div>
      <div class="input-group col-8 mt-3">
        <select class="form-control cb-input" [(ngModel)]="data.day_of_week" [ngModelOptions]="{ standalone: true }">
          <option *ngFor="let day of days" [value]="day.value">
            {{ day.display_string }}
          </option>
        </select>
      </div>
    </div>

    <!--    <div class="modal-header cb-modal-header" *ngIf="data.event_schedule === 'EVERY TWO YEARS'">-->
    <!--      <h4 class="modal-title font-weight-bold text-black-1">The event will run once in every two year. <br />-->
    <!--        In the</h4>-->
    <!--    </div>-->
    <div class="row mt-3" *ngIf="data.event_schedule === 'EVERY TWO YEARS'">
      <div class="col-4">
        <label for="dateOfBirth" class="text-black-1">Year</label>
      </div>
      <div class="input-group col-8">
        <select class="form-control cb-input" [(ngModel)]="data.year" [ngModelOptions]="{ standalone: true }">
          <option *ngFor="let year of numbered_bi" [value]="year.value">
            {{ year.display_string }}
          </option>
        </select>
      </div>
      <div class="col-4 mt-3">
        <label for="dateOfBirth" class="text-black-1">Month</label>
      </div>
      <div class="input-group col-8 mt-3">
        <select class="form-control cb-input" [(ngModel)]="data.month" [ngModelOptions]="{ standalone: true }">
          <option *ngFor="let months of months_year" [value]="months.value">
            {{ months.display_string }}
          </option>
        </select>
      </div>
      <div class="col-4 mt-3">
        <label for="dateOfBirth" class="text-black-1">Week Of the Month</label>
      </div>
      <div class="input-group col-8 mt-3">
        <select class="form-control cb-input" [(ngModel)]="data.day" [ngModelOptions]="{ standalone: true }">
          <option *ngFor="let week of numbered_week" [value]="week.value">
            {{ week.display_string }}
          </option>
        </select>
      </div>
      <div class="col-4 mt-3">
        <label for="dateOfBirth" class="text-black-1">Day Of the Week</label>
      </div>
      <div class="input-group col-8 mt-3">
        <select class="form-control cb-input" [(ngModel)]="data.day_of_week" [ngModelOptions]="{ standalone: true }">
          <option *ngFor="let day of days" [value]="day.value">
            {{ day.display_string }}
          </option>
        </select>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer border-0 cb-modal-footer" *ngIf="!seperateDiag">
  <button class="btn-cancel ml-4 w-100" (click)="closeDiag()">Cancel</button>
  <button class="btn-save ml-4 w-100" *ngIf="data.event_schedule" (click)="saveSchedule()">Submit</button>
</div>
