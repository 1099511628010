<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div class="modal-content">
    <div class="modal-header cb-modal-header">
      <div>
        <h4 class="modal-title font-weight-bold text-black-1" translate>Approve_Training_Document.Title</h4>
        <i
          class="fa fa-times text-black-1"
          style="width: 12px;
                height: 12px;
                right: 1em;
                cursor: pointer;
                position: absolute;
                top: 1.3em;"
          (click)="activeModal.close(false)"
          ngbAutoFocus
          translate
        ></i>
      </div>
    </div>
    <div class="modal-body">
      <div class="row mt-1 mb-3" *ngIf="backend_error">
        <div class="col-12">
          <span class="text-danger backend_error" translate> * {{ backend_error }}</span>
        </div>
      </div>

      <div *ngIf="signature_error && !this.UserSignature">
        <div class="col-12 mt-2 error-mssg" *ngIf="signature_error && !this.UserSignature">
          <label class="text-danger" translate
            >Approve_Training_Document.approve_training_document.*Signature is required.</label
          >
        </div>
      </div>
      <form [formGroup]="ApproveTrainingDocumentForm" novalidate>
        <div class="row mt-3">
          <div class="col-3">
            <label class="text-black-1 p-2" translate>Approve_Training_Document.Campaign_Title </label>
          </div>
          <div class="col-9">
            <div class="input-group">
              <input class="form-control cb-input" value="{{ CampaignName }}" disabled />
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-3">
            <label for="remarks" class="text-black-1 p-2" translate
              >Approve_Training_Document.approve_training_document.Remarks</label
            >
          </div>
          <div class="input-group col-9">
            <textarea
              id="remarks"
              class="form-control cb-input"
              [placeholder]="'Approve_Training_Document.approve_training_document.Remarks' | translate"
              name="remarks"
              type="text"
              formControlName="remarks"
              cols="64"
              rows="5"
              [maxLength]="4097"
            >
            </textarea>
            <div class=" col-12 error-mssg" *ngIf="(f.remarks.touched || f.remarks.dirty) && f.remarks.errors">
              <label for="remarks" class="text-danger" *ngIf="f.remarks.errors.maxLength" translate>
                Mark_Editing_Done.Remarks must be 4096 characters or less</label
              >
            </div>
          </div>
        </div>
        <div class="row mt-3 signature-upload">
          <div class="col-4">
            <div style="padding: 20px;" *ngIf="isSignatureLoading">
              <i
                class="fa fa-spinner fa-spin"
                style="font-size:24px;transform-origin: center;width: 20px;height: 20px;"
              ></i>
            </div>
            <img class="user-avatar-pic" *ngIf="UserSignature && !isSignatureLoading" [src]="UserSignature" />
          </div>
        </div>
        <div *ngIf="!UserSignature">
          <label class="text-black-1 mt-4 pl-2"> Update Signature </label>
          <div class="d-flex mt-1 pl-4">
            <mat-icon class="text-black-1" style="font-size:25px" (click)="OpenChangeSignatureModal()"
              >desktop_windows</mat-icon
            ><a
              class="text-black-1 cursor-pointer ml-2 mt-1"
              (click)="OpenChangeSignatureModal()"
              style="font-size: 12px;"
              >Upload Signature</a
            >
            <mat-icon class="ml-4 text-black-1" style="font-size:25px" (click)="OpenDrawSignatureModal()">edit</mat-icon
            ><a
              class="text-black-1 cursor-pointer ml-2 mt-1"
              (click)="OpenDrawSignatureModal()"
              style="font-size: 12px;"
              >Draw Signature</a
            >
            <mat-icon class="ml-4 text-black-1" style="font-size:25px" (click)="OpenGenerateSignatureModal(userName)"
              >add_circle_outline</mat-icon
            ><a
              class="text-black-1 cursor-pointer ml-2 mt-1"
              (click)="OpenGenerateSignatureModal(userName)"
              style="font-size: 12px;"
              >Generate Signature</a
            >
          </div>
        </div>
        <div class="row mt-2" *ngIf="UserSignature">
          <div class="col-12 text-black-1">
            <div class="input-group p-2 m-2">
              <label class="form-check-label text-black-1 checkbox-2" translate>
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="inlineRadioOptions"
                  id="inlinecheck"
                  formControlName="agreement_accepted"
                />
                <span class="checkmark" translate></span>
                Approve_Training_Document.approve_training_document.agreement_text
              </label>
              <div class="col-12 error-mssg" *ngIf="!agreement_accepted">
                <label for="agreement_accepted" class="text-danger" translate
                  >Approve_Training_Document.approve_training_document.*Please accept the agreement.</label
                >
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer border-0 cb-modal-footer">
      <button class="btn-cancel ml-4 w-100" (click)="activeModal.close(false)" ngbAutoFocus translate>
        Approve_Training_Document.approve_training_document.Cancel
      </button>
      <button class="btn-save ml-4 w-100" *ngIf="!isLoading" (click)="save()" translate>
        Approve_Training_Document.approve_training_document.Submit
      </button>
      <button class="btn-save ml-4 w-100" disabled *ngIf="isLoading">
        <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
      </button>
    </div>
  </div>
</ng-container>
