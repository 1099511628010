<div>
  <div class="modal-header cb-modal-header">
    <div>
      <h4 class="modal-title font-weight-bold text-black-1" translate>resolve_incident.title</h4>
      <i
        class="fa fa-times text-black-1"
        style="width: 12px;
              height: 12px;
              right: 1em;
              cursor: pointer;
              position: absolute;
              top: 1.3em;"
        (click)="activeModal.close({ isUpdate: false })"
        ngbAutoFocus
        translate
      ></i>
    </div>
  </div>
  <div class="modal-body">
    <div class="row mt-1 mb-3" *ngIf="backend_error">
      <div class="col-12">
        <span class="text-danger backend_error" translate> * {{ backend_error }}</span>
      </div>
    </div>

    <form [formGroup]="resolveIncidentForm" novalidate>
      <div class="row mt-3">
        <div class="col-3">
          <label class="text-black-4 p-2" translate>resolve_incident.Title </label>
        </div>
        <div class="col-9">
          <div class="input-group">
            <input class="form-control cb-input" value="{{ incidentTitle }}" disabled />
          </div>
        </div>

        <div class="col-3">
          <label for="remarks" class="text-black-1 p-2" translate>resolve_incident.Remarks</label>
        </div>
        <div class="input-group col-9">
          <textarea
            id="remarks"
            class="form-control cb-input"
            [placeholder]="'resolve_incident.Remarks' | translate"
            name="remarks"
            type="text"
            formControlName="remarks"
            cols="64"
            rows="5"
            [maxLength]="4097"
          ></textarea>
          <div class=" col-12 error-mssg" *ngIf="(f.remarks.touched || f.remarks.dirty) && f.remarks.errors">
            <label for="name" class="text-danger" *ngIf="f.remarks.errors.maxlength" translate>
              resolve_incident.Remarks must be 4096 characters or less</label
            >
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer border-0 cb-modal-footer">
    <button class="btn-cancel ml-4 w-100" (click)="activeModal.close({ isUpdate: false })" ngbAutoFocus translate>
      resolve_incident.Cancel
    </button>
    <button class="btn-save ml-4 w-100" *ngIf="!isLoading" (click)="save()" translate>
      resolve_incident.Submit
    </button>
    <button class="btn-save ml-4 w-100" disabled *ngIf="isLoading">
      <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
    </button>
  </div>
</div>
