<app-spinner *ngIf="isloading"></app-spinner>
<div class="row">
  <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
    <h2 class="text-cyan m-0" style="cursor:default" translate>Import Template</h2>
    <div>
      <button class="btn-cancel ml-4 w-100" [ngClass]="{ 'btn-cancel': true }" (click)="onReset()" translate>
        Create_Document.Btn_Clr
      </button>
      <!-- [disabled]="buttonDisable"
      [ngStyle]="{ 'background-color': buttonDisable ? 'rgba(0,0,0,.26)' : '#4db7c6' }" -->

      <button
        class="btn-save ml-4 w-100"
        [disabled]="ImportTemplateForm.invalid || upload_error_message"
        [ngStyle]="{
          'background-color': ImportTemplateForm.invalid || upload_error_message ? 'rgba(0,0,0,.26)' : '#4db7c6'
        }"
        [ngClass]="{ 'btn-save': true }"
        (click)="submit()"
        translate
      >
        Create_Document.Import
      </button>
    </div>
  </div>
</div>

<div class="row scroll-view">
  <div class="col">
    <ng-container>
      <div class="row mt-2" *ngIf="backend_error">
        <div class="col-12 col-sm-12 col-lg-12 ml-5">
          <div class="text-danger" translate>* {{ backend_error }}</div>
        </div>
      </div>

      <label for="title" class="ml-3 pt-2 mt-1 cb-label text-black-9" translate>Import templates by upload</label>

      <form [formGroup]="ImportTemplateForm" novalidate autocomplete="off">
        <!-- added to remove chromes default autocompelte-->
        <div class="row mt-5">
          <div class="col-2 col-sm-2 col-lg-2">
            <label for="title" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate>Create_Document.Doc_Title</label>
          </div>
          <div class="input-group col-9 col-sm-9 col-lg-9">
            <input
              id="title"
              class="form-control cb-input"
              formControlName="title"
              [placeholder]="'Document Title'"
              autocomplete="title"
              required
              name="title"
              type="text"
              style="height: 40px;"
              (keyup)="onChange($event)"
              [maxLength]="256"
              required
            />
            <div
              class="col-12 error-mssg"
              *ngIf="
                (ImportTemplateForm.controls.title.touched || ImportTemplateForm.controls.title.dirty) &&
                ImportTemplateForm.controls.title.errors
              "
            >
              <label for="name" class="text-danger" *ngIf="ImportTemplateForm.controls.title.errors.required" translate
                >Create_Document.Doc_Req_Title</label
              >
              <label for="name" class="text-danger" *ngIf="ImportTemplateForm.controls.title.errors.maxLength" translate
                >Create_Document.Title must be 255 characters or less</label
              >
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-2 col-sm-2 col-lg-2">
            <label for="description" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate
              >Create_Document.Description</label
            >
          </div>
          <div class="input-group col-9 col-sm-9 col-lg-9">
            <textarea
              id="description"
              class="form-control cb-input"
              [placeholder]="'Create_Document.Description' | translate"
              name="description"
              type="text"
              formControlName="description"
              cols="64"
              rows="10"
              [maxLength]="4097"
            ></textarea>
            <div
              class="col-12 error-mssg"
              *ngIf="
                (ImportTemplateForm.controls.description.touched || ImportTemplateForm.controls.description.dirty) &&
                ImportTemplateForm.controls.description.errors
              "
            >
              <label
                for="name"
                class="text-danger"
                *ngIf="ImportTemplateForm.controls.description.errors.required"
                translate
                >Create_Document.Doc_Req_Descr</label
              >
              <label
                for="name"
                class="text-danger"
                *ngIf="ImportTemplateForm.controls.description.errors.maxLength"
                translate
                >Create_Document.Description must be 4096 characters or less</label
              >
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-2 col-sm-2 col-lg-2">
            <label for="template_type" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate
              >Select Template Type</label
            >
          </div>
          <div class="input-group col-3 col-sm-3 col-lg-3">
            <select
              class="form-control cb-input"
              id="template_type"
              formControlName="template_type"
              name="template_type"
              [(ngModel)]="selectedTemplateType"
              (change)="onTemplateTypeChange($event)"
            >
              <option
                *ngFor="let template of templateTypeList; let i = index"
                [attr.data-index]="i"
                [value]="template['value']"
              >
                {{ template.value.charAt(0).toUpperCase() + template.value.slice(1) }}
              </option>
            </select>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-2 col-sm-2 col-lg-2">
            <label for="document_option" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate>
              Upload Document
            </label>
          </div>
          <div class="input-group col-4 col-sm-4 col-lg-4">
            <input
              id="file"
              class="file-upload file-input ml-1 mt-2"
              formControlName="file"
              name="file"
              style="height: 40px;"
              autocomplete="file"
              type="file"
              (change)="fileEvent($event)"
              [accept]="selectedTemplateType == 'form' ? '.docx,.xlsx' : '.docx,.xlsx,.pptx,.pdf'"
              #fileInput
              required
            />
            <div class="col-12 error-mssg" *ngIf="errorMessage">
              <label for="name" class="text-danger" *ngIf="errorMessage" translate
                >Form Template Not support Presentation You need to select proper type(.docx,.xlsx)</label
              >
            </div>
            <div class="col-12 error-mssg" *ngIf="upload_error_message">
              <label for="upload" class="text-danger" translate>{{ upload_error_message }}</label>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-2 col-sm-2 col-lg-2">
            <label for="version" class="ml-3 pt-2 mt-1 cb-label text-black-3" translate>Create_Document.Version</label>
          </div>
          <div class="input-group col-9 col-sm-9 col-lg-9">
            <input
              id="version"
              class="form-control cb-input"
              formControlName="version"
              autocomplete="version"
              placeholder="{{ this.version }}"
              required
              name="version"
              type="text"
              style="height: 40px;"
              value="1.0.0"
              (keyup)="onChange($event)"
            />
            <div
              class=" col-12 error-mssg"
              *ngIf="
                (ImportTemplateForm.controls.version.touched || submitted) && ImportTemplateForm.controls.version.errors
              "
            >
              <label
                for="version"
                class="text-danger"
                *ngIf="ImportTemplateForm.controls.version.errors.required"
                translate
                >Create_Document.Version_req</label
              >
            </div>
            <div
              class="col-12 error-mssg"
              *ngIf="ImportTemplateForm.controls.version.touched && ImportTemplateForm.controls.version.errors"
            >
              <label
                for="version"
                class="text-danger"
                *ngIf="ImportTemplateForm.controls.version.errors.restrictZeroNumber_error"
                translate
                >Create_Document.version_not_zero</label
              >
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-6 col-sm-12 col-lg-6">
            <div class="input-group pt-2 mb-2">
              <label for="Category" for="renewal_recurring" class="ml-3 mt-1 cb-label text-black-3" translate
                >Create_Document.Recurring_renewal</label
              >
              <mat-checkbox
                class="mt-1"
                formControlName="renewal_recurring"
                id="renewal_recurring"
                name="renewal_recurring"
                style="margin-left: 10em;"
                (change)="(true)"
                #checkBox
              ></mat-checkbox>
            </div>
          </div>
        </div>

        <div *ngIf="checkBox.checked">
          <div class="row">
            <div class="col-6 col-sm-12 col-lg-6">
              <span class="text-black-3 ml-3 mt-2 cb-label" translate>Create_Document.Sel_Rec</span>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-4 col-sm-6 col-lg-4">
              <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                >Create_Document.Select_Time</label
              >
            </div>
            <div class="col-6 col-sm-6 col-lg-6">
              <div class="cb-input cb-time">
                <i class="fa fa-clock"></i>
                <input
                  placeholder="{{ this.time }}"
                  aria-label="Time"
                  class="time_input"
                  [ngxTimepicker]="darkPicker"
                  [value]="data.time"
                  readonly
                />
                <ngx-material-timepicker
                  class="timepicker"
                  #darkPicker
                  [ngxMaterialTimepickerTheme]="darkTheme"
                  (timeSet)="timeSet($event)"
                ></ngx-material-timepicker>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-4 col-sm-6 col-lg-4">
              <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                >Create_Document.Select_Schedule</label
              >
            </div>
            <div class="input-group col-6 col-sm-6 col-lg-6">
              <select
                class="form-control cb-input"
                [value]="data.renewal_schedule"
                (change)="dataRefresh($event.target.value)"
              >
                <option *ngFor="let schedule of schedules" [value]="schedule.value">
                  {{ schedule.display_value }}
                </option>
              </select>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-4 col-sm-6 col-lg-4">
              <label class="text-black-3 ml-3 mt-2 cb-label-2" translate>Create_Document.Allowed_duration</label>
            </div>
            <div class="input-group col-6 col-sm-6 col-lg-6">
              <select
                class="form-control cb-input"
                [(ngModel)]="allowed_duration_selected"
                [ngModelOptions]="{ standalone: true }"
              >
                <option value="1" *ngFor="let duration of allowed_duration" [value]="duration" translate>{{
                  duration
                }}</option>
              </select>
            </div>
          </div>

          <div class="row mt-3" *ngIf="data.renewal_schedule === 'WEEKLY'">
            <div class="col-4 col-sm-6 col-lg-4">
              <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate>Create_Document.Day</label>
            </div>
            <div class="input-group col-6 col-sm-6 col-lg-6">
              <select
                class="form-control cb-input"
                [(ngModel)]="data.day_of_week"
                [ngModelOptions]="{ standalone: true }"
              >
                <option *ngFor="let day of days" [value]="day.value">
                  {{ day.display_string }}
                </option>
              </select>
            </div>
          </div>

          <div class="row mt-3" *ngIf="data.renewal_schedule === 'BI-WEEKLY'">
            <div class="col-4 col-sm-6 col-lg-4">
              <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate>Create_Document.Week</label>
            </div>
            <div class="input-group col-6 col-sm-6 col-lg-6">
              <select class="form-control cb-input" [(ngModel)]="data.week" [ngModelOptions]="{ standalone: true }">
                <option *ngFor="let week of numbered_bi" [value]="week.value">
                  {{ week.display_string }}
                </option>
              </select>
            </div>
          </div>

          <div class="row mt-3" *ngIf="data.renewal_schedule === 'MONTHLY'">
            <div class="col-4 col-sm-6 col-lg-4">
              <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate>
                Create_Document.Week_Month</label
              >
            </div>
            <div class="input-group col-6 col-sm-6 col-lg-6">
              <select class="form-control cb-input" [(ngModel)]="data.day" [ngModelOptions]="{ standalone: true }">
                <option *ngFor="let week of numbered_week" [value]="week.value">
                  {{ week.display_string }}
                </option>
              </select>
            </div>
          </div>

          <div class="row mt-3" *ngIf="data.renewal_schedule === 'MONTHLY'">
            <div class="col-4 col-sm-6 col-lg-4">
              <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate>Create_Document.Day</label>
            </div>
            <div class="input-group col-6 col-sm-6 col-lg-6">
              <select
                class="form-control cb-input"
                [(ngModel)]="data.day_of_week"
                [ngModelOptions]="{ standalone: true }"
              >
                <option *ngFor="let day of days" [value]="day.value">
                  {{ day.display_string }}
                </option>
              </select>
            </div>
          </div>

          <div class="row mt-3" *ngIf="data.renewal_schedule === 'BI-MONTHLY'">
            <div class="col-4 col-sm-6 col-lg-4">
              <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate>Create_Document.Month</label>
            </div>
            <div class="input-group col-6 col-sm-6 col-lg-6">
              <select class="form-control cb-input" [(ngModel)]="data.month" [ngModelOptions]="{ standalone: true }">
                <option *ngFor="let month of numbered_bi" [value]="month.value">
                  {{ month.display_string }}
                </option>
              </select>
            </div>
            <div class="col-4 col-sm-6 col-lg-4">
              <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                >Create_Document.Week_Month</label
              >
            </div>
            <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
              <select class="form-control cb-input" [(ngModel)]="data.day" [ngModelOptions]="{ standalone: true }">
                <option *ngFor="let week of numbered_week" [value]="week.value">
                  {{ week.display_string }}
                </option>
              </select>
            </div>
            <div class="col-4 col-sm-6 col-lg-4">
              <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate>Create_Document.Day</label>
            </div>
            <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
              <select
                class="form-control cb-input"
                [(ngModel)]="data.day_of_week"
                [ngModelOptions]="{ standalone: true }"
              >
                <option *ngFor="let day of days" [value]="day.value" translate>
                  {{ day.display_string }}
                </option>
              </select>
            </div>
          </div>

          <div class="row mt-3" *ngIf="data.renewal_schedule === 'QUARTERLY'">
            <div class="col-4 col-sm-6 col-lg-4">
              <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate>Create_Document.Month</label>
            </div>
            <div class="input-group col-6 col-sm-6 col-lg-6">
              <select class="form-control cb-input" [(ngModel)]="data.month" [ngModelOptions]="{ standalone: true }">
                <option *ngFor="let month of numbered_quarter" [value]="month.value" translate>
                  {{ month.display_string }}
                </option>
              </select>
            </div>
            <div class="col-4 col-sm-6 col-lg-4 mt-3">
              <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                >Create_Document.Week_Month</label
              >
            </div>
            <div class="input-group col-6 col-sm-6 col-lg-6">
              <select class="form-control cb-input" [(ngModel)]="data.day" [ngModelOptions]="{ standalone: true }">
                <option *ngFor="let week of numbered_week" [value]="week.value" translate>
                  {{ week.display_string }}
                </option>
              </select>
            </div>
            <div class="col-4 col-sm-6 col-lg-4 mt-3">
              <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate>Create_Document.Day</label>
            </div>
            <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
              <select
                class="form-control cb-input"
                [(ngModel)]="data.day_of_week"
                [ngModelOptions]="{ standalone: true }"
              >
                <option *ngFor="let day of days" [value]="day.value" translate>
                  {{ day.display_string }}
                </option>
              </select>
            </div>
          </div>

          <div class="row mt-3" *ngIf="data.renewal_schedule === 'SEMI-ANNUALLY'">
            <div class="col-4 col-sm-6 col-lg-4">
              <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate>Create_Document.Month</label>
            </div>
            <div class="input-group col-6 col-sm-6 col-lg-6">
              <select class="form-control cb-input" [(ngModel)]="data.month" [ngModelOptions]="{ standalone: true }">
                <option *ngFor="let months of months_half_year" [value]="months.value" translate>
                  {{ months.display_string }}
                </option>
              </select>
            </div>
            <div class="col-4 col-sm-6 col-lg-4 mt-3">
              <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                >Create_Document.Week_Month</label
              >
            </div>
            <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
              <select class="form-control cb-input" [(ngModel)]="data.day" [ngModelOptions]="{ standalone: true }">
                <option *ngFor="let week of numbered_week" [value]="week.value" translate>
                  {{ week.display_string }}
                </option>
              </select>
            </div>
            <div class="col-4 col-sm-6 col-lg-4 mt-3">
              <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate>Create_Document.Day</label>
            </div>
            <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
              <select
                class="form-control cb-input"
                [(ngModel)]="data.day_of_week"
                [ngModelOptions]="{ standalone: true }"
              >
                <option *ngFor="let day of days" [value]="day.value" translate>
                  {{ day.display_string }}
                </option>
              </select>
            </div>
          </div>

          <div class="row mt-3" *ngIf="data.renewal_schedule === 'ANNUALLY'">
            <div class="col-4 col-sm-6 col-lg-4">
              <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate>Create_Document.Month</label>
            </div>
            <div class="input-group col-6 col-sm-6 col-lg-6">
              <select class="form-control cb-input" [(ngModel)]="data.month" [ngModelOptions]="{ standalone: true }">
                <option *ngFor="let months of months_year" [value]="months.value" translate>
                  {{ months.display_string }}
                </option>
              </select>
            </div>
            <div class="col-4 col-sm-6 col-lg-4 mt-3">
              <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                >Create_Document.Week_Month</label
              >
            </div>
            <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
              <select class="form-control cb-input" [(ngModel)]="data.day" [ngModelOptions]="{ standalone: true }">
                <option *ngFor="let week of numbered_week" [value]="week.value" translate>
                  {{ week.display_string }}
                </option>
              </select>
            </div>
            <div class="col-4 col-sm-6 col-lg-4 mt-3">
              <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate>Create_Document.Day</label>
            </div>
            <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
              <select
                class="form-control cb-input"
                [(ngModel)]="data.day_of_week"
                [ngModelOptions]="{ standalone: true }"
              >
                <option *ngFor="let day of days" [value]="day.value" translate>
                  {{ day.display_string }}
                </option>
              </select>
            </div>
          </div>

          <div class="row mt-3" *ngIf="data.renewal_schedule === 'EVERY TWO YEARS'">
            <div class="col-4 col-sm-6 col-lg-4">
              <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate>Create_Document.Year</label>
            </div>
            <div class="input-group col-6 col-sm-6 col-lg-6">
              <select class="form-control cb-input" [(ngModel)]="data.year" [ngModelOptions]="{ standalone: true }">
                <option *ngFor="let year of numbered_bi" [value]="year.value" translate>
                  {{ year.display_string }}
                </option>
              </select>
            </div>
            <div class="col-4 col-sm-6 col-lg-4 mt-3">
              <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate>Create_Document.Month</label>
            </div>
            <div class="input-groupcol-5 col-6 col-sm-6 col-lg-6 mt-3">
              <select class="form-control cb-input" [(ngModel)]="data.month" [ngModelOptions]="{ standalone: true }">
                <option *ngFor="let months of months_year" [value]="months.value" translate>
                  {{ months.display_string }}
                </option>
              </select>
            </div>
            <div class="col-4 col-sm-6 col-lg-4 mt-3">
              <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate
                >Create_Document.Week_Month</label
              >
            </div>
            <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
              <select class="form-control cb-input" [(ngModel)]="data.day" [ngModelOptions]="{ standalone: true }">
                <option *ngFor="let week of numbered_week" [value]="week.value" translate>
                  {{ week.display_string }}
                </option>
              </select>
            </div>
            <div class="col-4 col-sm-6 col-lg-4 mt-3">
              <label for="dateOfBirth" class="text-black-3 ml-3 mt-2 cb-label-2" translate>Create_Document.Day</label>
            </div>
            <div class="input-group col-6 col-sm-6 col-lg-6 mt-3">
              <select
                class="form-control cb-input"
                [(ngModel)]="data.day_of_week"
                [ngModelOptions]="{ standalone: true }"
              >
                <option *ngFor="let day of days" [value]="day.value" translate>
                  {{ day.display_string }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div *ngIf="error">
            <div class="col-12 mt-2 error-mssg" *ngIf="error.non_field_errors">
              <label class="text-danger" translate>* {{ error.non_field_errors }}</label>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
</div>
