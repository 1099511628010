import { Component, OnInit, Input, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '@app/settings/settings.service';
import { OrganisationService } from '@app/core/organisation.service';
import { WizardService } from '@app/home/wizard.services';
import { SidenavService } from '@app/core/modal/sidenav.service';
import { ToasterService } from '@app/shared/toaster/toastr.service';

@Component({
  selector: 'app-add-new-reader',
  templateUrl: './add-new-reader.component.html',
  styleUrls: ['./add-new-reader.component.scss']
})
export class AddNewReaderComponent implements OnInit {
  peopleForm: FormGroup;
  submitted = false;
  error = {
    first_name: '',
    last_name: '',
    phone: '',
    email: ''
  };
  success: boolean;
  // multiselect dropdown
  GroupsList: any = [];
  selectedRoles: any = [];
  selectedItems: any = [];
  dropdownSettings = {};
  isLoading = false;
  is_deleted: boolean;
  OrganisationID: string | null;
  guidSubscription: any;
  abcd: any;
  userGuid: string;
  roleSelected = true;
  roles = {
    organization_user_role: 'User',
    organization_admin_role: 'Administrator'
  };

  default_roles: any = ['organization_user_role', 'document_reader'];

  @Input() title_tag: string;
  @Input() form_data: [];
  loading = true;
  domainName = '';
  orgPasswordBasedLogin: boolean = true;
  constructor(
    private orgService: OrganisationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private settingsService: SettingsService,
    private wizardService: WizardService,
    private sidenavService: SidenavService,
    private translate: TranslateService,
    private toasterService: ToasterService
  ) {
    this.orgPasswordBasedLogin = localStorage.getItem('org_password_based_login') == 'true' ? true : false;
    this.getDomainName(localStorage.getItem('domain'));
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  ngOnInit() {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
    });
    this.getAddPeopleForm(this.form_data);
    this.getRolesList();
    this.userGuid = this.form_data ? this.form_data['guid'] : null;
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'key',
      textField: 'value',
      itemsShowLimit: 10,
      enableCheckAll: false,
      allowSearchFilter: false
    };
    this.selectedItems = this.default_roles.map((item: any) => {
      const container = {};
      container['isDisabled'] = true;
      container['key'] = item;
      if (item === 'incident_operator') {
        container['value'] = 'Incident reporter';
      } else if (item === 'incident_coordinator') {
        container['value'] = 'Incident approver';
      } else {
        container['value'] = item
          .toLowerCase()
          .replace(/_/g, ' ')
          .replace(/(?: |\b)(\w)/g, function(key: any) {
            return key.toUpperCase();
          });
      }

      return container;
    });

    this.selectedItems.forEach((item: any) => {
      if (item.key == 'organization_user_role' || item.key == 'document_reader') {
        item['isDisabled'] = true;
      }
    });
  }
  isNotRemovable(item: any): boolean {
    return item.key == 'organization_user_role' || item.key == 'document_reader';
  }

  getSelectedRolesValues(GroupsList: any) {
    this.selectedRoles = []; // Initialize to avoid duplications

    for (let group of GroupsList) {
      // Check if the group should be disabled and selected
      const shouldBeDisabledAndSelected = ['organization_user_role', 'document_reader'].includes(group.key);

      group = {
        key: group.key,
        value: group.value,
        isDisabled: shouldBeDisabledAndSelected,
        selected: shouldBeDisabledAndSelected
      };

      // Add to selectedRoles if not already included
      if (!this.selectedRoles.some((item: any) => item.key === group.key)) {
        this.selectedRoles.push(group);
      }
    }

    this.GroupsList = this.selectedRoles;
    console.log(this.GroupsList);
  }

  private getRolesList(): void {
    this.settingsService.getOrganizationGroupsList().subscribe(
      data => {
        this.GroupsList = data;
        this.GroupsList = this.GroupsList.map((userList: any) => {
          if (userList.key === 'incident_operator') {
            userList.value = 'Incident reporter';
          } else if (userList.key === 'incident_coordinator') {
            userList.value = 'Incident approver';
          }
          return userList;
        });
        let datalist = this.GroupsList;

        this.getSelectedRolesValues(datalist);
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }

  onItemSelect(item: any) {
    item.selected = !item.selected;
    if (item.selected) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems = this.selectedItems.filter((x: any) => x.key !== item.key);
    }
    this.checkRoleSelection();
  }

  removeItem(item: any) {
    this.selectedItems = this.selectedItems.filter((x: any) => x.key !== item.key);
    const userIndex = this.GroupsList.findIndex((x: any) => x.key === item.key);
    if (userIndex !== -1) {
      this.GroupsList[userIndex]['selected'] = false;
    }
    this.checkRoleSelection();
  }
  onItemDeSelect(items: any) {
    this.checkRoleSelection();
  }

  get f() {
    return this.peopleForm.controls;
  }

  save() {
    let role: any = [];
    this.submitted = true;
    if (this.selectedItems.length > 0 && this.peopleForm.valid) {
      role = this.selectedItems.map((item: any) => {
        return item.key;
      });
      this.isLoading = true;
      const peopleData = this.peopleForm.value;
      peopleData['roles'] = role;
      this.submitOrganisationAddProfile(peopleData);
    } else {
      this.roleSelected = false;
      return;
    }
  }

  submitOrganisationAddProfile(peopleData: any): void {
    this.settingsService
      .submitOrganisationAddPeopleProfile(this.OrganisationID, peopleData)
      .pipe(
        finalize(() => {
          this.peopleForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.error = {
            first_name: '',
            last_name: '',
            phone: '',
            email: ''
          };
          this.submitted = false;
          this.translate.get('organisation_people.people_toaster_message.add_people').subscribe(res => {
            this.toasterService.showSuccess(res);
          });
          this.sidenavService.close(false, AddNewReaderComponent);
        },
        (error: any) => {
          this.error = error.error;
        }
      );
  }

  checkRoleSelection() {
    this.selectedItems.length > 0 ? (this.roleSelected = true) : (this.roleSelected = false);
  }

  getAddPeopleForm(data?: []) {
    this.peopleForm = this.formBuilder.group({
      first_name: [data ? (data['first_name'] ? data['first_name'] : '') : '', [Validators.required()]],
      last_name: [data ? (data['last_name'] ? data['last_name'] : '') : '', [Validators.required()]],
      email: [data ? (data['email'] ? data['email'] : '') : '', [Validators.email()]],
      selectedItems: [''],
      password_based_login: [data ? (data['password_based_login'] ? data['password_based_login'] : false) : false],
      external_sharing: [data ? (data['external_sharing'] ? data['external_sharing'] : false) : false]
    });
  }
  checkNonDomain(email = '') {
    if (!email) return false;
    if (!email.includes(this.domainName)) return false;
    return true;
  }
  getDomainName(url: string) {
    const domainRegex = /^(?:https?:\/\/)?(?:www\.)?([^/?]+).*/;
    const match = url?.match(domainRegex);
    this.domainName = match ? match[1] || '' : null;
  }
  closeNav() {
    this.sidenavService.close(true);
  }
  closeAll() {
    this.sidenavService.close(true);
    this.sidenavService.close(true);
  }
}
