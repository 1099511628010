<!--<div class="navbar navbar-expand-lg navbar-dark">-->
<!--<a class="navbar-brand" translate>APP_NAME</a>-->
<!--<button-->
<!--class="navbar-toggler"-->
<!--type="button"-->
<!--aria-controls="navbar-menu"-->
<!--aria-label="Toggle navigation"-->
<!--(click)="toggleMenu()"-->
<!--[attr.aria-expanded]="!menuHidden"-->
<!--&gt;-->
<!--<span class="navbar-toggler-icon"></span>-->
<!--</button>-->
<!--<div id="navbar-menu" class="collapse navbar-collapse float-xs-none">-->
<!--<div class="navbar-nav">-->
<!--<a class="nav-item nav-link text-uppercase" routerLink="/home" routerLinkActive="active">-->
<!--<i class="fas fa-home"></i> <span translate>Home</span>-->
<!--</a>-->
<!--<a class="nav-item nav-link text-uppercase" routerLink="/about" routerLinkActive="active">-->
<!--<i class="fas fa-question-circle"></i> <span translate>About</span>-->
<!--</a>-->
<!--</div>-->
<!--<div class="navbar-nav ml-auto" placement="bottom-right">-->
<!--<i class="fas fa-user-circle" style="font-size: 22px;" mat-button [matMenuTriggerFor]="menu"></i>-->
<!--<mat-menu #menu="matMenu"> <button mat-menu-item (click)="logout()" translate>Logout</button> </mat-menu>-->
<!--</div>-->

<!--&lt;!&ndash;<div class="navbar-nav ml-auto" placement="bottom-right">&ndash;&gt;-->
<!--&lt;!&ndash;<div class="nav-item" ngbDropdown >&ndash;&gt;-->
<!--&lt;!&ndash;<a id="user-dropdown" class="nav-link" ngbDropdownToggle> <i class="fas fa-user-circle"></i> </a>&ndash;&gt;-->
<!--&lt;!&ndash;<div ngbDropdownMenu aria-labelledby="user-dropdown">&ndash;&gt;-->
<!--&lt;!&ndash;<h6 class="dropdown-systemadminshellheader">&ndash;&gt;-->
<!--&lt;!&ndash;<span translate>Logged in as</span> <b>{{ username }}</b>&ndash;&gt;-->
<!--&lt;!&ndash;</h6>&ndash;&gt;-->
<!--&lt;!&ndash;<div class="dropdown-divider"></div>&ndash;&gt;-->
<!--&lt;!&ndash;<button class="dropdown-item" (click)="logout()" translate>Logout</button>&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--&lt;!&ndash;</div>&ndash;&gt;-->
<!--</div>-->
<!--</div>-->

<mat-toolbar color="primary">
  <div><a routerLink="/" translate>APP_NAME</a></div>
  <div fxFlex fxLayout fxLayoutAlign="end" fxHide.xs>
    <ul fxLayout fxLayoutGap="15px" class="navigation-items">
      <li>
        <div class="navbar-nav ml-auto" placement="bottom-right">
          <!--<i class="fas fa-user-circle" style="font-size: 22px;" mat-button [matMenuTriggerFor]="menu"></i>-->
          <!--<mat-menu #menu="matMenu"> <button mat-menu-item (click)="logout()" translate>Logout</button> </mat-menu>-->
        </div>
      </li>
    </ul>
  </div>
</mat-toolbar>
