///<reference path="../../../../../node_modules/rxjs-compat/add/observable/forkJoin.d.ts"/>
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { UserService } from '@app/core/user.service';
import { WizardService } from '@app/home/wizard.services';
import * as _ from 'lodash';
// import { MatHorizontalStepper } from '@angular/material/stepper';
import { MatDialog } from '@angular/material/dialog';
import { AddMembersDialogComponent } from '@app/home/steps/inviteCommitteeMembers/addMembersDialog.component';
import { OrganisationService } from '@app/core/organisation.service';

@Component({
  selector: 'app-invite-committee-members-step',
  templateUrl: './inviteCommitteeMembers.component.html',
  styleUrls: ['./inviteCommitteeMembers.component.scss']
})
export class InviteCommitteeMembersComponent implements OnInit, OnDestroy {
  // @Input() stepper: MatHorizontalStepper;
  @Input() show = false;
  // stepForm: FormGroup;
  OrganisationID: any;
  tempData: any = [];
  loading = true;
  displayedColumns: string[] = ['committee_name', 'program_name', 'members', 'addMembers'];
  data: any = [];
  guidSubscription: any;

  constructor(
    private _user: UserService,
    private wizardService: WizardService,
    private changeDetectorRefs: ChangeDetectorRef,
    public dialog: MatDialog,
    private orgService: OrganisationService
  ) {}

  ngOnInit() {
    this.guidSubscription = this.orgService.organisation_guid.subscribe((guid: any) => {
      this.OrganisationID = guid;
      this.loading = true;
      this.wizardService.getOrganizationCommittee(this.OrganisationID).subscribe(data => {
        this.tempData = _.cloneDeep(data);
        this.data = _.cloneDeep(this.tempData);
        let members: any = [];
        this.tempData.forEach((org: any, index: number) => {
          this.wizardService
            .getOrganizationCommitteeMembers(this.OrganisationID, org.org_review_com_guid)
            .subscribe(data2 => {
              members = [];
              data2.forEach((member: any) => {
                members.push(member.email.toString());
              });
              this.data[index]['members'] = members;
              // this.changeDetectorRefs.detectChanges();
            });
        });
        this.loading = false;
      });
    });
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  canGoToNextStep() {
    return true;
  }

  submitForm() {
    // this.stepForm.markAsDirty();
    // if (this.stepForm.valid) {
    // this.stepper.next();
  }

  addRemoveMembers(program: any, index: number) {
    const dialogRef = this.dialog.open(AddMembersDialogComponent, {
      width: '50%',
      data: {
        OrganisationID: this.OrganisationID,
        committee_guid: program.org_review_com_guid,
        members: program['members']
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(`Dialog result: ${result}`);
      this.wizardService
        .getOrganizationCommitteeMembers(this.OrganisationID, program.org_review_com_guid)
        .subscribe(data2 => {
          const members: any = [];
          data2.forEach((member: any) => {
            members.push(member.email.toString());
          });
          this.data[index]['members'] = members;
          // this.changeDetectorRefs.detectChanges();
        });
    });
  }
}
