<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div class="row">
    <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
      <h2 class="text-cyan m-0" translate>Training Groups</h2>
      <div>
        <button (click)="addTrainingGroup()" class="btn-add ml-4" translate>
          <i class="fa fa-plus"></i>Create Training Group
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div
      class="col search-results"
      infiniteScroll
      [scrollWindow]="false"
      (scrolled)="onScroll()"
      [infiniteScrollDistance]="modalScrollDistance"
      [infiniteScrollThrottle]="modalScrollThrottle"
      (scrolledUp)="onScrollUp()"
    >
      <div
        class="cb-accordian mx-auto w-740 mt-4"
        [ngClass]="!accArray.includes(i) ? '' : 'expand'"
        *ngFor="let item of TrainingGroupList; let i = index"
        [attr.data-index]="i"
      >
        <div class="acc-header d-flex justify-content-between">
          <div class="col-lg-8">
            <h5 class="text-black-1 f2 text-truncate" [ngbTooltip]="item.title" tooltipClass="cb-tooltip">
              {{ item.title }}
            </h5>
          </div>
          <div class="d-flex align-items-center">
            <h5 class="font-weight-bold text-black-1 m-0" translate>Total members - {{ item.users_count }}</h5>
            <button (click)="openAddUser(item)" class="btn-stop ml-4">
              <span style="color: white;" translate>
                <i class="fa fa-plus text-white mr-2 ml-1">
                  <!-- <i style="margin-left: -10px;"class="fas fa-pencil-alt btn-edit d-inline-block ml-2"></i> --> </i
                >Manage members</span
              >
            </button>
            <button class="btn-acc-toggle ml-4 mr-3" (click)="toggleDetails(i, item.guid)">
              <i
                [ngClass]="
                  !accArray.includes(i) ? 'fas fa-chevron-down text-black-2' : 'fas fa-chevron-up text-black-2'
                "
              ></i>
            </button>
          </div>
        </div>
        <hr style="width:100%;text-align:left;margin-top:0" />
        <h5 style="margin-left: 36px;" class="text-black-8 " translate>Details</h5>
        <div class="row mb-2">
          <div style="margin-left: 36px;margin-top: 5px;" class="col-lg-4">
            <h5 class="text-black-2 font-weight-bold" translate>Description:</h5>
          </div>
          <div class="col-lg-8" *ngIf="!item.edit">
            <h5 style="margin-left: -150px;" class="text-black-1 d-inline-block">
              {{ item.description }}
              <i
                *ngIf="is_editable"
                (click)="item.edit = !item.edit"
                class="fas fa-pencil-alt btn-edit d-inline-block ml-2"
              ></i>
            </h5>
          </div>
          <div class="col-lg-8" *ngIf="item.edit">
            <div class="row">
              <textarea
                style="margin-left: -150px;"
                id="description"
                class="form-control cb-input col-10"
                name="description"
                type="text"
                [(ngModel)]="item.description"
              ></textarea>
              <div class="d-flex align-items-center">
                <i
                  class="fas fa-check btn-edit col-1"
                  *ngIf="item.description.length"
                  (click)="submitPartForm(item.description, item.guid)"
                ></i>
                <i
                  class="fas fa-times btn-edit col-1"
                  (click)="resetField(item.description, i); item.edit = !item.edit"
                ></i>
              </div>
            </div>
            <div class="row" *ngIf="!item.description">
              <p class="error-danger" translate>
                Training_Group.Description is required
              </p>
            </div>
            <div class="row" *ngIf="item.description.length > 4096">
              <p class="error-danger" translate>
                Training_Group.Description must be 4096 characters or less
              </p>
            </div>
            <div class="row" *ngIf="form.description.error">
              <p class="error-danger">
                {{ form.description.error }}
              </p>
            </div>
          </div>
        </div>

        <div class="acc-body px-3" *ngIf="item.users_count > 0; else no_content">
          <h5 class="text-black-8 " translate>Members</h5>

          <table style="margin-bottom: 25px;" class="w-100p">
            <thead>
              <tr style="height: 50px;margin-left: 30px;">
                <th style="width: 35%;" scope="col" class="font-weight-bold text-black-3 text-center" translate>
                  Name
                </th>
                <th style="width: 10%;" scope="col" class="font-weight-bold text-black-3 text-center" translate>
                  Email
                </th>
                <!-- <th style="width: 35%;" scope="col" class="font-weight-bold text-black-3 text-center" translate>
                    Phone
                  </th> -->
                <th style="width: 15%;" scope="col" class="font-weight-bold text-black-3 text-center"></th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center" *ngFor="let trainingGroup of TrainingGroupUserList[item.guid]">
                <td>
                  <h4 class="text-black-3 dm-text-3 mt-3">{{ trainingGroup.name }}</h4>
                </td>
                <td>
                  <h4 style="margin-left: 30px;" class="text-black-3 dm-text-4 mt-3">
                    {{ trainingGroup['email'] }}
                  </h4>
                </td>
                <!-- <td>
                    <h4 class="text-black-3 dm-text-3 mt-3">
                      {{ trainingGroup['phone'] }}
                    </h4>
                  </td> -->
              </tr>
            </tbody>
          </table>
        </div>
        <ng-template #no_content>
          <hr style="width:100%;text-align:left;margin-left:0" />
          <div style="margin-top: -150px;padding-bottom: 65px;" class="acc-body px-3">
            <img class="empty-doc-list-image" src="../../../assets/images/documents/empty.svg" />
            <div class="text-empty-doc-list text-black-1" translate>No members</div>
            <div class="sub-text-empty-doc-list cursor-pointer text-cyan-force" translate>
              <a
                class="sub-text-empty-doc-list text-center text-cyan-force"
                (click)="openAddUser(item)"
                style="font-size: 18px;margin-left: -55px;"
                translate
                >Add new member</a
              >
            </div>
          </div>
        </ng-template>
        <!--
          </div>
        </div>
      </div> -->
      </div>
    </div>
  </div>
  &nbsp;
  <h2 *ngIf="scrollLoader" class="font-weight-bold text-black-3 text-center">
    <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': scrollLoader }" style="font-size:large;"></i> &nbsp; Loading...
  </h2>
</ng-container>
