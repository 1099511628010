import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SettingsRoutingModule } from './settings-routing.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SharedModule } from '@app/shared';
import { AdministratorComponent } from '@app/settings/Administrator/administrator.component';
import { CommitteesComponent } from '@app/settings/Committees/committees.component';
import { CommunicationComponent } from '@app/settings/Communication/communication.component';
import { EventsComponent } from '@app/settings/Events/events.component';
import { IntegrationsComponent } from '@app/settings/Integrations/integrations.component';
import { OperationComponent } from '@app/settings/Operations/operation.component';
import { PeopleComponent } from '@app/settings/People/people.component';
import { ProfileComponent } from '@app/settings/Profile/profile.component';
import { CbAgentComponent } from '@app/settings/cb-agent/cb-agent.component';
import { SettingsService } from '@app/settings/settings.service';
import { ImageSelectComponent } from '@app/settings/ImageCropper/imageSelect.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OrganisationAdminModule } from '@app/Organisation-admin/organisation-admin.module';
import { ModalService } from '@app/core/modal/modal.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AddEventDiagComponent } from '@app/settings/Events/addEventDiagComponent/addEventDiag.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DemoMaterialModule } from '../materials.module';
import { TrainingGroupsComponent } from './training-groups/training-groups.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CreateCategoryComponent } from './create-category/create-category.component';
import { DocumentGroupsComponent } from './document-groups/document-groups.component';
import { IntegrationComponent } from './integration/integration.component';
import { CloudIntegrationCardComponent } from './integration/cloud-integration-card/cloud-integration-card.component';
import { ServiceProviderIntegrationComponent } from './integration/service-provider-integration/service-provider-integration.component';
import { ManageAccountComponent } from './integration/manage-account/manage-account.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    TranslateModule,
    ReactiveFormsModule,
    SettingsRoutingModule,
    DemoMaterialModule,
    NgxMaterialTimepickerModule,
    FormsModule,
    SharedModule,
    ImageCropperModule,
    TranslateModule,
    OrganisationAdminModule,
    NgSelectModule,
    DemoMaterialModule,
    InfiniteScrollModule
  ],
  declarations: [
    AdministratorComponent,
    CommitteesComponent,
    CommunicationComponent,
    EventsComponent,
    IntegrationsComponent,
    OperationComponent,
    PeopleComponent,
    ProfileComponent,
    ImageSelectComponent,
    AddEventDiagComponent,
    CbAgentComponent,
    TrainingGroupsComponent,
    CreateCategoryComponent,
    DocumentGroupsComponent,
    IntegrationComponent,
    CloudIntegrationCardComponent,
    ServiceProviderIntegrationComponent,
    ManageAccountComponent
  ],
  exports: [],
  providers: [SettingsService, ModalService],
  entryComponents: [ImageSelectComponent, AddEventDiagComponent]
})
export class SettingsModule {}
