<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div class="cb-container cb-page-bg-color mt-lg-0 mt-5">
    <div class="container-fluid">
      <div class="row">
        <div class="col d-flex justify-content-between header-padding">
          <div>
            <h2 class="text-black-1 font-weight-bold" translate>
              <span class="sub-heading">
                <a
                  class="text-black-1 font-weight-bold"
                  style="font-size: 0.9em"
                  routerLink="/organisation/dashboard"
                  routerLinkActive="active"
                  translate
                >
                  organisation_dashboard_history.Dashboard
                </a>
              </span>
              <strong>
                >
                <span class="sub-heading" translate
                  >organisation_dashboard_history.event_type.{{ historyData['type'] }}</span
                >
                -
                <span class="sub-heading" translate
                  >organisation_dashboard_history.status_type.{{ historyData['status'] }}</span
                >
              </strong>
            </h2>
          </div>
          <div>
            <label translate>organisation_dashboard_history.Select program</label>
            <select class="cb-input w-140 ml-3" (change)="programChange($event.target.value)" [value]="selectedProgram">
              <option *ngFor="let program of listOfCompliance" value="{{ program['guid'] }}">{{
                program['name']
              }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <ng-container *ngFor="let drill of drillDownEventData; let i = index">
            <!-- [attr.data-index]="i" -->
            <div
              [ngClass]="
                !accArray.includes(i) ? 'cb-accordian mx-auto w-740 mt-4' : 'cb-accordian mx-auto w-740 mt-4 expand'
              "
            >
              <div class="acc-header d-flex justify-content-between">
                <h5 class="text-black-1">{{ drill.meeting_name }}</h5>
                <div class="d-flex align-items-center">
                  <!--              <h5 class="text-black-1 font-weight-bold m-0 pr-3">Jan 1, 2019 - Sept 12, 2019</h5>-->
                  <ng-container *ngIf="event_Type === 'no_participation_events'">
                    <span *ngIf="loaderArr.includes(i)" class="running-progress text-black-2" translate>
                      <i class="fas fa-sync-alt text-cyan pr-1"></i> organisation_dashboard_history.Running
                    </span>
                    <button
                      class="btn-save ml-4"
                      *ngIf="drill['is_running'] === 'Not Running' && !loaderArr.includes(i)"
                      (click)="runEvent(drill['guid'], i)"
                      translate
                    >
                      organisation_dashboard_history.Run
                    </button>
                    <button
                      class="btn-stop ml-4"
                      *ngIf="drill['is_running'] === 'Running' && !loaderArr.includes(i)"
                      (click)="stopEvent(drill['guid'], i)"
                      translate
                    >
                      organisation_dashboard_history.Stop
                    </button>
                  </ng-container>
                  <button class="btn-acc-toggle ml-4 mr-3" (click)="toggle(i, drill['guid'])">
                    <i
                      [ngClass]="
                        !accArray.includes(i) ? 'fas fa-chevron-down text-black-2' : 'fas fa-chevron-up text-black-2'
                      "
                    ></i>
                  </button>
                </div>
              </div>
              <div class="acc-body" style="margin-bottom: 1rem">
                <div class="row mb-2" *ngIf="upcomingEventData.length">
                  <div class="col-lg-4">
                    <h5 class="text-black-2 next-meting-info font-weight-bold d-inline-block" translate>
                      organisation_dashboard_history.Next meeting:
                    </h5>
                  </div>
                  <div class="col-lg-8">
                    <h5 class="text-black-1 next-meting-info d-inline-block">{{ upcomingEventData }}</h5>
                  </div>
                </div>

                <div class="row mb-2">
                  <div class="col-lg-4">
                    <h5 class="text-black-2 next-meting-info font-weight-bold d-inline-block" translate>
                      organisation_dashboard_history.Past meetings
                    </h5>
                  </div>
                </div>
                <table class="m-4 cb-table-1" *ngIf="drillDownEventHistoryData.length">
                  <thead>
                    <tr>
                      <td><h4 class="text-gray" translate>organisation_dashboard_history.Date</h4></td>
                      <td><h4 class="text-gray" translate>organisation_dashboard_history.Minutes</h4></td>
                      <td><h4 class="text-gray" translate>organisation_dashboard_history.Review Materials</h4></td>
                      <!--                    <td><h4 class="text-gray">Last Run</h4></td>-->
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let history of drillDownEventHistoryData; let i = index" [attr.data-index]="i">
                      <td>
                        <h4 class="text-black-2 font-weight-bold">{{ history.startTime }}</h4>
                      </td>
                      <td>
                        <a
                          *ngIf="history.mom_link != null"
                          href="{{ history.mom_link }}"
                          target="_blank"
                          class="btn-s3-link text-black-2"
                          translate
                        >
                          <i class="fas fa-link"></i>
                          organisation_dashboard_history.MoM Link
                        </a>
                        <a *ngIf="history.mom_link === null" target="_blank">-</a>
                      </td>
                      <td>
                        <a
                          *ngIf="history.log_link != null"
                          href="{{ history.log_link }}"
                          target="_blank"
                          class="btn-s3-link text-black-2"
                          translate
                        >
                          <i class="fas fa-link"></i>
                          organisation_dashboard_history.Log Link
                        </a>
                        <a *ngIf="history.log_link === null" target="_blank">-</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="row" *ngIf="nextPage">
                  <h5 class="text-black-2" style="margin:auto; cursor: pointer; ">
                    <a (click)="showMorePastEvents(i, drill['guid'])" translate=""
                      >organisation_dashboard_history.View more...</a
                    >
                  </h5>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
