import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class OrganisationAdminService {
  constructor(private http: HttpClient) {}

  getOrganizationDetails(org_uuid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_uuid}/`, {
      withCredentials: true
    });
  }

  getOrganizationMeetingWithStatus(
    org_uuid: string,
    frequency: string,
    page: number,
    search_title: '',
    guid = ''
  ): Observable<any> {
    let url = `/api/organizations/${org_uuid}/meetingstatus/?search_text=${search_title}`;
    if (frequency) {
      url = url + '&frequency=' + frequency;
      url = url + '&page=' + page + '&program=' + guid;
    } else {
      url = url + '&page=' + page + '&program=' + guid;
    }
    return this.http.get(url, {
      withCredentials: true
    });
  }

  runReviewEventNow(guid: string): Observable<any> {
    return this.http.get(`/api/events/${guid}/runrevieweventnow/`, {
      withCredentials: true
    });
  }
  stopReviewEventNow(guid: string): Observable<any> {
    return this.http.get(`/api/events/${guid}/endrevieweventnow/`, {
      withCredentials: true
    });
  }
  organizationEventScheduleUpdate(org_uuid: string, event_guid: string, payload: object): Observable<any> {
    return this.http.put(`/api/organizations/${org_uuid}/events/${event_guid}/schedule/`, payload, {
      withCredentials: true
    });
  }

  isOrganisationOnboarded(org_guid: string): Observable<any> {
    return this.http.get(`/api/organizations/${org_guid}/organizationonboardchecker/`, {
      withCredentials: true
    });
  }

  updateEventMessages(org_guid: string, review_event_guid: string, context: EventDetailsContext): Observable<any> {
    return this.http.put<any>('/api/organizations/' + org_guid + '/events/' + review_event_guid + '/', context, {
      withCredentials: true
    });
  }
  updateEventDateTime(org_guid: string, review_event_guid: string, context: any): Observable<any> {
    return this.http.put<any>('/api/organizations/' + org_guid + '/events/' + review_event_guid + '/', context, {
      withCredentials: true
    });
  }

  updateEventDetails(org_guid: string, review_event_guid: string, data: any): Observable<any> {
    return this.http.put<any>('/api/organizations/' + org_guid + '/events/' + review_event_guid + '/update/', data, {
      withCredentials: true
    });
  }

  updateEventCommitteeDetails(org_guid: string, review_event_guid: string, data: any): Observable<any> {
    return this.http.post<any>(
      '/api/organizations/' + org_guid + '/events/' + review_event_guid + '/committee-update/',
      data,
      {
        withCredentials: true
      }
    );
  }

  getEventDetails(org_guid: string, review_event_guid: string): Observable<any> {
    return this.http.get<any>('/api/organizations/' + org_guid + '/events/' + review_event_guid + '/', {
      withCredentials: true
    });
  }

  disableEvent(org_guid: string, review_event_guid: string, event: object): Observable<any> {
    let context = {
      is_enabled: event['is_enabled'],
      reason_for_disabled: event['reason_for_disabled']
    };
    return this.http.put<any>(
      '/api/organizations/' + org_guid + '/events/' + review_event_guid + '/enable-disable-event/',
      context,
      {
        withCredentials: true
      }
    );
  }

  getUrlTypes(): Observable<any> {
    return this.http.get<any>('/api/events/urltypes/', {
      withCredentials: true
    });
  }

  getOrganisationScheduleTypes(org_guid: string): Observable<any> {
    return this.http.get<any>('/api/organizations/' + org_guid + '/schedules/', {
      withCredentials: true
    });
  }
  getOrganisationProgramTypes(org_guid: string): Observable<any> {
    return this.http.get<any>('/api/organizations/' + org_guid + '/organizationprograms/', {
      withCredentials: true
    });
  }

  getOrganisationStatusTypes(): Observable<any> {
    return this.http.get<any>('/api/document-status/', {
      withCredentials: true
    });
  }

  getOrganisationIncidentStatusTypes(role: string): Observable<any> {
    return this.http.get<any>(`/api/incident-status/?role=${role}`, {
      withCredentials: true
    });
  }

  getOrganisationFormStatusTypes(roleFilter: string): Observable<any> {
    const url = `/api/form-status/?tab_status=${roleFilter}`;
    return this.http.get(url, {
      withCredentials: true
    });
  }

  deleteReviewDoc(org_guid: string, review_event_guid: string, review_document_guid: string): Observable<any> {
    return this.http.delete<any>(
      '/api/organizations/' + org_guid + '/events/' + review_event_guid + '/review-docs/' + review_document_guid,
      {
        withCredentials: true
      }
    );
  }

  //API service to get Allowed duration in Event schedule
  getEventAllowedDuration(): Observable<any> {
    return this.http.get<any>('/api/organizations/allowed_durations', {
      withCredentials: true
    });
  }
}

export interface EventDetailsContext {
  event_start_message: string;
  event_end_message: string;
}
