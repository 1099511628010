<app-spinner *ngIf="isloading"></app-spinner>
<ng-container *ngIf="!isloading">
  <div class="row">
    <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
      <h2 class="text-cyan m-0" style=" cursor:default" translate>Published_Documents.Title</h2>
      <div class="col top-header-filters">
        <button mat-icon-button *ngIf="show" (click)="refresh()">
          <mat-icon class="refresh">refresh</mat-icon>
        </button>

        <i class="fa fa-spinner fa-spin refresh" style="margin-right: 11px;" *ngIf="spnning"></i>
        <div class="search-people position-relative">
          <div class="input-group w-330">
            <input
              id="search"
              [(ngModel)]="searchText"
              class="form-control cb-input"
              placeholder="{{ this.placeholderSearch }}"
              name="search"
              (keyup)="updateSearchText($event)"
              (keyDown.enter)="Search(searchText)"
              type="text"
              #titleInput
            />
          </div>
          <i (click)="Search(titleInput.value)" class="fa fa-search search-icon"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="PublishedDocumentData?.length > 0; else no_content">
    <div
      class="col p-0 search-results"
      infiniteScroll
      [scrollWindow]="false"
      (scrolled)="onScroll()"
      [infiniteScrollDistance]="modalScrollDistance"
      [infiniteScrollThrottle]="modalScrollThrottle"
      (scrolledUp)="onScrollUp()"
    >
      <div class="table_wrapper">
        <div class="filter-checkbox">
          <button
            style="padding-right: 0px !important;"
            mat-button
            [disabled]="isDisabled()"
            [matTooltip]="isDisabled() ? 'Actions are disabled while documents are uploading.' : ''"
            matTooltipPosition="right"
            matTooltipClass="right mat_tooltip"
            [matMenuTriggerFor]="menu"
            class="table-config-menu"
          >
            <mat-icon aria-hidden="false" aria-label="Example home icon">filter_list</mat-icon>
            <mat-menu #menu="matMenu">
              <span class="table-config-menu-label">Select Columns</span>
              <div class="table-config-menu-options">
                <mat-checkbox
                  *ngFor="let cd of filterColumnOptions; let i = index"
                  (click)="$event.stopPropagation()"
                  [(ngModel)]="showHideColumns[cd.key]"
                >
                  {{ cd.label }}
                </mat-checkbox>
              </div>
            </mat-menu>
          </button>
        </div>
        <table class="w-100p bg-white dm-table table table-responsive">
          <thead>
            <tr style="height: 65px;">
              <th scope="col" class="font-weight-bold text-center" translate>
                Published_Documents.Table_title
              </th>
              <th scope="col" class="font-weight-bold text-center" translate>
                Published_Documents.Table_Author
              </th>
              <th scope="col" class="font-weight-bold" *ngIf="showHideColumns.isShowType" translate>
                Attention_Required.Table_type
              </th>
              <th *ngIf="showHideColumns.isShowFolderName" scope="col" class="font-weight-bold" translate>
                Attention_Required.Parent_folder
              </th>
              <th *ngIf="showHideColumns.isShowVersion" scope="col" class="font-weight-bold text-center" translate>
                Published_Documents.Version
              </th>
              <th scope="col" class="font-weight-bold text-center" translate>
                Published_Documents.Table_due_date
              </th>
              <th scope="col" class="font-weight-bold text-center" translate>
                Published_Documents.Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center" *ngFor="let Publish of PublishedDocumentData; let rowIndex = index">
              <td class="">
                <abbr title="{{ Publish['title'] }}">
                  <h4
                    class="dm-text-1 mt-3 text-truncate "
                    style="width: 300px;"
                    [class.text-decoration]="showHideColumns.isShowFolderName"
                  >
                    {{ Publish['title'] }}
                  </h4>
                </abbr>
              </td>
              <td>
                <h4 class="dm-text-2 mt-3">
                  {{ Publish['created_by']['name'] ? Publish['created_by']['name'] : '-' }}
                </h4>
              </td>
              <td *ngIf="showHideColumns.isShowType">
                <h4 class="dm-text-2 mt-3">
                  {{ Publish['document_type'] ? Publish['document_type'] : '-' }}
                </h4>
              </td>
              <td *ngIf="showHideColumns.isShowFolderName">
                <h4 class="dm-text-2 mt-3" style="text-align: start;">
                  {{ Publish['parent_folder'] ? Publish['parent_folder'] : '-' }}
                </h4>
              </td>
              <td *ngIf="showHideColumns.isShowVersion">
                <h4 class="dm-text-2 mt-3">{{ Publish['version'] ? Publish['version'] : '-' }}</h4>
              </td>
              <td>
                <h4 class="dm-text-2 mt-3">
                  {{ Publish['published_on_timestamp'] | date }}
                </h4>
              </td>
              <td style="text-align: start;">
                <div
                  [matTooltip]="isDisabled() ? 'Actions are disabled while documents are uploading.' : ''"
                  matTooltipPosition="left"
                  matTooltipClass="left mat_tooltip"
                >
                  <mat-icon
                    style="margin: 0px !important;"
                    class="mt-2 ml-4"
                    [style.color]="isDisabled() ? '#9AA7B4' : '#212529'"
                    [style.pointer-events]="!isDisabled() ? 'all' : 'none'"
                    [matMenuTriggerFor]="menu"
                    >more_horiz</mat-icon
                  >
                </div>
                <mat-menu #menu="matMenu" xPosition="before" class="fixed-menu-panel">
                  <ng-container *ngFor="let actions of Publish['allowed_actions_list']">
                    <button
                      mat-menu-item
                      (click)="getClickEvents(actions, Publish['guid'], Publish['version'], Publish['title'], Publish)"
                    >
                      {{ actions }}
                      <mat-icon *ngIf="actions == 'Edit Document' || actions == 'View Document'">launch</mat-icon>
                    </button>
                  </ng-container>
                  <button
                    *ngIf="Publish['document_type'] === 'document' || Publish['document_type'] === 'pdf'"
                    mat-menu-item
                    (click)="
                      getClickEvents(
                        Publish['export_status'],
                        Publish['guid'],
                        Publish['version'],
                        Publish['title'],
                        Publish
                      )
                    "
                  >
                    <ng-container [ngSwitch]="Publish['export_status']">
                      {{ exportStatusMapper[Publish['export_status']] }}
                      <mat-icon *ngSwitchCase="'INPROGRESS'">access_time</mat-icon>
                      <mat-icon *ngSwitchCase="'COMPLETED'">cloud_download</mat-icon>
                      <mat-icon *ngSwitchCase="'PENDING'"> import_export</mat-icon>
                      <mat-icon *ngSwitchCase="'FAILED'"> import_export</mat-icon>
                    </ng-container>

                    <ng-templ> </ng-templ>
                  </button>
                </mat-menu>
              </td>
              <!-- <td style="text-align: center">
                <mat-icon class="mt-2" [matMenuTriggerFor]="menu">more_horiz</mat-icon>
                <mat-menu xPosition="before" #menu="matMenu" class="fixed-menu-panel">
                  <ng-container *ngFor="let actions of Publish['allowed_actions_list']">
                    <button
                      mat-menu-item
                      (click)="getClickEvents(actions, Publish['guid'], Publish['version'], Publish['title'], Publish)"
                    >
                      Create New Version
                    </button>
                  </ng-container>
                </mat-menu>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #no_content>
  <div class="row scroll-view">
    <div class="col">
      <img class="empty-doc-list-image" src="../../../assets/images/documents/empty.svg" />
      <div class="text-empty-doc-list text-black-1" translate>Published_Documents.No_documents</div>
      <div class="sub-text-empty-doc-list" *ngIf="show_add_new_button">
        <a class="cursor-pointer text-cyan-force" (click)="goToCreateDoc()" style="font-size: 18px;" translate>
          Published_Documents.Add_new
        </a>
      </div>
    </div>
  </div>
</ng-template>
&nbsp;
<div *ngIf="scrollLoader" class="w-100p d-flex justify-content-center">
  <h2 class="font-weight-bold  ">
    <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': scrollLoader }" style="font-size:large;"></i> &nbsp; Loading...
  </h2>
</div>
