import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { NgbActiveModal, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import stringInfo from '../../../../translations/en-US.json';
import { TranslateService } from '@ngx-translate/core';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
@Component({
  selector: 'app-restore-editor',
  templateUrl: './restore-editor.component.html',
  styleUrls: ['./restore-editor.component.scss']
})
export class RestoreEditorComponent implements OnInit {
  RestorEditForm: FormGroup;
  submitted = false;
  isLoading = false;
  OrganisationID: string | null;
  guidSubscription: any;
  selectedItems: any = [];
  success: boolean;
  error: any;
  EditorSelected = true;
  date = stringInfo.Restore_Editor.Date;
  loading = true;
  store_due_date = '';
  minDate: NgbDateStruct;

  @Input() formType: string;
  @Input() guid: string;
  @Input() title_tag: string;
  @Input() message: string;
  @Input() DocumentGuid: string;
  @Input() docTitle: string;
  @Input() due_date: any;
  backend_error: string;
  statusData: any;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private documentsService: DocumentsService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.minDate = this.documentsService.getTomorrowDate();
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      if (this.due_date != null) {
        this.store_due_date = this.due_date;
        const due_date_arr = this.due_date.split('-');
        this.due_date = new NgbDate(parseInt(due_date_arr[0]), parseInt(due_date_arr[1]), parseInt(due_date_arr[2]));
      }
      this.getRestorEditForm();
    });

    this.loading = false;
  }
  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  getRestorEditForm() {
    this.RestorEditForm = this.formBuilder.group({
      due_date: [this.due_date, [Validators.required(), Validators.DateIsAfterToday()]],
      comment: ['', [Validators.maxLength(4096)]]
    });
  }
  get f() {
    return this.RestorEditForm.controls;
  }

  onDateSelection(date: any) {
    if (date) {
      let myDate = new Date(date.year, date.month - 1, date.day + 1);
      this.store_due_date = myDate.toISOString().split('T')[0];
    } else {
      this.store_due_date = this.store_due_date;
    }
  }

  save() {
    this.submitted = true;
    this.isLoading = true;
    let editor: any = [];
    // const data = { guid: this.guid };

    if (this.RestorEditForm.valid) {
      editor = this.guid;
      // this.isLoading = true;
      const RestoreData = this.RestorEditForm.value;
      // RestoreData['guid'] = this.guid;
      RestoreData['organization_users'] = [editor];
      RestoreData['role'] = 'editor';
      RestoreData['due_date'] = this.store_due_date;
      this.submitInviteEditor(RestoreData);
    } else {
      this.isLoading = false;
      return;
    }
  }

  changeName(statusData: any) {
    this.documentsService.setStatus10(statusData);
  }

  submitInviteEditor(RestoreData: any): void {
    this.documentsService
      .submitInviteDocumentEditorData(this.OrganisationID, this.DocumentGuid, RestoreData)
      .pipe(
        finalize(() => {
          this.RestorEditForm.markAsPristine();
          // this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.error = {
            due_date: ''
          };
          this.statusData = data;
          this.changeName(this.statusData);
          this.submitted = false;
          this.backend_error = '';
          this.activeModal.close(true);
        },
        (error: any) => {
          this.submitted = false;
          this.isLoading = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
  }
}
