<div class="d-flex justify-content-center align-items-center position-relative">
  <div class="certificate-container-expand">
    <div class="container">
      <div class="row">
        <div class="w-100p text-center">
          <img src="/assets/images/cb-lite/stamp.png" class="stamp-expand" alt="no-img" />
        </div>
      </div>
      <div class="row mt-3 justify-content-center">
        <div class="cert-title-expand">Certificate of Compliance</div>
      </div>
      <hr />
      <div class="m-3" style="min-height: 366px;">
        <div class="row text-left">
          <div class="cert-subtitle-expand">
            This is to certify that <b>{{ data.certificateData?.org_name }}</b> has successfully completed risk
            assessment and creation of policies and procedures in line with <b>45 CFR § 164.308(a)(1)(i).</b>
          </div>
        </div>
        <div class="row text-left mt-3">
          <div class="cert-subtitle-expand">
            The following list of policy and procedure documents was reviewed and approved by
            <b>{{ data.certificateData?.org_name }}</b> management on <b>{{ data.certificateData?.date }}</b>
          </div>
        </div>
        <div class="row mt-3" id="listContainer-expand">
          <div class="col-md-6">
            <ul class="category-style-expand d-flex flex-column" id="first-column-expand"></ul>
          </div>
          <div class="col-md-6">
            <ul class="category-style-expand d-flex flex-column" id="second-column-expand"></ul>
          </div>
        </div>
        <hr />
        <div class="d-flex justify-content-around align-items-center sign-container-expand">
          <div class="fs-17 cursor-pointer w-100p">
            <img src="/assets/images/logo/logo.png" class="cb-lite-logo-expand mx-1" alt="logo" srcset="" />
            <span class="font-weight-bold fs-17">ComplianceBot</span>
          </div>
          <img
            id="onlineImage"
            class="user-avatar-pic-expand"
            [src]="data.signature"
            height="70"
            width="130px"
            style="max-height: 100px; max-width: 130px;"
            alt="no-image"
          />
        </div>
      </div>
    </div>
  </div>
  <img (click)="closeDialog()" class="contract-btn" src="/assets/images/core/contract.svg" alt="" srcset="" />
</div>
