import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class VersionService {
  constructor(private http: HttpClient) {}

  getBackendVersion(): Observable<any> {
    return this.http.get(`/api/healthcheck/version/`, {
      withCredentials: true
    });
  }
}
