import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import stringInfo from '../../../../translations/en-US.json';

@Component({
  selector: 'app-create-training-success',
  templateUrl: './create-training-success.component.html',
  styleUrls: ['./create-training-success.component.scss']
})
export class CreateTrainingSuccessComponent implements OnInit {
  OrganisationID: any;
  DocURL: any;
  error: any;

  @Input() title_tag: string;
  @Input() DocumentGuid: string;
  @Input() DocumentTitle: string;
  @Input() docTitle: string;

  loginUser: any;
  loginUsername: any;
  loginUserEmail: any;
  AttentionList: any;
  guidSubscription: any;
  CampaignCreated = stringInfo.Title_Tag.Campaign_Temp;
  viewCampaign = stringInfo.Title_Tag.View_Campaign;
  Cancel = stringInfo.Title_Tag.Cancel;

  constructor(
    private orgService: OrganisationService,
    private documentsService: DocumentsService,
    private router: Router,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe((guid: any) => {
      this.OrganisationID = guid;
    });
  }

  goToCampaignPage() {
    this.activeModal.close({ isConfirmed: true });
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }
}
