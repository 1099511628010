<app-add-people
  *ngIf="modalType === 'people'"
  [title_tag]="title"
  [formType]="formType"
  [form_data]="formData"
></app-add-people>
<app-add-inactive-user *ngIf="modalType === 'Add people'" [title_tag]="title" [form_data]="formData">
</app-add-inactive-user>
<app-add-administrator
  *ngIf="modalType === 'administrator'"
  [title_tag]="title"
  [form_data]="formData"
></app-add-administrator>
<app-add-committee-members
  *ngIf="modalType === 'committee-members'"
  [title_tag]="title"
  [form_data]="formData"
  [committee_guid]="guid"
  [new_committee]="newCommittee"
></app-add-committee-members>
<app-add-committee *ngIf="modalType === 'committee'" [title_tag]="title" [form_data]="formData"></app-add-committee>
<app-create-training-group
  *ngIf="modalType === 'training group' || modalType === 'document group'"
  [title_tag]="title"
  [form_data]="formData"
></app-create-training-group>
<app-create-category *ngIf="modalType === 'Create Category'" [title_tag]="title"></app-create-category>
<app-disable-event *ngIf="modalType === 'disable-disregard'" [title]="title" [message]="message"></app-disable-event>
<app-lead-accept-confirm-dialog
  *ngIf="modalType === 'confirm-lead-accept-modal'"
  [title]="title"
  [message]="message"
  [userGuid]="userGuid"
>
</app-lead-accept-confirm-dialog>
<app-folder-selection
  *ngIf="modalType === 'document'"
  [title_tag]="title"
  [formType]="formType"
  [form_data]="formData"
  [storage_id]="storage_id"
></app-folder-selection>

<app-multi-select-scroll-document
  *ngIf="modalType === 'List all document version'"
  [title_tag]="title"
  [formType]="formType"
  [form_data]="formData"
></app-multi-select-scroll-document>
<app-select-templates
  *ngIf="modalType === 'Select Template'"
  [title_tag]="title"
  [template_guid]="template_guid"
  [storage_id]="storage_id"
  [type]="type"
  [template_type]="template_type"
>
</app-select-templates>
<!-- <app-invite-editors
  *ngIf="modalType === 'Manage Editors' || modalType === 'Manage Approvers'"
  [title_tag]="title"
  [DocumentGuid]="DocumentGuid"
  [DocCreatedBy]="DocCreatedBy"
  [docTitle]="docTitle"
  [type]="modalType"
>
</app-invite-editors> -->
<!-- <app-incident-reviewer
  *ngIf="modalType === 'Manage Reviewer' || modalType === 'Manage Approver'"
  [title_tag]="title"
  [incidentGuid]="incidentGuid"
  [incCreatedBy]="incCreatedBy"
  [incidentTitle]="incidentTitle"
></app-incident-reviewer> -->
<app-incident-reviewer-approver
  *ngIf="modalType === 'Manage Reviewer' || modalType === 'Invite Approvers'"
  [title_tag]="title"
  [incidentGuid]="incidentGuid"
  [incCreatedBy]="incCreatedBy"
  [incidentTitle]="incidentTitle"
  [type]="modalType"
>
</app-incident-reviewer-approver>
<app-manage-responder-approver
  *ngIf="modalType === 'Manage Responders' || modalType === 'Manage Form Approvers'"
  [title_tag]="title"
  [formGuid]="formGuid"
  [formCreatedBy]="formCreatedBy"
  [formTitle]="formTitle"
  [type]="modalType"
>
</app-manage-responder-approver>
<app-add-responder *ngIf="modalType === 'Add Responder'" [email]="email"> </app-add-responder>
<app-create-new-version
  *ngIf="modalType === 'Create New Version'"
  [title_tag]="title"
  [DocumentGuid]="DocumentGuid"
  [DocumentVersion]="DocumentVersion"
  [docTitle]="docTitle"
>
</app-create-new-version>

<!-- <app-continue-current-version
  *ngIf="modalType === 'Continue Current Version'"
  [title_tag]="title"
  [DocumentGuid]="DocumentGuid"
  [DocumentVersion]="DocumentVersion"
  [docTitle]="docTitle"
  [guid]="guid"
>
</app-continue-current-version> -->
<!-- <app-invite-approvers
  *ngIf="modalType === 'Manage Approvers'"
  [title_tag]="title"
  [DocumentGuid]="DocumentGuid"
  [DocCreatedBy]="DocCreatedBy"
  [docTitle]="docTitle"
>
</app-invite-approvers> -->
<!-- <app-incident-approvers
  *ngIf="modalType === 'Invite Approvers'"
  [title_tag]="title"
  [incidentGuid]="incidentGuid"
  [incCreatedBy]="incCreatedBy"
  [incidentTitle]="incidentTitle"
></app-incident-approvers> -->

<app-manage-document-group
  *ngIf="modalType === 'manage document group'"
  [selectedGroup]="formData"
></app-manage-document-group>

<app-mark-edit-done
  *ngIf="modalType === 'Mark Editing as Done' || modalType === 'Form Edit Complete'"
  [title_tag]="title"
  [DocumentGuid]="DocumentGuid"
  [docTitle]="docTitle"
  [modalType]="modalType"
>
</app-mark-edit-done>

<app-mark-edit-done
  *ngIf="modalType === 'No changes required'"
  [title_tag]="title"
  [DocumentGuid]="DocumentGuid"
  [docTitle]="docTitle"
>
</app-mark-edit-done>

<app-marked-edit-done
  *ngIf="modalType === 'Marked Editing as Done'"
  [message]="message"
  [title_tag]="title"
  [DocumentGuid]="DocumentGuid"
  [docTitle]="docTitle"
  [modalType]="modalType"
>
</app-marked-edit-done>

<app-approve-changes
  *ngIf="modalType === 'Approve Form Changes'"
  [title_tag]="title"
  [DocumentGuid]="DocumentGuid"
  [docTitle]="docTitle"
  [modalType]="modalType"
>
</app-approve-changes>

<app-approve-document
  *ngIf="modalType === 'Approve Changes' || modalType === 'Continue Current Version'"
  [title_tag]="title"
  [DocumentGuid]="DocumentGuid"
  [docTitle]="docTitle"
  [modalType]="modalType"
></app-approve-document>

<app-upload-document *ngIf="modalType === 'Import Document'" [title_tag]="title" [docTitle]="docTitle">
</app-upload-document>

<!-- <app-revoke-editor
  *ngIf="modalType === 'Revoke Editor'"
  [title_tag]="title"
  [guid]="guid"
  [message]="message"
  [DocumentGuid]="DocumentGuid"
  [docTitle]="docTitle"
>
</app-revoke-editor> -->
<app-remove-approver-editor
  *ngIf="modalType === 'Revoke Editor' || modalType === 'Revoke Approver'"
  [title_tag]="title"
  [guid]="guid"
  [message]="message"
  [DocumentGuid]="DocumentGuid"
  [docTitle]="docTitle"
>
</app-remove-approver-editor>
<app-delete-category
  *ngIf="modalType === 'Delete Incident Category' || modalType === 'Delete Document Group'"
  [title_tag]="title"
  [message]="message"
  [categoryGuid]="categoryGuid"
></app-delete-category>
<app-edit-incident-category
  *ngIf="modalType === 'Edit Incident Category'"
  [title_tag]="title"
  [categoryGuid]="categoryGuid"
  [categoryTitle]="categoryTitle"
  [categoryDescription]="categoryDescription"
></app-edit-incident-category>

<app-del-group-user *ngIf="modalType === 'Delete Group User'" [userGuid]="userGuid" [message]="message" [guid]="guid">
</app-del-group-user>

<app-add-group-user
  *ngIf="modalType === 'Add Group User'"
  [message]="message"
  [guid]="guid"
  [groupName]="groupName"
  [groupEmails]="groupEmails"
>
</app-add-group-user>

<!-- <app-revoke-approver
  *ngIf="modalType === 'Revoke Approver'"
  [title_tag]="title"
  [guid]="guid"
  [message]="message"
  [DocumentGuid]="DocumentGuid"
  [docTitle]="docTitle"
>
</app-revoke-approver> -->
<app-restore-editor
  *ngIf="modalType === 'Reassign Editor'"
  [title_tag]="title"
  [guid]="guid"
  [message]="message"
  [DocumentGuid]="DocumentGuid"
  [docTitle]="docTitle"
  [due_date]="due_date"
>
</app-restore-editor>
<app-reassign-approver
  *ngIf="
    modalType === 'Reassign Approver' ||
    modalType === 'Reassign Form Responder' ||
    modalType === 'Reassign Form Approver'
  "
  [title_tag]="title"
  [guid]="guid"
  [message]="message"
  [incidentGuid]="incidentGuid"
  [DocumentGuid]="DocumentGuid"
  [formGuid]="formGuid"
  [docTitle]="docTitle"
  [due_date]="due_date"
  [type]="type"
  [module]="module"
></app-reassign-approver>

<app-view-approvers
  *ngIf="modalType === 'View Approvers'"
  [title_tag]="title"
  [DocumentGuid]="DocumentGuid"
  [docTitle]="docTitle"
>
</app-view-approvers>

<app-view-editors
  *ngIf="modalType === 'View Editors' || modalType === 'View responders' || modalType === 'View Form Approvers'"
  [title_tag]="title"
  [DocumentGuid]="DocumentGuid"
  [docTitle]="docTitle"
  [type]="type"
>
</app-view-editors>

<app-change-req-for-invite-editors
  *ngIf="modalType === 'Form Change Request'"
  [title_tag]="title"
  [DocumentGuid]="DocumentGuid"
  [DocCreatedBy]="DocCreatedBy"
  [docTitle]="docTitle"
  [modalType]="modalType"
>
</app-change-req-for-invite-editors>
<app-change-due-date-form
  *ngIf="
    modalType === 'Change Due Date for invite editor' ||
    modalType === 'Change Due Date for invite approver' ||
    modalType === 'Change Due Date for Manage Responder' ||
    modalType === 'Change Due Date for Manage Form Approver'
  "
  [guid]="guid"
  [title_tag]="title"
  [DocumentGuid]="DocumentGuid"
  [incidentGuid]="incidentGuid"
  [formGuid]="formGuid"
  [module]="module"
  [DocCreatedBy]="DocCreatedBy"
  [user]="user"
  [start_message]="start_message"
  [due_date]="due_date"
  [role]="role"
></app-change-due-date-form>
<app-change-due-date-for-invite-approver
  *ngIf=""
  [guid]="guid"
  [title_tag]="title"
  [DocumentGuid]="DocumentGuid"
  [DocCreatedBy]="DocCreatedBy"
  [user]="user"
  [start_message]="start_message"
  [due_date]="due_date"
></app-change-due-date-for-invite-approver>

<app-publish-document
  *ngIf="modalType === 'Publish Document'"
  [title_tag]="title"
  [DocumentGuid]="DocumentGuid"
  [message]="message"
  [docTitle]="docTitle"
>
</app-publish-document>

<app-publish-documents
  *ngIf="modalType === 'Publish Documents' || modalType === 'Archive Document'"
  [title_tag]="title"
  [DocumentGuid]="DocumentGuid"
  [message]="message"
  [docTitle]="docTitle"
>
</app-publish-documents>

<app-create-document-success
  *ngIf="modalType === 'Document created'"
  [title_tag]="title"
  [DocumentGuid]="DocumentGuid"
  [DocumentTitle]="DocumentTitle"
  [docTitle]="docTitle"
>
</app-create-document-success>

<app-edit-document-success
  *ngIf="modalType === 'Document edited'"
  [title_tag]="title"
  [DocumentGuid]="DocumentGuid"
  [DocumentTitle]="DocumentTitle"
  [docTitle]="docTitle"
>
</app-edit-document-success>

<app-publish-doc-success
  *ngIf="modalType === 'Publish Document edited'"
  [title_tag]="title"
  [DocumentGuid]="DocumentGuid"
  [DocumentTitle]="DocumentTitle"
  [docTitle]="docTitle"
>
</app-publish-doc-success>

<app-create-document-success
  *ngIf="modalType === 'Template created'"
  [title_tag]="title"
  [DocumentGuid]="DocumentGuid"
  [DocumentTitle]="DocumentTitle"
  [docTitle]="docTitle"
  [template_guid]="template_guid"
>
</app-create-document-success>

<app-change-signature *ngIf="modalType === 'Change signature'" [title_tag]="title"> </app-change-signature>
<app-change-signature *ngIf="modalType === 'Upload signature'" [title_tag]="title"> </app-change-signature>
<app-generate-signature
  *ngIf="modalType === 'Generate Signature'"
  [title_tag]="title"
  [user_name]="user_name"
  [google_fonts]="google_fonts"
>
</app-generate-signature>
<app-draw-signature *ngIf="modalType === 'Draw Signature'" [title_tag]="title"> </app-draw-signature>

<app-view-trainees
  *ngIf="modalType === 'Trainees'"
  [title_tag]="title"
  [documentGuid]="documentGuid"
  [trainees]="trainees"
  [trainingGuid]="trainingGuid"
  [userData]="userData"
>
</app-view-trainees>

<app-view-documents
  *ngIf="modalType === 'VDocuments'"
  [title_tag]="title"
  [column]="column"
  [due_date]="due_date"
  [documentGuid]="documentGuid"
  [documents]="documents"
>
</app-view-documents>

<app-approve-training-document
  *ngIf="modalType === 'Complete Training'"
  [TrackingGuid]="TrackingGuid"
  [CampaignGuid]="CampaignGuid"
  [CampaignName]="CampaignName"
>
</app-approve-training-document>

<app-start-training
  *ngIf="modalType === 'Start Training'"
  [Tracking]="tracking"
  [TrackingGuid]="TrackingGuid"
  [CampaignGuid]="CampaignGuid"
  [CampaignName]="CampaignName"
  [message]="message"
>
</app-start-training>
<app-create-training-success
  *ngIf="modalType === 'Campaign created'"
  [title_tag]="title"
  [DocumentGuid]="DocumentGuid"
  [DocumentTitle]="DocumentTitle"
  [docTitle]="docTitle"
>
</app-create-training-success>

<app-create-training-success
  *ngIf="modalType === 'Campaign edited'"
  [title_tag]="title"
  [DocumentGuid]="DocumentGuid"
  [DocumentTitle]="DocumentTitle"
  [docTitle]="docTitle"
>
</app-create-training-success>
<app-create-jira-ticket
  *ngIf="modalType === 'Create jira ticket'"
  [title_tag]="title"
  [incidentGuid]="incidentGuid"
  [incidentTitle]="incidentTitle"
>
</app-create-jira-ticket>
<app-create-incident-success
  *ngIf="
    modalType === 'Incident Created' ||
    modalType === 'Incident Edited' ||
    modalType === 'Form Edited' ||
    modalType === 'Form Created'
  "
  [title_tag]="title"
  [incidentGuid]="incidentGuid"
  [incidentTitle]="incidentTitle"
  [formGuid]="formGuid"
  [formTitle]="formTitle"
  [requestorGuid]="requestorGuid"
></app-create-incident-success>

<app-pick-incident
  *ngIf="modalType === 'Pick Incident'"
  [title_tag]="title"
  [incidentGuid]="incidentGuid"
  [incidentTitle]="incidentTitle"
  [status_to_be_sent]="status_to_be_sent"
  [action]="action"
>
</app-pick-incident>
<app-revoke-incident-reviewer
  *ngIf="
    modalType === 'Revoke Reviewer' ||
    modalType === 'Revoke An Approver' ||
    modalType === 'Revoke Form Responder' ||
    modalType === 'Revoke Form Approver'
  "
  [title_tag]="title"
  [guid]="guid"
  [message]="message"
  [incidentGuid]="incidentGuid"
  [formGuid]="formGuid"
  [type]="type"
>
</app-revoke-incident-reviewer>
<app-block-incident
  *ngIf="modalType === 'Block Incident'"
  [title_tag]="title"
  [incidentGuid]="incidentGuid"
  [incidentTitle]="incidentTitle"
  [status_to_be_sent]="status_to_be_sent"
  [action]="action"
>
</app-block-incident>
<app-resolve-incident
  *ngIf="modalType === 'Resolve Incident'"
  [title_tag]="title"
  [incidentGuid]="incidentGuid"
  [incidentTitle]="incidentTitle"
  [status_to_be_sent]="status_to_be_sent"
  [action]="action"
>
</app-resolve-incident>
<app-reopen-incident
  *ngIf="modalType === 'Reopen Incident'"
  [title_tag]="title"
  [incidentGuid]="incidentGuid"
  [incidentTitle]="incidentTitle"
  [status_to_be_sent]="status_to_be_sent"
  [action]="action"
>
</app-reopen-incident>

<app-close-incident
  *ngIf="modalType === 'Close Incident'"
  [title_tag]="title"
  [incidentGuid]="incidentGuid"
  [incidentTitle]="incidentTitle"
  [status_to_be_sent]="status_to_be_sent"
  [action]="action"
>
</app-close-incident>
<app-link-an-issue
  *ngIf="modalType === 'Link an Issue'"
  [title_tag]="title"
  [incidentGuid]="incidentGuid"
  [incidentTitle]="incidentTitle"
>
</app-link-an-issue>

<app-unblock-incident
  *ngIf="modalType === 'Unblock Incident'"
  [title_tag]="title"
  [incidentGuid]="incidentGuid"
  [incidentTitle]="incidentTitle"
  [message]="message"
  [status_to_be_sent]="status_to_be_sent"
  [action]="action"
>
</app-unblock-incident>
<app-approve-incident
  *ngIf="modalType === 'Approve Incident'"
  [title_tag]="title"
  [incidentGuid]="incidentGuid"
  [incidentTitle]="incidentTitle"
  [status_to_be_sent]="status_to_be_sent"
  [action]="action"
>
</app-approve-incident>

<app-change-request-for-incident
  *ngIf="modalType === 'Request Change'"
  [title_tag]="title"
  [incidentGuid]="incidentGuid"
  [incCreatedBy]="incCreatedBy"
  [incidentTitle]="incidentTitle"
>
</app-change-request-for-incident>

<!-- <app-add-readers
  *ngIf="modalType === 'Add Readers'"
  [title_tag]="title"
  [guid]="guid"
  [readerEmails]="readerEmails"
  [DocumentVersion]="DocumentVersion"
  [requestedAccess]="requestedAccess"
>
</app-add-readers> -->

<app-rename-document-title
  *ngIf="modalType === 'Rename Document Title'"
  [title_tag]="title"
  [DocumentGuid]="DocumentGuid"
  [DocumentVersion]="DocumentVersion"
  [docTitle]="docTitle"
></app-rename-document-title>
<app-export-training *ngIf="modalType === 'Export Report'" [title_tag]="title"></app-export-training>
<app-save-cb-docuements *ngIf="modalType === 'Document Success'"></app-save-cb-docuements>
<app-invite-members *ngIf="modalType === 'Invite Members'"></app-invite-members>
<app-discard-pnp-changes
  [title_tag]="title"
  [message]="message"
  *ngIf="modalType === 'Discard Changes'"
></app-discard-pnp-changes>
<app-save-responses [title_tag]="title" [message]="message" *ngIf="modalType == 'Save Responses'"></app-save-responses>

<app-disconnect-integration [title_tag]="title" [message]="message" *ngIf="modalType === 'Disconnect Integration'">
</app-disconnect-integration>
