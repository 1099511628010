import { Component, OnInit, Inject, Input } from '@angular/core';
import { OrganisationService } from '@app/core/organisation.service';
import { OrganisationAdminService } from '@app/Organisation-admin/organisation-admin.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-disable-event',
  templateUrl: './disable-event.component.html',
  styleUrls: ['./disable-event.component.scss']
})
export class DisableEventComponent implements OnInit {
  reason: string;
  eventDisableForm: FormGroup;
  @Input() title: string;
  @Input() message: string;

  constructor(private formBuilder: FormBuilder, public activeModal: NgbActiveModal) {
    this.createForm();
  }

  ngOnInit() {}
  close() {
    this.activeModal.close(false);
  }
  confirm() {
    if (this.eventDisableForm.valid) {
      const data = this.eventDisableForm.value['reason_for_disabled'];
      this.activeModal.close({
        confirm: true,
        reason: data
      });
    }
  }

  private createForm() {
    this.eventDisableForm = this.formBuilder.group({
      reason_for_disabled: ['', Validators.maxLength(1024)]
    });
  }
}
