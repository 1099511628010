<div class="modal-header cb-modal-header">
  <div>
    <h4 class="modal-title font-weight-bold text-black-1" translate>
      {{ type == 'Manage Form Approvers' ? 'Manage Approvers' : 'Manage Responders' }}
    </h4>
    <i
      class="fa fa-times text-black-1"
      style="width: 12px;
                height: 12px;
                right: 1em;
                cursor: pointer;
                position: absolute;
                top: 1.3em;"
      (click)="activeModal.close({ isUpdate: true, result: actions, show_msg: false })"
      ngbAutoFocus
      translate
    ></i>
  </div>
</div>

<div class="modal-body">
  <div class="row mt-1 mb-3" *ngIf="backend_error">
    <div class="col-12">
      <span class="text-danger backend_error" translate> * {{ backend_error }}</span>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-3">
      <label class="text-black-4 p-2" translate
        >{{
          type == 'Manage Form Approvers' ? 'Incident_Manage_Approver.Incident' : 'Incident_Manage_Reviewer.Incident'
        }}
      </label>
    </div>
    <div class="col-9">
      <div class="input-group">
        <input class="form-control cb-input" value="{{ formTitle }}" disabled />
      </div>
    </div>
  </div>

  <form [formGroup]="form" novalidate>
    <div class="row mt-2">
      <div class="col-3">
        <label class="text-black-2 p-2" translate>
          {{ type == 'Manage Form Approvers' ? 'Incident_Manage_Approver.Select_Approver' : 'Select Responders' }}
        </label>
      </div>
      <div class="col-12">
        <div class="position-relative">
          <ng-multiselect-dropdown
            class="multi-select-dropdown"
            [placeholder]="type == 'Manage Form Approvers' ? 'Select Approvers' : 'Responders'"
            [data]="userList"
            [(ngModel)]="selectedItems"
            [settings]="dropdownSettings"
            (onSelect)="onItemSelect($event)"
            (onDeSelect)="onItemDeSelect($event)"
            [ngModelOptions]="{ standalone: true }"
            (onFilterChange)="filterChange($event)"
            (onDropDownClose)="closeResponderDropdown($event)"
            #multiSelectDropdown
          >
          </ng-multiselect-dropdown>
          <div
            class="tooltip-custom position-absolute"
            *ngIf="searchString.length && this.type !== 'Manage Form Approvers'"
            (click)="addResponder(searchString)"
          >
            <button
              class="tooltiptext"
              [disabled]="!isEmailvalid || checkEmailExistence(searchString)"
              [ngStyle]="{
                'background-color': !isEmailvalid || checkEmailExistence(searchString) ? 'rgba(0,0,0,.26)' : '#4db7c6'
              }"
            >
              Add
            </button>
          </div>
        </div>
        <div class="col-17 error-mssg" *ngIf="!selectedItems.length && submitted">
          <label for="users" class="text-danger" style="margin-left: 142px;" translate>
            {{
              type == 'Manage Form Approvers'
                ? 'Incident_Manage_Approver.invite_approver.*Atleast one approver is required.'
                : '*Atleast one responder is required.'
            }}</label
          >
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-3">
        <label for="due_date" class="text-black-1 p-2" translate>{{
          type == 'Manage Form Approvers'
            ? 'Incident_Manage_Approver.Due_Date'
            : 'Incident_Manage_Reviewer.invite_reviewer.Due Date'
        }}</label>
      </div>
      <div class="col-9">
        <div class="input-group">
          <input
            id="change_due_date"
            class="form-control cb-input"
            placeholder="yyyy-mm-dd"
            formControlName="due_date"
            name="due_date"
            [minDate]="minDate"
            ngbDatepicker
            #d="ngbDatepicker"
            (click)="d.toggle()"
            (dateSelect)="onDateSelection($event)"
            required
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary fa fa-calendar-alt cb-input"
              (click)="d.toggle()"
              type="button"
            ></button>
          </div>
          <div class="col-12 error-mssg" *ngIf="(f.due_date.touched || submitted) && f.due_date.errors">
            <label for="due_date" class="text-danger" *ngIf="f.due_date.errors.required" translate>
              {{
                type == 'Manage Form Approvers'
                  ? 'Incident_Manage_Approver.invite_approver.*Due Date is required.'
                  : 'Incident_Manage_Reviewer.invite_reviewer.*Due Date is required.'
              }}</label
            >
            <label for="due_date" class="text-danger" *ngIf="f.due_date.errors.DateTodayOrAfter_error" translate>
              {{
                type == 'Manage Form Approvers'
                  ? 'Incident_Manage_Approver.invite_approver.*Due Date must be greater than today.'
                  : 'Incident_Manage_Reviewer.invite_reviewer.*Due Date must be greater than today.'
              }}</label
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-3">
        <label for="comment" class="text-black-1 p-2" translate
          >{{
            type == 'Manage Form Approvers'
              ? 'Incident_Manage_Approver.invite_approver.Comment'
              : 'Incident_Manage_Reviewer.invite_reviewer.Comment'
          }}
        </label>
      </div>
      <div class="col-9">
        <div class="input-group">
          <textarea
            id="comment"
            class="form-control cb-input"
            [placeholder]="'Comment'"
            name="comment"
            type="text"
            formControlName="comment"
            cols="64"
            rows="5"
            [maxLength]="4097"
          ></textarea>
          <div class=" col-12 error-mssg" *ngIf="(f.comment.touched || f.comment.dirty) && f.comment.errors">
            <label
              for="name"
              style="margin-left:-15px;"
              class="text-danger"
              *ngIf="f.comment.errors.maxLength"
              translate
            >
              Incident_Manage_Reviewer.* Ensure that the comment has no more than 4096 characters.</label
            >
          </div>
        </div>
      </div>
    </div>
  </form>
  <button class="btn-save ml-4 w-100 mt-3 mb-3 float-right" *ngIf="!isLoading" (click)="save()" translate>
    {{
      type == 'Manage Form Approvers' ? 'Incident_Manage_Approver.Send_Invite' : 'Incident_Manage_Reviewer.Send_Invite'
    }}
  </button>
  <button class="btn-save ml-4 w-100 mt-3 mb-3 float-right" disabled *ngIf="isLoading">
    <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
  </button>
  <button
    class="btn-cancel ml-4 w-100 mt-3 float-right"
    (click)="activeModal.close({ isUpdate: true, result: actions, show_msg: false })"
    ngbAutoFocus
    translate
  >
    {{
      type == 'Manage Form Approvers'
        ? 'Incident_Manage_Approver.invite_approver.Cancel'
        : 'Incident_Manage_Reviewer.Close'
    }}
  </button>
</div>
<div class="approver-modal-footer cb-modal-footer mt-5" *ngIf="reviewerApproverList?.length > 0">
  <table class="table table-borderless">
    <thead>
      <tr>
        <th scope="col-6">
          <h4 class="modal-title font-weight-bold text-black-1" translate>
            {{ type == 'Manage Form Approvers' ? 'Approvers' : 'Responders' }}
          </h4>
        </th>
        <th scope="col-2">
          <h4 class="modal-title font-weight-bold text-black-1" translate>Incident_Manage_Approver.Due_Date</h4>
        </th>
        <th scope="col-2">
          <h4 class="modal-title font-weight-bold text-black-1" translate>Incident_Manage_Approver.Status</h4>
        </th>
        <th scope="col-2"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of reviewerApproverList">
        <td>
          <h4 class="text-black-1 text-left" scope="row">
            <span
              [ngClass]="item.initials.length === 1 ? 'i-circleinitial' : ''"
              class="i-circle text-black-1 mr-2 font-weight-bold"
              >{{ item.initials }}</span
            >{{ item.user.name ? item.user.name : item.user.email }}
          </h4>
        </td>
        <td>
          <h4 class="text-black-1">{{ item.due_date | date }}</h4>
        </td>
        <td>
          <h4 class="text-black-1 capitalize">{{ item.status }}</h4>
        </td>
        <td>
          <mat-icon
            class="cursor-pointer"
            *ngIf="item.status.toLowerCase() == 'yet to start'"
            (click)="openChangeDueFrom(item)"
            >edit</mat-icon
          >
          <mat-icon
            class="cursor-pointer"
            *ngIf="item.status.toLowerCase() == 'submitted' || item.status.toLowerCase() == 'approved'"
            (click)="openRestoreApproverForm(item, formTitle)"
            >refresh</mat-icon
          >
          <mat-icon
            class="cursor-pointer"
            *ngIf="item.status.toLowerCase() == 'yet to start'"
            (click)="
              revokeResponderApprover(formGuid, item.guid, item.user.name ? item.user.name : item.user.email, type)
            "
            >delete_outline</mat-icon
          >
        </td>
      </tr>
    </tbody>
  </table>
  <br />
</div>
