import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { OrganisationService } from '@app/core/organisation.service';
import { ToasterService } from '@app/shared/toaster/toastr.service';

@Component({
  selector: 'app-cloud-details',
  templateUrl: './cloud-details.component.html',
  styleUrls: ['./cloud-details.component.scss']
})
export class CloudDetailsComponent implements OnInit {
  loading = false;
  testDetails: any = {};
  testList: any = [];
  resourcesList: any = [];

  selectedTab: string = 'details';
  organisationId = '';
  searchText = '';
  status = '';
  testPageStatus = '';
  testId = '';
  page = 1;
  statusCount = {};
  searchResourceText = '';
  resourcePage = 1;
  statuses: any = [];
  activeStatus = '';
  statusMapper = {
    '': 'all-status',
    danger: 'status-danger',
    warning: 'status-warning',
    ignored: 'status-ignored',
    not_started: 'status-not-started',
    compliant: 'status-compliant'
  };
  constructor(
    private route: Router,
    private documentService: DocumentsService,
    private actRoute: ActivatedRoute,
    private orgService: OrganisationService,
    private toasterServ: ToasterService
  ) {
    this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.organisationId = guid;
    });
    this.actRoute.params.subscribe(res => {
      this.testId = res['test'];
      this.page = res['page'];
      this.testPageStatus = res['status'];
      this.getAllTest();
    });
  }

  ngOnInit(): void {}
  redirectToTests(isPage: boolean): void {
    if (isPage) {
      this.route.navigateByUrl(
        `/organisation/cloud?testTab=true&page=${this.page}&isTestIgnored=${this.testDetails.status ==
          'ignored'}&status=${this.testPageStatus}`
      );
    } else {
      this.route.navigateByUrl(
        `/organisation/cloud?testTab=true&page=${1}&isTestIgnored=${this.testDetails.status == 'ignored'}&status=${
          this.testPageStatus
        }`
      );
    }
  }
  getFrameworkClass(framework: string): string {
    switch (framework.toLowerCase()) {
      case 'hipaa':
        return 'hipaa';
      case 'soc2':
        return 'soc2';
      case 'iso27001':
        return 'iso27001';
      case 'gdpr':
        return 'gdpr';
      default:
        return '';
    }
  }

  changeTab(title: string) {
    this.selectedTab = title;
  }

  ignoreTest() {
    this.loading = true;
    let payload = { test_guids: [this.testId] };
    this.documentService.ignoreTests(this.organisationId, payload).subscribe(
      (res: any) => {
        this.getAllTest();
        this.loading = false;
        this.toasterServ.showSuccess(res.msg);
      },
      (error: any) => {
        this.loading = false;
        this.toasterServ.showError(error.error);
      }
    );
  }
  unignoreTest(guid: string) {
    this.loading = true;
    let payload = { test_guids: [this.testId] };
    this.documentService.unignoreTests(this.organisationId, payload).subscribe(
      (res: any) => {
        this.loading = false;
        this.toasterServ.showSuccess(res.msg);
        this.getAllTest();
      },
      (error: any) => {
        this.loading = false;
        this.toasterServ.showError(error.error);
      }
    );
  }
  getAllTest() {
    this.loading = true;
    this.documentService.getCloudTestDetails(this.organisationId, this.testId).subscribe(
      res => {
        this.testDetails = res;
        this.testList = res['test_details'] ? res['test_details']['controls'] : [];
        this.loading = false;
      },
      error => {
        this.loading = false;
      }
    );
  }

  getStatusClass(status: string): string {
    switch (status.toLowerCase()) {
      case 'compliant':
        return 'compliant-dot';
      case 'danger':
        return 'danger-dot';
      case 'not_started':
        return 'not-started-dot';
      case 'warning':
        return 'warning-dot';
      case 'ignored':
        return 'ignored-dot';
      // Add other status cases as needed
      default:
        return '';
    }
  }
  getDate(date: string) {
    let newDate = date.split(' ')[0] + ' ' + date.split(' ')[1];
    return newDate;
  }
  setStatus(status: string) {
    this.activeStatus = status;
  }
  setStatuses(res: any) {
    this.statuses = res;
  }
}
