import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'custom-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss']
})
export class PopoverComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
