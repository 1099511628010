import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { Trainee } from '@app/Organisation-admin/documents/training-report-page/training-report-page.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-view-trainees',
  templateUrl: './view-trainees.component.html',
  styleUrls: ['./view-trainees.component.scss']
})
export class ViewTraineesComponent implements OnInit {
  OrganisationID: string | null;
  guidSubscription: any;
  error: any;
  loading = true;

  @Input() formType: string;
  @Input() documentGuid: string;
  @Input() title_tag: string;
  @Input() trainees: Trainee[];
  @Input() trainingGuid: string;
  @Input() userData: any;

  constructor(
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private documentsService: DocumentsService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    console.log(this.userData);
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
    });
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  getInitials(name: string): string {
    let initials = '-';
    if (!!name) {
      let fullName = name.split(' ');
      if (fullName.length === 1) {
        return fullName[0][0].toUpperCase();
      }
      initials = `${fullName[0][0].toUpperCase()}${!!fullName[1][0] ? fullName[1][0].toUpperCase() : ''}`;
    }
    return initials;
  }
}
