import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponseBase } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger } from '../logger.service';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class RedirectHttpInterceptor implements HttpInterceptor {
  private readonly logoPartialUrl = '/login/';

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (event instanceof HttpResponseBase) {
      const response = event as HttpResponseBase;
      if (response && response.ok && response.url) {
        if (response.url.toLowerCase().indexOf(this.logoPartialUrl) >= 0) {
          const queryStringIndex = response.url.indexOf('?');
          const loginUrl =
            queryStringIndex && queryStringIndex > 0 ? response.url.substring(0, queryStringIndex) : response.url;
          console.log('User logout detected, redirecting to login page: %s', loginUrl);
          window.location.href = loginUrl;
        }
      }
    }
    return next.handle(req);
  }
}
