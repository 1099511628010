import { Component, OnInit, Input } from '@angular/core';
import { OrganisationService } from '@app/core/organisation.service';
import { SettingsService } from '@app/settings/settings.service';
import { ModalService } from '@app/core/modal/modal.service';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { ActivatedRoute, Router } from '@angular/router';
import { take, map } from 'rxjs/operators';
import { finalize } from 'rxjs/operators';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { SidenavService } from '@app/core/modal/sidenav.service';
import { forkJoin, of } from 'rxjs';
import { CustomsidenavComponent } from '../../../shared/modal/customsidenav/customsidenav.component';

@Component({
  selector: 'app-add-readers',
  templateUrl: './add-readers.component.html',
  styleUrls: ['./add-readers.component.scss']
})
export class AddReadersComponent implements OnInit {
  @Input() title_tag: string;
  @Input() guid: string;
  @Input() DocumentVersion: string;
  @Input() readerEmails: any;
  @Input() requestedAccess: any = [];
  newEmailListOfReaders: any[] = [];
  guidSubscription: any;
  pagnationData: any;
  OrganisationID: string | null;
  peopleList: object[];
  peopleCount = 0;
  scrollLoader = false;
  is_revoke_loading = false;
  loading = true;
  error: any;
  page = 1;
  existingReaderEmails: any;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  backend_error_addReader: any;
  paginatedDataUrl: any;
  docReaderRole: boolean = true;
  isLoading: boolean = false;
  private nameColors = new Map<string, string>();
  organizationAddUsers: any = [];
  organizationRemoveUsers: any = [];

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    private settingsService: SettingsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private documentsService: DocumentsService,
    private modalService: ModalService,
    private translate: TranslateService,
    private toasterService: ToasterService,
    private sidenavService: SidenavService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      document.getElementById('mySidenav').classList.toggle('open');
    }, 100);
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getPeopleList();
    });
    this.existingReaderEmails = [...this.readerEmails];
  }

  private getPeopleList(): void {
    console.log(this.readerEmails);
    this.settingsService
      .getOrganizationPeopleListWithdocReaderRoleAndPagination(this.OrganisationID, this.page, this.docReaderRole)
      .subscribe(
        data => {
          this.peopleList = data.results;
          for (let i = 0; i < this.requestedAccess.length; i++) {
            for (let j = 0; j < this.peopleList.length; j++) {
              if (this.peopleList[j]['user_guid'] == this.requestedAccess[i]) {
                this.peopleList[j]['requestedAccess'] = true;
                break;
              }
            }
          }
          this.paginatedDataUrl = data;
          // this.initializeOrganizationAddUsers();
          this.loading = false;
          this.peopleCount = this.peopleList.filter((x, i) => {
            this.peopleList[i]['isLoading'] = false;
            this.peopleList[i]['is_revoke_loading'] = false;
            return x['roles'].includes('document_reader');
          }).length;
        },
        error => {
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error_addReader = error;
          });
        }
      );
  }

  onScrollUp() {
    if (this.paginatedDataUrl?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }

  onScrollData() {
    this.scrollLoader = true;
    this.settingsService
      .getOrganizationPeopleListWithdocReaderRoleAndPagination(
        this.OrganisationID,
        (this.page += 1),
        this.docReaderRole
      )
      .subscribe(
        data => {
          this.scrollLoader = false;
          this.pagnationData = data.results;
          this.paginatedDataUrl = data;
          this.peopleList = this.peopleList.concat(data.results);
          this.peopleCount = this.peopleList.filter((x, i) => {
            this.peopleList[i]['isLoading'] = false;
            this.peopleList[i]['is_revoke_loading'] = false;
            return x['roles'].includes('document_reader');
          }).length;
          // this.initializeOrganizationAddUsers();
        },
        error => {
          this.scrollLoader = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error_addReader = error;
          });
        }
      );
  }
  onScroll() {
    if (this.paginatedDataUrl?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }
  getInitials(first_name: string, last_name: string): string {
    const firstNameInitial = first_name.charAt(0);
    const lastNameInitial = last_name.charAt(0);
    return `${firstNameInitial}${lastNameInitial}`;
  }
  getColor(name: string): string {
    if (this.nameColors.has(name)) {
      return this.nameColors.get(name)!;
    } else {
      const color = this.getRandomColor();
      this.nameColors.set(name, color);
      return color;
    }
  }
  getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  openPeopleForm() {
    this.sidenavService
      .open(CustomsidenavComponent, { title: 'Add New Reader', modalType: 'Add New Reader' })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        this.page = 1;
        this.getPeopleList();
      });
  }

  closePeopleAddModal(): void {
    this.backend_error_addReader = '';
    this.newEmailListOfReaders;
  }
  closeNav() {
    this.sidenavService.close(true);
  }
  isSaveDisabled(): boolean {
    return this.organizationAddUsers.length > 0 || this.organizationRemoveUsers.length > 0;
  }
  isChecked(people: any): boolean {
    return this.readerEmails.includes(people['email']) && !people['is_revoke_loading'];
  }

  onCheckboxChange(event: Event, guid: string, UserEmail: any): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      if (!this.organizationAddUsers.includes(guid) && !this.existingReaderEmails.includes(UserEmail)) {
        this.organizationAddUsers.push(guid);
      } else if (this.organizationRemoveUsers.includes(guid)) {
        this.organizationRemoveUsers.splice(this.organizationRemoveUsers.indexOf(guid), 1);
      }
      if (!this.readerEmails.includes(UserEmail)) {
        this.readerEmails.push(UserEmail);
      }
    } else {
      const addIndex = this.organizationAddUsers.indexOf(guid);
      if (addIndex > -1) {
        this.organizationAddUsers.splice(addIndex, 1);
      }
      if (!this.organizationRemoveUsers.includes(guid) && this.existingReaderEmails.includes(UserEmail)) {
        this.organizationRemoveUsers.push(guid);
      }
      const emailIndex = this.readerEmails.indexOf(UserEmail);
      if (emailIndex > -1) {
        this.readerEmails.splice(emailIndex, 1);
      }
    }
  }
  save(): void {
    this.isLoading = true;
    this.backend_error_addReader = '';
    const addData = this.organizationAddUsers.length > 0 ? { organization_users: this.organizationAddUsers } : null;
    const removeData =
      this.organizationRemoveUsers.length > 0 ? { organization_users: this.organizationRemoveUsers } : null;
    const addReaderObservable = addData
      ? this.documentsService.SubmitAddReadersData(this.OrganisationID, this.DocumentVersion, addData)
      : of(null);
    const removeReaderObservable = removeData
      ? this.documentsService.SubmitRevokeReadersData(this.OrganisationID, this.DocumentVersion, removeData)
      : of(null);

    forkJoin([addReaderObservable, removeReaderObservable]).subscribe(
      ([addReaderResponse, removeReaderResponse]) => {
        if (addReaderResponse) {
          this.translate.get('published_doc_readers.readers_toaster_message.add_reader').subscribe(res => {
            this.toasterService.showSuccess(res);
          });
        }
        if (removeReaderResponse) {
          this.translate.get('published_doc_readers.readers_toaster_message.remove_readers').subscribe(res => {
            this.toasterService.showSuccess(res);
          });
        }
        this.sidenavService.updateData({ isUpdate: true, result: addReaderResponse || removeReaderResponse });
        this.sidenavService.close(false);
        this.isLoading = false;
      },
      error => {
        this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
        this.backendErrorService.backendError$.subscribe(errorMsg => {
          this.backend_error_addReader = errorMsg;
          this.sidenavService.updateData(this.backend_error_addReader, true);
        });
        this.isLoading = false;
      }
    );
  }
}
