import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganisationService } from '@app/core/organisation.service';
import { finalize } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IncidentService } from '@app/Organisation-admin/incident/incident.service';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';

@Component({
  selector: 'app-resolve-incident',
  templateUrl: './resolve-incident.component.html',
  styleUrls: ['./resolve-incident.component.scss']
})
export class ResolveIncidentComponent implements OnInit {
  resolveIncidentForm: FormGroup;
  submitted = false;
  isLoading = false;
  OrganisationID: string | null;
  guidSubscription: any;
  success: boolean;
  error: any;
  @Input() formType: string;
  @Input() title_tag: string;
  @Input() incidentGuid: string;
  @Input() incidentTitle: string;
  @Input() status_to_be_sent: string;
  @Input() action: string;
  backend_error: string;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private incidentService: IncidentService
  ) {}

  ngOnInit() {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getresolveIncidentForm();
    });
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  getresolveIncidentForm() {
    this.resolveIncidentForm = this.formBuilder.group({
      status: this.status_to_be_sent,
      action: this.action,
      remarks: ['', Validators.maxLength(4096)]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.resolveIncidentForm.controls;
  }

  save() {
    this.submitted = true;
    if (this.resolveIncidentForm.valid) {
      this.isLoading = true;
      const resolveIncidentForm = this.resolveIncidentForm.value;
      this.submitCollaborationTask(resolveIncidentForm);
    }
  }

  submitCollaborationTask(resolveIncidentForm: any): void {
    this.incidentService
      .collaborationTask(this.OrganisationID, this.incidentGuid, resolveIncidentForm)
      .pipe(
        finalize(() => {
          this.resolveIncidentForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.error = {
            status: '',
            remarks: ''
          };
          this.submitted = false;
          this.backend_error = '';
          this.activeModal.close({ result: data, isUpdate: true });
        },
        (error: any) => {
          this.submitted = false;
          this.isLoading = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
  }
}
