import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbDate, NgbDateStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { OrganisationService } from '@app/core/organisation.service';
import { finalize } from 'rxjs/operators';
import { UserService } from '@app/core/user.service';
import { ModalService } from '@app/core/modal/modal.service';
import { take } from 'rxjs/operators';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { TranslateService } from '@ngx-translate/core';
import stringInfo from '../../../../translations/en-US.json';
import { IncidentService } from '@app/Organisation-admin/incident/incident.service';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';

@Component({
  selector: 'app-incident-reviewer-approver',
  templateUrl: './incident-reviewer-approver.component.html',
  styleUrls: ['./incident-reviewer-approver.component.scss']
})
export class IncidentReviewerApproverComponent implements OnInit {
  date = stringInfo.Document_Invite_Approver.Date;
  form: FormGroup;
  submitted = false;
  isLoading = false;
  OrganisationID: string | null;
  guidSubscription: any;
  success: boolean;
  dropdownList: any = [];
  selectedItems: any = [];
  dropdownSettings = {};
  error: any;
  selectedUser = true;
  reviewerApproverList: object[];
  UsersList: object[];
  DataList: object[];
  loginUser: any;
  loginUsername: string;
  loginUserEmail: string;
  minDate: NgbDateStruct;
  @Input() incidentGuid: string;
  @Input() title_tag: string;
  @Input() incCreatedBy: string;
  @Input() incidentTitle: string;
  @Input() type: string;
  @Input() comment: string;
  @Input() due_date: any;
  statusData: any;
  reviewerstatusData: any;
  backend_error: string;
  userList: object[];
  revokeReviewerdata: string;
  revokeApproverData: string;
  reassignReviewerdata: string;
  reassignApproverData: string;
  store_due_date = '';
  actions: any;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private incidentService: IncidentService,
    private userservice: UserService,
    private modalService: ModalService,
    private toaster: ToasterService,
    private translate: TranslateService // private config: NgbDatepickerConfig
  ) {}

  ngOnInit() {
    const currentDate = new Date();
    const tomorrowDate = new Date();
    tomorrowDate.setDate(currentDate.getDate() + 1);

    // Set minDate to tomorrow's date
    this.minDate = {
      year: tomorrowDate.getFullYear(),
      month: tomorrowDate.getMonth() + 1,
      day: tomorrowDate.getDate()
    };
    // Configure the datepicker to disable dates before today
    // this.config.minDate = this.minDate;
    // this.config.outsideDays = 'hidden';
    if (this.due_date != null) {
      const due_date_arr = this.due_date.split('-');
      this.due_date = new NgbDate(parseInt(due_date_arr[0]), parseInt(due_date_arr[1]), parseInt(due_date_arr[2]));
    }
    this.loginUser = this.userservice.getuserdetails();
    this.loginUsername = this.loginUser.user['username'];
    this.loginUserEmail = this.loginUser.user['email'];

    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getForm();
      this.getReviewerApproverList();
      this.getUnassignedReviewerApproverList();
    });
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'guid',
      textField: 'FullName',
      itemsShowLimit: 10,
      enableCheckAll: false,
      allowSearchFilter: true,
      orderby: 'FullName'
    };
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  onItemSelect(item: any) {
    this.checkSelection();
  }

  onItemDeSelect(items: any) {
    this.checkSelection();
  }

  checkSelection() {
    this.selectedItems.length > 0 ? (this.selectedUser = true) : (this.selectedUser = false);
  }

  getForm() {
    this.form = this.formBuilder.group({
      due_date: ['', [Validators.required(), Validators.DateIsAfterToday()]],
      comment: ['', Validators.maxLength(4096)]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  private getUnassignedReviewerApproverList(): void {
    let unassigned = {
      'Invite Approvers': 'getUnassignedApproverList',
      'Manage Reviewer': 'getUnassignedReviewerList'
    };

    this.incidentService[unassigned[this.type]](this.OrganisationID, this.incidentGuid).subscribe(
      (data: any) => {
        this.DataList = data;
        this.UsersList = this.DataList.filter(user => {
          if (this.type !== 'Invite Approvers') {
            return user['roles'].includes(
              'incident_reviewer' || (this.incCreatedBy == this.loginUserEmail && this.loginUserEmail == user['email'])
            );
          } else {
            return user['roles'].includes(
              'incident_coordinator' ||
                (this.incCreatedBy == this.loginUserEmail && this.loginUserEmail == user['email'])
            );
          }
        });

        this.UsersList.forEach(user => {
          user['FullName'] = user['first_name'] + ' ' + user['last_name'] + '(' + user['email'] + ')';
        });
        this.UsersList.sort((a, b) => a['FullName'].localeCompare(b['FullName']));
      },
      (error: any) => {
        this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
        });
      }
    );
  }

  openRevokeReviewerForm(incidentGuid: string, guid: string, user: string, type: string) {
    this.revokeApproverReviewer(incidentGuid, guid, user, type);
  }
  revokeApproverReviewer(incidentGuid: string, guid: string, user: string, type: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: this.type == 'Invite Approvers' ? 'Revoke An Approver' : 'Revoke Reviewer',
        message: `Are you sure you want to remove ${user} ?`,
        modalType: this.type == 'Invite Approvers' ? 'Revoke An Approver' : 'Revoke Reviewer',
        guid: guid,
        incidentGuid: incidentGuid,
        type: type
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          this.getReviewerApproverList();
          this.getUnassignedReviewerApproverList();
          this.actions = result.result;
          if (this.type == 'Invite Approvers') {
            this.translate
              .get('Revoke_Incident_Approver.revoke_approver_toaster_message.revoke_approver_submitted')
              .subscribe(res => {
                this.toaster.showSuccess(res);
              });
          } else {
            this.translate
              .get('Revoke_Incident_Reviewer.revoke_reviewer_toaster_message.revoke_reviewer_submitted')
              .subscribe(res => {
                this.toaster.showSuccess(res);
              });
          }
        } else {
          console.log('Cancelled.');
        }
      });
  }

  checkEditorSelection() {
    this.selectedItems.length > 0 ? (this.selectedUser = true) : (this.selectedUser = false);
  }
  submitInviteReviewer(analystData: any): void {
    this.incidentService
      .submitIncidentReviewerData(this.OrganisationID, this.incidentGuid, analystData)
      .pipe(
        finalize(() => {
          this.form.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.error = {
            due_date: ''
          };
          this.submitted = false;
          this.backend_error = '';
          this.activeModal.close({ result: data, isUpdate: true, show_msg: true });
          let successText =
            this.type !== 'Invite Approvers'
              ? 'Incident_Manage_Reviewer.invite_reviewer_toaster_message.invite_reviewer'
              : 'Incident_Manage_Approver.invite_approver_toaster_message.invite_approver';
          this.translate.get(successText).subscribe(res => {
            this.toaster.showSuccess(res);
          });
        },
        (error: any) => {
          this.submitted = false;
          this.isLoading = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
  }

  private getReviewerApproverList(): void {
    let unassigned = {
      'Invite Approvers': 'getIncidentApproverList',
      'Manage Reviewer': 'getIncidentReviewerList'
    };
    this.incidentService[unassigned[this.type]](this.OrganisationID, this.incidentGuid).subscribe(
      (data: any) => {
        this.reviewerApproverList = data;
        if (this.reviewerApproverList) {
          this.reviewerApproverList.forEach(item => {
            item['initials'] = item['user']['name']
              .match(/\b(\w)/g)
              .join('')
              .toUpperCase();
          });
        }
      },
      (error: any) => {
        this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
        });
      }
    );
  }

  changeName(statusData: any) {
    this.incidentService.setStatusforManageApprover(statusData);
  }
  changeNameReviewer(reviewerstatusData: any) {
    this.incidentService.setStatusforManageReviewer(reviewerstatusData);
  }
  onDateSelection(date: any) {
    if (date) {
      let myDate = new Date(date.year, date.month - 1, date.day + 1);
      this.store_due_date = myDate.toISOString().split('T')[0];
    } else {
      this.store_due_date = this.store_due_date;
    }
  }
  save() {
    let approver: any = [];
    this.submitted = true;
    if (this.selectedItems.length > 0 && this.form.valid) {
      approver = this.selectedItems.map((item: any) => {
        return item.guid;
      });
      this.checkSelection();
      this.isLoading = true;
      const approverData = this.form.value;
      approverData['organization_users'] = approver;
      approverData['role'] = this.type == 'Invite Approvers' ? 'approver' : 'reviewer';
      approverData['due_date'] = this.store_due_date;
      this.submitInviteReviewer(approverData);
    } else {
      this.checkSelection();
      // this.submitInviteReviewer(analystData);

      return;
    }
  }

  openChangeDueFrom(reviewerform: any): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: this.type == 'Invite Approvers' ? 'Edit Approver Due Date' : 'Edit Reviewer Due Date',
        modalType:
          this.type == 'Invite Approvers' ? 'Change Due Date for invite editor' : 'Change Due Date for invite editor',
        ...reviewerform,
        incidentGuid: this.incidentGuid,
        guid: reviewerform.guid,
        user: reviewerform.user.name,
        module: 'Incident',
        role: this.type == 'Invite Approvers' ? 'approver' : 'reviewer'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getReviewerApproverList();
          // this.getUsersList();
          // this.getApproversList()

          // this.documentsService.status12.subscribe(data => {
          //   this.status12 = data;
          // });
          // this.changeName(this.status12);
          this.translate.get('Change_due_date_toaster_message.Due_date_changed').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  openRestoreApproverForm(reviewerform: any, docTitle: any): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: this.type == 'Manage Reviewer' ? 'Reassign an Reviewer' : 'Reassign an Approver',
        message:
          this.type == 'Manage Reviewer'
            ? 'Are you sure you want to invite ' + reviewerform.user.name + ' for reviewing the Incident again?'
            : 'Are you sure you want to invite ' + reviewerform.user.name + ' for Approving the Incident again?',
        modalType: this.type == 'Manage Reviewer' ? 'Reassign Approver' : 'Reassign Approver',
        ...reviewerform,
        incidentGuid: this.incidentGuid,
        guid: reviewerform.user.guid,
        docTitle: docTitle,
        due_date: reviewerform.due_date,
        type: this.type,
        module: 'Incident'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          this.getReviewerApproverList();
          this.actions = result.result;
          if (this.type == 'Manage Approvers') {
            this.translate
              .get('Reassign_Approver.reassign_approver_toaster_message.reassign_approver_submitted')
              .subscribe(res => {
                this.toaster.showSuccess(res);
              });
          } else {
            this.translate
              .get('Reassign_Approver.reassign_reviewer_toaster_message.reassign_reviewer_submitted')
              .subscribe(res => {
                this.toaster.showSuccess(res);
              });
          }
        } else {
          console.log('Cancelled.');
        }
      });
  }
}
