<app-spinner *ngIf="loading"></app-spinner>

<ng-container>
  <div id="mySidenav" class="sidenav">
    <div class="sidenav-header d-flex justify-content-between">
      <span class="title"> Add Readers</span>
      <button (click)="openPeopleForm()" class="btn-add-new cb-btn-primary">Add New Reader</button>
      <i class="fa fa-times text-black-1 closebtn" (click)="closeNav()" ngbAutoFocus translate></i>
    </div>
    <div class="row mt-2" *ngIf="backend_error_addReader">
      <div class="col-12 col-sm-12 col-lg-12 ml-5">
        <div class="text-danger" translate>* {{ backend_error_addReader }}</div>
      </div>
    </div>
    <div *ngIf="peopleList?.length > 0" class="w-100p pt-3 pb-3">
      <div class="search-input mx-2 pb-2">
        <input
          id="search"
          [(ngModel)]="searchText"
          class="form-control cb-input m-0 cb-input-focus"
          [placeholder]="'Search' | translate"
          name="search"
          type="text"
        />
        <i class="fa fa-search search-icon"></i>
      </div>
      <div
        class=" search-results"
        infiniteScroll
        [scrollWindow]="false"
        (scrolled)="onScroll()"
        [infiniteScrollDistance]="modalScrollDistance"
        [infiniteScrollThrottle]="modalScrollThrottle"
        (scrolledUp)="onScrollUp()"
      >
        <table class="w-100p">
          <thead>
            <tr>
              <th class="p-2"></th>
              <th class="p-2">NAME</th>
              <th class="p-2">Email</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let people of peopleList | filter: searchText; let i = index" class="custom-tr">
              <td class="username pl-2">
                <div class="checkbox-container">
                  <input
                    type="checkbox"
                    id="checkbox"
                    class="form-check-input custom-checkbox"
                    (change)="onCheckboxChange($event, people.guid, people['email'])"
                    [checked]="isChecked(people)"
                  />
                  <label for="checkbox" class="custom-checkbox"></label>
                </div>
              </td>
              <td class="d-flex username mt-2">
                <span
                  class="initials"
                  [style.backgroundColor]="getColor(people.first_name !== null ? people['first_name'] : '-')"
                >
                  {{
                    getInitials(
                      people.first_name !== null ? people['first_name'] : '-',
                      people.last_name !== null ? people['last_name'] : '-'
                    )
                  }} </span
                ><span class="mt-2 username">{{ people['first_name'] }} {{ people['last_name'] }}</span>
              </td>
              <td class="username">{{ people['email'] !== null ? people['email'] : '-' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="sidenav-footer d-flex">
      <button
        type="submit"
        class="btn-save cb-btn-primary"
        *ngIf="!isLoading"
        [ngClass]="{ 'btn-disabled cb-btn-disabled': !isSaveDisabled() }"
        [disabled]="!isSaveDisabled()"
        (click)="save()"
      >
        Save
      </button>

      <button type="submit" class="btn-save cb-btn-disabled" disabled *ngIf="isLoading">
        <i class="fa fa-spinner fa-spin"></i>
      </button>
      <button type="button" class="px-2 cb-btn-secondary" (click)="closeNav()">Cancel</button>
    </div>
  </div>
</ng-container>
