import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UserService } from '@app/core/user.service';
import { OrganisationService } from '@app/core/organisation.service';
import { NgbActiveModal, NgbDate, NgbDateStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { IncidentService } from '@app/Organisation-admin/incident/incident.service';

@Component({
  selector: 'app-change-due-date-form',
  templateUrl: './change-due-date-form.component.html',
  styleUrls: ['./change-due-date-form.component.scss']
})
export class ChangeDueDateFormComponent implements OnInit {
  minDate: NgbDateStruct;
  DueDateEditorForm: FormGroup;
  loginUser: any;
  loginUsername: string;
  loginUserEmail: string;
  guidSubscription: any;
  OrganisationID: any;
  submitted = false;
  isLoading = false;
  success: boolean;
  dropdownList: any = [];
  selectedItems: any = [];
  dropdownSettings = {};
  error: any;
  EditorSelected = true;
  EditorList: any;
  UsersList: object[];
  DataList: object[];

  checked = false;
  oldeditors: any = [];
  @Input() title_tag: string;
  @Input() DocCreatedBy: string;
  @Input() DocumentGuid: string;
  @Input() guid: string;
  @Input() user: string;
  @Input() start_message: string;
  @Input() due_date: any;
  @Input() module: string = 'Document';
  @Input() incidentGuid: string;
  @Input() formGuid: string;
  @Input() role = 'editor';
  backend_error: string;
  statusData: any;

  constructor(
    private backendErrorService: BackendErrorService,
    private userservice: UserService,
    private orgService: OrganisationService,
    private documentsService: DocumentsService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private translate: TranslateService,
    private incidentService: IncidentService
  ) {}

  ngOnInit() {
    this.loginUser = this.userservice.getuserdetails();
    this.loginUsername = this.loginUser.user['username'];
    this.loginUserEmail = this.loginUser.user['email'];
    const currentDate = new Date();
    const tomorrowDate = new Date();
    tomorrowDate.setDate(currentDate.getDate() + 1);
    console.log(this.module);
    // Set minDate to tomorrow's date
    this.minDate = {
      year: tomorrowDate.getFullYear(),
      month: tomorrowDate.getMonth() + 1,
      day: tomorrowDate.getDate()
    };

    if (this.due_date != null) {
      const due_date_arr = this.due_date.split('-');
      this.due_date = new NgbDate(parseInt(due_date_arr[0]), parseInt(due_date_arr[1]), parseInt(due_date_arr[2]));
    }

    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getInviteEditorChangeReqForm();
    });
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  getInviteEditorChangeReqForm() {
    this.DueDateEditorForm = this.formBuilder.group({
      due_date: [this.due_date, Validators.DateIsAfterToday()],
      comment: ['', Validators.maxLength(4096)]
    });
  }

  get f() {
    return this.DueDateEditorForm.controls;
  }

  save() {
    this.submitted = true;
    // this.DueDateEditorForm['due_date'] = this.due_date;
    if (this.DueDateEditorForm.valid) {
      this.isLoading = true;
      const dueDateData = this.DueDateEditorForm.value;

      dueDateData['role'] = this.role;
      const myDate = new Date(
        dueDateData['due_date'].year,
        dueDateData['due_date'].month - 1,
        dueDateData['due_date'].day + 1
      );
      dueDateData['due_date'] = myDate.toISOString().split('T')[0];
      this.submitDueDateInviteEditor(dueDateData);
    } else {
      return;
    }
  }

  changeName(statusData: any) {
    this.documentsService.setStatus12(statusData);
  }

  submitDueDateInviteEditor(dueDateData: any): void {
    // this.incidentService.submit

    (this.module == 'Incident'
      ? this.incidentService.submitChangeDuedateFromData(this.OrganisationID, this.incidentGuid, this.guid, dueDateData)
      : this.module === 'Document'
      ? this.documentsService.submitChangeDuedateFromData(
          this.OrganisationID,
          this.DocumentGuid,
          this.guid,
          dueDateData
        )
      : this.documentsService.submitFormChangeDuedate(this.OrganisationID, this.formGuid, this.guid, dueDateData)
    )
      .pipe(
        finalize(() => {
          this.DueDateEditorForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.error = {
            due_date: ''
          };
          this.statusData = data;
          this.changeName(this.statusData);
          this.submitted = false;
          this.backend_error = '';
          this.activeModal.close(true);
        },
        (error: any) => {
          this.isLoading = false;
          this.submitted = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
  }
}
