<div class="cb-container cb-page-bg-color">
  <div class="container-fluid">
    <div class="row ">
      <div class="col document-menu">
        <div class="d-flex d-lg-none justify-content-between mb-4">
          <a href="" class="btn-back ml-3">&lt; Back</a>
          <i class="fas fa-times close-document-menu"></i>
        </div>
        <div class="document-menu-wrapper">
          <button
            class="btn-add ml-4"
            style="height: 40px; 
            border: solid 1px #4db7c6;"
            routerLink="create-incident"
            routerLinkActive="active"
            *ngIf="can_add_new"
          >
            <i class="fa fa-plus"></i>
            <span class="text-cyan" style="font-size: 14px; font-weight: normal;">Create Incident</span>
          </button>
          <h2 class="mt-4 font-weight-bold">Quick Actions</h2>

          <ul>
            <li>
              <a routerLink="/organisation/incident/incident-management" routerLinkActive="active">
                <img src="../../../../assets/images/documents/incident-management2.png" class="cb-icon" />
                Incidents</a
              >
            </li>
            <li>
              <a routerLink="/organisation/incident/incident-author" routerLinkActive="active">
                <img src="../../../assets/images/documents/author.png" class="cb-icon" /> Author</a
              >
            </li>
            <li>
              <a routerLink="/organisation/incident/reviewer" routerLinkActive="active">
                <img src="../../../assets/images/documents/edit.png" class="cb-icon" /> Reviewer</a
              >
            </li>
            <li>
              <a routerLink="/organisation/incident/incident-approver" routerLinkActive="active">
                <img src="../../../assets/images/documents/approve.png" class="cb-icon" /> Approver</a
              >
            </li>
          </ul>
        </div>
        <div class="document-menu-wrapper ">
          <h2 class="mt-4 font-weight-bold">Workspace</h2>
          <div *ngIf="filteredIncidentData?.length > 0">
            <ul>
              <li (click)="getClickedEventsOnWorkspaceElement(filteredIncidentData)">
                <a
                  routerLinkActive="active"
                  routerLink="/organisation/incident/workspace/{{ filteredIncidentData[0]['guid'] }}"
                  [queryParams]="{ folder: filteredIncidentData[0]['title'] }"
                >
                  <img src="../../../assets/images/documents/folder.png" class="cb-icon" />
                  {{ filteredIncidentData[0]['title'] }}
                </a>
              </li>
              &nbsp;
              <h2 *ngIf="scrollLoader" class="font-weight-bold text-black-3 text-center">
                <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': scrollLoader }" style="font-size:large;"></i> &nbsp;
                Loading...
              </h2>
            </ul>
          </div>
        </div>
      </div>
      <div class="col document-view">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
