<!--<div class="login-container bg-light">-->
<!--<div class="login-box">-->
<!--<mat-card style="width: 25%;">-->
<!--<div class="mx-auto">-->
<!--<div>-->
<!--{{ message }} <br />-->
<!--<br />-->
<!--<button mat-button (click)="done()" color="primary">OK</button>-->
<!--</div>-->
<!--</div>-->
<!--</mat-card>-->
<!--</div>-->
<!--</div>-->

<div class="container-fluid">
  <div class="row-fluid">
    <div class="centering text-center">
      <div style="height: 100%">
        <div class="jumbotron" style="text-align: center!important; margin: auto 0">
          <h1 class="display-3" *ngIf="type == 'success'">Thank You!</h1>
          <h1 class="display-3" *ngIf="type == 'error'">Sorry!</h1>
          <p class="lead">{{ message }}</p>
          <hr />
          <p>Having trouble? <a href="">Contact us</a></p>
          <p class="lead">
            <a class="btn btn-primary btn-sm" (click)="done()" role="button" style="color: white;"
              >Continue to homepage</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
