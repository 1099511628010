<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <!-- <div class="row">
    <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
      <h2 class="text-cyan m-0" translate>Compliance_Management.Title</h2> -->
  <!-- <div class="search-people" style="display: flex;">
          <div class="p-2">
            <div class="input-group">
              <input
                id="search"
                class="form-control cb-input"
                name="search"
                type="text"
              />
              <i class="fa fa-search search-icon"></i>
            </div>
          </div>
          <div class="p-2">
            <mat-slide-toggle class="mt-1 ml-1" (change)="openConfirm('switch', $event)" [checked]="isProdAgent">Show all documents in the category</mat-slide-toggle>
          </div>
        </div> -->
  <!-- </div>
  </div> -->
  <app-folder-document-view [title_tag]="title"></app-folder-document-view>

  <!-- <div class="row scroll-view" *ngIf="ComplianceList?.length > 0; else no_content">
    <div class="col p-0">
      <div class="table_wrapper">
        <table class="w-100p bg-white dm-table table table-responsive">
          <thead>
            <tr style="height: 65px;">
              <th scope="col" class="font-weight-bold text-black-3 text-center" translate>
                Compliance_Management.Table_title
              </th>
              <th scope="col" class="font-weight-bold text-black-3 text-center" translate>
                Compliance_Management.Table_status
              </th>
              <th scope="col" class="font-weight-bold text-black-3 text-center" translate>
                Compliance_Management.Table_due_date
              </th>
              <th scope="col" class="font-weight-bold text-black-3 text-center" translate>
                Compliance_Management.Table_requested
              </th>
              <th scope="col" class="font-weight-bold text-black-3 text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center" *ngFor="let compliance of ComplianceList">
              <td>
                <h4 class="dm-text-1 text-black-3 mt-3">{{ compliance['title'] }}</h4>
              </td>
              <td>
                <h4 class="text-black-3 dm-text-2 mt-3">{{ compliance['status']['value'] }}</h4>
              </td>
              <td>
                <h4 class="text-black-3 dm-text-2 mt-3">{{ compliance['due_date'] ? compliance['due_date'] : '-' }}</h4>
              </td>
              <td>
                <h4 class="text-black-3 dm-text-2 mt-3">
                  <img
                    src="../../../../assets/images/documents/author.png"
                    class="dm-user-icon"
                    *ngIf="compliance['requested_by']"
                  />
                  {{ compliance['requested_by'] ? compliance['requested_by']['name'] : '-' }}
                </h4>
              </td>
              <td>
                <button class="mt-2 dropdown-btn" mat-stroked-button [matMenuTriggerFor]="menu">
                  Actions <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #menu="matMenu" class="fixed-menu-panel">
                  <ng-container *ngFor="let actions of compliance['allowed_actions_list']">
                    <button
                      mat-menu-item
                      (click)="
                        getClickEvents(
                          actions,
                          compliance['guid'],
                          compliance['created_by']['email'],
                          compliance['title'],
                          compliance['document_store_identifier']
                        )
                      "
                    >
                      {{ actions }}
                    </button>
                  </ng-container>
                </mat-menu>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div> -->
</ng-container>
<ng-template #no_content>
  <div class="row scroll-view">
    <div class="col">
      <img class="empty-doc-list-image" src="../../../assets/images/documents/empty.svg" />
      <div class="text-empty-doc-list text-black-1" translate>Compliance_Management.No_documents</div>
      <div class="sub-text-empty-doc-list cursor-pointer text-cyan-force" translate>
        Compliance_Management.Add_new
        <a
          class="sub-text-empty-doc-list text-center text-cyan-force"
          (click)="goToCreateDoc()"
          style="font-size: 18px;"
          translate
          >Compliance_Management.Document</a
        >
      </div>
    </div>
  </div>
</ng-template>
