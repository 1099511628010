<div>
  <div class="modal-header cb-modal-header">
    <div>
      <!-- <h4 class="modal-title font-weight-bold text-black-1" translate>Create_Document_Succes.Title</h4> -->
      <h4 class="modal-title font-weight-bold text-black-1" translate>{{ title_tag }}</h4>
      <i
        class="fa fa-times text-black-1"
        style="width: 12px;
            height: 12px;
            right: 1em;
            cursor: pointer;
            position: absolute;
            top: 1.3em;"
        (click)="activeModal.close(false)"
        ngbAutoFocus
        translate
      ></i>
    </div>
  </div>
  <div class="modal-body">
    <div class="col-12 mt-2 ">
      <label class="text-black-1 p-2" style="width: 100%;" translate
        >{{ DocumentTitle }}&nbsp;{{
          title_tag == 'Document created' ? this.docSuccessCreated : this.tempSuccessCreated
        }}</label
      >
    </div>
  </div>
  <div class="modal-footer border-0 cb-modal-footer">
    <button
      type="button"
      class="btn btn-save ml-4  width"
      (click)="goToAttentionRequiredpage(template_guid)"
      ngbAutoFocus
      translate
    >
      {{ title_tag == 'Document created' ? this.viewDocuments : this.viewTemplates }}
    </button>

    <button type="button" class="btn btn-save ml-4 width" (click)="onNavigate()" ngbAutoFocus translate>
      {{ title_tag == 'Document created' ? this.editDoc : this.editTemp }}
    </button>
  </div>
</div>
