import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '@app/core/modal/modal.service';
import { OrganisationService } from '@app/core/organisation.service';
import { UserService } from '@app/core/user.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import stringInfo from '../../../../translations/en-US.json';

@Component({
  selector: 'app-create-document-success',
  templateUrl: './create-document-success.component.html',
  styleUrls: ['./create-document-success.component.scss']
})
export class CreateDocumentSuccessComponent implements OnInit {
  OrganisationID: any;
  DocURL: any;
  error: any;

  @Input() title_tag: string;
  @Input() DocumentGuid: string;
  @Input() DocumentTitle: string;
  @Input() docTitle: string;
  @Input() template_guid: string;

  loginUser: any;
  loginUsername: any;
  loginUserEmail: any;
  AttentionList: any;
  guidSubscription: any;
  docSuccessCreated = stringInfo.Title_Tag.DOC_Created;
  tempSuccessCreated = stringInfo.Title_Tag.Temp_Created;
  viewDocuments = stringInfo.Title_Tag.View_Doc;
  viewTemplates = stringInfo.Title_Tag.View_Temp;
  editDoc = stringInfo.Title_Tag.Edit_Doc;
  editTemp = stringInfo.Title_Tag.Edit_Temp;

  constructor(
    private orgService: OrganisationService,
    private documentsService: DocumentsService,
    private modalService: ModalService,
    private translate: TranslateService,
    private userservice: UserService,
    private router: Router,
    private route: ActivatedRoute,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe((guid: any) => {
      this.OrganisationID = guid;
    });
  }

  goToAttentionRequiredpage(template_guid: any) {
    console.log('template_guid', template_guid);
    if (this.title_tag == 'Document created') {
      this.router.navigate(['/organisation/documents/attention-required']);
    } else {
      // this.router.navigate(['/organisation/documents/templates/',{ queryParams: { guid: template_guid } }]);
      this.router.navigate(['/organisation/documents/attention-required']);
    }
    this.activeModal.close(false);
  }

  onNavigate() {
    this.documentsService.getOrganizationDocumentEditURl(this.OrganisationID, this.DocumentGuid, '').subscribe(
      (data: any) => {
        this.DocURL = data;
        window.open(this.DocURL.document_store_identifier, '_blank');
        this.activeModal.close(false);
        this.goToAttentionRequiredpage(this.template_guid);
      },
      (error: { error: { message: any } }) => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }
  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }
}
