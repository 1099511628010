<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div class="row">
    <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
      <h2 class="text-cyan m-0" translate>Operations</h2>
      <div *ngIf="operationShowData">
        <button class="btn-cancel ml-4 w-100" (click)="onReset()" translate>
          organisation_operations.Cancel
        </button>
        <button
          *ngIf="!organisationForm.invalid; else templateName"
          class="btn-save ml-4 w-100"
          (click)="save()"
          translate
        >
          organisation_operations.Save
        </button>
        <ng-template #templateName>
          <button class="btn-save btn-disabled ml-4 w-100" disabled>
            Save
          </button>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="row scroll-view" *ngIf="operationShowData">
    <div class="col">
      <div class="my-3 mx-3">
        <form name="form" [formGroup]="organisationForm">
          <div class="row mt-2">
            <div class="col max-w-300 pr-3">
              <h4 class="text-black-1 font-weight-bold" translate>organisation_operations.Organization Domain</h4>
              <p class="text-black-1" translate>organisation_operations.Enter a url for your organization</p>
            </div>
            <div class="col d-flex flex-column max-w-380">
              <div class="input-group pr-2 mb-2">
                <input
                  id="websiteUrl"
                  class="form-control cb-input"
                  placeholder="Organization Domain"
                  type="url"
                  formControlName="orgDomain"
                  [ngClass]="{ 'is-invalid': f.orgDomain.errors }"
                />
                <div *ngIf="f.orgDomain.errors" class="invalid-feedback">
                  <div class="text-danger" *ngIf="f.orgDomain.errors.url">*Please enter a valid URL.</div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col max-w-300 pr-3">
              <h4 class="text-black-1 font-weight-bold" translate>organisation_operations.Organization Logo</h4>
              <p class="text-black-1" translate>
                organisation_operations.Enter a public url of the organization's logo
              </p>
            </div>
            <div class="col d-flex flex-column max-w-380">
              <div class="input-group pr-2 mb-2">
                <input
                  id="organizationLogo"
                  class="form-control cb-input"
                  placeholder="Organization logo URL"
                  name="organizationLogo"
                  type="url"
                  formControlName="organizationLogo"
                  [ngClass]="{ 'is-invalid': f.organizationLogo.errors }"
                />
                <div *ngIf="f.organizationLogo.errors" class="invalid-feedback">
                  <div class="text-danger" *ngIf="f.organizationLogo.errors.url">*Please enter a valid URL.</div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="row mt-2">
          <div class="col max-w-300 pr-3">
            <h4 class="text-black-1 font-weight-bold" translate>organisation_operations.Timezone</h4>
            <p class="text-black-1" translate>organisation_operations.Select the timezone for the organisation</p>
          </div>
          <div class="col d-flex flex-column max-w-380">
            <div class="input-group pr-2 mb-2">
              <select
                class="form-control cb-input"
                (change)="timezoneChange($event.target.value)"
                [value]="selectedTimezone"
              >
                <option
                  *ngFor="let timezone of operationShowData['timezone_choices']"
                  [selected]="selectedTimezone == timezone['value']"
                  value="{{ timezone['value'] }}"
                >
                  {{ timezone['key'] }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col max-w-300 pr-3">
            <h4 class="text-black-1 font-weight-bold" translate>organisation_operations.Hours of Operation</h4>
            <p class="text-black-1" translate>
              organisation_operations.This is the start and end time for review events
            </p>
          </div>
          <div class="col d-flex">
            <div class="input-group pr-2 mb-2 d-block w-140">
              <p class="mb-2" translate>organisation_operations.Start Time</p>
              <div class="cb-input cb-time">
                <i class="fa fa-clock"></i>
                <input
                  placeholder="Time"
                  aria-label="Time"
                  class="time_input"
                  [ngxTimepicker]="darkPicker"
                  [value]="operationShowData['office_start_hours']"
                  readonly
                />
                <ngx-material-timepicker
                  class="timepicker"
                  #darkPicker
                  [ngxMaterialTimepickerTheme]="darkTheme"
                  (timeSet)="startTimeSet($event)"
                ></ngx-material-timepicker>
              </div>
            </div>
            <div class="input-group pr-2 mb-2 d-block w-140">
              <p class="mb-2" translate>organisation_operations.End Time</p>
              <div class="cb-input cb-time">
                <i class="fa fa-clock"></i>
                <input
                  placeholder="Time"
                  aria-label="Time"
                  class="time_input"
                  [ngxTimepicker]="darkPicker2"
                  [value]="operationShowData['office_end_hours']"
                  readonly
                />
                <ngx-material-timepicker
                  class="timepicker"
                  #darkPicker2
                  [ngxMaterialTimepickerTheme]="darkTheme"
                  (timeSet)="endTimeSet($event)"
                ></ngx-material-timepicker>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="row mt-4">
        <div class="col max-w-300 pr-3">
          <h4 class="text-black-1 font-weight-bold" translate>organisation_operations.CB Agent Environment</h4>
          <p class="text-black-1" translate>organisation_operations.Select the default CB Agent environment</p>
        </div>
        <div class="col d-flex flex-column max-w-380">
          <div class="input-group pr-2 mb-2">
            <mat-radio-group
              [(ngModel)]="selectedCbAgentEnvironment"
              #radioGroup="matRadioGroup"
              *ngFor="let cbAgentEnvironmentChoices of operationShowData['cbagent_environment_choices']"
            >
              <mat-radio-button
                class="tp-radio-button "
                [value]="cbAgentEnvironmentChoices['value']"
                (change)="cbAgentEnvironmentChange($event.value)"
              >
                <img
                  class="logo"
                  [src]="
                    cbAgentEnvironmentChoices['value'] !== 'Production'
                      ? '../../../assets/images/logo/logo-grey.png'
                      : '../../../assets/images/logo/logo.png'
                  "
                  height="30px"
                />
                {{ cbAgentEnvironmentChoices['value'] }}
              </mat-radio-button>
            </mat-radio-group>
            <span class="text-danger" *ngIf="selectedCbAgentEnvironment === 'Staging'" translate>
              <mat-icon>warning</mat-icon>
              organisation_operations.Staging environment is recommended to validate newly implemented changes.
            </span>
          </div>
        </div>
      </div> -->

        <div class="row mt-4">
          <div class="col max-w-300 pr-3">
            <h4 class="text-black-1 font-weight-bold" translate>organisation_operations.Bitbucket Repo URL</h4>
            <p class="text-black-1" translate>organisation_operations.CB Agent Bitbucket repo URL</p>
          </div>
          <div class="col d-flex flex-column max-w-380">
            <div class="input-group pr-2 mb-2">
              <input
                id="bitbucketRepoUrl"
                class="form-control cb-input"
                [value]="bitbucketRepoUrl"
                placeholder="Bitbucket Repo URL"
                name="name"
                type="text"
                disabled
              />
            </div>
          </div>
        </div>

        <!--          TODO: NEED TO BE IMPLEMENTED : BACKEND IS NOT READY FOR THE SAME-->
        <!--      <div class="row mt-4">-->
        <!--        <div class="col max-w-300 pr-3">-->
        <!--          <h4 class="text-black-1 font-weight-bold">Holidays</h4>-->
        <!--          <p class="text-black-1">Select the holidays observed by the organisation</p>-->
        <!--        </div>-->
        <!--        <div class="col flex-column max-w-380">-->
        <!--          <div class="holiday-selector">-->
        <!--            <div class="input-group pt-2 mb-2">-->
        <!--              <label class="form-check-label text-black-1 checkbox-2" for="inlinecheck1">-->
        <!--                <input-->
        <!--                  class="form-check-input"-->
        <!--                  type="checkbox"-->
        <!--                  name="inlineRadioOptions"-->
        <!--                  id="inlinecheck1"-->
        <!--                  value="option2"-->
        <!--                />-->
        <!--                <span class="checkmark"></span>-->
        <!--                Jan 01, Tuesday - New Year’s Day-->
        <!--              </label>-->
        <!--            </div>-->
        <!--            <div class="input-group pt-2 mb-2">-->
        <!--              <label class="form-check-label text-black-1 checkbox-2" for="inlinecheck1">-->
        <!--                <input-->
        <!--                  class="form-check-input"-->
        <!--                  type="checkbox"-->
        <!--                  name="inlineRadioOptions"-->
        <!--                  id="inlinecheck1"-->
        <!--                  value="option2"-->
        <!--                />-->
        <!--                <span class="checkmark"></span>-->
        <!--                Jan 01, Tuesday - New Year’s Day-->
        <!--              </label>-->
        <!--            </div>-->
        <!--            <div class="input-group pt-2 mb-2">-->
        <!--              <label class="form-check-label text-black-1 checkbox-2" for="inlinecheck1">-->
        <!--                <input-->
        <!--                  class="form-check-input"-->
        <!--                  type="checkbox"-->
        <!--                  name="inlineRadioOptions"-->
        <!--                  id="inlinecheck1"-->
        <!--                  value="option2"-->
        <!--                />-->
        <!--                <span class="checkmark"></span>-->
        <!--                Jan 01, Tuesday - New Year’s Day-->
        <!--              </label>-->
        <!--            </div>-->
        <!--            <div class="input-group pt-2 mb-2">-->
        <!--              <label class="form-check-label text-black-1 checkbox-2" for="inlinecheck1">-->
        <!--                <input-->
        <!--                  class="form-check-input"-->
        <!--                  type="checkbox"-->
        <!--                  name="inlineRadioOptions"-->
        <!--                  id="inlinecheck1"-->
        <!--                  value="option2"-->
        <!--                />-->
        <!--                <span class="checkmark"></span>-->
        <!--                Jan 01, Tuesday - New Year’s Day-->
        <!--              </label>-->
        <!--            </div>-->
        <!--            <div class="input-group pt-2 mb-2">-->
        <!--              <label class="form-check-label text-black-1 checkbox-2" for="inlinecheck1">-->
        <!--                <input-->
        <!--                  class="form-check-input"-->
        <!--                  type="checkbox"-->
        <!--                  name="inlineRadioOptions"-->
        <!--                  id="inlinecheck1"-->
        <!--                  value="option2"-->
        <!--                />-->
        <!--                <span class="checkmark"></span>-->
        <!--                Jan 01, Tuesday - New Year’s Day-->
        <!--              </label>-->
        <!--            </div>-->
        <!--            <div class="input-group pt-2 mb-2">-->
        <!--              <label class="form-check-label text-black-1 checkbox-2" for="inlinecheck1">-->
        <!--                <input-->
        <!--                  class="form-check-input"-->
        <!--                  type="checkbox"-->
        <!--                  name="inlineRadioOptions"-->
        <!--                  id="inlinecheck1"-->
        <!--                  value="option2"-->
        <!--                />-->
        <!--                <span class="checkmark"></span>-->
        <!--                Jan 01, Tuesday - New Year’s Day-->
        <!--              </label>-->
        <!--            </div>-->
        <!--            <div class="input-group pt-2 mb-2">-->
        <!--              <label class="form-check-label text-black-1 checkbox-2" for="inlinecheck1">-->
        <!--                <input-->
        <!--                  class="form-check-input"-->
        <!--                  type="checkbox"-->
        <!--                  name="inlineRadioOptions"-->
        <!--                  id="inlinecheck1"-->
        <!--                  value="option2"-->
        <!--                />-->
        <!--                <span class="checkmark"></span>-->
        <!--                Jan 01, Tuesday - New Year’s Day-->
        <!--              </label>-->
        <!--            </div>-->
        <!--            <div class="input-group pt-2 mb-2">-->
        <!--              <label class="form-check-label text-black-1 checkbox-2" for="inlinecheck1">-->
        <!--                <input-->
        <!--                  class="form-check-input"-->
        <!--                  type="checkbox"-->
        <!--                  name="inlineRadioOptions"-->
        <!--                  id="inlinecheck1"-->
        <!--                  value="option2"-->
        <!--                />-->
        <!--                <span class="checkmark"></span>-->
        <!--                Jan 01, Tuesday - New Year’s Day-->
        <!--              </label>-->
        <!--            </div>-->
        <!--            <div class="input-group pt-2 mb-2">-->
        <!--              <label class="form-check-label text-black-1 checkbox-2" for="inlinecheck1">-->
        <!--                <input-->
        <!--                  class="form-check-input"-->
        <!--                  type="checkbox"-->
        <!--                  name="inlineRadioOptions"-->
        <!--                  id="inlinecheck1"-->
        <!--                  value="option2"-->
        <!--                />-->
        <!--                <span class="checkmark"></span>-->
        <!--                Jan 01, Tuesday - New Year’s Day-->
        <!--              </label>-->
        <!--            </div>-->
        <!--            <div class="input-group pt-2 mb-2">-->
        <!--              <label class="form-check-label text-black-1 checkbox-2" for="inlinecheck1">-->
        <!--                <input-->
        <!--                  class="form-check-input"-->
        <!--                  type="checkbox"-->
        <!--                  name="inlineRadioOptions"-->
        <!--                  id="inlinecheck1"-->
        <!--                  value="option2"-->
        <!--                />-->
        <!--                <span class="checkmark"></span>-->
        <!--                Jan 01, Tuesday - New Year’s Day-->
        <!--              </label>-->
        <!--            </div>-->
        <!--            <div class="input-group pt-2 mb-2">-->
        <!--              <label class="form-check-label text-black-1 checkbox-2" for="inlinecheck1">-->
        <!--                <input-->
        <!--                  class="form-check-input"-->
        <!--                  type="checkbox"-->
        <!--                  name="inlineRadioOptions"-->
        <!--                  id="inlinecheck1"-->
        <!--                  value="option2"-->
        <!--                />-->
        <!--                <span class="checkmark"></span>-->
        <!--                Jan 01, Tuesday - New Year’s Day-->
        <!--              </label>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div class="col-lg-12"><p class="text-cyan mb-0 add-url mt-3">+ Add Holiday</p></div>-->
        <!--        </div>-->
        <!--      </div>-->

        <div class="row mt-4 text-black-1 font-weight-bold">
          <div class="col max-w-300 pr-3">
            <h4 class="text-black-1 font-weight-bold" translate>organisation_operations.password_based_login</h4>
          </div>
          <div class="col d-flex flex-column max-w-380">
            <div class="input-group pr-2 mb-2">
              <mat-slide-toggle
                (change)="Onchange($event)"
                [checked]="operationShowData['password_based_login']"
              ></mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
      <div style="border-top: 2px solid rgba(221, 221, 221, 0.68); margin-left: -15px;">
        <h2 class="row mt-3 mx-4 text-cyan" translate>organisation_operations.reminder_configuration</h2>
        <form name="form" [formGroup]="configurationForm" style="margin-left: 2rem !important;">
          <div class="row mt-5">
            <div class="col max-w-300 pr-3">
              <h4 class="text-black-1 font-weight-bold" translate>
                organisation_operations.document_renewal_threshold
                <i
                  class="fas fa-question-circle ml-2 cb-tooltip-btn"
                  [ngbTooltip]="'organisation_operations.document_renewal_threshold_detail' | translate"
                  triggers="hover:blur"
                  tooltipClass="cb-tooltip"
                  placement="bottom"
                ></i>
              </h4>
              <!-- <p class="text-black-1" translate>organisation_operations.enter_number_of_days</p> -->
            </div>
            <div class="col d-flex flex-column max-w-380">
              <div class="input-group pr-2 mb-2" style="width: 125px;">
                <input
                  id="document_renewal_threshold"
                  class="form-control cb-input"
                  placeholder="Enter number of days"
                  type="number"
                  formControlName="document_renewal_threshold"
                  [ngClass]="{ 'is-invalid': configurationForm.controls.document_renewal_threshold.errors }"
                />
                <div *ngIf="configurationForm.controls.document_renewal_threshold.errors" class="invalid-feedback">
                  <div
                    class="text-danger"
                    *ngIf="configurationForm.controls.document_renewal_threshold.errors.required"
                  >
                    *Please enter a valid number.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col max-w-300 pr-3">
              <h4 class="text-black-1 font-weight-bold" translate>
                organisation_operations.training_campaign_renewal_threshold
                <i
                  class="fas fa-question-circle ml-2 cb-tooltip-btn"
                  [ngbTooltip]="'organisation_operations.training_campaign_renewal_threshold_detail' | translate"
                  triggers="hover:blur"
                  tooltipClass="cb-tooltip"
                  placement="bottom"
                ></i>
              </h4>
              <!-- <p class="text-black-1" translate>organisation_operations.enter_number_of_days</p> -->
            </div>
            <div class="col d-flex flex-column max-w-380">
              <div class="input-group pr-2 mb-2" style="width: 125px;">
                <input
                  id="training_campaign_renewal_threshold"
                  class="form-control cb-input"
                  placeholder="Enter number of days"
                  type="number"
                  formControlName="training_campaign_renewal_threshold"
                  [ngClass]="{ 'is-invalid': configurationForm.controls.training_campaign_renewal_threshold.errors }"
                />
                <div
                  *ngIf="configurationForm.controls.training_campaign_renewal_threshold.errors"
                  class="invalid-feedback"
                >
                  <div
                    class="text-danger"
                    *ngIf="configurationForm.controls.training_campaign_renewal_threshold.errors.required"
                  >
                    *Please enter a valid number.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col max-w-300 pr-3">
              <h4 class="text-black-1 font-weight-bold" translate>
                organisation_operations.days_before_due_date_threshold_for_campaign
                <i
                  class="fas fa-question-circle ml-2 cb-tooltip-btn"
                  [ngbTooltip]="
                    'organisation_operations.days_before_due_date_threshold_for_campaign_detail' | translate
                  "
                  triggers="hover:blur"
                  tooltipClass="cb-tooltip"
                  placement="bottom"
                ></i>
              </h4>
              <!-- <p class="text-black-1" translate>organisation_operations.enter_number_of_days</p> -->
            </div>
            <div class="col d-flex flex-column max-w-380">
              <div class="input-group pr-2 mb-2" style="width: 125px;">
                <input
                  id="days_before_due_date_threshold_for_campaign"
                  class="form-control cb-input"
                  placeholder="Enter number of days"
                  type="number"
                  formControlName="days_before_due_date_threshold_for_campaign"
                  [ngClass]="{
                    'is-invalid': configurationForm.controls.days_before_due_date_threshold_for_campaign.errors
                  }"
                />
                <div
                  *ngIf="configurationForm.controls.days_before_due_date_threshold_for_campaign.errors"
                  class="invalid-feedback"
                >
                  <div
                    class="text-danger"
                    *ngIf="configurationForm.controls.days_before_due_date_threshold_for_campaign.errors.required"
                  >
                    *Please enter a valid number.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>
