<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div class="row">
    <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
      <h2 class="text-cyan m-0" translate>Category.Title</h2>
      <div>
        <button (click)="addCategory()" class="btn-add ml-4" translate>
          <i class="fa fa-plus"></i>Category.Create_Category
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="row mt-1 mb-3" *ngIf="backend_error">
      <div class="col-12">
        <span class="text-danger backend_error" translate> * {{ backend_error }}</span>
      </div>
    </div>
    <div
      class="col search-results"
      infiniteScroll
      [scrollWindow]="false"
      (scrolled)="onScroll()"
      [infiniteScrollDistance]="modalScrollDistance"
      [infiniteScrollThrottle]="modalScrollThrottle"
      (scrolledUp)="onScrollUp()"
    >
      <div
        class="cb-accordian mx-auto w-740 mt-4"
        [ngClass]="accArray.includes(i) ? '' : 'expand'"
        *ngFor="let item of categoryList; let i = index"
        [attr.data-index]="i"
      >
        <div class="acc-header d-flex justify-content-between">
          <div class="col-lg-8">
            <h5 class="text-black-1 f2 text-truncate" [ngbTooltip]="item.title" tooltipClass="cb-tooltip">
              {{ item.title }}
            </h5>
          </div>
          <div class="d-flex align-items-center">
            <div>
              <i
                (click)="openEditIncidentCategoryForm(item.guid, item.title, item.description)"
                class="fas fa-pencil-alt btn-edit d-inline-block ml-2"
                aria-hidden="true"
                style="font-size: 13px;"
              ></i>
            </div>
            <div class="col-1 delete-box">
              <i
                (click)="openDeleteIncidentCategoryForm(item.guid, item.title)"
                class="fa fa-trash fa fa-trash btn-edit cursor-pointer mt-2"
                aria-hidden="true"
                style="font-size: 17px;"
              ></i>
            </div>
          </div>
        </div>
        <hr style="width:100%;text-align:left;margin-top:0" />
        <h5 style="margin-left: 36px;" class="text-black-8 " translate>Category.Description</h5>
        <div>
          <div Class="text-black-1" style="margin-left: 45px;" *ngIf="!item.showMore">
            {{ trimString(item.description, 100)
            }}<a
              *ngIf="item.description.length > 100"
              style="color: #00667e; margin-left: 7px;"
              (click)="item.showMore = !item.showMore"
              >Show <span [innerHtml]="item.showMore ? 'Less' : 'More'"> </span>
            </a>
          </div>
          <div Class="text-black-1" style="margin-left: 45px;" *ngIf="item.showMore">
            {{ item.description
            }}<a
              *ngIf="item.description.length > 100"
              style="color: #00667e;margin-left: 7px;"
              (click)="item.showMore = !item.showMore"
              >Show <span [innerHtml]="item.showMore ? 'Less' : 'More'"> </span>
            </a>
          </div>
        </div>

        <ng-template #no_content>
          <hr style="width:100%;text-align:left;margin-left:0" />
          <div style="margin-top: -150px;padding-bottom: 65px;" class="acc-body px-3">
            <img class="empty-doc-list-image" src="../../../assets/images/documents/empty.svg" />
            <div class="text-empty-doc-list text-black-1" translate>Category.No Category</div>
            <div class="sub-text-empty-doc-list cursor-pointer text-cyan-force" translate>
              <a
                class="sub-text-empty-doc-list text-center text-cyan-force"
                (click)="openAddUser(item)"
                style="font-size: 18px;margin-left: -55px;"
                translate
                >Category.Create_Category</a
              >
            </div>
          </div>
        </ng-template>
        <!-- 
          </div>
        </div>
      </div> -->
      </div>
    </div>
  </div>
  &nbsp;
  <h2 *ngIf="scrollLoader" class="font-weight-bold text-black-3 text-center">
    <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': scrollLoader }" style="font-size:large;"></i> &nbsp; Loading...
  </h2>
</ng-container>
