<div>
  <div class="modal-header cb-modal-header">
    <div>
      <h4 class="modal-title font-weight-bold text-black-1" translate>organisation_profile.Title</h4>
      <i
        class="fa fa-times text-black-1"
        style="width: 12px;
              height: 12px;
              right: 1em;
              cursor: pointer;
              position: absolute;
              top: 1.3em;"
        (click)="activeModal.close(false)"
        ngbAutoFocus
        translate
      ></i>
    </div>
  </div>
  <div class="modal-body">
    <div class="row mt-1 mb-3" *ngIf="backend_error">
      <div class="col-12">
        <span class="text-danger backend_error" translate> * {{ backend_error }}</span>
      </div>
    </div>

    <form [formGroup]="ChangeSignatureForm" novalidate>
      <div class="row mt-3">
        <div class="col-3">
          <label for="Signature" class="text-black-1 pl-2" translate>organisation_profile.Select Signature</label>
        </div>
        <div class="input-group col-9">
          <input
            id="Signature"
            class="file-upload file-input"
            formControlName="Signature"
            name="Signature"
            autocomplete="Signature"
            type="file"
            (change)="UploadSignature($event)"
            accept=".jpg,.png,.jpeg"
          />
          <div class="col-12 error-mssg" *ngIf="submitted && f.Signature.errors">
            <label for="Signature" class="text-danger" *ngIf="f.Signature.errors.required" translate>
              organisation_profile.*Signature is required.</label
            >
          </div>
          <div class="col-12" *ngIf="file_type_error">
            <label for="Signature" class="text-danger" translate
              >organisation_profile.*Please upload your signature in jpeg or png format.</label
            >
          </div>
          <div class="col-12" *ngIf="file_size_error">
            <label for="Signature" class="text-danger" translate
              >organisation_profile.*File size should be less than equal to 5mb.</label
            >
          </div>
          <div class="col-12 signature-upload">
            <div class="col-4">
              <div style="padding: 20px;" *ngIf="isSignatureLoading && SignatureUrl">
                <i
                  class="fa fa-spinner fa-spin"
                  style="font-size:24px;transform-origin: center;width: 20px;height: 20px;"
                ></i>
              </div>
              <img
                class="user-avatar-pic"
                *ngIf="SignatureUrl && !isSignatureLoading"
                [src]="SignatureUrl"
                class="image"
              />
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="isShowCommentSection">
        <div class="row mt-3">
          <div class="col-3">
            <label for="Comment" class="text-black-1 p-2" translate
              >organisation_profile.Comment
              <i
                class="fas fa-question-circle cb-tooltip-btn"
                ngbTooltip="Enter the reason to change the signature."
                triggers="hover:blur"
                tooltipClass="cb-tooltip"
                placement="bottom"
              ></i>
            </label>
          </div>
          <div class="input-group col-9">
            <textarea
              id="Comment"
              class="form-control cb-input"
              [placeholder]="'organisation_profile.Comment' | translate"
              name="Comment"
              type="text"
              formControlName="Comment"
              cols="64"
              rows="5"
              [maxLength]="4097"
            >
            </textarea>
            <div class="col-12 error-mssg" *ngIf="(f.Comment.touched || f.Comment.dirty) && f.Comment.errors">
              <label
                for="name"
                style="margin-left:-15px ;"
                class="text-danger"
                *ngIf="f.Comment.errors.maxLength"
                translate
                >Mark_Editing_Done.* Ensure that the comment has no more than 4096 characters.</label
              >
            </div>
          </div>
        </div>
      </ng-container>
      <div class="row mt-3">
        <div class="col-12">
          <span class="text-black-1 font-weight-bold p-2" translate>organisation_profile.Note </span
          ><span class="text-black-1" translate>
            organisation_profile.Change_Signature_Note
          </span>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer border-0 cb-modal-footer">
    <button class="btn-cancel ml-4 w-100" (click)="activeModal.close(false)" ngbAutoFocus translate>
      organisation_profile.Cancel
    </button>
    <button class="btn-save ml-4 w-100" *ngIf="!isLoading" (click)="save()" translate>
      organisation_profile.Save
    </button>
    <button class="btn-save ml-4 w-100" disabled *ngIf="isLoading">
      <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
    </button>
  </div>
</div>
