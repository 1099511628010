import { Component, OnInit, Input } from '@angular/core';
import { NgbDateStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { SettingsService } from '@app/settings/settings.service';
import { finalize } from 'rxjs/operators';
import { UserService } from '@app/core/user.service';
import { ModalService } from '@app/core/modal/modal.service';
import { take } from 'rxjs/operators';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { TranslateService } from '@ngx-translate/core';
import stringInfo from '../../../../translations/en-US.json';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { SidenavService } from '@app/core/modal/sidenav.service';

@Component({
  selector: 'app-invite-editors',
  templateUrl: './invite-editors.component.html',
  styleUrls: ['./invite-editors.component.scss']
})
export class InviteEditorsComponent implements OnInit {
  minDate: NgbDateStruct;
  date = stringInfo.Document_Invite_Editor.Date;
  InviteEditorForm: FormGroup;
  submitted = false;
  isLoading = false;
  statusData: any;
  success: boolean;
  OrganisationID: string | null;
  guidSubscription: any;
  dropdownList: any = [];
  selectedItems: any = [];
  dropdownSettings = {};
  error: any;
  EditorSelected = true;
  EditorList: object[];
  UsersList: object[];
  DataList: object[];
  loginUser: any;
  loginUsername: string;
  loginUserEmail: string;
  loading = true;
  store_due_date = '';
  searchPlaceholder: string = '';
  private nameColors = new Map<string, string>();

  @Input() formType: string;
  @Input() DocumentGuid: string;
  @Input() title_tag: string;
  @Input() DocCreatedBy: string;
  @Input() docTitle: string;
  @Input() comment: string;
  @Input() index: any;
  @Input() type: string = '';
  orgGuidSubscription: any;
  backend_error: any;
  status8: string;
  status10: string;
  status12: string;
  status11: any;
  showTooltip: boolean = false;
  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    private formBuilder: FormBuilder,
    private documentsService: DocumentsService,
    private settingsService: SettingsService,
    private userservice: UserService,
    private modalService: ModalService,
    private toaster: ToasterService,
    private translate: TranslateService,
    private sidenavService: SidenavService
  ) {}

  ngOnInit() {
    this.loginUser = this.userservice.getuserdetails();
    this.loginUsername = this.loginUser.user['username'];
    this.loginUserEmail = this.loginUser.user['email'];

    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.minDate = this.documentsService.getTomorrowDate();

      this.getInviteEditorForm();
      // this.getUserList();
      this.getUnassignedEditorsList();
      this.getEditorList();
    });
    this.loading = false;
  }

  onItemSelect(item: any) {
    item.selected = !item.selected;
    if (item.selected) {
      this.selectedItems.push(item);
      this.checkEditorSelection();
    } else {
      this.selectedItems = this.selectedItems.filter((x: any) => x.guid !== item.guid);
    }
  }

  removeItem(item: any) {
    this.selectedItems = this.selectedItems.filter((x: any) => x.guid !== item.guid);
    const userIndex = this.UsersList.findIndex((x: any) => x.guid === item.guid);
    if (userIndex !== -1) {
      this.UsersList[userIndex]['selected'] = false;
    }
    this.checkEditorSelection();
  }

  checkEditorSelection() {
    this.selectedItems.length > 0 ? (this.EditorSelected = true) : (this.EditorSelected = false);
  }

  getInviteEditorForm() {
    this.InviteEditorForm = this.formBuilder.group({
      due_date: ['', [Validators.required(), Validators.DateIsAfterToday()]],
      comment: ['', [Validators.maxLength(4096)]]
    });
  }
  getInitials(first_name: string, last_name: string): string {
    const firstNameInitial = first_name.charAt(0);
    const lastNameInitial = last_name.charAt(0);
    return `${firstNameInitial}${lastNameInitial}`;
  }
  getColor(name: string): string {
    if (this.nameColors.has(name)) {
      return this.nameColors.get(name)!;
    } else {
      const color = this.getRandomColor();
      this.nameColors.set(name, color);
      return color;
    }
  }
  getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.InviteEditorForm.controls;
  }

  private getEditorList(): void {
    let unassigned = {
      'Manage Approvers': 'getDocumentApproverList',
      'Manage Editors': 'getDocumentEditorList'
    };
    this.documentsService[unassigned[this.type]](this.OrganisationID, this.DocumentGuid).subscribe(
      (data: any) => {
        this.EditorList = data;
        if (this.EditorList) {
          this.EditorList.forEach(editor => {
            var initials = editor['user']['name'].match(/\b(\w)/g);
            editor['initials'] = initials.join('').toUpperCase();
          });
        }
      },
      (error: any) => {
        this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
        });
      }
    );
  }
  private getUnassignedEditorsList(): void {
    let unassigned = {
      'Manage Approvers': 'getListUnassignedApprovers',
      'Manage Editors': 'getListUnassignedEditors'
    };
    this.documentsService[unassigned[this.type]](this.OrganisationID, this.DocumentGuid).subscribe(
      (data: any) => {
        this.DataList = data;
        this.UsersList = this.DataList.filter(x => {
          if (this.type != 'Manage Approvers') {
            return x['roles'].includes(
              'document_editor' || (this.DocCreatedBy == this.loginUserEmail && this.loginUserEmail == x['email'])
            );
          } else {
            return x['roles'].includes(
              'document_approver' || (this.DocCreatedBy == this.loginUserEmail && this.loginUserEmail == x['email'])
            );
          }
        });
        this.UsersList.forEach(user => {
          user['FullName'] = user['first_name'] + ' ' + user['last_name'] + '(' + user['email'] + ')';
        });
        this.UsersList.sort((a, b) => a['FullName'].localeCompare(b['FullName']));

        this.UsersList.forEach(user => {
          user['fullName'] = user['first_name'] + ' ' + user['last_name'];
        });
        console.log(this.UsersList);
      },

      (error: any) => {
        this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
        });
      }
    );
  }

  onDateSelection(date: any) {
    if (date) {
      let myDate = new Date(date.year, date.month - 1, date.day + 1);
      this.store_due_date = myDate.toISOString().split('T')[0];
    } else {
      this.store_due_date = this.store_due_date;
    }
    this.checkEditorSelection();
  }

  save() {
    let editor: any = [];
    this.submitted = true;
    if (this.selectedItems.length > 0 && this.InviteEditorForm.valid) {
      editor = this.selectedItems.map((item: any) => {
        return item.guid;
      });
      this.checkEditorSelection();
      this.isLoading = true;
      const editorData = this.InviteEditorForm.value;
      editorData['organization_users'] = editor;
      editorData['role'] = this.type == 'Manage Approvers' ? 'approver' : 'editor';
      editorData['due_date'] = this.store_due_date;
      this.submitInviteEditor(editorData);
    } else {
      this.checkEditorSelection();
      return;
    }
  }

  changeName(statusData: any) {
    this.documentsService.setStatus(statusData);
  }

  submitInviteEditor(editorData: any): void {
    this.documentsService
      .submitInviteDocumentEditorData(this.OrganisationID, this.DocumentGuid, editorData)
      .pipe(
        finalize(() => {
          this.InviteEditorForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.statusData = data;
          this.changeName(this.statusData);
          this.sidenavService.updateData({ isUpdate: true, result: data });
          this.sidenavService.close(false);
          this.success = true;
          this.error = {
            due_date: ''
          };
          this.submitted = false;
          this.backend_error = '';
        },
        (error: any) => {
          this.submitted = false;
          this.isLoading = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
            // this.sidenavService.updateData(this.backend_error, true);
          });
          console.log(' this.backend_error', this.backend_error);
        }
      );
  }

  // Revoke Editor code
  openRevokeEditorForm(DocumentGuid: string, guid: string, user: string) {
    this.RevokeEditorOpenModal(DocumentGuid, guid, user);
  }
  RevokeEditorOpenModal(DocumentGuid: string, guid: string, user: string): void {
    let userMsg = this.type == 'Manage Approvers' ? ' from approvers?' : ' from editors?';
    this.modalService
      .custom(CustomDialogComponent, {
        title: this.type == 'Manage Approvers' ? 'Revoke an Approver' : 'Revoke an Editor',
        message: 'Are you sure you want to remove ' + user + userMsg,
        modalType: this.type == 'Manage Approvers' ? 'Revoke Approver' : 'Revoke Editor',
        guid: guid,
        DocumentGuid: DocumentGuid
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        this.getEditorList();
        this.getUnassignedEditorsList();
        this.documentsService.status8.subscribe(data => {
          this.status8 = data;
        });
        this.changeName(this.status8);
        if (result) {
          let msg =
            this.type == 'Manage Approvers'
              ? 'Revoke_Approver.revoke_approver_toaster_message.revoke_approver_submitted'
              : 'Revoke_Editor.revoke_editor_toaster_message.revoke_editor_submitted';
          this.translate.get(msg).subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  // Reassign Editor code
  openRestoreEditorForm(DocumentGuid: string, guid: string, user: string, docTitle: string, due_date: any) {
    this.RestoreEditorModal(DocumentGuid, guid, user, docTitle, due_date);
  }
  RestoreEditorModal(DocumentGuid: string, guid: string, user: string, docTitle: string, due_date: any): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Reassign an Editor',
        message: 'Are you sure you want to invite ' + user + ' for editing the document again?',
        modalType: 'Reassign Editor',
        guid: guid,
        DocumentGuid: DocumentGuid,
        docTitle: docTitle,
        due_date: due_date
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        this.getEditorList();
        this.getUnassignedEditorsList();
        if (result) {
          this.documentsService.status10.subscribe(data => {
            this.status10 = data;
          });
          this.changeName(this.status10);
          this.translate
            .get('Restore_Editor.restore_editor_toaster_message.restore_editor_submitted')
            .subscribe(res => {
              this.toaster.showSuccess(res);
            });
        } else {
          console.log('Cancelled.');
        }
      });
  }
  openRestoreApproverForm(DocumentGuid: string, guid: string, user: string, docTitle: string, due_date: any): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Reassign an Approver',
        message: 'Are you sure you want to invite ' + user + ' for Approving the document again?',
        modalType: 'Reassign Approver',
        guid: guid,
        DocumentGuid: DocumentGuid,
        docTitle: docTitle,
        due_date: due_date
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        this.getEditorList();
        this.getUnassignedEditorsList();
        this.documentsService.status11.subscribe(data => {
          this.status11 = data;
        });
        this.changeName(this.status11);
        if (result) {
          this.translate
            .get('Reassign_Approver.reassign_approver_toaster_message.reassign_approver_submitted')
            .subscribe(res => {
              this.toaster.showSuccess(res);
            });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  openChangeDueFrom(
    DocumentGuid: string,
    guid: string,
    DocCreatedBy: string,
    user: string,
    start_message: string,
    due_date: any
  ) {
    this.ChangeDuedateModal(DocumentGuid, guid, DocCreatedBy, user, start_message, due_date);
  }
  ChangeDuedateModal(
    DocumentGuid: string,
    guid: string,
    DocCreatedBy: string,
    user: string,
    start_message: string,
    due_date: any
  ): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Edit Collaboration',
        modalType:
          this.type == 'Manage Approvers' ? 'Change Due Date for invite approver' : 'Change Due Date for invite editor',
        DocumentGuid: DocumentGuid,
        DocCreatedBy: DocCreatedBy,
        guid: guid,
        user: user,
        module: 'Document',
        start_message: start_message,
        due_date: due_date,
        role: this.type == 'Manage Approvers' ? 'approver' : 'editor'
      })

      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getEditorList();
          this.documentsService.status12.subscribe(data => {
            this.status12 = data;
          });
          this.changeName(this.status12);
          this.translate.get('Change_due_date_toaster_message.Due_date_changed').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }
  closeNav() {
    this.sidenavService.close(true);
  }
}
