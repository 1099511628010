<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div>
    <div class="modal-header cb-modal-header">
      <div>
        <h4 class="modal-title font-weight-bold text-black-1" translate>Select_Template.Title</h4>
        <i
          class="fa fa-times text-black-1"
          style="width: 12px;
          height: 12px;
          right: 1em;
          cursor: pointer;
          position: absolute;
          top: 1.3em;"
          (click)="activeModal.close(false)"
          ngbAutoFocus
          translate
        ></i>
      </div>
    </div>
    <div class="modal-body">
      <div *ngIf="error">
        <div class="col-12 mt-2 error-mssg" *ngIf="error.non_field_errors">
          <label class="text-danger" translate>* {{ error.non_field_errors }}</label>
        </div>
      </div>
      <form [formGroup]="selectTemplateForm">
        <div
          class="row mt-1 ml-3 search-results"
          infiniteScroll
          [scrollWindow]="false"
          (scrolled)="onScroll()"
          [infiniteScrollDistance]="modalScrollDistance"
          [infiniteScrollThrottle]="modalScrollThrottle"
          (scrolledUp)="onScrollUp()"
        >
          <div class="col-12" *ngIf="TemplatesList?.length > 0; else no_content">
            <table class="table table-condensed table-hover table-bordered">
              <thead>
                <tr>
                  <th class="font-weight-bold text-black-1 text-center" translate>Select_Template.Select</th>
                  <th class="font-weight-bold text-black-1 text-center" translate>Select_Template.Template Name</th>
                  <th class="font-weight-bold text-black-1 text-center" translate>Select_Template.Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of TemplatesList">
                  <td>
                    <input
                      [id]="item.Id"
                      type="radio"
                      style="margin-left: 20px"
                      [value]="item.id"
                      name="item.Id"
                      [checked]="item.checked"
                      (change)="SelectChanged(item, $event)"
                    />
                  </td>

                  <td class="css-title">
                    <abbr
                      title="{{ item.title }} {{ item['created_by']['name'] }} {{
                        item['published_on_timestamp'] | date
                      }} {{ item['version'] }}"
                    >
                      {{ item.title }}
                    </abbr>
                  </td>
                  <td>
                    <i
                      class="fa fa-spinner fa-spin ml-4"
                      style="font-size: 16px; color: #673ab7;"
                      *ngIf="isViewLoading && clickedRow === item.guid; else other_content"
                    ></i>
                    <ng-template #other_content>
                      <a
                        class="ml-4"
                        style="color: #673ab7;"
                        target="_blank"
                        (click)="ViewTemplate(item.guid, item.doc_guid, item.document_store_identifier)"
                        >View</a
                      >
                    </ng-template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <h4 *ngIf="scrollLoader" class="font-weight-bold text-black-3 text-left">
          <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': scrollLoader }" style="font-size:large;"></i> &nbsp;
          Loading...
        </h4>
      </form>
    </div>
    <div class="modal-footer border-0 cb-modal-footer">
      <button class="btn-cancel ml-4 w-100" (click)="activeModal.close(false)" ngbAutoFocus translate>
        Select_Template.Cancel
      </button>
      <button class="btn-save ml-4 w-100" *ngIf="!isLoading" (click)="save()" translate>
        Select_Template.Submit
      </button>

      <button class="btn-save ml-4 w-100" disabled *ngIf="isLoading">
        <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
      </button>
    </div>
  </div>
</ng-container>
<ng-template #no_content>
  <div class="scroller">
    <div class="col" style="bottom: 7em;">
      <img class="empty-doc-list-image" src="../../../assets/images/documents/empty.svg" />
      <div class="text-black-1 mr-2  ml-1 text-center" translate>No Template Found</div>
      <a
        class="cursor-pointer text-cyan-force"
        (click)="goToCreateTemplate()"
        style="font-size: 13px; margin-left: 150px"
        translate
      >
        Add New Templates
      </a>
    </div>
  </div>
</ng-template>
