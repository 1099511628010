import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleDriveUploadService {
  constructor() {}
  async uploadFileAndGetInfo(
    file: File,
    temporaryToken: string,
    folderId: string,
    document_title: string
  ): Promise<{ fileId: string; fileType: string }> {
    const fileMetadata = {
      name: document_title,
      parents: [folderId]
    };

    // Create the resumable upload session
    const sessionResponse = await this.createResumableUploadSession(fileMetadata, temporaryToken);

    // Retrieve the resumable upload URL from the session response
    const location = sessionResponse.headers.get('Location');

    // Perform the actual file upload using the resumable URL
    const uploadResponse = await this.uploadFileToResumableURL(location, file, temporaryToken);

    // Parse the response JSON to get file ID and file type
    const fileId = uploadResponse.id;
    const fileType = uploadResponse.mimeType;

    return { fileId, fileType };
  }

  private async createResumableUploadSession(fileMetadata: any, temporaryToken: string): Promise<Response> {
    return fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=resumable&supportsAllDrives=true', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${temporaryToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(fileMetadata)
    });
  }

  private async uploadFileToResumableURL(url: string, file: File, temporaryToken: string): Promise<any> {
    return fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${temporaryToken}`,
        'Content-Type': file.type
      },
      body: file
    }).then(response => response.json());
  }
}
