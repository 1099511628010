import { Component, Inject, ViewChild } from '@angular/core';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-image-select',
  templateUrl: './imageSelect.component.html',
  styleUrls: ['./imageSelect.component.scss']
})
export class ImageSelectComponent {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  showContext = false;
  showCropper = false;
  croppedImageFile: any = '';
  @ViewChild(ImageCropperComponent, { static: false }) imageCropper: ImageCropperComponent;

  constructor(private dialogRef: MatDialogRef<ImageSelectComponent>) {}

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.showContext = true;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.croppedImageFile = event.file;
  }
  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded');
  }
  cropperReady() {
    console.log('Cropper ready');
  }
  loadImageFailed() {
    console.log('Load failed');
  }
  rotateLeft() {
    this.imageCropper.rotateLeft();
  }
  rotateRight() {
    this.imageCropper.rotateRight();
  }
  flipHorizontal() {
    this.imageCropper.flipHorizontal();
  }
  flipVertical() {
    this.imageCropper.flipVertical();
  }

  saveImage() {
    this.dialogRef.close({ file: this.croppedImageFile, b64: this.croppedImage });
  }

  cancel() {
    this.dialogRef.close();
  }
}
