<!-- <ng-template #no_content> -->
<app-spinner *ngIf="isLoading"></app-spinner>
<!-- </ng-template> -->
<div class="row">
  <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
    <h2 class="text-cyan m-0" translate>Tasks</h2>
    <div class="search-people position-relative">
      <div class="input-group w-330">
        <input
          id="search"
          [(ngModel)]="searchText"
          class="form-control cb-input"
          [placeholder]="'search'"
          name="search"
          type="text"
          (keyup)="updateSearchText($event)"
          (keyDown.enter)="Search(searchText)"
          #titleInput
        />
      </div>
      <i class="fa fa-search search-icon"></i>
    </div>
    <div *ngIf="showAddEvents">
      <button class="btn-add ml-4" translate (click)="open_add_event_diag()">
        <i class="fa fa-plus"></i>Add Tasks
      </button>
    </div>
  </div>
</div>
<div class="row " *ngIf="!isLoading">
  <div class="col mt-5 ">
    <!-- single event-->
    <!--<div class="single-event-acc mx-auto w-740 expand mb-4">-->
    <!--<div class="acc-header d-flex justify-content-between">-->
    <!--<div class="participations bg-yellow">-->
    <!--<h5>Participation: 3/10</h5>-->
    <!--</div>-->
    <!--<h5 class="text-black-1">Management of media and covered data</h5>-->
    <!--<div class="d-flex align-items-center">-->
    <!--<span class="running-progress text-black-2"> <i class="fas fa-sync-alt text-cyan pr-1"></i> Running </span>-->
    <!--<button class="btn-stop ml-4">Stop</button>-->
    <!--<button class="btn-acc-toggle ml-4 mr-3" (click)="(toggleEvent)">-->
    <!--<i class="fas fa-chevron-up text-black-2"></i>-->
    <!--</button>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="acc-body">-->
    <!--<div class="event-details p-4">-->
    <!--<h4 class="text-black-2 mb-3">Details</h4>-->
    <!--<div class="row mb-2">-->
    <!--<div class="col-lg-4"><h5 class="text-black-2 font-weight-bold">Description:</h5></div>-->
    <!--<div class="col-lg-8">-->
    <!--<h5 class="text-black-1">Intrusion Detection logs are reviewed daily</h5>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="row mb-2">-->
    <!--<div class="col-lg-4"><h5 class="text-black-2 font-weight-bold">Slack Channel Name:</h5></div>-->
    <!--<div class="col-lg-8"><h5 class="text-black-1">intrusion-detecti-log</h5></div>-->
    <!--</div>-->
    <!--<div class="row mb-2">-->
    <!--<div class="col-lg-4"><h5 class="text-black-2 font-weight-bold">Committee Name:</h5></div>-->
    <!--<div class="col-lg-8"><h5 class="text-black-1">Information Security Management Committee</h5></div>-->
    <!--</div>-->
    <!--<div class="row mb-2">-->
    <!--<div class="col-lg-6">-->
    <!--<div class="row">-->
    <!--<div class="col-12">-->
    <!--<h5 class="text-black-2 font-weight-bold">Schedule:</h5>-->
    <!--</div>-->
    <!--<div class="col-12">-->
    <!--<span class="btn-time text-black-1 mr-2">-->
    <!--Daily - 9:00 AM <i class="fas fa-pencil-alt ml-2"></i>-->
    <!--</span>-->
    <!--<span class="btn-s3-link text-black-2">-->
    <!--<i class="fas fa-link"></i>-->
    <!--S3 Folder Link-->
    <!--</span>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="col-lg-6">-->
    <!--<div class="row">-->
    <!--<div class="col-12">-->
    <!--<h5 class="text-black-2 font-weight-bold">Event Status</h5>-->
    <!--</div>-->
    <!--<div class="col-12">-->
    <!--<div class="form-check form-check-inline">-->
    <!--<label class="form-check-label radio-1 text-black-1" for="inlineRadio2">-->
    <!--<input-->
    <!--class="form-check-input "-->
    <!--type="radio"-->
    <!--name="inlineRadioOptions"-->
    <!--id="inlineRadio2"-->
    <!--value="option2"-->
    <!--/>-->
    <!--<span class="checkmark"></span>-->
    <!--Enable-->
    <!--</label>-->
    <!--</div>-->

    <!--<div class="form-check form-check-inline">-->
    <!--<label class="form-check-label radio-1 text-black-1" for="inlineRadio1">-->
    <!--<input-->
    <!--class="form-check-input"-->
    <!--type="radio"-->
    <!--name="inlineRadioOptions"-->
    <!--id="inlineRadio1"-->
    <!--value="option2"-->
    <!--/>-->
    <!--<span class="checkmark"></span>-->
    <!--Disable-->
    <!--</label>-->
    <!--</div>-->

    <!--<div class="form-check form-check-inline">-->
    <!--<label class="form-check-label radio-1 text-black-1" for="inlineRadio1">-->
    <!--<input-->
    <!--class="form-check-input"-->
    <!--type="radio"-->
    <!--name="inlineRadioOptions"-->
    <!--id="inlineRadio1"-->
    <!--value="option2"-->
    <!--/>-->
    <!--<span class="checkmark"></span>-->
    <!--Disregard-->
    <!--</label>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="event-more-details p-4">-->
    <!--<h4 class="text-black-2 mb-3">More Details</h4>-->
    <!--<div class="row mb-2">-->
    <!--<div class="col-lg-4"><h5 class="text-black-2 font-weight-bold">Review Event Name:</h5></div>-->
    <!--<div class="col-lg-8">-->
    <!--<h5 class="text-black-1">Intrusion Detection logs are reviewed daily</h5>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="row mb-2">-->
    <!--<div class="col-lg-4">-->
    <!--<h5 class="text-black-2 font-weight-bold">Review Event Committee Name:</h5>-->
    <!--</div>-->
    <!--<div class="col-lg-8"><h5 class="text-black-1">intrusion-detecti-log</h5></div>-->
    <!--</div>-->
    <!--<div class="row mb-2">-->
    <!--<div class="col-lg-12"><h5 class="text-black-2 font-weight-bold">Review Event Name:</h5></div>-->
    <!--<div class="col-lg-12">-->
    <!--<textarea-->
    <!--class="form-control cb-input"-->
    <!--placeholder="Mobile devices and unauthorised devices review meeting started."-->
    <!--&gt;</textarea>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="row mb-2 mt-3">-->
    <!--<div class="col-lg-12"><h5 class="text-black-2 font-weight-bold mb-0">Document URLs:</h5></div>-->
    <!--<div class="col-12">-->
    <!--<div class="row">-->
    <!--<div class="col-lg-3 px-2 pl-3">-->
    <!--<label class="text-black-1 mb-1">URL Type</label>-->
    <!--<select class="form-control cb-input d-flex">-->
    <!--<option>State</option></select-->
    <!--&gt;-->
    <!--</div>-->
    <!--<div class="col-lg-6 px-2">-->
    <!--<label class="text-black-1 mb-1">URL</label>-->
    <!--<input type="url" class="form-control cb-input" placeholder="Label" />-->
    <!--</div>-->
    <!--<div class="col-lg-3 px-2">-->
    <!--<p class="delete-url">Delete URL</p>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->

    <!--<div class="row mb-2">-->
    <!--<div class="col-lg-12"><p class="text-cyan mb-0 add-url">+ Add URL</p></div>-->
    <!--</div>-->
    <!--<div class="row mb-2 mt-4">-->
    <!--<div class="col text-right">-->
    <!--<button class="btn-cancel ml-4 w-80">Cancel</button>-->
    <!--<button class="btn-add ml-4 w-80">Reset</button>-->
    <!--<button class="btn-save ml-4 w-80">Update</button>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="event-view-more text-right">-->
    <!--<span class="text-black-2"> View More <img class="mr-4 ml-2" src="/assets/images/elipsis.svg"/></span>-->
    <!--</div>-->
    <!--</div>-->
    <!--</div>-->
    <!-- single event end-->
    <div
      *ngIf="Events?.length > 0; else no_content"
      class="search-results"
      infiniteScroll
      [scrollWindow]="false"
      (scrolled)="onScroll()"
      [infiniteScrollDistance]="modalScrollDistance"
      [infiniteScrollThrottle]="modalScrollThrottle"
      (scrolledUp)="onScrollUp()"
    >
      <ng-container *ngFor="let event of Events">
        <app-event-card [event]="event" [is_editable]="true" (schedule_changed)="getAllEvents()"> </app-event-card>
      </ng-container>
    </div>
    &nbsp;
    <h2 *ngIf="scrollLoader" class="font-weight-bold text-black-3 text-center">
      <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': scrollLoader }" style="font-size:large;"></i> &nbsp; Loading...
    </h2>
  </div>
</div>
