///<reference path="../../../../../node_modules/rxjs-compat/add/observable/forkJoin.d.ts"/>
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { UserService } from '@app/core/user.service';
import { WizardService } from '@app/home/wizard.services';
import * as _ from 'lodash';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { SelectionModel } from '@angular/cdk/collections';
import { OrganisationService } from '@app/core/organisation.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';

@Component({
  selector: 'app-review-run-finish-step',
  templateUrl: './reviewRunFinish.component.html',
  styleUrls: ['./reviewRunFinish.component.scss']
})
export class ReviewRunFinishComponent implements OnInit, OnDestroy {
  @Input() stepper: MatHorizontalStepper;
  @Input() show = false;
  // stepForm: FormGroup;
  organisationID: any;
  tempData: any = [];
  loading = true;
  data: any = [];
  unique_schedules: any = [];
  unique_schedules_data: any = [];
  guidSubscription: any;
  eventCount = 0;
  eventSuccess = 0;
  eventError = 0;

  constructor(
    private _user: UserService,
    private wizardService: WizardService,
    private orgService: OrganisationService,
    private translate: TranslateService,
    private toaster: ToasterService
  ) {}

  accordian_toggle(index: any) {
    this.unique_schedules[index]['expand'] = !this.unique_schedules[index]['expand'];
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  ngOnInit() {
    this.guidSubscription = this.orgService.organisation_guid.subscribe((guid: any) => {
      this.organisationID = guid;
      this.wizardService.getOrganizationMeetingScheduleETA(this.organisationID).subscribe(data => {
        this.tempData = data;
        this.data = _.cloneDeep(this.tempData);
        this.unique_schedules = Array.from(new Set(this.data.map((item: any) => item['eta'])));
        this.unique_schedules = this.unique_schedules.map((value: any) => {
          let display_value = value.replace('-', ' ');
          display_value = display_value.charAt(0).toUpperCase() + display_value.slice(1).toLowerCase();
          return {
            display_value: display_value,
            value: value,
            expand: true,
            selection: 'none'
          };
        });

        for (const schedule of this.unique_schedules) {
          const temp_data = _.filter(this.data, function(o: any) {
            return o['eta'] === schedule.value;
          });
          this.unique_schedules_data[schedule.value] = temp_data.map((t_value: any) => {
            return Object.assign({}, t_value, {
              selected: false
            });
          });
        }
        this.loading = false;
      });
    });
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  canGoToNextStep() {
    return true;
  }

  change_schedule_selection(index: any) {
    const current_value = this.unique_schedules[index]['selection'];
    let event_selected_value = false;
    if (current_value === 'partial') {
      this.unique_schedules[index]['selection'] = 'all';
      event_selected_value = true;
    } else if (current_value === 'all') {
      this.unique_schedules[index]['selection'] = 'none';
      event_selected_value = false;
    } else if (current_value === 'none') {
      this.unique_schedules[index]['selection'] = 'all';
      event_selected_value = true;
    } else {
      this.unique_schedules[index]['selection'] = 'none';
      event_selected_value = false;
    }
    for (const events of this.unique_schedules_data[this.unique_schedules[index]['value']]) {
      events['selected'] = event_selected_value;
    }
  }

  isCheckboxSelected = (currentValue: any) => currentValue['selected'] === true;

  change_event_selection(schedule: any, event_index: any, schedule_index: any) {
    this.unique_schedules_data[schedule][event_index]['selected'] = !this.unique_schedules_data[schedule][event_index][
      'selected'
    ];
    if (this.unique_schedules_data[schedule].every(this.isCheckboxSelected)) {
      this.unique_schedules[schedule_index]['selection'] = 'all';
    } else {
      if (this.unique_schedules_data[schedule].some(this.isCheckboxSelected)) {
        this.unique_schedules[schedule_index]['selection'] = 'partial';
      } else {
        this.unique_schedules[schedule_index]['selection'] = 'none';
      }
    }
  }

  submitForm() {
    const selection: any = [];

    for (const schedule of this.unique_schedules) {
      if (schedule['selection'] === 'all' || schedule['selection'] === 'partial') {
        for (const event of this.unique_schedules_data[schedule['value']]) {
          if (event['selected']) {
            selection.push(event['review_event_guid']);
          }
        }
      }
    }

    for (const meeting of selection) {
      this.eventCount += 1;
      this.wizardService.runReviewEventNow(meeting).subscribe(
        (data: any) => {
          this.eventSuccess += 1;
          console.log('Success- event run');
        },
        (error: any) => {
          this.eventError += 1;
          console.log('Error - event failed');
        }
      );
    }

    this.toaster.showError(
      'Out of' +
        ' ' +
        this.eventCount +
        ' ' +
        'events,' +
        ' ' +
        this.eventSuccess +
        ' ' +
        'scheduled successfully and' +
        ' ' +
        this.eventError +
        ' ' +
        'failed.'
    );
  }
}
