import { Component, OnDestroy, OnInit, Injectable } from '@angular/core';
import { OrganisationService } from '@app/core/organisation.service';
import { ModalService } from '@app/core/modal/modal.service';
import { take } from 'rxjs/operators';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { TranslateService } from '@ngx-translate/core';
import { LeadService } from './lead.service';
import { finalize } from 'rxjs/operators';
import {
  NgbCalendar,
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '@app/core/user.service';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.year + this.DELIMITER + date.month + this.DELIMITER + date.day : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.year + this.DELIMITER + date.month + this.DELIMITER + date.day : '';
  }
}

@Component({
  selector: 'app-lead',
  templateUrl: './lead.component.html',
  styleUrls: ['./lead.component.scss'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class LeadComponent implements OnInit, OnDestroy {
  OrganisationID: string | null;
  userType: any;
  error: any;
  guidSubscription: any;
  leadGeneratedList: object[] = [];
  parentTypeMessage = 'no-lead-generated';
  accArray: any[] = [];
  isLoading = false;
  actionType = '';
  // Multiselect dropdown
  dropdownList: any = [];
  selectedItems: any = [];
  dropdownSettings = {};
  // Date selector
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  constructor(
    private orgService: OrganisationService,
    private leadService: LeadService,
    private modalService: ModalService,
    private toaster: ToasterService,
    private translate: TranslateService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private user: UserService,
    private dateAdapter: NgbDateAdapter<string>
  ) {
    this.fromDate = null;
    this.toDate = null;
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  private callDateConventor(date: any) {
    return this.dateAdapter.toModel(date)!;
  }

  private callfilterData(toDate: any, fromDate: any) {
    const toDateFilter = this.callDateConventor(toDate);
    const fromDateFilter = this.callDateConventor(fromDate);
    let selectedStatusType: any = [];
    selectedStatusType = this.selectedItems.map((item: any) => {
      return item.item_id;
    });
    if (toDateFilter !== null && fromDateFilter !== null) {
      this.getLeadGeneratedList(toDateFilter, fromDateFilter, selectedStatusType);
    } else if (toDateFilter === null && fromDateFilter === null) {
      this.getLeadGeneratedList('', '', selectedStatusType);
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    this.callfilterData(this.toDate, this.fromDate);
  }

  removeDate(dateType: string) {
    switch (dateType) {
      case 'fromDate':
        this.toDate = null;
        this.fromDate = null;
        this.callfilterData(this.toDate, this.fromDate);
        break;
      case 'toDate':
        this.toDate = null;
        this.callfilterData(this.toDate, this.fromDate);
        break;
      default:
        this.toDate = null;
        this.fromDate = null;
        this.callfilterData(this.toDate, this.fromDate);
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  ngOnInit(): void {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      console.log(this.OrganisationID);
      this.setStatusDropDownFields();
    });
    this.userType = this.user.getUserRole();
  }

  private getRegistrationLeadsChoices(): void {
    this.leadService.registrationLeadChoices().subscribe(
      (data: any) => {
        this.dropdownList = data;
        this.selectedItems = [
          { item_id: 'applied', item_text: 'Applied' },
          { item_id: 'in_process', item_text: 'In process' }
        ];
        this.dropdownSettings = {
          singleSelection: false,
          idField: 'item_id',
          textField: 'item_text',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          itemsShowLimit: 5,
          allowSearchFilter: false,
          enableCheckAll: false
        };
        this.callfilterData(this.toDate, this.fromDate);
      },
      (error: any) => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }

  private setStatusDropDownFields() {
    this.getRegistrationLeadsChoices();
  }

  onItemSelect(item: any) {
    this.callfilterData(this.toDate, this.fromDate);
  }

  onItemDeSelect(item: any) {
    this.callfilterData(this.toDate, this.fromDate);
  }

  openModal(firstName?: string, lastName?: string, userGuid?: string, action?: string, orgName?: string) {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Do you want to ' + action + ' ' + orgName,
        message: 'If you want to accept the ' + orgName + ' request, then please add the CBAgent bitbucket URL.',
        modalType: 'confirm-lead-accept-modal',
        userGuid: userGuid
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.callfilterData(this.toDate, this.fromDate);
          this.translate
            .get('organisation_lead_generation.lead_generated_toaster_message.accept_user')
            .subscribe(res => {
              this.toaster.showSuccess(res);
            });
        }
      });
  }

  openConfirm(firstName?: string, lastName?: string, userGuid?: string, action?: string, orgName?: string) {
    this.modalService
      .confirm(
        'Do you want to ' + action + ' ' + orgName,
        action.charAt(0).toUpperCase() + action.slice(1) + ' ' + orgName + ' request.'
      )
      .pipe(take(1))
      .subscribe((confirmed: any) => {
        if (confirmed) {
          this.isUserAcceptedOrRejected(userGuid, action);
        }
      });
  }

  isUserAcceptedOrRejected(guid: string, action: string): void {
    let payload = {};
    this.isLoading = true;
    this.actionType = action;
    if (action === 'reject') {
      payload = {
        status: 'rejected'
      };
    } else {
      payload = {
        status: 'approved'
      };
    }
    this.leadService
      .rejectOrAcceptOrganizationLeadGeneratedUser(guid, payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.actionType = '';
        })
      )
      .subscribe(
        (data: any) => {
          this.callfilterData(this.toDate, this.fromDate);
          this.translate
            .get(
              action === 'reject'
                ? 'organisation_lead_generation.lead_generated_toaster_message.reject_user'
                : 'organisation_lead_generation.lead_generated_toaster_message.accept_user'
            )
            .subscribe(res => {
              this.toaster.showSuccess(res);
            });
        },
        (error: any) => {
          this.error = error.error.message;
          this.translate
            .get('organisation_lead_generation.lead_generated_toaster_message.error_lead')
            .subscribe(res => {
              this.toaster.showError(res);
            });
          console.log('Error:', error.error.message);
        }
      );
  }

  toggleDetails(index: any): void {
    if (this.accArray.includes(index)) {
      const pos = this.accArray.indexOf(index);
      if (index > -1) {
        this.accArray.splice(pos, 1);
      }
    } else {
      this.accArray.push(index);
    }
  }

  private getLeadGeneratedList(toDate?: any, fromDate?: any, statusItems?: any): void {
    this.leadService.getOrganisationLeadGeneratedList(toDate, fromDate, statusItems).subscribe(
      (data: any) => {
        this.leadGeneratedList = data.length === 0 ? null : data;
      },
      (error: any) => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }
}
