<app-spinner *ngIf="isLoading"></app-spinner>
<div>
  <ng-container *ngIf="!isLoading">
    <div class="modal-header cb-modal-header">
      <div>
        <h3 class="modal-title font-weight-bold text-black-1" translate>Create_jira_ticket.header</h3>
        <i
          class="fa fa-times text-black-1"
          style="width: 12px;
          height: 12px;
          right: 1em;
          cursor: pointer;
          position: absolute;
          top: 1.3em;"
          (click)="activeModal.close(false)"
          ngbAutoFocus
          translate
        ></i>
      </div>
    </div>

    <div class="modal-body">
      <div class="row mt-1 mb-3" *ngIf="backend_error">
        <div class="col-12">
          <span class="text-danger backend_error" translate> * {{ backend_error }}</span>
        </div>
      </div>

      <form [formGroup]="jiraTicket" novalidate>
        <div class="row mt-2">
          <div class="col-3">
            <label class="text-black-1 p-2" translate>Create_jira_ticket.Incident </label>
          </div>
          <div class="col-9">
            <div class="input-group">
              <input class="form-control cb-input" value="{{ incidentTitle }}" disabled />
            </div>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col-3">
            <label for="issue_type" class="text-black-1 p-2" translate>Create_jira_ticket.Issue type</label>
          </div>
          <div class="col-12">
            <ng-select
              [items]="jira_issue_type"
              bindLabel="label"
              bindValue="label"
              [(ngModel)]="selectedIssue"
              class="form-control"
              appendTo="body"
              formControlName="issue_type"
              placeholder="Select issue type"
              class="jira_ticket"
              required
            >
              <ng-template ng-label-tmp let-item="item">
                <img height="15" width="15" [src]="item.icon_url" />
                <b>{{ item.label }}</b>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <img height="15" width="15" [src]="item.icon_url" />
                <b>{{ item.label }}</b>
              </ng-template>
            </ng-select>
          </div>
          <div
            class="col-12 error-mssg"
            style="margin-left: 8em;"
            *ngIf="(jiraTicket.controls.issue_type.touched || submitted) && jiraTicket.controls.issue_type.errors"
          >
            <label for="issue_type" class="text-danger" *ngIf="jiraTicket.controls.issue_type.errors.required" translate
              >Create_jira_ticket.issue_type_required</label
            >
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-3">
            <label for="summary" class="text-black-1 p-2" translate>Create_jira_ticket.Summary</label>
          </div>
          <div class="col-9">
            <div class="input-group">
              <input
                id="summary"
                class="form-control cb-input"
                [placeholder]="'Summary'"
                name="summary"
                type="text"
                formControlName="summary"
                [maxLength]="256"
                required
              />
            </div>
          </div>
          <div
            class="col-12 error-mssg"
            style="margin: -0.8em 8em;"
            *ngIf="
              (jiraTicket.controls.summary.touched || jiraTicket.controls.summary.dirty || submitted) &&
              jiraTicket.controls.summary.errors
            "
          >
            <label for="summary" class="text-danger" *ngIf="jiraTicket.controls.summary.errors.required" translate
              >Create_jira_ticket.summary_required</label
            >
            <label for="summary" class="text-danger" *ngIf="jiraTicket.controls.summary.errors.maxlength" translate
              >Create_jira_ticket.Summary must be 255 characters or less</label
            >
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-3">
            <label for="description" class="text-black-1 p-2" translate>Create_jira_ticket.Description</label>
          </div>
          <div class="col-9">
            <div class="input-group">
              <textarea
                id="description"
                class="form-control cb-input"
                [placeholder]="'Description'"
                name="description"
                type="text"
                formControlName="description"
                cols="64"
                rows="5"
                [maxLength]="32768"
                required
              ></textarea>
            </div>
          </div>
          <div
            class="col-12 error-mssg"
            style="margin-left: 8em;"
            *ngIf="
              (jiraTicket.controls.description.touched || jiraTicket.controls.description.dirty || submitted) &&
              jiraTicket.controls.description.errors
            "
          >
            <label
              for="description"
              class="text-danger"
              *ngIf="jiraTicket.controls.description.errors.required"
              translate
              >Create_jira_ticket.description_required</label
            >
            <label
              for="description"
              class="text-danger"
              *ngIf="jiraTicket.controls.description.errors.maxlength"
              translate
              >Create_jira_ticket.*Description must be 32767 characters or less</label
            >
          </div>
          <!-- <div
            class="col-12 error-mssg"
            style="margin-left: 8em;"
            *ngIf="(jiraTicket.controls.description.touched || jiraTicket.controls.description.dirty) && jiraTicket.controls.description.errors"
          >
            <label
              for="description"
              class="text-danger"
              *ngIf="jiraTicket.controls.description.errors.maxLength"
              translate
              >Create_jira_ticket.description_required</label
            >
          </div> -->
        </div>
        <div class="row mt-3">
          <div class="col-3">
            <label for="assignee" class="text-black-1 p-2" translate>Create_jira_ticket.assignee</label>
          </div>
          <div class="col-12">
            <ng-select
              [items]="assigneeList"
              bindLabel="label"
              bindValue="value"
              [(ngModel)]="selectedAssignee"
              class="form-control fontSize"
              appendTo="body"
              formControlName="assignee"
              placeholder="Select assignee"
              class="jira_ticket"
              required
            >
              <ng-template ng-label-tmp let-item="item">
                <img
                  src="../../../../assets/images/documents/author.png"
                  style="color: black;"
                  width="20"
                  height="15"
                />
                <b>{{ item.label }}</b>
              </ng-template>
              <ng-template ng-option-tmp let-item="item" let-index="index" class="fontSize">
                <img
                  src="../../../../assets/images/documents/author.png"
                  style="color: black;"
                  width="20"
                  height="15"
                />
                <b>{{ item.label }}</b>
              </ng-template>
            </ng-select>
          </div>
          <div
            class="col-12 error-mssg"
            style="margin-left: 8em;"
            *ngIf="(jiraTicket.controls.assignee.touched || submitted) && jiraTicket.controls.assignee.errors"
          >
            <label for="assignee" class="text-danger" *ngIf="jiraTicket.controls.assignee.errors.required" translate
              >Create_jira_ticket.assignee_required</label
            >
          </div>
        </div>
      </form>
      <button
        class="btn-save ml-4 w-100 mt-3 mb-3 float-right"
        *ngIf="!isLoadingSubmitButton"
        (click)="save()"
        translate
      >
        Create_jira_ticket.Submit
      </button>
      <button class="btn-save ml-4 w-100 mt-3 mb-3 float-right" disabled *ngIf="isLoadingSubmitButton">
        <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
      </button>
      <button class="btn-cancel ml-4 w-100 mt-3 float-right" (click)="activeModal.close(false)" ngbAutoFocus translate>
        Create_jira_ticket.Cancel
      </button>
    </div>
  </ng-container>
</div>
<!-- <ng-template #no_content>
  <div class="modal-body">
    <h2 class="modal-title font-weight-bold text-black-1 p-5 text-center" translate>Create_jira_ticket.404_error</h2>
  </div>
  <div class="modal-footer border-0 cb-modal-footer">
    <button class="btn-cancel ml-4 w-100" (click)="activeModal.close(false)" ngbAutoFocus translate>
      Create_jira_ticket.Close
    </button>
  </div>
</ng-template> -->
