import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { PasswordResetRoutingModule } from './password-reset-routing.module';
import { PasswordResetComponent } from './password-reset.component';
import { RouterModule } from '@angular/router';

import { AboutComponent } from '@app/about/about.component';
import { AboutModule } from '@app/about/about.module';
import { PasswordResetService } from './password-reset.service';
import { DemoMaterialModule } from '../materials.module';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, PasswordResetRoutingModule, DemoMaterialModule],
  providers: [PasswordResetService],
  declarations: [PasswordResetComponent]
})
export class PasswordResetModule {}
