<!-- <app-spinner></app-spinner>
<div class="row">
  <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
    <div class="float-left">
      <h2 class="text-cyan m-0" translate>Document History - Cryptography P&P</h2>
    </div>
    <div class="float-right">
      <p class="mb-0 text-black-1">Created by: Chandan</p>
      <p class="mb-0 text-black-1">Status: Pending Approval</p>
      <p class="mb-0 text-black-1">Version: 1.2</p>
    </div>
  </div>
</div> -->
<!-- <div class="row scroll-view">
  <div class="col mt-5">
    <ng-container>
      <div class="row" style="margin-bottom: 30px;" xmlns="http://www.w3.org/1999/html">
        <div class="col">
          <div class="single-event-acc mx-auto w-740" [ngClass]="showDetails ? 'expand' : ''">
            <div class="acc-header d-flex justify-content-between">
              <h5 class="text-black-3">Current 1.2</h5>
              <div class="d-flex align-items-center">
                <span class="text-black-2" style="font-weight: 400;font-size: 14px;line-height: 18px;" translate>
                  07/20/2022 - 08/20/2022
                </span>
                <button class="btn-acc-toggle ml-4 mr-3" (click)="toggleDetails()">
                  <i class="fas fa-chevron-up text-black-2" *ngIf="showDetails"></i>
                  <i class="fas fa-chevron-down text-black-2" *ngIf="!showDetails"></i>
                </button>
              </div>
            </div>
            <div class="acc-body">
              <div class="event-details p-4">
                <div class="row">
                  <div class="col">
                    <span class="float-right text-black-3 cb-p">
                      <div class="circle mr-3 ml-3">
                        <span class="initials">C</span>
                      </div>
                      <p class="text-black-3 mt-1 cb-p1">
                        <b>Chandan </b> Added <b>Kinjal</b> as an approver August 21, 2022 at 10:42 PM
                      </p>
                    </span>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col">
                    <span class="float-right text-black-3 cb-p">
                      <div class="circle mr-3 ml-3">
                        <span class="initials">C</span>
                      </div>
                      <p class="text-black-3 mt-1 cb-p1">
                        <b>Chandan </b> changed the status August 21, 2022 at 10:42 PM
                      </p>
                    </span>
                    <div style="margin-top: 2.5em; margin-left: 4em;">
                      <button
                        style="background: #D9D9D9; border-radius: 4px; width: 60px; height: 30px; font-size: 14px;"
                      >
                        Draft
                      </button>
                      <img
                        src="../../../../assets/images/documents/right_arrow.png"
                        style="width: 20px;height: 15px;margin: 0px 45px;"
                      />
                      <button
                        style="background: #D9D9D9; border-radius: 4px; height: 30px; width: 145px; font-size: 14px;"
                      >
                        Pending Approval
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col">
                    <span class="float-right text-black-3 cb-p">
                      <div class="circle mr-3 ml-3">
                        <span class="initials">U</span>
                      </div>
                      <p class="text-black-3 mt-1 cb-p1">
                        <b>Utkarsh </b> updated the document August 21, 2022 at 10:42 PM
                      </p>
                    </span>
                    <div style="display: flex; margin-top: 2.5em; margin-left: 4em;">
                      <div style="width: 30%;">
                        <p>Text before edit</p>
                        <p>Text before edit</p>
                        <p>Text before edit</p>
                      </div>
                      <img
                        src="../../../../assets/images/documents/right_arrow.png"
                        style="width: 20px; height: 15px; margin: 2.4em 5em 0em -1em;"
                      />
                      <div style="flex-grow: 1;">
                        <p>Text after edit</p>
                        <p>Text after edit</p>
                        <p>Text after edit</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="float-right text-black-3 cb-p">
                      <div class="circle mr-3 ml-3">
                        <span class="initials">C</span>
                      </div>
                      <p class="text-black-3 mt-1 cb-p1">
                        <b>Chandan </b> Added <b>Utkarsh</b> as an editor August 21, 2022 at 10:42 PM
                      </p>
                    </span>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col">
                    <span class="float-right text-black-3 cb-p">
                      <div class="circle mr-3 ml-3">
                        <span class="initials">C</span>
                      </div>
                      <p class="text-black-3 mt-1 cb-p1">
                        <b>Chandan </b> created the <b>Document</b> August 21, 2022 at 10:42 PM
                      </p>
                    </span>
                  </div>
                </div>
              </div>
              <hr />
              <div class="bg-white" style="height: 48px;">
                <span class="float-left">
                  <select class="form-control ml-4 mb-1">
                    <option selected>Change Log</option>
                    <option>Lifecycle Change Report </option>
                    <option>Approval Reports</option>
                    <option>Trainee Reports</option>
                  </select>
                </span>
                <span class="float-right">
                  <button class="text-white history-button mr-2">View</button>
                  <button class="text-white history-button mr-3">Download as PDF</button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container>
      <div class="row" style="margin-bottom: 30px;" xmlns="http://www.w3.org/1999/html">
        <div class="col">
          <div class="single-event-acc mx-auto w-740" [ngClass]="showSecondDetails ? 'expand' : ''">
            <div class="acc-header d-flex justify-content-between">
              <h5 class="text-black-3">Current 1.1</h5>
              <div class="d-flex align-items-center">
                <span class="text-black-2" translate style="font-weight: 400;font-size: 14px;line-height: 18px;">
                  07/20/2022 - 08/20/2022
                </span>
                <button class="btn-acc-toggle ml-4 mr-3" (click)="toggleSecondDetails()">
                  <i class="fas fa-chevron-up text-black-2" *ngIf="showSecondDetails"></i>
                  <i class="fas fa-chevron-down text-black-2" *ngIf="!showSecondDetails"></i>
                </button>
              </div>
            </div>
            <div class="acc-body">
              <div class="event-details p-4">
                <div class="row">
                  <div class="col">
                    <span class="float-right text-black-3 cb-p">
                      <div class="circle mr-3 ml-3">
                        <span class="initials">C</span>
                      </div>
                      <p class="text-black-3 mt-1 cb-p1">
                        <b>Chandan </b> Added <b>Kinjal</b> as an approver August 21, 2022 at 10:42 PM
                      </p>
                    </span>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col">
                    <span class="float-right text-black-3 cb-p">
                      <div class="circle mr-3 ml-3">
                        <span class="initials">C</span>
                      </div>
                      <p class="text-black-3 mt-1 cb-p1">
                        <b>Chandan </b> changed the status August 21, 2022 at 10:42 PM
                      </p>
                    </span>
                    <div style="margin-top: 2.5em; margin-left: 4em;">
                      <button
                        style="background: #D9D9D9; border-radius: 4px; width: 60px; height: 30px; font-size: 14px;"
                      >
                        Draft
                      </button>
                      <img
                        src="../../../../assets/images/documents/right_arrow.png"
                        style="width: 20px;height: 15px;margin: 0px 45px;"
                      />
                      <button
                        style="background: #D9D9D9; border-radius: 4px; height: 30px; width: 145px; font-size: 14px;"
                      >
                        Pending Approval
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col">
                    <span class="float-right text-black-3 cb-p">
                      <div class="circle mr-3 ml-3">
                        <span class="initials">U</span>
                      </div>
                      <p class="text-black-3 mt-1 cb-p1">
                        <b>Utkarsh </b> updated the document August 21, 2022 at 10:42 PM
                      </p>
                    </span>
                    <div style="display: flex; margin-top: 2.5em; margin-left: 4em;">
                      <div style="width: 30%;">
                        <p>Text before edit</p>
                        <p>Text before edit</p>
                        <p>Text before edit</p>
                      </div>
                      <img
                        src="../../../../assets/images/documents/right_arrow.png"
                        style="width: 20px; height: 15px; margin: 2.4em 5em 0em -1em;"
                      />
                      <div style="flex-grow: 1;">
                        <p>Text after edit</p>
                        <p>Text after edit</p>
                        <p>Text after edit</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <span class="float-right text-black-3 cb-p">
                      <div class="circle mr-3 ml-3">
                        <span class="initials">C</span>
                      </div>
                      <p class="text-black-3 mt-1 cb-p1">
                        <b>Chandan </b> Added <b>Utkarsh</b> as an editor August 21, 2022 at 10:42 PM
                      </p>
                    </span>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col">
                    <span class="float-right text-black-3 cb-p">
                      <div class="circle mr-3 ml-3">
                        <span class="initials">C</span>
                      </div>
                      <p class="text-black-3 mt-1 cb-p1">
                        <b>Chandan </b> created the <b>Document</b> August 21, 2022 at 10:42 PM
                      </p>
                    </span>
                  </div>
                </div>
              </div>
              <hr />
              <div class="bg-white" style="height: 48px;">
                <span class="float-left">
                  <select class="form-control ml-4 mb-1">
                    <option selected>Change Log</option>
                    <option>Lifecycle Change Report </option>
                    <option>Approval Reports</option>
                    <option>Trainee Reports</option>
                  </select>
                </span>
                <span class="float-right">
                  <button class="text-white history-button mr-2">View</button>
                  <button class="text-white history-button mr-3">Download as PDF</button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div> -->
<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <!-- <div class="row">
    <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
      <h2 class="text-cyan m-0">IT Management</h2> -->
  <!-- <div class="search-people" style="display: flex;">
      <div class="p-2">
        <div class="input-group">
          <input
            id="search"
            class="form-control cb-input"
            name="search"
            type="text"
          />
          <i class="fa fa-search search-icon"></i>
        </div>
      </div>
      <div class="p-2">
        <mat-slide-toggle class="mt-1 ml-1" (change)="openConfirm('switch', $event)" [checked]="isProdAgent">Show all documents in the category</mat-slide-toggle>
      </div>
    </div> -->
  <!-- </div>
  </div> -->
  <!-- <div class="row scroll-view" *ngIf="ItManagementList?.length > 0; else no_content">
    <div class="col p-0">
      <div class="table_wrapper">
        <table class="w-100p bg-white dm-table table table-responsive">
          <thead>
            <tr style="height: 65px;">
              <th scope="col" class="font-weight-bold text-black-3 text-center">Title</th>
              <th scope="col" class="font-weight-bold text-black-3 text-center">Status</th>
              <th scope="col" class="font-weight-bold text-black-3 text-center">Due Date</th>
              <th scope="col" class="font-weight-bold text-black-3 text-center">Requested By</th>
              <th scope="col" class="font-weight-bold text-black-3 text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center" *ngFor="let It of ItManagementList">
              <td>
                <h4 class="dm-text-1 text-black-3 mt-3">{{ It['title'] }}</h4>
              </td>
              <td>
                <h4 class="text-black-3 dm-text-2 mt-3">{{ It['status']['value'] }}</h4>
              </td>
              <td>
                <h4 class="text-black-3 dm-text-2 mt-3">{{ It['due_date'] ? It['due_date'] : '-' }}</h4>
              </td>
              <td>
                <h4 class="text-black-3 dm-text-2 mt-3">
                  <img
                    src="../../../../assets/images/documents/author.png"
                    class="dm-user-icon"
                    *ngIf="It['requested_by']"
                  />
                  {{ It['requested_by'] ? It['requested_by']['name'] : '-' }}
                </h4>
              </td>
              <td>
                <button class="mt-2 dropdown-btn" mat-stroked-button [matMenuTriggerFor]="menu">
                  Actions <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-menu #menu="matMenu" class="fixed-menu-panel">
                  <ng-container *ngFor="let actions of It['allowed_actions_list']">
                    <button
                      mat-menu-item
                      (click)="
                        getClickEvents(
                          actions,
                          It['guid'],
                          It['created_by']['email'],
                          It['title'],
                          It['document_store_identifier']
                        )
                      "
                    >
                      {{ actions }}
                    </button>
                  </ng-container>
                </mat-menu>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div> -->
  <app-folder-document-view [title_tag]="title"></app-folder-document-view>
</ng-container>

<ng-template #no_content>
  <div class="row scroll-view">
    <div class="col">
      <img class="empty-doc-list-image" src="../../../assets/images/documents/empty.svg" />
      <div class="text-empty-doc-list text-black-1" translate>No documents found</div>
      <div class="sub-text-empty-doc-list cursor-pointer text-cyan-force" translate>
        Add new
        <a
          class="sub-text-empty-doc-list text-center text-cyan-force"
          (click)="goToCreateDoc()"
          style="font-size: 18px;"
          >document</a
        >
      </div>
    </div>
  </div>
</ng-template>
