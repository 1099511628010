import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-discard-pnp-changes',
  templateUrl: './discard-pnp-changes.component.html',
  styleUrls: ['./discard-pnp-changes.component.scss']
})
export class DiscardPnpChangesComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}
  @Input() message: string;
  @Input() title_tag: string;
  ngOnInit(): void {}

  confirm() {
    this.activeModal.close({ isConfirmed: true });
  }
}
