<app-spinner *ngIf="isLoading"></app-spinner>
<div class="row justify-content-center" *ngIf="!isTokenAvailable">
  <div class="login-outer-container">
    <div class="my-5">
      <div class=" text-center d-flex">
        <div class="cursor-pointer fs-17 w-100p" routerLink="/login">
          <img src="/assets/images/logo/logo.png" class="cb-lite-logo mx-1" alt="logo" srcset="" />
          <span class="font-weight-bold fs-17"> ComplianceBot</span>
        </div>
      </div>
      <div class="login-intro">
        <div class="login-container">
          <div class=" text-center w-100p  mb-2">
            <div class="font-weight-bold create-text" *ngIf="!showForgetPasswordForm">
              Welcome Back
            </div>
          </div>
          <div class=" text-center w-100p mb-4">
            <div class="font-weight-light login-text" *ngIf="!showForgetPasswordForm">
              Don't have an account yet?
              <a class="login-text custom-color" routerLink="/signup">Sign up</a>
            </div>
          </div>
          <div *ngIf="signUpWithGoogleError?.length" class="mb-2" style="line-height: 1 !important;margin-left: 8%;">
            <mat-error *ngIf="signUpWithGoogleError?.length"> *{{ this.signUpWithGoogleError }} </mat-error>
          </div>
          <div class=" text-center w-100p mb-4" *ngIf="loginStep == 1">
            <button class="lite-login-btn" (click)="openGoogleLoginPage()">
              <div class="d-flex justify-content-center">
                <img height="20px" src="../../../../assets/images/icons/search.png" />
                <div class="lite-login-btn-text mx-2">
                  Sign in with Google
                </div>
              </div>
            </button>
            <button class="lite-login-btn mt-2" (click)="setStep(2)">
              <div class="d-flex justify-content-center" style="padding-top: 5px;">
                <mat-icon class="fs-20">alternate_email</mat-icon>
                <div class="lite-login-btn-text mx-2">
                  Sign in with Email
                </div>
              </div>
            </button>
          </div>

          <div *ngIf="loginStep == 2">
            <ng-container *ngIf="!showForgetPasswordForm">
              <form class="example-form" (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
                <div>
                  <ng-container *ngIf="password_based_login; else checkUserNotExists">
                    <p class="error-danger">*{{ password_based_login }}</p>
                  </ng-container>

                  <ng-template #checkUserNotExists>
                    <ng-container *ngIf="userNotExists; else other_error">
                      <p class="error-danger">*{{ userNotExists }}</p>
                    </ng-container>
                  </ng-template>
                  <ng-template #other_error>
                    <div *ngIf="page_error">
                      <p class="error-danger" *ngIf="page_error == 'session_timeout'" translate>
                        login_page.Your Session Expired. Please Login Again.
                      </p>
                    </div>
                    <div *ngIf="tokenErrorString.length">
                      <p class="error-danger" translate>*{{ tokenErrorString }}</p>
                    </div>
                    <div *ngIf="loginError">
                      <p class="error-danger" translate>
                        login_page.Error
                      </p>
                    </div>
                    <div *ngIf="page_state">
                      <p class="success-msg" *ngIf="page_state == 'password-updated'" translate>
                        login_page.password-updated
                      </p>
                    </div>
                    <p class="error-danger" [hidden]="!error || isLoading" translate>
                      login_page.Username or password incorrect.
                    </p>
                  </ng-template>
                </div>
                <div class="d-flex justify-content-center w-100p mb-2">
                  <div class="text-left">
                    <div>
                      <label class="m-0">Email</label>
                    </div>
                    <mat-form-field appearance="outline" class="w-400">
                      <input
                        type="email"
                        matInput
                        placeholder="Ex. pat@example.com"
                        formControlName="username"
                        (keydown.space)="$event.preventDefault()"
                      />
                    </mat-form-field>
                    <ng-container *ngIf="loginForm.controls.username.invalid && loginForm.controls.username.touched">
                      <mat-error *ngIf="loginForm.controls.username.errors.required">
                        *Email is required
                      </mat-error>
                      <mat-error *ngIf="loginForm.controls.username.hasError('email')">
                        *Please enter a valid email address
                      </mat-error>
                    </ng-container>
                  </div>
                </div>
                <div class="d-flex justify-content-center w-100p ">
                  <div class="text-left">
                    <div>
                      <label class="m-0">Password</label>
                    </div>
                    <mat-form-field appearance="outline" class="w-400">
                      <input
                        (keydown.space)="$event.preventDefault()"
                        [type]="hide ? 'password' : 'text'"
                        matInput
                        formControlName="password"
                      />
                      <mat-icon class="cursor-pointer pt-2" matSuffix (click)="hide = !hide">{{
                        hide ? 'visibility_off' : 'visibility'
                      }}</mat-icon>
                    </mat-form-field>
                    <ng-container *ngIf="loginForm.controls.password.invalid && loginForm.controls.password.touched">
                      <mat-error *ngIf="loginForm.controls.password.errors.required">
                        *Password is required
                      </mat-error>
                    </ng-container>
                  </div>
                </div>
                <div class="mt-1 mb-4">
                  <div class="text-right forgot-password-text">
                    <p style="margin-left: 19rem; cursor: pointer;" (click)="toggleForgotPasswordForm()">
                      Forgot Password?
                    </p>
                  </div>
                </div>
                <div class="row justify-content-center  ">
                  <button
                    type="button"
                    class="auth-btn lite-login-btn mr-2"
                    (click)="setStep(1); $event.preventDefault()"
                  >
                    <span class="lite-login-btn-text">
                      Back
                    </span>
                  </button>
                  <button
                    [disabled]="loginForm.invalid || isLoading"
                    class="auth-btn lite-login-btn ml-2"
                    [class.lite-login-btn]="!loginForm.invalid"
                    [class.lite-login-btn-disabled]="loginForm.invalid || isLoading"
                    [disabled]="loginForm.invalid || isLoading"
                    (keydown.enter)="$event.preventDefault(); login()"
                  >
                    <span class="lite-login-btn-text">
                      Sign In
                    </span>
                  </button>
                </div>
              </form>
            </ng-container>

            <ng-container *ngIf="showForgetPasswordForm">
              <div class="row">
                <div class="col-12">
                  <form (ngSubmit)="sendPasswordResetEmail()" [formGroup]="forgotPasswordForm">
                    <p class="error-danger" [hidden]="!reset_password_error" translate>*{{ reset_password_error }}</p>
                    <p class="success-msg" [hidden]="!success" translate>
                      password_recovery.Password reset link has been sent, Please check your inbox.
                    </p>
                    <div class="form-group">
                      <label for="username" class="font-weight-bold" translate
                        >password_recovery.Password Recovery</label
                      >
                      <p translate>
                        password_recovery.Enter the email address associated with your Compliance Bot account
                      </p>
                      <mat-form-field appearance="outline" class="w-400">
                        <input
                          type="email"
                          autocomplete="email"
                          matInput
                          [placeholder]="'Type here...' | translate"
                          formControlName="email"
                          (keydown.space)="$event.preventDefault()"
                          (keyup)="onchange($event)"
                        />
                      </mat-form-field>
                      <ng-container
                        *ngIf="forgotPasswordForm.controls.email.invalid && forgotPasswordForm.controls.email.touched"
                      >
                        <mat-error *ngIf="forgotPasswordForm.controls.email.hasError('required')">
                          *Email is required
                        </mat-error>
                        <mat-error *ngIf="forgotPasswordForm.controls.email.hasError('email')">
                          *Please enter a valid email address
                        </mat-error>
                      </ng-container>
                    </div>

                    <button
                      class="password-btn "
                      [class.lite-password-disabled]="forgotPasswordForm.invalid"
                      [class.lite-password-btn]="!forgotPasswordForm.invalid"
                      [disabled]="forgotPasswordForm.invalid || isLoading"
                    >
                      <span class="lite-login-btn-text">
                        Send password reset link
                      </span>
                    </button>
                    <div class="text-right forgot-password-text">
                      <p style="margin-left: 21.2rem ;cursor: pointer;" (click)="toggleForgotPasswordForm()">
                        Back to sign in
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
