import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbDateStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { SettingsService } from '@app/settings/settings.service';
import { finalize } from 'rxjs/operators';
import { UserService } from '@app/core/user.service';
import { ModalService } from '@app/core/modal/modal.service';
import { CustomDialogComponent } from '../customDialog/customDialog.component';
import { take } from 'rxjs/operators';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { TranslateService } from '@ngx-translate/core';
import stringInfo from '../../../../translations/en-US.json';
import { IncidentService } from '@app/Organisation-admin/incident/incident.service';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';

@Component({
  selector: 'app-change-request-for-incident',
  templateUrl: './change-request-for-incident.component.html',
  styleUrls: ['./change-request-for-incident.component.scss']
})
export class ChangeRequestForIncidentComponent implements OnInit {
  minDate: NgbDateStruct;
  InviteEditorForm: FormGroup;
  submitted = false;
  isLoading = false;
  success: boolean;
  OrganisationID: string | null;
  guidSubscription: any;
  dropdownList: any = [];
  selectedItems: any = [];
  selectedOldEditor: any = [];
  dropdownSettingsForOldUsers = {};
  dropdownSettingsForNewUsers = {};
  error: any;
  statusData: any;

  OldEditorSelected = true;
  EditorSelected = true;
  EditorList: object[];
  UsersList: object[];
  DataList: object[];
  loginUser: any;
  loginUsername: string;
  loginUserEmail: string;
  checked = false;
  OldUsersList: object[];
  loading = true;
  date = stringInfo.Document_Invite_Editor.Date;

  @Input() formType: string;
  @Input() incidentGuid: string;
  @Input() title_tag: string;
  @Input() incCreatedBy: string;
  @Input() incidentTitle: string;

  oldEditorListGuid: any;
  editorlength: any;
  backend_error: string;
  store_due_date = '';

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private documentsService: DocumentsService,
    private settingsService: SettingsService,
    private userservice: UserService,
    private modalService: ModalService,
    private toaster: ToasterService,
    private translate: TranslateService,
    private incidentService: IncidentService
  ) {}

  ngOnInit() {
    this.loginUser = this.userservice.getuserdetails();
    this.loginUsername = this.loginUser.user['username'];
    this.loginUserEmail = this.loginUser.user['email'];

    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.minDate = this.documentsService.getTomorrowDate();
      this.getInviteEditorChangeReqForm();
      this.getEditorList();
      this.getUnassignedEditorsList();
      this.loading = false;
    });

    this.dropdownSettingsForNewUsers = {
      singleSelection: false,
      idField: 'guid',
      textField: 'FullName',
      itemsShowLimit: 10,
      enableCheckAll: false,
      allowSearchFilter: true
    };

    this.dropdownSettingsForOldUsers = {
      singleSelection: false,
      idField: 'guid',
      textField: 'FullName',
      itemsShowLimit: 10,
      enableCheckAll: false,
      allowSearchFilter: true
    };
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  onItemSelect(item: any) {
    this.checkEditorSelection();
  }

  onItemDeSelect(items: any) {
    this.checkEditorSelection();
  }

  checkEditorSelection() {
    this.selectedItems.length > 0 ? (this.EditorSelected = true) : (this.EditorSelected = false);
  }
  onOldEditorSelect(item: any) {
    this.checkOldEditorSelection();
  }

  onOldEditorDeSelect(items: any) {
    this.checkOldEditorSelection();
  }
  checkOldEditorSelection() {
    this.selectedOldEditor.length > 0 ? (this.OldEditorSelected = true) : (this.OldEditorSelected = false);
  }

  getInviteEditorChangeReqForm() {
    this.InviteEditorForm = this.formBuilder.group({
      due_date: ['', [Validators.required(), Validators.DateIsAfterToday()]],
      comment: ['', Validators.maxLength(4096)]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.InviteEditorForm.controls;
  }

  private getUserList(): void {
    this.settingsService.getOrganizationPeopleList(this.OrganisationID).subscribe(
      data => {
        this.DataList = data.data;
        this.UsersList = this.DataList.filter(x => {
          return x['roles'].includes(
            'document_editor' || (this.incCreatedBy == this.loginUserEmail && this.loginUserEmail == x['email'])
          );
        });
        this.UsersList.forEach(user => {
          user['FullName'] = user['first_name'] + ' ' + user['last_name'] + '(' + user['email'] + ')';
        });
        this.UsersList.sort((a, b) => a['FullName'].localeCompare(b['FullName']));
      },
      error => {
        this.error = error.error.message;
      }
    );
  }

  private getEditorList(): void {
    this.incidentService.getIncidentReviewerList(this.OrganisationID, this.incidentGuid).subscribe(
      data => {
        this.OldUsersList = data;
        if (this.OldUsersList) {
          this.OldUsersList.forEach(user => {
            var initials = user['user']['name'].match(/\b(\w)/g);
            user['initials'] = initials.join('').toUpperCase();
          });
        }
        this.OldUsersList.forEach(user => {
          user['guid'] = user['user']['guid'];
          user['FullName'] = user['user']['name'] + '(' + user['user']['email'] + ')';
        });
        this.OldUsersList.sort((a, b) => a['FullName'].localeCompare(b['FullName']));
      },

      error => {
        this.error = error.error.message;
      }
    );
  }

  private getUnassignedEditorsList(): void {
    this.incidentService.getUnassignedReviewerList(this.OrganisationID, this.incidentGuid).subscribe(
      data => {
        this.DataList = data;
        this.UsersList = this.DataList.filter(x => {
          return x['roles'].includes(
            'document_editor' || (this.incCreatedBy == this.loginUserEmail && this.loginUserEmail == x['email'])
          );
        });
        this.UsersList.forEach(user => {
          user['FullName'] = user['first_name'] + ' ' + user['last_name'] + '(' + user['email'] + ')';
        });
        this.UsersList.sort((a, b) => a['FullName'].localeCompare(b['FullName']));
      },
      error => {
        this.error = error.error.message;
      }
    );
  }

  openRevokeEditorForm(incidentGuid: string, guid: string, user: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Revoke an Editor',
        message: 'Are you sure you want to remove ' + user + ' from editors.',
        modalType: 'Revoke Editor',
        guid: guid,
        incidentGuid: incidentGuid
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        this.getEditorList();
        this.getUnassignedEditorsList();
        if (result) {
          this.translate.get('Revoke_Editor.revoke_editor_toaster_message.revoke_editor_submitted').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }
  onDateSelection(date: any) {
    if (date) {
      let myDate = new Date(date.year, date.month - 1, date.day + 1);
      this.store_due_date = myDate.toISOString().split('T')[0];
    } else {
      this.store_due_date = this.store_due_date;
    }
  }

  save() {
    let editor: any = [];
    let oldeditors: any = [];

    this.submitted = true;
    if ((this.selectedItems.length > 0 || this.selectedOldEditor.length > 0) && this.InviteEditorForm.valid) {
      editor = this.selectedItems.map((item: any) => {
        return item.guid;
      });
      oldeditors = this.selectedOldEditor.map((item: any) => {
        return item.guid;
      });
      this.editorlength = oldeditors.length;
      this.checkEditorSelection();
      this.isLoading = true;
      let editorData = this.InviteEditorForm.value;
      editorData['organization_users'] = editor;
      editorData['role'] = 'reviewer';
      editorData['organization_users'] = [...editorData['organization_users'], ...oldeditors];
      editorData['due_date'] = this.store_due_date;
      this.submitInviteEditor(editorData);
    } else {
      this.checkEditorSelection();
      return;
    }
  }

  submitInviteEditor(editorData: any): void {
    this.incidentService
      .submitIncidentReviewerData(this.OrganisationID, this.incidentGuid, editorData)
      .pipe(
        finalize(() => {
          this.InviteEditorForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.error = {
            due_date: ''
          };
          this.submitted = false;
          this.backend_error = '';
          this.activeModal.close({ isUpdate: true, result: data });
        },
        (error: any) => {
          this.submitted = false;
          this.isLoading = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
  }
  openChangeDueFrom(
    incidentGuid: string,
    guid: string,
    incCreatedBy: string,
    user: string,
    comment: string,
    due_date: any
  ) {
    this.ChangeDuedateModal(incidentGuid, guid, incCreatedBy, user, comment, due_date);
  }
  ChangeDuedateModal(
    incidentGuid: string,
    guid: string,
    incCreatedBy: string,
    user: string,
    comment: string,
    due_date: any
  ): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Edit Collaboration',
        modalType: 'Change Due Date for invite editor',
        incidentGuid: incidentGuid,
        incCreatedBy: incCreatedBy,
        guid: guid,
        user: user,
        comment: comment,
        due_date: due_date
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getEditorList();
          this.translate.get('Change_due_date_toaster_message.Due_date_changed').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }
}
