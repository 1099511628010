<div>
  <div class="page-header text-center">
    <div class=" mt-2 mx-2">
      Manage Documents
    </div>
  </div>
  <hr />
  <div class="modal-header justify-content-between d-flex h-70 align-items-center border-0">
    <div class="search-people position-relative">
      <div class="input-group w-330">
        <input
          id="search"
          [(ngModel)]="searchText"
          class="form-control cb-input"
          placeholder="Search"
          name="search"
          (input)="searchDocuments($event?.target?.value)"
          type="text"
        />
      </div>
      <i class="fa fa-search search-icon"></i>
    </div>
    <div>
      <button
        class="btn-save ml-4 w-100"
        [disabled]="!isChanged"
        [ngStyle]="{
          'background-color': !isChanged ? 'rgba(0,0,0,.26)' : '#4db7c6'
        }"
        (click)="saveDocuments()"
        [ngClass]="{ 'btn-save': true }"
        translate
      >
        Save Group
      </button>
    </div>
  </div>
  <div class="modal-body pt-0 pb-0">
    <div class="row">
      <div class="col search-results" infiniteScroll [scrollWindow]="false">
        <div class="table_wrapper mt-0 mx-1">
          <table class="w-100p table-2">
            <thead class="bg-yellow-2">
              <tr class="border-0">
                <td>
                  <h4 class="font-weight-bold text-black-2 p-3 mt-2" translate>
                    Title
                  </h4>
                </td>
                <td>
                  <h4 class="font-weight-bold text-black-2 p-3 mt-2" translate>
                    Type
                  </h4>
                </td>
                <td>
                  <h4
                    (click)="activeModal.close({ result: true })"
                    class="font-weight-bold text-black-2 p-3 mt-2 text-right"
                    translate
                  >
                    Close <i class="fa fa-times"></i>
                  </h4>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let document of publishedDocuments; let i = index" [attr.data-index]="i">
                <td class="ml-3" style="max-width: 230px;">
                  <div class="pl-2 d-flex" [ngbTooltip]="document['title']" tooltipClass="cb-tooltip">
                    <input
                      mdbCheckbox
                      type="checkbox"
                      style="margin-right: 2px;"
                      [value]="document.guid"
                      (change)="selectDocument(document)"
                      [checked]="document.checked"
                      [(ngModel)]="document.checked"
                    />
                    <span class="text-truncate">
                      {{ document['title'] }}
                    </span>
                  </div>
                </td>
                <td>
                  <span class="m-0 p-3">{{ document['document_type'] }}</span>
                </td>

                <td class="p-3 text-center cursor-pointer">
                  <i
                    class="fa fa-spinner fa-spin ml-4"
                    style="font-size: 16px; color: #673ab7;"
                    *ngIf="isViewLoading && clickedRow === document.guid; else other_content"
                  ></i>
                  <ng-template #other_content>
                    <a
                      class="ml-4"
                      style="color: #673ab7;"
                      target="_blank"
                      (click)="viewDocument(document.document_version, document.guid, document.doc_store_identifier)"
                      >View</a
                    >
                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <app-spinner *ngIf="isLoading"></app-spinner>
</div>
