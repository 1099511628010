import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbDateStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { SettingsService } from '@app/settings/settings.service';
import { finalize } from 'rxjs/operators';
import { UserService } from '@app/core/user.service';
import { ModalService } from '@app/core/modal/modal.service';
import { CustomDialogComponent } from '../customDialog/customDialog.component';
import { take } from 'rxjs/operators';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { TranslateService } from '@ngx-translate/core';
import stringInfo from '../../../../translations/en-US.json';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
// import { MatCheckbox, MatCheckboxChange } from '@angular/material';
// import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-change-req-for-invite-editors',
  templateUrl: './change-req-for-invite-editors.component.html',
  styleUrls: ['./change-req-for-invite-editors.component.scss']
})
export class ChangeReqForInviteEditorsComponent implements OnInit {
  minDate: NgbDateStruct;
  InviteEditorForm: FormGroup;
  submitted = false;
  isLoading = false;
  success: boolean;
  OrganisationID: string | null;
  guidSubscription: any;
  dropdownList: any = [];
  selectedItems: any = [];
  selectedOldEditor: any = [];
  dropdownSettingsForOldUsers = {};
  dropdownSettingsForNewUsers = {};
  error: any;
  statusData: any;

  OldEditorSelected = true;
  EditorSelected = true;
  EditorList: object[];
  userList: object[];
  DataList: object[];
  loginUser: any;
  loginUsername: string;
  loginUserEmail: string;
  checked = false;
  OldUsersList: object[];
  loading = true;
  date = stringInfo.Document_Invite_Editor.Date;
  store_due_date = '';
  public emailList: any = [];
  @Input() formType: string;
  @Input() DocumentGuid: string;
  @Input() title_tag: string;
  @Input() DocCreatedBy: string;
  @Input() docTitle: string;
  @Input() modalType: string;

  oldEditorListGuid: any;
  editorlength: any;
  backend_error: any;
  status8: string;
  status12: string;
  isEmailvalid = false;
  searchString = '';

  @ViewChild('multiSelectDropdown') multiSelectDropdown: any;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private documentsService: DocumentsService,
    private userservice: UserService,
    private modalService: ModalService,
    private toaster: ToasterService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.loginUser = this.userservice.getuserdetails();
    this.loginUsername = this.loginUser.user['username'];
    this.loginUserEmail = this.loginUser.user['email'];

    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.minDate = this.documentsService.getTomorrowDate();
      this.getInviteEditorChangeReqForm();
      this.getEditorList();
      //  this.getEditorData();
      this.getUnassignedEditorsList();
      this.loading = false;
    });

    this.dropdownSettingsForNewUsers = {
      singleSelection: false,
      idField: 'guid',
      textField: 'FullName',
      itemsShowLimit: 10,
      enableCheckAll: false,
      allowSearchFilter: true,
      allowRemoteDataSearch: this.modalType == 'Form Change Request' ? true : false,
      closeDropDownOnSelection: false,
      searchPlaceholderText: this.modalType == 'Form Change Request' ? 'Search or add responder' : 'Search'
    };

    this.dropdownSettingsForOldUsers = {
      singleSelection: false,
      idField: 'org_user_guid',
      textField: 'FullName',
      itemsShowLimit: 10,
      enableCheckAll: false,
      allowSearchFilter: true
    };
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  onItemSelect(item: any) {
    this.checkEditorSelection();
  }

  onItemDeSelect(items: any) {
    this.checkEditorSelection();
  }

  checkEditorSelection() {
    this.selectedItems.length > 0 ? (this.EditorSelected = true) : (this.EditorSelected = false);
  }
  onOldEditorSelect(item: any) {
    this.checkOldEditorSelection();
  }

  onOldEditorDeSelect(items: any) {
    this.checkOldEditorSelection();
  }
  checkOldEditorSelection() {
    this.selectedOldEditor.length > 0 ? (this.OldEditorSelected = true) : (this.OldEditorSelected = false);
  }

  getInviteEditorChangeReqForm() {
    this.InviteEditorForm = this.formBuilder.group({
      due_date: ['', [Validators.required(), Validators.DateIsAfterToday()]],
      comment: ['', [Validators.maxLength(4096)]]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.InviteEditorForm.controls;
  }

  private getEditorList(): void {
    let apiToCall: any = {
      'Invite Editors for Request Change': 'getDocumentEditorList',
      'Form Change Request': 'getFormResponderList'
    };
    this.documentsService[apiToCall[this.modalType]](this.OrganisationID, this.DocumentGuid).subscribe(
      (data: any) => {
        this.OldUsersList = data;
        if (this.OldUsersList) {
          this.OldUsersList.forEach(user => {
            // var initials = user['user']['name'].match(/\b(\w)/g);
            // user['initials'] = initials.join('').toUpperCase();
            user['initials'] = user['user']['name']
              ? user['user']['name']
                  .match(/\b(\w)/g)
                  .join('')
                  .toUpperCase()
              : user['user']['email'].charAt(0).toUpperCase();
          });
        }
        this.OldUsersList.forEach(user => {
          user['FullName'] = user['user']['name'] + '(' + user['user']['email'] + ')';
          if (this.modalType == 'Form Change Request') {
            user['org_user_guid'] = user['user']['guid'];
          }
        });
        this.OldUsersList.sort((a, b) => a['FullName'].localeCompare(b['FullName']));
      },

      (error: any) => {
        this.error = error.error.message;
      }
    );
  }

  private getUnassignedEditorsList(): void {
    let apiToCall: any = {
      'Invite Editors for Request Change': 'getListUnassignedEditors',
      'Form Change Request': 'getUnassignedResponderList'
    };
    this.documentsService[apiToCall[this.modalType]](this.OrganisationID, this.DocumentGuid).subscribe(
      (data: any) => {
        this.DataList = data;
        if (this.modalType === 'Form Change Request') {
          this.userList = this.DataList.filter(x => {
            return x['roles'].includes(
              'form_responder' || (this.DocCreatedBy == this.loginUserEmail && this.loginUserEmail == x['email'])
            );
          });
        } else {
          this.userList = this.DataList.filter(x => {
            return x['roles'].includes(
              'document_editor' || (this.DocCreatedBy == this.loginUserEmail && this.loginUserEmail == x['email'])
            );
          });
        }
        this.userList.forEach(user => {
          user['FullName'] = user['first_name'] + ' ' + user['last_name'] + '(' + user['email'] + ')';
        });
        this.userList.sort((a, b) => a['FullName'].localeCompare(b['FullName']));
      },
      (error: any) => {
        this.error = error.error.message;
      }
    );
  }

  openRevokeEditorForm(DocumentGuid: string, guid: string, user: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Revoke an Editor',
        message: 'Are you sure you want to remove ' + user + ' from editors.',
        modalType: 'Revoke Editor',
        guid: guid,
        DocumentGuid: DocumentGuid
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        this.getEditorList();
        this.getUnassignedEditorsList();
        if (result) {
          this.documentsService.status8.subscribe(data => {
            this.status8 = data;
          });
          this.changeName(this.status8);
          this.translate.get('Revoke_Editor.revoke_editor_toaster_message.revoke_editor_submitted').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  onDateSelection(date: any) {
    if (date) {
      let myDate = new Date(date.year, date.month - 1, date.day + 1);
      this.store_due_date = myDate.toISOString().split('T')[0];
    } else {
      this.store_due_date = this.store_due_date;
    }
  }

  save() {
    let editor: any = [];
    let oldeditors: any = [];

    this.submitted = true;
    if (!this.store_due_date) return;
    let selectedItemsToSend = {
      selectedEmails: this.selectedItems
        .filter((item: any) => item.guid == item.FullName)
        .map((item: any) => item.guid),
      selectedUsers: this.selectedItems.filter((item: any) => item.guid !== item.FullName).map((item: any) => item.guid)
    };
    if ((this.selectedItems.length > 0 || this.selectedOldEditor.length > 0) && this.InviteEditorForm.valid) {
      editor = selectedItemsToSend.selectedUsers;
      oldeditors = this.selectedOldEditor.map((item: any) => {
        return item.org_user_guid;
      });
      this.editorlength = oldeditors.length;
      this.checkEditorSelection();
      this.isLoading = true;
      let editorData = this.InviteEditorForm.value;
      editorData['organization_users'] = editor;
      editorData['organization_old_editors'] = oldeditors;
      editorData['role'] = this.modalType == 'Form Change Request' ? 'responder' : 'editor';
      editorData['organization_users'] = [
        ...editorData['organization_users'],
        ...editorData['organization_old_editors']
      ];
      editorData['due_date'] = this.store_due_date;
      if (this.modalType !== 'Invite Editors for Request Change') {
        editorData['newly_added_users'] = selectedItemsToSend.selectedEmails;
      }
      this.submitInviteEditor(editorData);
    } else {
      this.checkEditorSelection();
      return;
    }
  }
  changeName(statusData: any) {
    this.documentsService.setStatus(statusData);
  }

  submitInviteEditor(editorData: any): void {
    let apiToCall: any = {
      'Invite Editors for Request Change': 'submitInviteDocumentEditorData',
      'Form Change Request': 'submitFormReviewerData'
    };
    this.documentsService[apiToCall[this.modalType]](this.OrganisationID, this.DocumentGuid, editorData)
      .pipe(
        finalize(() => {
          this.InviteEditorForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.error = {
            due_date: ''
          };
          this.backend_error = '';
          this.submitted = false;
          this.activeModal.close({ isUpdate: true, result: data });
        },
        (error: any) => {
          this.isLoading = false;
          this.submitted = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
  }

  openChangeDueFrom(
    DocumentGuid: string,
    guid: string,
    DocCreatedBy: string,
    user: string,
    comment: string,
    due_date: any
  ) {
    this.ChangeDuedateModal(DocumentGuid, guid, DocCreatedBy, user, comment, due_date);
  }
  ChangeDuedateModal(
    DocumentGuid: string,
    guid: string,
    DocCreatedBy: string,
    user: string,
    comment: string,
    due_date: any
  ): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Edit Collaboration',
        modalType: 'Change Due Date for invite editor',
        DocumentGuid: DocumentGuid,
        DocCreatedBy: DocCreatedBy,
        guid: guid,
        user: user,
        module: 'Document',
        comment: comment,
        due_date: due_date
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getEditorList();
          this.documentsService.status12.subscribe(data => {
            this.status12 = data;
          });
          this.changeName(this.status12);
          this.translate.get('Change_due_date_toaster_message.Due_date_changed').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  private validateEmail(email: any) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  filterChange(searchString: any) {
    this.searchString = searchString;
    if (this.modalType == 'Invite Editors for Request Change') return;
    let result = this.selectedItems.filter((obj: any) =>
      Object.values(obj).some(val => val.toString().includes(searchString))
    );

    if (
      searchString.slice(-1) == ',' &&
      this.validateEmail(searchString.slice(0, -1)) &&
      !this.checkEmailExistence(searchString.slice(0, -1))
    ) {
      this.addResponder(searchString.slice(0, -1));
      this.searchString = '';
      this.isEmailvalid = false;
      this.clearSearchInput();
    }
    if (this.validateEmail(searchString) && !result.length) {
      this.isEmailvalid = true;
      this.searchString = searchString;
    } else {
      this.isEmailvalid = false;
    }
  }
  addResponder(email: any): void {
    this.userList = [
      ...this.userList,
      {
        FullName: email,
        guid: email,
        email: email
      }
    ];
    console.log(this.userList);
    this.selectedItems = [
      ...this.selectedItems,
      {
        FullName: email,
        guid: email,
        email: email
      }
    ];
    console.log(this.selectedItems);

    this.emailList = [
      ...this.emailList,
      {
        FullName: email,
        guid: email,
        email: email
      }
    ];
    console.log(this.emailList);

    this.isEmailvalid = false;
  }
  closeResponderDropdown(event: any) {
    this.searchString = '';
    this.isEmailvalid = false;
  }
  clearSearchInput() {
    this.multiSelectDropdown.filter.text = '';
  }
  checkEmailExistence(email: string): boolean {
    return (
      this.userList.some((item: any) => item?.email?.toLowerCase() == email?.toLowerCase()) ||
      this.OldUsersList.some((item: any) => item?.user?.email?.toLowerCase() == email?.toLowerCase())
    );
  }
}
