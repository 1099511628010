import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OrganisationService } from '@app/core/organisation.service';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { IncidentService } from '@app/Organisation-admin/incident/incident.service';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-link-an-issue',
  templateUrl: './link-an-issue.component.html',
  styleUrls: ['./link-an-issue.component.scss']
})
export class LinkAnIssueComponent implements OnInit {
  LinkIssueForm: FormGroup;
  submitted = false;
  issueSelected = true;
  error_message: any;
  selectedItems: any = [];
  isLoading = false;
  selectedIssueType: any;
  OrganisationID: string | null;
  guidSubscription: any;
  jira_issue_type: any;
  issueType: boolean;
  selectedIssue: any;
  jiraLoading = true;
  showSaveButton = true;
  hideForm = true;
  success: boolean;
  error: any;
  showData = false;
  linkIssueData: any;
  jiraIdData: any;
  jiraid: any;
  form_error: any;
  Link_jira_ticket_Submit: any;
  issueTypeOption = [
    { id: 'Task', name: 'Task' },
    { id: 'Bug', name: 'Bug' },
    { id: 'Story', name: 'Story' }
  ];
  @Input() formType: string;
  @Input() title_tag: string;
  @Input() incidentGuid: string;
  @Input() incidentTitle: string;
  backend_error: any;

  constructor(
    private backendErrorService: BackendErrorService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private orgService: OrganisationService,
    private incidentService: IncidentService,
    private translate: TranslateService,
    private toaster: ToasterService
  ) {}

  ngOnInit(): void {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.selectedIssueType = 'select_issue_type';
      this.LinkIssueForm = this.formBuilder.group({
        jira_id: ['', [Validators.required()]]
      });
      this.Link_jira_ticket_Submit = 'Fetch Details';
      // this.getJiraTicket();
    });
  }

  getLinkIssueForm() {
    this.LinkIssueForm = this.formBuilder.group({
      jira_id: [this.jiraid, [Validators.required()]],
      summary: [this.jiraIdData ? (this.jiraIdData['summary'] ? this.jiraIdData['summary'] : '') : ''],
      issue_type: [this.jiraIdData ? (this.jiraIdData['issue_type'] ? this.jiraIdData['issue_type'] : '') : ''],
      description: [this.jiraIdData ? (this.jiraIdData['description'] ? this.jiraIdData['description'] : '') : ''],
      project_name: [this.jiraIdData ? (this.jiraIdData['project_name'] ? this.jiraIdData['project_name'] : '') : ''],
      project_tag: [this.jiraIdData ? (this.jiraIdData['project_tag'] ? this.jiraIdData['project_tag'] : '') : '']
    });
  }

  issueTypeChange(option: any) {
    this.selectedIssueType = option;
  }
  onReset() {
    this.submitted = false;
    this.LinkIssueForm.reset();
    this.selectedIssueType = '';
  }
  JiraId(id: any) {
    this.jiraid = id.target.value;
    if (id.type == 'change' && this.backend_error) {
      this.backend_error = '';
    }
    // this.getData()
  }
  changeJiraid(evt: any) {
    if (evt.type == 'input') this.showSaveButton = true;
    this.showData = false;
    this.Link_jira_ticket_Submit = 'Fetch Details';
  }
  getData() {
    this.submitted = true;
    if (this.LinkIssueForm.valid) {
      this.isLoading = true;

      this.incidentService.getJiraIdData(this.OrganisationID, this.incidentGuid, this.jiraid).subscribe(
        data => {
          this.jiraIdData = data;
          this.Link_jira_ticket_Submit = 'Link';
          this.showData = true;
          this.getLinkIssueForm();
          this.showSaveButton = false;
          this.success = true;
          this.error = {
            jira_id: '',
            summary: '',
            description: '',
            assignee: ''
          };
          this.submitted = false;
          this.isLoading = false;
        },
        (error: any) => {
          this.isLoading = false;
          this.submitted = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
    }
  }
  save() {
    this.submitted = true;
    if (this.LinkIssueForm.valid) {
      this.isLoading = true;
      const issue_data = Object.assign({}, this.LinkIssueForm.value);
      this.error_message = '';
      this.linkAnIssue(issue_data);
    }
  }
  linkAnIssue(issue_data: any) {
    this.incidentService
      .linkJiraTicket(this.OrganisationID, this.incidentGuid, issue_data)
      .pipe()
      .subscribe(
        (data: any) => {
          this.linkIssueData = data;
          this.showData = true;
          this.getLinkIssueForm();
          this.showSaveButton = false;

          this.success = true;
          this.error = {
            jira_id: '',
            summary: '',
            description: '',
            assignee: ''
          };
          this.submitted = false;
          this.isLoading = false;
          this.backend_error = '';
          this.translate.get('Link_jira_ticket.issue_linked').subscribe(res => {
            this.toaster.showSuccess(res);
          });
          this.activeModal.close(true);
        },
        (error: any) => {
          this.submitted = false;
          this.isLoading = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
  }
}
