import { Component, OnInit } from '@angular/core';
import { SettingsService } from '@app/settings/settings.service';
import { OrganisationService } from '@app/core/organisation.service';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { UserService } from '@app/core/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@app/core/modal/modal.service';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-cb-agent',
  templateUrl: './cb-agent.component.html',
  styleUrls: ['./cb-agent.component.scss']
})
export class CbAgentComponent implements OnInit {
  isSystemAdmin = false;
  loading = true;
  currentAgent: string;
  OrganisationID: string | null;
  error: any;
  selectedCbAgent = {
    environment: '',
    node_id: ''
  };
  cbAgentShowData: any;
  guidSubscription: any;
  agentSubscription: any;
  actionCommand: any = [];
  actionCommandPagination: any = [];
  showActionTable = false;
  page = 1;
  pageSize = 10;
  actionCommandResponse: {};
  actionCommandData: {};
  actionCommandResponseLabel: '';
  actionCommandResponseData: '';
  currentAction: string;
  isProdAgent: boolean;
  showUndraw = false;
  parentTypeMessage = 'no-administrator-added';
  showCbAgentUpdateWarning = false;
  scrollLoader = false;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  pagnationData: any;
  node_id: string;
  paginatedDataUrl: any;

  constructor(
    private settingsService: SettingsService,
    private orgService: OrganisationService,
    private toastr: ToasterService,
    private userService: UserService,
    private modalService: NgbModal,
    private translate: TranslateService,
    private modalServiceConfirm: ModalService
  ) {}

  ngOnInit() {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.checkUserROle();
    });
    this.getCbAgentData();
  }

  onScrollUp() {
    this.scrollLoader = false;
  }

  onScrollData() {
    this.scrollLoader = true;
    this.settingsService.getActionCommands(this.OrganisationID, this.node_id, (this.page += 1)).subscribe(
      data => {
        this.scrollLoader = false;
        this.paginatedDataUrl = data;
        this.pagnationData = data['results'];
        this.actionCommand = this.actionCommand.concat(data['results']);
      },
      error => {
        this.scrollLoader = false;
        this.error = error.error.message;
      }
    );
  }
  onScroll() {
    if (this.paginatedDataUrl?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }

  private checkDeregisterUpdate() {
    if (this.OrganisationID && this.selectedCbAgent['node_id']) {
      this.settingsService
        .checkDeregisterUpdate(this.OrganisationID, this.selectedCbAgent['node_id'])
        .subscribe(data => {
          if (data && data['update_required']) {
            this.showCbAgentUpdateWarning = true;
          } else {
            this.showCbAgentUpdateWarning = false;
          }
        });
    }
  }

  deRegister() {
    if (this.OrganisationID && this.selectedCbAgent['node_id']) {
      this.settingsService.getDeregister(this.OrganisationID, this.selectedCbAgent['node_id']).subscribe(
        data => {
          if (data && data['Status']) {
            this.toastrMessage(data['Status'], 'success');
          } else {
            this.toastrMessage('Unregister failed!! Please Contact Support', 'error');
          }
        },
        error => {
          this.toastrMessage('Unregister failed!! Please Contact Support', 'error');
        }
      );
    }
  }

  private checkUserROle() {
    if (this.userService.getUserRole() === 'system_admin_role') {
      this.isSystemAdmin = true;
    }
  }

  cbAgentEnvironmentChange() {
    const updatAgentData = this.cbAgentShowData.find((activeAgent: any) => {
      if (!activeAgent.active) {
        return activeAgent;
      }
    });
    this.selectedCbAgent['environment'] = updatAgentData['environment'];
    this.selectedCbAgent['node_id'] = updatAgentData['node_id'];
    this.updateCbAgent();
  }

  private getCbAgentData(): void {
    this.settingsService.getCbAgent(this.OrganisationID).subscribe(
      data => {
        this.loading = false;
        this.paginatedDataUrl = data;
        this.cbAgentShowData = data ? data : '';
        if (this.cbAgentShowData && this.cbAgentShowData !== undefined && Array.isArray(this.cbAgentShowData)) {
          this.updateCurrentAgent();
          this.checkDeregisterUpdate();
        }
      },
      error => {
        this.error = error.error.message;
        this.loading = false;
        this.toastrMessage('Failed to load CBAgent!! Please Contact Support', 'error');
      }
    );
  }

  private updateCurrentAgent() {
    const getAgentData = this.cbAgentShowData.find((activeAgent: any) => {
      if (activeAgent.active) {
        return activeAgent;
      }
    });
    this.selectedCbAgent['environment'] = getAgentData['environment'];
    this.selectedCbAgent['node_id'] = getAgentData['node_id'];
    this.isProdAgent = this.selectedCbAgent['environment'] === 'production' ? true : false;
    this.checkDeregisterUpdate();
    this.settingsService.sendcbEnvChange(true);
  }

  public updateCbAgent() {
    const payload = {
      node_id: this.selectedCbAgent['node_id']
    };
    if (
      payload.node_id !== '' &&
      payload.node_id !== undefined &&
      this.OrganisationID !== '' &&
      this.OrganisationID !== undefined
    ) {
      this.settingsService.updateCbAgentEnvironment(this.OrganisationID, payload).subscribe(
        updateResult => {
          if (updateResult.length > 0) {
            this.cbAgentShowData.length = 0;
            this.cbAgentShowData = updateResult;
            this.updateCurrentAgent();
            this.toastrMessage('Agent successfully updated', 'success');
          } else {
            this.toastrMessage('Update failed!! Please Contact Support', 'error');
            this.updateCurrentAgent();
          }
        },
        error => {
          this.error = error.error.message;
          this.toastrMessage('Update failed!! Please Contact Support', 'error');
          this.updateCurrentAgent();
        }
      );
    }
  }

  private toastrMessage(shortDesc: string, type: string) {
    if (type === 'success') {
      this.translate.get(shortDesc).subscribe(res => {
        this.toastr.showSuccess(res);
      });
      return;
    }
    this.translate.get(shortDesc).subscribe(res => {
      this.toastr.showError(res);
    });
  }

  public updateCbCode(node_id: string) {
    if (node_id && node_id !== '' && node_id !== undefined) {
      const payload = {
        node_id: node_id
      };
      this.settingsService.updateCbAgentCode(payload).subscribe(
        result => {
          // TODO handle 200 request
          // if(result) {
          this.toastrMessage('Code Update Process initialized', 'success');
          // }
        },
        error => {
          this.toastrMessage('Error in Code Update!!! Please Contact Support', 'error');
        }
      );
    } else {
      this.toastrMessage('Node ID is not present!!! Please Contact Support', 'error');
    }
  }

  public getActionCommands(env: string, node_id: string) {
    this.currentAction = env;
    this.node_id = node_id;
    this.settingsService.getActionCommands(this.OrganisationID, node_id, (this.page = 1)).subscribe(
      response => {
        if (response && response !== null && response !== undefined && response['results'].length > 0) {
          this.actionCommand.length = 0;
          this.actionCommand = response['results'];
          this.paginatedDataUrl = response;
          this.refreshAction();
          this.showActionTable = true;
          this.showUndraw = false;
        } else {
          this.showUndraw = true;
        }
      },
      error => {
        this.error = error.error.message;
        this.toastrMessage('No Action Command Data!', 'error');
      }
    );
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  refreshAction() {
    this.actionCommandPagination = this.actionCommand
      .map((action: any, i: any) => ({ id: i + 1, ...action }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  closeActionPallet() {
    this.showActionTable = false;
    this.currentAction = '';
  }

  openActionModal(content: any, data: any, response: string) {
    this.modalService.open(content, { size: 'lg' });
    if (data && data !== null && data !== '' && Object.keys(data).length !== 0) {
      this.actionCommandData = data;
    } else {
      this.actionCommandData = '';
    }
    if (response && response !== null && response !== '' && Object.keys(response).length !== 0) {
      this.actionCommandResponse = response;
    } else {
      this.actionCommandResponse = '';
    }
  }

  // use this as a common for dialog box. Specify the type here. Need to change to switch case for more types
  openConfirm(type: string, event?: any) {
    if (type === 'switch') {
      let environment = this.selectedCbAgent['environment'] === 'production' ? 'staging' : 'production';
      this.modalServiceConfirm
        .confirm('Are you sure you want to switch the CBAgent environment to ', 'Switch CB Agent', environment + '?')
        .pipe(take(1))
        .subscribe((confirmed: any) => {
          if (confirmed) {
            this.cbAgentEnvironmentChange();
          } else {
            if (event) {
              event.source.checked = this.isProdAgent;
            }
          }
        });
    } else if (type === 'unregister') {
      this.modalServiceConfirm
        .confirm('Are you sure you want to unregister CBAgent ?', 'Unregister CB Agent')
        .pipe(take(1))
        .subscribe((confirmed: any) => {
          if (confirmed) {
            this.deRegister();
          }
        });
    }
  }
}
