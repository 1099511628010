import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { DemoMaterialModule } from '../../materials.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PublicShellHeaderComponent } from '@app/shells/publicShell/header/publicShellHeader.component';
import { PublicShellComponent } from '@app/shells/publicShell/publicShell.component';

@NgModule({
  imports: [CommonModule, TranslateModule, RouterModule, FlexLayoutModule, DemoMaterialModule],
  declarations: [PublicShellHeaderComponent, PublicShellComponent]
})
export class PublicShellModule {}
