<app-spinner *ngIf="isScreenSpinnerLoading"></app-spinner>
<ng-container *ngIf="!isScreenSpinnerLoading">
  <div class="disconnect-integration-container">
    <div class="disconnect-integration-label">
      <div class="label">{{ title_tag }}</div>
      <div>
        <i
          class="fa fa-times text-black-1"
          style="width: 12px;
                  height: 12px;
                  right: 1em;
                  cursor: pointer;
                  position: absolute;
                  top: 14px;"
          (click)="activeModal.close({ isUpdate: false })"
          ngbAutoFocus
          translate
        ></i>
      </div>
    </div>
    <div class="text-body">
      <div class="text">{{ message }}</div>
    </div>
    <div class="actions-container">
      <button type="button" class="btn-cancel" (click)="activeModal.close({ isUpdate: false })">Cancel</button>
      <button type="submit" (click)="connectDisconectAccount()" class="btn-save" *ngIf="!isLoading">
        Yes, Disconnect
      </button>
      <button type="submit" class="btn-save" disabled *ngIf="isLoading"><i class="fa fa-spinner fa-spin"></i></button>
    </div>
  </div>
</ng-container>
