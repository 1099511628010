<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div class="row">
    <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
      <h2 class="text-cyan m-0" translate>People</h2>
      <p class="success-msg" *ngIf="success" translate>
        organisation_people.Form is updated successfully.
      </p>
      <p class="error-danger" *ngIf="apiError" translate>
        organisation_people.*some_error_message_display
      </p>
      <div class="button-container">
        <div class="search-people position-relative input-group w-330">
          <input
            id="search"
            [(ngModel)]="searchText"
            class="form-control cb-input"
            placeholder="Search"
            name="search"
            type="text"
            (keyup)="updateSearchText($event)"
            (keyDown.enter)="Search(searchText)"
            #titleInput
          />
          <i (click)="Search(titleInput.value)" class="fa fa-search search-icon"></i>
        </div>
        <select
          class="cb-input-1 w-140"
          (keyup)="updateStatus($event)"
          (change)="onStatusChange($event.target.value)"
          *ngIf="!status_loading"
        >
          <option selected>
            Active
          </option>
          <option>
            InActive
          </option>
        </select>
        <button class="btn-add ml-4" (click)="openPeopleForm('add')" translate>
          <i class="fa fa-plus"></i>organisation_people.Add people
        </button>
      </div>
    </div>
  </div>
  <div class="row " *ngIf="peopleList.length > 0; else other_content">
    <div class="table_wrapper my-5 mx-3">
      <table class="w-100p table-2">
        <thead class="bg-yellow-2">
          <tr class="border-0">
            <td><h4 class="font-weight-bold text-black-2 p-3 mt-2" translate>organisation_people.Name</h4></td>
            <td>
              <h4 class="font-weight-bold text-black-2 p-3 mt-2" translate>organisation_people.Phone Number</h4>
            </td>
            <td><h4 class="font-weight-bold text-black-2 p-3 mt-2" translate>organisation_people.Email</h4></td>
            <td colspan="2">
              <h4 style="margin-left: 235px;" class="font-weight-bold text-black-2 p-3 mt-2" translate>
                organisation_people.Actions
              </h4>
            </td>
          </tr>
        </thead>
      </table>
      <div
        class="col search-results"
        infiniteScroll
        [scrollWindow]="false"
        (scrolled)="onScroll()"
        [infiniteScrollDistance]="modalScrollDistance"
        [infiniteScrollThrottle]="modalScrollThrottle"
        (scrolledUp)="onScrollUp()"
      >
        <div class="table-body">
          <table class="w-100p table-2">
            <tbody>
              <tr *ngFor="let people of peopleList">
                <td>
                  <h4 class="text-black-1 p-3 pr-3 w-240">{{ people['first_name'] }} {{ people['last_name'] }}</h4>
                </td>
                <td>
                  <h4 class="text-black-1 p-3 pr-3 ">{{ people['phone'] !== null ? people['phone'] : '-' }}</h4>
                </td>
                <td>
                  <h4 class="text-black-1 p-3">{{ people['email'] !== null ? people['email'] : '-' }}</h4>
                </td>
                <td>
                  <ng-container *ngFor="let role of people['role']; let i = index" [attr.data-index]="i">
                    <span class="text-black-1 text-size-for-role"
                      >{{ people['role'] !== [] ? roles[role] : '-' }}
                      <ng-container *ngIf="people['role'].length !== i + 1">,</ng-container>
                    </span>
                  </ng-container>
                </td>
                <td>
                  <a class="text-black-1 p-3 pr-3 link-1 cursor-pointer" (click)="openPeopleForm('edit', people)"
                    ><u translate>organisation_people.Edit</u></a
                  >
                  <a
                    *ngIf="people['email'] !== userEmail && !people['is_deleted']; else other_ccontent"
                    class="text-black-1 p-3 pr-3 link-1 cursor-pointer"
                    (click)="openConfirm(people['first_name'], people['last_name'], people['guid'])"
                    ><u translate>organisation_people.Remove</u></a
                  >
                  <a
                    *ngIf="people['is_deleted']"
                    class="text-black-1 p-3 pr-3 link-1 cursor-pointer"
                    (click)="addInactivePeopleModal(people)"
                    ><u translate>Add</u></a
                  >
                  <ng-template #other_ccontent>
                    <a *ngIf="people['email'] == userEmail" class="text-black-1 p-3 pr-3 link-1 disabled-remove-people"
                      ><u translate>organisation_people.Remove</u></a
                    >
                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <ng-template #other_content class="no-org-selected">
    <app-no-organisation-selected [typeMessage]="parentTypeMessage"></app-no-organisation-selected>
  </ng-template>
  &nbsp;
  <h2 *ngIf="scrollLoader" class="font-weight-bold text-black-3 text-center">
    <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': scrollLoader }" style="font-size:large;"></i> &nbsp; Loading...
  </h2>
</ng-container>
