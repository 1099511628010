<app-spinner *ngIf="loading"></app-spinner>
<div class="cb-container cb-page-bg">
  <div class="container-fluid pt-3">
    <div class="cloud-header">
      <div class="label" style="font-size: 28px;">Cloud</div>
    </div>
    <div class="filter-container">
      <div class="select-container">
        <div style="width: 210px; " class="d-inline-block mx-2" style="margin-left: 0px !important;">
          <div style="display: grid;font-size: 10px;">
            <label for="title" class="filter-label">Framework</label>
            <mat-form-field appearance="outline" class="cb-input-focus">
              <mat-select
                #frameworkSelect
                [(ngModel)]="selectedFrameworks"
                (ngModelChange)="onFrameworksChange($event)"
                multiple
                placeholder="All Frameworks"
              >
                <mat-option *ngFor="let item of frameworks" [value]="item.guid">{{ item.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div style="width: 210px; " class="d-inline-block mx-2">
          <div style="display: grid;font-size: 10px;">
            <label for="title" class="filter-label">Connector</label>
            <mat-form-field appearance="outline">
              <mat-select
                #connectorSelect
                [(ngModel)]="selectedConnectors"
                (ngModelChange)="onConnectorChange($event)"
                multiple
                placeholder="All Connectors"
              >
                <mat-option *ngFor="let connector of connectors" [value]="connector.key">{{
                  connector.value
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div style="width: 210px; " class="d-inline-block mx-2">
          <div style="display: grid;font-size: 10px;">
            <label for="title" class="filter-label">Account</label>
            <mat-form-field appearance="outline">
              <mat-select
                #accountSelect
                [(ngModel)]="selectedAccounts"
                (ngModelChange)="onAccountChange($event)"
                multiple
                placeholder="All Accounts"
              >
                <mat-option *ngFor="let account of accounts" [value]="account.guid">{{ account.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div style="width: 300px;" class="d-inline-block mx-2">
          <div style="display: grid;font-size: 10px;">
            <label for="title" class="filter-label">Service</label>
            <div class="d-flex">
              <mat-form-field appearance="outline">
                <mat-select
                  #serviceSelect
                  [(ngModel)]="selectedServices"
                  (ngModelChange)="onServicesChange($event)"
                  multiple
                  placeholder="All Services"
                >
                  <mat-option *ngFor="let service of services" [value]="service">{{ service }}</mat-option>
                </mat-select>
              </mat-form-field>
              <div
                *ngIf="
                  selectedFrameworks?.length ||
                  selectedConnectors?.length ||
                  selectedAccounts?.length ||
                  selectedServices?.length
                "
                class="ml-3 d-flex align-item-center"
                (click)="clearFilters()"
                style="cursor: pointer;margin-top: 10px;"
              >
                <span class=" clear-filter">Clear Filters</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- For PDF view Only -->
      <div
        id="pdf-organization-content"
        class="d-inline-block d-flex  justify-content-start"
        style="display: none !important;"
        *ngIf="pdfDownloading"
      >
        <div class="static-texts " style="text-align: left !important; margin-bottom: 20px !important;">
          <span style="font-size: 30px !important; font-weight: 700;color: #1A3555; margin-left: 2.5rem !important;">{{
            orgName
          }}</span>
        </div>

        <div
          class="static-texts"
          style="text-align: center; margin-bottom: 20px !important; margin-left: 30rem !important;"
        >
          <img
            style="height: 60px !important; width: 250px !important;"
            src="../../../assets/images/core/Compliance-Bot.png"
            alt="Compliancebot Logo"
            id="logo"
          />
        </div>
      </div>
      <div
        id="chips-container"
        class="d-inline-block d-flex align-items-center"
        style="display: none !important; "
        *ngIf="pdfDownloading"
      >
        <div class="w-100p mb-5" style="border-bottom:1px solid #CFCFCF !important;">
          <div class="w-100p" style="text-align: center !important; margin-bottom: 10px !important;">
            <h1 style="font-weight: 700; color:#1A3555 ;">Cloud Dashboard</h1>
          </div>
          <div
            style="text-align: left;"
            *ngIf="
              filters?.frameworks?.length ||
                filters?.connectors?.length ||
                filters?.accounts?.length ||
                filters?.services?.length;
              h
            "
          >
            <span class="pt-2 pb-2 mb-1 pl-5 pr-5 d-block font-weight-bold">APPLIED FILTERS</span>
            <div class="d-flex pt-2 pb-2 pl-5 pr-5">
              <div *ngIf="filters?.frameworks?.length" style="width: 25%;">
                <label style="display: block; font-weight: bold;">Framework</label>
                <span *ngFor="let item of selectedFrameworks.slice(0, 3)" style="display: block;">{{
                  getFramework(item)
                }}</span>
              </div>
              <div
                *ngIf="filters?.connectors?.length"
                style="width: 25%; padding-left: 15px; padding-bottom: 5px;"
                [ngStyle]="{ 'border-left': filters?.frameworks?.length ? '1px solid #DEE2E6' : 'none' }"
              >
                <label style="display: block; font-weight: bold;">Connector</label>
                <span *ngFor="let item of selectedConnectors.slice(0, 3)" style="display: block;">{{
                  getConnector(item)
                }}</span>
              </div>
              <div
                *ngIf="filters?.accounts?.length"
                style="width: 25%; padding-left: 15px; padding-bottom: 5px;"
                [ngStyle]="{
                  'border-left':
                    filters?.frameworks?.length || filters?.connectors?.length ? '1px solid #DEE2E6' : 'none'
                }"
              >
                <label style="display: block; font-weight: bold;">Account</label>
                <span *ngFor="let item of selectedAccounts.slice(0, 3)" style="display: block;">{{
                  getAccount(item)
                }}</span>
              </div>
              <div
                *ngIf="filters?.services?.length"
                style="width: 25%; padding-left: 15px; padding-bottom: 5px;"
                [ngStyle]="{
                  'border-left':
                    filters?.frameworks?.length || filters?.connectors?.length || filters?.accounts?.length
                      ? '1px solid #DEE2E6'
                      : 'none'
                }"
              >
                <label style="display: block; font-weight: bold;">Service</label>
                <span *ngFor="let item of selectedServices.slice(0, 3)" style="display: block;">{{ item }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chips-container">
        <ng-container *ngFor="let key of getFilteredKeys(); let i = index">
          <div [ngSwitch]="key">
            <div *ngSwitchCase="'frameworks'">
              <div
                class="filters-selected-status d-flex"
                (click)="$event.stopPropagation(); openMatSelect('frameworkSelect')"
                *ngIf="filters?.frameworks?.length"
              >
                <div class="selected-filter-name">
                  Frameworks
                </div>
                <div class="selected-filter-name border-left-0 rounded-0">
                  {{ filters?.frameworks?.length > 1 ? 'includes' : 'is' }}
                </div>
                <div class="selected-filter-name d-flex align-items-center border-left-0 rounded-0">
                  <ng-container *ngIf="selectedFrameworks.length < 4; else truncate">
                    <ng-container *ngFor="let item of selectedFrameworks.slice(0, 3); let i = index">
                      {{ getFramework(item)
                      }}{{ selectedFrameworks.length == 1 ? '' : i <= selectedFrameworks.length - 2 ? ',' : '' }}
                    </ng-container>
                  </ng-container>
                  <ng-template #truncate>
                    <ng-container *ngFor="let item of selectedFrameworks.slice(0, 3); let i = index">
                      {{ getFramework(item) }},
                    </ng-container>
                    <ng-container *ngIf="selectedFrameworks.length > 3">
                      <span [matTooltip]="selectedFrameworks" class="option-count"
                        >+{{ selectedFrameworks.length - 3 }}
                      </span>
                    </ng-container>
                  </ng-template>
                </div>
                <div class="filter-cross border-left-0 rounded-right">
                  <mat-icon
                    style="font-size: 12px;"
                    (click)="$event.preventDefault(); selectedFrameworks = []; removeFrameworks()"
                    >close</mat-icon
                  >
                </div>
              </div>
            </div>
            <div *ngSwitchCase="'connectors'">
              <div
                class="filters-selected-status d-flex"
                (click)="$event.stopPropagation(); openMatSelect('connectorSelect')"
                *ngIf="filters?.connectors?.length"
              >
                <div class="selected-filter-name">
                  Connector
                </div>
                <div class="selected-filter-name border-left-0 rounded-0">
                  {{ filters?.connectors?.length > 1 ? 'includes' : 'is' }}
                </div>
                <div class="selected-filter-name d-flex align-items-center border-left-0 rounded-0">
                  <ng-container *ngIf="selectedConnectors.length < 4; else truncate">
                    <ng-container *ngFor="let item of selectedConnectors.slice(0, 3); let i = index">
                      {{ getConnector(item) }}
                      {{ selectedConnectors.length == 1 ? '' : i <= selectedConnectors.length - 2 ? ',' : '' }}
                    </ng-container>
                  </ng-container>
                  <ng-template #truncate>
                    <ng-container *ngFor="let item of selectedConnectors.slice(0, 3); let i = index">
                      {{ getConnector(item) }},
                    </ng-container>
                    <ng-container *ngIf="selectedConnectors.length > 3">
                      <span [matTooltip]="selectedConnectors" class="option-count"
                        >+{{ selectedConnectors.length - 3 }}
                      </span>
                    </ng-container>
                  </ng-template>
                </div>
                <div class="filter-cross border-left-0 rounded-right">
                  <mat-icon
                    style="font-size: 12px;"
                    (click)="$event.preventDefault(); selectedConnectors = []; removeConnectors()"
                    >close</mat-icon
                  >
                </div>
              </div>
            </div>
            <div *ngSwitchCase="'accounts'">
              <div
                class="filters-selected-status d-flex"
                (click)="$event.stopPropagation(); openMatSelect('accountSelect')"
                *ngIf="filters?.accounts?.length && selectedAccounts.length"
              >
                <div class="selected-filter-name">
                  Account
                </div>
                <div class="selected-filter-name border-left-0 rounded-0">
                  {{ filters?.accounts?.length > 1 ? 'includes' : 'is' }}
                </div>
                <div class="selected-filter-name d-flex align-items-center border-left-0 rounded-0">
                  <ng-container *ngIf="selectedAccounts.length < 4; else truncate">
                    <ng-container *ngFor="let item of selectedAccounts.slice(0, 3); let i = index">
                      {{ getAccount(item)
                      }}{{ selectedAccounts.length == 1 ? '' : i <= selectedAccounts.length - 2 ? ',' : '' }}
                    </ng-container>
                  </ng-container>
                  <ng-template #truncate>
                    <ng-container *ngFor="let item of selectedAccounts.slice(0, 3); let i = index">
                      {{ getAccount(item) }},
                    </ng-container>
                    <ng-container *ngIf="selectedAccounts.length > 3">
                      <span [matTooltip]="selectedAccounts" class="option-count"
                        >+{{ selectedAccounts.length - 3 }}
                      </span>
                    </ng-container>
                  </ng-template>
                </div>
                <div class="filter-cross border-left-0 rounded-right">
                  <mat-icon
                    style="font-size: 12px;"
                    (click)="$event.preventDefault(); selectedAccounts = []; removeAccounts()"
                    >close</mat-icon
                  >
                </div>
              </div>
            </div>
            <div *ngSwitchCase="'services'">
              <div
                class="filters-selected-status d-flex"
                (click)="$event.stopPropagation(); openMatSelect('serviceSelect')"
                *ngIf="filters?.services?.length && selectedServices.length"
              >
                <div class="selected-filter-name">
                  Service
                </div>
                <div class="selected-filter-name border-left-0 rounded-0">
                  {{ filters?.services?.length > 1 ? 'includes' : 'is' }}
                </div>
                <div class="selected-filter-name d-flex align-items-center border-left-0 rounded-0">
                  <ng-container *ngIf="selectedServices.length < 4; else truncate">
                    {{ selectedServices }}
                  </ng-container>
                  <ng-template #truncate>
                    <ng-container *ngFor="let item of selectedServices.slice(0, 3); let i = index">
                      {{ item }},
                    </ng-container>
                    <ng-container *ngIf="selectedServices.length > 3">
                      <span [matTooltip]="selectedServices" class="option-count"
                        >+{{ selectedServices.length - 3 }}
                      </span>
                    </ng-container>
                  </ng-template>
                </div>
                <div class="filter-cross border-left-0 rounded-right">
                  <mat-icon
                    style="font-size: 12px;"
                    (click)="$event.preventDefault(); selectedServices = []; removeServices()"
                    >close</mat-icon
                  >
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="tab-container">
        <ul class="tab-group" style="margin-bottom: 0px !important;">
          <li
            *ngFor="let tab of tabs; index as i"
            class="tab"
            [class.active]="tab.active"
            (click)="changeTab(tab.title)"
          >
            <span [ngClass]="tab.active ? 'active-tab-text' : 'tab-text'">{{ tab.title }}</span>
          </li>
        </ul>
        <div class="tab-content-group">
          <div class="tab-content"></div>
        </div>
      </div>
    </div>

    <div class="status-container" *ngIf="statuses">
      <div class="status-group">
        <div
          class="all-status"
          (click)="activeTab = 'all-status'; setStatus('')"
          [ngClass]="{ 'active-status': activeTab === 'all-status' }"
        >
          <span>All ({{ statuses?.all ? statuses?.all : 0 }})</span>
        </div>
        <div
          class="status-danger"
          (click)="activeTab = 'status-danger'; setStatus('danger')"
          [ngClass]="{ 'active-status': activeTab === 'status-danger' }"
        >
          <span class="danger"
            ><span class="danger-dot"></span>Danger ({{ statuses?.danger ? statuses?.danger : 0 }})</span
          >
        </div>
        <div
          class="status-warning"
          (click)="activeTab = 'status-warning'; setStatus('warning')"
          [ngClass]="{ 'active-status': activeTab === 'status-warning' }"
        >
          <span class="warning"
            ><span class="warning-dot"></span>Warning ({{ statuses?.warning ? statuses?.warning : 0 }})</span
          >
        </div>
        <div
          class="status-compliant"
          (click)="activeTab = 'status-compliant'; setStatus('compliant')"
          [ngClass]="{ 'active-status': activeTab === 'status-compliant' }"
        >
          <span class="compliant"
            ><span class="compliant-dot"></span>Compliant ({{ statuses?.compliant ? statuses?.compliant : 0 }})</span
          >
        </div>
        <div
          class="status-ignored"
          (click)="activeTab = 'status-ignored'; setStatus('ignored')"
          [ngClass]="{ 'active-status': activeTab === 'status-ignored' }"
        >
          <span class="ignored"
            ><span class="ignored-dot"></span>Ignored ({{ statuses?.ignored ? statuses?.ignored : 0 }})</span
          >
        </div>
        <div
          class="status-not-started"
          (click)="activeTab = 'status-not-started'; setStatus('not_started')"
          [ngClass]="{ 'active-status': activeTab === 'status-not-started' }"
        >
          <span class="not-started"
            ><span class="not-started-dot"></span>Not started ({{
              statuses?.not_started ? statuses?.not_started : 0
            }})</span
          >
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center mr-3">
        <!-- <mat-slide-toggle><span>Include Ignored Tests</span></mat-slide-toggle> -->
        <div *ngIf="activeTab == 'all-status'">
          <mat-slide-toggle [(ngModel)]="includeIgnoredTests"
            ><span class="switch-label">Include Ignored Tests</span></mat-slide-toggle
          >
        </div>
        <div class="ml-3">
          <button
            class="button"
            (click)="onExportButtonClick()"
            [disabled]="isExportButtonDisabled()"
            [ngClass]="isExportButtonDisabled() ? 'button-disabled' : 'button'"
          >
            Export
            <svg
              width="16"
              style="margin-top: -3px;margin-left:2px"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.58065 9.53997C2.90133 9.53997 3.16129 9.79993 3.16129 10.1206V12.6452C3.16129 12.7521 3.24794 12.8387 3.35484 12.8387H12.6452C12.7521 12.8387 12.8387 12.7521 12.8387 12.6452V10.1206C12.8387 9.79993 13.0987 9.53997 13.4194 9.53997C13.74 9.53997 14 9.79993 14 10.1206V12.6452C14 13.3934 13.3934 14 12.6452 14H3.35484C2.60658 14 2 13.3934 2 12.6452V10.1206C2 9.79993 2.25996 9.53997 2.58065 9.53997Z"
                fill="#0A4D68"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.23547 2C8.55615 2 8.81611 2.25996 8.81611 2.58065V9.66132L10.6524 7.82506C10.8791 7.5983 11.2468 7.5983 11.4735 7.82506C11.7003 8.05181 11.7003 8.41946 11.4735 8.64621L9.31249 10.8073C8.71767 11.4021 7.75326 11.4021 7.15844 10.8073L4.9974 8.64621C4.77064 8.41946 4.77064 8.05181 4.9974 7.82506C5.22415 7.5983 5.5918 7.5983 5.81855 7.82506L7.65482 9.66132V2.58065C7.65482 2.25996 7.91478 2 8.23547 2Z"
                fill="#0A4D68"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="sub-comp">
      <ng-container *ngIf="selectedTab == 'Tests'">
        <app-test-cloud-service-provider
          [selectedFrameworks]="selectedFrameworks"
          [selectedConnectors]="selectedConnectors"
          [selectedAccounts]="selectedAccounts"
          [selectedResources]="selectedResources"
          [selectedServices]="selectedServices"
          [include_ignored_test]="includeIgnoredTests"
          [status]="activeStatus"
          (setStatus)="setStatuses($event)"
          (csvDataEmitter)="downloadCSV($event)"
          (treeListStatus)="handleTreeListStatus($event)"
          [page]="testPage"
          [include_ignored_test]="includeIgnoredTests"
          [status]="activeStatus"
        ></app-test-cloud-service-provider>
      </ng-container>
      <ng-container *ngIf="selectedTab == 'Resources'">
        <app-resources
          [selectedFrameworks]="selectedFrameworks"
          [selectedConnectors]="selectedConnectors"
          [selectedAccounts]="selectedAccounts"
          [selectedResources]="selectedResources"
          [selectedServices]="selectedServices"
          [include_ignored_test]="includeIgnoredTests"
          [activeStatus]="activeStatus"
          (setStatus)="setStatuses($event)"
          (onClickResourceTitle)="setResourceTitle($event)"
          (csvDataEmitter)="downloadCSV($event)"
          (resourcesListStatus)="handResourcesListStatus($event)"
        >
        </app-resources>
      </ng-container>
      <ng-container *ngIf="selectedTab == 'Dashboard'">
        <div class="w-100p p-3 d-flex justify-content-between align-items-center">
          <div class="update-text">Last updated: {{ latestRunDate }}</div>
          <div class="d-flex align-items-center">
            <div class="">
              <mat-slide-toggle [(ngModel)]="includeIgnoredTests" (change)="getDashboardCharts(); getHistoryChartData()"
                ><span class="switch-label">Include Ignored Tests</span></mat-slide-toggle
              >
            </div>
            <div class="ml-3">
              <button class="button" (click)="downloadDashboard()">
                Export
                <svg
                  width="16"
                  style="margin-top: -3px;margin-left:2px"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.58065 9.53997C2.90133 9.53997 3.16129 9.79993 3.16129 10.1206V12.6452C3.16129 12.7521 3.24794 12.8387 3.35484 12.8387H12.6452C12.7521 12.8387 12.8387 12.7521 12.8387 12.6452V10.1206C12.8387 9.79993 13.0987 9.53997 13.4194 9.53997C13.74 9.53997 14 9.79993 14 10.1206V12.6452C14 13.3934 13.3934 14 12.6452 14H3.35484C2.60658 14 2 13.3934 2 12.6452V10.1206C2 9.79993 2.25996 9.53997 2.58065 9.53997Z"
                    fill="#0A4D68"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.23547 2C8.55615 2 8.81611 2.25996 8.81611 2.58065V9.66132L10.6524 7.82506C10.8791 7.5983 11.2468 7.5983 11.4735 7.82506C11.7003 8.05181 11.7003 8.41946 11.4735 8.64621L9.31249 10.8073C8.71767 11.4021 7.75326 11.4021 7.15844 10.8073L4.9974 8.64621C4.77064 8.41946 4.77064 8.05181 4.9974 7.82506C5.22415 7.5983 5.5918 7.5983 5.81855 7.82506L7.65482 9.66132V2.58065C7.65482 2.25996 7.91478 2 8.23547 2Z"
                    fill="#0A4D68"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="cloudDashboard" id="cloudDashboard">
          <div class="row mt-1 px-3 bg">
            <ng-container *ngFor="let item of tabsData">
              <div
                class="col mx-3 doc-tab document-tab"
                (click)="changeActiveTab(item)"
                *ngIf="(item.code == 'ignored' && includeIgnoredTests) || item.show"
              >
                <div class="doc-tab-title"><span [class]="item.dot"></span> {{ item.title }}</div>
                <div class="doc-tab-number">
                  {{ this.statusCount[item.code] }}
                </div>
              </div>
            </ng-container>
          </div>
          <div class=" mt-3 px-2 d-flex">
            <div class="mx-2 dougnut-chart-container cursor-pointer" (click)="changeTab('Tests')">
              <div class="chart-subtitle">TESTS SUMMARY</div>
              <div class="d-flex">
                <div class="test-summary-doughnut mt-3 position-relative">
                  <canvas id="testsummarychart"></canvas>
                  <div class="position-absolute doughnut-center-percentage">
                    <div class="text-secondary">
                      PASSED
                    </div>
                    <div class="chart-total-percentage">{{ testSummary?.passed_percentage || 0 }}%</div>
                  </div>
                </div>
                <div class="mt-4 ml-3 summary-block">
                  <div class="chart-sub">
                    TOTAL TESTS
                  </div>
                  <div class="chart-total">
                    {{
                      includeIgnoredTests
                        ? (testSummary?.total_tests_sum || 0) + (statusCount?.ignored || 0)
                        : testSummary?.total_tests_sum || 0
                    }}
                  </div>
                  <div class="mt-3">
                    <div class="legends">
                      <span class="compliant-dot"></span> Passed
                      <span class="legends-total"> {{ testSummary.passed_tests_sum }}</span>
                    </div>
                    <div class="legends">
                      <span class="danger-dot"></span> Failed
                      <span class="legends-total">{{ testSummary.failed_tests_sum }}</span>
                    </div>
                    <div *ngIf="includeIgnoredTests" class="legends">
                      <span class="ignored-dot"></span> Ignored
                      <span class="legends-total">{{ statusCount.ignored }}</span>
                    </div>
                    <div class="legends">
                      <span class="error-dot"></span> Error
                      <span class="legends-total">{{ testSummary.error_tests_sum }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class=" mx-2 dougnut2-chart-container cursor-pointer" (click)="changeTab('Resources')">
              <div class="chart-subtitle">RESOURCE SCAN SUMMARY</div>
              <div class="d-flex">
                <div>
                  <div class="test-summary-doughnut mt-3 position-relative">
                    <canvas id="totalresourcechart"></canvas>
                    <div class="position-absolute doughnut-center-percentage-2">
                      <div class="text-secondary">
                        COMPLIANT
                      </div>
                      <div class="chart-total-percentage">{{ this.resourceSummary.compliant_percentage || 0 }}%</div>
                    </div>
                  </div>
                </div>
                <div class="mt-4 ml-3 summary-block">
                  <div class="chart-sub">
                    TOTAL RESOURCES
                  </div>
                  <div class="chart-total">
                    {{ this.resourceSummary.total_resources_sum || 0 }}
                  </div>
                  <div class="mt-3">
                    <div class="legends">
                      <span class="compliant-dot"></span> Passed
                      <span class="legends-total">{{ this.resourceSummary.passed_resources_sum }}</span>
                    </div>
                    <div class="legends">
                      <span class="danger-dot"></span> Failed
                      <span class="legends-total">{{ this.resourceSummary.failed_resources_sum }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class=" mx-2 failure-chart-container">
              <div class="chart-subtitle">FAILURE SEVERITY</div>
              <div class="mt-4 ml-3">
                <div class="chart-sub">
                  TOTAL FAILURES
                </div>
                <div class="chart-total">
                  {{ this.statusCount.total || 0 }}
                </div>
              </div>
              <div class="d-flex">
                <canvas id="failurechart"></canvas>
              </div>
            </div>
          </div>
          <div class="row px-3 mt-3">
            <div class="mx-3 history-chart-container ">
              <div class="d-flex justify-content-between ">
                <div class="chart-subtitle">TEST FAILURES HISTORY OVER TIME</div>
                <div
                  class="d-inline-block mx-2 dynamic-select"
                  style="margin-left: 0px !important;"
                  *ngIf="!pdfDownloading"
                >
                  <div style="display: grid;font-size: 10px;">
                    <mat-form-field appearance="outline" style="width: 130px;">
                      <mat-select [(ngModel)]="daysSelected" (ngModelChange)="onDaysChange()">
                        <mat-option *ngFor="let item of days" [value]="item.value">{{ item.title }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <!-- For PDF view Only -->
                <div
                  class="d-inline-block mx-2"
                  style="margin-left: 0px !important; display: none !important;"
                  *ngIf="pdfDownloading"
                >
                  <div
                    class="d-inline-block mx-2 static-text"
                    style="display: none; font-size: 12px; border: 1px solid #ccc; padding: 5px; width: 80px;border-radius: 5px;
                  text-align: center;"
                  >
                    {{ getSelectedDayTitle() }}
                  </div>
                </div>
              </div>

              <div class="test-history-container mt-4">
                <canvas id="testhistory"></canvas>
              </div>
            </div>
            <div class="px-3 mt-3 d-flex align-items-center">
              <div>Warning</div>
              <hr class="warning-line mx-3" />
              <div>Critical</div>
              <hr class="critical-line mx-3" />
            </div>
          </div>
        </div>
      </ng-container>

      <!-- <router-outlet></router-outlet> -->
    </div>
  </div>
</div>
