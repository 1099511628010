<!-- TODO: Need to use ngFor by using dynamic form creation.-->
<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div class="row">
    <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
      <h2 class="text-cyan m-0" translate>organisation_communications.Communication</h2>
      <div>
        <button class="btn-cancel ml-4 w-100" (click)="onReset()" translate>organisation_communications.Cancel</button>
        <button class="btn-save ml-4 w-100" *ngIf="!isLoading" (click)="save()" translate>
          organisation_communications.Save
        </button>
        <button class="btn-save ml-4 w-100" disabled *ngIf="isLoading">
          <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="row scroll-view" *ngIf="communicationData">
    <div class="col p-32">
      <div class="row">
        <div class="col-12 error-mssg" *ngIf="error['error']">
          <label class="text-danger">*{{ error['error'] }}</label>
        </div>
        <form [formGroup]="communicationForm1" novalidate>
          <div class="col">
            <h4 class="text-black-2 font-weight-bold mb-4" translate>
              organisation_communications.mid_day_reminder.Mid-day Reminder
            </h4>
            <div class="input-group pt-2 mb-4">
              <label class="form-check-label text-black-1 checkbox-2" translate>
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="inlineRadioOptions"
                  id="inlinecheck"
                  formControlName="is_enabled"
                />
                <span class="checkmark"></span>
                organisation_communications.mid_day_reminder.Mid-day reminder is enabled
              </label>
            </div>
            <div class="input-group pr-2 mb-4 d-block">
              <p class="mb-2 d-inline-block" translate>organisation_communications.mid_day_reminder.Reminder Time</p>
              <div class="cb-input cb-time">
                <i class="fa fa-clock"></i>
                <input
                  placeholder="Time"
                  aria-label="Time"
                  class="time_input"
                  [ngxTimepicker]="darkPicker"
                  formControlName="next_run_time"
                  readonly
                />
                <!--              (timeSet)="startTimeSet($event)"-->
                <ngx-material-timepicker
                  class="timepicker"
                  #darkPicker
                  [ngxMaterialTimepickerTheme]="darkTheme"
                ></ngx-material-timepicker>
              </div>
            </div>
            <div class="mb-1">
              <label for="name" class="text-black-1" translate
                >organisation_communications.mid_day_reminder.Reminder Message
                <i
                  class="fas fa-question-circle ml-2 cb-tooltip-btn"
                  [ngbTooltip]="'organisation_communications.mid_day_reminder.tooltip_reminder_mssg' | translate"
                  triggers="hover:blur"
                  tooltipClass="cb-tooltip"
                  placement="bottom"
                ></i>
              </label>
            </div>
            <div class="input-group">
              <textarea
                id="name"
                formControlName="message"
                class="p-4 cb-textarea"
                [placeholder]="
                  'organisation_communications.mid_day_reminder.Here you can set the message for mid-day reminder.'
                    | translate
                "
                name="name"
                [maxLength]="4097"
              >
              </textarea>
              <div class="col-12 error-mssg" *ngIf="(f1.message.touched || submitted) && f1.message.errors">
                <label for="message" class="text-danger" *ngIf="f1.message.errors.required" translate
                  >organisation_communications.*Message is required.</label
                >
              </div>
              <div class="col-12 error-mssg" *ngIf="(f1.message.touched || f1.message.dirty) && f1.message.errors">
                <label for="message" class="text-danger" *ngIf="f1.message.errors.maxLength" translate
                  >organisation_communications.Message must be 4096 characters or less</label
                >
              </div>
              <div class="col-12 error-mssg" *ngIf="error['message']">
                <label for="message" class="text-danger">*{{ error['message'] }}</label>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="row mt-5">
        <form [formGroup]="communicationForm2" novalidate>
          <div class="col">
            <h4 class="text-black-2 font-weight-bold mb-4" translate>
              organisation_communications.daily_status_email.Daily Status Email
            </h4>
            <div class="input-group pt-2 mb-4">
              <label class="form-check-label text-black-1 checkbox-2" translate>
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="inlineRadioOptions"
                  id="inlinecheck1"
                  formControlName="is_enabled"
                />
                <span class="checkmark"></span>
                organisation_communications.daily_status_email.Daily status email is enabled
              </label>
            </div>
            <div class="input-group pr-2 mb-4 d-block">
              <p class="mb-2 d-inline-block" translate>organisation_communications.daily_status_email.Email Time</p>
              <div class="cb-input cb-time">
                <i class="fa fa-clock"></i>
                <input
                  placeholder="Time"
                  aria-label="Time"
                  class="time_input"
                  [ngxTimepicker]="darkPicker1"
                  formControlName="next_run_time"
                  readonly
                />
                <!--              (timeSet)="startTimeSet($event)"-->
                <ngx-material-timepicker
                  class="timepicker"
                  #darkPicker1
                  [ngxMaterialTimepickerTheme]="darkTheme"
                ></ngx-material-timepicker>
              </div>
            </div>
            <div class="mb-1">
              <label for="name" class="text-black-1" translate
                >Email Message
                <i
                  class="fas fa-question-circle ml-2 cb-tooltip-btn"
                  [ngbTooltip]="'organisation_communications.daily_status_email.tooltip_email_mssg' | translate"
                  triggers="hover:blur"
                  tooltipClass="cb-tooltip"
                  placement="bottom"
                ></i>
              </label>
            </div>

            <div class="input-group">
              <textarea
                id="name1"
                formControlName="message"
                class="p-4 cb-textarea"
                [placeholder]="
                  'organisation_communications.daily_status_email.Here you can set the message for daily email status.'
                    | translate
                "
                name="name"
                [maxLength]="4097"
              >
              </textarea>
              <div class="col-12 error-mssg" *ngIf="(f2.message.touched || submitted) && f2.message.errors">
                <label for="message" class="text-danger" *ngIf="f2.message.errors.required" translate
                  >organisation_communications.*Message is required.</label
                >
              </div>
              <div class="col-12 error-mssg" *ngIf="(f2.message.touched || f2.message.dirty) && f2.message.errors">
                <label for="message" class="text-danger" *ngIf="f2.message.errors.maxLength" translate
                  >organisation_communications.Message must be 4096 characters or less</label
                >
              </div>
              <div class="col-12 error-mssg" *ngIf="error['message']">
                <label for="message" class="text-danger">*{{ error['message'] }}</label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-container>
