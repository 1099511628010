import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox-schedule',
  templateUrl: './checkboxSchedule.component.html',
  styleUrls: ['./checkboxSchedule.component.scss']
})
export class CheckboxScheduleComponent implements OnInit {
  @Input() schedule: any;
  @Output() public checkbox_clicked = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  box_clicked() {
    this.checkbox_clicked.emit(true);
  }
}
