import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LeadService {
  constructor(private http: HttpClient) {}

  /** Data call for getting lead generated list **/
  getOrganisationLeadGeneratedList(toDate: string, fromDate: string, statusItems: any): Observable<any> {
    console.log('toDate', toDate, fromDate);
    let apiString: any;
    if ((toDate === '' || fromDate === '') && statusItems.length === 0) {
      apiString = `/api/organizations/registration-leads/`;
    } else if (toDate !== '' && fromDate !== '' && statusItems.length === 0) {
      apiString = `/api/organizations/registration-leads/?start_date=${fromDate}&end_date=${toDate}`;
    } else if ((toDate === '' || fromDate === '') && statusItems.length !== 0) {
      apiString =
        '/api/organizations/registration-leads/' +
        '?status=' +
        statusItems[0] +
        (statusItems[1] ? '&status=' + statusItems[1] : '') +
        (statusItems[2] ? '&status=' + statusItems[2] : '') +
        (statusItems[3] ? '&status=' + statusItems[3] : '');
    } else {
      apiString =
        '/api/organizations/registration-leads/' +
        '?start_date=' +
        fromDate +
        '&end_date=' +
        toDate +
        (statusItems[0] ? '&status=' + statusItems[0] : '') +
        (statusItems[1] ? '&status=' + statusItems[1] : '') +
        (statusItems[2] ? '&status=' + statusItems[2] : '') +
        (statusItems[3] ? '&status=' + statusItems[3] : '');
    }
    return this.http.get(apiString, {
      withCredentials: true
    });
  }

  /** Data call for rejecting or approving lead generated user **/
  rejectOrAcceptOrganizationLeadGeneratedUser(lead_guid: string, payload: object): Observable<any> {
    return this.http.post(`/api/organizations/registration-leads/${lead_guid}/action/`, payload, {
      withCredentials: true
    });
  }

  /** Data call for rejecting or approving lead generated user **/
  registrationLeadChoices(): Observable<any> {
    return this.http.get(`/api/organizations/registration-leads/choices/`, {
      withCredentials: true
    });
  }
  updateOrganisationGrcData(guid: string): Observable<any> {
    return this.http.get(`/api/organizations/${guid}/update_hippa_master_data/`, {
      withCredentials: true
    });
  }
  getOrganisationGrcDataList(): Observable<any> {
    return this.http.get(`/api/organizations/account`, {
      withCredentials: true
    });
  }
  getOrganisationGrcEnabled(guid: string): Observable<any> {
    return this.http.get(`/api/organizations/${guid}/org_feature/`, {
      withCredentials: true
    });
  }
  upDateGrcToggle(guid: string, grcFlag: boolean): Observable<any> {
    return this.http.put(
      `/api/organizations/${guid}/toggle_org_feature/`,
      { is_grc: grcFlag },
      {
        withCredentials: true
      }
    );
  }
}
