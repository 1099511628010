import { SidenavService } from '@app/core/modal/sidenav.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OrganisationService } from '@app/core/organisation.service';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';

@Component({
  selector: 'app-create-folder',
  templateUrl: './create-folder.component.html',
  styleUrls: ['./create-folder.component.scss']
})
export class CreateFolderComponent implements OnInit {
  folderForm: FormGroup;
  submitted = false;
  guidSubscription: any;
  error = {
    title: '',
    description: ''
  };
  form_error: any;
  isLoading = false;
  success: boolean;
  OrganisationID: string | null;
  ParentFolderList: object[];
  backend_error: any;
  subFolderName: string = '';
  subFolderData: any;
  subFolderGuid: string = '';
  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    private formBuilder: FormBuilder,
    private documentsService: DocumentsService,
    private translate: TranslateService,
    private toasterService: ToasterService,
    private sidenavService: SidenavService
  ) {}

  ngOnInit() {
    setTimeout(() => {
      document.getElementById('mySidenav').classList.toggle('open');
    }, 100);
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getAddfolderForm();
    });
    this.documentsService.folderName.subscribe((name: any) => {
      this.subFolderData = name;
      this.subFolderName = this.subFolderData?.title;
      this.subFolderGuid = this.subFolderData?.guid;
      console.log(this.subFolderGuid);
    });
  }

  get f() {
    return this.folderForm.controls;
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  getAddfolderForm() {
    this.folderForm = this.formBuilder.group({
      title: ['', [Validators.required(), Validators.maxLength(255)]],
      description: ['', [Validators.required(), Validators.maxLength(4096)]]
    });
  }

  createfolderForm() {
    this.submitted = true;
    if (this.folderForm.valid) {
      this.isLoading = true;
      const FolderData = this.folderForm.value;
      if (this.subFolderGuid) {
        FolderData['parent_folder_guid'] = this.subFolderGuid;
      }
      this.submitDocumentAddFolder(FolderData);
    } else {
      return;
    }
  }

  onChange(evt: any) {
    if (evt.type == 'keyup' && this.backend_error) {
      this.backend_error = '';
    }
  }

  submitDocumentAddFolder(FolderData: any): void {
    this.documentsService
      .SubmitDocumentFolderData(this.OrganisationID, FolderData)
      .pipe(
        finalize(() => {
          this.folderForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.error = {
            title: '',
            description: ''
          };
          this.sidenavService.updateData({ isUpdate: true, result: data });
          this.folderForm.reset();
          this.sidenavService.close(false);
          this.backend_error = '';
          if (this.subFolderGuid) {
            this.translate
              .get('create_a_subfolder.add_subfolder_toaster_message.subfolder_submitted')
              .subscribe(res => {
                this.toasterService.showSuccess(res);
              });
          } else {
            this.translate.get('document_folder.folder_toaster_message.add_folder').subscribe(res => {
              this.toasterService.showSuccess(res);
            });
          }
        },
        (error: any) => {
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
  }
  closeNav() {
    this.folderForm.reset();
    this.subFolderData = null;
    this.subFolderName = '';
    this.subFolderGuid = '';
    this.backend_error = '';
    this.sidenavService.close(true);
  }
}
