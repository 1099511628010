<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div *ngIf="ApproverList?.length > 0; else no_content">
    <div class="modal-header cb-modal-header">
      <div>
        <h4 class="modal-title text-center text-break m-0 mt-4 font-weight-bold text-black-44" translate>
          {{ docTitle }}
        </h4>
        <i
          class="fa fa-times text-black-1"
          style="width: 12px;
              height: 12px;
              right: 1em;
              cursor: pointer;
              position: absolute;
              top: 1.3em;"
          (click)="activeModal.close(false)"
          ngbAutoFocus
          translate
        ></i>
      </div>
    </div>

    <div class="modal-body">
      <!-- <div class="row mt-3">
        <div class="col-3">
          <label class="text-black-4 p-2" translate>Title </label>
        </div>
        <div class="col-9">
          <div class="input-group">
            <input class="form-control cb-input" value="{{ docTitle }}" disabled />
          </div>
        </div>
      </div> -->
      <!-- <hr class="solid" style="width: 107%; margin-left: -15px;" /> -->
      <table class="table table-borderless">
        <thead>
          <tr>
            <th scope="col-6">
              <h4 class="modal-title font-weight-bold text-black-1" translate>
                Revoke_Approver.View_approver.Approvers
              </h4>
            </th>
            <th scope="col-2">
              <h4 class="modal-title font-weight-bold text-black-1" translate>
                Revoke_Approver.View_approver.Due_Date
              </h4>
            </th>
            <th scope="col-2">
              <h4 class="modal-title font-weight-bold text-black-1" translate>Revoke_Approver.View_approver.Status</h4>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let approver of ApproverList">
            <td>
              <h4 class="text-black-1" scope="row">
                <span class="i-circle text-black-1 mr-2 font-weight-bold" translate>{{ approver.initials }}</span
                >{{ approver.user.name }}
              </h4>
            </td>
            <td>
              <h4 class="text-black-1" translate>{{ approver.due_date | date }}</h4>
            </td>
            <td>
              <h4 class="text-black-1 capitalize" translate>{{ approver.status }}</h4>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="modal-footer border-0 cb-modal-footer">
      <button class="btn-cancel ml-4 w-100" (click)="activeModal.close(false)" ngbAutoFocus translate>
        Revoke_Approver.View_approver.Close
      </button>
    </div>
  </div>
</ng-container>

<ng-template #no_content>
  <div class="modal-body">
    <h2 class="modal-title font-weight-bold text-black-1 p-5 text-center" translate>
      Revoke_Approver.View_approver.No_Approvers
    </h2>
  </div>
  <div class="modal-footer border-0 cb-modal-footer">
    <button class="btn-cancel ml-4 w-100" (click)="activeModal.close(false)" ngbAutoFocus translate>
      Revoke_Approver.View_approver.Close
    </button>
  </div>
</ng-template>
