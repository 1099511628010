<ng-container style="height: 100%">
  <app-public-shell-header style="width: 100%"></app-public-shell-header>
  <!--<mat-sidenav-container>-->
  <!--<mat-sidenav #sidenav mode="side" role="navigation" opened>-->
  <!--<mat-nav-list> <a mat-list-item>Profile1</a> </mat-nav-list>-->
  <!--</mat-sidenav>-->
  <!--<mat-sidenav-content>-->
  <!--&lt;!&ndash;in here all the content must reside. We will add a navigation header as well&ndash;&gt;-->

  <!--<main><router-outlet style="height: 100%"></router-outlet></main>-->
  <!--</mat-sidenav-content>-->
  <!--</mat-sidenav-container>-->
  <router-outlet style="background-color: #e9ecef"></router-outlet>
</ng-container>
