<div class="row lead-generated-header">
  <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
    <h2 class="text-cyan m-0" translate>organisation_lead_generation.Lead Generated</h2>
  </div>
</div>
<div class="filter-date-section">
  <div class="date-select-section">
    <form class="form-inline">
      <div class="form-group hidden">
        <div class="input-group">
          <input
            name="datepicker"
            class="form-control"
            ngbDatepicker
            #datepicker="ngbDatepicker"
            [autoClose]="'outside'"
            (dateSelect)="onDateSelection($event)"
            [displayMonths]="2"
            [dayTemplate]="t"
            outsideDays="hidden"
            [startDate]="fromDate!"
            tabindex="-1"
          />
          <ng-template #t let-date="date" let-focused="focused">
            <span
              class="custom-day"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null"
            >
              {{ date.day }}
            </span>
          </ng-template>
        </div>
      </div>
      <div class="form-group">
        <div class="input-group">
          <input
            #dpFromDate
            class="form-control w-117"
            placeholder="Start date"
            s
            name="dpFromDate"
            disabled
            [value]="formatter.format(fromDate)"
            (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary calendar fa fa-times"
              (click)="removeDate('fromDate')"
              type="button"
            ></button>
            <button
              class="btn btn-outline-secondary calendar fa fa-calendar"
              (click)="datepicker.toggle()"
              type="button"
            ></button>
          </div>
        </div>
      </div>
      <div class="form-group ml-2">
        <div class="input-group">
          <input
            #dpToDate
            class="form-control w-117"
            placeholder="End date"
            name="dpToDate"
            disabled
            [value]="formatter.format(toDate)"
            (input)="toDate = validateInput(toDate, dpToDate.value)"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary calendar fa fa-times"
              (click)="removeDate('toDate')"
              type="button"
            ></button>
            <button
              class="btn btn-outline-secondary calendar fa fa-calendar"
              (click)="datepicker.toggle()"
              type="button"
            ></button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="filter-dropdown-section">
    <ng-multiselect-dropdown
      class="multi-select-dropdown"
      [placeholder]="'Status type'"
      [data]="dropdownList"
      [(ngModel)]="selectedItems"
      [settings]="dropdownSettings"
      (onSelect)="onItemSelect($event)"
      (onDeSelect)="onItemDeSelect($event)"
      [ngModelOptions]="{ standalone: true }"
    >
    </ng-multiselect-dropdown>
  </div>
</div>
<div class="row scroll-view lead-generated-section">
  <div class="col" *ngIf="leadGeneratedList !== null; else other_content">
    <div
      class="cb-accordian mx-auto wm-1000 mt-4"
      [ngClass]="!accArray.includes(i) ? '' : 'expand'"
      *ngFor="let lead of leadGeneratedList; let i = index"
      [attr.data-index]="i"
    >
      <div class="acc-header d-flex justify-content-between">
        <h5 class="text-black-1">{{ lead['organization_name'] }} [{{ lead['organization_timezone'] }}]</h5>
        <div class="d-flx align-items-center d-flex-row">
          <div *ngIf="lead['status'] !== 'applied'; else other_action">
            <span
              class="mr-3"
              [ngClass]="lead['status'] == 'approved' ? 'approved-status' : 'rejected-status'"
              translate
            >
              {{ lead['status'] }} by {{ lead['action_taken_by'] }} -
              {{ lead['action_taken_at_time'] | date: 'mediumDate' }}
            </span>
          </div>
          <ng-template #other_action>
            <button
              class="btn-save mr-1 btn-accept"
              [disabled]="isLoading"
              (click)="
                openModal(lead['first_name'], lead['last_name'], lead['guid'], 'accept', lead['organization_name'])
              "
              translate
            >
              <i class="fas fa-cog fa-spin" *ngIf="isLoading && actionType === 'accept' && accArray.includes(i)"></i>
              <!-- <i (click)="openConfirm(lead['first_name'], lead['last_name'], lead['guid'], 'accept', lead['organization_name'])" 
            class="fa fa-check-circle cursor-pointer ml-1 accept-icon" aria-hidden="true"></i> -->
              organisation_lead_generation.accept
            </button>
            <button
              class="btn-save mr-3 btn-reject"
              [disabled]="isLoading"
              (click)="
                openConfirm(lead['first_name'], lead['last_name'], lead['guid'], 'reject', lead['organization_name'])
              "
              translate
            >
              <i class="fas fa-cog fa-spin" *ngIf="isLoading && actionType === 'reject' && accArray.includes(i)"></i>
              <!-- <i (click)="openConfirm(lead['first_name'], lead['last_name'], lead['guid'], 'reject', lead['organization_name'])" 
              class="fa fa-times-circle cursor-pointer ml-1 reject-icon" aria-hidden="true"></i> -->
              organisation_lead_generation.reject
            </button>
          </ng-template>
          <!-- <i *ngIf="lead['status'] === 'rejected'" class="fa fa-times-circle cursor-pointer ml-1 reject-icon" aria-hidden="true"></i>
          <i *ngIf="lead['status'] === 'approved'" class="fa fa-check-circle cursor-pointer ml-1 accept-icon" aria-hidden="true"></i> -->
          <button class="ml-1 mr-3 btn-acc-toggle" (click)="toggleDetails(i)">
            <i
              [ngClass]="!accArray.includes(i) ? 'fas fa-chevron-down text-black-2' : 'fas fa-chevron-up text-black-2'"
            ></i>
          </button>
        </div>
      </div>
      <div class="acc-body px-3">
        <div class="text-black-1 p-3 pr-3" *ngIf="lead['website_url'] !== null" translate="">
          Website :
          <a class="text-black-1 p-3 pr-3 link-1 cursor-pointer" [attr.href]="lead['website_url']" target="blank">{{
            lead['website_url']
          }}</a>
        </div>
        <table class="w-100p table-2">
          <thead>
            <tr>
              <td>
                <h4 class="font-weight-bold text-black-2 p-3" translate>organisation_lead_generation.Phone Number</h4>
              </td>
              <td><h4 class="font-weight-bold text-black-2 p-3" translate>organisation_lead_generation.Email</h4></td>
              <td>
                <h4 class="font-weight-bold text-black-2 p-3" translate>organisation_lead_generation.Created At</h4>
              </td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <h4 class="text-black-1 p-3 pr-3 w-240">
                  {{ lead['phone_number'] !== null ? lead['phone_number'] : '-' }}
                </h4>
              </td>
              <td>
                <h4 class="text-black-1 p-3">
                  {{ lead['organization_admin_email'] !== null ? lead['organization_admin_email'] : '-' }}
                </h4>
              </td>
              <td>
                <h4 class="text-black-1 p-3">
                  {{ lead['created_at'] !== null ? (lead['created_at'] | date: 'medium') : '-' }}
                </h4>
              </td>
              <td>
                <!-- <a
                  (click)="openConfirm(members['full_name'], committee['org_review_com_guid'], members['email'])"
                  class="text-black-1 p-3 pr-3 link-1 cursor-pointer"
                  ><u translate>organisation_committee.Remove</u></a
                > -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <ng-template #other_content class="no-org-selected">
    <app-no-organisation-selected
      class="no-lead-section"
      [typeMessage]="parentTypeMessage"
    ></app-no-organisation-selected>
  </ng-template>
</div>
