<div>
  <div>
    <div [ngClass]="graphStyleBoxContainerChanged ? 'graph-overall' : 'graph-other'">
      <canvas
        baseChart
        [data]="doughnutChartData"
        [labels]="doughnutChartLabels"
        [chartType]="doughnutChartType"
        [colors]="chartColors"
        [options]="chartOptions"
        (chartClick)="chartClicked($event)"
      >
      </canvas>
    </div>
  </div>
</div>
