import { Component, OnDestroy, OnInit } from '@angular/core';
import { OrganisationService } from '@app/core/organisation.service';
import { SettingsService } from '@app/settings/settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '@app/core/modal/modal.service';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';

@Component({
  selector: 'app-committees',
  templateUrl: './committees.component.html',
  styleUrls: ['./committees.component.scss']
})
export class CommitteesComponent implements OnInit, OnDestroy {
  OrganisationID: string | null;
  error: any;
  guidSubscription: any;
  committeeList: object[];
  accArray: any[] = [];
  paginatedDataUrl: any;
  scrollLoader = false;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  page = 1;
  roles = {
    organization_user_role: 'User',
    organization_admin_role: 'Administrator'
  };
  loading = true;

  constructor(
    private orgService: OrganisationService,
    private settingsService: SettingsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private translate: TranslateService,
    private toaster: ToasterService
  ) {}

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }
  onScrollUp() {
    this.scrollLoader = false;
  }

  onScroll() {
    if (this.paginatedDataUrl?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }
  onScrollData() {
    this.scrollLoader = true;
    this.settingsService.getPaginatedOrganizationCommitteeList(this.OrganisationID, (this.page += 1)).subscribe(
      data => {
        this.scrollLoader = false;
        this.paginatedDataUrl = data;
        this.committeeList = this.committeeList.concat(data.results);
      },
      error => {
        this.scrollLoader = false;
        this.error = error.error.message;
      }
    );
  }
  ngOnInit(): void {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getCommitteeList();
    });
  }

  addCommiittee() {
    this.modalService
      .custom(CustomDialogComponent, { title: 'Add Committee', modalType: 'committee' })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getCommitteeList();
          this.translate.get('organisation_committee.committee_toaster_message.add_committee').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        }
      });
  }

  addParticipantsToCommittee(committeeMembers: [], committeeGuid: string) {
    const emailList = committeeMembers.map(value => value['email']);
    this.modalService
      .custom(
        CustomDialogComponent,
        {
          title: 'Add Committee',
          modalType: 'committee-members',
          formData: emailList,
          guid: committeeGuid
        },
        { windowClass: 'add-admin' }
      )
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        //  TODO : TO HANDLE THE SUCCESS AND ERROR MEESSAGE
        if (result) {
          this.getCommitteeList();
        }
      });
  }

  openConfirm(firstName?: string, userGuid?: string, email?: string) {
    this.modalService
      .confirm('Do you want to remove ' + firstName)
      .pipe(take(1))
      .subscribe((confirmed: any) => {
        if (confirmed) {
          this.removeUser(userGuid, email);
        }
      });
  }

  toggleDetails(index: any): void {
    if (this.accArray.includes(index)) {
      const pos = this.accArray.indexOf(index);
      if (index > -1) {
        this.accArray.splice(pos, 1);
      }
    } else {
      this.accArray.push(index);
    }
  }

  // TODO : Dummy API STRRUCTURE FOR REMOVE COMMITTEE
  removeUser(guid: string, email: string): void {
    this.settingsService.revokeOrAddOrganizationCommitteeMember(guid, this.OrganisationID, email, 'revoke').subscribe(
      data => {
        if (data) {
          this.translate.get('User deleted successfully').subscribe(res => {
            this.toaster.showSuccess(res);
          });
          this.getCommitteeList();
        } else {
          this.translate.get('Failed to delete user!!').subscribe(res => {
            this.toaster.showError(res);
          });
        }
      },
      error => {
        this.error = error.error.message;
        this.translate.get('User deleted successfully').subscribe(res => {
          this.toaster.showError(res);
        });
      }
    );
  }

  private getCommitteeList(): void {
    this.page = 1;
    this.settingsService.getPaginatedOrganizationCommitteeList(this.OrganisationID, this.page).subscribe(
      data => {
        this.paginatedDataUrl = data;
        this.committeeList = data.results;
        this.loading = false;
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }
}
