<div>
  <div class="modal-header cb-modal-header">
    <div>
      <h4 class="modal-title font-weight-bold text-black-1">{{ title_tag }}</h4>
      <i
        class="fa fa-times text-black-1"
        style="width: 12px;
                      height: 12px;
                      right: 1em;
                      cursor: pointer;
                      position: absolute;
                      top: 1.3em;"
        (click)="activeModal.close(false)"
        ngbAutoFocus
        translate
      ></i>
    </div>
  </div>
  <div class="modal-body pl-0">
    <div class="col-12 pl-0">
      <div class="modal-title text-black-1 text-center m-auto p-1 font-weight-bold" [innerHtml]="message"></div>
    </div>
  </div>
  <div class="modal-footer border-0 cb-modal-footer">
    <button class="btn-cancel ml-4 w-100" (click)="activeModal.close(false)" translate>No</button>
    <button class="btn-save ml-4 w-100" (click)="confirm()" translate>Yes</button>
  </div>
</div>
