import { Component, OnDestroy, OnInit } from '@angular/core';
import { OrganisationService } from '@app/core/organisation.service';
import { UserService } from '@app/core/user.service';
import { DocumentsService } from './documents.service';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnDestroy {
  selectedIndex: number;
  loginUser: any;
  can_add_new: boolean = false;
  guidSubscription: any = new Subscription();
  loading: boolean;
  workspaceList: any;
  dataFolderDocumentViewList: any;
  OrganisationID: string;
  guid: any;
  WorkspaceElementData: any;
  clickedWorkspaceElementGuid: any;
  showComponent: boolean;
  title: string;
  sharedData: string;
  treeApiList: any;
  error: any;
  showDocument: boolean;
  page = 1;
  scrollLoader = false;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  pagnationData: any;
  paginatedDataUrl: any;
  searchText = '';
  constructor(
    private userservice: UserService,
    private documentsService: DocumentsService,
    private orgService: OrganisationService,
    private router: Router
  ) {
    this.selectedIndex = -1;
  }
  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  ngOnInit() {
    this.updateActiveTab();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateActiveTab();
      }
    });

    this.loginUser = this.userservice.getuserdetails();
    if (
      this.loginUser.user.role.includes('document_author') ||
      this.loginUser.user.role.includes('organization_admin_role')
    ) {
      this.can_add_new = true;
    } else {
      this.can_add_new = false;
    }
    this.documentsService.sharedData$.subscribe(sharedData => (this.sharedData = sharedData));

    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
      this.getWorkspace();
    });
  }
  private updateActiveTab() {
    const currentRoute = this.router.url;
    const isTrainingOrReport =
      currentRoute === '/organisation/documents/training' || currentRoute === '/organisation/documents/report';
    const trainingTab = document.querySelector('#trainingTab');

    if (isTrainingOrReport) {
      trainingTab.classList.add('active-training');
    } else {
      trainingTab?.classList?.remove('active-training');
    }
  }

  onScrollUp() {
    this.scrollLoader = false;
  }

  onScrollData() {
    this.scrollLoader = true;
    this.documentsService.getOrganizationFolderListPagination(this.OrganisationID, (this.page += 1)).subscribe(
      data => {
        this.scrollLoader = false;
        this.paginatedDataUrl = data;
        this.pagnationData = data.results;
        this.workspaceList = this.workspaceList.concat(data.results);
      },
      error => {
        this.scrollLoader = false;
        this.error = error.error.message;
      }
    );
  }
  onScroll() {
    if (this.paginatedDataUrl?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }

  getClickedEventsOnWorkspaceElement(workspace: any) {
    this.documentsService.getFolderDocumentViewList(this.OrganisationID, workspace.guid).subscribe(
      data => {
        this.loading = false;
        this.treeApiList = data;
        if (this.treeApiList != 0) {
          this.documentsService.setData({ workspace: workspace, treeApiList: this.treeApiList });
        }
      },
      error => {
        this.error = error.error.message;
        this.loading = false;
      }
    );
  }

  getWorkspace() {
    this.documentsService.getOrganizationFolderListForWorkspace(this.OrganisationID).subscribe(data => {
      this.loading = false;
      this.workspaceList = data;
      this.loginUser = this.userservice.getuserdetails();
      if (
        this.loginUser.user.role.includes('form_responder') &&
        !(
          this.loginUser.user.role.includes('organization_admin_role') ||
          this.loginUser.user.role.includes('form_requester') ||
          this.loginUser.user.role.includes('form_approver')
        )
      ) {
        this.workspaceList = this.workspaceList.filter((item: any) => item.title !== 'Forms');
      }
      if (
        this.loginUser.user.role.includes('organization_user_role') &&
        !(
          this.loginUser.user.role.includes('organization_admin_role') ||
          this.loginUser.user.role.includes('incident_operator') ||
          this.loginUser.user.role.includes('incident_reviewer') ||
          this.loginUser.user.role.includes('incident_coordinator') ||
          this.loginUser.user.role.includes('incident_reader')
        )
      ) {
        this.workspaceList = this.workspaceList.filter((item: any) => item.title !== 'Incidents');
      }

      // Filter the data based on the 'title' property
      const filteredIncidentData = this.workspaceList.filter((item: any) => item.title === 'Incidents');
      const filteredFormsData = this.workspaceList.filter((item: any) => item.title === 'Forms');
      // Store the filtered data in local storage
      localStorage.setItem('filteredWorkspaceIncidentData', JSON.stringify(filteredIncidentData));
      localStorage.setItem('filteredWorkspaceFormsData', JSON.stringify(filteredFormsData));
      this.paginatedDataUrl = data;
      this.workspaceList = this.workspaceList.filter(
        (item: any) => item.title !== 'Forms' && item.title !== 'Incidents'
      );
    });
  }

  ShowSubMenu(index: number) {
    if (this.selectedIndex == index) {
      this.selectedIndex = -1;
    } else {
      this.selectedIndex = index;
    }
  }
}
