<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div class="row">
    <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
      <h2 class="text-cyan m-0" translate>organisation_profile.Profile Settings</h2>
      <div *ngIf="!isFormLoading">
        <!--      <button class="ml-4 w-160 " style="background-color: inherit;" disabled *ngIf="success">-->
        <!--        <p class="success-msg">-->
        <!--          Profile Updated Successfully.-->
        <!--        </p>-->
        <!--      </button>-->

        <!--TODO: make disable classes for each button type like btn-cancel, btn-save etc-->
        <button
          class="btn-cancel ml-4 w-100"
          [ngClass]="{ 'btn-cancel': true, 'btn-cancel-disable': profileUpdateForm.pristine }"
          (click)="onReset()"
          translate
        >
          organisation_profile.Cancel
        </button>
        <button
          class="btn-save ml-4 w-100"
          [disabled]="profileUpdateForm.invalid || profileUpdateForm.pristine"
          [ngStyle]="{
            'background-color': profileUpdateForm.invalid || profileUpdateForm.pristine ? 'rgba(0,0,0,.26)' : '#4db7c6'
          }"
          (click)="save()"
          [disabled]="profileUpdateForm.pristine || profileUpdateForm.invalid"
          translate
        >
          organisation_profile.Save
        </button>
      </div>
    </div>
  </div>
  <div class="row scroll-view">
    <div class="col">
      <div class="text-black-1 ml-3 mt-3" style="font-size: 18px;">Personal Data</div>

      <div class="mt-4 ml-3 profile-upload">
        <img
          src="../../../../assets/images/core/user-profile.svg"
          class="user-avatar-pic"
          *ngIf="!UserPhoto && !isImageLoading"
        />
        <div style="padding: 20px;" *ngIf="isImageLoading">
          <i
            class="fa fa-spinner fa-spin"
            style="font-size:24px;transform-origin: center;width: 20px;height: 20px;"
          ></i>
        </div>
        <img class="user-avatar-pic" *ngIf="UserPhoto && !isImageLoading" [src]="UserPhoto" />
        <div>
          <a class="text-cyan-force cursor-pointer" (click)="selectImage()">Upload Photo (.jpg, .png only)</a>
          <a *ngIf="UserPhoto" class="text-cyan-force cursor-pointer" (click)="removeProfileImage()">Remove Photo</a>
        </div>
      </div>
      <ng-container *ngIf="!isFormLoading">
        <form [formGroup]="profileUpdateForm" novalidate autocomplete="off">
          <!-- added to remove chromes default autocompelte-->
          <div style="display: none">
            <input type="text" name="username" />
            <input
              id="old_password"
              class="form-control cb-input"
              autocomplete="new-password"
              [placeholder]="'organisation_profile.Old Password' | translate"
              name="old password"
              type="password"
              #old_password
              (input)="removeBackendValidation(old_password)"
            />
          </div>
          <!-- end-->

          <div class="row profile-form">
            <div class="col-lg-6 col-12 pr-4">
              <div class="col-12">
                <label for="first_name" class="text-black-1" translate>organisation_profile.First Name</label>
              </div>

              <div class="input-group col-12">
                <input
                  id="first_name"
                  class="form-control cb-input"
                  formControlName="first_name"
                  autocomplete="first_name"
                  [placeholder]="'organisation_profile.First Name' | translate"
                  required
                  name="name"
                  type="text"
                  #first_name
                  (input)="removeBackendValidation(first_name)"
                />
              </div>
              <div class="col-12" *ngIf="(f.first_name.touched || submitted) && f.first_name.errors">
                <label for="first_name" class="text-danger" *ngIf="f.first_name.errors.required" translate
                  >organisation_profile.*First name is required</label
                >
              </div>
              <div class="col-12">
                <label for="second_name" class="text-black-1" translate>organisation_profile.Last Name</label>
              </div>

              <div class="input-group col-12">
                <input
                  id="second_name"
                  class="form-control cb-input"
                  formControlName="last_name"
                  autocomplete="last_name"
                  [placeholder]="'organisation_profile.Last Name' | translate"
                  required
                  name="name"
                  type="text"
                  #last_name
                  (input)="removeBackendValidation(last_name)"
                />
              </div>
              <div class="col-12" *ngIf="(f.last_name.touched || submitted) && f.last_name.errors">
                <label for="second_name" class="text-danger" *ngIf="f.last_name.errors.required" translate
                  >organisation_profile.*Last name is required</label
                >
              </div>

              <div class="col-12 mt-3">
                <label for="phone" class="text-black-1" translate>organisation_profile.Phone Number</label>
              </div>

              <div class="input-group col-12">
                <input
                  id="phone"
                  class="form-control cb-input"
                  formControlName="phone"
                  [placeholder]="'organisation_profile.Phone Number' | translate"
                  name="name"
                  type="text"
                  #phone_number
                  (input)="removeBackendValidation(phone_number)"
                  autocomplete="off"
                />
              </div>

              <div class="col-12" *ngIf="(f.phone.touched || submitted) && f.phone.errors">
                <label for="first_name" class="text-danger" *ngIf="f.phone.errors.required" translate
                  >organisation_profile.*Phone number is required</label
                >
              </div>
              <div class="col-12" *ngIf="error['phone']">
                <label for="phone" class="text-danger">*{{ error['phone'] }}</label>
              </div>

              <div class="col-12 mt-3">
                <label for="email" class="text-black-1" translate>organisation_profile.Email Address</label>
              </div>

              <div class="input-group col-12">
                <input
                  id="email"
                  class="form-control cb-input"
                  [value]="UserData['email']"
                  [placeholder]="'organisation_profile.Email Address' | translate"
                  name="name"
                  type="text"
                  disabled
                />
              </div>

              <!-- <div class="col-12 mt-4" *ngIf="UserSignature">
              <label for="upload-signature" class="text-black-1" translate>My Signature </label>
            </div>
            <div class="signature-upload mt-4">
              <img
                src="../../../../assets/images/core/user-profile.svg"
                class="user-avatar-pic"
                *ngIf="!UserSignature && !isSignatureLoading"
              />
              <div style="padding: 20px;" *ngIf="isSignatureLoading">
                <i
                  class="fa fa-spinner fa-spin"
                  style="font-size:24px;transform-origin: center;width: 20px;height: 20px;"
                ></i>
              </div>
              <img class="user-avatar-pic" *ngIf="UserSignature && !isSignatureLoading" [src]="UserSignature" />
              <div>
                <a
                  *ngIf="UserSignature"
                  class="text-cyan-force cursor-pointer"
                  (click)="OpenChangeSignatureModal('change_signature')"
                  style="font-size: 12px; padding: 0.1rem;"
                  >Change signature
                  <i
                    class="fas fa-question-circle cb-tooltip-btn"
                    ngbTooltip="Signature should be in png/jpeg format, not more than 5 mb."
                    triggers="hover:blur"
                    tooltipClass="cb-tooltip"
                    placement="bottom"
                  ></i
                ></a>
                <a
                  *ngIf="!UserSignature"
                  class="text-cyan-force cursor-pointer"
                  (click)="OpenChangeSignatureModal('upload_signature')"
                  style="font-size: 12px; padding: 0.1rem;"
                  >Upload signature
                  <i
                    class="fas fa-question-circle cb-tooltip-btn"
                    ngbTooltip="Signature should be in png/jpeg format, not more than 5 mb."
                    triggers="hover:blur"
                    tooltipClass="cb-tooltip"
                    placement="bottom"
                  ></i
                ></a>
              </div>
            </div> -->
            </div>

            <div class="col-lg-6 col-12 password-form-border pl-4">
              <div class="col-12">
                <label for="old_password" class="text-black-1" translate>organisation_profile.Old Password</label>
              </div>

              <div class="input-group col-12">
                <input
                  id="old_password"
                  class="form-control cb-input"
                  formControlName="old_password"
                  autocomplete="old_password"
                  [placeholder]="'organisation_profile.Old Password' | translate"
                  name="old password"
                  type="password"
                  #old_password
                  (input)="removeBackendValidation(old_password)"
                />
              </div>

              <div class="col-12" *ngIf="f.old_password.errors && (f.old_password.touched || submitted)">
                <label for="old_password" class="text-danger" *ngIf="f.old_password.errors.RequiredIfAny" translate
                  >organisation_profile.*Old password is required</label
                >
              </div>
              <div class="col-12" *ngIf="f.old_password.errors && (f.old_password.touched || submitted)">
                <label for="old_password" class="text-danger" *ngIf="f.old_password.errors.minLength" translate
                  >organisation_profile.*Password must be at least 8 characters</label
                >
              </div>
              <div class="col-12" *ngIf="error['old_password']">
                <label for="phone" class="text-danger">*{{ error['old_password'] }}</label>
              </div>

              <div class="col-12 mt-3">
                <label for="new-password" class="text-black-1" translate>organisation_profile.New Password</label>
              </div>

              <div class="input-group col-12">
                <input
                  id="new-password"
                  class="form-control cb-input"
                  formControlName="password"
                  autocomplete="password"
                  [placeholder]="'organisation_profile.New password' | translate"
                  name="password"
                  type="password"
                  #password
                  (input)="removeBackendValidation(password)"
                />
              </div>
              <div class="col-12" *ngIf="f.password.errors && (f.password.touched || submitted)">
                <label for="new-password" class="text-danger" *ngIf="f.password.errors.RequiredIfAny" translate
                  >organisation_profile.*New password is required</label
                >
              </div>
              <div class="col-12" *ngIf="f.password.errors && (f.password.touched || submitted)">
                <label for="new-password" class="text-danger" *ngIf="f.password.errors.minLength" translate
                  >organisation_profile.*Password must be at least 8 characters.</label
                >
              </div>
              <div class="col-12" *ngIf="error['password']">
                <label for="phone" class="text-danger">*{{ error['password'] }}</label>
              </div>

              <div class="col-12 mt-3">
                <label for="confirm-password" class="text-black-1" translate
                  >organisation_profile.Confirm Password</label
                >
              </div>

              <div class="input-group col-12">
                <input
                  id="confirm-password"
                  class="form-control cb-input"
                  formControlName="password2"
                  autocomplete="password2"
                  [placeholder]="'organisation_profile.Confirm Password' | translate"
                  name="password"
                  type="password"
                  #password2
                  (input)="removeBackendValidation(password2)"
                />
              </div>
              <div class="col-12" *ngIf="error['password2']">
                <label for="phone" class="text-danger">*{{ error['password2'] }}</label>
              </div>
              <div class="col-12" *ngIf="f.password2.errors && (f.password2.touched || submitted)">
                <label for="confirm-password" class="text-danger" *ngIf="f.password2.errors.RequiredIfAny" translate
                  >organisation_profile.*Confirm Password is required</label
                >
              </div>
              <div class="col-12" *ngIf="f.password2.errors && (f.password2.touched || submitted)">
                <label for="confirm-password" class="text-danger" *ngIf="f.password2.errors.minLength" translate
                  >organisation_profile.*Password must be at least 8 characters</label
                >
              </div>
              <div class="col-12" *ngIf="f.password2.errors && (f.password2.touched || submitted)">
                <label for="confirm-password" class="text-danger" *ngIf="f.password2.errors.mustMatch" translate
                  >organisation_profile.*Confirm Password must match with new password</label
                >
              </div>
            </div>
          </div>
        </form>
      </ng-container>

      <div class="row mt-3" style="border-top: 2px solid rgba(221, 221, 221, 0.68);">
        <div class="col-6 mt-3 ml-4">
          <div class="text-black-1" style="font-size: 18px;">Signature</div>
          <label class="text-black-1 mt-2 pl-2" *ngIf="UserSignature">Current Signature </label>
          <div class="signature-upload mt-2 pl-2" *ngIf="UserSignature">
            <div style="padding: 20px;" *ngIf="isSignatureLoading">
              <i
                class="fa fa-spinner fa-spin"
                style="font-size:24px;transform-origin: center;width: 20px;height: 20px;"
              ></i>
            </div>
            <img class="user-avatar-pic" *ngIf="UserSignature && !isSignatureLoading" [src]="UserSignature" />
          </div>
          <label class="text-black-1 mt-4 pl-2"> Update Signature </label>

          <!-- <a *ngIf="UserSignature" class="text-cyan-force">Update signature
        <i
          class="fas fa-question-circle cb-tooltip-btn"
          ngbTooltip="Signature should be in png/jpeg format, not more than 5 mb."
          triggers="hover:blur"
          tooltipClass="cb-tooltip"
          placement="bottom"
        ></i></a> -->
          <div class="d-flex mt-1 pl-4">
            <mat-icon class="text-black-1" style="font-size:25px" (click)="OpenChangeSignatureModal()"
              >desktop_windows</mat-icon
            ><a
              class="text-black-1 cursor-pointer ml-2 mt-1"
              (click)="OpenChangeSignatureModal()"
              style="font-size: 12px;"
              >Upload Signature</a
            >
            <mat-icon class="ml-4 text-black-1" style="font-size:25px" (click)="OpenDrawSignatureModal()">edit</mat-icon
            ><a
              class="text-black-1 cursor-pointer ml-2 mt-1"
              (click)="OpenDrawSignatureModal()"
              style="font-size: 12px;"
              >Draw Signature</a
            >
            <mat-icon class="ml-4 text-black-1" style="font-size:25px" (click)="OpenGenerateSignatureModal(user_name)"
              >add_circle_outline</mat-icon
            ><a
              class="text-black-1 cursor-pointer ml-2 mt-1"
              (click)="OpenGenerateSignatureModal(user_name)"
              style="font-size: 12px;"
              >Generate Signature</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
