import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OrganisationService } from '@app/core/organisation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { SettingsService } from '@app/settings/settings.service';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';

@Component({
  selector: 'app-generate-signature',
  templateUrl: './generate-signature.component.html',
  styleUrls: ['./generate-signature.component.scss']
})
export class GenerateSignatureComponent implements OnInit {
  GenerateSignatureForm: FormGroup;
  OrganisationID: string | null;
  guidSubscription: any;
  isLoading = false;
  submitted: boolean = false;
  error: any;

  file: any;
  size: any;
  file_type: any;
  file_type_error: boolean = false;
  file_size_error: boolean = false;
  SignatureUrl: any;
  isSignatureLoading: boolean = false;
  form_error: any;

  signature_image: any = [];
  sig_image: string;
  UserNameRequired: boolean = false;

  @Input() title_tag: string;
  @Input() user_name: string;
  @Input() google_fonts: any;
  checkFont: boolean = false;
  isShowCommentSection: boolean = false;
  backend_error: any;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private settingsService: SettingsService
  ) {}

  ngOnInit() {
    this.isShowCommentSection = localStorage.getItem('is-org-onboarded') === 'true' ? true : false;
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
    });
    this.getGenerateSignatureForm();
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  get f() {
    return this.GenerateSignatureForm.controls;
  }

  getGenerateSignatureForm() {
    this.GenerateSignatureForm = this.formBuilder.group({
      Comment: ['', [Validators.maxLength(4096)]],
      select_signature: ['', [Validators.required()]]
    });
  }

  ngDoCheck() {
    this.signature_image = this.settingsService.GenerateSignature(this.user_name, this.google_fonts);
  }

  convertBase64ToFile(base64String: any, fileName: any) {
    let arr = base64String.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let uint8Array = new Uint8Array(n);
    while (n--) {
      uint8Array[n] = bstr.charCodeAt(n);
    }
    let file = new File([uint8Array], fileName, { type: mime });
    return file;
  }

  save() {
    this.submitted = true;
    if (this.GenerateSignatureForm.valid && !this.file_size_error && !this.file_type_error) {
      const signatureData = this.GenerateSignatureForm.value;
      this.file = this.convertBase64ToFile(signatureData['select_signature'], 'signature');
      this.size = this.file.size;
      this.file_size_error = false;
      if (this.size < 1024 * 1024 * 5 || this.size == 1024 * 1024 * 5) {
        this.file_size_error = false;
      } else {
        this.file_size_error = true;
      }
      this.isLoading = true;
      const data = { size: this.size, comment: signatureData['Comment'] };
      this.submitGenerateSignature(data, this.file);
    } else {
      return;
    }
  }

  submitGenerateSignature(data: any, file: any): void {
    this.settingsService.uploadUserSignature(this.OrganisationID, data).subscribe(
      (data: any) => {
        this.settingsService.uploadUserSignatureToGCP(data.url, file).subscribe(
          (data: any) => {
            this.error = {
              Comment: ''
            };
            this.submitted = false;
            this.activeModal.close(true);
            console.log('Signature uploaded.');
          },
          (error: any) => {
            this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
            this.backendErrorService.backendError$.subscribe(error => {
              this.backend_error = error;
            });
          }
        );
      },
      (error: any) => {
        this.isLoading = false;
        this.submitted = false;
        this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
        });
      }
    );
  }
}
