import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { OrganisationService } from '@app/core/organisation.service';
import { ModalService } from '@app/core/modal/modal.service';
import { take } from 'rxjs/operators';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { DocumentsService } from '../documents.service';
import { Document_Invite_Training_Groups } from '../../../../translations/en-US.json';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { SettingsService } from '@app/settings/settings.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-create-training-group',
  templateUrl: './create-training-group.component.html',
  styleUrls: ['./create-training-group.component.scss']
})
export class CreateTrainingGroupComponent implements OnInit {
  InviteTrainingGroupsForm: FormGroup;
  submitted = false;
  isLoading = false;
  loading = false;
  success: boolean;
  organisationID: string | null;
  guidSubscription: any;
  dropdownSettings = {};
  default_doc_url_type: any;
  folder_name: string;
  parent_folder: any;
  // addCampaignForm: any;
  DocStoreguid: any;
  error_message: any;
  selected_option: any;
  template_name: any;
  GroupsList: any;
  DocumentsList: any;
  selectedItems: any;
  groupSelected: any;
  error: {};
  form_error: any;
  dropdownList: any = [];
  selectedGroups: any = [];
  selectedDocuments: any = [];
  dropdownSettingsForDocuments = {};
  dropdownSettingsForGroup = {};
  DocumentSelected = true;
  GroupSelected = true;
  DataList: any = [];
  UsersList: object[];
  loginUser: any;
  loginUsername: string;
  loginUserEmail: string;
  EditorSelected = true;
  @Input() DocCreatedBy: string;

  constructor(
    private orgService: OrganisationService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private settingsService: SettingsService,
    private router: Router,
    private documentsService: DocumentsService,
    private translate: TranslateService,
    private toaster: ToasterService
  ) {}

  ngOnInit() {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.organisationID = guid;
      this.getInviteTrainingGroupsForm();
      this.getUserList();
    });

    this.documentsService.getAllgroups(this.organisationID).subscribe(data => {
      this.GroupsList = data;
    });
    this.documentsService.getAllDocumentVersions(this.organisationID).subscribe(data => {
      this.DocumentsList = data;
    });

    this.default_doc_url_type = 'Others';

    this.dropdownSettingsForDocuments = {
      singleSelection: false,
      idField: 'document_version',
      textField: 'DataList',
      itemsShowLimit: 10,
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'guid',
      textField: 'FullName',
      itemsShowLimit: 10,
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.dropdownSettingsForGroup = {
      singleSelection: false,
      idField: 'guid',
      textField: 'name',
      itemsShowLimit: 10,
      enableCheckAll: false,
      allowSearchFilter: true
    };
  }

  onItemSelect(item: any) {
    this.checkEditorSelection();
  }

  onItemDeSelect(items: any) {
    this.checkEditorSelection();
  }

  checkEditorSelection() {
    this.selectedItems.length > 0 ? (this.EditorSelected = true) : (this.EditorSelected = false);
  }

  private getUserList(): void {
    this.settingsService.getOrganizationPeopleList(this.organisationID).subscribe(
      data => {
        this.DataList = data.data;
        this.UsersList = this.DataList.filter((x: any) => {
          return x['roles'].includes(
            'document_editor' || (this.DocCreatedBy == this.loginUserEmail && this.loginUserEmail == x['email'])
          );
        });
        this.UsersList.forEach(user => {
          user['FullName'] = user['first_name'] + ' ' + user['last_name'] + '(' + user['email'] + ')';
        });
        this.UsersList.sort((a, b) => a['FullName'].localeCompare(b['FullName']));
      },
      error => {
        this.error = error.error.message;
      }
    );
  }

  getInviteTrainingGroupsForm() {
    this.InviteTrainingGroupsForm = this.formBuilder.group({
      // title: ['', Validators.required()],
      title: ['', Validators.required()]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.InviteTrainingGroupsForm.controls;
  }

  get optionsData(): FormArray {
    return this.InviteTrainingGroupsForm.get('document_links') as FormArray;
  }

  submit() {
    let editor: any = [];
    this.submitted = true;
    if (this.selectedItems.length > 0 && this.InviteTrainingGroupsForm.valid) {
      editor = this.selectedItems.map((item: any) => {
        return item.guid;
      });
      this.checkEditorSelection();
      // this.isLoading = true;
      const trainingGroupData = this.InviteTrainingGroupsForm.value;
      trainingGroupData['users'] = editor;

      this.submitTrainingGroup(trainingGroupData);
      this.router.navigate(['/organisation/settings/training']);
    } else {
      this.checkEditorSelection();
      return;
    }
  }

  submitTrainingGroup(data: any) {
    console.log(data);
    if (this.organisationID != null) {
      this.documentsService.createTrainingGroup(this.organisationID, data).subscribe(
        data => {
          this.loading = false;
          this.translate.get('Training_Group.people_toaster_message.group_created').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        },
        error => {
          this.error = error.error.message;
          this.loading = false;
          console.log('Error:', error.error.message);
        }
      );
    }
  }

  onReset() {
    this.submitted = false;
    this.InviteTrainingGroupsForm.reset();
    // this.folder_name = '';
    // this.template_name = '';
    // this.selected_option = '';
  }
}
