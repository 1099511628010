import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import stringInfo from '../../../../translations/en-US.json';
import { finalize } from 'rxjs/internal/operators/finalize';

@Component({
  selector: 'app-create-incident-success',
  templateUrl: './create-incident-success.component.html',
  styleUrls: ['./create-incident-success.component.scss']
})
export class CreateIncidentSuccessComponent implements OnInit {
  OrganisationID: any;
  DocURL: any;
  error: any;

  @Input() title_tag: string;
  @Input() incidentGuid: string;
  @Input() incidentTitle: string;
  @Input() formGuid: string;
  @Input() formTitle: string;
  @Input() requestorGuid: any = [];

  loginUser: any;
  title: any;
  loginUsername: any;
  loginUserEmail: any;
  AttentionList: any;
  guidSubscription: any;
  CampaignCreated = stringInfo.Title_Tag.Campaign_Temp;
  View_Incident = stringInfo.Title_Tag.View_Incident;
  Cancel = stringInfo.Title_Tag.Cancel;
  View_Form = stringInfo.Title_Tag.View_Form;
  constructor(
    private orgService: OrganisationService,
    private documentsService: DocumentsService,
    private router: Router,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe((guid: any) => {
      this.OrganisationID = guid;
    });
    if (this.title_tag === 'Form Created' || this.title_tag === 'Form Edited') {
      this.title = this.formTitle;
    } else {
      this.title = this.incidentTitle;
    }
  }

  viewForm(formGuid: any) {
    this.documentsService.getOrganizationFormEditURl(this.OrganisationID, formGuid).subscribe(
      (data: any) => {
        this.DocURL = data;
        window.open(this.DocURL.document_store_identifier, '_blank');
        this.activeModal.close(false);
      },
      (error: { error: { message: any } }) => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }

  goToIncidentPage() {
    if (this.title_tag === 'Incident Created' || this.title_tag === 'Incident Edited') {
      this.router.navigate(['/organisation/incident/incident-management']);
    } else {
      this.router.navigate(['/organisation/form/pending-form']);
    }
    this.activeModal.close(false);
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }
}
