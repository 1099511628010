import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-onboard-header',
  templateUrl: './onboard-header.component.html',
  styleUrls: ['./onboard-header.component.scss']
})
export class OnboardHeaderComponent implements OnInit {
  @Input() activeStep = 1;
  constructor() {}

  ngOnInit() {}
}
