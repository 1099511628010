import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { OrganisationService } from '@app/core/organisation.service';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';

@Component({
  selector: 'app-test-cloud-service-provider',
  templateUrl: './test-cloud-service-provider.component.html',
  styleUrls: ['./test-cloud-service-provider.component.scss']
})
export class TestCloudServiceProviderComponent implements OnInit {
  @Input() selectedFrameworks: any[] = [];
  @Input() selectedConnectors: any[] = [];
  @Input() selectedAccounts: any[] = [];
  @Input() selectedResources: any[] = [];
  @Input() selectedServices: any[] = [];
  @Input() include_ignored_test: boolean;
  @Input() status: string;
  @Output() csvDataEmitter = new EventEmitter<string>();
  @Output() setStatus: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('resourceSelect') resourceSelect: MatSelect;
  @Output() treeListStatus = new EventEmitter<boolean>();
  filters: any = {};
  loading: boolean = false;
  @Input() isIgnoreTest: boolean = false;
  searchText: string = '';
  totalLength: any = 0;
  OrganisationID: string | null;
  orgGuidSubscription: any;
  resources: any[];
  @Input() page = 1;
  pageSize = 10;
  pageSizes = [
    { value: 10, label: '10 / page' },
    { value: 25, label: '25 / page' },
    { value: 50, label: '50 / page' },
    { value: 100, label: '100 / page' }
  ];
  testList: any = [];
  testListWithoutPagination: any = [];
  constructor(
    private orgService: OrganisationService,
    private documentsService: DocumentsService,
    private toaster: ToasterService,
    private router: Router
  ) {}

  // ngOnChanges lifecycle hook
  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedFrameworks']) {
      this.filters['frameworks'] = this.selectedFrameworks;
    }

    if (changes['selectedConnectors']) {
      this.filters['connectors'] = this.selectedConnectors;
    }

    if (changes['selectedAccounts']) {
      this.filters['accounts'] = this.selectedAccounts;
    }

    if (changes['selectedResources']) {
      this.filters['resources'] = this.selectedResources;
    }

    // if (changes['selectedResources']) {
    //   this.filters['resources'] = this.selectedResources;
    // }

    if (changes['selectedServices']) {
      console.log("changes['selectedServices'", this.selectedServices);
      this.filters['services'] = this.selectedServices;
    }

    if (this.OrganisationID) {
      this.page = 1;
      this.getTestList();
      this.getStatusCount();
    }
    this.checkTreeListStatus();
  }

  checkTreeListStatus() {
    this.treeListStatus.emit(this.testList && this.testList.length > 0);
  }
  async exportTableData(): Promise<void> {
    try {
      await this.getTestListWithoutPagination();
      const csvData = this.generateCSV();
      if (csvData) {
        this.csvDataEmitter.emit(csvData);
      } else {
        console.error('CSV data is empty.');
      }
    } catch (error) {
      console.error('Error fetching test list or generating CSV:', error);
    }
  }
  generateCSV(): string {
    if (!this.testListWithoutPagination || this.testListWithoutPagination.length === 0) {
      return '';
    }
    const headers = ['TEST NAME', 'CONNECTOR', 'RESOURCE NAME', 'SERVICE', 'LAST RUN', 'STATUS', 'FRAMEWORK'];
    let csvContent = headers.join(',') + '\r\n'; // Add headers to CSV content
    this.testListWithoutPagination.forEach((test: any) => {
      const formattedLastRun = test.last_run
        ? new Date(test.last_run).toLocaleString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            timeZone: 'PST'
          })
        : '-';
      const formattedStatus = test.status ? test.status.charAt(0).toUpperCase() + test.status.slice(1) : '';
      const values = [
        `"${test.test_name}"`,
        `"${test.connector}"`,
        `"${test.resource_name}"`,
        `"${test.service}"`,
        `"${formattedLastRun}"`,
        `"${formattedStatus}"`,
        `"${test.framework.join(',')}"`
      ];
      csvContent += values.join(',') + '\r\n';
    });

    return csvContent;
  }

  getTestListWithoutPagination(): Promise<void> {
    const queryString = this.objectToQueryString(this.filters);

    return new Promise((resolve, reject) => {
      this.documentsService
        .getTestListWithoutPagination(
          this.OrganisationID,
          queryString,
          this.include_ignored_test,
          this.searchText,
          this.status
        )
        .subscribe(
          res => {
            if (res?.results && res?.results.length > 0) {
              res.results.forEach((test: any) => {
                test['isSelected'] = false;
              });
            }
            this.testListWithoutPagination = res.results;
            resolve(); // Resolve the promise when data is set
          },
          error => {
            console.error('Error fetching test list:', error);
            reject(error); // Reject the promise if there is an error
          }
        );
    });
  }

  getStatusClass(status: string): string {
    switch (status.toLowerCase()) {
      case 'compliant':
        return 'compliant';
      case 'danger':
        return 'danger';
      case 'not_started':
        return 'not-started';
      case 'warning':
        return 'warning';
      case 'ignored':
        return 'ignored';
      // Add other status cases as needed
      default:
        return '';
    }
  }

  navigateToDetails(test: any) {
    this.router.navigate(['/organisation/cloud-details', test.guid, this.page, this.status]);
  }

  getFrameworkClass(framework: string): string {
    switch (framework.toLowerCase()) {
      case 'hipaa':
        return 'hipaa';
      case 'soc2':
        return 'soc2';
      case 'iso27001':
        return 'iso27001';
      case 'gdpr':
        return 'gdpr';
      default:
        return '';
    }
  }

  ngOnInit(): void {
    this.orgGuidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
    });
    if (this.OrganisationID) {
      this.getTestList();
      this.getStatusCount();
      this.getAllResouces();
    }
  }

  getAllResouces() {
    this.documentsService.getAllResouces(this.OrganisationID).subscribe(res => {
      this.resources = res;
    });
  }

  onResourceChange(resource: any) {
    this.filters['resources'] = this.selectedResources;
    this.getTestList();
    this.getStatusCount();
  }

  removeResource() {
    this.selectedResources = [];
    this.filters['resources'] = this.selectedResources;
    this.getTestList();
    this.getStatusCount();
  }

  getTestList() {
    const queryString = this.objectToQueryString(this.filters);
    this.loading = true;
    this.documentsService
      .getTestList(
        this.OrganisationID,
        this.page,
        this.pageSize,
        queryString,
        this.include_ignored_test,
        this.searchText,
        this.status
      )
      .subscribe(res => {
        this.totalLength = res.count;
        if (res?.results && res?.result?.length > 0) {
          res?.results.forEach((test: any) => {
            test['isSelected'] = false;
          });
        }
        this.testList = res.results;
        this.loading = false;
        this.checkTreeListStatus();
      });
  }

  getStatusCount() {
    const queryString = this.objectToQueryString(this.filters);
    this.documentsService
      .getStatusCount(this.OrganisationID, queryString, this.include_ignored_test, this.searchText, this.status)
      .subscribe(res => {
        this.setStatus.emit(res);
      });
  }

  objectToQueryString(obj: Record<string, unknown>): string {
    return Object.entries(obj)
      .filter(([_, value]) => Array.isArray(value) && value.length > 0)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          const encodedValues = value.map(item => encodeURIComponent(String(item)));
          return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(encodedValues))}`;
        }
        return '';
      })
      .filter(Boolean)
      .join('&');
  }

  changePageSize(pageSize: any) {
    this.pageSize = pageSize;
    this.getTestList();
  }

  goToPageChange(value: number = 1) {
    this.page = value;
    if (value > Math.ceil(this.totalLength / this.pageSize)) {
      this.page = Math.ceil(this.totalLength / this.pageSize);
    }
    this.getTestList();
  }

  pageChange(value: number = 1) {
    this.page = value;
    this.getTestList();
  }

  searchByTest() {
    if (this.OrganisationID) {
      this.getTestList();
      this.getStatusCount();
    }
  }

  // isAllSelected() {
  //   return this.testList?.every((test: any) => test.isSelected === true);
  // }

  isAllSelected() {
    return this.testList?.every((test: any) => test.isSelected === true && test.status !== 'ignored') ?? false;
  }

  atleastOneSelected() {
    return !!this.testList?.find((test: any) => test.isSelected === true && test.status !== 'ignored');
  }

  // onAllCheckBoxChange(event: MatCheckboxChange) {
  //   const isChecked = event.checked;
  //   if (isChecked) {
  //     this.testList?.forEach((test: any) => (test.isSelected = true));
  //   } else {
  //     this.testList?.forEach((test: any) => (test.isSelected = false));
  //   }
  // }

  onAllCheckBoxChange(event: MatCheckboxChange) {
    const isChecked = event.checked;
    if (isChecked) {
      this.testList?.forEach((test: any) => {
        if (test.status !== 'ignored') {
          test.isSelected = true;
        }
      });
    } else {
      this.testList?.forEach((test: any) => {
        if (test.status !== 'ignored') {
          test.isSelected = false;
        }
      });
    }
  }

  onCheckBoxChange(event: MatCheckboxChange, test: any) {
    const isChecked = event.checked;
    if (isChecked) {
      test.isSelected = true;
    } else {
      test.isSelected = false;
    }
  }

  isSomeSelected() {
    if (!this.isAllSelected()) {
      return this.testList?.some((test: any) => test.isSelected === true && test.status !== 'ignored') ?? false;
    }
    return false;
  }

  // isIgnoreDisabled() {
  //   return !(this.isAllSelected() || this.testList?.some((test: any) => test.isSelected === true)) ?? false;
  // }

  isIgnoreDisabled() {
    return (
      !(
        this.isAllSelected() ||
        this.testList?.some((test: any) => test.isSelected === true && test.status !== 'ignored')
      ) ?? false
    );
  }

  ignoreTestOnRow(test: any) {}

  ignoreTest() {
    let guids = this.testList.filter((test: any) => test.isSelected).map((test: any) => test.guid);
    let payload = { test_guids: guids };
    this.isIgnoreTest = true;
    this.documentsService.ignoreTests(this.OrganisationID, payload).subscribe(
      (res: any) => {
        this.toaster.showSuccess(res.msg);
        this.getTestList();
        this.getStatusCount();
        this.isIgnoreTest = false;
      },
      (error: any) => {
        this.toaster.showError(error.error);
        this.isIgnoreTest = false;
      }
    );
  }

  unignoreTest(guid: string) {
    let payload = { test_guids: [guid] };
    this.isIgnoreTest = true;
    this.documentsService.unignoreTests(this.OrganisationID, payload).subscribe(
      (res: any) => {
        this.toaster.showSuccess(res.msg);
        this.getTestList();
        this.getStatusCount();
        this.isIgnoreTest = false;
      },
      (error: any) => {
        this.toaster.showError(error.error);
        this.isIgnoreTest = false;
      }
    );
  }

  openResourceDropdown() {
    this.resourceSelect.open();
  }
}
