<ng-container>
  <div class="modal-content">
    <div class="modal-header cb-modal-header">
      <div>
        <h2 translate>
          Approve and Confirm
        </h2>
        <i
          class="fa fa-times"
          style="width: 12px;
          height: 12px;
          right: 1em;
          cursor: pointer;
          position: absolute;
          top: 17px;
          font-size: 20px;"
          (click)="activeModal.close(false)"
          ngbAutoFocus
          translate
        ></i>
      </div>
    </div>

    <div class="modal-body">
      <div class="row mt-1 mb-3" *ngIf="backend_error">
        <div class="col-12">
          <span class="text-danger backend_error" translate> * {{ backend_error }}</span>
        </div>
      </div>
      <div class="dm-text-2 mb-4">
        You're about to create documents using policy and procedure text tailored to your organization's needs.
      </div>
      <div class="dm-text-2 mb-4">The following documents will be generated</div>
      <div class="col" [ngClass]="{ 'Document-Title': DocuementGenaratedTitles.length > 0 }">
        <div class="col" *ngFor="let headers of DocuementGenaratedTitles">
          <span class="dm-text-2 font-weight-bold" for="Document-Title" translate>{{ headers.header }}</span>
        </div>
      </div>
      <span class="dm-text-2 font-weight-bold">Signature*</span>
      <div class="row mt-2">
        <div class="col-12 dm-text-2">
          <ng-container *ngIf="UserSignature">
            <span class="readonly-box">
              <img [src]="UserSignature" style="height: 100px; width: 200px; margin-left: 44px; margin-top: -9px;" />
            </span>
            <div
              *ngIf="showSignatureOption"
              style="color: #2a647c; cursor: pointer; width: 17%;"
              (click)="showSignatureOption = !showSignatureOption"
            >
              <mat-icon style="color: #2a647c; cursor: pointer;">edit</mat-icon>Edit Signature
            </div>
          </ng-container>
          <i *ngIf="!UserSignature && isSignatureLoading" class="fa fa-spinner fa-spin" style="font-size:24px"></i>
          <div class="d-flex pb-1 mt-1 " *ngIf="!showSignatureOption || !UserSignature">
            <!-- <mat-icon class="text-black-1" style="font-size:25px" (click)="OpenChangeSignatureModal()"
              >desktop_windows</mat-icon
            > -->
            <img
              src="./../../../assets/images/core/file_upload.svg"
              (click)="OpenChangeSignatureModal()"
              style="cursor: pointer;"
            />
            <a
              class="text-black-1 cursor-pointer ml-1 mt-1 mr-1"
              (click)="OpenChangeSignatureModal()"
              style="font-size: 14px;"
              >Upload Signature</a
            >
            <img
              src="./../../../assets/images/core/drawsignature.svg"
              (click)="OpenDrawSignatureModal()"
              style="cursor: pointer;"
            />
            <a
              class="text-black-1 cursor-pointer ml-1 mt-1 mr-1"
              (click)="OpenDrawSignatureModal()"
              style="font-size: 14px;"
              >Draw Signature</a
            >
            <img
              src="./../../../assets/images/core/generatesignature.svg"
              (click)="OpenGenerateSignatureModal(userName)"
              style="cursor: pointer;"
            />
            <a
              class="text-black-1 cursor-pointer ml-1 mt-1 mr-1"
              (click)="OpenGenerateSignatureModal(userName)"
              style="font-size: 14px;"
              >Generate Signature</a
            >
          </div>
          <div class="input-g roup pr-2 pb-2 pt-2 pl-0  mt-2 mb-2 mr-2 ml-0">
            <label class="form-check-label dm-text-2 checkbox-2 " translate>
              <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlinecheck" />
              <span class="checkmark" translate></span>
              I have reviewed the document. I am approving the document using the above signature.
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <div class="row mt-3">
        <div class="col-6">
          <button class="btn-cancel" (click)="activeModal.close(false)" ngbAutoFocus translate>
            Cancel
          </button>
        </div>
        <div class="col-6">
          <button class="btn-save" disabled *ngIf="isLoadingButton">
            <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
          </button>
          <button
            *ngIf="!isLoadingButton"
            class="btn-save"
            (click)="viewGrcData()"
            [disabled]="!checkboxChecked() && UserSignature"
            [ngStyle]="{ 'background-color': !checkboxChecked() && UserSignature ? 'rgba(0,0,0,.26)' : '#2a647c' }"
            translate
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
