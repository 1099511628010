import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@app/core/modal/modal.service';
import { OrganisationService } from '@app/core/organisation.service';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { take } from 'rxjs/operators';
import stringInfo from '../../../../translations/en-US.json';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { IncidentService } from '../incident.service';
import { Router } from '@angular/router';
import { OrganisationAdminService } from '@app/Organisation-admin/organisation-admin.service';

@Component({
  selector: 'app-incident-management',
  templateUrl: './incident-management.component.html',
  styleUrls: ['./incident-management.component.scss']
})
export class IncidentManagementComponent implements OnInit {
  orgGuidSubscription: any;
  OrganisationID: string | null;
  loading = true;
  IncidentList: any;
  totalLength: any;
  error: any;
  search_title: any;
  statusFilter: any = '';
  page = 1;
  paginatedData: any;
  state_filter: string | null = '';
  placeholderSearch = stringInfo.Incident_Mangement.PlaceHolder_Search;
  scrollLoader = false;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  clickedGuid: string;
  statusAndActions: any;
  status_loading: boolean = false;
  IncidentStatus: [];
  paginatedDataUrl: any;
  role: string = '';
  roleRouteMapper = {
    '/organisation/incident/incident-management': '',
    '/organisation/incident/incident-author': 'author',
    '/organisation/incident/reviewer': 'reviewer',
    '/organisation/incident/incident-approver': 'approver'
  };
  titleMapper = {
    '': 'Incident_Mangement.Title',
    author: 'Incident_Mangement.Author',
    reviewer: 'Incident_Mangement.Reviewer',
    approver: 'Incident_Mangement.Approver'
  };
  constructor(
    private incidentService: IncidentService,
    private orgService: OrganisationService,
    private modalService: ModalService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private router: Router,
    private orgAdminService: OrganisationAdminService
  ) {
    this.role = this.roleRouteMapper[window.location.pathname];
  }

  ngOnInit() {
    this.orgGuidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
      this.getIncidentList();
    });

    this.getStatusTypes();
  }

  getStatusTypes() {
    if (this.OrganisationID !== null) {
      this.status_loading = true;
      this.orgAdminService.getOrganisationIncidentStatusTypes(this.role).subscribe(data => {
        this.IncidentStatus = data;
        this.status_loading = false;
      });
    }
  }

  onScrollUp() {
    this.scrollLoader = false;
  }

  onScrollData() {
    this.scrollLoader = true;
    if (this.search_title == null || undefined || this.statusFilter == undefined) {
      this.search_title = '';
      this.statusFilter = this.statusFilter;
      this.incidentService
        .getPaginatedIncidentList(
          this.OrganisationID,
          (this.page += 1),
          (this.search_title = this.search_title),
          (this.statusFilter = this.statusFilter),
          this.role
        )
        .subscribe(
          data => {
            this.scrollLoader = false;
            this.paginatedData = data.results;
            this.paginatedDataUrl = data;
            this.IncidentList = this.IncidentList.concat(data.results);
          },
          error => {
            this.scrollLoader = false;
            this.error = error.error.message;
          }
        );
    } else {
      this.scrollLoader = true;
      this.incidentService
        .getPaginatedIncidentList(
          this.OrganisationID,
          (this.page += 1),
          this.search_title,
          this.statusFilter,
          this.role
        )
        .subscribe(
          data => {
            this.scrollLoader = false;
            this.paginatedData = data.results;
            this.paginatedDataUrl = data;
            this.IncidentList = this.IncidentList.concat(data.results);
          },
          error => {
            this.scrollLoader = false;
            this.error = error.error.message;
          }
        );
    }
  }
  onScroll() {
    if (this.paginatedDataUrl?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }

  Search(search_title: string) {
    this.loading = true;
    this.search_title = search_title;
    if (this.statusFilter === undefined) {
      this.statusFilter = '';
    }
    this.incidentService
      .getOrganizationIncidentList(this.OrganisationID, this.statusFilter, this.search_title, this.page, this.role)
      .subscribe(
        data => {
          this.loading = false;
          this.IncidentList = data.results;
        },
        error => {
          this.error = error.error.message;
        }
      );
  }

  onStatusChange(state: any, value: any) {
    this.loading = true;
    this.statusFilter = state;
    if (this.search_title === '') {
      this.search_title = this.search_title;
    }
    if (this.search_title == undefined) {
      this.search_title = '';
    }
    for (let i = 0; i < this.IncidentList.length; i++) {
      if (this.IncidentList[i]['status'] != this.statusFilter) {
        this.page = 1;
      }
    }
    this.incidentService
      .getOrganizationIncidentList(this.OrganisationID, this.statusFilter, this.search_title, this.page, this.role)
      .subscribe(
        data => {
          this.loading = false;
          this.IncidentList = data.results;
          this.paginatedDataUrl = data;
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
  }
  getIncidentList(): void {
    if (this.OrganisationID != null) {
      if (this.search_title == undefined) {
        this.search_title = '';
      }
      this.incidentService
        .getIncidentListData(this.OrganisationID, (this.statusFilter = ''), (this.search_title = ''), this.role)
        .subscribe(
          data => {
            this.loading = false;
            this.paginatedDataUrl = data;
            this.IncidentList = data.results;
            this.totalLength = data.count;
          },
          error => {
            this.error = error.error.message;
            this.loading = false;
          }
        );
    }
  }
  ApplySearchFilter(page: any, search_title: string, statusFilter: string) {
    if (search_title == undefined) {
      search_title = '';
    }
    if (statusFilter == undefined) {
      statusFilter = '';
    }
    this.incidentService
      .getPaginatedIncidentList(this.OrganisationID, page, search_title, statusFilter, this.role)
      .subscribe(
        data => {
          this.IncidentList = data.results;
        },
        error => {
          this.error = error.error.message;
        }
      );
  }
  goToCreateIncident() {
    this.router.navigate(['organisation/incident/create-incident']);
  }
  goTOEditIncident(incidentGuid: any) {
    this.router.navigate(['/organisation/incident/edit-incident'], { queryParams: { incidentGuid: incidentGuid } });
  }
  pickIncident(incidentGuid: string, incidentTitle: string, status_to_be_sent: string, action: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Pick Incident',
        modalType: 'Pick Incident',
        incidentGuid: incidentGuid,
        incidentTitle: incidentTitle,
        status_to_be_sent: status_to_be_sent,
        action: action
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          for (let i = 0; i <= this.IncidentList.length; i++) {
            if (this.clickedGuid == this.IncidentList[i]['guid']) {
              this.IncidentList[i] = result.result;
              break;
            }
          }
          this.translate.get('pick_incident.incident_picked_successfully').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
        }
      });
  }

  blockIncident(incidentGuid: string, incidentTitle: string, status_to_be_sent: string, action: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Block Incident',
        modalType: 'Block Incident',
        incidentGuid: incidentGuid,
        incidentTitle: incidentTitle,
        status_to_be_sent: status_to_be_sent,
        action: action
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          for (let i = 0; i <= this.IncidentList.length; i++) {
            if (this.clickedGuid == this.IncidentList[i]['guid']) {
              this.IncidentList[i] = result.result;
              break;
            }
          }
          this.translate.get('block_incident.incident_blocked_successfully').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        }
      });
  }
  resolveIncident(incidentGuid: string, incidentTitle: string, status_to_be_sent: string, action: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Resolve Incident',
        modalType: 'Resolve Incident',
        incidentGuid: incidentGuid,
        incidentTitle: incidentTitle,
        status_to_be_sent: status_to_be_sent,
        action: action
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          for (let i = 0; i <= this.IncidentList.length; i++) {
            if (this.clickedGuid == this.IncidentList[i]['guid']) {
              this.IncidentList[i] = result.result;
              break;
            }
          }
          this.translate.get('resolve_incident.incident_resolve_successfully').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        }
      });
  }
  closeIncident(incidentGuid: string, incidentTitle: string, status_to_be_sent: string, action: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Are you sure you want to close the incident ?',
        modalType: 'Close Incident',
        incidentGuid: incidentGuid,
        incidentTitle: incidentTitle,
        status_to_be_sent: status_to_be_sent,
        action: action
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          for (let i = 0; i <= this.IncidentList.length; i++) {
            if (this.clickedGuid == this.IncidentList[i]['guid']) {
              this.IncidentList[i] = result.result;
              break;
            }
          }
          this.translate.get('close_incident.incident_close_successfully').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        }
      });
  }
  linkIncident(incidentGuid: string, incidentTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Link an Issue ',
        modalType: 'Link an Issue',
        incidentGuid: incidentGuid,
        incidentTitle: incidentTitle
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.translate.get('reopen_incident.Ticket_linked_successfully').subscribe(res => {
            this.toaster.showSuccess(res);
            // this.getIncidentList();
          });
        }
      });
  }

  opneCreateJiraTicketForm(incidentGuid: any, incidentTitle: string, status_to_be_sent: string) {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Create jira ticket',
        modalType: 'Create jira ticket',
        incidentGuid: incidentGuid,
        incidentTitle: incidentTitle,
        status_to_be_sent: status_to_be_sent
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.translate.get('Create_jira_ticket.issue_created').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        }
      });
  }

  openReviewerApproverModal(type: string, incidentGuid: string, incCreatedBy: string, incidentTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: type === 'Manage Reviewers' ? 'Manage Reviewer' : 'Manage Approvers',
        modalType: type === 'Manage Reviewers' ? 'Manage Reviewer' : 'Invite Approvers',
        incidentGuid: incidentGuid,
        incCreatedBy: incCreatedBy,
        incidentTitle: incidentTitle
      })
      .pipe(take(1))
      .subscribe((result: any) => {
        if (result.isUpdate && Object.keys(result.result).length) {
          for (let i = 0; i <= this.IncidentList.length; i++) {
            if (this.clickedGuid == this.IncidentList[i]['guid']) {
              this.IncidentList[i] = result.result;
              break;
            }
          }
          if (result.show_msg == true) {
            let msg =
              type === 'Manage Reviewers'
                ? 'Incident_Manage_Reviewer.invite_reviewer_toaster_message.invite_reviewer'
                : 'Incident_Manage_Approver.invite_approver_toaster_message.invite_approver';
            this.translate.get(msg).subscribe(res => {
              this.toaster.showSuccess(res);
            });
          }
        }
      });
  }

  goTOIncidentDetailsPage(guid: any) {
    this.router.navigate(['/organisation/incident/incident-details'], { queryParams: { guid: guid } });
  }

  approveIncident(incidentGuid: string, incidentTitle: string, status_to_be_sent: string, action: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Are you sure you want to approve changes ?',
        modalType: 'Approve Incident',
        incidentGuid: incidentGuid,
        incidentTitle: incidentTitle,
        status_to_be_sent: status_to_be_sent,
        action: action
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          for (let i = 0; i <= this.IncidentList.length; i++) {
            if (this.clickedGuid == this.IncidentList[i]['guid']) {
              this.IncidentList[i] = result.result;
              break;
            }
          }
          this.translate.get('approve_incident.incident_approved_successfully').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
        }
      });
  }

  unblockIncident(
    incidentGuid: string,
    incidentTitle: string,
    name: string,
    status_to_be_sent: string,
    action: string
  ): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Unblock Incident',
        modalType: 'Unblock Incident',
        incidentGuid: incidentGuid,
        message: 'Are you sure you want to unblock incident ' + incidentTitle + ' requested by ' + name + ' ?',
        status_to_be_sent: status_to_be_sent,
        action: action
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          for (let i = 0; i <= this.IncidentList.length; i++) {
            if (this.clickedGuid == this.IncidentList[i]['guid']) {
              this.IncidentList[i] = result.result;
              break;
            }
          }
          this.translate.get('unblock_incident.unblocked_incident').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        }
      });
  }

  onNavigate(jira_url: string) {
    window.open(jira_url, '_blank');
  }
  updateSearchText(x: any) {
    this.search_title = x.target.value;
    if (this.search_title == undefined) {
      this.search_title = '';
    }
  }

  InviteEditorsForChangeReqOpenModal(incidentGuid: string, incCreatedBy: string, incidentTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Request Change',
        modalType: 'Request Change',
        incidentGuid: incidentGuid,
        incidentTitle: incidentTitle,
        incCreatedBy: incCreatedBy
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          for (let i = 0; i <= this.IncidentList.length; i++) {
            if (this.clickedGuid == this.IncidentList[i]['guid']) {
              this.IncidentList[i] = result.result;
              break;
            }
          }
          this.translate
            .get('change_request_for_incident.change_request_toaster_message.change_request_submitted')
            .subscribe(res => {
              this.toaster.showSuccess(res);
            });
        }
      });
  }

  getClickEvents(
    action: string,
    incidentGuid: string,
    incCreatedBy: string,
    incidentTitle: string,
    name: string,
    status_to_be_sent: string,
    jira_url: string
  ): void {
    this.clickedGuid = incidentGuid;
    if (action == 'Create Ticket') {
      this.opneCreateJiraTicketForm(incidentGuid, incidentTitle, status_to_be_sent);
    } else if (action == 'Link Ticket') {
      this.linkIncident(incidentGuid, incidentTitle);
    } else if (action == 'Edit Incident') {
      this.goTOEditIncident(incidentGuid);
    } else if (action == 'Resolve Incident') {
      this.resolveIncident(incidentGuid, incidentTitle, status_to_be_sent, action);
    } else if (action == 'Manage Reviewers' || action == 'Manage Approvers') {
      this.openReviewerApproverModal(action, incidentGuid, incCreatedBy, incidentTitle);
    } else if (action == 'Block Incident') {
      this.blockIncident(incidentGuid, incidentTitle, status_to_be_sent, action);
    } else if (action == 'Approve Incident') {
      this.approveIncident(incidentGuid, incidentTitle, status_to_be_sent, action);
    } else if (action == 'Close Incident') {
      this.closeIncident(incidentGuid, incidentTitle, status_to_be_sent, action);
    } else if (action == 'View Incident') {
      this.onNavigate(jira_url);
    } else if (action == 'Unblock Incident') {
      this.unblockIncident(incidentGuid, incidentTitle, name, status_to_be_sent, action);
    } else if (action == 'Move to In Progress') {
      this.pickIncident(incidentGuid, incidentTitle, status_to_be_sent, action);
    } else if (action == 'Reopen Incident') {
      this.InviteEditorsForChangeReqOpenModal(incidentGuid, incCreatedBy, incidentTitle);
    } else if (action == 'View Incident Details') {
      this.goTOIncidentDetailsPage(incidentGuid);
    }
  }
}
