import {
  Directive,
  Input,
  OnInit,
  ElementRef,
  ViewContainerRef,
  TemplateRef,
  HostListener,
  EmbeddedViewRef
} from '@angular/core';

@Directive({
  selector: '[popoverTrigger]'
})
export class PopoverDirective {
  @Input()
  public popoverTrigger: TemplateRef<any>;

  private embeddedView: EmbeddedViewRef<any> = null;
  private targetClicked = false;

  constructor(private el: ElementRef, private viewContainer: ViewContainerRef) {}

  ngOnInit() {}

  @HostListener('click', ['$event.target'])
  onClick(target: any) {
    this.targetClicked = true;

    if (this.embeddedView) {
      this.embeddedView.destroy();
      this.embeddedView = null;
      return;
    }

    const targetPos = this.getTargetPosition(target);
    this.embeddedView = this.viewContainer.createEmbeddedView(this.popoverTrigger);
    const root = this.embeddedView.rootNodes[0];
    root.style.position = 'absolute';
    root.style.left = targetPos.left - 50 + 'px';
    root.style.top = targetPos.top + 15 + 'px';
  }

  @HostListener('document:click')
  documentClicked() {
    if (!this.targetClicked && this.embeddedView !== null) {
      this.embeddedView.destroy();
      this.embeddedView = null;
      return;
    }

    this.targetClicked = false;
  }

  private getTargetPosition(target: HTMLElement): { top: number; left: number } {
    const rect = target.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    const top = rect.top + scrollTop + target.offsetHeight;
    const left = rect.left + scrollLeft;

    return { top, left };
  }
}
