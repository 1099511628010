<app-spinner *ngIf="loading"></app-spinner>
<div id="mySidenav" class="sidenav">
  <div class="sidenav-header">
    <span *ngIf="!accountDetails" class="title">Integrate {{ serviceProvider?.description?.short_name }}</span>
    <span *ngIf="accountDetails" class="title">Reconfigure {{ serviceProvider?.description?.short_name }}</span>
    <div class="sub-title">
      Integrate {{ serviceProvider?.description?.short_name }} with ComplianceBot to automate and streamline your
      compliance process
    </div>
    <div class="integration-guid">
      Guide to integrate {{ serviceProvider?.description?.short_name }} with ComplianceBot
    </div>
    <i class="fa fa-times text-black-1 closebtn" (click)="closeNav()" ngbAutoFocus translate></i>
  </div>
  <form [formGroup]="integrationForm">
    <div class="account-name">
      <div class="account-name-form-ctrl">
        <div class="form-group">
          <label for="title" class="label">Account Name</label>
          <input
            type="text"
            id="title"
            formControlName="accountName"
            class="form-control cb-input-focus"
            placeholder="Enter account name"
          />
          <div *ngIf="(f.accountName.touched || f.accountName.dirty) && f.accountName.errors" class="text-danger">
            <label *ngIf="f.accountName.errors.required">* Account name is required.</label>
            <label *ngIf="f.accountName.errors.maxlength">* Account name must be less than 255 characters.</label>
          </div>
        </div>
      </div>
    </div>
    <div class="sync-scan-data-container">
      <div class="sync-scan-data-label">Sync Data</div>
      <div class="scan-data-inner-container">
        <div class="automatically">
          <div class="d-flex">
            <input
              type="radio"
              id="syncAuto"
              formControlName="syncType"
              name="syncType"
              value="automatic"
              class="form-radio-input mr-2"
            />
            <span for="syncAuto" class="automatically-label">Automatically</span>
          </div>
          <div class="automatically-sublabel">
            Automatically scan data to ComplianceBot
          </div>
        </div>
        <div class="schedule-frequency-container">
          <div class="schedule-frequency-label">
            Schedule Frequency
          </div>
          <div class="form-group">
            <select
              class="form-control cb-input-focus select-dropdown"
              (change)="getRunSchedule()"
              formControlName="time"
            >
              <option class="select-placeholder" *ngFor="let schedule of scheduleChoices" [value]="schedule.key">
                {{ schedule.value }}</option
              >
            </select>
          </div>
          <div
            style="margin-top: -21px !important;"
            *ngIf="(f.time.touched || f.time.dirty) && f.time.errors"
            class="text-danger"
          >
            <label *ngIf="f.time.errors.required">Schedule frequency is required.</label>
          </div>
        </div>
        <div class="schdule-run-note">
          {{ scheduleTimeMsg }}
          <span style="font-weight: 700 !important;">{{ scheduleTime | titlecase }}</span>
        </div>
        <div class="scan-data-inner-container">
          <div class="automatically">
            <div class="d-flex">
              <input
                type="radio"
                id="syncManual"
                formControlName="syncType"
                name="syncType"
                value="manual"
                class="form-radio-input mr-2"
              />
              <span for="syncManual" class="automatically-label">Manually</span>
            </div>
            <div class="automatically-sublabel">
              Manually scan data to ComplianceBot
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="steps-container">
      <div class="step-inner-container">
        <ng-container *ngIf="serviceProvider?.type == 'GCP'">
          <div class="step-header mb-2">
            To complete the integration, follow these steps in GCP
          </div>
          <div class="step">
            1. Sign in to your target <span class="step-bold-text">GCP account</span> in another browser tab
          </div>
          <div class="step">
            2. <span class="step-bold-text">Choose the project</span> where you want to create the service account.
          </div>
          <div class="step">
            3. Navigate to <span class="step-bold-text">IAM & Admin</span> from the left navigation menu
          </div>
          <div class="step">
            4. Click on <span class="step-bold-text">Create Service Account</span> and enter the Service Account Name,
            Account ID, and Account Description.
          </div>
          <div class="step">
            5. Assign necessary permissions to the service account
          </div>
          <div class="step">
            6. Grant specific users access to the service account as needed
          </div>
          <div class="step">7. Go to the Keys tab and click <span class="step-bold-text">Add Key</span></div>
          <div class="step">8. Select <span class="step-bold-text">JSON as the key</span> type to download it</div>
          <div class="step">
            9. Upload the JSON below
          </div>
        </ng-container>

        <div *ngIf="serviceProvider?.type == 'GCP'" class="json-upload-container" style="margin-top: 12px !important;">
          <span *ngIf="jsonFileName" class="json-file-name">{{ jsonFileName }}</span>
          <input type="file" id="jsonFile" (change)="onFileChange($event)" accept=".json" hidden />
          <button type="button" class="ml-2 upload-json" (click)="triggerFileInput()">
            {{ jsonUploaded ? 'Change JSON' : 'Upload JSON' }}
          </button>
        </div>

        <ng-container *ngIf="serviceProvider?.type == 'AWS'">
          <div class="step-header mb-2">
            To complete the integration, follow these steps in AWS
          </div>
          <div class="step">
            1. Review our
            <ng-container *ngIf="connectionLinkDetails?.description?.cloud_formation_template_link; else noLink">
              <a
                [href]="connectionLinkDetails?.description?.cloud_formation_template_link"
                target="_blank"
                class="step-text-link"
              >
                CloudFormation Template
              </a>
              <mat-icon class="blue-icon">launch</mat-icon>
              <img
                class="ml-1"
                src="../../../../assets/images/core/copyIcon.svg"
                (click)="copyLink(connectionLinkDetails?.description?.cloud_formation_template_link)"
                alt="Copy Link"
                style="cursor: pointer;"
              />
            </ng-container>
            <ng-template #noLink>
              <span class="step">CloudFormation Template</span>
            </ng-template>
          </div>
          <div class="step">
            2. Follow the detailed
            <ng-container *ngIf="connectionLinkDetails?.description?.guide_link; else noGuideLink">
              <a [href]="connectionLinkDetails?.description?.guide_link" target="_blank" class="step-text-link">
                <mat-icon class="blue-icon">launch</mat-icon>
                Guide
              </a>
            </ng-container>
            <ng-template #noGuideLink>
              <span class="step">Guide</span>
            </ng-template>
          </div>
          <!-- <div class="step">2. Follow the detailed <span class="step-text-link">Guide</span></div> -->
          <div class="step">3. Copy the AWS "AccessKeyId" field and paste it in - Acess Key in CB</div>
          <div class="step">4. Copy the AWS "SecretAccessKey" field and paste it in - Secret Acess Key in CB</div>
          <!-- <div class="step">
            4. Check “ I acknowledge that AWS Cloudformation might cease IAM resources with custom names” and click
            <span class="step-bold-text">Create</span>
          </div>
          <div class="step">
            5. When stack creation is finished, goto Outputs and copy the <span class="step-bold-text">ARN</span> and
            paste it below
          </div> -->
        </ng-container>
        <div class="arn-container" *ngIf="serviceProvider?.type == 'AWS'">
          <div class="arn-inner-container">
            <div class="arn-label">Access Key</div>
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control cb-input-focus"
                formControlName="accessKey"
                #accessKey
                placeholder="Enter access key"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
              <!-- <div class="input-group-append">
                <span class="input-group-text cursor-pointer" (click)="copyInputMessage(accessKey)">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1017_4065)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3.06742 4.46629V8.93258H7.53371H12V4.46629V0H7.53371H3.06742V4.46629ZM11.0225 4.48315V7.98876H7.51685H4.01124V4.48315V0.977528H7.51685H11.0225V4.48315ZM0 7.53371V12H4.46629H8.93258V10.8708V9.74157H8.46067H7.98876V10.382V11.0225H4.48315H0.977528V7.51685V4.01124H1.61798H2.25843V3.53933V3.06742H1.12921H0V7.53371Z"
                        fill="#002244"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1017_4065">
                        <rect width="12" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </div> -->
            </div>
            <div
              *ngIf="(f.accessKey.touched || f.accessKey.dirty) && f.accessKey.errors"
              class="text-danger"
              style="margin-top: -20px;"
            >
              <label *ngIf="f.accessKey.errors.required">* Accerss key is required.</label>
            </div>
          </div>
          <div class="arn-inner-container" style=" margin-top: 16px !important;">
            <div class="arn-label">Secret Access Key</div>
            <div class="input-group mb-3">
              <input
                [type]="isShowSecretAccessKey ? 'text' : 'password'"
                class="form-control cb-input-focus"
                formControlName="secretAccessKey"
                placeholder="Enter secret access key"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
              <div class="input-group-append">
                <span class="input-group-text cursor-pointer" (click)="isShowSecretAccessKey = !isShowSecretAccessKey">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1017_4085)">
                      <g clip-path="url(#clip1_1017_4085)">
                        <path
                          d="M9.78033 8.22706C11.0257 7.11567 11.7139 5.85638 11.7139 5.85638C11.7139 5.85638 9.51749 1.8296 5.85678 1.8296C5.15362 1.83202 4.45842 1.97853 3.8141 2.2601L4.37785 2.82458C4.85186 2.65191 5.35229 2.56297 5.85678 2.56174C7.40892 2.56174 8.69676 3.41688 9.64049 4.36061C10.0947 4.81724 10.5018 5.31835 10.8558 5.85638C10.8134 5.92008 10.7665 5.99036 10.7131 6.06724C10.4678 6.41867 10.1054 6.88724 9.64049 7.35215C9.51969 7.47295 9.39376 7.59229 9.26197 7.70797L9.78033 8.22706Z"
                          fill="#002244"
                        />
                        <path
                          d="M8.27065 6.71738C8.43403 6.26041 8.46429 5.76644 8.3579 5.29294C8.25151 4.81944 8.01285 4.38589 7.66969 4.04273C7.32654 3.69957 6.89298 3.46091 6.41949 3.35452C5.94599 3.24814 5.45202 3.27839 4.99504 3.44177L5.5976 4.04433C5.87897 4.00406 6.16585 4.02986 6.43551 4.11971C6.70518 4.20956 6.95021 4.36098 7.1512 4.56196C7.35218 4.76295 7.5036 5.00798 7.59345 5.27764C7.68329 5.54731 7.7091 5.83419 7.66883 6.11556L8.27065 6.71738ZM6.11596 7.66844L6.71778 8.27026C6.2608 8.43364 5.76683 8.46389 5.29333 8.35751C4.81984 8.25112 4.38629 8.01246 4.04313 7.6693C3.69997 7.32614 3.46131 6.89259 3.35492 6.41909C3.24853 5.9456 3.27879 5.45162 3.44217 4.99465L4.04472 5.5972C4.00445 5.87857 4.03026 6.16546 4.12011 6.43512C4.20995 6.70478 4.36137 6.94982 4.56236 7.1508C4.76334 7.35179 5.00838 7.5032 5.27804 7.59305C5.5477 7.6829 5.83459 7.70871 6.11596 7.66844Z"
                          fill="#002244"
                        />
                        <path
                          d="M2.45231 4.00406C2.32053 4.1212 2.19387 4.23981 2.07306 4.36061C1.61887 4.81724 1.21171 5.31835 0.857705 5.85638L1.00047 6.06724C1.24574 6.41867 1.60815 6.88724 2.07306 7.35215C3.01679 8.29588 4.30537 9.15103 5.85678 9.15103C6.38099 9.15103 6.87446 9.05365 7.33571 8.88745L7.89946 9.45267C7.25512 9.73421 6.55993 9.88073 5.85678 9.88317C2.19606 9.88317 -0.000366211 5.85638 -0.000366211 5.85638C-0.000366211 5.85638 0.687116 4.59636 1.93322 3.4857L2.45158 4.00479L2.45231 4.00406ZM9.99046 10.5084L1.20474 1.7227L1.7231 1.20435L10.5088 9.99006L9.99046 10.5084Z"
                          fill="#002244"
                        />
                      </g>
                    </g>
                    <defs>
                      <clipPath id="clip0_1017_4085">
                        <rect width="12" height="12" fill="white" />
                      </clipPath>
                      <clipPath id="clip1_1017_4085">
                        <rect width="12" height="12" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </div>
            </div>
            <div
              *ngIf="(f.secretAccessKey.touched || f.secretAccessKey.dirty) && f.secretAccessKey.errors"
              class="text-danger"
              style="margin-top: -20px;"
            >
              <label *ngIf="f.secretAccessKey.errors.required">* Secret access key is required.</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="test-connection">
      <button
        *ngIf="!isTestConnection"
        type="submit"
        (click)="testServiceProviderConnection()"
        [disabled]="
          serviceProvider?.description?.short_name?.toLowerCase() == 'aws'
            ? !integrationForm.get('accessKey').value || !integrationForm.get('secretAccessKey').value
            : !file
        "
        [ngClass]="{
          'test-connection-btn': true,
          disabled:
            serviceProvider?.description?.short_name?.toLowerCase() == 'aws'
              ? !integrationForm.get('accessKey').value || !integrationForm.get('secretAccessKey').value
              : !file
        }"
      >
        Test Connection
      </button>

      <button
        *ngIf="isTestConnection"
        type="submit"
        (click)="testServiceProviderConnection()"
        [disabled]="
          serviceProvider?.description?.short_name?.toLowerCase() == 'aws'
            ? !integrationForm.get('accessKey').value || !integrationForm.get('secretAccessKey').value
            : !file
        "
        [ngClass]="{
          'test-connection-btn': true,
          disabled:
            serviceProvider?.description?.short_name?.toLowerCase() == 'aws'
              ? !integrationForm.get('accessKey').value || !integrationForm.get('secretAccessKey').value
              : !file
        }"
      >
        <i class="fa fa-spinner fa-spin"></i>
      </button>

      <span *ngIf="testConnRes?.status == 'Successful' && changeJson" class="success-msg">
        <img src="../../../../assets/images/logo/check.svg" />
        Successful
      </span>
      <span *ngIf="testConnRes?.status == 'Failed' && changeJson" class="failed-msg">
        <img src="../../../../assets/images/logo/cross.svg" />
        Failed
      </span>
      <span *ngIf="testConnRes?.status == 'Error' && changeJson" class="error-msg">
        <img src="../../../../assets/images/logo/error.svg" />
        Error
      </span>
    </div>
    <div class="conn-desc-container" *ngIf="testConnRes && testConnRes?.status !== 'Successful' && changeJson">
      <div class="conn-desc-inner-container">
        <div class="msg-container">
          <div class="msg-label">Message</div>
          <div class="msg-desc">
            {{ testConnRes?.message }}
          </div>
        </div>
      </div>
    </div>
    <div style="margin-bottom: 100px;"></div>
    <div class="sidenav-footer d-flex">
      <button
        *ngIf="!isSubmitting"
        type="button"
        class="btn-save cb-btn-primary"
        (click)="onSubmit()"
        [disabled]="
          serviceProvider?.description?.short_name?.toLowerCase() == 'aws'
            ? integrationForm.invalid || testConnRes?.status !== 'Successful'
            : integrationForm.invalid || testConnRes?.status !== 'Successful' || !file
        "
        [ngClass]="{
          'btn-disabled cb-btn-disabled':
            serviceProvider?.description?.short_name?.toLowerCase() == 'aws'
              ? integrationForm.invalid || testConnRes?.status !== 'Successful'
              : integrationForm.invalid || testConnRes?.status !== 'Successful' || !file
        }"
      >
        Save
      </button>
      <button *ngIf="isSubmitting" type="button" class="btn-save">
        <i class="fa fa-spinner fa-spin"></i>
      </button>
      <button type="button" class="btn-cancel" (click)="closeNav()">Cancel</button>
    </div>
  </form>
</div>
