import { Component, OnDestroy, OnInit } from '@angular/core';
import { OrganisationService } from '@app/core/organisation.service';
import { UserService } from '@app/core/user.service';
import { SettingsService } from '@app/settings/settings.service';
// import {  } from '@app/shared/'
@Component({
  selector: 'app-settings-shell',
  templateUrl: './settingsSubShell.component.html',
  styleUrls: ['./settingsSubShell.component.scss']
})
export class SettingsSubShellComponent implements OnInit, OnDestroy {
  isSystemAdmin = false;
  guidSubscription: any;
  OrganisationID: string | null;
  cbAgentData: any;
  error: any;
  showCbAgentUpdateWarning = false;
  cbEnvChangeMessage = false;
  setUserDetails: any;
  showSettingSubfolder: boolean;
  constructor(
    private orgService: OrganisationService,
    private settingsService: SettingsService,
    private user: UserService
  ) {}

  ngOnInit() {
    this.setUserDetails = this.user.getuserdetails();

    for (let i = 0; i <= this.setUserDetails.user.role.length; i++) {
      if (this.setUserDetails.user.role[i] == 'organization_admin_role') {
        this.showSettingSubfolder = true;
      }
    }
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
    });
    // this.getCbAgentData();
    // this.settingsService.cbEnvChange$.subscribe(message => {
    //   this.cbEnvChangeMessage = message;
    //   if (this.cbEnvChangeMessage) {
    //     this.getCbAgentData();
    //   }
    // });
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  private getCbAgentData(): void {
    this.settingsService.getCbAgent(this.OrganisationID).subscribe(
      data => {
        this.cbAgentData = data ? data : '';
        if (this.cbAgentData.length > 0) {
          this.cbAgentData.find((activeAgent: any) => {
            if (activeAgent.active) {
              this.checkDeregisterUpdate(activeAgent.node_id);
            }
          });
        }
      },
      error => {
        this.error = error.error.message;
      }
    );
  }

  private checkDeregisterUpdate(node_id: string) {
    if (this.OrganisationID && node_id) {
      this.settingsService.checkDeregisterUpdate(this.OrganisationID, node_id).subscribe(data => {
        if (data && data['update_required']) {
          this.showCbAgentUpdateWarning = true;
          this.cbEnvChangeMessage = false;
        } else {
          this.showCbAgentUpdateWarning = false;
        }
      });
    }
  }
}
