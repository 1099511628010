import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrganisationAdminService } from '@app/Organisation-admin/organisation-admin.service';
import { EventScheduleDialogComponent } from '@app/Organisation-admin/EventsSchedule/eventsSchedule.component';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { OrganisationService } from '@app/core/organisation.service';
import { take } from 'rxjs/operators';
import { ModalService } from '@app/core/modal/modal.service';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import * as _ from 'lodash';
import { SpinnerService } from '@app/shared/spinner/spinner.service';
import { Observable } from 'rxjs';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
interface FieldData {
  data: string;
  edit: boolean;
  error: string;
}
interface FormData {
  name: FieldData;
  purpose: FieldData;
  description: FieldData;
  channel_name: FieldData;
  committee_name: FieldData;
  more_details_form: FieldData;
}
@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent implements OnInit {
  @Input() event: object;
  @Input() is_editable: boolean = true;
  @Output() public schedule_changed = new EventEmitter();
  minDate: NgbDateStruct;
  maxDate: NgbDateStruct;
  event_details: object | null = null;
  isRunning: boolean = false;
  isloading: boolean = false;
  isEDLoading: boolean = true;
  OrganisationID: string;
  eventDisableForm: FormGroup;
  showDetails: boolean = false;
  showMoreDetails: boolean = false;
  error: string = '';
  event_start_message: string = '';
  event_end_message: string = '';
  document_urls: Array<object>;
  isLoading: boolean = true;
  backend_error: any;
  eventNextRunDate: any;
  eventNextRunTime: any;

  selectedCommitteeName: string = '';
  isDateEdit: boolean = false;
  form: FormData = {
    name: { data: '', edit: false, error: '' },
    purpose: { data: '', edit: false, error: '' },
    description: { data: '', edit: false, error: '' },
    channel_name: { data: '', edit: false, error: '' },
    committee_name: { data: '', edit: false, error: '' },
    more_details_form: { data: '', edit: false, error: '' }
  };
  initialFormValues: {};
  eventCard: Observable<boolean>;
  guidSubscription: any;
  constructor(
    private formBuilder: FormBuilder,
    private orgAdminService: OrganisationAdminService,
    private orgService: OrganisationService,
    private router: Router,
    private NgbModalService: NgbModal,
    private modalService: ModalService,
    private translate: TranslateService,
    private toaster: ToasterService,
    public spinner: SpinnerService,
    private backendErrorService: BackendErrorService
  ) {
    const currentDate = new Date();
    this.minDate = { year: currentDate.getFullYear(), month: currentDate.getMonth() + 1, day: currentDate.getDate() };
    this.createForm();
  }
  public resetEventFormFields(key: string, value: any) {
    this.form[key].edit = !this.form[key].edit;
  }
  getSelectedCommittee(committee: any) {
    return _.find(committee, { use_this: true });
  }
  ngOnInit() {
    this.isDateEdit = false;
    // this.spinner.show()
    // this.eventCard = this.event;
    if (this.event['status'] === 'Running') {
      this.isRunning = true;
    }
    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
    });
    this.selectedCommitteeName = this.getSelectedCommittee(this.event['committee_name']);
    this.form = {
      name: { data: this.event['meeting_name'], edit: false, error: '' },
      purpose: { data: this.event['purpose'], edit: false, error: '' },
      description: { data: this.event['description'], edit: false, error: '' },
      channel_name: { data: this.event['channel_name'], edit: false, error: '' },
      committee_name: { data: this.selectedCommitteeName, edit: false, error: '' },
      more_details_form: { data: '', edit: false, error: '' }
    };
    this.initialFormValues = JSON.parse(JSON.stringify(this.form)); // this is used to for deep cloning.
    // this.spinner.hide();
    const currentEventDate = new Date(this.event['custom_next_run_time'] || this.event['next_run_time']);
    this.eventNextRunDate = {
      year: currentEventDate.getFullYear(),
      month: currentEventDate.getMonth() + 1,
      day: currentEventDate.getDate()
    };

    const next_run_time = new Date(this.event['next_run_time']);
    this.maxDate = {
      year: next_run_time.getFullYear(),
      month: next_run_time.getMonth() + 1,
      day: next_run_time.getDate() - 1
    };
    this.eventNextRunTime = this.event['schedule']?.time;
    this.createForm();
  }
  ngOnDestroy(): void {
    this.guidSubscription?.unsubscribe();
  }
  handleEnableClick(event: Event, type: any): void {
    if (!this.event['is_attached_to_evidence'] && type === 'Enable') {
      this.openEventEnableConfirmationDialog();
    } else if (!this.event['is_attached_to_evidence'] && type === 'Disable') {
      this.openEventDisabledReasonDialog();
    } else if (!this.event['is_attached_to_evidence'] && type === 'Disregard') {
      this.openEventDisregardReasonDialog();
    }
  }
  runEvent(guid: string) {
    this.isloading = true;
    this.isRunning = false;
    this.orgAdminService.runReviewEventNow(guid).subscribe(
      (data: any) => {
        this.isloading = false;
        this.isRunning = true;
      },
      error => {
        if (error.error.is_archived[0]) {
          this.translate.get(error.error.is_archived[0]).subscribe(res => {
            this.toaster.showError(res);
          });
        } else {
          this.translate.get('organisation_add_event.add_event_toaster_message.event_run_error').subscribe(res => {
            this.toaster.showError(res);
          });
        }
        this.isloading = false;
        this.isRunning = false;
      }
    );
  }
  stopEvent(guid: string) {
    if (this.isRunning) {
      this.isloading = true;
      this.orgAdminService.stopReviewEventNow(guid).subscribe(
        (data: any) => {
          this.isRunning = false;
          this.isloading = false;
        },
        error => {
          if (error.error.is_archived[0]) {
            this.translate.get(error.error.is_archived[0]).subscribe(res => {
              this.toaster.showError(res);
            });
          } else {
            this.translate.get('organisation_add_event.add_event_toaster_message.event_stop_error').subscribe(res => {
              this.toaster.showError(res);
            });
          }
          this.isloading = false;
        }
      );
    }
  }
  callUpdateEventFieldAPI(field_name: any, data: any) {
    this.orgAdminService.updateEventDetails(this.OrganisationID, this.event['review_event_guid'], data).subscribe(
      (resp: any) => {
        this.event[field_name] = resp[field_name];
        this.form[field_name]['edit'] = false;
        this.event['meeting_name'] = resp['meeting_name'];
        this.ngOnInit();
      },
      (error: any) => {
        this.form[field_name]['error'] = error.error;
        for (var key in this.form[field_name]['error']) {
          if (typeof this.form[field_name]['error'][key] === 'object') {
            this.form[field_name]['error'] = this.form[field_name]['error'][key][0];
          } else if (typeof this.form[field_name]['error'][key] === 'string') {
            this.form[field_name]['error'] = this.form[field_name]['error'][key];
          }
        }
      }
    );
  }
  callUpdateCommiteeEventAPI(field_name: any, data: any) {
    this.orgAdminService
      .updateEventCommitteeDetails(this.OrganisationID, this.event['review_event_guid'], data)
      .subscribe(
        (resp: any) => {
          this.event[field_name] = resp[field_name];
          this.selectedCommitteeName = this.getSelectedCommittee(resp[field_name]);
          this.form[field_name]['edit'] = false;
          this.ngOnInit();
        },
        (error: any) => {
          this.form[field_name]['error'] = error.error;
        }
      );
  }
  submitPartForm(field_name: string, submit_data: any = null) {
    if (submit_data) {
      this.orgAdminService
        .updateEventDetails(this.OrganisationID, this.event['review_event_guid'], submit_data)
        .subscribe(
          (resp: any) => {
            // TODO: add servisce for error
            const currentEventDate = new Date(resp['next_run_time']);
            this.eventNextRunDate = {
              year: currentEventDate.getFullYear(),
              month: currentEventDate.getMonth() + 1,
              day: currentEventDate.getDate()
            };
            this.maxDate = {
              year: currentEventDate.getFullYear(),
              month: currentEventDate.getMonth() + 1,
              day: currentEventDate.getDate()
            };
            this.isDateEdit = false;
            Object.assign(this.event, resp);
          },
          (error: any) => {
            this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
            this.backendErrorService.backendError$.subscribe(error => {
              this.backend_error = error;
              this.translate.get(this.backend_error).subscribe(res => {
                this.toaster.showError(res);
              });
            });
          }
        );
    } else {
      if (this.form[field_name]['data']) {
        let data = {};
        if (field_name === 'committee_name') {
          data = {
            org_rev_committee_guid: this.form[field_name]['data']['guid']
          };
          this.callUpdateCommiteeEventAPI(field_name, data);
        } else {
          data[field_name] = this.form[field_name]['data'];
          this.callUpdateEventFieldAPI(field_name, data);
        }
      } else {
        this.form[field_name]['error'] = 'This field is required.';
      }
    }
  }
  onChange(evt: any, field_name: any) {
    if (evt.type == 'keyup' && this.form[field_name].error) {
      this.form[field_name].error = '';
    }
  }
  resetField(field_name: string) {
    this.form[field_name]['data'] = this.initialFormValues[field_name]['data'];
    this.form[field_name].edit = !this.form[field_name].edit;
    this.form[field_name]['error'] = '';
  }
  openChangeScheduleDialog() {
    const dialogRef = this.NgbModalService.open(EventScheduleDialogComponent);
    dialogRef.componentInstance.dialogData = { event: this.event };
    dialogRef.result.then(result => {
      if (result) {
        this.event['schedule'] = result;
        console.log(this.event['schedule']);
        this.schedule_changed.emit();
      }
    });
  }
  openEventDetails() {
    this.isEDLoading = true;
    this.orgAdminService.getEventDetails(this.OrganisationID, this.event['review_event_guid']).subscribe(data => {
      this.event_details = data;
      this.error = data.error;
      this.event_start_message = data['event_start_message']
        ? data['event_start_message']
        : data['name'] + ' meeting has started.';
      this.event_end_message = data['event_end_message']
        ? data['event_end_message']
        : 'Thank you for participating in ' + data['name'] + ' meeting.';
      this.document_urls = data['review_document_urls'];
      this.isEDLoading = false;
      // this.createForm();
      this.toggleMoreDetails();
    });
  }
  openEventDisabledReasonDialog() {
    if (this.event['event_status'] !== 'disabled') {
      this.modalService
        .custom(CustomDialogComponent, {
          title: 'Disable ' + this.event['meeting_name'],
          message: 'Do you want to disable ' + this.event['meeting_name'],
          modalType: 'disable-disregard'
        })
        .pipe(take(1)) // take() manages unsubscription for us
        .subscribe((result: any) => {
          //  TODO : TO HANDLE THE SUCCESS AND ERROR MEESSAGE
          if (result['confirm']) {
            const data: any = {};
            data['event_status'] = 'disabled';
            data['reason'] = result['reason'];
            this.submitPartForm('status', data);
          }
        });
    }
  }
  openEventDisregardReasonDialog() {
    if (this.event['event_status'] !== 'disregard') {
      this.modalService
        .custom(CustomDialogComponent, {
          title: 'Disregard ' + this.event['meeting_name'],
          message: 'Do you want to disregard ' + this.event['meeting_name'],
          modalType: 'disable-disregard'
        })
        .pipe(take(1)) // take() manages unsubscription for us
        .subscribe((result: any) => {
          //  TODO : TO HANDLE THE SUCCESS AND ERROR MEESSAGE
          if (result['confirm']) {
            const data: any = {};
            data['event_status'] = 'disregard';
            data['reason'] = result['reason'];
            this.submitPartForm('status', data);
          }
        });
    }
  }
  openEventEnableConfirmationDialog() {
    if (this.event['event_status'] !== 'enabled') {
      this.modalService
        .confirm('Do you want enable ' + this.event['meeting_name'], 'Confirm')
        .pipe(take(1))
        .subscribe((confirmed: any) => {
          if (confirmed) {
            const data: any = {};
            data['event_status'] = 'enabled';
            this.submitPartForm('event_status', data);
          }
        });
    }
  }
  toggleDetails() {
    this.showDetails = !this.showDetails;
  }
  toggleMoreDetails() {
    this.showMoreDetails = !this.showMoreDetails;
  }
  goToLink(url: string) {
    window.open(url, '_blank');
  }
  private createForm() {
    this.eventDisableForm = this.formBuilder.group({
      eventDisableSlider: []
    });
  }
  submitDateChanges() {
    let date = this.eventNextRunDate.year + '-' + this.eventNextRunDate.month + '-' + this.eventNextRunDate.day;
    let finalDate = this.formatDate(date) + ' ' + this.eventNextRunTime;
    this.saveSchedule({ next_run_time: finalDate, update_next_run_time: true });
  }
  formatDate(inputDate: string) {
    var parts = inputDate.split('-');
    var year = parts[0];
    var month = parts[1];
    var day = parts[2];
    if (month.length === 1) {
      month = '0' + month;
    }
    if (day.length === 1) {
      day = '0' + day;
    }
    return year + '-' + month + '-' + day;
  }

  timeSet(time: any) {
    this.eventNextRunTime = time;
  }
  saveSchedule(data = {}) {
    this.orgAdminService.updateEventDateTime(this.OrganisationID, this.event['review_event_guid'], data).subscribe(
      res_data => {
        if (res_data['custom_next_run_time']) {
          this.event['custom_next_run_time'] = res_data['custom_next_run_time'];
        } else {
          this.event['next_run_time'] = res_data['next_run_time'];
        }
        const currentEventDate = new Date(this.event['next_run_time']);
        this.maxDate = {
          year: currentEventDate.getFullYear(),
          month: currentEventDate.getMonth() + 1,
          day: currentEventDate.getDate() - 1
        };
        this.isDateEdit = false;
      },
      error => {
        this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
          this.translate.get(this.backend_error).subscribe(res => {
            this.toaster.showError(res);
          });
        });
      }
    );
  }
  cancelChanges() {
    this.isDateEdit = false;
    this.eventNextRunDate = this.maxDate;
    this.eventNextRunTime = this.event['schedule']?.time;
  }
}
