<div style="position: relative;">
  <div class="header mb-0 d-flex pr-3 pl-3 justify-content-between align-items-center" mat-dialog-title>
    {{ row.title }}
  </div>
  <ng-container *ngIf="!isQuestionnaireView">
    <!-- 
    TODO: This code is commented out because of the "Answer Questionnaire" option, not for Phase 1. 
    Please do not remove this code.
-->
    <div class="answer-questionaire d-flex">
      <div>
        <!-- <div class="guidlines">Update the policies and procedures generated by AI.</div> -->
        <div class="guidlines">
          The policies and procedures have been generated using AI based on best practices. These can be edited and
          changed as needed.
        </div>
      </div>
      <!-- <div class="d-flex align-items-end">
        <button
          class="answer-questionaire-btn"
          [ngStyle]="{
            'border-color': orgAssesment?.length == 0 || !orgAssesment ? 'rgba(0,0,0,.26)' : '#2a647c'
          }"
          [disabled]="orgAssesment?.length == 0 || !orgAssesment"
          (click)="onCloseDialog(true)"
          [matTooltip]="
            orgAssesment?.length == 0 || !orgAssesment ? 'Assessment is not available for this control.' : ''
          "
          matTooltipPosition="below"
          matTooltipClass="cb-tooltip custom-tooltip"
          mat-stroked-button
        >
          Answer Questionnaire
        </button>
      </div> -->
    </div>

    <div class="mat-typography">
      <div class="div-container d-flex">
        <div class="pl-2" style="width: 50%;border-right: 1px solid #ccc;">
          <div>
            <div class="label">
              <span>
                <span class="pnp-label">Policy</span>
                <img
                  style="margin-top: -1px !important;"
                  class="ml-2"
                  *ngIf="isRestorePolicyDisabled"
                  src="./../../../assets/images/core/ai-generated.svg"
                  height="13px"
              /></span>
              <div class="edit-icons">
                <button
                  class="edit-btn"
                  mat-stroked-button
                  *ngIf="!row.isPolicyEditable && row.policy_text"
                  (click)="row.isPolicyEditable = !row.isPolicyEditable"
                >
                  Edit
                </button>

                <div class="icon-wrapper">
                  <button class="close-btn mr-2" *ngIf="row.isPolicyEditable" mat-button (click)="cancelPolicyEdit()">
                    Cancel
                  </button>
                  <button
                    class="close-btn mr-2"
                    *ngIf="row.isPolicyEditable"
                    mat-stroked-button
                    [ngStyle]="{
                      'border-color': isRestorePolicyDisabled ? 'rgba(0,0,0,.26)' : '#2a647c'
                    }"
                    [disabled]="isRestorePolicyDisabled"
                    (click)="restorePolicyText(row, true)"
                    matTooltip="Click here to restore the policy text to its previous version generated by AI."
                    matTooltipPosition="below"
                    matTooltipClass="cb-tooltip custom-tooltip"
                    [matTooltipDisabled]="isRestorePolicyDisabled"
                  >
                    Restore
                  </button>
                  <button
                    style="background-color: rgb(42, 100, 124);color: #ffffff;line-height: 30px;"
                    mat-flat-button
                    *ngIf="row.isPolicyEditable"
                    (click)="restorePolicyText(row, false)"
                    class="mat-save-btn"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
            <div class="value">
              <div class="policy-text" *ngIf="!row.isPolicyEditable && row.policy_text">
                {{ row.policy_text }}
              </div>
              <div class="policy-text" *ngIf="!row.isPolicyEditable && !row.policy_text">
                <ng-container *ngFor="let item of items; let i = index">
                  <ngx-skeleton-loader
                    [theme]="{ width: item + '%', height: '10px' }"
                    [appearance]="'line'"
                  ></ngx-skeleton-loader>
                </ng-container>
              </div>
              <textarea *ngIf="row.isPolicyEditable" [(ngModel)]="policyText" class="textarea-border"></textarea>
            </div>
          </div>
        </div>

        <div class="pr-2" style="width: 50%;">
          <div>
            <div class="label">
              <span>
                <span class="pnp-label">Procedure</span>
                <img
                  style="margin-top: -1px !important;"
                  class="ml-2"
                  *ngIf="isRestoreProcedureDisabled"
                  src="./../../../assets/images/core/ai-generated.svg"
                  height="13px"
              /></span>
              <div class="edit-icons">
                <button
                  class="edit-btn"
                  mat-stroked-button
                  *ngIf="!row.isProcedureEditable && row.procedure_text"
                  (click)="row.isProcedureEditable = !row.isProcedureEditable"
                >
                  Edit
                </button>
                <div class="icon-wrapper">
                  <button
                    class="close-btn mr-2"
                    *ngIf="row.isProcedureEditable"
                    mat-button
                    (click)="cancelProcedureEdit()"
                  >
                    Cancel
                  </button>
                  <button
                    class="close-btn mr-2"
                    *ngIf="row.isProcedureEditable"
                    mat-stroked-button
                    [ngStyle]="{
                      'border-color': isRestoreProcedureDisabled ? 'rgba(0,0,0,.26)' : '#2a647c'
                    }"
                    [disabled]="isRestoreProcedureDisabled"
                    (click)="restoreProcedureText(row, true)"
                    matTooltip="Click here to restore the procedure text to its previous version generated by AI."
                    matTooltipPosition="below"
                    matTooltipClass="cb-tooltip custom-tooltip"
                    [matTooltipDisabled]="isRestoreProcedureDisabled"
                  >
                    Restore
                  </button>

                  <button
                    style="background-color: rgb(42, 100, 124);color: #ffffff;line-height: 30px;"
                    mat-flat-button
                    *ngIf="row.isProcedureEditable"
                    (click)="restoreProcedureText(row, false)"
                    class="mat-save-btn"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
            <div class="value">
              <div class="policy-text" *ngIf="!row.isProcedureEditable && row.procedure_text">
                {{ row.procedure_text }}
              </div>
              <div class="policy-text" *ngIf="!row.isProcedureEditable && !row.procedure_text">
                <ng-container *ngFor="let item of items; let i = index">
                  <ngx-skeleton-loader
                    [theme]="{ width: item + '%', height: '10px' }"
                    [appearance]="'line'"
                  ></ngx-skeleton-loader>
                </ng-container>
              </div>
              <textarea
                *ngIf="row.isProcedureEditable"
                [(ngModel)]="procedureText"
                [value]="row.procedure_text"
                class="textarea-border"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isQuestionnaireView">
    <div class="answer-questionaire">
      <div class="d-flex justify-content-between">
        <div class="questionaire-label">
          <ng-container *ngIf="orgAssesment?.length > 0">
            Questionnaire
          </ng-container>
        </div>
        <div class="d-flex align-items-start">
          <button
            class="answer-questionaire-btn"
            style="height: 30px !important;"
            (click)="backToManualEditing()"
            mat-stroked-button
          >
            Back to Manual Editing
          </button>
          <button
            *ngIf="orgAssesment?.length > 0"
            style="background-color: rgb(42, 100, 124);color: #ffffff;line-height: 30px;"
            mat-flat-button
            [disabled]="compareAnswers(asmtResponse, orgAssesment) || validateAnswers(orgAssesment)"
            class="mat-save-btn ml-3"
            (click)="updateOrgAssesment()"
            [ngStyle]="{
              'background-color':
                compareAnswers(asmtResponse, orgAssesment) || validateAnswers(orgAssesment)
                  ? 'rgba(0,0,0,.26)'
                  : '#2a647c'
            }"
          >
            Regenerate
          </button>
        </div>
      </div>
      <div>
        <!-- <div class="guidlines">Note : Your updated responses will be saved when you go back to manual editing</div> -->
        <div class="guidlines" *ngIf="orgAssesment?.length > 0">
          Note : The policy and procedure document will regenerate only on clicking Regenerate
        </div>
      </div>
    </div>
    <div
      *ngIf="orgAssesment?.length > 0; else no_content"
      style="width: 100% ;display: flex;justify-content: center;flex-direction: column;align-items: center; padding: 0px 20px 20px;"
    >
      <div style="max-height: 630px; overflow-y: scroll; width: 100%;">
        <div>
          <div class="custom-accordion">
            <div *ngFor="let group of orgAssesment; let j = index">
              <div class="question-css">
                <h3 style="padding-right: 10px;font-weight: 600;">
                  {{ j + 1 }}. {{ group.question_details.question }}
                </h3>
                <div *ngIf="group.question_details.type === 'multiple_choice'">
                  <ul class="option-list">
                    <li *ngFor="let option of group.question_details.options; let k = index" class="option-item">
                      <div class="d-flex justify-content-between align-items-center">
                        <mat-checkbox
                          [checked]="
                            isOptionSelected(option, group?.answers) ||
                            (option?.opt?.toLowerCase() === 'other' && group.isOtherActive)
                          "
                          (change)="onCheckboxChange($event, option, group)"
                          style="font-size: 14px;"
                        >
                          {{ option?.opt }}
                        </mat-checkbox>
                        <img
                          style="margin-top: -1px !important;"
                          class="ml-2 mr-2"
                          *ngIf="isBestResponse(group.question_details.best_response, option.opt)"
                          src="./../../../assets/images/core/best-response.svg"
                          height="15px"
                        />
                      </div>
                      <div
                        *ngIf="option?.opt?.toLowerCase() === 'other' && group.isOtherActive"
                        class="other-option-container"
                      >
                        <input
                          type="text"
                          [value]="group?.answers"
                          placeholder="Enter an answer..."
                          [(ngModel)]="group.otherOption"
                          name="otherOption"
                          #otherOption="ngModel"
                          required
                        />
                        <div
                          *ngIf="
                            (otherOption.dirty || otherOption.touched || otherOption.invalid) &&
                            otherOption.errors?.required
                          "
                          class="text-danger"
                        >
                          *This field is required.
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div *ngIf="group.question_details.type === 'single_choice'">
                  <ul class="option-list">
                    <li *ngFor="let option of group.question_details.options; let k = index" class="option-item">
                      <div class="d-flex justify-content-between align-items-center">
                        <mat-radio-button
                          [checked]="
                            isOptionSelected(option, group?.answers) ||
                            (option?.opt?.toLowerCase() === 'other' && group.isOtherActive)
                          "
                          [value]="option?.opt"
                          name="{{ group.question_details.question }}"
                          (change)="onRadioChange($event, option, group)"
                          style="font-size: 14px;"
                        >
                          {{ option?.opt }}
                        </mat-radio-button>
                        <img
                          style="margin-top: -1px !important;"
                          class="ml-2 mr-2"
                          *ngIf="isBestResponse(group.question_details.best_response, option.opt)"
                          src="./../../../assets/images/core/best-response.svg"
                          height="15px"
                        />
                      </div>
                      <div
                        *ngIf="option?.opt?.toLowerCase() === 'other' && group.isOtherActive"
                        class="other-option-container"
                      >
                        <input
                          type="text"
                          [value]="group?.answers"
                          placeholder="Enter an answer..."
                          [(ngModel)]="group.otherOption"
                          name="otherOption"
                          #otherOption="ngModel"
                          required
                        />
                        <div
                          *ngIf="
                            (otherOption.dirty || otherOption.touched || otherOption.invalid) &&
                            otherOption.errors?.required
                          "
                          class="text-danger"
                        >
                          *This field is required.
                        </div>
                      </div>
                    </li>
                    <li
                      class="option-item"
                      *ngIf="group.answers && group.answers[0]?.opt === 'Yes' && group.question_details.follow_up"
                    >
                      <ng-container *ngFor="let eachQuestion of group.question_details.follow_up.Yes">
                        <h3 style="padding-right: 10px;font-weight: 600;margin-top: 20px;">
                          {{ eachQuestion.question }}
                        </h3>
                        <div>
                          <input
                            class="ml-0"
                            type="text"
                            [placeholder]="eachQuestion.question"
                            [(ngModel)]="eachQuestion.answers[0]"
                            name_email="name_email"
                            #name_email="ngModel"
                            required
                          />
                          <div
                            *ngIf="(name_email.dirty || name_email.touched) && name_email.errors?.required"
                            class="text-danger mt-1"
                          >
                            *This field is required.
                          </div>
                        </div>
                      </ng-container>
                    </li>
                  </ul>
                </div>
                <div *ngIf="group.question_details.type === 'nested_questions'">
                  <ul class="option-list">
                    <li *ngFor="let option of group.question_details.options; let k = index" class="option-item">
                      <div class="d-flex justify-content-between align-items-center">
                        <mat-radio-button
                          [checked]="
                            isOptionSelected(option, group?.answers) ||
                            (option?.opt?.toLowerCase() === 'other' && group.isOtherActive)
                          "
                          [value]="option?.opt"
                          name="{{ group.question_details.question }}"
                          (change)="onRadioChange($event, option, group)"
                          style="font-size: 14px;"
                        >
                          {{ option?.opt }}
                        </mat-radio-button>
                        <img
                          style="margin-top: -1px !important;"
                          class="ml-2 mr-2"
                          *ngIf="isBestResponse(group.question_details.best_response, option.opt)"
                          src="./../../../assets/images/core/best-response.svg"
                          height="15px"
                        />
                      </div>
                      <div
                        *ngIf="option?.opt?.toLowerCase() === 'other' && group.isOtherActive"
                        class="other-option-container"
                      >
                        <input
                          type="text"
                          [value]="group?.answers"
                          placeholder="Enter an answer..."
                          [(ngModel)]="group.otherOption"
                          name="otherOption"
                          #otherOption="ngModel"
                          required
                        />
                        <div
                          *ngIf="
                            (otherOption.dirty || otherOption.touched || otherOption.invalid) &&
                            otherOption.errors?.required
                          "
                          class="text-danger"
                        >
                          *This field is required.
                        </div>
                      </div>
                    </li>
                    <!-- nested loop -->
                    <ng-container
                      *ngIf="group.answers && group.answers[0]?.opt === 'Yes' && group.question_details.follow_up"
                    >
                      <div class="ml-4">
                        <ng-container *ngFor="let question of group.question_details.follow_up.Yes; let l = index">
                          <h3 style="padding-right: 10px;font-weight: 600;margin-top: 20px;">
                            {{ getAlphabet(l) }}. {{ question.question }}
                          </h3>
                          <div *ngIf="question.type === 'multiple_choice'">
                            <ul class="option-list">
                              <li *ngFor="let option of question.options; let k = index" class="option-item">
                                <div class="d-flex justify-content-between align-items-center">
                                  <mat-checkbox
                                    [checked]="
                                      isOptionSelected(option, question?.answers) ||
                                      (option?.opt?.toLowerCase() === 'other' && question.isOtherActive)
                                    "
                                    (change)="onCheckboxChange($event, option, question)"
                                    style="font-size: 14px;"
                                  >
                                    {{ option?.opt }}
                                  </mat-checkbox>
                                  <img
                                    style="margin-top: -1px !important;"
                                    class="ml-2 mr-2"
                                    *ngIf="isBestResponse(question.best_response, option.opt)"
                                    src="./../../../assets/images/core/best-response.svg"
                                    height="15px"
                                  />
                                </div>
                                <div
                                  *ngIf="option?.opt?.toLowerCase() === 'other' && question.isOtherActive"
                                  class="other-option-container"
                                >
                                  <input
                                    type="text"
                                    [value]="question?.answers"
                                    placeholder="Enter an answer..."
                                    [(ngModel)]="question.otherOption"
                                    name="otherOption"
                                    #otherOption="ngModel"
                                    required
                                  />
                                  <div
                                    *ngIf="
                                      (otherOption.dirty || otherOption.touched || otherOption.invalid) &&
                                      otherOption.errors?.required
                                    "
                                    class="text-danger"
                                  >
                                    *This field is required.
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div *ngIf="question?.type === 'single_choice'">
                            <ul class="option-list">
                              <li *ngFor="let option of question.options; let k = index" class="option-item">
                                <div class="d-flex justify-content-between align-items-center">
                                  <mat-radio-button
                                    [checked]="
                                      isOptionSelected(option, question?.answers) ||
                                      (option?.opt?.toLowerCase() === 'other' && question.isOtherActive)
                                    "
                                    [value]="option?.opt"
                                    name="{{ question?.question }}"
                                    (change)="onRadioChange($event, option, question)"
                                    style="font-size: 14px;"
                                  >
                                    {{ option?.opt }}
                                  </mat-radio-button>
                                  <img
                                    style="margin-top: -1px !important;"
                                    class="ml-2 mr-2"
                                    *ngIf="isBestResponse(question.best_response, option.opt)"
                                    src="./../../../assets/images/core/best-response.svg"
                                    height="15px"
                                  />
                                </div>
                                <div
                                  *ngIf="option?.opt?.toLowerCase() === 'other' && question.isOtherActive"
                                  class="other-option-container"
                                >
                                  <input
                                    type="text"
                                    [value]="question?.answers"
                                    placeholder="Enter an answer..."
                                    [(ngModel)]="question?.answers.otherOption"
                                    name="otherOption"
                                    #otherOption="ngModel"
                                    required
                                  />
                                  <div
                                    *ngIf="
                                      (otherOption.dirty || otherOption.touched || otherOption.invalid) &&
                                      otherOption.errors?.required
                                    "
                                    class="text-danger"
                                  >
                                    *This field is required.
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <mat-icon (click)="onCloseDialog(false)" class="cross-icon text-black-1">close</mat-icon>
</div>
<ng-template #no_content>
  <div>
    <div class="col">
      <img class="empty-doc-list-image" src="../../../assets/images/documents/empty.svg" />
      <div class="text-empty-doc-list text-black-1" translate>Assessment not found!</div>
    </div>
  </div>
</ng-template>
