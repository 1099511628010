import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganisationService } from '@app/core/organisation.service';
import { finalize } from 'rxjs/operators';
import { IncidentService } from '@app/Organisation-admin/incident/incident.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';

@Component({
  selector: 'app-unblock-incident',
  templateUrl: './unblock-incident.component.html',
  styleUrls: ['./unblock-incident.component.scss']
})
export class UnblockIncidentComponent implements OnInit {
  submitted = false;
  isLoading = false;
  OrganisationID: string | null;
  guidSubscription: any;
  success: boolean;
  error: any;
  loading = true;
  unblockIncidentForm: FormGroup;
  @Input() formType: string;
  @Input() incidentGuid: string;
  @Input() title_tag: string;
  @Input() message: string;
  @Input() incidentTitle: string;
  @Input() status_to_be_sent: string;
  @Input() action: string;
  backend_error: any;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private incidentService: IncidentService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getUnblockIncident();
    });
  }

  getUnblockIncident() {
    this.unblockIncidentForm = this.formBuilder.group({
      status: this.status_to_be_sent,
      action: this.action,
      remarks: ['', [Validators.required(), Validators.maxLength(4096)]]
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.unblockIncidentForm.controls;
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  save() {
    this.submitted = true;
    if (this.unblockIncidentForm.valid) {
      this.isLoading = true;
      const unblockIncident = this.unblockIncidentForm.value;
      this.submitCollaborationTask(unblockIncident);
    }
  }

  submitCollaborationTask(unblockIncident: any): void {
    this.incidentService
      .collaborationTask(this.OrganisationID, this.incidentGuid, unblockIncident)
      .pipe(
        finalize(() => {
          this.unblockIncidentForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.error = {
            status: '',
            remarks: ''
          };
          this.submitted = false;
          this.backend_error = '';
          this.activeModal.close({ result: data, isUpdate: true });
        },
        (error: any) => {
          this.submitted = false;
          this.isLoading = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
  }
}
