import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganisationService } from '@app/core/organisation.service';
import { finalize } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IncidentService } from '@app/Organisation-admin/incident/incident.service';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';

@Component({
  selector: 'app-reopen-incident',
  templateUrl: './reopen-incident.component.html',
  styleUrls: ['./reopen-incident.component.scss']
})
export class ReopenIncidentComponent implements OnInit {
  reopenIncidentForm: FormGroup;
  submitted = false;
  isLoading = false;
  OrganisationID: string | null;
  guidSubscription: any;
  success: boolean;
  error: any;
  @Input() formType: string;
  @Input() title_tag: string;
  @Input() incidentGuid: string;
  @Input() incidentTitle: string;
  @Input() status_to_be_sent: string;
  @Input() action: string;
  statusData: any;
  backend_error: string;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private incidentService: IncidentService
  ) {}

  ngOnInit() {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getreopenIncidentForm();
    });
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  getreopenIncidentForm() {
    this.reopenIncidentForm = this.formBuilder.group({
      status: this.status_to_be_sent,
      action: this.action,
      remarks: ['']
    });
  }

  changeName(statusData: any) {
    this.incidentService.setStatus(statusData);
    console.log(statusData);
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.reopenIncidentForm.controls;
  }

  save() {
    this.submitted = true;
    this.isLoading = true;
    if (this.reopenIncidentForm.valid) {
      const reopenIncident = this.reopenIncidentForm.value;
      this.submitCollaborationTask(reopenIncident);
    }
  }

  submitCollaborationTask(reopenIncident: any): void {
    this.incidentService
      .collaborationTask(this.OrganisationID, this.incidentGuid, reopenIncident)
      .pipe(
        finalize(() => {
          this.reopenIncidentForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.statusData = data;
          this.changeName(this.statusData);
          this.success = true;
          this.error = {
            status: '',
            remarks: ''
          };
          this.submitted = false;
          this.backend_error = '';
          this.activeModal.close(true);
        },
        (error: any) => {
          this.submitted = false;
          this.isLoading = false;
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
  }
}
