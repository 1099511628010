import { Component, OnInit } from '@angular/core';
import AttentionJson from 'app/Organisation-admin/attention_table.json';
import { ActivatedRoute, Router } from '@angular/router';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '../documents.service';
import { ModalService } from '@app/core/modal/modal.service';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { OrganisationAdminService } from '@app/Organisation-admin/organisation-admin.service';
import { take } from 'rxjs/operators';

// interface Template {
//   id: Number;
//   title: String;
//   created_by: String;
//   status: String;
//   last_changed: String;
//   due_date: String;
//   assigned_users: String;
// }

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent implements OnInit {
  OrganisationID: string;
  error: any;
  templateList: any;
  ItManagementList: any;
  guidSubscription: any;
  DocURL: any;
  page = 1;
  public pageSize = 10;
  totalLength: any;
  paginatedData: any;
  currentPage: number = 1;
  pagingNumber = 0;
  loaderArr: any = [];
  accArray: any[] = [];
  search_title: any;
  status: any = [];
  documentStatus: [];
  status_loading = false;
  statusFilter: any;
  search_text: any;
  update_status: any;
  title = 'Templates';
  role = 'templates';
  loading = true;
  constructor(
    private orgService: OrganisationService,
    private orgAdminService: OrganisationAdminService,
    private documentsService: DocumentsService,
    private modalService: ModalService,
    private toaster: ToasterService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
      this.getTemplateData();
      this.getStatusTypes();
    });
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  getTemplateData(): void {
    this.documentsService.getOrganizationTemplatesList(this.OrganisationID).subscribe(
      data => {
        console.log('sawan', data);
        this.loading = false;
        this.templateList = data.results;
        this.totalLength = data.count;
      },
      error => {
        this.error = error.error.message;
        this.loading = false;
        console.log('Error:', error.error.message);
      }
    );
  }

  updateSearchText(x: any) {
    this.search_text = x.target.value;
    if (this.search_title == undefined) {
      this.search_text = '';
    }
  }

  updateStatus(y: any) {
    this.update_status = y.target.value;
    if (this.statusFilter == undefined) {
      this.update_status = '';
    }
  }

  Search(search_title: string) {
    this.search_title = search_title;
    for (let i = 0; i < this.templateList.length; i++) {
      if (this.templateList[i]['title'] != this.search_title) {
        this.page = 1;
      }
    }
    if (this.statusFilter == undefined) {
      this.statusFilter = '';
    }
    this.documentsService
      .getSearchResultList(this.OrganisationID, this.search_title, this.page, this.statusFilter, this.role)
      .subscribe(
        data => {
          this.totalLength = data.count;
          this.templateList = data.results;
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
  }

  getStatusTypes() {
    if (this.OrganisationID !== null) {
      this.status_loading = true;
      this.orgAdminService.getOrganisationStatusTypes().subscribe(data => {
        this.documentStatus = data;
        this.status_loading = false;
      });
    }
  }

  load_status_list() {
    this.getStatusTypes();
  }

  onStatusChange(status: any, value: any) {
    this.statusFilter = status;
    if (this.search_text === '') {
      this.search_text = this.search_title;
    }
    if (this.search_title == undefined) {
      this.search_text = '';
    }

    for (let i = 0; i < this.templateList.length; i++) {
      if (this.templateList[i]['status'] != this.statusFilter) {
        this.page = 1;
      }
    }
    this.documentsService
      .getSearchAndFilterList(this.OrganisationID, this.search_text, this.statusFilter, this.page, this.role)
      .subscribe(
        data => {
          //this.Events = data;
          this.totalLength = data.count;
          this.templateList = data.results;
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
  }

  clearLoader(value: any): void {
    const index = this.loaderArr.indexOf(value);
    if (index > -1) {
      this.loaderArr.splice(index, 1);
    }
  }

  // templates: Template[] = AttentionJson;
  onNavigate(docGuid: string) {
    this.documentsService.getOrganizationDocumentEditURl(this.OrganisationID, docGuid, '').subscribe(
      data => {
        this.DocURL = data;
        window.open(this.DocURL.doc_store_url, '_blank');
        this.ngOnInit();
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }

  // Manage Editors code
  InviteEditorOpenModal(DocumentGuid: string, DocCreatedBy: string, docTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Manage Editors',
        modalType: 'Manage Editors',
        DocumentGuid: DocumentGuid,
        DocCreatedBy: DocCreatedBy
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getITManagementsList();
          this.translate.get('Document_Invite_Editor.invite_editor_toaster_message.invite_editor').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }
  getITManagementsList() {
    throw new Error('Method not implemented.');
  }

  // View Editors Code
  ViewEditorsOpenModal(DocumentGuid: string, docTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'View Editors',
        modalType: 'View Editors',
        DocumentGuid: DocumentGuid,
        docTitle: docTitle
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getITManagementsList();
        } else {
          console.log('Cancelled.');
        }
      });
  }

  // Mark Edit Done Code
  MarkEditDoneOpenModal(DocumentGuid: string, docTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Mark Editing as Done',
        modalType: 'Mark Editing as Done',
        DocumentGuid: DocumentGuid,
        docTitle: docTitle
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getITManagementsList();
          this.translate
            .get('Mark_Editing_Done.mark_edit_done_toaster_message.mark_edit_done_submitted')
            .subscribe(res => {
              this.toaster.showSuccess(res);
            });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  // Invite Approvers Code
  InviteApproverOpenModal(DocumentGuid: string, DocCreatedBy: string, docTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Manage Approvers',
        modalType: 'Manage Approvers',
        DocumentGuid: DocumentGuid,
        DocCreatedBy: DocCreatedBy,
        docTitle: docTitle
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getITManagementsList();
          this.translate
            .get('Document_Invite_Approver.invite_approver_toaster_message.invite_approver')
            .subscribe(res => {
              this.toaster.showSuccess(res);
            });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  // Request Change Code
  ChangeRequestOpenModal(DocumentGuid: string, docTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Request Change',
        modalType: 'Request Change',
        DocumentGuid: DocumentGuid,
        docTitle: docTitle
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getITManagementsList();
          this.translate
            .get('Change_Request.change_request_toaster_message.change_request_submitted')
            .subscribe(res => {
              this.toaster.showSuccess(res);
            });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  // View Approvers Code
  ViewApproversOpenModal(DocumentGuid: string, docTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'View Approvers',
        modalType: 'View Approvers',
        DocumentGuid: DocumentGuid,
        docTitle: docTitle
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getITManagementsList();
        } else {
          console.log('Cancelled.');
        }
      });
  }

  // Approve Changes Code
  ApproveChangesOpenModal(DocumentGuid: string, docTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Are you sure you want to approve changes ?',
        modalType: 'Approve Changes',
        DocumentGuid: DocumentGuid,
        docTitle: docTitle
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getITManagementsList();
          this.translate
            .get('Approve_Changes.approve_changes_toaster_message.approve_changes_submitted')
            .subscribe(res => {
              this.toaster.showSuccess(res);
            });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  // Publish Document Code
  ViewPublishDocumentOpenModal(DocumentGuid: string, DocName: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Publish Document',
        modalType: 'Publish Document',
        DocumentGuid: DocumentGuid,
        message: 'Are you sure you want to publish document ' + DocName + ' ?'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getITManagementsList();
          this.translate
            .get('Publish_Document.publish_document_toaster_message.publish_document_submitted')
            .subscribe(res => {
              this.toaster.showSuccess(res);
            });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  getClickEvents(action: string, DocGuid: string, createdBy: string, docTitle: string): void {
    if (action == 'Edit Document') {
      this.onNavigate(DocGuid);
    } else if (action == 'Manage Editors') {
      this.InviteEditorOpenModal(DocGuid, createdBy, docTitle);
    } else if (action == 'View Editors') {
      this.ViewEditorsOpenModal(DocGuid, docTitle);
    } else if (action == 'Complete Edit') {
      this.MarkEditDoneOpenModal(DocGuid, docTitle);
    } else if (action == 'Manage Approvers') {
      this.InviteApproverOpenModal(DocGuid, createdBy, docTitle);
    } else if (action == 'Request Change') {
      this.ChangeRequestOpenModal(DocGuid, docTitle);
    } else if (action == 'View Approvers') {
      this.ViewApproversOpenModal(DocGuid, docTitle);
    } else if (action == 'Approve Document') {
      this.ApproveChangesOpenModal(DocGuid, docTitle);
    } else if (action == 'Publish Document') {
      this.ViewPublishDocumentOpenModal(DocGuid, docTitle);
    }
  }

  goToCreateTemplate() {
    this.router.navigate(['organisation/documents/create-template']);
  }

  goToCreateDoc() {
    this.router.navigate(['organisation/documents/create-document']);
  }
}
