import { Component, OnInit } from '@angular/core';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '../documents.service';
import { ModalService } from '@app/core/modal/modal.service';
import { take } from 'rxjs/operators';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-risk-management',
  templateUrl: './risk-management.component.html',
  styleUrls: ['./risk-management.component.scss']
})
export class RiskManagementComponent implements OnInit {
  OrganisationID: string | null;
  RiskManagementList: any;
  guidSubscription: any;
  error: any;
  DocURL: any;
  loaderArr: any = [];
  accArray: any[] = [];
  loading = true;
  title = 'Risk Management';

  constructor(
    private orgService: OrganisationService,
    private documentsService: DocumentsService,
    private modalService: ModalService,
    private toaster: ToasterService,
    private translate: TranslateService,
    private router: Router
  ) {}

  ngOnInit() {
    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
      this.getRiskManagementsList();
    });
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  getRiskManagementsList(): void {
    this.documentsService.getOrganizationRiskManagementsList(this.OrganisationID).subscribe(
      data => {
        this.loading = false;
        this.RiskManagementList = data.results;
      },
      error => {
        this.error = error.error.message;
        this.loading = false;
        console.log('Error:', error.error.message);
      }
    );
  }

  clearLoader(value: any): void {
    const index = this.loaderArr.indexOf(value);
    if (index > -1) {
      this.loaderArr.splice(index, 1);
    }
  }

  onNavigate(docGuid: string) {
    this.documentsService.getOrganizationDocumentEditURl(this.OrganisationID, docGuid, '').subscribe(
      data => {
        this.OpenDocument(data.doc_store_url);
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }

  OpenDocument(url: string) {
    window.open(url, '_blank');
    this.getRiskManagementsList();
  }

  // Manage Editors code
  InviteEditorOpenModal(DocumentGuid: string, DocCreatedBy: string, docTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Manage Editors',
        modalType: 'Manage Editors',
        DocumentGuid: DocumentGuid,
        DocCreatedBy: DocCreatedBy,
        docTitle: docTitle
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getRiskManagementsList();
          this.translate.get('Document_Invite_Editor.invite_editor_toaster_message.invite_editor').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  // View Editors Code
  ViewEditorsOpenModal(DocumentGuid: string, docTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'View Editors',
        modalType: 'View Editors',
        DocumentGuid: DocumentGuid,
        docTitle: docTitle
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getRiskManagementsList();
        } else {
          console.log('Cancelled.');
        }
      });
  }

  // Mark Edit Done Code
  MarkEditDoneOpenModal(DocumentGuid: string, docTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Mark Editing as Done',
        modalType: 'Mark Editing as Done',
        DocumentGuid: DocumentGuid,
        docTitle: docTitle
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getRiskManagementsList();
          this.translate
            .get('Mark_Editing_Done.mark_edit_done_toaster_message.mark_edit_done_submitted')
            .subscribe(res => {
              this.toaster.showSuccess(res);
            });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  // Invite Approvers Code
  InviteApproverOpenModal(DocumentGuid: string, DocCreatedBy: string, docTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Manage Approvers',
        modalType: 'Manage Approvers',
        DocumentGuid: DocumentGuid,
        DocCreatedBy: DocCreatedBy,
        docTitle: docTitle
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getRiskManagementsList();
          this.translate
            .get('Document_Invite_Approver.invite_approver_toaster_message.invite_approver')
            .subscribe(res => {
              this.toaster.showSuccess(res);
            });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  // View Approvers Code
  ViewApproversOpenModal(DocumentGuid: string, docTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'View Approvers',
        modalType: 'View Approvers',
        DocumentGuid: DocumentGuid,
        docTitle: docTitle
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getRiskManagementsList();
        } else {
          console.log('Cancelled.');
        }
      });
  }

  // Approve Changes Code
  ApproveChangesOpenModal(DocumentGuid: string, docTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Are you sure you want to approve changes ?',
        modalType: 'Approve Changes',
        DocumentGuid: DocumentGuid,
        docTitle: docTitle
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getRiskManagementsList();
          this.translate
            .get('Approve_Changes.approve_changes_toaster_message.approve_changes_submitted')
            .subscribe(res => {
              this.toaster.showSuccess(res);
            });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  // Invite editors for Request Change modal popup Code
  InviteEditorsForChangeReqOpenModal(DocumentGuid: string, DocCreatedBy: string, docTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Invite Editors for Request Change',
        modalType: 'Invite Editors for Request Change',
        DocumentGuid: DocumentGuid,
        DocCreatedBy: DocCreatedBy,
        docTitle: docTitle
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getRiskManagementsList();
          this.translate.get('Document_Invite_Editor.invite_editor_toaster_message.invite_editor').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  // Publish Document Code
  ViewPublishDocumentOpenModal(DocumentGuid: string, DocName: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Publish Document',
        modalType: 'Publish Document',
        DocumentGuid: DocumentGuid,
        message: 'Are you sure you want to publish document ' + DocName + ' ?'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getRiskManagementsList();
          this.translate
            .get('Publish_Document.publish_document_toaster_message.publish_document_submitted')
            .subscribe(res => {
              this.toaster.showSuccess(res);
            });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  goToCreateDoc() {
    this.router.navigate(['organisation/documents/create-document']);
  }

  getClickEvents(action: string, DocGuid: string, createdBy: string, docTitle: string, url: string): void {
    if (action == 'Edit Document') {
      this.onNavigate(DocGuid);
    } else if (action == 'Manage Editors') {
      this.InviteEditorOpenModal(DocGuid, createdBy, docTitle);
    } else if (action == 'View Editors') {
      this.ViewEditorsOpenModal(DocGuid, docTitle);
    } else if (action == 'Complete Edit') {
      this.MarkEditDoneOpenModal(DocGuid, docTitle);
    } else if (action == 'Manage Approvers') {
      this.InviteApproverOpenModal(DocGuid, createdBy, docTitle);
    } else if (action == 'Request Change') {
      this.InviteEditorsForChangeReqOpenModal(DocGuid, createdBy, docTitle);
    } else if (action == 'View Approvers') {
      this.ViewApproversOpenModal(DocGuid, docTitle);
    } else if (action == 'Approve Document') {
      this.ApproveChangesOpenModal(DocGuid, docTitle);
    } else if (action == 'Publish Document') {
      this.ViewPublishDocumentOpenModal(DocGuid, docTitle);
    } else if (action == 'View Document') {
      this.OpenDocument(url);
    }
  }
}
