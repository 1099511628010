<div class="publish-doc-container">
  <div class="publish-doc-label">
    <div class="label">{{ title_tag }}</div>
    <div>
      <i
        class="fa fa-times text-black-1"
        style="width: 12px;
                  height: 12px;
                  right: 1em;
                  cursor: pointer;
                  position: absolute;
                  top: 1.3em;"
        (click)="activeModal.close()"
        ngbAutoFocus
        translate
      ></i>
    </div>
  </div>
  <div class="text-body">
    <div class="text" [innerHtml]="message"></div>
  </div>
  <div class="actions-container">
    <button type="button" class="btn-cancel" (click)="activeModal.close()">Cancel</button>
    <button type="submit" class="btn-save" *ngIf="!isLoading" (click)="save()">Yes, Remove</button>
    <button type="submit" class="btn-save" disabled *ngIf="isLoading"><i class="fa fa-spinner fa-spin"></i></button>
  </div>
</div>
