<app-invite-editors
  *ngIf="modalType === 'Manage Editors' || modalType === 'Manage Approvers'"
  [title_tag]="title"
  [DocumentGuid]="DocumentGuid"
  [DocCreatedBy]="DocCreatedBy"
  [docTitle]="docTitle"
  [type]="modalType"
></app-invite-editors>
<app-edit-editor-approver
  *ngIf="modalType === 'Edit Due Date'"
  [guid]="guid"
  [DocumentGuid]="DocumentGuid"
  [userEmail]="userEmail"
  [user]="user"
  [start_message]="start_message"
  [due_date]="due_date"
  [role]="role"
></app-edit-editor-approver>
<app-reassign-editor-approver
  *ngIf="modalType === 'Reassign Collaborator'"
  [guid]="guid"
  [DocumentGuid]="DocumentGuid"
  [userEmail]="userEmail"
  [user]="user"
  [due_date]="due_date"
  [role]="role"
></app-reassign-editor-approver>
<app-folder-selection
  *ngIf="modalType === 'document'"
  [title_tag]="title"
  [formType]="formType"
  [form_data]="formData"
  [storage_id]="storage_id"
></app-folder-selection>
<app-select-template
  *ngIf="modalType === 'Select Template'"
  [title_tag]="title"
  [template_guid]="template_guid"
  [storage_id]="storage_id"
  [type]="type"
  [template_type]="template_type"
>
</app-select-template>
<app-create-folder *ngIf="modalType === 'Create Folder'" [folderName]="folderName"> </app-create-folder>
<app-create-new-version
  *ngIf="modalType === 'Create New Version'"
  [title_tag]="title"
  [DocumentGuid]="DocumentGuid"
  [DocumentVersion]="DocumentVersion"
  [docTitle]="docTitle"
>
</app-create-new-version>
<app-request-change
  *ngIf="modalType === 'Invite Editors for Request Change'"
  [title_tag]="title"
  [DocumentGuid]="DocumentGuid"
  [DocCreatedBy]="DocCreatedBy"
  [docTitle]="docTitle"
  [modalType]="modalType"
>
</app-request-change>
<app-add-readers
  *ngIf="modalType === 'Add Readers'"
  [title_tag]="title"
  [guid]="guid"
  [readerEmails]="readerEmails"
  [DocumentVersion]="DocumentVersion"
>
</app-add-readers>
<app-add-new-reader *ngIf="modalType === 'Add New Reader'" [title_tag]="title"></app-add-new-reader>
<app-mapped-controls
  *ngIf="modalType === 'Mapped Controls'"
  [controls]="controls"
  [code]="code"
  [control]="control"
></app-mapped-controls>
