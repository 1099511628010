<div id="mySidenav" class="sidenav">
  <div class="sidenav-header d-flex justify-content-between">
    <span class="title"> Mapped Controls</span>
    <i class="fa fa-times text-black-50  closebtn" (click)="closeNav()" ngbAutoFocus translate></i>
  </div>

  <div class="pt-3 pb-3">
    <div class="table-container px-3">
      <div class="d-flex px-2">
        <div class="mr-5">
          <div>
            <div class="sidenav-title">CONTROL</div>
            <div class="my-1 font-weight-lighter">{{ control }}</div>
          </div>
        </div>
        <div class="margin-32">
          <div>
            <div class="sidenav-title">TITLE</div>
            <div class="my-1 font-weight-lighter">{{ code }}</div>
          </div>
        </div>
      </div>

      <table class="mt-3">
        <thead>
          <tr>
            <th>CONTROL</th>
            <th>FRAMEWORK</th>
            <th>TITLE</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of controls; let i = index">
            <tr [ngClass]="{ expanded: expandedIndex === i }">
              <td>{{ item.code }}</td>
              <td>
                <span class="pill" [ngClass]="getFrameworkClass(item?.framework)">{{ item?.framework }}</span>
              </td>
              <td style="width: 200px;">
                {{ item.title }}
              </td>
              <td class="text-right cursor-pointer">
                <span (click)="toggleAccordion(i)" class="icon">
                  <mat-icon>{{ expandedIndex === i ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</mat-icon>
                </span>
              </td>
            </tr>
            <tr *ngIf="expandedIndex === i" [ngClass]="{ expanded: expandedIndex === i }">
              <td colspan="4">
                <div class="accordion-content">
                  <div class="regulatory-title">REGULATORY TEXT</div>
                  <p class="regulatory-text mt-2">{{ item.regulatory_text }}</p>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
