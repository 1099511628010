import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { extract } from '@app/core';
import { OrgAdminShell } from '@app/shells/orgAdminshell/orgAdminshell.service';
import { LeadComponent } from './Lead-generation/lead.component';
import { GrcDataComponent } from './grc-data/grc-data.component';
const routes: Routes = [
  OrgAdminShell.childRoutes([{ path: 'leads', component: LeadComponent, data: { title: extract('Lead Generated') } }]),
  OrgAdminShell.childRoutes([{ path: 'GRC', component: GrcDataComponent, data: { title: extract('GRC Generated') } }])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class OrganisationSystemAdminRoutingModule {}
