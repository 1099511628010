<div>
  <div class="modal-header cb-modal-header">
    <h4 class="modal-title font-weight-bold text-black-1">{{ title }}</h4>
  </div>
  <div class="modal-body">
    <p>
      {{ message }}
      <span
        ><strong>{{ optionalMessage }}</strong></span
      >
    </p>
  </div>
  <div class="modal-footer border-0 cb-modal-footer">
    <button class="btn-cancel ml-4 w-100" (click)="activeModal.close(false)" translate>Confirm_Dialog.Cancel</button>
    <button class="btn-save ml-4 w-100" (click)="activeModal.close(true)" translate>Confirm_Dialog.Submit</button>
  </div>
</div>
