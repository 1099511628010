import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { DemoMaterialModule } from '../../materials.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { OrgAdminShellHeaderComponent } from '@app/shells/orgAdminshell/header/orgAdminshellHeader.component';
import { OrgAdminShellComponent } from '@app/shells/orgAdminshell/orgAdminshell.component';
import { SharedModule } from '@app/shared';
import { OrgAdminshellSidenavComponent } from '@app/shells/orgAdminshell/sidenav/orgAdminshellSidenav.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WizardConfigurationComponent } from '@app/shells/orgAdminshell/wizard-configuration/wizardConfiguration.component';

@NgModule({
  imports: [CommonModule, TranslateModule, SharedModule, RouterModule, FlexLayoutModule, NgbModule, DemoMaterialModule],
  declarations: [
    OrgAdminShellHeaderComponent,
    OrgAdminshellSidenavComponent,
    OrgAdminShellComponent,
    WizardConfigurationComponent
  ],
  providers: [],
  exports: [OrgAdminShellHeaderComponent]
})
export class OrgAdminshellModule {}
