import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { environment } from '@env/environment';
import { OrganisationService } from './organisation.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private websocket: WebSocket;
  private messageSubject: Subject<any> = new Subject<any>();
  url: string;
  OrganisationID: string | null;
  orgGuidSubscription: Subscription;
  constructor(private orgService: OrganisationService) {
    this.orgGuidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
    });
    if (environment.serverUrl) {
      this.url = environment.serverUrl.split('://')[1];
    }
  }

  connect(): void {
    // this.websocket = new WebSocket(`ws://${this.url}/organizations/${this.OrganisationID}/ws/data/`);
    this.websocket = new WebSocket(`wss://${location.hostname}/api/organizations/${this.OrganisationID}/ws/data/`);
    this.websocket.onopen = () => {
      console.log('WebSocket connected');
      this.websocket.send(JSON.stringify({ message: 'Ping' }));
      setTimeout(() => {
        this.websocket.send(JSON.stringify({ message: 'Ping - 20 seconds' }));
      }, 20000);
    };

    this.websocket.onmessage = event => {
      const message = JSON.parse(event.data);
      this.messageSubject.next(message);
      console.log(message);
    };
    this.websocket.onclose = () => {
      console.log('WebSocket disconnected');
    };
  }

  getMessage(): Observable<any> {
    return this.messageSubject.asObservable();
  }

  close(): void {
    console.log('I AM in close method');
    if (this.websocket) {
      this.websocket.close();
    }
  }
}
