<div>
  <div class="modal-header cb-modal-header">
    <h4 class="modal-title font-weight-bold text-black-1" translate>Category.Create_Category</h4>
  </div>
  <div class="modal-body">
    <div class="row mt-1 mb-3" *ngIf="backend_error">
      <div class="col-12">
        <span class="text-danger backend_error" translate> * {{ backend_error }}</span>
      </div>
    </div>

    <form [formGroup]="createCategoryForm">
      <div class="row mt-3">
        <div class="col-4">
          <label for="category-name" class="text-black-1" translate>Category.Name</label>
        </div>
        <div class="input-group col-8">
          <input
            id="category-name"
            class="form-control cb-input"
            [placeholder]="'Enter the Category name'"
            name="name"
            type="text"
            formControlName="name"
            (keyup)="onChangeTitle($event)"
            [maxLength]="256"
          />
          <div class="col-12 error-mssg" *ngIf="(f.name.touched || submitted) && f.name.errors">
            <label for="name" class="text-danger" *ngIf="f.name.errors.required" translate
              >Category.*Name is required.</label
            >
          </div>
          <div class="col-12 error-mssg" *ngIf="(f.name.touched || f.name.dirty) && f.name.errors">
            <label for="name" class="text-danger" *ngIf="f.name.errors.maxLength" translate
              >Category.*Name must be 255 characters or less</label
            >
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-4">
          <label for="charter" class="text-black-1" translate>Category.Description</label>
        </div>
        <div class="input-group col-8">
          <textarea
            style="height: 100px;"
            class="form-control cb-input"
            [placeholder]="'Description'"
            formControlName="description"
            [maxLength]="4097"
          >
          </textarea>
          <div class="col-12 error-mssg" *ngIf="(f.description.touched || submitted) && f.description.errors">
            <label for="description" class="text-danger" *ngIf="f.description.errors.required" translate
              >Category.Category description is Required</label
            >
          </div>
          <div class="col-12 error-mssg" *ngIf="(f.description.touched || f.description.dirty) && f.description.errors">
            <label for="description" class="text-danger" *ngIf="f.description.errors.maxLength" translate
              >Category.Description must be 4096 characters or less</label
            >
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer border-0 cb-modal-footer">
    <button class="btn-cancel ml-4 w-100" (click)="activeModal.close(false)" ngbAutoFocus translate>
      Category.Cancel
    </button>
    <button
      class="btn-save ml-4 w-100"
      *ngIf="!isLoading"
      [ngStyle]="{
        'background-color': createCategoryForm.invalid ? 'rgba(0,0,0,.26)' : '#4db7c6'
      }"
      [disabled]="createCategoryForm.invalid"
      (click)="save()"
      translate
    >
      Category.Submit
    </button>
    <button class="btn-save ml-4 w-100" disabled *ngIf="isLoading">
      <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
    </button>
  </div>
</div>
