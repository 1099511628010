import { Component, OnDestroy, OnInit } from '@angular/core';
import { OrganisationService } from '@app/core/organisation.service';
import { SettingsService } from '@app/settings/settings.service';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';

declare var require: any;
const moment = require('moment');

@Component({
  selector: 'app-operation',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.scss']
})
export class OperationComponent implements OnInit, OnDestroy {
  OrganisationID: string | null;
  error: any;
  guidSubscription: any;
  selectedTimezone = '';
  selectedCbAgentEnvironment = '';
  organizationLogo = '';
  orgDomain = '';
  bitbucketRepoUrl = '';
  officeStartHours = '';
  officeEndHours = '';
  operationData: object[];
  isLoading = false;
  submitted = false;
  success = false;
  operationShowData: object[];
  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#fff',
      buttonColor: '#FFFFFF'
    },
    dial: {
      dialBackgroundColor: '#555'
    },
    clockFace: {
      clockFaceBackgroundColor: '#fff',
      clockHandColor: 'black',
      clockFaceTimeInactiveColor: 'black'
    }
  };
  organisationForm: FormGroup;
  configurationForm: FormGroup;
  loading = true;
  password_based_login: any;
  document_renewal_threshold: any;
  training_campaign_renewal_threshold: any;
  days_before_due_date_threshold_for_campaign: any;

  static convertTimeTo24Format(time: any) {
    return moment(time, ['h:mm A']).format('HH:mm');
  }

  constructor(
    private orgService: OrganisationService,
    private settingsService: SettingsService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private formBuilder: FormBuilder
  ) {}

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getOperationData();
    });
  }

  get f() {
    return this.organisationForm.controls;
  }

  onReset(): void {
    this.getOperationData();
  }

  save(): void {
    this.submitted = true;
    this.operationShowData['org_timezone'] = this.selectedTimezone;
    this.operationShowData['cbagent_environment'] = this.selectedCbAgentEnvironment;
    this.operationShowData['public_logo'] = this.organisationForm.value.organizationLogo;
    this.operationShowData['org_domain'] = this.organisationForm.value.orgDomain;
    this.operationShowData['office_start_hours'] = this.convertTimeTo24Format(this.officeStartHours);
    this.operationShowData['office_end_hours'] = this.convertTimeTo24Format(this.officeEndHours);
    this.operationShowData['password_based_login'] = this.password_based_login;
    this.operationShowData['recurring_configuration'] = {
      document_renewal_threshold: this.configurationForm.value.document_renewal_threshold,
      training_campaign_renewal_threshold: this.configurationForm.value.training_campaign_renewal_threshold,
      days_before_due_date_threshold_for_campaign: this.configurationForm.value
        .days_before_due_date_threshold_for_campaign
    };
    this.settingsService
      .submitOperationalUserProfile(this.operationShowData, this.OrganisationID)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.success = true;
          this.submitted = false;
          this.selectedTimezone = data['org_timezone'];
          this.selectedCbAgentEnvironment = data['cbagent_environment'];
          this.organizationLogo = data['public_logo'];
          this.orgDomain = data['org_domain'];
          this.bitbucketRepoUrl = data['bitbucket_repo_url'];
          this.password_based_login = data['password_based_login'];
          this.document_renewal_threshold = data['document_renewal_threshold'];
          this.training_campaign_renewal_threshold = data['training_campaign_renewal_threshold'];
          this.days_before_due_date_threshold_for_campaign = data['days_before_due_date_threshold_for_campaign'];
          this.setForm();
          this.translate.get('organisation_operations.operation_toaster_message.update_operation').subscribe(res => {
            this.toaster.showSuccess(res);
          });
          localStorage.setItem('org_password_based_login', data['password_based_login']);
        },
        (error: any) => {
          this.error = error.error;
        }
      );
  }

  /** Detect change in program on selection **/
  timezoneChange(event: any) {
    this.selectedTimezone = event;
  }

  cbAgentEnvironmentChange(event: any) {
    this.selectedCbAgentEnvironment = event;
  }

  organizationLogoChange(event: any) {
    this.operationShowData['public_logo'] = event;
  }

  orgDomainChange(event: any) {
    this.operationShowData['org_domain'] = event;
  }

  startTimeSet(time: any) {
    this.officeStartHours = this.convertTimeTo24Format(time);
  }

  endTimeSet(time: any) {
    this.officeEndHours = this.convertTimeTo24Format(time);
  }

  convertTimeTo24Format(time: any) {
    return moment(time, ['h:mm A']).format('HH:mm');
  }

  setForm() {
    this.organisationForm = this.formBuilder.group({
      orgDomain: [this.orgDomain, Validators.httpsURL()],
      organizationLogo: [this.organizationLogo, Validators.httpsURL()]
    });

    this.configurationForm = this.formBuilder.group({
      document_renewal_threshold: [this.document_renewal_threshold, Validators.required()],
      training_campaign_renewal_threshold: [this.training_campaign_renewal_threshold, Validators.required()],
      days_before_due_date_threshold_for_campaign: [
        this.days_before_due_date_threshold_for_campaign,
        Validators.required()
      ]
    });
  }

  private getOperationData(): void {
    this.settingsService.getOrganizationOperationData(this.OrganisationID).subscribe(
      data => {
        this.operationShowData = data;
        this.selectedTimezone = data['org_timezone'];
        this.selectedCbAgentEnvironment = data['cbagent_environment'];
        this.organizationLogo = data['public_logo'];
        this.orgDomain = data['org_domain'];
        this.bitbucketRepoUrl = data['bitbucket_repo_url'];
        this.officeStartHours = data['office_start_hours'];
        this.officeEndHours = data['office_end_hours'];
        this.password_based_login = data['password_based_login'];
        this.document_renewal_threshold = data['document_renewal_threshold'];
        this.training_campaign_renewal_threshold = data['training_campaign_renewal_threshold'];
        this.days_before_due_date_threshold_for_campaign = data['days_before_due_date_threshold_for_campaign'];
        this.setForm();
        this.loading = false;
      },
      error => {
        this.error = error.error.message;
      }
    );
  }

  Onchange(event: any) {
    this.password_based_login = event.checked;
  }
}
