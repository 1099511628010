<div class="container-fluid p-0">
  <div class="modal-header cb-modal-header">
    <h4 class="modal-title font-weight-bold text-black-1">{{ title }}</h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <p class="col">{{ message }}</p>
    </div>
    <div class="row mb-4">
      <label class="col" translate>Confirm_Dialog.Event_Triggered</label>
    </div>
    <div class="row">
      <form [formGroup]="eventDisableForm" novalidate class="col">
        <div class="row">
          <label for="reason" class="col-3" translate> Confirm_Dialog.Reason</label>
          <textarea
            formControlName="reason_for_disabled"
            id="reason"
            name="reason"
            class="col-8 cb-textarea"
            placeholder="Enter a reason. (optional)"
            [(ngModel)]="reason"
            [maxLength]="1025"
          ></textarea>
          <div
            class="col-12 error-mssg"
            *ngIf="
              (eventDisableForm.controls.reason_for_disabled.touched ||
                eventDisableForm.controls.reason_for_disabled.dirty) &&
              eventDisableForm.controls.reason_for_disabled.errors
            "
          >
            <label
              for="name"
              class="text-danger"
              style="    margin-left: 122px"
              *ngIf="eventDisableForm.controls.reason_for_disabled.errors.maxlength"
              translate
              >Reason must be 1025 characters or less</label
            >
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer border-0 cb-modal-footer p-0 mt-4">
      <button class="btn-cancel ml-4 w-100" ngbAutoFocus (click)="close()" translate>Confirm_Dialog.Cancel</button>
      <button class="btn-save ml-4 w-100" (click)="confirm()" translate>Confirm_Dialog.Submit</button>
    </div>
  </div>
</div>
