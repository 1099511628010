import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-onboard7-final',
  templateUrl: './onboard7-final.component.html',
  styleUrls: ['./onboard7-final.component.scss']
})
export class Onboard7FinalComponent implements OnInit {
  @Input() show = false;
  constructor() {}

  ngOnInit() {}

  canGoToNextStep() {
    return true;
  }
}
