<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div id="mySidenav" class="sidenav">
    <div class="sidenav-header d-flex align-items-center">
      <mat-icon class="cursor-pointer" (click)="closeNav()">arrow_back</mat-icon>
      <div class="hedeartitle ml-2">{{ title_tag }}</div>
      <i class="fa fa-times text-black-1 closebtn" (click)="closeAll()" ngbAutoFocus translate></i>
    </div>
    <div class="row mt-2" *ngIf="backend_error">
      <div class="col-12 col-sm-12 col-lg-12 ml-5">
        <div class="text-danger" translate>* {{ backend_error }}</div>
      </div>
    </div>
    <div class="border-bottom mt-2">
      <label class="folder-label">Select a template</label>
    </div>
    <div class="tree-container ">
      <div
        class="col p-0 search-results"
        infiniteScroll
        [scrollWindow]="false"
        (scrolled)="onScroll()"
        [infiniteScrollDistance]="modalScrollDistance"
        [infiniteScrollThrottle]="modalScrollThrottle"
        (scrolledUp)="onScrollUp()"
      >
        <ul class="tree">
          <ng-container *ngFor="let template of TemplatesList">
            <li Class="main-folder-border cursor-pointer">
              <div class="tree-list" [ngClass]="{ selected: template_guid === template.guid }">
                <span
                  class="title"
                  title="{{ template.title }} {{ template['created_by']['name'] }} {{
                    template['published_on_timestamp'] | date
                  }} {{ template['version'] }}"
                  (click)="SelectChanged(template, $event)"
                  >{{ template.title }}</span
                >
                <i
                  class="fa fa-spinner fa-spin ml-4"
                  style="font-size: 16px; color: #673ab7;"
                  *ngIf="isViewLoading && clickedRow === template.guid; else other_content"
                ></i>
                <ng-template #other_content>
                  <img
                    class="ml-2"
                    (click)="ViewTemplate(template.guid, template.doc_guid, template.document_store_identifier)"
                    src="./../../../assets/images/documents/Openewwindow.svg"
                    height="13px"
                /></ng-template>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
    <div *ngIf="scrollLoader" class="w-100p d-flex justify-content-center">
      <h6 class="font-weight-bold ">
        <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': scrollLoader }"></i> &nbsp; Loading...
      </h6>
    </div>
    <div class="sidenav-footer d-flex">
      <button
        type="submit"
        class="btn-save cb-btn-primary"
        [ngClass]="{ 'btn-disabled cb-btn-disabled': !template_guid }"
        [disabled]="!template_guid"
        *ngIf="!isLoading"
        (click)="save()"
      >
        Done
      </button>
      <button type="submit" class="btn-save cb-btn-primary" disabled *ngIf="isLoading">
        <i class="fa fa-spinner fa-spin"></i>
      </button>
      <button type="button" class="cb-btn-secondary px-2" (click)="closeNav()">Cancel</button>
    </div>
  </div>
</ng-container>
