<app-spinner *ngIf="isloading"></app-spinner>
<div id="mySidenavImport" class="sidenav-import">
  <div class="sidenav-header">
    <span class="title"> {{ isImportDocument ? 'Import Document' : 'Import Template' }}</span>
    <i class="fa fa-times text-black-1 closebtn" (click)="closeNav()" ngbAutoFocus translate></i>
  </div>
  <!-- <div class="row mt-2" *ngIf="backend_error">
    <div class="col-12 col-sm-12 col-lg-12 ml-5">
      <div class="text-danger" translate>* {{ backend_error }}</div>
    </div>
  </div> -->
  <form [formGroup]="addDocumentForm" class="sidenav-body form">
    <div class="form-group">
      <label for="title" class="label">Document Name</label>
      <input
        type="text"
        id="title"
        formControlName="title"
        class="form-control cb-input-focus"
        placeholder="Document Name"
        [maxLength]="256"
        (keyup)="onChange($event)"
        required
      />
      <div *ngIf="(f.title.touched || f.title.dirty) && f.title.errors" class="text-danger">
        <label *ngIf="f.title.errors.required">*Document name is required.</label>
        <label *ngIf="f.title.errors.maxLength">*Document name must be less than 255 characters.</label>
      </div>
    </div>
    <div class="form-group">
      <label for="description" class="label">Document Description</label>
      <textarea
        id="description"
        formControlName="description"
        class="form-control cb-input-focus"
        placeholder="Description"
        [maxLength]="4097"
        required
      ></textarea>
      <div *ngIf="(f.description.touched || f.description.dirty) && f.description.errors" class="text-danger">
        <label *ngIf="f.description.errors.required">*Description is required.</label>
        <label *ngIf="f.description.errors.maxLength">*Description must be less than 4096 characters.</label>
      </div>
    </div>
    <div class="form-group" *ngIf="isImportDocument">
      <label for="Folder" style="color: rgba(73, 90, 106, 1);" class="folder-label">Folder</label>
      <div>
        <label class="label mr-3" *ngIf="folder_name">{{ folder_name }}</label>
        <button (click)="SelectFolderDialog()" class="cb-btn-secondary p-1 px-2">
          {{ folder_name ? 'Change Folder' : 'Select
          Folder' }}
        </button>
      </div>
      <div class="col-12 error-mssg" *ngIf="submitted && !folder_name">
        <label for="folder" style="margin-left: -6px;" class="text-danger" translate>Create_Document.Sel_Fol_Req</label>
      </div>
    </div>
    <div class="form-group" *ngIf="!isImportDocument">
      <label for="template_type" class="label">Select Template Type</label>
      <select
        class="form-control cb-input-focus select-dropdown"
        id="template_type"
        formControlName="template_type"
        name="template_type"
        (change)="onTemplateTypeChange($event)"
        [ngClass]="{
          'select-with-placeholder': template_type === ''
        }"
      >
        <option value="" disabled selected hidden>Select template type</option>
        <option
          class="select-placeholder"
          *ngFor="let template of templateTypeList; let i = index"
          [attr.data-index]="i"
          [value]="template['value']"
          >{{ template.value.charAt(0).toUpperCase() + template.value.slice(1) }}</option
        >
      </select>
    </div>
    <div class="form-group">
      <label for="Folder" style="color: rgba(73, 90, 106, 1);" class="folder-label">{{
        isImportDocument ? 'Upload Document' : 'Upload Template'
      }}</label>
      <div>
        <label class="label mr-3" *ngIf="file">{{ file?.name }}</label>
        <button class="cb-btn-secondary p-1 px-2" (click)="fileInput.click()">
          {{
            isImportDocument
              ? file?.name
                ? 'Change Document'
                : 'Upload Document'
              : file?.name
              ? 'Change Template'
              : 'Upload Template'
          }}
        </button>
        <input
          #fileInput
          id="file"
          class="file-upload file-input ml-1 mt-2"
          formControlName="file"
          name="file"
          style="display: none;"
          type="file"
          (change)="fileEvent($event)"
          accept=".docx,.xlsx,.pptx,.pdf"
          required
        />
      </div>
    </div>

    <div class="form-group">
      <label for="version" class="label">Version</label>
      <input
        type="text"
        id="version"
        formControlName="version"
        class="form-control cb-input-focus"
        placeholder="Example 1.0.0"
        (keyup)="onChange($event)"
        (keydown.enter)="$event.preventDefault()"
        required
      />
      <div *ngIf="(f.version.touched || f.version.dirty) && f.version.errors" class="text-danger">
        <label *ngIf="f.version.errors.required" translate>Create_Document.Version_req</label>
        <label *ngIf="addDocumentForm.controls.version.errors.restrictZeroNumber_error" translate
          >Create_Document.version_not_zero</label
        >
      </div>
    </div>
    <div class="input-g roup pr-2 pb-2 pt-2 pl-0  mt-2 mb-2 mr-2 ml-0">
      <label class="form-check-label dm-text-2 checkbox-2 " translate>
        <input
          formControlName="renewal_recurring"
          (change)="(true)"
          id="renewal_recurring"
          name="renewal_recurring"
          class="form-check-input"
          (keydown.enter)="$event.preventDefault()"
          type="checkbox"
        />
        <span class="checkmark" style="margin-top: 4px !important;" translate></span>
        <span class="recurrence">Recurrence</span>
      </label>
    </div>
    <div class="recurrence-container" *ngIf="addDocumentForm.get('renewal_recurring')?.value">
      <div class="form-group">
        <label for="time" class="label">Recurrence Time</label>
        <input
          type="time"
          [value]="data.time"
          [(ngModel)]="data.time"
          (change)="onChangeTime($event)"
          id="time"
          class="form-control cb-input-focus"
          [ngModelOptions]="{ standalone: true }"
        />
      </div>

      <div class="form-group">
        <label for="renewal_schedule" class="label">Schedule</label>
        <select
          class="form-control cb-input-focus select-dropdown"
          (keydown.enter)="$event.preventDefault()"
          (change)="dataRefresh($event.target.value)"
          [value]="data.renewal_schedule"
          id="renewal_schedule"
          required
        >
          <option class="select-placeholder" *ngFor="let schedule of schedules" [value]="schedule.value">{{
            schedule.display_value
          }}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="duration" class="label">Duration</label>
        <select
          class="form-control cb-input-focus select-dropdown"
          [(ngModel)]="allowed_duration_selected"
          [ngModelOptions]="{ standalone: true }"
        >
          <option
            class="select-placeholder"
            *ngFor="let duration of allowed_duration"
            [value]="duration"
            translate
            translate
            >{{ duration }}</option
          >
        </select>
      </div>

      <div class="form-group" *ngIf="data.renewal_schedule === 'WEEKLY'">
        <label for="day_of_week" class="label" translate>Create_Document.Day</label>
        <select
          class="form-control cb-input-focus select-dropdown"
          [(ngModel)]="data.day_of_week"
          [ngModelOptions]="{ standalone: true }"
          (keydown.enter)="$event.preventDefault()"
          id="day_of_week"
          ng-model="day_of_week"
        >
          <option class="select-placeholder" *ngFor="let day of days" [value]="day.value"
            >{{ day.display_string }}
          </option>
        </select>
      </div>

      <div class="form-group" *ngIf="data.renewal_schedule === 'BI-WEEKLY'">
        <label for="week" class="label" translate>Create_Document.Week</label>
        <select
          class="form-control cb-input-focus select-dropdown"
          [(ngModel)]="data.week"
          [ngModelOptions]="{ standalone: true }"
          (keydown.enter)="$event.preventDefault()"
          id="week"
          ng-model="week"
        >
          <option class="select-placeholder" *ngFor="let week of numbered_bi" [value]="week.value">{{
            week.display_string
          }}</option>
        </select>
      </div>

      <div class="form-group" *ngIf="data.renewal_schedule === 'MONTHLY'">
        <label for="month" class="label" translate>Create_Document.Week_Month</label>
        <select
          class="form-control cb-input-focus select-dropdown"
          [(ngModel)]="data.day"
          [ngModelOptions]="{ standalone: true }"
          (keydown.enter)="$event.preventDefault()"
          id="month"
          ng-model="month"
        >
          <option class="select-placeholder" *ngFor="let week of numbered_bi" [value]="week.value">{{
            week.display_string
          }}</option>
        </select>
      </div>

      <div class="form-group" *ngIf="data.renewal_schedule === 'MONTHLY'">
        <label for="day_of_the_week" class="label" translate>Create_Document.Day</label>
        <select
          class="form-control cb-input-focus select-dropdown"
          [(ngModel)]="data.day_of_week"
          [ngModelOptions]="{ standalone: true }"
          (keydown.enter)="$event.preventDefault()"
          id="day_of_the_week"
          ng-model="day_of_the_week"
        >
          <option class="select-placeholder" *ngFor="let day of days" [value]="day.value"
            >{{ day.display_string }}
          </option>
        </select>
      </div>

      <ng-container *ngIf="data.renewal_schedule === 'BI-MONTHLY'">
        <div class="form-group">
          <label for="numbered_bi" class="label" translate>Create_Document.Month</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.month"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="numbered_bi"
            ng-model="numbered_bi"
          >
            <option class="select-placeholder" *ngFor="let month of numbered_bi" [value]="month.value">{{
              month.display_string
            }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="numbered_week" class="label" translate>Create_Document.Week_Month</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.day"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="numbered_week"
            ng-model="numbered_week"
          >
            <option class="select-placeholder" *ngFor="let week of numbered_week" [value]="week.value">
              {{ week.display_string }}</option
            >
          </select>
        </div>

        <div class="form-group">
          <label for="day_of_week" class="label" translate>Create_Document.Day</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.day_of_week"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="day_of_week"
            ng-model="day_of_week"
          >
            <option class="select-placeholder" *ngFor="let day of days" [value]="day.value">
              {{ day.display_string }}
            </option>
          </select>
        </div>
      </ng-container>

      <ng-container *ngIf="data.renewal_schedule === 'QUARTERLY'">
        <div class="form-group">
          <label for="numbered_quarter" class="label" translate>Create_Document.Month</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.month"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="numbered_quarter"
            ng-model="numbered_quarter"
          >
            <option class="select-placeholder" *ngFor="let month of numbered_quarter" [value]="month.value">{{
              month.display_string
            }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="numbered_week" class="label" translate>Create_Document.Week_Month</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.day"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="numbered_week"
            ng-model="numbered_week"
          >
            <option class="select-placeholder" *ngFor="let week of numbered_week" [value]="week.value">
              {{ week.display_string }}</option
            >
          </select>
        </div>

        <div class="form-group">
          <label for="day_of_week" class="label" translate>Create_Document.Day</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.day_of_week"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="day_of_week"
            ng-model="day_of_week"
          >
            <option class="select-placeholder" *ngFor="let day of days" [value]="day.value">
              {{ day.display_string }}
            </option>
          </select>
        </div>
      </ng-container>

      <ng-container *ngIf="data.renewal_schedule === 'SEMI-ANNUALLY'">
        <div class="form-group">
          <label for="months_half_year" class="label" translate>Create_Document.Month</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.month"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="months_half_year"
            ng-model="months_half_year"
          >
            <option class="select-placeholder" *ngFor="let month of months_half_year" [value]="month.value">{{
              month.display_string
            }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="numbered_week" class="label" translate>Create_Document.Week_Month</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.day"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="numbered_week"
            ng-model="numbered_week"
          >
            <option class="select-placeholder" *ngFor="let week of numbered_week" [value]="week.value">
              {{ week.display_string }}</option
            >
          </select>
        </div>

        <div class="form-group">
          <label for="day_of_week" class="label" translate>Create_Document.Day</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.day_of_week"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="day_of_week"
            ng-model="day_of_week"
          >
            <option class="select-placeholder" *ngFor="let day of days" [value]="day.value">
              {{ day.display_string }}
            </option>
          </select>
        </div>
      </ng-container>

      <ng-container *ngIf="data.renewal_schedule === 'ANNUALLY'">
        <div class="form-group">
          <label for="months_year" class="label" translate>Create_Document.Month</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.month"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="months_year"
            ng-model="months_year"
          >
            <option class="select-placeholder" *ngFor="let month of months_year" [value]="month.value">{{
              month.display_string
            }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="numbered_week" class="label" translate>Create_Document.Week_Month</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.day"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="numbered_week"
            ng-model="numbered_week"
          >
            <option class="select-placeholder" *ngFor="let week of numbered_week" [value]="week.value">
              {{ week.display_string }}</option
            >
          </select>
        </div>

        <div class="form-group">
          <label for="day_of_week" class="label" translate>Create_Document.Day</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.day_of_week"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="day_of_week"
            ng-model="day_of_week"
          >
            <option class="select-placeholder" *ngFor="let day of days" [value]="day.value">
              {{ day.display_string }}
            </option>
          </select>
        </div>
      </ng-container>

      <ng-container *ngIf="data.renewal_schedule === 'EVERY TWO YEARS'">
        <div class="form-group">
          <label for="year" class="label" translate>Create_Document.Year</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.year"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="year"
            ng-model="year"
          >
            <option class="select-placeholder" *ngFor="let year of numbered_bi" [value]="year.value">{{
              year.display_string
            }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="months_year" class="label" translate>Create_Document.Month</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.month"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="months_year"
            ng-model="months_year"
          >
            <option class="select-placeholder" *ngFor="let month of months_year" [value]="month.value">{{
              month.display_string
            }}</option>
          </select>
        </div>

        <div class="form-group">
          <label for="numbered_week" class="label" translate>Create_Document.Week_Month</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.day"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="numbered_week"
            ng-model="numbered_week"
          >
            <option class="select-placeholder" *ngFor="let week of numbered_week" [value]="week.value">
              {{ week.display_string }}</option
            >
          </select>
        </div>

        <div class="form-group">
          <label for="day_of_week" class="label" translate>Create_Document.Day</label>
          <select
            class="form-control cb-input-focus select-dropdown"
            [(ngModel)]="data.day_of_week"
            [ngModelOptions]="{ standalone: true }"
            (keydown.enter)="$event.preventDefault()"
            id="day_of_week"
            ng-model="day_of_week"
          >
            <option class="select-placeholder" *ngFor="let day of days" [value]="day.value">
              {{ day.display_string }}
            </option>
          </select>
        </div>
      </ng-container>
    </div>
    <div class="input-g roup pr-2 pb-2 pt-2 pl-0  mt-2 mb-2 mr-2 ml-0">
      <label class="form-check-label dm-text-2 checkbox-2 mb-2" translate>
        <input formControlName="remarks_check" class="form-check-input" type="checkbox" name="remarks" id="remarks" />
        <span class="checkmark" style="margin-top: 4px !important;" translate></span>
        <span class="recurrence">Remarks</span>
      </label>
      <ng-container *ngIf="addDocumentForm.get('remarks_check')?.value">
        <textarea
          id="remark"
          formControlName="remark"
          class="form-control cb-input-focus"
          placeholder="Write some remarks"
        ></textarea>
        <div *ngIf="(f.remark.touched || f.remark.dirty) && f.remark.errors" class="text-danger">
          <label *ngIf="f.remark.errors.maxLength" translate
            >* Ensure that the remark contains no more than 4096 characters.</label
          >
        </div>
      </ng-container>
    </div>
    <span class="label">Signature</span>
    <div class="row mt-2">
      <div class="col-12 dm-text-2">
        <ng-container *ngIf="UserSignature">
          <span class="readonly-box">
            <img [src]="UserSignature" style="height: 100px; width: 200px; margin-left: 44px; margin-top: -9px;" />
          </span>
        </ng-container>
        <i *ngIf="!UserSignature && isSignatureLoading" class="fa fa-spinner fa-spin" style="font-size:24px"></i>
        <div class="d-flex pb-1 mt-1 ">
          <span tabindex="0" class="signature-state"
            ><img
              src="./../../../assets/images/core/file_upload.svg"
              (click)="OpenChangeSignatureModal()"
              style="cursor: pointer;"
            />
            <a
              class="text-black-1 cursor-pointer ml-1 mt-1 mr-1"
              (click)="OpenChangeSignatureModal()"
              style="font-size: 14px;"
              >Upload Signature</a
            ></span
          >
          <span tabindex="0" class="signature-state"
            ><img
              src="./../../../assets/images/core/drawsignature.svg"
              (click)="OpenDrawSignatureModal()"
              style="cursor: pointer;"
            />
            <a
              class="text-black-1 cursor-pointer ml-1 mt-1 mr-1"
              (click)="OpenDrawSignatureModal()"
              style="font-size: 14px;"
              >Draw Signature</a
            ></span
          >
          <span tabindex="0" class="signature-state"
            ><img
              src="./../../../assets/images/core/generatesignature.svg"
              (click)="OpenGenerateSignatureModal(userName)"
              style="cursor: pointer;"
            />
            <a
              class="text-black-1 cursor-pointer ml-1 mt-1 mr-1"
              (click)="OpenGenerateSignatureModal(userName)"
              style="font-size: 14px;"
              >Generate Signature</a
            ></span
          >
        </div>
      </div>
      <div class="confirm mt-2">
        <label class=" checkbox-2" translate>
          <input
            class="form-check-input"
            formControlName="agreement_accepted"
            type="checkbox"
            name="inlineRadioOptions"
            id="inlinecheck"
          />
          <span class="checkmark" translate></span>
          <div class="confirm-text">
            I have reviewed the document. I am approving the document using the above signature.
          </div>
        </label>
      </div>
    </div>
  </form>
  <div class="sidenav-footer d-flex">
    <button
      type="submit"
      class="btn-save cb-btn-primary"
      [ngClass]="{
        'btn-disabled cb-btn-disabled':
          addDocumentForm.invalid || isloading || !addDocumentForm.get('agreement_accepted')?.value
      }"
      [disabled]="addDocumentForm.invalid || isloading || !addDocumentForm.get('agreement_accepted')?.value"
      (click)="submit()"
    >
      Save
    </button>
    <button type="button" class="px-2 cb-btn-secondary" (click)="closeNav()">Cancel</button>
  </div>
</div>
