import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { ModalService } from '@app/core/modal/modal.service';
import { OrganisationService } from '@app/core/organisation.service';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-form-details-page',
  templateUrl: './form-details-page.component.html',
  styleUrls: ['./form-details-page.component.scss']
})
export class FormDetailsPageComponent implements OnInit {
  docUrl: any;
  accArray: any[] = [];
  formGuid: string = '';
  formDetails: any = {};
  isOpen: boolean = true;
  isApproverOpen = true;
  historyOpen = false;
  guidSubscription: any;
  organisationId: string | null;
  error: any;
  details: any;
  title: any;
  status: any;
  approvers: any;
  historyLog: any;
  comment: any;
  history_data: Array<any> = [];
  loading = false;
  workspace: any;
  constructor(
    public route: ActivatedRoute,
    private orgService: OrganisationService,
    private documentsService: DocumentsService,
    private modalService: ModalService,
    private toaster: ToasterService,
    private translate: TranslateService,
    private router: Router
  ) {
    this.route.params.subscribe(params => {
      this.formGuid = params['id'];
    });
  }

  ngOnInit() {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.organisationId = guid;
      this.getFormDetails();
    });
    this.workspace = this.route.snapshot.params['guid'];
    if (this.workspace) {
      this.formGuid = this.workspace;
      this.getFormDetails();
    }
  }
  getFormDetails() {
    this.historyLog = [];
    this.formDetails = {};
    this.history_data = [];

    this.loading = true;
    this.documentsService.getFormData(this.organisationId, this.formGuid).subscribe((data: any) => {
      this.formDetails = data;
      this.loading = false;
      data['responders'].forEach((item: any) => {
        item['initials'] = item['user']['name']
          ? item['user']['name']
              .match(/\b(\w)/g)
              .join('')
              .toUpperCase()
          : item['user']['email'].charAt(0).toUpperCase();
      });
      data['approvers'].forEach((item: any) => {
        item['initials'] = item['user']['name']
          ? item['user']['name']
              .match(/\b(\w)/g)
              .join('')
              .toUpperCase()
          : item['user']['email'].charAt(0).toUpperCase();
      });
      this.formDetails['initials'] = data['created_by']['name']
        ? data['created_by']['name']
            .match(/\b(\w)/g)
            .join('')
            .toUpperCase()
        : data['created_by']['email'].charAt(0).toUpperCase();
      for (let i = 0; i < data.history_log.length; i++) {
        let currentState = '';
        let previousState = '';
        let showStateChange = false;
        this.historyLog = [];
        this.historyLog = data.history_log;
        if (i > 0) {
          currentState = this.historyLog[i].action_log.display_status;
          previousState = this.historyLog[i - 1].action_log.display_status;
          this.historyLog[i].previousState = previousState;
          this.historyLog[i].currentState = currentState;
        }

        if (currentState !== previousState) {
          showStateChange = true;
        }
        if (this.historyLog[i].action_type == 'create_form') {
          this.history_data.push({
            history: this.historyLog[i],
            created_by: this.historyLog[i]['action_log']['author']
              ? this.historyLog[i]['action_log']['author']
              : this.historyLog[i]['action_log']['email'],
            created_by_Initial: String(this.historyLog[i]['action_log']['author'])
              ? String(this.historyLog[i]['action_log']['author'])
                  .match(/\b(\w)/g)
                  .join('')
                  .toUpperCase()
              : String(this.historyLog[i]['action_log']['email'])
                  .charAt(0)
                  .toUpperCase()
          });
        } else if (this.historyLog[i].action_type == 'manage_responder') {
          this.history_data.push({
            history: this.historyLog[i],
            assignedTo: this.historyLog[i]['action_log']['assigned_to']['names']
              ? this.historyLog[i]['action_log']['assigned_to']['names']
                  .map((j: any) => j['name'] || j['email'])
                  .join(', ')
              : this.historyLog[i]['action_log']['assigned_to']['name'],
            initiatedby: data.history_log[i].action_log.initiated_by.name
              ? data.history_log[i].action_log.initiated_by.name
              : data.history_log[i].action_log.initiated_by.email,
            inviteResponderInitial: String(data.history_log[i].action_log.initiated_by.name)
              ? String(data.history_log[i].action_log.initiated_by.name)
                  .match(/\b(\w)/g)
                  .join('')
                  .toUpperCase()
              : String(data.history_log[i].action_log.initiated_by.email)
                  .charAt(0)
                  .toUpperCase(),
            comment: this.historyLog[i]['action_log']['assigned_to']['comment']
          });
        } else if (this.historyLog[i].action_type == 'manage_approver') {
          this.history_data.push({
            history: this.historyLog[i],
            assignedTo: this.historyLog[i]['action_log']['assigned_to']['names']
              ? this.historyLog[i]['action_log']['assigned_to']['names']
                  .map((j: any) => j['name'] || j['email'])
                  .join(', ')
              : this.historyLog[i]['action_log']['assigned_to']['name'],
            initiatedby: data.history_log[i].action_log.initiated_by.name
              ? data.history_log[i].action_log.initiated_by.name
              : data.history_log[i].action_log.initiated_by.email,
            inviteApproverInitial: String(data.history_log[i].action_log.initiated_by.name)
              ? String(data.history_log[i].action_log.initiated_by.name)
                  .match(/\b(\w)/g)
                  .join('')
                  .toUpperCase()
              : String(data.history_log[i].action_log.initiated_by.email)
                  .charAt(0)
                  .toUpperCase(),
            comment: this.historyLog[i]['action_log']['assigned_to']['comment']
          });
        } else if (this.historyLog[i].action_type == 'complete_edit') {
          this.history_data.push({
            history: this.historyLog[i],
            completed_by: this.historyLog[i]['action_log']['completed_by']['name']
              ? this.historyLog[i]['action_log']['completed_by']['name']
              : this.historyLog[i]['action_log']['completed_by']['email'],
            editingDoneInitial: String(this.historyLog[i]['action_log']['completed_by']['name'])
              ? String(this.historyLog[i]['action_log']['completed_by']['name'])
                  .match(/\b(\w)/g)
                  .join('')
                  .toUpperCase()
              : String(this.historyLog[i]['action_log']['completed_by']['email'])
                  .charAt(0)
                  .toUpperCase(),
            comment: this.historyLog[i]['action_log']['completed_by']['comment']
          });
        } else if (this.historyLog[i].action_type == 'reassign_form') {
          if (data.history_log[i].action_log.type == 'create_collaboration_task') {
            this.history_data.push({
              history: this.historyLog[i],
              changeRequestInitiatedBy: data.history_log[i].action_log.initiated_by.name,
              changeRequestAssignTo: this.historyLog[i]['action_log']['assigned_to']['names']
                ? this.historyLog[i]['action_log']['assigned_to']['names']
                    .map((j: any) => j['name'] || j['email'])
                    .join(', ')
                : this.historyLog[i]['action_log']['assigned_to']['name'],
              reqChangeIntial: String(this.historyLog[i]['action_log']['assigned_to']['names'])
                ? String(this.historyLog[i]['action_log']['assigned_to']['names'])
                    .match(/\b(\w)/g)
                    .join('')
                    .toUpperCase()
                : String(this.historyLog[i]['action_log']['assigned_to']['email'])
                    .charAt(0)
                    .toUpperCase(),
              reassigneResponderIntial: String(data.history_log[i].action_log.initiated_by.name)
                ? String(data.history_log[i].action_log.initiated_by.name).match(/\b(\w)/g)
                : String(data.history_log[i].action_log.initiated_by.email),
              comment: this.historyLog[i]['action_log']['assigned_to']['comment']
            });
          }
        } else if (this.historyLog[i].action_type == 'revoke_approver') {
          this.history_data.push({
            history: this.historyLog[i],
            approverRevoke: this.historyLog[i]['action_log']['revoked_details']['name']
              ? this.historyLog[i]['action_log']['revoked_details']['name']
              : this.historyLog[i]['action_log']['revoked_details']['email'],
            approverRevokedBy: this.historyLog[i]['action_log']['revoked_details']['revoked_by']['name']
              ? this.historyLog[i]['action_log']['revoked_details']['revoked_by']['name']
              : this.historyLog[i]['action_log']['revoked_details']['revoked_by']['email'],
            revokeApproverInitial: String(this.historyLog[i]['action_log']['revoked_details']['revoked_by']['name'])
              ? String(this.historyLog[i]['action_log']['revoked_details']['revoked_by']['name'])
                  .match(/\b(\w)/g)
                  .join('')
                  .toUpperCase()
              : String(this.historyLog[i]['action_log']['revoked_details']['revoked_by']['email'])
                  .charAt(0)
                  .toUpperCase(),
            comment: this.historyLog[i]['action_log']['revoked_details']['comment']
          });
        } else if (this.historyLog[i].action_type == 'revoke_responder') {
          this.history_data.push({
            history: this.historyLog[i],
            editorRevoke: this.historyLog[i]['action_log']['revoked_details']['name']
              ? this.historyLog[i]['action_log']['revoked_details']['name']
              : this.historyLog[i]['action_log']['revoked_details']['email'],
            editorRevokedBy: this.historyLog[i]['action_log']['revoked_details']['revoked_by']['name']
              ? this.historyLog[i]['action_log']['revoked_details']['revoked_by']['name']
              : this.historyLog[i]['action_log']['revoked_details']['revoked_by']['email'],
            revokeResponderInitial: String(this.historyLog[i]['action_log']['revoked_details']['revoked_by']['name'])
              ? String(this.historyLog[i]['action_log']['revoked_details']['revoked_by']['name'])
                  .match(/\b(\w)/g)
                  .join('')
                  .toUpperCase()
              : String(this.historyLog[i]['action_log']['revoked_details']['revoked_by']['email'])
                  .charAt(0)
                  .toUpperCase(),
            comment: this.historyLog[i]['action_log']['revoked_details']['comment']
          });
        } else if (this.historyLog[i].action_type == 'all_moved_to_submit') {
          this.history_data.push({
            history: this.historyLog[i],
            allcompleteedit: this.historyLog[i]['action_log']['names'],
            alleditInitial: String(this.historyLog[i]['action_log']['names'])
              .match(/\b(\w)/g)
              .join('')
              .toUpperCase(),
            allEditCompleteStatus: this.historyLog[i]['action_log']['display_status']
          });
        } else if (this.historyLog[i].action_type == 'pending_submission') {
          this.history_data.push({
            history: this.historyLog[i],
            editDoc: this.historyLog[i]['action_log']['completed_by']['name']
              ? this.historyLog[i]['action_log']['completed_by']['name']
              : this.historyLog[i]['action_log']['completed_by']['email'],
            editFormInitiatedBy: data.history_log[i].action_log.completed_by.name
              ? data.history_log[i].action_log.completed_by.name
              : data.history_log[i].action_log.completed_by.email,
            editFormInitial: String(data.history_log[i].action_log.completed_by.name)
              ? String(data.history_log[i].action_log.completed_by.name)
                  .match(/\b(\w)/g)
                  .join('')
                  .toUpperCase()
              : String(data.history_log[i].action_log.completed_by.email)
                  .charAt(0)
                  .toUpperCase(),
            editFormStatus: this.historyLog[i]['action_log']['display_status']
          });
        } else if (this.historyLog[i].action_type == 'all_approvers_approved') {
          this.history_data.push({
            history: this.historyLog[i],
            allApprover: this.historyLog[i]['action_log']['names'],
            approverinitial: String(this.historyLog[i]['action_log']['names'])
              .match(/\b(\w)/g)
              .join('')
              .toUpperCase(),
            allApprovedStatus: this.historyLog[i]['action_log']['display_status']
          });
        } else if (this.historyLog[i].action_type == 'approve_form') {
          this.history_data.push({
            history: this.historyLog[i],
            published_by: this.historyLog[i]['action_log']['completed_by']['name']
              ? this.historyLog[i]['action_log']['completed_by']['name']
              : this.historyLog[i]['action_log']['completed_by']['email'],
            approveFormInitial: String(data.history_log[i].action_log.completed_by.name)
              ? String(data.history_log[i].action_log.completed_by.name)
                  .match(/\b(\w)/g)
                  .join('')
                  .toUpperCase()
              : String(data.history_log[i].action_log.completed_by.email)
                  .charAt(0)
                  .toUpperCase(),
            publishDocStatus: this.historyLog[i]['action_log']['display_status']
          });
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  expand() {
    this.isOpen = true;
  }
  shrink() {
    this.isOpen = false;
  }
  expandApprover() {
    this.isApproverOpen = true;
  }
  shrinkApprover() {
    this.isApproverOpen = false;
  }
  expandHistory() {
    this.historyOpen = true;
  }
  shrinkHistory() {
    this.historyOpen = false;
  }

  goToEditForm(formGuid: any) {
    this.router.navigate(['/organisation/form/edit-form'], { queryParams: { formGuid: formGuid } });
  }

  toggleDetails(index: any): void {
    if (this.accArray.includes(index)) {
      const pos = this.accArray.indexOf(index);
      if (index > -1) {
        this.accArray.splice(pos, 1);
      }
    } else {
      this.accArray.push(index);
    }
  }

  approveForm(formGuid: string, formTitle: string) {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Are you sure you want to approve changes ?',
        modalType: 'Approve Form Changes',
        DocumentGuid: formGuid,
        docTitle: formTitle
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          this.getFormDetails();

          this.translate
            .get('Approve_Changes.approve_changes_toaster_message.form_changes_submitted')
            .subscribe(res => {
              this.toaster.showSuccess(res);
            });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  openReviewerApproverModal(type: string, formGuid: string, formCreatedBy: string, formTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: type === 'Manage Responders' ? 'Manage Responders' : 'Manage Approvers',
        modalType: type === 'Manage Responders' ? 'Manage Responders' : 'Manage Form Approvers',
        formGuid: formGuid,
        formCreatedBy: formCreatedBy,
        formTitle: formTitle
      })
      .pipe(take(1))
      .subscribe((result: any) => {
        if (result.isUpdate && Object.keys(result.result).length) {
          // this.formDetails = { ...this.formDetails, ...result.result };
          this.getFormDetails();
          if (result.show_msg == true) {
            let msg = type === 'Manage Responders' ? 'Responders invited' : 'Approvers invited';
            this.translate.get(msg).subscribe(res => {
              this.toaster.showSuccess(res);
            });
          }
        }
      });
  }
  viewForm(formGuid: any) {
    let payload = {
      remarks: '',
      status: 'pending_submission'
    };
    this.documentsService.getFormDetailsEditUrl(this.organisationId, formGuid, payload).subscribe(
      data => {
        this.getFormDetails();
        window.open(data.document_store_identifier, '_blank');
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }
  completeEdit(formGuid: string, formTitle: string) {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Form Edit Complete',
        modalType: 'Form Edit Complete',
        DocumentGuid: formGuid,
        docTitle: formTitle
      })
      .pipe(take(1))
      .subscribe((result: any) => {
        if (result.isUpdate) {
          this.getFormDetails();
          let msg = 'Edit Complete Successfully';
          this.translate.get(msg).subscribe(res => {
            this.toaster.showSuccess(res);
          });
        }
      });
  }

  viewResponderApprover(DocumentGuid: string, docTitle: string, type: any): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: type == 'view responders' ? 'View responders' : 'View Approvers',
        modalType: type == 'view responders' ? 'View responders' : 'View Form Approvers',
        DocumentGuid: DocumentGuid,
        docTitle: docTitle,
        type: type
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {});
  }
  changeRequestModal(): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Form Change Request',
        modalType: 'Form Change Request',
        DocumentGuid: this.formGuid,
        docTitle: this.formDetails.title,
        DocCreatedBy: this.formDetails['created_by']['name']
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result.isUpdate) {
          this.getFormDetails();
          this.translate.get('Create_Form_Request.Responder_Success').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        }
      });
  }

  editForm(formGuid: any) {
    this.documentsService.getOrganizationFormEditURl(this.organisationId, formGuid).subscribe(
      (data: any) => {
        this.docUrl = data;
        window.open(this.docUrl.document_store_identifier, '_blank');
      },
      (error: { error: { message: any } }) => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }

  openForm(url: string) {
    window.open(url, '_blank');
  }

  getClickEvents(action: string, pending: any): void {
    if (action == 'Edit Form') {
      this.goToEditForm(pending['guid']);
    } else if (action == 'Manage Responders' || action == 'Manage Approvers') {
      this.openReviewerApproverModal(action, pending['guid'], pending['created_by']['name'], pending['title']);
    } else if (action == 'View Responders' || action == 'View Approvers') {
      this.viewResponderApprover(pending['guid'], pending['title'], action);
    } else if (action == 'View Form Details') {
      this.router.navigate(['/organisation/form/form-details/', pending['guid']]);
    } else if (action == 'Edit Form Template') {
      this.viewForm(pending['guid']);
    } else if (action == 'Complete Edit') {
      this.completeEdit(pending['guid'], pending['title']);
    } else if (action == 'Approve Form') {
      this.approveForm(pending['guid'], pending['title']);
    } else if (action == 'Reassign Form') {
      this.changeRequestModal();
    } else if (action == 'Edit Document') {
      this.editForm(pending['guid']);
    } else if (action == 'View Document') {
      this.openForm(pending['document_store_identifier']);
    }
  }
}
