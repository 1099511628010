import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { OrganisationService } from '@app/core/organisation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { SettingsService } from '@app/settings/settings.service';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';

@Component({
  selector: 'app-change-signature',
  templateUrl: './change-signature.component.html',
  styleUrls: ['./change-signature.component.scss']
})
export class ChangeSignatureComponent implements OnInit {
  ChangeSignatureForm: FormGroup;
  OrganisationID: string | null;
  guidSubscription: any;
  isLoading = false;
  submitted: boolean = false;
  error: any;
  file: any;
  size: any;
  file_type: any;
  file_type_error: boolean = false;
  file_size_error: boolean = false;
  SignatureUrl: any;
  isSignatureLoading: boolean = false;
  isShowCommentSection: boolean = false;

  @Input() title_tag: string;
  form_error: any;
  backend_error: any;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private settingsService: SettingsService
  ) {}

  ngOnInit() {
    this.isShowCommentSection = localStorage.getItem('is-org-onboarded') === 'true' ? true : false;
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
    });
    this.getChangeSignatureForm();
  }

  getChangeSignatureForm() {
    this.ChangeSignatureForm = this.formBuilder.group({
      Comment: ['', [Validators.maxLength(4096)]],
      Signature: ['', [Validators.required()]]
    });
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  get f() {
    return this.ChangeSignatureForm.controls;
  }

  UploadSignature(input: any) {
    this.isSignatureLoading = false;
    this.SignatureUrl = '';
    if (input) {
      this.isSignatureLoading = true;
      this.file = input.target.files[0];
      this.size = this.file.size;
      this.file_type = this.file.name.substr(this.file.name.lastIndexOf('.') + 1);
      if (this.file_type == 'jpeg' || this.file_type == 'jpg' || this.file_type == 'png') {
        this.file_type_error = false;
        this.file_size_error = false;
        if (this.size < 1024 * 1024 * 5 || this.size == 1024 * 1024 * 5) {
          //Show image preview
          var reader = new FileReader();
          reader.readAsDataURL(this.file); // read file as data url
          reader.onload = event => {
            // called once readAsDataURL is completed
            this.SignatureUrl = event.target['result'];
            this.isSignatureLoading = false;
          };
          this.file_size_error = false;
        } else {
          this.isSignatureLoading = false;
          this.file_size_error = true;
        }
      } else {
        this.isSignatureLoading = false;
        this.file_type_error = true;
      }
    }
  }

  save() {
    this.submitted = true;
    if (this.ChangeSignatureForm.valid && !this.file_size_error && !this.file_type_error) {
      this.isLoading = true;
      const signatureData = this.ChangeSignatureForm.value;
      const data = { size: this.size, comment: signatureData['Comment'] };
      this.submitChangeSignature(data, this.file);
    } else {
      return;
    }
  }

  submitChangeSignature(data: any, file: any): void {
    this.settingsService.uploadUserSignature(this.OrganisationID, data).subscribe(
      (data: any) => {
        this.settingsService.uploadUserSignatureToGCP(data.url, file).subscribe(
          (data: any) => {
            this.error = {
              Comment: ''
            };
            this.submitted = false;
            this.activeModal.close(true);
            console.log('Signature uploaded.');
          },
          (error: any) => {
            this.isLoading = true;
            this.submitted = false;
            this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
            this.backendErrorService.backendError$.subscribe(error => {
              this.backend_error = error;
            });
          }
        );
      },
      (error: any) => {
        this.isLoading = true;
        this.submitted = false;
        this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
        });
      }
    );
  }
}
