<!-- <app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading"> -->
<!-- <div class="row" *ngIf="templateList?.length > 0; else no_content">
    <div class="col setting-actions justify-content-between d-flex h-70 align-items-center"> -->
<!-- <h2 class="text-cyan m-0" translate>Templates_Page.Title</h2> -->
<!-- <div class="search-people position-relative">
        <div class="input-group w-330">
          <input
            id="search"
            [(ngModel)]="searchText"
            class="form-control cb-input"
            placeholder="search"
            name="search"
            (keyup)="updateSearchText($event)"
            (keyDown.enter)="Search(searchText)"
            type="text"
            #titleInput
          />
        </div>
        <i (click)="Search(titleInput.value)" class="fa fa-search search-icon"></i>
      </div> -->
<!-- <div class="p-2">
          <button class="dm-template-button" (click)="goToCreateTemplate()" translate>
      </div> -->
<!-- <div>
        <select
          class="cb-input-1 w-140"
          (keyup)="updateStatus($event)"
          (change)="onStatusChange($event.target.value)"
          *ngIf="!status_loading"
        >
          <option value="" disabled selected hidden>Status</option>
          <option *ngFor="let status of documentStatus; let i = index" [attr.data-index]="i" [value]="status['key']">
            {{ status['value'] }}
          </option>
        </select>
      </div> -->
<!-- <div class="p-2">
          <button class="dm-template-button" (click)="goToCreateTemplate()" translate>
            <img src="../../../assets/images/documents/plus.png" class="cb-plus-icon" />
            Templates_Page.Create_Template
          </button>
      </div> -->
<!-- </div>
  </div> -->
<!-- <div class="row scroll-view">
    <div class="col p-0">
      <div class="table_wrapper">
        <table class="w-100p bg-white dm-table table table-responsive">
          <tr style="height: 65px;">
            <th scope="col" class="font-weight-bold text-black-3 text-center" translate>Templates_Page.Table_title</th>
            <th scope="col" class="font-weight-bold text-black-3 text-center" translate>Templates_Page.Table_status</th>
            <th scope="col" class="font-weight-bold text-black-3 text-center" translate>Templates_Page.Last_Updated</th>
            <th scope="col" class="font-weight-bold text-black-3 text-center" translate>
              Templates_Page.Actions
            </th>
            <th scope="col" class="font-weight-bold text-black-3 text-center" translate></th>
          </tr>

          <tbody>
            <tr class="text-center" *ngFor="let template of templateList; let rowIndex = index">
              <td class="d-none">{{ calcNo(rowIndex) }}</td>
              <td>
                <h4 class="dm-text-1 text-black-3 mt-3 text-center">{{ template['title'] }}</h4>
                <span style="color: #777E8B;"> Created By: {{ template['created_by']['name'] }}</span>
              </td>
              <td>
                <h4 class="text-black-3 dm-text-2 mt-3">{{ template['status']['value'] }}</h4>
              </td>
              <td>
                <h4 class="text-black-3 dm-text-2 mt-3">{{ template['modified_timestamp'] | date: 'yyyy-MM-dd' }}</h4>
              </td>
              <td style="text-align: center">
                <mat-icon class="mt-2 dropdown-btn" mat-stroked-button [matMenuTriggerFor]="menu">
                  more_horiz
                </mat-icon>
                <mat-menu #menu="matMenu" class="fixed-menu-panel">
                  <ng-container *ngFor="let actions of template['allowed_actions_list']">
                    <button
                      mat-menu-item
                      (click)="
                        getClickEvents(actions, template['guid'], template['created_by']['email'], template['title'])
                      "
                    >
                      {{ actions }}
                    </button>
                  </ng-container>
                </mat-menu>
              </td>
            </tr>
          </tbody>
        </table>
        <ngb-pagination
          class="pagination"
          (pageChange)="onPageChange($event)"
          [(page)]="page"
          [pageSize]="pageSize"
          [collectionSize]="totalLength"
          size="lg"
        >
          <ng-template ngbPaginationPrevious>Prev</ng-template>
          <ng-template ngbPaginationNext>Next</ng-template>></ngb-pagination
        >
      </div>
    </div>
  </div> -->
<app-folder-document-view [title_tag]="title"></app-folder-document-view>
<!-- </ng-container> -->
<ng-template #no_content>
  <div class="row scroll-view">
    <div class="col">
      <img class="empty-doc-list-image" src="../../../assets/images/documents/empty.svg" />
      <div class="text-empty-doc-list text-black-1" translate>Templates_Page.No_documents</div>
      <div class="sub-text-empty-doc-list cursor-pointer text-cyan-force" translate>
        Templates_Page.Add_new
        <a
          class="sub-text-empty-doc-list text-center text-cyan-force"
          (click)="goToCreateDoc()"
          translate
          style="font-size: 18px;"
          >Templates_Page.Document</a
        >
      </div>
    </div>
  </div>
</ng-template>
