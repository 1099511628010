<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div class="row">
    <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
      <h2 class="text-cyan m-0" style="cursor:default" translate>{{ titleMapper[role] }}</h2>
      <div class="col top-header-filters">
        <div class="search-people position-relative">
          <div class="input-group w-330">
            <input
              id="search"
              [(ngModel)]="searchText"
              class="form-control cb-input m-0"
              placeholder="{{ this.placeholderSearch }}"
              name="search"
              type="text"
              (keyup)="updateSearchText($event)"
              (keyDown.enter)="Search(searchText)"
              #titleInput
            />
            <i (click)="Search(titleInput.value)" class="fa fa-search search-icon"></i>
          </div>
        </div>
        <div>
          <select
            style="margin-left: 10px;"
            class="cb-input-1 w-140"
            [(ngModel)]="state_filter"
            (change)="onStatusChange($event.target.value)"
            *ngIf="!status_loading"
            [(ngModel)]="statusFilter"
          >
            <option *ngFor="let status of IncidentStatus; let i = index" [attr.data-index]="i" value="{{ status.key }}">
              {{ status.value }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="row scroll-view" *ngIf="IncidentList?.length > 0; else no_content">
    <div
      class="col p-0 search-results"
      infiniteScroll
      [scrollWindow]="false"
      (scrolled)="onScroll()"
      [infiniteScrollDistance]="modalScrollDistance"
      [infiniteScrollThrottle]="modalScrollThrottle"
      (scrolledUp)="onScrollUp()"
    >
      <div class="table_wrapper">
        <table style="width:105%" class="w-100p bg-white dm-table table table-responsive">
          <thead>
            <tr style="height: 65px;">
              <th scope="col" class="font-weight-bold text-black-3 text-center" translate>
                Incident_Mangement.Table_title
              </th>
              <th scope="col" class="font-weight-bold text-black-3" translate>
                Incident_Mangement.Table_status
              </th>
              <th scope="col" class="font-weight-bold text-black-3" translate>
                Incident_Mangement.severity
              </th>
              <th scope="col" class="font-weight-bold text-black-3">
                Category
              </th>
              <th scope="col" class="font-weight-bold text-black-3 text-center" translate>
                Incident_Mangement.reported_date
              </th>
              <th scope="col" class="font-weight-bold text-black-3 text-center" translate>
                Incident_Mangement.created_date
              </th>
              <th scope="col" class="font-weight-bold text-black-3 text-center" translate>
                Incident_Mangement.Table_Created_by
              </th>
              <th scope="col" class="font-weight-bold text-black-3 text-center" translate>
                Incident_Mangement.Actions
              </th>
              <th scope="col" class="font-weight-bold text-black-3 text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center" *ngFor="let incident of IncidentList; let rowIndex = index">
              <td class="css-b8r45">
                <abbr title="{{ incident['title'] }}">
                  <h4 class="text-black-4 dm-text-1 mt-3 ">{{ incident['title'] }}</h4>
                </abbr>
              </td>
              <td>
                <h4 class="text-black-3 dm-text-2 mt-3">{{ incident['status'] }}</h4>
              </td>
              <td>
                <h4 class="text-black-3 dm-text-2 mt-3">{{ incident['severity'] }}</h4>
              </td>
              <td class="css-category">
                <abbr title="{{ incident['category'] }}">
                  <h4 class="text-black-4 dm-text-1 mt-3">{{ incident['category'] }}</h4>
                </abbr>
              </td>
              <td>
                <h4 class="text-black-3 dm-text-2 mt-3 ">
                  {{ incident['reported_date'] | date: 'yyyy-MM-dd' }}
                </h4>
              </td>
              <td>
                <h4 class="text-black-3 dm-text-2 mt-3 " style="width: 75px !important;">
                  {{ incident['created_timestamp'] | date: 'yyyy-MM-dd' }}
                </h4>
              </td>
              <td>
                <h4 class="text-black-9 dm-text-2 mt-3">
                  <img src="../../../../assets/images/documents/author.png" class="dm-user-icon" />
                  {{ incident['created_by']['name'] }}
                </h4>
              </td>
              <td>
                <mat-icon class="mt-2" [matMenuTriggerFor]="menu">more_horiz</mat-icon>
                <mat-menu xPosition="before" #menu="matMenu" class="fixed-menu-panel">
                  <ng-container *ngFor="let actions of incident['allowed_actions_list']">
                    <button
                      mat-menu-item
                      (click)="
                        getClickEvents(
                          actions.value,
                          incident['guid'],
                          incident['created_by']['email'],
                          incident['title'],
                          incident['created_by']['name'],
                          actions.status_to_be_sent,
                          incident['document_store_identifier']
                        )
                      "
                    >
                      {{ actions.value }}
                    </button>
                  </ng-container>
                  <!-- <button  mat-menu-item [click]="goTOIncidentDetailsPage(incident['guid'])" >
                    View Incident details
                  </button> -->
                </mat-menu>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #no_content>
  <div class="row scroll-view">
    <div class="col">
      <img class="empty-doc-list-image" src="../../../assets/images/documents/empty.svg" />
      <div class="text-empty-doc-list text-black-1" translate>Incident_Author.No_Incident</div>
      <div class="sub-text-empty-doc-list ">
        <a class="cursor-pointer text-cyan-force" (click)="goToCreateIncident()" style="font-size: 18px;" translate>
          Incident_Mangement.Add_new
        </a>
      </div>
    </div>
  </div>
</ng-template>
&nbsp;
<div *ngIf="scrollLoader" class="w-100p d-flex justify-content-center">
  <h2 class="font-weight-bold  ">
    <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': scrollLoader }" style="font-size:large;"></i> &nbsp; Loading...
  </h2>
</div>
