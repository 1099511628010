<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div>
    <div class="modal-header cb-modal-header">
      <div>
        <h4 class="modal-title font-weight-bold text-black-1" translate>
          {{ modalType === 'Form Edit Complete' ? 'Complete Edit' : 'Mark Editing as Done' }}
        </h4>
        <i
          class="fa fa-times text-black-1"
          style="width: 12px;
            height: 12px;
            right: 1em;
            cursor: pointer;
            position: absolute;
            top: 1.3em;"
          (click)="activeModal.close({ isUpdate: false })"
          ngbAutoFocus
          translate
        ></i>
      </div>
    </div>
    <!-- <div class="modal-body"> -->
    <!-- <div class="d-none col-12 mt-2 ">
      <label class="text-black-1 p-2" translate
        >{{ DocumentTitle }}&nbsp;{{
          title_tag == 'Mark Editing as Done' ? 'Mark Editing as Done' : 'No changes required'
        }}</label
      >
    </div> -->
    <!-- </div> -->

    <div class="modal-body">
      <div class="row mt-1 mb-3" *ngIf="backend_error">
        <div class="col-12">
          <span class="text-danger backend_error" translate> * {{ backend_error }}</span>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-3">
          <label class="text-black-4 p-2" translate>Title </label>
        </div>
        <div class="col-9">
          <div class="input-group">
            <input class="form-control cb-input" value="{{ docTitle }}" disabled />
          </div>
        </div>
      </div>

      <form [formGroup]="MarkEditDoneForm" novalidate>
        <div class="row mt-3">
          <div class="col-3">
            <label for="remarks" class="text-black-1 p-2" translate>Mark_Editing_Done.mark_edit_done.Comments</label>
          </div>
          <div class="input-group col-9">
            <textarea
              id="remarks"
              class="form-control cb-input"
              [placeholder]="'Mark_Editing_Done.mark_edit_done.Comments' | translate"
              name="remarks"
              type="text"
              formControlName="remarks"
              cols="64"
              rows="5"
              [maxLength]="4097"
            ></textarea>
            <div class="col-12 error-mssg" *ngIf="(f.remarks.touched || f.remarks.dirty) && f.remarks.errors">
              <label
                for="name"
                style="margin-left: -10px;"
                class="text-danger"
                *ngIf="f.remarks.errors.maxLength"
                translate
                >Mark_Editing_Done.* Ensure that the comment has no more than 4096 characters.</label
              >
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="modal-footer border-0 cb-modal-footer">
      <button class="btn-cancel ml-4 w-100" (click)="activeModal.close({ isUpdate: false })" ngbAutoFocus translate>
        Mark_Editing_Done.mark_edit_done.Cancel
      </button>
      <button class="btn-save ml-4 w-100" *ngIf="!isLoading" (click)="save()" translate>
        Mark_Editing_Done.mark_edit_done.Submit
      </button>
      <button class="btn-save ml-4 w-100" disabled *ngIf="isLoading">
        <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
      </button>
    </div>
  </div>
</ng-container>
