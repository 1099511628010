import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatChipInputEvent } from '@angular/material/chips';
import * as _ from 'lodash';
import { WizardService } from '@app/home/wizard.services';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';

@Component({
  selector: 'app-add-members-dialog',
  templateUrl: './add-members-dialog.html'
})
export class AddMembersDialogComponent {
  isloading = false;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  emails: string[] = [];
  removeEmails: object[] = [];
  error: object[] = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddMembersDialogComponent>,
    private wizardService: WizardService,
    private translate: TranslateService,
    private toaster: ToasterService
  ) {}

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.emails.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }

  removeMember(email: string): boolean {
    const payload = { email: email };
    this.removeEmails.push(payload);
    _.remove(this.data.members, function(n: string) {
      return n === email;
    });
    return true;
  }

  save() {
    this.isloading = true;
    const payload: any = [];
    this.emails.forEach((email: string) => {
      payload.push({ email: email });
    });

    this.wizardService
      .removeOrganizationCommitteeMembers(this.data.OrganisationID, this.data.committee_guid, this.removeEmails)
      .subscribe(data => {
        this.wizardService
          .addOrganizationCommitteeMembers(this.data.OrganisationID, this.data.committee_guid, payload)
          .subscribe(
            data2 => {
              this.isloading = false;
              this.translate
                .get('organisation_onboard.invite_committee_members_page.add_remove_success')
                .subscribe(res => {
                  this.toaster.showSuccess(res);
                });
              this.dialogRef.close();
            },
            error => {
              for (const json_data of error['error']['created']) {
                this.data.members.push(json_data['email']);
              }
              this.isloading = false;
              this.error = error['error']['failed'];
              console.log('error: ', this.error);
            }
          );
      });
  }
}
