<div id="mySidenav" class="sidenav">
  <div class="sidenav-header d-flex align-items-center">
    <mat-icon class="cursor-pointer" (click)="closeNav()">arrow_back</mat-icon>
    <div class="hedeartitle ml-2">Add New Reader</div>
    <i class="fa fa-times text-black-1 closebtn" (click)="closeAll()" ngbAutoFocus translate></i>
  </div>
  <div class="row mt-2" *ngIf="error['error']">
    <div class="col-12 col-sm-12 col-lg-12 ml-5">
      <div class="text-danger" translate>*{{ error['error'] }}</div>
    </div>
  </div>
  <form [formGroup]="peopleForm" class="sidenav-body">
    <div class="form-group">
      <label for="First Name" class="label">First Name</label>
      <input
        id="first_name"
        class=" form-control cb-input-focus pr-3"
        [placeholder]="'organisation_people.add_organisation_people.First name' | translate"
        formControlName="first_name"
        name="firstname"
        type="text"
      />
      <div class="col-12 error-mssg" *ngIf="(f.first_name.touched || submitted) && f.first_name.errors">
        <label for="first_name" class="text-danger" *ngIf="f.first_name.errors.required" translate
          >organisation_people.add_organisation_people.*First name is required.</label
        >
      </div>
    </div>
    <div class="form-group">
      <label for="Last Name" class="label">Last Name</label>
      <input
        id="last_name"
        class=" form-control cb-input-focus pr-3"
        [placeholder]="'organisation_people.add_organisation_people.Last name' | translate"
        formControlName="last_name"
        name="lastname"
        type="text"
      />
      <div class="col-12 error-mssg" *ngIf="(f.last_name.touched || submitted) && f.last_name.errors">
        <label for="last_name" class="text-danger" *ngIf="f.last_name.errors.required" translate
          >organisation_people.add_organisation_people.*Last name is required.</label
        >
      </div>
    </div>
    <div class="form-group">
      <label for="email" class="label">Email</label>
      <input
        id="email"
        formControlName="email"
        class=" form-control cb-input-focus"
        [placeholder]="'organisation_people.add_organisation_people.Email address' | translate"
        name="email"
        type="text"
      />
      <div class="col-12 error-mssg" *ngIf="(f.email.touched || submitted) && f.email.errors">
        <label for="first_name" class="text-danger" *ngIf="f.email.errors.email" translate
          >organisation_people.add_organisation_people.*Email address in not valid.</label
        >
      </div>
      <div class="col-12 error-mssg" *ngIf="error['email']">
        <label for="email" class="text-danger">*{{ error['email'] }}</label>
      </div>
    </div>
    <div class="form-group">
      <label for="Select Role" class="label">
        Select Role
      </label>
      <!-- <div class="input-group">
        <ng-multiselect-dropdown
          formControlName="selectedItems"
          class="multi-select-dropdown"
          [placeholder]="'Select role'"
          [(ngModel)]="selectedItems"
          (onSelect)="onItemSelect($event)"
          (onDeSelect)="onItemDeSelect($event)"
          [settings]="dropdownSettings"
          [data]="GroupsList"
        >
        </ng-multiselect-dropdown>

        <div class="col-12 error-mssg" *ngIf="error['invalid_role']">
          <label for="phone-number" class="text-danger" translate>*{{ error['invalid_role'] }}</label>
        </div>
      </div> -->
      <ng-select
        [clearSearchOnAdd]="true"
        [closeOnSelect]="false"
        [multiple]="true"
        placeholder="Select Role"
        clearAllText="Clear"
        [items]="GroupsList"
        bindLabel="'key'"
        class="select-editors"
      >
        <ng-template ng-option-tmp let-item="item">
          <div
            class="option-item d-flex"
            (click)="$event.stopPropagation()"
            [ngClass]="{ 'disabled-option': item.isDisabled }"
            [ngStyle]="{
              backgroundColor: item.selected ? '#E5F7F9' : ''
            }"
          >
            <div class="d-flex selected-option ">
              <input
                class="custom-checkbox"
                id="item-{{ item.key }}"
                type="checkbox"
                [checked]="item.selected"
                (change)="onItemSelect(item)"
                [disabled]="item.isDisabled"
              />
              <div class="d-flex cursor-default" (click)="$event.stopPropagation()">
                <span class="initials">
                  {{ item.value }}
                </span>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-select>
      <div class="selected-items" *ngIf="selectedItems.length > 0" (mouseleave)="showTooltip = false">
        <ng-container *ngFor="let item of selectedItems.slice(0, 2)">
          <span class="badge badge-primary mr-2">
            {{ item.value }}
            <span
              class="badge-remove"
              [class.disabled]="isNotRemovable(item)"
              (click)="isNotRemovable(item) ? null : removeItem(item)"
              >x</span
            >
          </span>
        </ng-container>
        <ng-container *ngIf="selectedItems.length > 2">
          <span (mouseenter)="showTooltip = true">
            <span class="count">+{{ selectedItems.length - 2 }}</span>
            <div
              class="tooltip"
              *ngIf="showTooltip"
              (mouseenter)="showTooltip = true"
              (mouseleave)="showTooltip = false"
            >
              <ng-container *ngFor="let item of selectedItems.slice(2)">
                <span class="badge badge-primary mr-2">
                  {{ item.value }}
                  <span
                    class="badge-remove"
                    [class.disabled]="isNotRemovable(item)"
                    (click)="isNotRemovable(item) ? null : removeItem(item)"
                    >x</span
                  >
                </span>
              </ng-container>
            </div>
          </span>
        </ng-container>
      </div>
      <div class="col-12 error-mssg" *ngIf="error['invalid_role']">
        <label for="phone-number" class="text-danger" translate>*{{ error['invalid_role'] }}</label>
      </div>
    </div>
    <div class="form-group d-flex">
      <label for="Select Role" class="label">
        External Sharing
      </label>
      <div class="input-group col-8">
        <mat-slide-toggle
          [disabled]="checkNonDomain(peopleForm.value.email) || f.email.invalid"
          formControlName="external_sharing"
        >
        </mat-slide-toggle>
      </div>
    </div>
  </form>

  <div class="sidenav-footer d-flex">
    <button
      type="submit"
      class="btn-save"
      [ngClass]="{ 'btn-disabled': peopleForm.invalid }"
      [disabled]="peopleForm.invalid"
      *ngIf="!isLoading"
      (click)="save()"
    >
      Save
    </button>
    <button type="submit" class="btn-save" disabled *ngIf="isLoading">
      <i class="fa fa-spinner fa-spin"></i>
    </button>
    <button type="button" class="btn-cancel" (click)="closeNav()">Cancel</button>
  </div>
</div>
