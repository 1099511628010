<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div>
    <div class="modal-header justify-content-between d-flex h-70 align-items-center border-0">
      <div class="search-people position-relative">
        <div class="input-group w-330">
          <div class="input-group w-330">
            <input
              id="search"
              [(ngModel)]="searchText"
              class="form-control cb-input"
              placeholder="Search"
              name="search"
              type="text"
              (keyup)="updateSearchText($event)"
              (keyDown.enter)="Search(searchText)"
              #titleInput
            />
          </div>
          <i (click)="Search(titleInput.value)" class="fa fa-search search-icon"></i>
        </div>
      </div>
      <div class="button-container">
        <button class="btn-add ml-4" (click)="openPeopleForm('add')" translate>
          <i class="fa fa-plus"></i>organisation_administration.add_organisation_administration.Add People
        </button>
        <button (click)="closePeopleAddModal()" class="btn-add ml-4"><i class="fa fa-times"></i> Close</button>
      </div>
    </div>
    <div class="modal-body pt-0 pb-0">
      <div class="row">
        <div
          class="col search-results"
          infiniteScroll
          [scrollWindow]="false"
          (scrolled)="onScroll()"
          [infiniteScrollDistance]="modalScrollDistance"
          [infiniteScrollThrottle]="modalScrollThrottle"
          (scrolledUp)="onScrollUp()"
        >
          <div class="table_wrapper mt-0 mx-1">
            <table class="w-100p table-2">
              <thead class="bg-yellow-2">
                <tr class="border-0">
                  <td>
                    <h4 class="font-weight-bold text-black-2 p-3 mt-2" translate>
                      organisation_administration.add_organisation_administration.Name
                    </h4>
                  </td>
                  <td>
                    <h4 class="font-weight-bold text-black-2 p-3 mt-2" translate>
                      organisation_administration.add_organisation_administration.Phone Number
                    </h4>
                  </td>
                  <td>
                    <h4 class="font-weight-bold text-black-2 p-3 mt-2" translate>
                      organisation_administration.add_organisation_administration.Email
                    </h4>
                  </td>
                  <td>
                    <h4 class="css font-weight-bold text-black-2 p-3 mt-2 ">
                      Actions
                    </h4>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let people of peopleList; let i = index" [attr.data-index]="i">
                  <td>
                    <h4 class="text-black-1 p-3 pr-3 w-240">{{ people['first_name'] }} {{ people['last_name'] }}</h4>
                  </td>
                  <td>
                    <h4 class="text-black-1 p-3 pr-3 ">{{ people['phone'] !== null ? people['phone'] : '-' }}</h4>
                  </td>
                  <td>
                    <h4 class="text-black-1 p-3">{{ people['email'] !== null ? people['email'] : '-' }}</h4>
                  </td>
                  <td>
                    <a
                      (click)="organisationAdministrationAdd(people['guid'], 'revoke')"
                      class="mr-4 w-80 btn-small"
                      *ngIf="people['roles'].includes('organization_admin_role'); else other_content"
                      ><u translate>organisation_administration.add_organisation_administration.Remove</u></a
                    >
                    <ng-template #other_content
                      ><button
                        (click)="organisationAdministrationAdd(people['guid'], 'assign')"
                        class="btn-save mr-4 w-80 btn-small"
                        translate
                      >
                        organisation_administration.add_organisation_administration.Add
                      </button></ng-template
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          &nbsp;
          <h2 *ngIf="scrollLoader" class="font-weight-bold text-black-3 text-center">
            <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': scrollLoader }" style="font-size:large;"></i> &nbsp;
            Loading...
          </h2>
          <div class="table_footer">
            <h4 class="font-weight-bold text-black-2 ml-4 mb-0">
              {{ peopleCount }}
              {{ 'organisation_administration.add_organisation_administration.People Added' | translate }}
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
