///<reference path="../../../../../node_modules/rxjs-compat/add/observable/forkJoin.d.ts"/>
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { UserService } from '@app/core/user.service';
import { WizardService } from '@app/home/wizard.services';
import * as _ from 'lodash';
import { OrganisationService } from '@app/core/organisation.service';

@Component({
  selector: 'app-review-schedule-step',
  templateUrl: './reviewSchedule.component.html',
  styleUrls: ['./reviewSchedule.component.scss']
})
export class ReviewScheduleComponent implements OnInit, OnDestroy {
  // stepForm: FormGroup;
  @Input() show = false;
  OrganisationID: any;
  tempData: any = [];
  loading = true;
  displayedColumns: string[] = ['committee_name', 'meeting_name', 'schedule'];
  data: any = [];
  unique_schedules: any = [];
  guidSubscription: any;

  constructor(
    private _user: UserService,
    private wizardService: WizardService,
    private orgService: OrganisationService
  ) {}

  ngOnInit() {
    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
      this.wizardService.getOrganizationMeetingSchedule(this.OrganisationID).subscribe(data => {
        this.tempData = data;
        this.data = _.cloneDeep(this.tempData);
        this.unique_schedules = Array.from(new Set(this.data.map((item: any) => item['schedule'])));
        this.unique_schedules = this.unique_schedules.map((value: any) => {
          let display_value = value.replace('-', ' ');
          display_value = display_value.charAt(0).toUpperCase() + display_value.slice(1).toLowerCase();
          return {
            display_value: display_value,
            value: value,
            expand: true
          };
        });
        this.loading = false;
      });
    });
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  canGoToNextStep() {
    return true;
  }

  accordian_toggle(index: any) {
    this.unique_schedules[index]['expand'] = !this.unique_schedules[index]['expand'];
  }

  submitForm() {
    console.log('form submitted');
    // this.stepForm.markAsDirty();
    // if (this.stepForm.valid) {
  }
}
