import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UserService } from '@app/core/user.service';
import { OrganisationService } from '@app/core/organisation.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { MixpanelService } from '@app/mixpanel/mixpanel.service';

export interface Credentials {
  // Customize received credentials here
  key: string;
  user: object;
}

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

export interface SignupContext {
  first_name: string;
  last_name: string;
  organization_name: string;
  organization_admin_email: string;
}

const credentialsKey = 'credentials';
const rememberMeKey = 'rememberme';

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable()
export class AuthenticationService {
  private _credentials: Credentials | null;
  private _remember_me: string | null;
  private _login_url = '/api/accounts/login/';
  private _signup_url = '/api/organizations/signup/';
  private _signup_url2 = '/api/organizations/signup/v2/';

  private _logout_url = '/api/accounts/logout/';

  constructor(
    private _http: HttpClient,
    private _userService: UserService,
    private orgService: OrganisationService,
    private cookieService: CookieService,
    private router: Router,
    private mixpanel: MixpanelService
  ) {
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
      this._userService.saveUserDetails({ user: this._credentials.user });
    }
    const savedRememberMe = this.cookieService.get(rememberMeKey);
    if (savedRememberMe) {
      this._remember_me = savedRememberMe;
    }
  }

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext): Observable<Credentials> {
    // Replace by proper authentication call
    return this._http.post<Credentials>(this._login_url, context, { withCredentials: true });
  }

  setLoginData(data: any, context: LoginContext) {
    this.setCredentials(data, context.remember);
    this._userService.saveUserDetails({ user: data.user });
  }

  /**
   * Authenticates the user.
   * @param context The signup parameters.
   * @return The user credentials.
   */
  signup(context: SignupContext): Observable<Credentials> {
    return this._http.post<Credentials>(this._signup_url2, context, { withCredentials: true });
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout() {
    this.mixpanel.logout();
    sessionStorage.clear();
    localStorage.clear();
    // delete $cookies["userInfo"]
    this.cookieService.deleteAll();

    this._http.get(this._logout_url, { withCredentials: true }).subscribe(data => {
      this.setCredentials();
      // this.orgService.setSelectedOrganisationGuid(null);
      this.router.navigate(['/login'], { replaceUrl: true });
    });
    // Customize credentials invalidation here
  }

  logoutWithoutRedirect() {
    this.setCredentials();
    this.orgService.setSelectedOrganisationGuid(null);
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */

  isAuthenticated(): boolean {
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
      this._userService.saveUserDetails({ user: this._credentials.user });
    }
    return !!this._credentials;
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    return this._credentials;
  }

  rememberMeEmail(): string | null {
    return this._remember_me ? this._remember_me : '';
  }

  saveRememberMeEmail(user_email?: string) {
    if (user_email) {
      this._remember_me = user_email;
      this.cookieService.set(rememberMeKey, this._remember_me);
    } else {
      this._remember_me = null;
      this.cookieService.delete(rememberMeKey);
    }
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  private setCredentials(credentials?: Credentials, remember?: boolean) {
    this._credentials = credentials || null;
    localStorage.clear();
    // $cookies.remove("userInfo");
    this.cookieService.delete('credentials');
    if (credentials) {
      // const storage = remember ? localStorage : sessionStorage;
      const storage = localStorage;
      storage.setItem(credentialsKey, JSON.stringify(credentials));

      if (remember) {
        this.saveRememberMeEmail(credentials.user['email']);
      } else {
        this.saveRememberMeEmail();
      }
    } else {
      sessionStorage.clear();
      localStorage.clear();
      this.cookieService.delete('credentials');

      // $cookies.remove("userInfo");
    }
  }
  verifyLink(token: string) {
    return this._http.get(`/api/organizations/verify_email_token?token=${token}`, {
      withCredentials: true
    });
  }
}
