import { Injectable } from '@angular/core';
import { Chart, ChartConfiguration, ChartType } from 'chart.js';

@Injectable({
  providedIn: 'root'
})
export class ChartService {
  private chartManager: { [key: string]: Chart } = {};

  createChart(
    chartId: string,
    chartType: ChartType,
    labels: string[],
    data: number[],
    backgroundColor: string[],
    titleText: string
  ) {
    let options: any = {
      responsive: true,
      legend: {
        display: false
      },
      tooltips: {
        enabled: false
      },
      cutoutPercentage: 82, // Applies to doughnut charts
      maintainAspectRatio: false,
      title: {
        display: false
      }
    };
    // Merged options configuration
    if (chartType == 'horizontalBar') {
      options['scales'] = {
        xAxes: [
          {
            barPercentage: 0.5,
            barThickness: 13,
            minBarLength: 2,
            gridLines: {
              display: true,
              borderDash: [5, 5]
            },
            ticks: {
              beginAtZero: true,
              callback: function(value: number) {
                if (value % 1 === 0) {
                  // Only show whole numbers
                  return value;
                }
              } // Ensure x-axis (which is the horizontal axis) starts at zero
            }
          }
        ],
        yAxes: [
          {
            barPercentage: 0.5,
            barThickness: 13,
            minBarLength: 2,
            gridLines: {
              display: false // Show horizontal grid lines
            },
            ticks: {
              beginAtZero: true // Ensure x-axis (which is the horizontal axis) starts at zero
            }
          }
        ]
      };
    }

    const canvas = document.getElementById(chartId) as HTMLCanvasElement;
    if (!canvas) {
      console.error(`Canvas element with id ${chartId} not found`);
      return;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      console.error(`Failed to get 2D context from canvas with id ${chartId}`);
      return;
    }

    if (this.chartManager[chartId]) {
      this.chartManager[chartId].destroy();
    }

    const chartConfig: ChartConfiguration = {
      type: chartType,
      data: {
        labels: labels || [], // Default to empty array if undefined
        datasets: [
          {
            data: data || [], // Default to empty array if undefined
            backgroundColor: backgroundColor || [], // Default to empty array if undefined
            borderColor: backgroundColor || [], // Default to empty array if undefined
            borderWidth: 1
          }
        ]
      },
      options: options
    };

    var myChart = new Chart(ctx, chartConfig);
    this.chartManager[chartId] = myChart;
  }

  createDoughnutChart(chartId: string, labels: string[], data: number[], backgroundColor: string[], titleText: string) {
    this.createChart(chartId, 'doughnut', labels, data, backgroundColor, titleText);
  }

  createHorizontalBarChart(
    chartId: string,
    labels: string[],
    data: number[],
    backgroundColor: string[],
    titleText: string
  ) {
    this.createChart(chartId, 'horizontalBar', labels, data, backgroundColor, titleText);
  }

  createLineChart(
    chartId: string,
    labels: string[],
    datasets: { data: number[]; backgroundColor: string; borderColor: string }[],
    titleText: string
  ) {
    const canvas = document.getElementById(chartId) as HTMLCanvasElement;
    if (!canvas) {
      console.error(`Canvas element with id ${chartId} not found`);
      return;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      console.error(`Failed to get 2D context from canvas with id ${chartId}`);
      return;
    }

    if (this.chartManager[chartId]) {
      this.chartManager[chartId].destroy();
    }

    const chartConfig: ChartConfiguration = {
      type: 'line' as ChartType,
      data: {
        labels: labels || [],
        datasets: datasets.map(dataset => ({
          data: dataset.data || [],
          backgroundColor: dataset.backgroundColor || 'rgba(0,0,0,0)',
          borderColor: dataset.borderColor || 'rgba(0,0,0,1)',
          borderWidth: 2,
          fill: false // No fill under the line
        }))
      },
      options: {
        responsive: true,
        legend: {
          display: false
        },
        tooltips: {
          displayColors: false,
          backgroundColor: '#0A4D68',
          enabled: true,
          callbacks: {
            // Swapping title and label
            label: function(tooltipItem, data) {
              return data.labels[tooltipItem.index] as string; // Label as title
            },
            title: function(tooltipItems, data) {
              const datasetLabel = data.datasets[tooltipItems[0].datasetIndex].label || '';
              const value = tooltipItems[0].yLabel || 0;
              return `Failures ${value}`;
            },
            labelColor: function(tooltipItem, chart) {
              return {
                borderColor: '#0A4D68', // Transparent border to remove the square
                backgroundColor: '#0A4D68', // Dot with the line color
                borderWidth: 0
              };
            }
          }
        },
        maintainAspectRatio: false,
        title: {
          display: false
        },

        scales: {
          xAxes: [
            {
              gridLines: {
                display: false // Hide vertical grid lines
              },
              ticks: {
                beginAtZero: true,
                fontColor: '#717F8C' // Set tick labels color to black
              },
              scaleLabel: {
                display: true,
                fontColor: '#000' // Set label color to black
              },
              afterFit: function(scale) {
                scale.lineWidth = 2; // Increase thickness of x-axis line
                scale.lineColor = '#000'; // Set x-axis line color to black
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                borderDash: [5, 5] // Dotted horizontal lines
              },
              ticks: {
                beginAtZero: true, // Ensure y-axis starts at zero
                fontColor: '#717F8C',
                callback: function(value: number) {
                  if (value % 1 === 0) {
                    // Only show whole numbers
                    return value;
                  }
                } // Set tick labels color to black
              },
              scaleLabel: {
                display: true,
                fontColor: '#000' // Set label color to black
              },
              afterFit: function(scale) {
                scale.lineWidth = 2; // Increase thickness of x-axis line
                scale.lineColor = '#000'; // Set x-axis line color to black
              }
            }
          ]
        }
      }
    };

    var myChart = new Chart(ctx, chartConfig);
    this.chartManager[chartId] = myChart;
  }
}
