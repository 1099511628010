import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { minSelectedCheckboxes } from '@app/home/validators';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { WizardService } from '@app/home/wizard.services';
import { OrganisationService } from '@app/core/organisation.service';
import { findIndex } from 'lodash';
import { ToasterService } from '@app/shared/toaster/toastr.service';

@Component({
  selector: 'app-select-program-step',
  templateUrl: './selectProgram.component.html',
  styleUrls: ['./selectProgram.component.scss']
})
export class SelectProgramComponent implements OnInit, OnDestroy {
  @Input() stepper: MatHorizontalStepper;
  OrganisationID: string;
  stepForm: FormGroup;
  form_submitted = false;
  selected_programs: any = [];
  @Input() show = false;
  programs = [
    { guid: '1', name: 'HIPAA', id: 1 },
    { guid: '2', name: 'SOC2', id: 2 },
    { guid: '3', name: 'PCI', id: 3 }
  ];
  isLoading = true;
  guidSubscription: any;
  constructor(
    private fb: FormBuilder,
    private wizardService: WizardService,
    private orgService: OrganisationService,
    private toaster: ToasterService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.wizardService.organisationalProgram(guid).subscribe(data => {
        this.selected_programs = Array.from(new Set(data.map((item: any) => item['parent_compliance_program_guid'])));

        this.wizardService.getAllPrograms().subscribe((data2: any) => {
          data2 = data2.map((value: any) => {
            value['checked'] = this.selected_programs.includes(value['guid']);
            return value;
          });
          this.programs = data2;
        });

        this.isLoading = false;
      });
    });
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  get formData() {
    return this.stepForm.get('programs');
  }

  canGoToNextStep() {
    const value = this.submitForm();
    return true;
  }

  add_remove_program(index: any) {
    this.isLoading = true;
    this.programs[index]['checked'] = !this.programs[index]['checked'];
    this.isLoading = false;
  }

  arrayRemove(arr: any, value: any) {
    return arr.filter(function(ele: any) {
      return ele !== value;
    });
  }

  submitForm() {
    const selectedProgramIds = [];
    for (const program of this.programs) {
      if (program['checked']) {
        selectedProgramIds.push(program['guid']);
      }
    }
    this.form_submitted = true;
    const result = this.wizardService.organizationAddPrograms(this.OrganisationID, selectedProgramIds).toPromise();
    result.then(
      (data: any) => {
        this.orgService.setSelectedOrganisationGuid(this.OrganisationID).subscribe(data2 => {
          this.OrganisationID = data2;
          return true;
        });
      },
      error => {
        if (error.status === 409) {
          return true;
        } else if (error.status === 404) {
          this.toaster.showError('there was some problem: ' + error.error['failed'][0]);
        } else {
          return false;
        }
      }
    );
  }
}
