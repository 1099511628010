import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  programName: string;
}

@Component({
  selector: 'app-restart-program-dialog',
  templateUrl: './restart-program-dialog.component.html',
  styleUrls: ['./restart-program-dialog.component.scss']
})
export class RestartProgramDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<RestartProgramDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}
  confirm = false;

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onRestart(): void {
    this.dialogRef.close(true);
  }
}
