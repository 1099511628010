import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { OrganisationService } from '@app/core/organisation.service';
import { ModalService } from '@app/core/modal/modal.service';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { take } from 'rxjs/operators';
import { Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DocumentsService } from '../documents.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { UserService } from '@app/core/user.service';
import stringInfo from '../../../../translations/en-US.json';
import { values } from 'lodash';

export interface State {
  value: String;
  viewValue: string;
}
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  scrollLoader = false;
  spnning = false;
  modalScrollDistance = 0.1;
  show = true;
  modalScrollThrottle = 50;
  paginatedDataUrl: any;
  showDetails = false;
  showSecondDetails = false;
  guidSubscription: any;
  NotificationForm: FormGroup;
  submitted = false;
  error: {};
  organisationID: string | null;
  notificationDetails: any[];
  loading = true;
  status_types: any = [];
  search_title: any;
  type_filter: string | null = '';
  state_loading = false;
  type_loading = false;
  statusFilter: any;
  noData: boolean;
  status: any;
  isNotificationUnread: boolean = false;
  pagnationData: any;
  unread: any;
  backend_error: any;
  loginUser: any = {};
  page = 1;
  public pageSize = 10;
  totalLength: any;
  paginatedData: any;
  currentPage: number = 1;
  pagingNumber = 0;
  user_guid = '';
  searchText: any;
  state_filter: any;
  placeholderSearch = stringInfo.Attention_Required.PlaceHolder_Search;
  @Output() public schedule_changed = new EventEmitter();
  states: any = [
    { value: 'all', viewValue: 'all' },
    { value: 'open', viewValue: 'open' },
    { value: 'closed', viewValue: 'closed' }
  ];

  constructor(
    private ModalService: ModalService,
    private formBuilder: FormBuilder,
    private documentsService: DocumentsService,
    private orgService: OrganisationService,
    private router: Router,
    private route: ActivatedRoute,
    private backendService: BackendErrorService,
    private userservice: UserService,
    private toaster: ToasterService
  ) {}

  onScrollUp() {
    this.scrollLoader = false;
  }

  onScrollData() {
    this.scrollLoader = true;
    this.documentsService
      .getNotificationData(this.organisationID, this.statusFilter, this.search_title, (this.page += 1))
      .subscribe(
        data => {
          this.scrollLoader = false;
          this.pagnationData = data.results;
          this.paginatedDataUrl = data;
          this.notificationDetails = this.notificationDetails.concat(data.results);
        },
        error => {
          this.scrollLoader = false;
          this.error = error.error.message;
        }
      );
  }
  onScroll() {
    console.log(this.paginatedDataUrl);
    if (this.paginatedDataUrl?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }

  ngOnInit() {
    this.loginUser = this.userservice.getuserdetails();
    if (this.loginUser) {
      this.user_guid = this.loginUser.user['org_user_guid'];
    }
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.organisationID = guid;
    });
    this.getNotification();
  }

  refresh() {
    this.show = false;
    this.spnning = true;
    this.page = 1;
    this.searchText = '';
    this.search_title = '';
    this.statusFilter = '';
    this.getNotification();
  }

  Search(searchText: string) {
    this.search_title = searchText;
    if (this.statusFilter === undefined) {
      this.statusFilter = '';
    }
    this.documentsService
      .getNotificationData(this.organisationID, this.statusFilter, this.search_title, (this.page = 1))
      .subscribe(
        data => {
          this.notificationDetails = data.results;
          this.paginatedDataUrl = data;
          if (this.notificationDetails.length == 0) {
            this.noData = true;
          }
          this.show = true;
          this.spnning = false;
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
  }

  load_schedule_list() {
    this.getNotification();
  }

  change_state(state: any) {
    this.statusFilter = state;
    if (this.search_title === '') {
      this.search_title = this.search_title;
    }
    if (this.search_title == undefined) {
      this.search_title = '';
    }
    this.documentsService
      .getNotificationData(this.organisationID, this.statusFilter, this.search_title, (this.page = 1))
      .subscribe(
        data => {
          this.notificationDetails = data.results;
          this.paginatedDataUrl = data;
          if (this.notificationDetails.length == 0) {
            this.noData = true;
          }
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
  }

  getNotification() {
    if (this.search_title == undefined) {
      this.search_title = '';
    }
    if (this.statusFilter == undefined) {
      this.statusFilter = 'all';
    }
    this.documentsService
      .getNotificationData(this.organisationID, this.statusFilter, this.search_title, this.page)
      .subscribe(
        data => {
          this.loading = false;
          this.paginatedDataUrl = data;
          this.notificationDetails = data.results;
          this.show = true;
          this.spnning = false;
          if (this.notificationDetails.length == 0) {
            this.noData = true;
          }
        },
        error => {
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
  }
  openNewVersionForm(DocumentGuid: string, DocumentVersion: string, docTitle: string) {
    this.NewVersionOpenModal(DocumentGuid, DocumentVersion, docTitle);
  }
  NewVersionOpenModal(DocumentGuid: string, DocumentVersion: string, docTitle: string): void {
    this.ModalService.custom(CustomDialogComponent, {
      title: 'Create New Version',
      modalType: 'Create New Version',
      DocumentGuid: DocumentGuid,
      docTitle: docTitle,
      DocumentVersion: DocumentVersion
    })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getNotification();
          this.schedule_changed.emit();
        } else {
          console.log('Cancelled.');
        }
      });
  }
  ContinueCurrentVersionOpenModal(DocumentGuid: string, docTitle: string, guid: string): void {
    this.ModalService.custom(CustomDialogComponent, {
      title: 'No changes required',
      modalType: 'Continue Current Version',
      DocumentGuid: DocumentGuid,
      docTitle: docTitle,
      guid: guid
    })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getNotification();
          this.schedule_changed.emit();
        } else {
          console.log('Cancelled.');
        }
      });
  }

  goToDocumentdetailspage(DocGuid: any) {
    this.router.navigate(['organisation/documents/attention-required/' + DocGuid]);
  }

  onSubmit() {
    this.submitted = true;
  }

  OpenDocument(url: string) {
    // window.open(url, '_blank');
  }
  ViewTemplate(version_guid: any, doc_guid: any, url: any) {
    const payload_data = { organization_users: [this.user_guid] };
    this.documentsService.checkCollaboratorAdminRole(this.organisationID, version_guid).subscribe(
      (data: any) => {
        if (data['success'] == true) {
          window.open(url, '_blank');
        }
      },
      (error: any) => {
        this.backendService.setBackendError(error); // Pass the error.error value to the backendService
        this.backendService.backendError$.subscribe(error => {
          this.backend_error = error;
        });
        this.toaster.showError(this.backend_error);
        // this.backendService.setBackendError(error); // Pass the error.error value to the backendService
      }
    );
  }

  getClickEvents(action: string, document: string, title: string, guid: string): void {
    if (action == 'View Document') {
      this.ViewTemplate(document['document_version_guid'], document['guid'], document['document_store_identifier']);
    } else if (action == 'Create New Version') {
      this.NewVersionOpenModal(document['guid'], document['document_version'], document['title']);
    } else if (action == 'Continue Current Version') {
      this.ContinueCurrentVersionOpenModal(document['guid'], document['title'], guid);
    } else if (action == 'View Document Details') {
      this.goToDocumentdetailspage(document['document_version_guid']);
    }
  }
  toggleDetails() {
    this.showDetails = !this.showDetails;
  }
  toggleSecondDetails() {
    this.showSecondDetails = !this.showSecondDetails;
  }
}
