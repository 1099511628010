import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BackendErrorService {
  form_error: any;
  backend_error: any;
  private backendErrorSubject = new BehaviorSubject<string>('');
  backendError$ = this.backendErrorSubject.asObservable();

  setBackendError(error: any) {
    this.form_error = error.error;
    if (error.status == 400 || error.status == 401 || error.status == 403) {
      for (var key in this.form_error) {
        if (typeof this.form_error[key] === 'object') {
          this.backend_error = this.form_error[key][0];
        } else if (typeof this.form_error[key] === 'string') {
          this.backend_error = this.form_error[key];
        }
      }
    } else if (error.status == 403) {
      if (this.form_error.message) {
        this.backend_error = this.form_error.message;
      }
    }
    this.backendErrorSubject.next(this.backend_error);
  }
}
