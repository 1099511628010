<h2 mat-dialog-title translate>organisation_onboard.committee_members_page.Add/Remove Members</h2>
<ng-container *ngIf="isloading"> <mat-spinner></mat-spinner> </ng-container>
<ng-container *ngIf="!isloading">
  <div class="flex-row">
    <h5 *ngIf="data.members.length>0" translate>organisation_onboard.committee_members_page.Existing Members</h5>

    <mat-chip-list #memberChipList>
      <mat-chip
        *ngFor="let member of data.members; let i = index"
        [removable]="true"
        (removed)="removeMember(data.members[i])"
      >
        {{member}}
        <mat-icon matChipRemove translate>organisation_onboard.committee_members_page.cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>
  <br />
  <div class="flex-row">
    <!--<h5 translate>organisation_onboard.committee_members_page.Add Members to Committee</h5>-->
    <mat-dialog-content class="mat-typography">
      <mat-form-field class="email-chip-list" style="width: 100%">
        <mat-chip-list #chipList>
          <mat-chip
            *ngFor="let email of emails"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(email)"
          >
            {{email}}
            <mat-icon matChipRemove *ngIf="removable" translate
              >organisation_onboard.committee_members_page.cancel</mat-icon
            >
          </mat-chip>
          <input
            placeholder="Add Member..."
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)"
          />
        </mat-chip-list>
      </mat-form-field>
      <div *ngIf="error">
        <span style="color: #ff0000; font-size: 1em" translate>
          organisation_onboard.committee_members_page.committee_email_error</span
        ><br />
        <mat-chip-list>
          <mat-chip *ngFor="let e of error">
            {{e['email']}}
          </mat-chip>
        </mat-chip-list>
      </div>
    </mat-dialog-content>
  </div>

  <mat-dialog-actions align="end">
    <button class="btn-cancel ml-4 w-100" mat-dialog-close translate>
      organisation_onboard.committee_members_page.Cancel
    </button>
    <button class="btn-save ml-4 w-100" (click)="save()" cdkFocusInitial translate>
      organisation_onboard.committee_members_page.Save
    </button>
  </mat-dialog-actions>
</ng-container>
