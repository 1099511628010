import { Component, OnDestroy, OnInit } from '@angular/core';
import { SettingsService } from '@app/settings/settings.service';
import { MatDialog } from '@angular/material/dialog';
import { ImageSelectComponent } from '@app/settings/ImageCropper/imageSelect.component';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { FormBuilder, FormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { OrganisationService } from '@app/core/organisation.service';
import { HttpHeaders } from '@angular/common/http';
import { ModalService } from '@app/core/modal/modal.service';
import { take } from 'rxjs/operators';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';

@Component({
  selector: 'app-people',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  OrganisationID: string | null;
  guidSubscription: any;
  UserData: any = null;
  UserPhoto: any = null;
  UserSignature: any = null;
  isImageLoading = true;
  isSignatureLoading = true;
  isFormLoading = true;
  profileUpdateForm: FormGroup;
  submitted = false;
  error = {
    first_name: '',
    last_name: '',
    phone: '',
    old_password: '',
    password: '',
    password2: ''
  };
  isLoading = false;
  user_name: string;
  fonts = ['italic 32px Pacifico', 'italic 32px Yellowtail', 'italic 32px Damion', 'italic 32px Lobster'];
  loading = true;

  constructor(
    private orgService: OrganisationService,
    private settingsService: SettingsService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private modalService: ModalService
  ) {}

  ngOnDestroy() {}

  ngOnInit(): void {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
    });

    this.getProfileForForm();
    this.settingsService.getUserPhoto().subscribe(
      data => {
        this.UserPhoto = data.image;
        this.isImageLoading = false;
      },
      error => {
        this.isImageLoading = false;
      }
    );
    this.getUserSignature();
  }

  loadGoogleFonts(user_name: string) {
    this.settingsService.GenerateSignature(user_name, this.fonts);
  }

  getUserSignature() {
    this.settingsService.getUserSignature(this.OrganisationID).subscribe(
      data => {
        this.UserSignature = data.url;
        this.isSignatureLoading = false;
        this.loading = false;
      },
      error => {
        this.isSignatureLoading = false;
      }
    );
  }

  onReset() {
    this.submitted = false;
    this.isFormLoading = true;
    this.profileUpdateForm.reset();
    this.getProfileForForm();
    this.isFormLoading = false;
  }

  removeBackendValidation(element: HTMLElement) {
    this.error[`${element.getAttribute('formControlName')}`] = '';
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.profileUpdateForm.controls;
  }

  save() {
    this.submitted = true;
    this.loading = true;
    if (this.profileUpdateForm.invalid) {
      return;
    }
    const data = this.profileUpdateForm.value;
    let isPasswordUpdate = false;
    if (!data['password'] || !data['old_password'] || !data['password2']) {
      delete data['password'];
      delete data['password2'];
      delete data['old_password'];
    } else {
      isPasswordUpdate = true;
    }
    this.settingsService
      .submitUserProfile(data)
      .pipe(
        finalize(() => {
          this.profileUpdateForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data: any) => {
          this.error = {
            first_name: '',
            last_name: '',
            phone: '',
            old_password: '',
            password: '',
            password2: ''
          };
          this.loading = false;

          this.submitted = false;
          // if (isPasswordUpdate) {
          //   this.router.navigate(['/login'], { queryParams: { state: 'password-updated' }, replaceUrl: true });
          // } else {
          this.translate.get('organisation_profile.profile_toaster_message.profile_submitted').subscribe(res => {
            this.toaster.showSuccess(res);
          });
          // }
        },
        (error: any) => {
          this.loading = false;
          this.error = error.error;
        }
      );
  }

  selectImage() {
    const dialogRef = this.dialog.open(ImageSelectComponent, {
      width: '60%',
      maxHeight: '60%'
    });
    dialogRef.afterClosed().subscribe(
      (result: any) => {
        if (result) {
          this.isImageLoading = true;
          const formData = new FormData();
          formData.append('photo', result['file']);
          this.settingsService.uploadUserPhoto(formData).subscribe(data => {
            this.UserPhoto = result['b64'];
            this.isImageLoading = false;
          });
        }
      },
      (error: any) => {
        this.isImageLoading = false;
        console.log(error);
      }
    );
  }

  removeProfileImage() {
    this.isImageLoading = true;
    this.settingsService.removeUserPhoto().subscribe(
      (data: any) => {
        this.UserPhoto = null;
        this.isImageLoading = false;
      },
      (error: any) => {
        this.isImageLoading = false;
        console.log(error);
      }
    );
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.UserPhoto = reader.result;
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getProfileForForm() {
    this.isFormLoading = true;
    this.settingsService.getUserProfile().subscribe(data => {
      this.UserData = data;
      this.user_name = data['full_name'];
      this.loadGoogleFonts(this.user_name);
      data['first_name'] = data['full_name'] ? data['full_name'].split(' ')[0] : '';
      data['last_name'] = data['full_name']
        ? data['full_name']
            .split(' ')
            .splice(1)
            .join(' ')
        : '';
      this.profileUpdateForm = this.formBuilder.group(
        {
          first_name: [data['first_name'] ? data['first_name'] : '', Validators.required],
          last_name: [data['last_name'] ? data['last_name'] : '', Validators.required],
          phone: [data['phone'], []],
          password: ['', [Validators.minLength(8)]],
          password2: ['', [Validators.minLength(8)]],
          old_password: ['', [Validators.minLength(8)]],
          signature: ['']
        },
        {
          validator: [
            Validators.RequiredIfAny('old_password', 'password', 'password2'),
            Validators.MustMatch('password', 'password2')
          ]
        }
      );
      this.isFormLoading = false;
      this.loading = false;
    });
  }

  OpenChangeSignatureModal(): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Upload signature',
        modalType: 'Upload signature'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getUserSignature();
          this.translate.get('organisation_profile.profile_toaster_message.signature_uploaded').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  OpenGenerateSignatureModal(user_name: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Generate Signature',
        modalType: 'Generate Signature',
        user_name: user_name,
        google_fonts: this.fonts
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getUserSignature();
          this.translate.get('organisation_profile.profile_toaster_message.signature_uploaded').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  OpenDrawSignatureModal(): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Draw Signature',
        modalType: 'Draw Signature'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getUserSignature();
          this.translate.get('organisation_profile.profile_toaster_message.signature_uploaded').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }
}
