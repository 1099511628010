<div class="onboarding-main pt-4" *ngIf="show">
  <div class="container max-w-840">
    <div class="row">
      <div class="col">
        <h1 class="text-black-2 mb-3 font-weight-bold" translate>onboard.finish.Finish and Run</h1>
        <p class="text-black-2 mb-5" translate>
          onboard.finish.detail_message
        </p>
        <div class="w-640 m-auto" *ngIf="unique_schedules.length > 0">
          <ng-container *ngFor="let schedule of unique_schedules; let s_i = index">
            <div class="cb-accordian-yellow mb-4" [ngClass]="{ expand: schedule['expand'] }">
              <div class="acc-header d-flex justify-content-between">
                <h5 class="text-black-1">
                  <app-checkbox-schedule
                    [schedule]="schedule"
                    (checkbox_clicked)="change_schedule_selection(s_i)"
                  ></app-checkbox-schedule>
                  {{ schedule.display_value }}
                </h5>
                <div class="d-flex align-items-center">
                  <button class="btn-acc-toggle ml-4 mr-3" (click)="accordian_toggle(s_i)">
                    <i class="fas fa-chevron-up text-black-2" *ngIf="schedule['expand']"></i>
                    <i class="fas fa-chevron-down text-black-2" *ngIf="!schedule['expand']"></i>
                  </button>
                </div>
              </div>
              <div class="acc-body">
                <table class="w-100p table-2">
                  <thead>
                    <tr>
                      <td></td>
                      <td>
                        <h4 class="font-weight-bold text-black-2 p-3" translate>onboard.finish.Committee Name</h4>
                      </td>
                      <td><h4 class="font-weight-bold text-black-2 p-3" translate>onboard.finish.Meeting Name</h4></td>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let event of unique_schedules_data[schedule['value']]; let e_i = index">
                      <tr>
                        <td>
                          <label
                            class="form-check-label text-black-1 checkbox-3 d-inline ml-30"
                            for="inlinecheck1-{{ event['review_event_guid'] }}"
                          >
                            <input
                              (click)="change_event_selection(schedule['value'], e_i, s_i)"
                              class="form-check-input"
                              type="checkbox"
                              name="inlineRadioOptions"
                              id="inlinecheck1-{{ event['review_event_guid'] }}"
                              [checked]="event['selected']"
                            />
                            <span class="checkmark"></span>
                          </label>
                        </td>
                        <td>
                          <h4 class="text-black-1 p-3 pr-3 w-240">
                            {{ event['committee_name'] }}
                          </h4>
                        </td>
                        <td>
                          <h4 class="text-black-1 p-3">{{ event['meeting_name'] }}</h4>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
