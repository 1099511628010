import { UserService } from '@app/core/user.service';
import { Component, Input, OnInit } from '@angular/core';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { OrganisationService } from '@app/core/organisation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';

@Component({
  selector: 'app-manage-document-group',
  templateUrl: './manage-document-group.component.html',
  styleUrls: ['./manage-document-group.component.scss']
})
export class ManageDocumentGroupComponent implements OnInit {
  @Input() title_tag: string;
  @Input() guid: string;
  @Input() selectedGroup: any = [];
  error: any;
  publishedDocuments: any = [];
  selectedDocuments: any[] = [];
  search_title: any;
  documentCount = 0;
  isChanged = false;
  searchText: any;
  isLoading = false;
  OrganisationID: string | null;
  guidSubscription: any;
  page = 1;
  currentDocuments: any = [];
  user_guid: any;
  backend_error: any;
  clickedRow: any;
  isViewLoading = false;
  loginUser: any;
  publishedDocumentsCopy: any = [];
  constructor(
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private documentService: DocumentsService,
    private userService: UserService,
    private documentsService: DocumentsService,
    private backendErrorService: BackendErrorService
  ) {}

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.loginUser = this.userService.getuserdetails();
    if (this.loginUser) {
      this.user_guid = this.loginUser.user['org_user_guid'];
      console.log(this.user_guid);
    }
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.documentService.publishedDocumentsBehavour.subscribe((res: any) => {
        if (res.isUpdated) {
          this.getPublishedDocList();
        } else {
          this.publishedDocuments = JSON.parse(JSON.stringify(res.publishedDocuments));
          this.publishedDocumentsCopy = this.publishedDocuments;
          this.setData();
        }
      });
    });
  }
  searchDocuments(searchText: string) {
    this.publishedDocuments = this.publishedDocumentsCopy.filter((doc: any) => doc.title.includes(searchText));
    console.log(this.publishedDocuments);
  }
  private getPublishedDocList(): void {
    this.isLoading = true;
    this.documentService.getAllPublishedDocsLite(this.OrganisationID, false).subscribe(
      data => {
        this.publishedDocuments = JSON.parse(JSON.stringify(data));
        this.publishedDocumentsCopy = JSON.parse(JSON.stringify(data));
        this.documentService.publishedDocumentsBehavour.next({ isUpdated: false, publishedDocuments: data });
        this.setData();
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
      }
    );
  }
  setData() {
    this.selectedGroup.documents.forEach((element: any) => {
      this.publishedDocuments.forEach((item: any) => {
        if (item.document_version == element.doc_version_guid) {
          item['checked'] = true;
        }
      });
      this.publishedDocumentsCopy.forEach((item: any) => {
        if (item.document_version == element.doc_version_guid) {
          item['checked'] = true;
        }
      });
    });

    this.currentDocuments = JSON.parse(
      JSON.stringify(
        this.publishedDocuments.filter((item: any) => item.checked).map((item: any) => item.document_version)
      )
    );
    this.selectedDocuments = JSON.parse(
      JSON.stringify(
        this.publishedDocuments.filter((item: any) => item.checked).map((item: any) => item.document_version)
      )
    );
  }
  selectDocument(doc: any) {
    if (this.searchText) {
      this.selectedDocuments = [
        ...this.publishedDocuments.filter((item: any) => item.checked).map((item: any) => item.document_version),
        ...this.publishedDocumentsCopy.filter((item: any) => item.checked).map((item: any) => item.document_version)
      ];
    } else {
      this.selectedDocuments = this.publishedDocuments
        .filter((item: any) => item.checked)
        .map((item: any) => item.document_version);
    }
    this.isChanged = true;
  }
  saveDocuments() {
    console.log(this.trackArrayModifications(this.currentDocuments, this.selectedDocuments));
    this.activeModal.close({
      ...this.trackArrayModifications(this.currentDocuments, this.selectedDocuments),
      update: true
    });
  }

  trackArrayModifications(previousArray: any = [], currentArray: any = []) {
    const addedElements = currentArray.filter((element: any) => !previousArray.includes(element));
    const removedElements = previousArray.filter((element: any) => !currentArray.includes(element));
    return {
      added: addedElements,
      removed: removedElements
    };
  }
  viewDocument(version_guid: any, doc_guid: any, url: any) {
    this.clickedRow = doc_guid;
    this.isViewLoading = !this.isViewLoading;
    const payload_data = { organization_users: [this.user_guid] };
    this.documentService.check_collaborator(this.OrganisationID, version_guid).subscribe(
      (data: any) => {
        if (data['success'] == true) {
          this.clickedRow = null;
          this.isViewLoading = false;
          window.open(url, '_blank');
        } else {
          this.documentsService.SubmitAddReadersData(this.OrganisationID, version_guid, payload_data).subscribe(
            (data: any) => {
              if (data) {
                this.clickedRow = null;
                this.isViewLoading = false;
                window.open(url, '_blank');
              }
            },
            (error: any) => {
              this.clickedRow = null;
              this.isViewLoading = false;
              this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
              this.backendErrorService.backendError$.subscribe(error => {
                this.backend_error = error;
              });
            }
          );
        }
      },
      (error: any) => {
        this.clickedRow = null;
        this.isViewLoading = false;
        this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
        });
      }
    );
  }
}
