<div>
  <div class="modal-header cb-modal-header">
    <h4 class="modal-title font-weight-bold text-black-1" translate>Document_Invite_Training_Groups.select_document</h4>
  </div>
  <div class="modal-body">
    <div class="col-12 error-mssg" *ngIf="error['error']">
      <label class="text-danger">*{{ error['error'] }}</label>
    </div>
    <form [formGroup]="MultiSelectDocumentForm">
      <div class="row mt-1 ml-3">
        <div class="row mt-2">
          <div class="col-12 error-mssg" *ngIf="showError">
            <label class="text-danger" translate>*Atleast one document is required.</label>
          </div>
          <div class="row mt-2">
            <div class="col-3">
              <label class="text-black-2 col-12" translate>Published documents</label>
            </div>
            <div class="col-6">
              <ng-multiselect-dropdown
                class="multi-select-dropdown"
                [placeholder]="'Select documents'"
                [data]="DocumentsList"
                [(ngModel)]="selectedItems"
                [settings]="dropdownSettings"
                (onSelect)="onItemSelect($event)"
                (onDeSelect)="onItemDeSelect($event)"
                [ngModelOptions]="{ standalone: true }"
              >
              </ng-multiselect-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div class="row col-12">
        <div class="col-3">
          <label class="text-black-2 col-6" style="padding-left: 0;" translate>External documents</label>
        </div>
        <div class="input-group col-6 col-sm-9 col-lg-9" formArrayName="document_links">
          <div style="width: 100%; margin-top: 5px;" *ngFor="let opt of optionsData.controls; let i = index">
            <div class="row" style="margin-right: 0; margin-left: 2px;" [formGroupName]="i">
              <!--                <div class="col-2" style="margin-right: -7em;">-->
              <!--                  <img src="../../../../assets/images/logo/{{ opt.get('urltype').value }}.png" width="20px" />-->
              <!--                </div>-->
              <div class="col-5 select_doc_input">
                <input
                  id="title"
                  class="form-control cb-input"
                  placeholder="Document title."
                  formControlName="title"
                  name="title"
                  type="text"
                />
              </div>
              <div class="col-5 select_doc_input">
                <input
                  id="url"
                  class="form-control cb-input"
                  placeholder="Document link."
                  formControlName="url"
                  name="url"
                  type="text"
                />
              </div>
              <div class="col-2 mt-1 delete-box">
                <i
                  (click)="removeOption(i)"
                  style="font-size: 17px;"
                  class="fa fa-trash fa fa-trash btn-edit cursor-pointer"
                  aria-hidden="true"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="row mb-6" style="float: right;">
      <div class="col-12">
        <p
          class="text-cyan add_document_url"
          style="margin-top: 8px; "
          (click)="addDocumentUrl()"
          aria-hidden="true"
          translate
        >
          Document_Invite_Training_Groups.Add_External_Document
        </p>
      </div>
    </div>
  </div>
  <div class="modal-footer border-0 cb-modal-footer">
    <button class="btn-cancel ml-4 w-100" (click)="cancel()" ngbAutoFocus translate>
      Document_Invite_Training_Groups.Cancel
    </button>
    <button class="btn-save ml-4 w-100" *ngIf="!isLoading" (click)="save()" translate>
      Document_Invite_Training_Groups.Submit
    </button>
    <button class="btn-save ml-4 w-100" disabled *ngIf="isLoading">
      <i class="fa fa-spinner fa-spin" style="font-size: 16px;"></i>
    </button>
  </div>
</div>
