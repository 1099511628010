<ng-container [ngSwitch]="typeMessage">
  <div *ngSwitchCase="'organisation-not-selected'">
    <img class="no-organisation-image" src="../../../assets/images/no-Organisation/no_organisation_selected1.svg" />
    <div class="text-no-organisation" translate>organisation_no_data.no_organisation</div>
    <div class="sub-text-no-organisation" translate>organisation_no_data.select_organisation</div>
  </div>
  <div *ngSwitchCase="'no-administrator-added'">
    <img class="no-administrator" src="../../../assets/images/no-people/select-team.svg" />
    <div class="text-no-person" translate>organisation_no_data.no_administrator</div>
    <div class="sub-text-no-person" translate>organisation_no_data.no_administrator_message</div>
  </div>
  <div *ngSwitchCase="'no-people-added'">
    <img class="no-administrator" src="../../../assets/images/no-people/add-people.svg" />
    <div class="text-no-person" translate>organisation_no_data.no_people</div>
    <div class="sub-text-no-person" translate>organisation_no_data.no_people_message</div>
  </div>
  <div *ngSwitchCase="'no-lead-generated'">
    <img class="no-administrator wd-600" src="../../../assets/images/no-people/no_lead.svg" />
    <div class="text-no-person" translate>organisation_lead_generation.no_lead_generated_message</div>
  </div>
</ng-container>
