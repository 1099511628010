<div>
  <div class="page-header ">
    <div class=" mt-2 d-flex justify-content-between">
      <div>
        Manage Controls
      </div>
      <div class="d-flex">
        <div>
          <div style="width: 220px;font-size: 10px; " class="d-inline-block mx-2">
            <div style="display: grid;font-size: 10px;">
              <mat-form-field appearance="outline">
                <mat-select
                  placeholder="All organization type"
                  (selectionChange)="applyFilters($event)"
                  [(ngModel)]="organisationType"
                >
                  <mat-option *ngFor="let item of organiationTypes" [value]="item.guid">{{ item.title }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div>
          <button mat-raised-button class="mx-2 " style="background-color: #fff;color: #2a647c;" (click)="onNoClick()">
            Close
          </button>
        </div>
        <div>
          <button
            [disabled]="!isUpdate"
            class="mx-2"
            mat-raised-button
            [style.background-color]="!isUpdate ? '#85b2c6' : '#2a647c'"
            style="color: #fff;"
            (click)="updateApplicableData()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div class="loader-container" *ngIf="scrollLoader" class="font-weight-bold text-black-3 w-100p text-center ">
    <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': scrollLoader }"></i> &nbsp;
  </div>
  <div *ngIf="!scrollLoader" class="manage-control-container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
      <ng-container matColumnDef="control">
        <th mat-header-cell *matHeaderCellDef>Control</th>
        <td
          mat-cell
          *matCellDef="let element"
          class="text-truncate pr-3 "
          [matTooltip]="element?.details?.program_unit?.code"
          matTooltipPosition="above"
        >
          {{ element?.details?.program_unit?.code }}
        </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>Title</th>
        <td
          mat-cell
          *matCellDef="let element"
          class="text-truncate pr-3 "
          [matTooltip]="element?.details?.program_unit?.title"
          matTooltipPosition="above"
        >
          {{ element?.details?.program_unit?.title }}
        </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef>Category</th>
        <td
          mat-cell
          *matCellDef="let element"
          class="text-truncate pr-3"
          [matTooltip]="element?.details?.categories"
          matTooltipPosition="above"
        >
          {{ getOrgType(element?.organization_type) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="applicability">
        <th mat-header-cell *matHeaderCellDef>
          Applicability
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="selectControl($event, row.guid)"
            [checked]="row.applicable"
            [aria-label]="checkboxLabel(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="table-header"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
