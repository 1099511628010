<!--<mat-toolbar color="primary">-->
<!--  <div class="home-heading-component">-->
<!--    <div class="logo-section">-->
<!--      <img style="width: 97%;" src="../../../../assets/images/logo/logo.png" />-->
<!--    </div>-->
<!--    <div class="header-section">-->
<!--      <a routerLink="/organisation" class="company-name" translate>APP_NAME</a>-->
<!--    </div>-->
<!--    <div [ngClass]="userType !== 'system_admin_role' ? 'header-section-name' : 'header-section-selection'" class="">-->
<!--      <span class="sub-heading-component-name" *ngIf="userType !== 'system_admin_role'">{{ orgName }}</span>-->
<!--      <mat-form-field class="sub-heading-component-selection" *ngIf="userType === 'system_admin_role'">-->
<!--        <mat-select [(value)]="organisationID" (selectionChange)="organisationChange($event)">-->
<!--          <mat-option *ngFor="let program of organisations" value="{{ program['guid'] }}">-->
<!--            {{ program['name'] }}-->
<!--          </mat-option>-->
<!--        </mat-select>-->
<!--      </mat-form-field>-->
<!--    </div>-->
<!--    <div class="header-section" fxFlex fxLayout fxLayoutAlign="end" fxHide.xs>-->
<!--      <ul fxLayout fxLayoutGap="15px" class="navigation-items">-->
<!--        <li>-->
<!--          <div class="navbar-nav ml-auto" placement="bottom-right">-->
<!--            <div>-->
<!--              <span class="user-name">{{ userName }}</span>-->
<!--              <i class="fas fa-user-circle" style="font-size: 34px;" mat-button [matMenuTriggerFor]="menu"></i>-->
<!--            </div>-->
<!--            <mat-menu #menu="matMenu"> <button mat-menu-item (click)="logout()" translate>Logout</button> </mat-menu>-->
<!--          </div>-->
<!--        </li>-->
<!--      </ul>-->
<!--    </div>-->
<!--  </div>-->
<!--</mat-toolbar>-->

<div class="cb-top-nav-bar">
  <div class="d-flex align-items-flex-start">
    <i class="fas fa-bars text-gray d-inline-block py-3 px-4 d-lg-none" (click)="openSidenav()"></i>
    <a routerLink="/organisation/documents" class="d-inline-block">
      <img src="../../../../assets/images/core/compliance_bot_logo.png" class="cb-logo-top" />
    </a>
  </div>

  <div>
    <!--    <div ngbDropdown class="d-inline-block btn-notification">-->
    <!--      <button class="" id="dropdownForm1" ngbDropdownToggle>-->
    <!--        <i class="fas fa-bell text-gray"></i>-->
    <!--      </button>-->
    <!--      <div ngbDropdownMenu aria-labelledby="dropdownForm1">-->
    <!--        No Notifications-->
    <!--      </div>-->
    <!--    </div>-->

    <div ngbDropdown class="d-inline-block btn-user-account mx-4" placement="bottom-right">
      <button class="" id="dropdownForm1" ngbDropdownToggle>
        <img src="../../../../assets/images/core/user-avatar.svg" class="user-avatar" />
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownForm1">
        <!-- <a class="active" *ngIf="userType !== 'system_admin_role'"> <span class="org_dot"></span>{{ orgName }} </a>
        <ng-container *ngIf="userType === 'system_admin_role'"> -->
        <a
          class="data-hover"
          [class.active]="organisationID === program['guid']"
          *ngFor="let program of organisations; let i = index"
          [attr.data-index]="i"
          (click)="organisationChange(program['guid'], i)"
        >
          <span class="org_dot"></span>{{ program['name'] }}
        </a>
        <!-- </ng-container> -->
        <div class="dropdown-divider"></div>
        <a class="data-hover" (click)="logout()" translate><i class="fas fa-power-off"></i> Sign Out</a>
      </div>
    </div>
  </div>
</div>
