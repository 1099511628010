import { WizardService } from '@app/home/wizard.services';
import { Component, Input, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '@app/core/modal/modal.service';
import { take } from 'rxjs/operators';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '@app/settings/settings.service';
import { OrganisationService } from '@app/core/organisation.service';
import { UserService } from '@app/core/user.service';
import confetti from 'canvas-confetti';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';

@Component({
  selector: 'app-save-cb-docuements',
  templateUrl: './save-cb-docuements.component.html',
  styleUrls: ['./save-cb-docuements.component.scss']
})
export class SaveCBDocuementsComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private modalService: ModalService,
    private toaster: ToasterService,
    private translate: TranslateService,
    private orgService: OrganisationService,
    private settingsService: SettingsService,
    private userservice: UserService,
    private wizardService: WizardService,
    private backendErrorService: BackendErrorService
  ) {}

  loginUser: any;
  userName: any;
  isConfirm: boolean = false;
  isLoadingButton: boolean = false;
  folderValue: string = 'Policy and Procedure Documents';
  fonts = ['italic 32px Pacifico', 'italic 32px Yellowtail', 'italic 32px Damion', 'italic 32px Lobster'];
  OrganisationID: string | null;
  guidSubscription: any;
  UserSignature: any = null;
  isSignatureLoading = true;
  loading = true;
  backend_error: string;
  intervalId: any;
  showSignatureOption: boolean = true;
  DocuementGenaratedTitles: any = [];
  file: any;
  size: any;
  file_type: any;
  file_type_error: boolean = false;
  file_size_error: boolean = false;
  signature: any;
  ngOnInit(): void {
    this.loginUser = this.userservice.getuserdetails();
    this.userName = this.loginUser.user['first_name'] + ' ' + this.loginUser.user['last_name'];
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.wizardService.DocuementGenaratedTitles.subscribe(data => {
        this.DocuementGenaratedTitles = data;
      });
      if (this.OrganisationID) {
        this.loadGoogleFonts(this.userName);
        this.getUserSignature();
      }
    });
  }

  checkboxChecked(): boolean {
    const checkbox = document.getElementById('inlinecheck') as HTMLInputElement;
    return checkbox ? checkbox.checked : false;
  }
  viewGrcData() {
    this.isLoadingButton = true;
    this.wizardService.genarateOrganizationDocuments(this.OrganisationID, this.DocuementGenaratedTitles).subscribe(
      (data: any) => {
        this.activeModal.close(true);
        this.isLoadingButton = false;
        this.wizardService.isConfirmedFinishedHippaProgram.next(true);
        this.orgService.setIsOrganisationOnboarded(true);
        this.router.navigateByUrl('/organisation/dashboard?cert=true');
      },
      (error: any) => {
        this.isLoadingButton = false;
        this.backendErrorService.setBackendError(error);
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
        });
      }
    );
  }

  private showConfetti(): Promise<void> {
    return new Promise<void>(resolve => {
      this.intervalId = setInterval(() => {
        confetti({
          particleCount: 10,
          spread: 70,
          origin: { y: 0.6 }
        });
      }, 100);

      Swal.getConfirmButton().addEventListener('click', () => {
        clearInterval(this.intervalId); // Stop the confetti
        resolve();
      });
    });
  }

  loadGoogleFonts(user_name: string) {
    this.settingsService.GenerateSignature(user_name, this.fonts);
  }

  getUserSignature() {
    this.settingsService.getUserSignature(this.OrganisationID).subscribe(
      data => {
        this.UserSignature = data.url;
        if (!this.UserSignature) {
          this.uploadSignature();
        }
        this.isSignatureLoading = false;
        this.loading = false;
      },
      error => {
        this.isSignatureLoading = false;
      }
    );
  }
  uploadSignature() {
    this.isSignatureLoading = true;
    this.signature = this.settingsService.GenerateInitialSignature(this.userName, this.fonts);
    this.file = this.convertBase64ToFile(this.signature[2], 'signature');
    this.size = this.file.size;
    this.file_size_error = false;
    if (this.size < 1024 * 1024 * 5 || this.size == 1024 * 1024 * 5) {
      this.file_size_error = false;
    } else {
      this.file_size_error = true;
    }
    const data = { size: this.size, comment: '' };
    this.settingsService.uploadUserSignature(this.OrganisationID, data).subscribe((data: any) => {
      this.settingsService.uploadUserSignatureToGCP(data.url, this.file).subscribe((data: any) => {
        this.getUserSignature();
      });
    });
  }
  convertBase64ToFile(base64String: any, fileName: any) {
    let arr = base64String.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let uint8Array = new Uint8Array(n);
    while (n--) {
      uint8Array[n] = bstr.charCodeAt(n);
    }
    let file = new File([uint8Array], fileName, { type: mime });
    return file;
  }
  OpenChangeSignatureModal(): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Upload signature',
        modalType: 'Upload signature'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getUserSignature();
          this.translate.get('organisation_profile.profile_toaster_message.signature_uploaded').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  OpenGenerateSignatureModal(user_name: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Generate Signature',
        modalType: 'Generate Signature',
        user_name: user_name,
        google_fonts: this.fonts
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getUserSignature();
          this.translate.get('organisation_profile.profile_toaster_message.signature_uploaded').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }

  OpenDrawSignatureModal(): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Draw Signature',
        modalType: 'Draw Signature'
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getUserSignature();
          this.translate.get('organisation_profile.profile_toaster_message.signature_uploaded').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        } else {
          console.log('Cancelled.');
        }
      });
  }
}
