<div class="onboarding-header">
  <ul>
    <!--<li class="active"><span>1</span> Organization Details</li>-->
    <li [ngClass]="{ active: activeStep == 1 }">
      <span translate>onboard.onboard_header.1</span>
      {{ 'onboard.onboard_header.Connect Slack' | translate }}
    </li>
    <li [ngClass]="{ active: activeStep == 2 }">
      <span translate>onboard.onboard_header.2</span>
      {{ 'onboard.onboard_header.Select Program' | translate }}
    </li>
    <li [ngClass]="{ active: activeStep == 3 }">
      <span translate>onboard.onboard_header.3</span>
      {{ 'onboard.onboard_header.Review Schedule' | translate }}
    </li>
    <li [ngClass]="{ active: activeStep == 4 }">
      <span translate>onboard.onboard_header.4</span>
      {{ 'onboard.onboard_header.Committee Members' | translate }}
    </li>
    <li [ngClass]="{ active: activeStep == 5 }">
      <span translate>onboard.onboard_header.5</span>
      {{ 'onboard.onboard_header.Backend Automation' | translate }}
    </li>
    <li [ngClass]="{ active: activeStep == 6 || activeStep == 7 }">
      <span translate>onboard.onboard_header.6</span>
      {{ 'onboard.onboard_header.Finish' | translate }}
    </li>
    <li class="mobile-steps">
      <i>{{ activeStep }}</i
      >/6
    </li>
  </ul>
</div>
