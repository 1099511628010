import { Component, OnDestroy, OnInit } from '@angular/core';
import { OrganisationService } from '@app/core/organisation.service';
import { OrganisationAdminService } from '@app/Organisation-admin/organisation-admin.service';
import { Router } from '@angular/router';
import { AddEventDiagComponent } from '@app/settings/Events/addEventDiagComponent/addEventDiag.component';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '@app/core/user.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit, OnDestroy {
  isLoading = true;
  guidSubscription: any;
  OrganisationID: string;
  orgSelect = false;
  Events: any;
  closeResult: any;
  error: any;
  scrollLoader = false;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  page = 1;
  pagnationData: any;
  search_title: any;
  searchText: any;
  setUserDetails: any;
  showAddEvents: boolean = false;
  paginatedDataUrl: any;

  constructor(
    private orgService: OrganisationService,
    private orgAdminService: OrganisationAdminService,
    private router: Router,
    private modalService: NgbModal,
    private user: UserService
  ) {}

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  ngOnInit() {
    this.setUserDetails = this.user.getuserdetails();

    for (let i = 0; i <= this.setUserDetails.user.role.length; i++) {
      if (this.setUserDetails.user.role[i] == 'organization_admin_role') {
        this.showAddEvents = true;
      }
    }

    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getAllEvents();
    });
  }

  onScrollUp() {
    this.scrollLoader = false;
  }

  onScrollData() {
    this.scrollLoader = true;
    if (this.search_title === undefined) {
      this.search_title = '';
    }
    this.orgAdminService
      .getOrganizationMeetingWithStatus(this.OrganisationID, '', (this.page += 1), this.search_title)
      .subscribe(
        data => {
          this.scrollLoader = false;
          this.paginatedDataUrl = data;
          this.pagnationData = data.results;
          this.Events = this.Events.concat(data.results);
        },
        error => {
          this.scrollLoader = false;
          this.error = error.error.message;
        }
      );
  }
  onScroll() {
    if (this.paginatedDataUrl?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }
  updateSearchText(x: any) {
    this.search_title = x.target.value;
    if (this.search_title == undefined) {
      this.search_title = '';
    }
  }
  userOnbboard() {
    if (this.OrganisationID !== null) {
      this.orgSelect = true;
      this.orgAdminService
        .isOrganisationOnboarded(this.OrganisationID)
        .pipe()
        .subscribe(data => {
          const isOrganisationOnboarded = data.isorganizationonboarded;
          this.orgService.setIsOrganisationOnboarded(isOrganisationOnboarded);
          if (!isOrganisationOnboarded) {
            this.router.navigateByUrl('/home');
          }
        });
    } else {
      this.orgSelect = false;
    }
  }

  Search(search_title: string) {
    this.search_title = search_title;
    for (let i = 0; i < this.Events.length; i++) {
      if (this.Events[i]['title'] != this.search_title) {
        this.page = 1;
      }
    }
    this.orgAdminService
      .getOrganizationMeetingWithStatus(this.OrganisationID, '', (this.page = 1), this.search_title)
      .subscribe(
        data => {
          this.isLoading = false;
          this.Events = data.results;
        },
        error => {
          this.error = error.error.message;
          this.isLoading = false;
          console.log('Error:', error.error.message);
        }
      );
  }

  private getAllEvents() {
    if (this.search_title === undefined) {
      this.search_title = '';
    }
    this.orgAdminService
      .getOrganizationMeetingWithStatus(this.OrganisationID, '', this.page, this.search_title)
      .subscribe(
        data => {
          this.Events = data.results;
          this.paginatedDataUrl = data;
          this.userOnbboard();
          this.isLoading = false;
        },
        error => {
          this.isLoading = false;
          this.error = error.error.message;
          console.log('Error:', error.error.message);
        }
      );
  }
  open_add_event_diag() {
    this.modalService.open(AddEventDiagComponent, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      result => {
        this.getAllEvents();
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
