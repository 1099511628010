import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '@app/core/user.service';
import { WizardService } from '@app/home/wizard.services';
// import { MatHorizontalStepper } from '@angular/material/stepper';
import { OrganisationService } from '@app/core/organisation.service';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-create-backendautomation-step',
  templateUrl: './createBackendAutomation.component.html',
  styleUrls: ['./createBackendAutomation.component.scss']
})
export class CreateBackendAutomationComponent implements OnInit, OnDestroy, AfterViewInit {
  // @Input() stepper: MatHorizontalStepper;
  @Input() show = false;
  AWSForm: FormGroup;
  jiraForm: FormGroup;
  organisationID: any;
  organizationJiraCredemtials: object = null;
  isLoading: boolean = true;
  boxForm: FormGroup;
  isAWSTestButtonLoading: boolean = false;
  isAWSSubmitButtonLoading: boolean = false;
  isAWSFormSubmitted: boolean = false;
  isJiraSubmitButtonLoading: boolean = false;
  isJiraTestButtonLoading: boolean = false;
  isJiraFormSubmitted: boolean = false;
  bucket_name: string = '';
  jiraApiKey = false;
  awsAccessId = false;
  awsAccessKey = false;
  guidSubscription: any;
  orgGuidSubscription: any;
  postAWSDataSubscription: any | null = null;
  postJIRADataSubscription: any | null = null;
  boxOrganization: object;
  boxOrganizationType: any;
  // Dropdown value
  isJiraFormIssueTypeRecieved = false;
  isJiraIssueLoading = false;
  jiraIssueTypeError = '';
  isBoxSubmitButtonLoading: boolean;
  isBoxFormSubmitted = false;
  selectedFile: any;
  postBoxDataSubscription: any | null = null;
  error: {};
  selectedOption: any;
  show_GD_button: boolean = false;
  show_box_button: boolean = false;
  DocStoresList: any;
  backend_error: any;
  showSubmitBtn: Boolean = false;
  showBoxData = true;
  showBoxDetails: Boolean = false;

  show_initialize_folder_btn: boolean = false;
  is_shared_drive: boolean = false;
  google_store_url = 'https://docs.google.com/';
  private redirectUri = 'http://127.0.0.1:4200/home';
  private redirectUriCreateFolder = 'http://127.0.0.1:4200/home?createFolder=true';
  private clientId = '';
  private clientSecret = '';
  private tokenUrl = 'https://oauth2.googleapis.com/token';
  CREATE_SHARED_DRIVE_SCOPES = ['https://www.googleapis.com/auth/drive'];
  FILE_DRIVE_SCOPES = ['https://www.googleapis.com/auth/drive.file'];
  driveConfiguration: string = '';
  drivePermissions: boolean = false;
  storeUpdating = false;
  boxOrganizationdoc_store_url = false;
  showData = false;
  constructor(
    private fb: FormBuilder,
    private wizard: WizardService,
    private _user: UserService,
    private orgService: OrganisationService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private backendErrorService: BackendErrorService,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
    this.redirectUri = window.location.origin + '/home';
    this.redirectUriCreateFolder = window.location.origin + '/home?createFolder=true';
    if (window.location.origin.includes('127.0.0.1') || window.location.origin.includes('localhost')) {
      this.redirectUri = 'http://127.0.0.1:4200/home';
      this.redirectUriCreateFolder = 'http://127.0.0.1:4200/home?createFolder=true';
    }
  }

  ngOnInit() {
    this.getOrganizationDetails();
    this.getDocumentStoreList();

    this.AWSForm = this.fb.group({
      AWS_ACCESS_ID: ['', Validators.required],
      AWS_ACCESS_KEY: ['', Validators.required],
      S3_BUCKET_NAME: ['', Validators.required]
    });
    this.jiraForm = this.fb.group({
      JIRA_API_KEY: ['', Validators.required],
      JIRA_ISSUE_TYPE: ['', Validators.required],
      JIRA_PROJECT_KEY: ['', Validators.required],
      JIRA_SERVER_URL: ['', Validators.required],
      JIRA_USER_EMAIL: ['', Validators.required]
    });
    this.boxForm = this.fb.group({
      store_secret: [''],
      document_store: ['', Validators.required],
      doc_store_url: ['', Validators.required],
      store: ['']
    });

    this.show_GD_button = false;
  }

  getDocumentStoreList() {
    this.wizard.getDocumentStoresList().subscribe((data: any) => {
      this.DocStoresList = data;
    });
  }

  ngAfterViewInit() {
    this.orgGuidSubscription = this.orgService.__organisation_guid.subscribe((guid: any) => {
      this.organisationID = guid;
      this.getBoxOrganizationDetails();
      this.getDriveConfig();
    });
  }
  getBoxForm() {
    this.boxForm = this.fb.group({
      store_secret: [
        this.boxOrganization ? (this.boxOrganization['store_secret'] ? this.boxOrganization['store_secret'] : '') : ''
      ],
      document_store: [
        this.boxOrganization
          ? this.boxOrganization['document_store_title']
            ? this.boxOrganization['document_store_title']
            : ''
          : ''
      ],
      doc_store_url: [
        this.boxOrganization ? (this.boxOrganization['doc_store_url'] ? this.boxOrganization['doc_store_url'] : '') : ''
      ]
    });
  }
  canGoToNextStep() {
    return true;
  }

  onStatusChange(status: any) {
    if (this.backend_error) {
      this.backend_error = '';
    }
    if (status == 'Box') {
      this.boxForm.get('store_secret').setValidators([Validators.required]);
      this.boxForm.get('store_secret').updateValueAndValidity();
      this.boxForm.get('doc_store_url').setValidators([Validators.required]);
      this.boxForm.get('doc_store_url').updateValueAndValidity();
      this.show_box_button = true;
      this.show_GD_button = false;
      this.selectedOption = status;
      this.showSubmitBtn = true;
      this.showBoxDetails = true;
      this.boxForm.patchValue({ doc_store_url: '' });
      this.boxForm.get('doc_store_url').enable();
    } else if (status == 'Google Drive') {
      this.boxForm.get('store_secret').removeValidators([Validators.required]);
      this.boxForm.get('store_secret').updateValueAndValidity();
      this.boxForm.get('doc_store_url').setValidators([Validators.required]);
      this.boxForm.get('doc_store_url').updateValueAndValidity();
      this.boxForm.patchValue({ doc_store_url: this.google_store_url });
      this.boxForm.get('doc_store_url').disable();
      this.show_GD_button = true;
      this.show_box_button = false;
      this.selectedOption = status;
      this.showSubmitBtn = true;
      this.showBoxDetails = true;
    } else {
      this.show_GD_button = false;
      this.show_box_button = false;
      this.selectedOption = '';
      this.showSubmitBtn = false;
      this.showBoxDetails = false;
    }
  }

  submitAWSForm() {
    this.isAWSSubmitButtonLoading = true;
    this.isAWSFormSubmitted = false;
    let data = _.cloneDeep(this.AWSForm.value);
    let AWS_ROOT_DIR = 'https://s3.console.aws.amazon.com/s3/buckets/' + data['S3_BUCKET_NAME'];
    delete data['S3_BUCKET_NAME'];
    data['AWS_ROOT_DIR'] = AWS_ROOT_DIR;
    this.AWSForm.markAsDirty();
    if (this.AWSForm.valid) {
      this.postAWSDataSubscription = this.wizard.organizationUpdate(this.organisationID, data).subscribe(data => {
        this.isAWSSubmitButtonLoading = false;
        this.isAWSFormSubmitted = true;
        this.translate.get('organisation_integration.integration_toaster_message.submit_aws_form').subscribe(res => {
          this.toaster.showSuccess(res);
        });
      });
    }
  }

  public checkValidityOfJiraForm(jiraFormValue: any) {
    return (
      !!jiraFormValue.JIRA_API_KEY &&
      !!jiraFormValue.JIRA_PROJECT_KEY &&
      !!jiraFormValue.JIRA_SERVER_URL &&
      !!jiraFormValue.JIRA_USER_EMAIL
    );
  }

  public detectJiraChange() {
    if (this.checkValidityOfJiraForm(this.jiraForm.value)) {
      this.selectJiraIssueType(this.jiraForm.value);
    } else {
      this.isJiraFormIssueTypeRecieved = false;
    }
  }

  public resetJiraIssueTypeField() {
    this.isJiraFormIssueTypeRecieved = false;
    this.organizationJiraCredemtials['JIRA_ISSUE_TYPE_LIST'] = [];
    this.jiraIssueTypeError = '';
    this.organizationJiraCredemtials['JIRA_ISSUE_TYPE'] = null;
  }

  selectJiraIssueType(formData: any) {
    this.isJiraIssueLoading = true;
    this.wizard.getJiraIssueTypes(formData).subscribe(
      resp_data => {
        this.organizationJiraCredemtials['JIRA_ISSUE_TYPE'] = resp_data.issue_types[0];
        this.organizationJiraCredemtials['JIRA_ISSUE_TYPE_LIST'] = resp_data.issue_types;
        this.isJiraFormIssueTypeRecieved = true;
        this.isJiraIssueLoading = false;
      },
      error => {
        this.isJiraFormIssueTypeRecieved = false;
        this.isJiraIssueLoading = false;
        this.jiraIssueTypeError = error.error.error;
      }
    );
  }

  submitJiraForm() {
    this.isJiraSubmitButtonLoading = true;
    this.isJiraFormSubmitted = false;
    const data = _.cloneDeep(this.jiraForm.value);
    this.jiraForm.markAsDirty();
    if (this.jiraForm.valid) {
      this.postJIRADataSubscription = this.wizard.organizationUpdate(this.organisationID, data).subscribe(
        resp_data => {
          this.organizationJiraCredemtials = resp_data;
          this.isJiraSubmitButtonLoading = false;
          this.isJiraFormSubmitted = true;
          this.translate.get('organisation_integration.integration_toaster_message.submit_jira_form').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        },
        error => {
          this.isJiraSubmitButtonLoading = false;
          this.isJiraFormSubmitted = false;
          this.translate
            .get('organisation_integration.integration_toaster_message.submit_jira_form_error')
            .subscribe(res => {
              this.toaster.showError(res);
            });
        }
      );
    } else {
      this.isJiraSubmitButtonLoading = false;
      this.isJiraFormSubmitted = false;
    }
  }
  submitBoxForm() {
    if (this.backend_error) {
      this.backend_error = '';
    }
    this.isBoxSubmitButtonLoading = true;
    this.isBoxFormSubmitted = false;
    const data = _.cloneDeep(this.boxForm.value);
    if (this.selectedFile == null) {
      data.store_secret = data.store;
      delete data.store;
    } else {
      data.store_secret = this.selectedFile;
      delete data.store;
    }
    data.document_store = this.selectedOption;
    if (data['document_store'] == 'Box') {
      this.boxForm.get('store_secret').setValidators([Validators.required]);
      this.boxForm.get('store_secret').updateValueAndValidity();
      this.boxForm.get('doc_store_url').setValidators([Validators.required]);
      this.boxForm.get('doc_store_url').updateValueAndValidity();
    } else {
      this.boxForm.markAsDirty();
      data['doc_store_url'] = this.google_store_url;
    }
    if (this.boxForm.valid) {
      data['is_shared_drive'] = this.is_shared_drive;
      this.postBoxDataSubscription = this.wizard.organizationBoxUpdate(this.organisationID, data).subscribe(
        resp_data => {
          this.boxOrganization = resp_data;

          this.showSubmitBtn = false;
          this.isBoxSubmitButtonLoading = false;
          this.isBoxFormSubmitted = true;

          if (this.boxOrganization['document_store_title']) {
            this.selectedOption = this.boxOrganization['document_store_title'];
            this.boxForm.patchValue({ document_store: this.boxOrganization['document_store_title'] });
            this.boxForm.get('document_store').disable();
            this.boxForm.patchValue({ doc_store_url: this.boxOrganization['doc_store_url'] });
            this.boxForm.get('doc_store_url').disable();
          }
          if (this.boxOrganization['document_store_title'] == 'Box') {
            this.show_box_button = true;
            if (this.boxOrganization['store_secret'] != null) {
              this.showBoxData = false;
            }
            this.translate
              .get('organisation_integration.integration_toaster_message.submit_document_store_form')
              .subscribe(res => {
                this.toaster.showSuccess(res);
              });
          }

          if (
            this.boxOrganization['document_store_title'] == 'Google Drive' &&
            this.boxOrganization['store_secret'] == '' &&
            this.boxOrganization['store_details']['is_shared_drive'] == true
          ) {
            this.show_initialize_folder_btn = true;
            this.is_shared_drive = true;
            this.showSubmitBtn = true;
            this.translate
              .get('organisation_integration.integration_toaster_message.shared_drive_created')
              .subscribe(res => {
                this.toaster.showSuccess(res);
              });
          } else if (
            this.boxOrganization['document_store_title'] == 'Google Drive' &&
            this.boxOrganization['store_secret'] != ''
          ) {
            this.showSubmitBtn = false;
            this.show_initialize_folder_btn = false;
            this.translate
              .get('organisation_integration.integration_toaster_message.submit_document_store_form')
              .subscribe(res => {
                this.toaster.showSuccess(res);
              });
          }
        },
        (error: any) => {
          this.isBoxSubmitButtonLoading = false;
          this.isBoxFormSubmitted = false;
          this.error = error.error;
          console.log(this.error);
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
        }
      );
    } else {
      this.isBoxSubmitButtonLoading = false;
      this.isBoxFormSubmitted = false;
    }
  }

  uploadFile(event: any) {
    this.error = '';
    var selected: any;
    var selectedFile1: any;
    selected = event.target.files[0];

    var reader = new FileReader();

    reader.onload = e => {
      this.selectedFile = reader.result;
    };
    reader.onerror = function(e) {
      // error occurred
    };

    reader.readAsBinaryString(selected);
  }
  // To show password when you click on eye icon
  showPassword(val: any): void {
    switch (val) {
      case 'jira_api_key':
        this.jiraApiKey = !this.jiraApiKey;
        break;
      case 'aws_access_id':
        this.awsAccessId = !this.awsAccessId;
        break;
      case 'aws_access_key':
        this.awsAccessKey = !this.awsAccessKey;
        break;
      default:
        this.awsAccessKey = false;
        this.awsAccessId = false;
        this.jiraApiKey = false;
    }
  }

  testJiraForm() {
    const data = _.cloneDeep(this.jiraForm.value);
    if (this.jiraForm.valid && !this.isJiraTestButtonLoading) {
      this.isJiraTestButtonLoading = true;
      this.orgService.organisation_guid.subscribe((guid: any) => {
        this.organisationID = guid;
        this.wizard.testJiraCredentials(data).subscribe(
          resp_data => {
            this.translate.get('organisation_integration.integration_toaster_message.test_jira_form').subscribe(res => {
              this.toaster.showSuccess(res);
            });
            this.isJiraTestButtonLoading = false;
          },
          error => {
            this.translate
              .get('organisation_integration.integration_toaster_message.test_jira_form_error')
              .subscribe(res => {
                this.toaster.showError(res);
              });
            this.isJiraTestButtonLoading = false;
          }
        );
      });
    } else {
      this.translate.get('organisation_integration.integration_toaster_message.test_jira_form_error').subscribe(res => {
        this.toaster.showError(res);
      });
      this.isJiraTestButtonLoading = false;
    }
  }
  focusOutFunction() {
    this.selectJiraIssueType(this.jiraForm.value);
  }

  getJiraIssueTypeList() {
    if (!_.isEmpty(this.organizationJiraCredemtials)) {
      this.selectJiraIssueType(this.organizationJiraCredemtials);
    }
  }
  onChange(evt: any) {
    if (evt.type == 'change' && this.error) {
      this.error = '';
    }
  }

  getOrganizationDetails() {
    this.guidSubscription = this.orgService.organisation_data.subscribe((data: any) => {
      this.organizationJiraCredemtials = data;
      this.organizationJiraCredemtials['JIRA_ISSUE_TYPE_LIST'] =
        data['JIRA_ISSUE_TYPE_LIST'] === null ? [] : data['JIRA_ISSUE_TYPE_LIST'];
      if (data && data.AWS_ROOT_DIR) {
        this.getBucketFromS3Root(data.AWS_ROOT_DIR);
      }
      this.isLoading = false;
    });
  }

  getBoxOrganizationDetails() {
    this.isLoading = true;

    this.wizard.organizationBoxDetailsUpdate(this.organisationID).subscribe((data: any) => {
      console.log(data);
      this.boxOrganization = data;
      if (this.boxOrganization['doc_store_url']) {
        this.boxOrganizationdoc_store_url = true;
      }
      // this.isLoading = false;
      if (this.boxOrganization['document_store_title'] == 'Box') {
        if (this.boxOrganization['store_secret'] != '' || this.boxOrganization['store_secret'] != null) {
          this.showBoxData = false;
        }
        this.showBoxDetails = true;
      }
      this.getBoxForm();

      if (this.boxOrganization['document_store_title'] != null) {
        this.showBoxDetails = true;
        this.showData = true;
        this.boxForm.patchValue({ document_store: this.boxOrganization['document_store_title'] });
        this.boxForm.get('document_store').disable();
        this.boxForm.get('doc_store_url').disable();

        if (
          this.boxOrganization['document_store_title'] == 'Google Drive' &&
          this.boxOrganization['store_secret'] == '' &&
          this.boxOrganization['store_details']['is_shared_drive'] == true
        ) {
          this.show_initialize_folder_btn = true;
          this.is_shared_drive = true;
          this.showSubmitBtn = true;
        }

        if (
          this.boxOrganization['document_store_title'] == 'Google Drive' &&
          this.boxOrganization['store_secret'] != ''
        ) {
          this.show_initialize_folder_btn = false;
          this.showSubmitBtn = false;
        }
      }

      this.boxOrganizationType = this.boxOrganization['store_secret'];
      this.selectedOption = this.boxOrganization['document_store_title'];
      this.storeUpdating = false;
      this.isLoading = false;
    });
  }
  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
    this.orgGuidSubscription.unsubscribe();
    if (this.postJIRADataSubscription) {
      this.postJIRADataSubscription.unsubscribe();
    }
    if (this.postAWSDataSubscription) {
      this.postAWSDataSubscription.unsubscribe();
    }
  }

  getBucketFromS3Root(aws_root: string) {
    const array_of_splitted_elements = aws_root.split('/');
    let bucket = array_of_splitted_elements[array_of_splitted_elements.length - 1];
    if (bucket) {
      // If trailing '/' is not present in aws_root
      this.bucket_name = bucket;
    } else if (array_of_splitted_elements.length != 0) {
      // If trailing '/' is present in aws_root and arraylength is not 0
      bucket = array_of_splitted_elements[array_of_splitted_elements.length - 2];
      this.bucket_name = bucket;
    }
  }

  testS3Credentials() {
    let data = _.cloneDeep(this.AWSForm.value);
    if (this.AWSForm.valid && !this.isAWSTestButtonLoading) {
      this.isAWSTestButtonLoading = true;
      this.wizard.testS3Credentials(data).subscribe(
        (data: any) => {
          this.translate
            .get('organisation_integration.integration_toaster_message.tests3_credential')
            .subscribe(res => {
              this.toaster.showSuccess(res);
            });
          this.isAWSTestButtonLoading = false;
        },
        error => {
          if (error.status == 400) {
            this.translate
              .get('organisation_integration.integration_toaster_message.tests3_credential_400')
              .subscribe(res => {
                this.toaster.showError(res);
              });
          } else if (error.status == 403) {
            this.translate
              .get('organisation_integration.integration_toaster_message.tests3_credential_403')
              .subscribe(res => {
                this.toaster.showError(res);
              });
          } else if (error.status == 404) {
            this.translate
              .get('organisation_integration.integration_toaster_message.tests3_credential_404')
              .subscribe(res => {
                this.toaster.showError(res);
              });
          } else {
            this.toaster.showError(error ? error.error.error : 'Some error has occurred.');
          }
          this.isAWSTestButtonLoading = false;
        }
      );
    }
  }

  getAccessToken(code: string, createFolder: boolean = false) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const params = new HttpParams()
      .set('code', code)
      .set('client_id', this.clientId)
      .set('client_secret', this.clientSecret)
      .set('redirect_uri', createFolder ? this.redirectUriCreateFolder : this.redirectUri)
      .set('grant_type', 'authorization_code');
    return this.http
      .post(this.tokenUrl, params.toString(), { headers })
      .toPromise()
      .then((response: any) => {
        console.log(response);
        this.updateStoreDetails(response, createFolder);
      })
      .catch(error => {
        window.location.href = this.redirectUri;
        console.error('Error getting access token:', error);
        throw error;
      });
  }
  updateStoreDetails(code: any = {}, createFolder: boolean = false) {
    this.storeUpdating = true;
    this.isLoading = true;
    let payload = {
      store_secret: {
        token: code.access_token,
        refresh_token: code.refresh_token,
        token_uri: this.tokenUrl,
        client_id: this.clientId,
        scopes: createFolder ? this.FILE_DRIVE_SCOPES : this.CREATE_SHARED_DRIVE_SCOPES
      }
    };
    this.orgService.updateStoreDetails(this.organisationID, payload).subscribe(
      res => {
        this.isLoading = false;
        this.driveConfiguration = 'shared_drive';
        this.getBoxOrganizationDetails();
      },
      error => {
        if (error.status == 400) {
          this.drivePermissions = true;
          this.boxForm.get('document_store').setValue('Google Drive');
          this.show_initialize_folder_btn = false;
          this.show_GD_button = true;
          this.showSubmitBtn = true;
          this.toaster.showError('The authenticated user cannot create new shared drives.');
        } else {
          this.toaster.showError(error ? error.error.error : 'Some error has occurred.');
        }
        this.storeUpdating = false;
        this.isLoading = false;
      }
    );
  }
  getDriveConfig() {
    this.orgService.getDriveConfig(this.organisationID).subscribe((res: any) => {
      let data = JSON.parse(res.drive_config || '');
      this.clientId = data.client_id;
      this.clientSecret = data.client_secret;
      this.route.queryParams.subscribe(params => {
        const authorizationCode = params['code'];
        const isCreateFolder = params['createFolder'];
        if (authorizationCode) {
          // Call getAccessToken with the authorization code

          this.getAccessToken(authorizationCode, Boolean(isCreateFolder));
        } else {
          console.error('Authorization code not found.');
        }
      });
    });
  }
  openGoogleDrivePage() {
    const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    const params = {
      response_type: 'code',
      client_id: this.clientId,
      redirect_uri: `${this.redirectUri}`,
      prompt: 'consent',
      access_type: 'offline',
      scope: this.CREATE_SHARED_DRIVE_SCOPES
    };
    const urlParams = this.objectToQueryString(params);
    window.location.href = `${googleAuthUrl}?${urlParams}`;
  }
  openGoogleCreateSharedDoc() {
    const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    const params = {
      response_type: 'code',
      client_id: this.clientId,
      redirect_uri: `${this.redirectUriCreateFolder}`,
      prompt: 'consent',
      access_type: 'offline',
      scope: this.FILE_DRIVE_SCOPES
    };
    const urlParams = this.objectToQueryString(params);
    console.log(this.redirectUriCreateFolder, urlParams);
    window.location.href = `${googleAuthUrl}?${urlParams}`;
  }
  objectToQueryString = (paramsObject: any) => {
    const keys = Object.keys(paramsObject);
    const queryString = keys
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(paramsObject[key])}`)
      .join('&');
    return queryString;
  };
  onRadioChange($event: any) {
    console.log($event);
    this.driveConfiguration = $event.target.value;
    console.log('Selected drive configuration:', this.driveConfiguration);
    // Perform any necessary actions with the selected value here
  }
}
