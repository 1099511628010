import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '@app/core/modal/modal.service';
import { OrganisationService } from '@app/core/organisation.service';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '../settings.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { take } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';

@Component({
  selector: 'app-document-groups',
  templateUrl: './document-groups.component.html',
  styleUrls: ['./document-groups.component.scss']
})
export class DocumentGroupsComponent implements OnInit, OnDestroy {
  scrollLoader = false;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  page = 1;
  organisationID: string | null;
  error: any;
  guidSubscription: any;
  publishedDocuments: any = [];
  role: any;
  isOrgAdmin: boolean = false;
  accArray: any[] = [];
  documentGroupList: any = [];
  documentGroups: any = [];
  is_editable = true;
  itemUpdated: any = {};
  roles = {
    organization_user_role: 'User',
    organization_admin_role: 'Administrator'
  };
  close = false;
  submitted = false;
  groupName: string;
  groupDescription: string;
  loading = true;
  createdBy: any;
  paginatedDataUrl: any;
  form = {
    description: { data: '', edit: false, error: '' }
  };
  pagnationData: any;
  documentGroupsCopy: Array<any> = [];
  backend_error: string;
  fetch_document = false;
  documentList: any = {};

  constructor(
    private orgService: OrganisationService,
    private modalService: ModalService,
    private toaster: ToasterService,
    private translate: TranslateService,
    private documentService: DocumentsService,
    private backendErrorService: BackendErrorService
  ) {
    this.role = JSON.parse(localStorage.getItem('credentials'));
  }

  ngOnInit(): void {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.organisationID = guid;
      this.getDocumentGroups();
      this.isOrgAdmin = this.role['user']['role'].includes('organization_admin_role');
    });
  }

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  createDocumentGroup() {
    this.modalService
      .custom(CustomDialogComponent, { title: 'Create Document Group', modalType: 'document group' })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.page = 1;
          this.getDocumentGroups();
        }
      });
  }
  manageDocument(item: any) {
    this.documentService
      .getDocumentList(this.organisationID, this.page, true, '', [item.guid])
      .subscribe((data: any) => {
        this.modalService
          .custom(CustomDialogComponent, {
            title: 'manage document group',
            modalType: 'manage document group',
            formData: data[0]
          })
          .pipe(take(1)) // take() manages unsubscription for us
          .subscribe((result: any) => {
            if (result.update) {
              let { added, removed } = result;
              this.documentService
                .saveDocumentGroup(this.organisationID, item.guid, { added: added, removed: removed })
                .subscribe(
                  (res: any) => {
                    this.documentGroupList.forEach((doc: any) => {
                      if (doc.guid == res.guid) {
                        doc['documents'] = res['documents'];
                        doc['doc_count'] = res['doc_count'];
                        this.fetch_document = true;
                        this.documentService
                          .getDocumentList(this.organisationID, this.page, this.fetch_document, '', [doc.guid])
                          .subscribe((data: any) => {
                            this.documentList[doc.guid] = data[0]['documents'];
                          });
                      }
                    });
                    this.translate.get('Document_Group.document_toaster_message.updated_group').subscribe(res => {
                      this.toaster.showSuccess(res);
                    });
                    this.getDocumentGroups();
                  },
                  error => {
                    this.error = error.error.message;
                    this.loading = false;
                    this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
                    this.backendErrorService.backendError$.subscribe(error => {
                      this.backend_error = error;
                    });
                    this.toaster.showError(this.backend_error);
                  }
                );
            }
          });
      });
  }

  toggleDetails(index: any, clickedGuid: string): void {
    if (this.accArray.includes(index)) {
      const pos = this.accArray.indexOf(index);
      if (index > -1) {
        this.accArray.splice(pos, 1);
      }
    } else {
      this.accArray.push(index);
      for (let i = 0; i <= this.documentGroupList.length; i++) {
        if (clickedGuid == this.documentGroupList[i]['guid']) {
          this.fetch_document = true;
          this.documentService
            .getDocumentList(this.organisationID, this.page, this.fetch_document, '', [clickedGuid])
            .subscribe((data: any) => {
              this.documentList[clickedGuid] = data[0]['documents'];
            });
          break;
        }
      }
    }
  }
  onScrollUp() {
    this.scrollLoader = false;
  }
  private getDocumentGroups(): void {
    this.documentService.getDocumentGroups(this.organisationID, this.page, this.fetch_document, '').subscribe(
      (data: any) => {
        this.paginatedDataUrl = data;
        if (this.page > 1) {
          this.documentGroupList = this.documentGroupList.concat(
            data.results.filter((data: any) =>
              this.documentGroupList.every((item: any) => item['guid'] !== data['guid'])
            )
          );
        } else {
          this.documentGroupList = JSON.parse(JSON.stringify(data.results));
        }

        this.loading = false;
        this.documentGroupsCopy = JSON.parse(JSON.stringify(this.documentGroupList));
        for (let i = 0; i < this.documentGroupList.length; i++) {
          this.documentGroupList[i].edit = false;
        }

        this.loading = false;
      },
      error => {}
    );
  }
  onScrollData() {
    this.scrollLoader = true;
    this.fetch_document = false;
    this.documentService.getDocumentGroups(this.organisationID, (this.page += 1), this.fetch_document, '').subscribe(
      (data: any) => {
        this.scrollLoader = false;
        this.paginatedDataUrl = data;
        this.documentGroupList = this.documentGroupList.concat(data.results);
      },
      error => {
        this.scrollLoader = false;
        this.error = error.error.message;
      }
    );
  }
  onScroll() {
    if (this.paginatedDataUrl?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }

  resetField(field_name: string, index: any) {
    this.documentGroupList[index]['description'] = this.documentGroupsCopy[index]['description'];
    if (field_name == this.documentGroupList[index]['description']) {
      this.form['description'].edit = !this.form['description'].edit;
    }
  }

  submitPartForm(description: any, groupguid: any) {
    this.edit(description, groupguid);
  }

  edit(element: any, groupguid: any) {
    const data = { description: element };
    this.documentService.updateDocumentGroup(this.organisationID, groupguid, data).subscribe(
      data => {
        this.getDocumentGroups();
        this.form.description.edit = !this.form.description.edit;
        this.translate.get('Document_Group.Description_edited').subscribe(res => {
          this.toaster.showSuccess(res);
        });
      },
      error => {
        this.error = error.error.message;
        console.log('Error:', error.error.message);
      }
    );
  }
  deleteGroupPrompt(item: any) {
    this.deleteDocumentModal(item.guid, item.title);
  }
  deleteDocumentModal(documentGroupGuid: string, documentgroupTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Delete Document Group',
        message: 'Are you sure you want to delete document group: ' + documentgroupTitle,
        modalType: 'Delete Document Group',
        categoryGuid: documentGroupGuid
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.loading = true;
          this.documentService.deleteDocumentGroup(this.organisationID, documentGroupGuid).subscribe(
            (data: any) => {
              this.loading = false;
              this.page = 1;
              this.getDocumentGroups();
              this.translate.get('Document_Group.document_removed').subscribe(res => {
                this.toaster.showSuccess(res);
              });
            },
            error => {
              this.error = error.error.message;
              this.loading = false;
              this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
              this.backendErrorService.backendError$.subscribe(error => {
                this.backend_error = error;
              });
              this.toaster.showError(this.backend_error);
            }
          );
        } else {
          console.log('Cancelled.');
        }
      });
  }
}
