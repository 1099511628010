<app-spinner *ngIf="loading && !key"></app-spinner>
<ng-container *ngIf="!loading">
  <div class="cb-container cb-page-bg-color" *ngIf="orgSelect">
    <div class="container-fluid">
      <div class="row">
        <div class="col d-flex justify-content-between header-padding">
          <div>
            <h2 class="text-black-1 font-weight-bold">Tasks</h2>
          </div>
          <div class="search-people position-relative">
            <div class="input-group w-330">
              <input
                id="search"
                [(ngModel)]="searchText"
                class="form-control cb-input"
                [placeholder]="'events-search' | translate"
                name="search"
                type="text"
                (keyup)="updateSearchText($event)"
                (keyDown.enter)="Search(searchText)"
                #titleInput
              />
            </div>
            <i class="fa fa-search search-icon"></i>
          </div>
          <div>
            <label>Select Program</label>
            <select class="cb-input w-140 ml-3" [(ngModel)]="programFilter" (change)="changeProgram($event)">
              <option value="" selected>All</option>
              <option
                *ngFor="let program of program; let i = index"
                [attr.data-index]="i"
                value="{{ program['guid'] }}"
              >
                {{ program['name'] }}
              </option>
            </select>
          </div>
          <div>
            <label>Schedule Type</label>
            <select
              class="cb-input w-140 ml-3"
              [(ngModel)]="schedule_filter"
              (change)="change_schedule($event)"
              *ngIf="!schedule_loading"
            >
              <option value="">All</option>
              <option
                *ngFor="let schedule of schedules; let i = index"
                [attr.data-index]="i"
                value="{{ schedule['value'] }}"
              >
                {{ schedule['display_value'] }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div
        class="search-results"
        infiniteScroll
        [scrollWindow]="false"
        (scrolled)="onScroll()"
        [infiniteScrollDistance]="modalScrollDistance"
        [infiniteScrollThrottle]="modalScrollThrottle"
        (scrolledUp)="onScrollUp()"
      >
        <ng-container *ngFor="let event of Events; let i = index">
          <app-event-card [event]="event" [is_editable]="is_editable" (schedule_changed)="load_schedule_list()">
          </app-event-card>
        </ng-container>
      </div>
      <!-- </d<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">iv> -->
    </div>
    <h2 *ngIf="scrollLoader" class="font-weight-bold text-black-3 text-center">
      <i class="fa" [ngClass]="{ 'fa-spin fa-spinner': scrollLoader }" style="font-size:large;"></i> &nbsp; Loading...
    </h2>
  </div>
</ng-container>
<ng-template #no_content>
  <div *ngIf="!orgSelect" class="no-org-selected">
    <app-no-organisation-selected [typeMessage]="parentTypeMessage"></app-no-organisation-selected>
  </div>
</ng-template>

<!--New event html that to be integrated : TODO-->
