<app-spinner *ngIf="loading"></app-spinner>
<ng-container *ngIf="!loading">
  <div class="row">
    <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
      <h2 class="text-cyan m-0" translate>organisation_committee.Committees</h2>
      <div>
        <button (click)="addCommiittee()" class="btn-add ml-4" translate>
          <i class="fa fa-plus"></i>organisation_committee.Add Committee
        </button>
      </div>
    </div>
  </div>
  <div class="row ">
    <div
      class="col p-0 search-results"
      infiniteScroll
      [scrollWindow]="false"
      (scrolled)="onScroll()"
      [infiniteScrollDistance]="modalScrollDistance"
      [infiniteScrollThrottle]="modalScrollThrottle"
      (scrolledUp)="onScrollUp()"
    >
      <div class="col">
        <div
          class="cb-accordian mx-auto w-740 mt-4"
          [ngClass]="!accArray.includes(i) ? '' : 'expand'"
          *ngFor="let committee of committeeList; let i = index"
          [attr.data-index]="i"
        >
          <div class="acc-header d-flex justify-content-between">
            <h5
              class="text-black-1 f2 text-truncate"
              style=" width: 45%;"
              [ngbTooltip]="committee['committee_name']"
              tooltipClass="cb-tooltip"
            >
              {{ committee['committee_name'] }}
            </h5>
            <div class="d-flex align-items-center">
              <h5 class="font-weight-bold text-black-1 m-0" translate>
                {{ 'organisation_committee.Total_Participants' | translate }} - {{ committee['total_participation'] }}
              </h5>
              <button
                class="btn-save ml-4 w-140"
                (click)="addParticipantsToCommittee(committee['members'], committee['org_review_com_guid'])"
                translate
              >
                <i class="fa fa-plus text-white mr-2 ml-1"></i>organisation_committee.Add Participant
              </button>
              <button
                class="ml-4 mr-3"
                [ngClass]="committee['total_participation'] > 0 ? 'btn-acc-toggle' : 'btn-acc-toggle-disabled'"
                [disabled]="!committee['total_participation'] > 0"
                (click)="toggleDetails(i)"
              >
                <i
                  *ngIf="committee['total_participation'] > 0"
                  [ngClass]="
                    !accArray.includes(i) ? 'fas fa-chevron-down text-black-2' : 'fas fa-chevron-up text-black-2'
                  "
                ></i>
              </button>
            </div>
          </div>
          <div class="acc-body px-3">
            <table class="w-100p table-2">
              <thead>
                <tr>
                  <td><h4 class="font-weight-bold text-black-2 p-3" translate>organisation_committee.Name</h4></td>
                  <td>
                    <h4 class="font-weight-bold text-black-2 p-3" translate>organisation_committee.Phone Number</h4>
                  </td>
                  <td>
                    <h4 class="font-weight-bold text-black-2 p-3" translate>organisation_committee.Email Address</h4>
                  </td>
                  <td><h4 class="font-weight-bold text-black-2 p-3" translate>organisation_committee.Role</h4></td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let members of committee['members']">
                  <td>
                    <h4 class="text-black-1 p-3 pr-3 w-240">{{ members['full_name'] }}</h4>
                  </td>
                  <td>
                    <h4 class="text-black-1 p-3">{{ members['phone'] ? members['phone'] : '-' }}</h4>
                  </td>
                  <td>
                    <h4 class="text-black-1 p-3">{{ members['email'] ? members['email'] : '-' }}</h4>
                  </td>
                  <td *ngFor="let role of committee['roles']">
                    <h4 class="text-black-1 p-3">{{ committee['roles'] !== [] ? roles[role] : '-' }}</h4>
                  </td>
                  <td>
                    <a
                      (click)="openConfirm(members['full_name'], committee['org_review_com_guid'], members['email'])"
                      class="text-black-1 p-3 pr-3 link-1 cursor-pointer"
                      ><u translate>organisation_committee.Remove</u></a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
