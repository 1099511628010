import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { VersionComponent } from './version.component';

const routes: Routes = [{ path: 'version', component: VersionComponent, data: { title: extract('version') } }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class VersionRoutingModule {}
