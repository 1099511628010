import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/core';
import { OrganisationService } from '@app/core/organisation.service';
import { DocumentsService } from '@app/Organisation-admin/documents/documents.service';
import { SettingsService } from '@app/settings/settings.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Document_Invite_Training_Groups } from '../../../../translations/en-US.json';
import { take } from 'rxjs/operators';
import { ProviderDataValidators as Validators } from '@app/modules/data-valiidator';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';

// import { ReplyComponent } from '../reply/reply.component';

@Component({
  selector: 'app-multi-select-scroll-document',
  templateUrl: './multi-select-scroll-document.component.html',
  styleUrls: ['./multi-select-scroll-document.component.scss']
})
export class MultiSelectScrollDocumentComponent implements OnInit, AfterViewInit {
  MultiSelectDocumentForm: FormGroup;
  submitted = false;
  error = {};
  default_doc_url_type: any;
  success: boolean;
  // multiselect dropdown
  dropdownList: any = [];
  DocumentsList: any = [];

  dropdownSettings = {};
  isLoading = false;
  OrganisationID: string | null;
  guidSubscription: any;
  abcd: any;
  userGuid: string;
  roleSelected: boolean;
  FolderList: any = [];
  SubFolderList: object[];
  form_submitted: boolean;
  showError: boolean;
  external_documents: any = [];
  internal_documents: any = [];

  @Input() title_tag: string;
  @Input() formType: string;
  @Input() form_data: [];
  @Input() docTitle: string;
  @Input() selectedDocuments: any = [];

  selectedItems: any = [];

  document_types: any;
  DocStoreguid: any;
  // addDocumentForm: FormGroup;
  showorhidesubfolder: any;
  list: any[];
  childList1: any[];
  subfolder: any;
  completeData: any;
  subscription: any;
  selectedFolderitem: any;
  itemChecked: any;
  message: string;
  documentSelected: boolean;

  // @ViewChild(ReplyComponent, {static: false}) replay: ReplyComponent;
  checkedItem: string;
  folder_name: string;
  // setselectedFolder: void;

  constructor(
    private orgService: OrganisationService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private documentsService: DocumentsService
  ) {}

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
    // this.subscription.unsubscribe();
  }

  parentChanged(i: any, $event: any) {
    console.log(i, $event);
  }

  selectedNavItem(SubFolderList: any) {
    this.completeData = SubFolderList;
  }

  newDocumentUrl(): FormGroup {
    return this.formBuilder.group({
      // urltype: [this.default_doc_url_type],
      url: ['', [Validators.httpsURL(), Validators.required()]],
      title: ['', [Validators.required()]]
    });
  }

  ngOnInit() {
    this.subscription = this.documentsService
      .getNavChangeEmitter()
      .subscribe((item: any) => this.selectedNavItem(item));
    this.completeData = this.documentsService.getCompleteData();
    this.subfolder = this.documentsService.getReplies();
    this.getMultiSelectDocumentForm();

    this.documentsService.getSelectedDocsData().subscribe(value => {
      this.selectedDocuments = value;
    });
    this.optionsData.push(this.newDocumentUrl());

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'document_version',
      textField: 'title',
      itemsShowLimit: 10,
      enableCheckAll: false,
      allowSearchFilter: true,
      orderby: 'title'
    };

    this.guidSubscription = this.orgService.getSelectedOrganisationGuid().subscribe(guid => {
      this.OrganisationID = guid;
      this.documentsService.getAllDocumentVersions(this.OrganisationID).subscribe(
        data => {
          this.DocumentsList = data;
          // this.documentsService.emitNavChangeEvent(this.FolderList);
          // this.documentsService.emitEventWithLatestData(this.documentsService.getcheckedItem());
        },
        error => {
          this.error = error.error.message;
        }
      );
    });
  }

  ngAfterViewInit() {
    this.documentsService.getSelectedDocsData().subscribe(value => {
      this.selectedDocuments = value;
      this.selectedItems = this.selectedDocuments.internal_documents;
      this.MultiSelectDocumentForm.value.document_links = this.selectedDocuments.external_documents;
    });
  }

  onItemSelect(item: any) {
    this.checkDocumentSelection();
  }

  checkDocumentSelection() {
    this.selectedItems.length > 0 ? (this.documentSelected = true) : (this.documentSelected = false);
  }
  onItemDeSelect(items: any) {
    this.checkApproverSelection();
  }

  getMultiSelectDocumentForm(): void {
    this.MultiSelectDocumentForm = this.formBuilder.group({
      title: ['', Validators.required()],
      document_links: this.formBuilder.array([])
    });
  }

  checkApproverSelection() {
    this.selectedItems.length > 0 ? (this.documentSelected = true) : (this.documentSelected = false);
  }

  get optionsData(): FormArray {
    return this.MultiSelectDocumentForm.get('document_links') as FormArray;
  }

  get f() {
    return this.MultiSelectDocumentForm.controls;
  }

  removeOption(i: number) {
    this.optionsData.removeAt(i);
  }

  addDocumentLink() {
    this.submitted = false;
    if (this.optionsData.valid) {
      this.optionsData.push(this.newDocumentUrl());
    } else {
      return;
    }
  }

  addDocumentUrl() {
    this.submitted = false;
    if (this.optionsData.valid) {
      this.optionsData.push(this.newDocumentUrl());
    } else {
      return;
    }
  }

  cancel() {
    if (this.internal_documents.length <= 0 && this.external_documents.length <= 0) {
      this.showError = true;
    } else {
      this.activeModal.close(false);
    }
  }

  save() {
    // if (this.MultiSelectDocumentForm.invalid) {
    //   return;
    // }
    // this.isLoading = true;
    this.error = '';

    this.external_documents = this.MultiSelectDocumentForm.value.document_links;
    this.internal_documents = this.selectedItems;

    if (this.internal_documents.length <= 0 && this.external_documents.length <= 0) {
      this.showError = true;
    } else {
      this.documentsService.setSelectedDocsData({
        internal_documents: this.internal_documents,
        external_documents: this.external_documents
      });
      this.form_submitted = true;
      this.activeModal.close(false);
    }
  }

  onDocumentSelect(item: any) {
    this.checkDocumentSelection();
  }

  onDocumentDeSelect(items: any) {
    this.checkDocumentSelection();
  }
}
