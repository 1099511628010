<div class="row">
  <div class="col setting-actions justify-content-between d-flex h-70 align-items-center">
    <h2 class="text-cyan m-0" style="cursor:default" translate>Create_Form.Title</h2>
  </div>
</div>
<div class="row scroll-view">
  <div class="col">
    <ng-container>
      <form [formGroup]="CreateForm" novalidate autocomplete="off">
        <!-- added to remove chromes default autocompelte-->

        <div class="row mt-5">
          <div class="col-2 col-sm-2 col-lg-2">
            <label for="form_title" class="ml-2 mt-1 cb-label text-black-3" translate>Create_Form.Form_Title</label>
          </div>
          <div class="input-group col-10 col-sm-10 col-lg-10">
            <input
              id="form_title"
              class="form-control"
              formControlName="form_title"
              autocomplete="form_title"
              required
              name="form_title"
              type="text"
              style="height: 40px;"
            />
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-2 col-sm-2 col-lg-2">
            <label for="form_template" class="ml-2 mt-1 cb-label text-black-3" translate
              >Create_Form.Form_Template</label
            >
          </div>
          <div class="input-group col-10 col-sm-10 col-lg-10">
            <select id="form_template" class="form-control" formControlName="form_template" name="form_template">
              <option *ngFor="let template of templates" [value]="template.value">{{ template.viewValue }}</option>
            </select>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-2 col-sm-2 col-lg-2">
            <label for="destination_folder" class="ml-2 mt-1 cb-label text-black-3" translate
              >Create_Form.Destination_Folder</label
            >
          </div>
          <div class="input-group col-10 col-sm-10 col-lg-10">
            <mat-select
              [(value)]="SelectedFolder"
              id="destination_folder"
              class="form-control"
              formControlName="destination_folder"
              name="destination_folder"
            >
              <mat-option *ngFor="let folder of folders" [value]="folder.value">{{ folder.viewValue }}</mat-option>
            </mat-select>
          </div>
        </div>

        <div class="row mt-3" *ngIf="SelectedFolder">
          <div class="col-2"></div>
          <div class="col-10 col-sm-10 col-lg-10">
            <p>
              <img src="../../../assets/images/documents/folder.png" class="mr-2" /><span
                class="cb-label-2 mt-1"
                style="margin-bottom: 0.5rem;"
                translate
                >{{ SelectedFolder }}</span
              >
            </p>
            <p>
              <img src="../../../assets/images/documents/folder.png" class="ml-4 mr-2" /><span
                class="cb-label-2 mt-1"
                style="margin-bottom: 0.5rem;"
                translate
                >Create_Form.Employee_Details</span
              >
            </p>
            <p>
              <img src="../../../assets/images/documents/folder.png" class="ml-4 mr-2" /><span
                class="cb-label-2 mt-1"
                style="margin-bottom: 0.5rem;"
                >Create_Form.Admin</span
              >
            </p>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-md-12 bg-light text-right">
            <button [disabled]="!CreateForm.valid" class="cb-cancel-button" translate>Create_Form.Btn_Cncl</button>
            <button [disabled]="!CreateForm.valid" class="ml-2 cb-save-button" translate>Create_Form.Btn_Save</button>
          </div>
        </div>
      </form>
    </ng-container>
  </div>
</div>
