import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { OrganisationService } from '@app/core/organisation.service';
import { SettingsService } from '@app/settings/settings.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '@app/core/modal/modal.service';
import { CustomDialogComponent } from '@app/shared/modal/customDialog/customDialog.component';
import { take, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@app/shared/toaster/toastr.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IncidentService } from '@app/Organisation-admin/incident/incident.service';
import { BackendErrorService } from '@app/Organisation-admin/backenederror.service';

@Component({
  selector: 'app-create-category',
  templateUrl: './create-category.component.html',
  styleUrls: ['./create-category.component.scss']
})
export class CreateCategoryComponent implements OnInit {
  OrganisationID: string | null;
  error: any;
  guidSubscription: any;
  accArray: any[] = [];

  categoryName: string;
  updatedGuid: any;
  loading = true;
  categoryList: Array<any> = [];
  userGuid: any;
  categoryGuid: any;
  CategoryUpdatedList: any;
  @Input() is_editable = true;
  form = {
    description: { data: '', edit: false, error: '' }
  };
  scrollLoader = false;
  modalScrollDistance = 0.1;
  modalScrollThrottle = 50;
  page = 1;
  pagnationData: any;
  initialFormValues: {};
  info: any;
  showMore: boolean;
  backend_error: string;
  paginatedDataUrl: any;

  constructor(
    private backendErrorService: BackendErrorService,
    private orgService: OrganisationService,
    private modalService: ModalService,
    private translate: TranslateService,
    private toaster: ToasterService,
    private incidentService: IncidentService
  ) {}

  ngOnDestroy(): void {
    this.guidSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.guidSubscription = this.orgService.__organisation_guid.subscribe(guid => {
      this.OrganisationID = guid;
      this.getCategoryList();
    });
    this.initialFormValues = JSON.parse(JSON.stringify(this.form));
    console.log(this.initialFormValues);
  }

  onScrollUp() {
    this.scrollLoader = false;
  }

  onScrollData() {
    this.scrollLoader = true;
    this.incidentService.getCategoryListWithPagination(this.OrganisationID, (this.page += 1)).subscribe(
      data => {
        this.scrollLoader = false;
        this.pagnationData = data.results;
        this.paginatedDataUrl = data;
        this.categoryList = this.categoryList.concat(data.results);
      },
      error => {
        this.scrollLoader = false;
        this.error = error.error.message;
      }
    );
  }
  onScroll() {
    if (this.paginatedDataUrl?.next) {
      this.onScrollData();
    } else {
      this.scrollLoader = false;
    }
  }
  private getCategoryList(): void {
    if (this.OrganisationID != null) {
      this.incidentService.getCategoryList(this.OrganisationID).subscribe(
        data => {
          this.paginatedDataUrl = data;
          this.loading = false;
          this.categoryList = data.results;
          for (let i = 0; i < this.categoryList.length; i++) {
            this.categoryName = this.categoryList[i]['title'];
            this.userGuid = this.categoryList[i]['guid'];
            this.categoryList[i].edit = false;
            this.info = this.categoryList[i]['description'];
            this.categoryList = this.info.map((item: any) => ({
              ...item,
              showMore: false
            }));
          }
          this.loading = false;
        },
        error => {
          this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
          this.backendErrorService.backendError$.subscribe(error => {
            this.backend_error = error;
          });
          this.loading = false;
        }
      );
    }
  }
  trimString(string: any, length: any) {
    return string.length > length ? string.substring(0, length) + '...' : string;
  }

  resetField(field_name: string, index: any) {
    console.log(field_name);
    this.categoryList[index].edit = false;
  }

  addCategory() {
    this.modalService
      .custom(CustomDialogComponent, { title: ' Create Category', modalType: 'Create Category' })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getCategoryList();
          this.translate.get('Category.category_toaster_message.category_created').subscribe(res => {
            this.toaster.showSuccess(res);
          });
        }
      });
  }

  submitPartForm(description: any, catGuid: any) {
    this.edit(description, catGuid);
  }
  edit(element: any, catGuid: any) {
    console.log(element);
    const data = { description: element };
    this.incidentService.updateCategoryDescription(this.OrganisationID, catGuid, data).subscribe(
      data => {
        this.getCategoryList();
        this.form.description.edit = !this.form.description.edit;
      },
      error => {
        this.backendErrorService.setBackendError(error); // Pass the error.error value to the BackendErrorService
        this.backendErrorService.backendError$.subscribe(error => {
          this.backend_error = error;
        });
      }
    );
  }

  toggleDetails(index: any): void {
    if (this.accArray.includes(index)) {
      const pos = this.accArray.indexOf(index);
      if (index > -1) {
        this.accArray.splice(pos, 1);
      }
    } else {
      this.accArray.push(index);
    }
  }
  openDeleteIncidentCategoryForm(categoryGuid: string, categoryTitle: string) {
    this.deleteIncidentCategoryModal(categoryGuid, categoryTitle);
  }
  deleteIncidentCategoryModal(categoryGuid: string, categoryTitle: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Delete Incident Category',
        message: 'Are you sure you want to delete category ' + '"' + categoryTitle + '"' + ' .',
        modalType: 'Delete Incident Category',
        categoryGuid: categoryGuid
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getCategoryList();
          // this.translate
          //   .get('Revoke_Approver.revoke_approver_toaster_message.revoke_approver_submitted')
          //   .subscribe(res => {
          //     this.toaster.showSuccess(res);
          //   });
        } else {
          console.log('Cancelled.');
        }
      });
  }
  openEditIncidentCategoryForm(categoryGuid: string, categoryTitle: string, categoryDescription: string) {
    this.editIncidentCategoryModal(categoryGuid, categoryTitle, categoryDescription);
  }
  editIncidentCategoryModal(categoryGuid: string, categoryTitle: string, categoryDescription: string): void {
    this.modalService
      .custom(CustomDialogComponent, {
        title: 'Edit Incident Category',
        modalType: 'Edit Incident Category',
        categoryGuid: categoryGuid,
        categoryTitle: categoryTitle,
        categoryDescription: categoryDescription
      })
      .pipe(take(1)) // take() manages unsubscription for us
      .subscribe((result: any) => {
        if (result) {
          this.getCategoryList();
          // this.translate
          //   .get('Revoke_Approver.revoke_approver_toaster_message.revoke_approver_submitted')
          //   .subscribe(res => {
          //     this.toaster.showSuccess(res);
          //   });
        } else {
          console.log('Cancelled.');
        }
      });
  }
}
